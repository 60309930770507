"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Sticky',
  props: {
    stickyTop: {
      type: Number,
      default: 84
    },
    zIndex: {
      type: Number,
      default: 1
    },
    className: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      active: false,
      position: '',
      width: undefined,
      height: undefined,
      isSticky: false
    };
  },
  mounted: function mounted() {
    this.height = this.$el.getBoundingClientRect().height;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  activated: function activated() {
    this.handleScroll();
  },
  destroyed: function destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sticky: function sticky() {
      if (this.active) {
        return;
      }

      this.position = 'fixed';
      this.active = true;
      this.width = this.width + 'px';
      this.isSticky = true;
    },
    handleReset: function handleReset() {
      if (!this.active) {
        return;
      }

      this.reset();
    },
    reset: function reset() {
      this.position = '';
      this.width = 'auto';
      this.active = false;
      this.isSticky = false;
    },
    handleScroll: function handleScroll() {
      var width = this.$el.getBoundingClientRect().width;
      this.width = width || 'auto';
      var offsetTop = this.$el.getBoundingClientRect().top;

      if (offsetTop < this.stickyTop) {
        this.sticky();
        return;
      }

      this.handleReset();
    },
    handleResize: function handleResize() {
      if (this.isSticky) {
        this.width = this.$el.getBoundingClientRect().width + 'px';
      }
    }
  }
};
exports.default = _default;