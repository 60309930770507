"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order_task = require("@/api/order_task");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      listLoading: false,
      hisList: []
    };
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadHistoryList', function (orderId) {
      if (orderId) {
        _this.loadHistoryList(orderId);
      }
    });
  },
  methods: {
    initSelect: function initSelect() {},
    loadHistoryList: function loadHistoryList(orderId) {
      var _this2 = this;

      (0, _order_task.getHistoryList)(orderId).then(function (response) {
        console.log(response);

        if (response.data) {
          _this2.hisList = response.data;
          var indexNum = _this2.hisList.length;
          _this2.hisList = response.data.map(function (v) {
            _this2.$set(v, 'indexNum', indexNum--); // 动态加字段


            return v;
          });
        }
      });
    }
  }
};
exports.default = _default;