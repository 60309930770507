"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteBatchCheckOrder = deleteBatchCheckOrder;
exports.deleteByIds = deleteByIds;
exports.findPageList = findPageList;
exports.getCheckItemPageList = getCheckItemPageList;
exports.queryById = queryById;
exports.saveBatchCheckOrder = saveBatchCheckOrder;
exports.saveCheckOrderItem = saveCheckOrderItem;
exports.updateBatchCheckOrder = updateBatchCheckOrder;
exports.updatePicUrl = updatePicUrl;
exports.uploadCheckItem = uploadCheckItem;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/batch_check/getPageList',
    method: 'post',
    params: query
  });
}
/** 新建咨询工单*/


function saveBatchCheckOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/batch_check/save',
    method: 'post',
    data: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/batch_check/queryById',
    method: 'get',
    params: {
      id: id
    }
  });
}
/**
 * 更新批量检测单
 */


function updateBatchCheckOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/batch_check/update',
    method: 'post',
    data: data
  });
}
/**
 * 检测单详细设备列表查询
 */


function getCheckItemPageList(id) {
  return (0, _request.default)({
    url: '/bus_app/batch_check_item/getItemList',
    method: 'get',
    params: {
      id: id
    }
  });
}
/*
*删除检测单设备
*
*/


function deleteBatchCheckOrder(id) {
  return (0, _request.default)({
    url: '/bus_app/batch_check_item/deleteById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 更新图片链接
 */


function updatePicUrl(picUrl, id) {
  return (0, _request.default)({
    url: '/bus_app/batch_check_item/updatePicUrlById',
    method: 'post',
    params: {
      picUrl: picUrl,
      id: id
    }
  });
}
/*
* 手动新增设备信息
* */


function saveCheckOrderItem(data) {
  return (0, _request.default)({
    url: '/bus_app/batch_check_item/add',
    method: 'post',
    data: data
  });
}
/*
* 通过excel导入设备信息
* */


function uploadCheckItem(id, fileName) {
  return (0, _request.default)({
    url: '/bus_app/batch_check_item/uploadCheckItem',
    method: 'post',
    params: {
      id: id,
      fileName: fileName
    }
  });
}
/*
* 通过ids删除检测单
*
* */


function deleteByIds(ids) {
  return (0, _request.default)({
    url: '/bus_app/batch_check/deleteByIds',
    method: 'post',
    params: {
      ids: ids
    }
  });
}