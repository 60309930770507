"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncExport = asyncExport;
exports.sendSettleMsg = sendSettleMsg;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 创建异步导出任务
 * @param params
 * @returns {AxiosPromise}
 */
function asyncExport(params) {
  return (0, _request.default)({
    url: '/sys_app/asyncExport/export',
    method: 'post',
    params: params
  });
}
/**
 * 异步生成结算单
 * @param params
 */


function sendSettleMsg(params) {
  return (0, _request.default)({
    url: '/sys_app/asyncExport/sendSettleMsg',
    method: 'post',
    params: params
  });
}