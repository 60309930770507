var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_REPORT_WIP_EXPORT"],
                                expression: "['ROLE_REPORT_WIP_EXPORT']",
                              },
                            ],
                            attrs: {
                              size: "small",
                              type: "success",
                              plain: "",
                            },
                            on: { click: _vm.handelExport },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                border: "",
                stripe: "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                  height: "50px",
                },
                data: _vm.list,
              },
              on: { "cell-dblclick": _vm.gotoEditHtml },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  type: "selection",
                  width: "55",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: "服务单号",
                  sortable: "custom",
                  align: "center",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "link-type",
                              on: {
                                click: function ($event) {
                                  return _vm.gotoEditHtml(row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.orderNo))]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "model",
                  label: "型号",
                  resizable: "",
                  "show-overflow-tooltip": "",
                  align: "center",
                  "min-width": "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "subState",
                  label: "子状态",
                  align: "center",
                  "min-width": "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("subStateFilter")(row.subState))),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }