"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _list = require("@/api/stock/stock_in_plan/list");

var _select = require("@/api/common/select");

var _sku = require("@/api/basics/sku/sku");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// 分页组件
// 按钮波纹
var _default = {
  name: 'StockInPlanDetail',
  components: {
    Pagination: _Pagination.default,
    Sticky: _Sticky.default
  },
  directives: {
    waves: _waves.default,
    permission: _index.default
  },
  data: function data() {
    return {
      downloadLoading: false,
      id: this.$route.query.id,
      total: 0,
      // 查询条件
      listQuery: {
        page: 1,
        limit: 10,
        sapTag: '',
        modelId: '',
        sku: ''
      },
      stockInPlan: {
        warehouseCode: '',
        supplierId: '',
        memo: ''
      },
      // 添加的信息
      stockInPlanItemsInfo: {
        id: '',
        sysCode: '',
        num: '',
        parentId: this.$route.query.id,
        skuId: '',
        skuCode: '',
        price: '',
        quantity: '',
        actualQuantity: ''
      },
      rules: {
        warehouseCode: [{
          required: true,
          message: '请选择仓库',
          trigger: 'blur'
        }],
        memo: [{
          max: 200,
          message: '采购备注长度不能超过200个字',
          trigger: 'blur'
        }]
      },
      // 校验规则
      warehouseList: [],
      supplierList: [],
      modelList: [],
      // 弹出框select 型号
      skuListValidate: false,
      // 明细不能为空校验标识
      addSkudialogFormVisible: false,
      // 弹出框是否可见
      list4Sku: [],
      // 明细列表数据
      loadingList4Sku: false,
      // 明细加载
      // <状态控制> 删除关联型号按钮
      deleteBtnDisabled: true,
      // <状态控制> 添加关联型号按钮
      addBtnDisabled: true,
      loadingList4SkuSelect: false,
      // 明细的数据加载
      infoDisabled: false,
      // 主信息不可操作标识
      isTemp: false,
      // 暂存按钮不可操作标识
      isSub: false,
      // 提交按钮不可操作标识
      isCon: false,
      // 确认按钮不可操作标识
      listLoading: false,
      // 弹出框的数据加载
      skuItemsList: [],
      // 弹出框的数据
      addSnDialogFormVisible: false,
      findSnDialogFormVisible: false,
      snIn: '',
      snNumber: 0,
      snList: [],
      snSure: [],
      rowSku: {},
      snFindList: []
    };
  },
  // 初始化
  created: function created() {
    // 获取采购单详细信息
    this.getDetailInfo(); // 获取采购单明细信息

    this.getDetailItems(); // 获取下拉框数据

    this.getSupplierList();
    this.getModelList();
  },
  methods: {
    // 获取入库单详细信息
    getDetailInfo: function getDetailInfo() {
      var _this = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response != null) {
          _this.stockInPlan = response.data;

          _this.getWarehouseList();
        }
      });
    },
    // 获取采购单明细信息
    getDetailItems: function getDetailItems() {
      var _this2 = this;

      (0, _list.getItemsList)(this.id).then(function (response) {
        if (response != null && response.data != null && response.data.length > 0) {
          _this2.infoDisabled = true;
          _this2.isSub = false;
          _this2.list4Sku = response.data;
        } else {
          _this2.infoDisabled = false;
          _this2.isSub = true;
          _this2.list4Sku = [];
        }
      });
    },
    // 供应商下拉
    getSupplierList: function getSupplierList() {
      var _this3 = this;

      var param = {};
      (0, _select.getSupplierSelect)(param).then(function (response) {
        _this3.supplierList = response.data.records;
      });
    },
    // 入库仓库下拉
    getWarehouseList: function getWarehouseList() {
      var _this4 = this;

      (0, _select.getHouseSelect)(this.stockInPlan.orgId).then(function (response) {
        _this4.warehouseList = response.data;
      });
    },
    checkboxT: function checkboxT(row) {
      if (row.state === '10') {
        return false;
      } else {
        return true;
      }
    },
    // 暂存
    tempSave: function tempSave() {
      var _this5 = this;

      this.isTemp = true;
      (0, _list.tempSave)(this.stockInPlan).then(function (response) {
        if (response != null && response.data) {
          _this5.$message({
            message: '暂存成功',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/stock/stock_in_plan'
          });
        } else {
          _this5.isTemp = false;

          _this5.$message({
            showClose: true,
            message: '暂存失败！',
            type: 'warning'
          });
        }
      });
    },
    // 提交
    submitSave: function submitSave() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this6.isSub = true;
          (0, _list.submitSave)(_this6.stockInPlan).then(function (res) {
            if (res != null && res.data) {
              _this6.$message({
                message: '提交成功',
                type: 'success'
              });

              _this6.$store.dispatch('tagsView/delView', _this6.$route);

              _this6.$router.push({
                path: '/stock/stock_in_plan'
              });
            } else {
              _this6.isSub = false;

              _this6.$message({
                showClose: true,
                message: '提交失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // // 返回
    // returnBtn() {
    //   this.$router.push({ path: '/stock/stock_in_plan' })
    // },
    // 添加物料明细弹出框
    addSkuClick: function addSkuClick() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _list.tempSave)(_this7.stockInPlan).then(function (response) {
            if (response != null && response.data) {
              _this7.getDetailInfo();

              _this7.addSkudialogFormVisible = true;
            }
          });
        }
      });
    },
    // 【货品明细列表】验证方法
    validateModelList: function validateModelList() {
      if (!this.list4Sku || this.list4Sku.length === 0) {
        this.skuListValidate = true;
        return false;
      }

      return true;
    },
    // 【已关联型号列表】checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.deleteBtnDisabled = this.multipleSelection.length < 1;
    },
    // 【已关联型号列表】单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 弹出框型号下拉选择
    getModelList: function getModelList() {
      var _this8 = this;

      (0, _select.getModelSelect)().then(function (response) {
        _this8.modelList = response.data;
      });
    },
    // 弹出框查询按钮事件
    handleFilter: function handleFilter() {
      var _this9 = this;

      this.listLoading = true;
      this.listQuery.sapTag = '1';
      (0, _sku.getSkuPage)(this.listQuery).then(function (response) {
        if (response != null) {
          _this9.skuItemsList = response.data.records.map(function (v) {
            _this9.$set(v, 'realCost', v.cost2Yuan); // 设置入库成本初始为参考成本价


            _this9.$set(v, 'quantity', 1); // 设置数量初始为1


            return v;
          });
          _this9.total = response.data.total;
        }

        _this9.listLoading = false;
      });
    },
    // 弹出框按钮重置
    resetBtn: function resetBtn() {
      this.listQuery = {
        page: 1,
        limit: 10
      };
    },
    // 弹出框物料添加
    handleModifyStatus: function handleModifyStatus(row) {
      var _this10 = this;

      var tempData = Object.assign({}, this.stockInPlanItemsInfo);
      tempData.skuId = row.id;
      tempData.price = row.realCost;
      tempData.quantity = row.quantity;
      (0, _list.addItems)(tempData).then(function (response) {
        if (response != null) {
          if (response.data) {
            var index = _this10.skuItemsList.indexOf(row);

            _this10.skuItemsList.splice(index, 1);

            _this10.getDetailInfo();

            _this10.getDetailItems();

            _this10.$message({
              message: '添加成功',
              type: 'success'
            });
          } else {
            _this10.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        } else {
          _this10.$message({
            showClose: true,
            message: '添加失败',
            type: 'warning'
          });
        }
      });
    },
    // 【明细列表】删除方法
    deleteModelClick: function deleteModelClick() {
      var _this11 = this;

      this.loadingList4SkuSelect = true;

      if (this.multipleSelection.length > 0) {
        var ids = [];
        this.multipleSelection.forEach(function (v) {
          ids.push(v.id);
        });
        this.deleteBtnDisabled = true;
        (0, _list.deleteItems)(this.id, ids.join(',')).then(function (response) {
          if (response != null && response.data) {
            _this11.getDetailInfo();

            _this11.getDetailItems();

            _this11.$message({
              message: '删除成功',
              type: 'success'
            });
          } else {
            _this11.deleteBtnDisabled = false;

            _this11.$message({
              showClose: true,
              message: '删除失败',
              type: 'warning'
            });
          }
        });
      }

      this.loadingList4SkuSelect = false;
    }
  }
};
exports.default = _default;