"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 信息查询
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var queryRouter = {
  path: '/query/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'order/detail/:id',
    name: 'queryOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/query/order/detail'));
      });
    },
    meta: {
      title: '服务单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/query/order/list',
      parent: {
        path: '/query',
        redirect: 'noRedirect',
        meta: {
          title: '信息查询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'customer/detail/:id',
    name: 'queryCustomerDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order_task'));
      });
    },
    meta: {
      title: '客服工单列表',
      icon: 'table',
      hidden: true,
      activeMenu: '/order_task/summary',
      parent: {
        path: '/order_task',
        redirect: 'noRedirect',
        meta: {
          title: '客服工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'customer/task/detail/:taskId/:id',
    name: 'customerTaskDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order_task/task/detail'));
      });
    },
    meta: {
      title: '客服任务详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order_task/task',
      parent: {
        path: '/order_task',
        redirect: 'noRedirect',
        meta: {
          title: '客服工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'customer/TaskFunctionDetail/detail/:id',
    name: 'TaskFunctionDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order_task/TaskFunction/detail'));
      });
    },
    meta: {
      title: '客服任务创建详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order_task/TaskFunction',
      parent: {
        path: '/order_task/TaskFunction',
        redirect: 'noRedirect',
        meta: {
          title: '客服工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'fc/detail/:orderNo',
    name: 'FcCleanDeatail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/query/fc/detail'));
      });
    },
    meta: {
      title: '清洁服务单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/query/fc',
      parent: {
        path: '/query/fc',
        redirect: 'noRedirect',
        meta: {
          title: '清洁服务待支付订单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = queryRouter;
exports.default = _default;