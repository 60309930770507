"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _repair = require("@/api/basics/repair/repair");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 可拖拽弹出框
// 下拉框 树形结构 可检索组件
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  components: {
    treeSelect: _treeSelect.default,
    Sticky: _Sticky.default
  },
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'YES',
        0: 'NO'
      };
      return statusMap[status];
    },
    statusNameFilter: function statusNameFilter(status) {
      var statusMap = {
        1: '启用',
        0: '禁用'
      };
      return statusMap[status];
    },
    tagStatusFilter: function tagStatusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      // this.$route.params.id,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      // <状态控制> 隐藏/显示 型号选择折叠面板
      dialogFormVisible: false,
      // <状态控制> 添加关联型号按钮
      addBtnDisabled: true,
      // <状态控制> 删除关联型号按钮
      deleteBtnDisabled: true,
      // <参数验证> 关联型号列表是否存在
      modelListValidate: false,
      // <参数验证> 查询型号列表条件
      modelSelConditionValidate: false,
      // <下拉数组> 维修分类
      repairTypeSelect: [],
      // <下拉数组> 品类下拉
      categorySelect: [],
      // <下拉数组> 品牌
      brandSelect: [],
      // <下拉数组> 型号
      modelSelect: [],
      // <多选列表选中数组> 型号选择列表
      multipleSelection4ModelSel: [],
      // <多选列表选中数组> 关联型号列表
      multipleSelection: [],
      loadingList4ModelSelect: false,
      loadingList4Model: false,
      // <列表数组> 型号选择
      list4ModelSelect: null,
      // <列表数组> 关联型号
      list4Model: null,
      // <列表数组> 型号选择条件
      listQuery: {
        categoryId: undefined,
        brandId: undefined,
        id: undefined,
        categoryBrandName: '1',
        isEnable: '1'
      },
      // 入力项目 + 验证规则
      validateForm: {
        id: '',
        code: '',
        name: '',
        type: '',
        isEnable: '1',
        memo: '',
        categoryId: ''
      },
      rules: {
        code: [{
          required: true,
          message: '维修代码编码是必填的',
          trigger: 'blur'
        }, {
          max: 64,
          message: '最大长度 64 个字符',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '维修代码名称是必填的',
          trigger: 'blur'
        }, {
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '维修代码分类是必填的',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '品类是必选的',
          trigger: 'blur'
        }],
        memo: [{
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }]
      },
      tableData1: [],
      accordion: false,
      cTree: true
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    }
  },
  created: function created() {
    this.getSelect();

    if (!this.isCreate) {
      this.getRepairInfo();
    }
  },
  methods: {
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value) {
      this.validateForm.categoryId = value;
    },
    // 获取维修代码详细
    getRepairInfo: function getRepairInfo() {
      var _this = this;

      (0, _repair.queryById)(this.id).then(function (response) {
        if (response.data) {
          // console.log(response.data)
          _this.validateForm = response.data;
        }
      });
    },
    // 【已关联型号列表】验证方法
    validateModelList: function validateModelList() {
      if (!this.list4Model || this.list4Model.length === 0) {
        this.modelListValidate = true;
        return false;
      }

      return true;
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _repair.saveData)(_this2.validateForm).then(function (response) {
            if (response.data) {
              _this2.$message({
                showClose: true,
                message: '维修代码（' + _this2.validateForm.code + ') 新增成功！',
                type: 'success'
              });

              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                path: '/basics/repair/list'
              });
            } else {
              _this2.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 编辑确认
    updateSubmit: function updateSubmit() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _repair.saveData)(_this3.validateForm).then(function (response) {
            if (response.data) {
              _this3.$message({
                showClose: true,
                message: '维修代码（' + _this3.validateForm.code + ') 修改成功！',
                type: 'success'
              });

              _this3.$store.dispatch('tagsView/delView', _this3.$route);

              _this3.$router.push({
                path: '/basics/repair/list'
              });
            } else {
              _this3.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/repair/list'
      });
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this4 = this;

      // 维修分类下拉
      var params = {};

      if (!this.id) {
        params.isEnable = '1';
      }

      (0, _select.getCodeTypeSelect)('repair', params).then(function (res) {
        _this4.repairTypeSelect = res.data.records;
      }); // 品类下拉

      (0, _select.getCategorySelect)(params).then(function (res) {
        _this4.categorySelect = res.data.records;
      }); // 品牌下拉

      (0, _select.getBrandSelect)(params).then(function (res) {
        _this4.brandSelect = res.data.records;
      }); // 型号下拉

      this.loadModelSelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this5 = this;

      var params = {
        categoryId: this.listQuery.categoryId,
        brandId: this.listQuery.brandId
      };

      if (!this.id) {
        params.isEnable = '1';
      }

      (0, _select.getModelSelect)(params).then(function (res) {
        _this5.modelSelect = res.data;
      });
    },
    // 品类下拉联动清空 型号列表
    categorySelectChange: function categorySelectChange() {
      this.listQuery.modelId = '';
      this.modelSelect = [];
    },
    // 品牌下拉联动清空 型号列表
    brandSelectChange: function brandSelectChange() {
      this.listQuery.modelId = '';
      this.modelSelect = [];
    },
    // 【已关联型号列表】删除方法
    deleteModelClick: function deleteModelClick() {
      this.loadingList4ModelSelect = true;

      if (this.multipleSelection.length > 0) {
        var modelIds4Selects = [];
        this.multipleSelection.forEach(function (v) {
          modelIds4Selects.push(v.modelId);
        });
        var list4ModelSelectCopy = JSON.parse(JSON.stringify(this.list4Model));
        var newList4Model = [];

        for (var i = 0; i < list4ModelSelectCopy.length; i++) {
          if (modelIds4Selects.indexOf(list4ModelSelectCopy[i].modelId) === -1) {
            newList4Model.push(list4ModelSelectCopy[i]);
          }
        }

        this.list4Model = newList4Model;
      }

      this.loadingList4ModelSelect = false;
    },
    // 【型号选择】弹框切换隐藏显示处理
    addModelListSelectSwitch: function addModelListSelectSwitch() {
      this.listQuery = {
        categoryBrandName: '1',
        isEnable: '1'
      };
      this.modelSelConditionValidate = false;
      this.modelListValidate = false;
      this.dialogFormVisible = !this.dialogFormVisible;
      this.list4ModelSelect = [];
    },
    // 【型号选择】重置按钮事件
    cleanBtnClick4ModelSel: function cleanBtnClick4ModelSel() {
      this.listQuery = {
        categoryBrandName: '1',
        isEnable: '1'
      };
      this.modelSelConditionValidate = false;
      this.list4ModelSelect = [];
    },
    // 【型号选择】查询按钮事件
    searchBtnClick4ModelSel: function searchBtnClick4ModelSel() {
      // 验证【型号选择列表】查询条件
      if (this.listQuery.id || this.listQuery.brandId || this.listQuery.categoryId) {
        this.modelSelConditionValidate = false;
        this.getList4ModelSel();
      } else {
        this.modelSelConditionValidate = true;
      }
    },
    // 查询型号列表
    getList4ModelSel: function getList4ModelSel() {
      var _this6 = this;

      this.loadingList4Model = true;
      (0, _select.getModelSelect)(this.listQuery).then(function (response) {
        _this6.list4ModelSelect = response.data;
        _this6.loadingList4Model = false;
      });
    },
    // 【型号选择列表】 单击一行，则切换该行的checkBox选中状态
    rowClickSelection4ModelSelect: function rowClickSelection4ModelSelect(row, column, event) {
      this.$refs.multipleTable4ModelSelect.toggleRowSelection(row);
    },
    // 【型号选择列表】checkbox选中影响【添加】按钮状态
    handleSelectionChange4ModelSelect: function handleSelectionChange4ModelSelect(val) {
      this.multipleSelection4ModelSel = val;

      if (this.multipleSelection4ModelSel.length >= 1) {
        this.addBtnDisabled = false;
      } else {
        this.addBtnDisabled = true;
      }
    },
    // 【型号选择列表】将选中的型号添加入【关联型号列表】
    addModelSelectBtnClick: function addModelSelectBtnClick() {
      this.loadingList4Model = true;

      if (this.multipleSelection4ModelSel.length >= 1) {
        var addModelSels = [];
        this.multipleSelection4ModelSel.map(function (value, index, arry) {
          addModelSels.push({
            'modelId': value.id,
            'modelCode': value.code,
            'modelName': value.name,
            'categoryName': value.categoryName,
            'brandName': value.brandName
          });
        });
        var list4ModelCopy = JSON.parse(JSON.stringify(this.list4Model)); // 合并数组

        var newList4ModelCopy = list4ModelCopy ? list4ModelCopy.concat(addModelSels) : addModelSels; // 数组根据主键modelId去掉重复项

        var temp = {};
        var newList4Model = [];

        for (var i = 0; i < newList4ModelCopy.length; i++) {
          if (!temp[newList4ModelCopy[i].modelId]) {
            newList4Model.push(newList4ModelCopy[i]);
            temp[newList4ModelCopy[i].modelId] = true;
          }
        } // 已关联型号数组渲染赋值


        this.list4Model = newList4Model; // 隐藏弹出框

        this.addModelListSelectSwitch();
      }

      this.loadingList4Model = false;
    },
    // 【已关联型号列表】单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 【已关联型号列表】checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;

      if (this.multipleSelection.length >= 1) {
        this.deleteBtnDisabled = false;
      } else {
        this.deleteBtnDisabled = true;
      }
    }
  }
};
exports.default = _default;