"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/register/constant"));

var _productRegisterFail = require("@/api/register/productRegisterFail");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _productChannel = require("@/api/register/productChannel");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SnRegisterFailDetail',
  components: {
    Sticky: _Sticky.default
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      productRegisterFailInfo: {},
      btnLoading: false,
      listLoading: false,
      total: 0,
      status: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      msgOptions: [{
        'val': '01',
        'name': 'Prefix校验不通过'
      }, {
        'val': '02',
        'name': '已注册，无需再次注册'
      }, {
        'val': '03',
        'name': '产品已销毁'
      }, {
        'val': '04',
        'name': '产品已退回'
      }, {
        'val': '05',
        'name': '注册次数超上限'
      }, {
        'val': '06',
        'name': '大仓未进口入库'
      }, {
        'val': '07',
        'name': '产品已锁定'
      }, {
        'val': '08',
        'name': '生产日期解析异常'
      }],
      dialogVisible: false,
      options: [{
        value: 'channel1',
        label: '渠道1'
      }, {
        value: 'channel2',
        label: '渠道2'
      }],
      channelCode: '',
      registerDisabled: false,
      registerSourceOptions: _constant.default.registerSourceOptions
    };
  },
  computed: {
    buttonDisabled: function buttonDisabled() {
      if (this.productRegisterFailInfo) {
        return !(this.productRegisterFailInfo.state === '0' && (this.productRegisterFailInfo.errMsg === '01' || this.productRegisterFailInfo.errMsg === '05' || this.productRegisterFailInfo.errMsg === '06' || this.productRegisterFailInfo.errMsg === '07'));
      }

      return false;
    }
  },
  watch: {},
  created: function created() {
    this.getDetail();
    this.getChannelSelect();
  },
  mounted: function mounted() {},
  methods: {
    // 初始查询详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _productRegisterFail.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.productRegisterFailInfo = response.data;
        }
      });
    },
    permitRegister: function permitRegister() {
      this.dialogVisible = true;
      this.registerDisabled = false;
    },
    // 允许注册
    allowRegister: function allowRegister() {
      var _this2 = this;

      (0, _productRegisterFail.changeState)(this.id, 1, this.channelCode).then(function (response) {
        if (response && response.code === 200) {
          _this2.$message.success(response.msg || '操作成功！');

          _this2.registerDisabled = false;

          _this2.$router.push({
            path: '/register/sn_register_fail'
          });
        } else {
          _this2.$message.error(response.msg || '操作失败！');

          _this2.registerDisabled = false;
        }

        _this2.getDetail();

        _this2.dialogVisible = false;
      });
    },
    // 拒绝注册
    refuseRegister: function refuseRegister() {
      var _this3 = this;

      (0, _productRegisterFail.changeState)(this.id, 2).then(function (response) {
        if (response && response.code === 200) {
          _this3.$message.success('操作成功！');

          _this3.status = true;

          _this3.$router.push({
            path: '/register/sn_register_fail'
          });
        } else {
          _this3.$message.error('操作失败！');
        }

        _this3.getDetail();
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/register/sn_register_fail'
      });
    },
    // 获得channel选择
    getChannelSelect: function getChannelSelect() {
      var _this4 = this;

      // 大仓未出库，需要手动进行导入渠道信息
      (0, _productChannel.getChannelSelect)().then(function (response) {
        if (response.data) {
          _this4.options = response.data.map(function (item) {
            return {
              value: item.channelCode,
              label: item.channelCode + '-' + item.channelName
            };
          });
        } else {
          _this4.options = [];
        }
      });
    }
  }
};
exports.default = _default;