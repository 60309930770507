"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _select = require("@/api/common/select");

var _organization = require("@/api/organization");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getSapSysCodeList } from '@/api/logistics/buyout'
var _default = {
  // name: 'orgDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  data: function data() {
    var _this = this;

    var validate = function validate(rule, value, callback) {
      // 后台方法
      if (_this.dialogStatus === 'create') {
        (0, _organization.validateCode)(value).then(function (res) {
          if (res && res.data === true) {
            callback();
          } else if (res && res.data === false) {
            callback('编码已存在');
          }
        });
      } else {
        callback();
      }
    };

    return {
      msg: 'vue模板页',
      typesOptionsList: [],
      customerNumList: [],
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      downloadLoading: false,
      dialogStatus: this.$route.params.dialogStatus,
      // this.$route.params.dialogStatus,
      id: this.$route.params.id,
      // this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      textMap: {
        update: '编辑',
        create: '新建'
      },
      listQuery: {
        page: 1,
        limit: 10,
        code: '',
        name: '',
        isEnabled: '',
        type: '',
        isAsc: '1',
        orderByField: '1'
      },
      OrganizationInfo: {
        memo: '',
        parentId: '',
        name: '',
        code: '',
        orgType: '1',
        type: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        address: '',
        linkMan: '',
        linkTel: '',
        mobilePhone: '',
        fax: '',
        email: '',
        contractCode: '',
        contractName: '',
        compName: '',
        taxNum: '',
        openBank: '',
        bankAcc: '',
        fileUrl: '',
        isEnable: '1',
        disabledReason: '',
        disabledInfo: '',
        feeType: ''
      },
      // 添加的信息
      orgTypesOptions: [{
        label: '1',
        value: '1'
      }, {
        label: '2',
        value: '2'
      }, {
        label: '3',
        value: '3'
      }, {
        label: '4',
        value: '4'
      }],
      typesOptions: [{
        label: '一级网点',
        value: '10'
      }],
      typesOptions1: [{
        label: '二级网点',
        value: '20'
      }],
      statusOptions: [{
        label: '启用',
        value: true
      }, {
        label: '禁用',
        value: false
      }],
      isEnablesOptions: [{
        label: '启用',
        value: '1'
      }, {
        label: '禁用',
        value: '2'
      }],
      rules: {
        parentId: [{
          required: true,
          message: '上级网点必选',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '机构编码是必填的',
          trigger: 'blur'
        }, {
          min: 4,
          message: '长度4~10之间',
          trigger: 'blur'
        }, {
          max: 10,
          message: '长度4~10之间',
          trigger: 'blur'
        }, {
          validator: validate,
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '机构名称是必填的',
          trigger: 'blur'
        }, {
          max: 100,
          message: '长度不能超过100 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '网点类型必选',
          trigger: 'blur'
        }],
        feeType: [{
          required: true,
          message: '服务费级别必选',
          trigger: 'blur'
        }],
        provinceId: [{
          required: true,
          message: '省份必选',
          trigger: 'blur'
        }],
        cityId: [{
          required: true,
          message: '市必选',
          trigger: 'blur'
        }],
        districtId: [{
          required: true,
          message: '区/县必选',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '详细地址必填',
          trigger: 'blur'
        }],
        linkMan: [{
          required: true,
          message: '联系人必填',
          trigger: 'blur'
        }],
        mobilePhone: [{
          required: true,
          message: '手机号码必填',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        linkTel: [{
          required: true,
          message: '联系电话必填',
          trigger: 'blur'
        }, {
          pattern: /(^(\d{3,4}-)?\d{7,8})$|(^0?(13[0-9]|15[012356789]|18[0-9]|14[57])[0-9]{8})$/,
          message: '请输入正确的电话',
          trigger: 'blur,change'
        }],
        email: [{
          type: 'email',
          message: '请输入正确的E-mail',
          trigger: 'blur,change'
        }],
        memo: [{
          max: 200,
          message: '长度不能超过200 个字符',
          trigger: 'blur'
        }]
      },
      // 校验规则
      serveTypeName: [],
      // 详细里选择的服务内容
      organizationList: [],
      disableVisible: false,
      // 上传图片部分
      dialogImageUrl: '',
      dialogVisible: false,
      imgList: [],
      isMultiple: true,
      imgLimit: 5,
      sizeLimit: 10,
      uploadAction: '/uploadsever/s3/uploadFile'
    };
  },
  // 初始化
  created: function created() {
    // 拿取上级网点
    this.getOrganizationList(); // 加载省份列表

    this.getProvinceOptions(); // 客户代码
    // this.getSapSysCodeList('Customernum')

    if (!this.isCreate) {
      this.getOrganizationInfo();
    }
  },
  methods: {
    // 初始查后台 拿取 机构信息
    getOrganizationInfo: function getOrganizationInfo() {
      var _this2 = this;

      // console.log(this.id)
      (0, _organization.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this2.OrganizationInfo = response.data; // 服务内容 多选下拉

          if (_this2.OrganizationInfo.serveType != null) {
            _this2.serveTypeName = _this2.OrganizationInfo.serveType.split(',');
          } // 禁用、启用 显示原因


          if (_this2.OrganizationInfo.isEnable === '0') {
            _this2.disableVisible = true;
          } // 城市回显


          if (_this2.OrganizationInfo.provinceId != null && _this2.OrganizationInfo.provinceId !== '') {
            (0, _select.getAreasByParentId)(_this2.OrganizationInfo.provinceId).then(function (response) {
              _this2.cityOptions = response.data;
            });
          } // 区/县  回显


          if (_this2.OrganizationInfo.cityId != null && _this2.OrganizationInfo.cityId !== '') {
            (0, _select.getAreasByParentId)(_this2.OrganizationInfo.cityId).then(function (response) {
              _this2.countryOptions = response.data;
            });
          } // 图片 回显


          if (_this2.OrganizationInfo.filePaths !== null) {
            for (var i = 0; i < _this2.OrganizationInfo.filePaths.length; i++) {
              var obj = {};
              obj.name = i;
              obj.url = _this2.OrganizationInfo.filePaths[i];

              _this2.imgList.push(obj);
            }
          } else {
            _this2.OrganizationInfo.filePaths = [];
          }

          if (_this2.OrganizationInfo.grade === '2') {
            _this2.typesOptionsList = _this2.typesOptions;
          } else if (_this2.OrganizationInfo.grade === '3') {
            _this2.typesOptionsList = _this2.typesOptions1;
          } else {
            _this2.typesOptionsList = [];
          }

          _this2.$nextTick(function () {
            _this2.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 添加页，拿取上级机构
    getOrganizationList: function getOrganizationList() {
      var _this3 = this;

      (0, _organization.getParentOrgList)(this.listQuery).then(function (response) {
        _this3.organizationList = response.data;
      });
    },
    changeOrgType: function changeOrgType(val) {
      var _this4 = this;

      // console.log(val)
      var parId = val;
      this.OrganizationInfo.type = ''; // console.log(this.OrganizationInfo.type)

      (0, _organization.queryById)(parId).then(function (response) {
        // console.log(response.data)
        if (response.data) {
          // console.log(response.data.grade)
          if (response.data.grade === '1') {
            _this4.typesOptionsList = _this4.typesOptions;
          } else if (response.data.grade === '2') {
            _this4.typesOptionsList = _this4.typesOptions1;
          } else {
            _this4.typesOptionsList = [];
          }
        }
      });
    },
    getProvinceOptions: function getProvinceOptions() {
      var _this5 = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this5.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this6 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this6.cityOptions = response.data;
        });
      }

      this.OrganizationInfo.cityId = '';
      this.OrganizationInfo.districtId = '';
    },
    cityChange: function cityChange(city) {
      var _this7 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this7.countryOptions = response.data;
        });
      }

      this.OrganizationInfo.districtId = '';
    },
    // 新建 保存数据
    createData: function createData() {
      var _this8 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this8.$confirm('确定要提交表单吗？').then(function (_) {
            (0, _organization.createOrganization)(_this8.OrganizationInfo).then(function (response) {
              if (response.data) {
                _this8.$message({
                  message: '新建成功',
                  type: 'success'
                });

                _this8.$store.dispatch('tagsView/delView', _this8.$route);

                _this8.$router.push({
                  path: '/sys/organization'
                });
              } else {
                _this8.$message({
                  message: '新建失败',
                  type: 'warning'
                });
              }
            });
          }).catch(function (_) {});
        }
      });
    },
    // 编辑保存数据
    updateData: function updateData() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this9.OrganizationInfo);
          tempData.timestamp = +new Date(tempData.timestamp); // change Thu Nov 30 2017 16:41:05 GMT+0800 (CST) to 1512031311464

          (0, _organization.updateOrganization)(tempData).then(function (response) {
            if (response.data) {
              _this9.$message({
                message: '更新成功',
                type: 'success'
              });

              _this9.$store.dispatch('tagsView/delView', _this9.$route);

              _this9.$router.push({
                path: '/sys/organization'
              });
            } else {
              _this9.$message({
                message: '更新失败',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.replace({ path: '/sys/organization' })
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/sys/organization'
      });
    },
    // 禁用启用，禁用控制禁用信息的填写
    enableChange: function enableChange(isEnable) {
      this.disableVisible = isEnable !== '1';
    },
    // 上传图片部分
    // 移除图片
    handleRemove: function handleRemove(file, fileList) {
      this.OrganizationInfo.filePaths = [];

      for (var i = 0; i < fileList.length; i++) {
        if (this.dialogStatus === 'update') {
          this.OrganizationInfo.filePaths.push(fileList[i].url);
        } else {
          this.OrganizationInfo.filePaths.push(process.env.VUE_APP_S3_STORAGE_URL + fileList[i].response.data);
        }
      } // console.log(this.OrganizationInfo.filePaths)

    },
    // 预览图片时调用
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传之前调用做一些拦截限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // console.log(process.env.VUE_APP_UPLOAD_SEVER_URL)
      var isJPG = true;
      var sizeLimit = file.size / 1024 / 1024 < this.sizeLimit; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }

      if (!sizeLimit) {
        this.$message.error('上传图片大小不能超过' + this.sizeLimit + 'MB!');
      }

      return isJPG && sizeLimit;
    },
    // 图片上传成功
    handleAvatarSuccess: function handleAvatarSuccess(res, file, fileList) {
      if (res.code !== 'success') {
        this.imgUploadError();
      } else {
        this.OrganizationInfo.filePaths.push(process.env.VUE_APP_S3_STORAGE_URL + res.data);
      }
    },
    // 图片上传失败
    imgUploadError: function imgUploadError() {
      this.$message.error('上传图片失败!');
    },
    // 图片上传超过数量限制
    handleExceed: function handleExceed() {
      this.$message.error('上传图片不能超过' + this.imgLimit + '张!');
    }
  }
};
exports.default = _default;