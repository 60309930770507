var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "sticky",
            { attrs: { "class-name": "sub-navbar default" } },
            [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple",
                  staticStyle: { float: "left", "padding-left": "1.3rem" },
                },
                [
                  _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                    _vm._v("仓库管理详细"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: [
                        "ROLE_BASE_PRD_WAREHOUSE_ADD",
                        "ROLE_BASE_PRD_WAREHOUSE_MODIFY",
                      ],
                      expression:
                        "['ROLE_BASE_PRD_WAREHOUSE_ADD','ROLE_BASE_PRD_WAREHOUSE_MODIFY']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.create()
                        : _vm.update()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-folder" }),
                  _vm._v("\n        保存\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { type: "info" },
                  on: { click: _vm.returnBtn },
                },
                [
                  _c("i", { staticClass: "el-icon-back" }),
                  _vm._v("\n        返回\n      "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-12" },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card", attrs: { shadow: "never" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("基本信息")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form",
                              {
                                ref: "dataForm",
                                attrs: {
                                  rules: _vm.rules,
                                  model: _vm.warehouseInfo,
                                  "label-width": "150px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "仓库名称", prop: "name" },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.warehouseInfo.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.warehouseInfo,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "warehouseInfo.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "仓库分类", prop: "type" },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.warehouseInfo.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.warehouseInfo,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "warehouseInfo.type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.typesOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注", prop: "memo" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "textarea", rows: 4 },
                                          model: {
                                            value: _vm.warehouseInfo.memo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.warehouseInfo,
                                                "memo",
                                                $$v
                                              )
                                            },
                                            expression: "warehouseInfo.memo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "el-col el-col-12" },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card", attrs: { shadow: "never" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", [
                          _c("span", { staticClass: "orgList__must" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("关联机构"),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.orgListValidate,
                                  expression: "orgListValidate",
                                },
                              ],
                              staticClass: "orgList__error",
                            },
                            [_vm._v(" 请添加关联机构 ！")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button-group",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-top": "-8px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: [
                                      "ROLE_BASE_PRD_WAREHOUSE_ADD",
                                      "ROLE_BASE_PRD_WAREHOUSE_MODIFY",
                                    ],
                                    expression:
                                      "['ROLE_BASE_PRD_WAREHOUSE_ADD','ROLE_BASE_PRD_WAREHOUSE_MODIFY']",
                                  },
                                ],
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.addOrg2Warehouse },
                              },
                              [_vm._v("添加机构")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "20px",
                          "padding-bottom": "20px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              data: _vm.list,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "code", label: "机构编码" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "机构名称",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                "class-name": "status-col",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      row.isEnable == "1"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("启用")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v(" 禁用 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                align: "center",
                                width: "230",
                                "class-name": "small-padding fixed-width",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return row.status != "0"
                                        ? [
                                            row.isEnable === "1"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "permission",
                                                        rawName: "v-permission",
                                                        value: [
                                                          "ROLE_BASE_PRD_WAREHOUSE_ADD",
                                                          "ROLE_BASE_PRD_WAREHOUSE_MODIFY",
                                                        ],
                                                        expression:
                                                          "['ROLE_BASE_PRD_WAREHOUSE_ADD','ROLE_BASE_PRD_WAREHOUSE_MODIFY']",
                                                      },
                                                    ],
                                                    attrs: {
                                                      plain: "",
                                                      size: "mini",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleModifyStatus(
                                                          row,
                                                          "false"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    禁用\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "permission",
                                                        rawName: "v-permission",
                                                        value: [
                                                          "ROLE_BASE_PRD_WAREHOUSE_ADD",
                                                          "ROLE_BASE_PRD_WAREHOUSE_MODIFY",
                                                        ],
                                                        expression:
                                                          "['ROLE_BASE_PRD_WAREHOUSE_ADD','ROLE_BASE_PRD_WAREHOUSE_MODIFY']",
                                                      },
                                                    ],
                                                    attrs: {
                                                      size: "mini",
                                                      plain: "",
                                                      type: "success",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleModifyStatus(
                                                          row,
                                                          "true"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    启用\n                  "
                                                    ),
                                                  ]
                                                ),
                                            _vm._v(" "),
                                            row.isDelete
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "permission",
                                                        rawName: "v-permission",
                                                        value: [
                                                          "ROLE_BASE_PRD_WAREHOUSE_ADD",
                                                          "ROLE_BASE_PRD_WAREHOUSE_MODIFY",
                                                        ],
                                                        expression:
                                                          "['ROLE_BASE_PRD_WAREHOUSE_ADD','ROLE_BASE_PRD_WAREHOUSE_MODIFY']",
                                                      },
                                                    ],
                                                    attrs: {
                                                      plain: "",
                                                      size: "mini",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleModifyStatus(
                                                          row,
                                                          "deleted"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    删除\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加机构", visible: _vm.addOrgdialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.addOrgdialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机构名称", prop: "code" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                model: {
                                  value: _vm.listQuery.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "name", $$v)
                                  },
                                  expression: "listQuery.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级机构", prop: "name" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "" },
                                  on: { focus: _vm.getParentOrgList },
                                  model: {
                                    value: _vm.listQuery.parentId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "parentId", $$v)
                                    },
                                    expression: "listQuery.parentId",
                                  },
                                },
                                _vm._l(_vm.parentOrgList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.resetBtn },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "listTableDiv" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableKey",
                  ref: "orgmultipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark",
                    data: _vm.organizationList,
                    "highlight-current-row": "",
                  },
                  on: {
                    "selection-change": _vm.orgDialogSelectionChange,
                    "row-click": _vm.rowClickSelection,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "机构名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "orgName", label: "上级机构" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.addOrgdialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addOrgClick } },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }