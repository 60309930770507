"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 线下门店退换记录
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var offlineRouter = {
  path: '/offline/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/offline/detail/:isQuery/:id',
    name: 'offlineStoreReturnDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offline/offline_store_return/detail'));
      });
    },
    meta: {
      title: '门店退换记录详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/offline/offline_store_return',
      parent: {
        path: '/offline',
        redirect: 'noRedirect',
        meta: {
          title: '线下工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'batch_check/:id',
    name: 'batchCheckCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offline/batch_check/detail'));
      });
    },
    meta: {
      title: '批量检测单创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/offline/batch_check/list',
      parent: {
        path: '/offline',
        redirect: 'noRedirect',
        meta: {
          title: '线下工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'batch_check/detail/:id',
    name: 'batchCheckDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offline/batch_check/detail'));
      });
    },
    meta: {
      title: '批量检测单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/offline/batch_check/list',
      parent: {
        path: '/offline',
        redirect: 'noRedirect',
        meta: {
          title: '线下工单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = offlineRouter;
exports.default = _default;