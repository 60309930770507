"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createConfig = createConfig;
exports.findPageList = findPageList;
exports.queryById = queryById;
exports.updateConfigStatus = updateConfigStatus;
exports.validateConfig = validateConfig;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/equity_extend_config/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新建保存
 * @param data
 */


function createConfig(data) {
  return (0, _request.default)({
    url: '/product_app/equity_extend_config/create',
    method: 'post',
    data: data
  });
}
/**
 * 编辑
 * @param data
 */


function updateConfigStatus(data) {
  return (0, _request.default)({
    url: '/product_app/equity_extend_config/update',
    method: 'post',
    data: data
  });
}
/**
 * 校验延保配置
 * @param data
 */


function validateConfig(data) {
  return (0, _request.default)({
    url: '/product_app/equity_extend_config/validateConfig',
    method: 'post',
    data: data
  });
}
/**
 * 获取延保配置
 * @param data
 */


function queryById(data) {
  return (0, _request.default)({
    url: '/product_app/equity_extend_config/queryById',
    method: 'post',
    params: {
      id: data
    }
  });
}