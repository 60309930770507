"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAreaInfoTree = getAreaInfoTree;
exports.getAreasByParentId = getAreasByParentId;
exports.getBaseOrderTypeSelect = getBaseOrderTypeSelect;
exports.getBrandSelect = getBrandSelect;
exports.getCategorySelect = getCategorySelect;
exports.getChildsById = getChildsById;
exports.getCodeTypeSelect = getCodeTypeSelect;
exports.getFaultSelect = getFaultSelect;
exports.getGroupSelect = getGroupSelect;
exports.getHouseAllList = getHouseAllList;
exports.getHouseSelect = getHouseSelect;
exports.getHouseSelectByType = getHouseSelectByType;
exports.getMemberList = getMemberList;
exports.getModeBySku = getModeBySku;
exports.getModelSelect = getModelSelect;
exports.getModelSelectByCategoryId = getModelSelectByCategoryId;
exports.getModelSelectSale = getModelSelectSale;
exports.getOrgSubList = getOrgSubList;
exports.getRepairSelect = getRepairSelect;
exports.getRepairSelectByCategory = getRepairSelectByCategory;
exports.getSkuFeeSelect = getSkuFeeSelect;
exports.getSkuSelect = getSkuSelect;
exports.getSkuSelectByParams = getSkuSelectByParams;
exports.getSkuSelectSale = getSkuSelectSale;
exports.getSkuSelectTrue = getSkuSelectTrue;
exports.getUserModelSelect = getUserModelSelect;
exports.querySuperOrg = querySuperOrg;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 省市区级联
 * @param grade {1:省份；2：省市2级；3：省市区3级} 必须
 */
function getAreaInfoTree(grade) {
  return (0, _request.default)({
    url: '/bus_app/area/' + grade + '/getAreaInfoTree',
    method: 'post',
    params: {}
  });
}
/**
 * 已知上级地区ID获取直属下级地区
 * @param parentId {0:获取省份地区，上级地区ID} 必须
 */


function getAreasByParentId(parentId) {
  return (0, _request.default)({
    url: '/bus_app/area/' + parentId + '/getAreasByParentId',
    method: 'post',
    params: {}
  });
}
/**
 * 品类
 * @param params {id:xxx, isEnable:yyy} 非必须
 */


function getCategorySelect(params) {
  return (0, _request.default)({
    url: '/bus_app/category/getCategorySelect',
    method: 'post',
    params: params
  });
}

function getChildsById(params) {
  return (0, _request.default)({
    url: '/bus_app/category/getChildsById',
    method: 'post',
    params: params
  });
}
/**
 * 品牌
 * @param params {id:xxx, isEnable:yyy} 非必须
 */


function getBrandSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/brand/getBrandSelect',
    method: 'post',
    params: params
  });
}
/**
 * 型号
 * @param params {id:xxx, isEnable:yyy, categoryId:zzz, brandId:mmm} 非必须
 */


function getModelSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/base_model/getModelSelect',
    method: 'post',
    params: params
  });
}
/**
 * 故障分类/维修分类
 * @param type （repair : 维修分类 | fault : 故障分类）必须
 * @param params {id:xxx, isEnable:yyy} 非必须
  * @param params {model:xxx} 【型号】非必须
 */


function getCodeTypeSelect(type, params) {
  return (0, _request.default)({
    url: '/bus_app/code/' + type + '/getCodeTypeSelect',
    method: 'post',
    params: params
  });
}
/**
 * 故障原因
 * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getFaultSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/fault/getFaultSelect',
    method: 'post',
    params: params
  });
}
/**
 * 维修代码
 * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getRepairSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/repair/getRepairSelect',
    method: 'post',
    params: params
  });
}

function getRepairSelectByCategory(params) {
  return (0, _request.default)({
    url: '/bus_app/repair/getRepairSelectByCategory',
    method: 'post',
    params: params
  });
}
/**
 * 获取SKU列表
  * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getSkuSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/sku/getSkuSelect',
    method: 'post',
    params: params
  });
}
/**
 * 获取SKU列表  正确的ddir
  * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getSkuSelectTrue(params) {
  return (0, _request.default)({
    url: '/bus_app/sku/getSkuSelectTrue',
    method: 'post',
    params: params
  });
}
/**
 * 获取【成品SKU】品类型号信息
  * @param params {code:xxx} 必须
 */


function getModeBySku(params) {
  return (0, _request.default)({
    url: '/bus_app/sku/getModeBySku',
    method: 'post',
    params: params
  });
}
/**
 * 获取服务SKU列表
  * @param params {id:xxx, isEnable:yyy} 非必须
 */


function getSkuFeeSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/skuFee/getSkuFeeSelect',
    method: 'post',
    params: params
  });
}
/**
 * 机构仓库
 * @param params {orgId:xxx} 非必须
 */


function getHouseSelect(orgId) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/getHoseList',
    method: 'post',
    params: {
      orgId: orgId
    }
  });
}
/**
 * 所有仓库，与机构无关
 */


function getHouseAllList() {
  return (0, _request.default)({
    url: '/bus_app/warehouse/getHoseAllList',
    method: 'post',
    params: {}
  });
}
/**
 * 查询【本机构+本机构的直属下属机构】集合
 * @param orgId 机构ID
 * @param isIncludeSelf 是否包含自身机构 {1：是；0：否}
 */


function getOrgSubList(params) {
  return (0, _request.default)({
    url: '/acc_app/organization/getOrgSubList',
    method: 'get',
    params: params
  });
}
/**
 * 查询【本机构的直属上级】 Entity
 * @param orgId 机构ID
 * @param isIncludeSelf 没有上级机构是否返回自身机构 {1：是；0：否}
 */


function querySuperOrg(params) {
  return (0, _request.default)({
    url: '/acc_app/organization/querySuperOrg',
    method: 'get',
    params: params
  });
}
/**
 * 获取用户列表
 *  @param  orgId 所属机构ID 非必须
 *  @param  type  用户类型   非必须
 */


function getMemberList(params) {
  return (0, _request.default)({
    url: '/acc_app/user/getMemberList',
    method: 'post',
    params: params
  });
}
/**
 *  根据 仓库类型
 * @param param
 */


function getHouseSelectByType(param) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/getHoseListByType',
    method: 'post',
    params: param
  });
}
/**
 * 高级权限（用户关联型号）select型号列表
 * @param params {id:xxx, isEnable:yyy, categoryId:zzz, brandId:mmm} 非必须
 */


function getUserModelSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/base_model/getUserModelSelect',
    method: 'post',
    params: params
  });
}
/**
 * 获取所有工单类型
 * @param params {id:xxx, isEnable:yyy} 非必须
 */


function getBaseOrderTypeSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/base_order_type/getBaseOrderTypeSelect',
    method: 'post',
    params: params
  });
}
/**
 * 获取所有客服小组
 * @param params {id:xxx, isEnable:yyy} 非必须
 */


function getGroupSelect(params) {
  return (0, _request.default)({
    url: '/bus_app/base_group/getBaseGroupSelect',
    method: 'post',
    params: params
  });
}
/**
 * 获取SKU列表
 * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getSkuSelectSale(params) {
  return (0, _request.default)({
    url: '/bus_app/sku/getSkuSelectSale',
    method: 'post',
    params: params
  });
}
/**
 * 型号
 * @param params {id:xxx, isEnable:yyy, categoryId:zzz, brandId:mmm} 非必须
 */


function getModelSelectSale(params) {
  return (0, _request.default)({
    url: '/bus_app/base_model/getModelSelectSale',
    method: 'post',
    params: params
  });
}
/**
 * 获取SKU列表
 * @param params {id:xxx, isEnable:yyy, modelId} 非必须
 */


function getSkuSelectByParams(params) {
  return (0, _request.default)({
    url: '/bus_app/sku/getSkuSelectByParams',
    method: 'post',
    params: params
  });
}
/**
 * 型号
 * @param params {id:xxx, isEnable:yyy, categoryId:zzz, brandId:mmm} 非必须
 */


function getModelSelectByCategoryId(params) {
  return (0, _request.default)({
    url: '/bus_app/base_model/getModelSelectByCategoryId',
    method: 'post',
    params: params
  });
}