var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("标签创建"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_LABEL_ADD"],
                  expression: "['ROLE_BASE_LABEL_ADD']",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.saveSumbit },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.returnBtn } }, [_vm._v("返回")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.label,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "标签类型", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.label.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.label, "type", $$v)
                                  },
                                  expression: "label.type",
                                },
                              },
                              _vm._l(_vm.labelType, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "标签级别", prop: "level" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.label.level,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.label, "level", $$v)
                                  },
                                  expression: "label.level",
                                },
                              },
                              _vm._l(_vm.labelLevel, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "标签名称", prop: "name" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.label.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.label, "name", $$v)
                                },
                                expression: "label.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "状态", prop: "isEnable" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { fill: "#000000", size: "medium" },
                                model: {
                                  value: _vm.label.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.label, "isEnable", $$v)
                                  },
                                  expression: "label.isEnable",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "true" } },
                                  [_vm._v("启用")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "false" } },
                                  [_vm._v("禁用")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }