"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeWarehouseById = changeWarehouseById;
exports.createData = createData;
exports.fetchList = fetchList;
exports.getWareSetOrgList = getWareSetOrgList;
exports.queryById = queryById;
exports.updateData = updateData;
exports.validateCode = validateCode;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/getPageList',
    method: 'get',
    params: query
  });
}
/**
 * 新建保存
 * @param data
 */


function createData(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/bus_app/warehouse/save',
    method: 'post',
    data: data
  });
}
/**
 * 编辑
 * @param data
 */


function updateData(data) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/update',
    method: 'post',
    data: data
  });
}
/**
 * 校验code唯一性
 * @param value
 */


function validateCode(value) {
  console.log(value);
  return (0, _request.default)({
    url: '/bus_app/warehouse/validateCode',
    method: 'post',
    params: {
      code: value
    }
  });
}
/**
 * 禁用、启用、删除
 * @param data
 */


function changeWarehouseById(data) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/updateEnable',
    method: 'get',
    params: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 根据仓库id，查询关联的机构信息
 * @param id
 */


function getWareSetOrgList(id) {
  return (0, _request.default)({
    url: '/bus_app/warehouse/getWareSetOrgList',
    method: 'post',
    params: {
      id: id
    }
  });
}