"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 库存块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var stockRouter = {
  path: '/stock/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'stock_in',
    name: 'stockInDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_in/detail'));
      });
    },
    meta: {
      title: '入库详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_in/list',
      parent: {
        path: '/stock',
        meta: {
          title: '总部库存管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'stock_out/create/:id/:dialogStatus',
    name: 'stockOutCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_out/detail'));
      });
    },
    meta: {
      title: '出库详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_out/index',
      parent: {
        path: '/stock',
        meta: {
          title: '总部库存管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'stock_in_plan/create/:id/:dialogStatus',
    name: 'stockInPlanDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_in_plan/detail'));
      });
    },
    meta: {
      title: '采购明细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_in_plan/index',
      parent: {
        path: '/stock',
        meta: {
          title: '采购管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'stock_transfer/create/:id/:dialogStatus',
    name: 'stockTransferCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_transfer/detail'));
      });
    },
    meta: {
      title: '库存转移详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_transfer/index',
      parent: {
        path: '/stock',
        meta: {
          title: '总部库存管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'stock_trim',
    name: 'stockTrimDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_trim/detail'));
      });
    },
    meta: {
      title: '库存调整（盘点）详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_trim/index',
      parent: {
        path: '/stock',
        meta: {
          title: '库存调整（盘点）',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'stock_out_plan/create/:dialogStatus/:id',
    name: 'stockOutPlanCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stock/stock_out_plan/detail'));
      });
    },
    meta: {
      title: '批量出库计划详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/stock/stock_out_plan/index',
      parent: {
        path: '/stock',
        meta: {
          title: '总部库存管理',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = stockRouter;
exports.default = _default;