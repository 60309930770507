"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _equityExtendConfig = require("@/api/register/equityExtendConfig");

var _select = require("@/api/common/select");

var _utils = require("@/utils");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 下拉框 树形结构 可检索组件
var _default = {
  name: 'EquityExtendConfigDetail',
  //eslint-disable-line
  directives: {
    waves: _waves.default
  },
  components: {
    treeSelect: _treeSelect.default,
    Sticky: _Sticky.default
  },
  data: function data() {
    var _this = this;

    var validateConfigDate = function validateConfigDate(rule, value, callback) {
      // 后台方法
      if (_this.configDate.length < 2) {
        callback('活动时间是必填的');
      } else {
        callback();
      }
    };

    return {
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      customerOptions: [{
        name: '所有用户',
        val: '0'
      }, {
        name: '复购用户',
        val: '1'
      }],
      sourceOptions: [{
        name: '天猫',
        val: '1'
      }, {
        name: 'M2',
        val: '7'
      }, {
        name: 'AIO',
        val: '23'
      }, {
        name: '抖音',
        val: '32'
      }, {
        name: '云店',
        val: '34'
      }, {
        name: 'JD POP',
        val: '35'
      }],
      configDate: [],
      btnLoading: false,
      dialogSkuSelVisible: false,
      // 弹出框查询校验
      skuSelValidate: false,
      list4ModelSelect: [],
      // 删除按钮状态
      removeBtnDisabled: true,
      // 一览页面信息
      modelSelConditionValidate: false,
      skuListValidate: false,
      loadingListSkuSelect: false,
      loadingListSku: false,
      //
      loading: false,
      multipleSelection: [],
      listQuery: {
        page: 1,
        limit: 1000000
      },
      categorySelectTree: [],
      categorySelect: [],
      modelSelect: [],
      skuSelect: [],
      listSkuSelect: [],
      listSku: [],
      multipleSelectionSkuSel: [],
      addBtnDisabled: true,
      deleteBtnDisabled: true,
      list: [],
      total: 0,
      listLoading: true,
      // >>> 添加编辑FORM项目 >>>
      validateForm: {
        id: null,
        configDate: '',
        repurchaseFlag: '',
        extendSource: '',
        isUsed: '1',
        isEnable: '1',
        memo: '',
        ids: '',
        categoryId: ''
      },
      rules: {
        configDate: [{
          required: true,
          validator: validateConfigDate,
          trigger: 'blur'
        }],
        repurchaseFlag: [{
          required: true,
          message: '请选择用户群体',
          trigger: 'blur'
        }],
        extendSource: [{
          required: true,
          message: '请选择渠道',
          trigger: 'blur'
        }],
        extendDate: [{
          required: true,
          message: '延保时长是必填的',
          trigger: 'change'
        }, {
          pattern: /^[1-9]\d*$/,
          message: '请正确填写正整数'
        }]
      },
      // <<< 添加编辑FORM项目 <<<
      // ------- 弹出框
      // 弹出框列表
      dialogList: null,
      // 弹出框选择
      dialogSelection: [],
      textMap: {
        update: '编辑',
        create: '新建'
      },
      dialogFormVisible: false,
      setBtnState_disabled: true,
      // 删除按钮：初始化状态
      tableData1: [],
      accordion: false,
      cTree: true,
      pdText: '请选择品类',
      tableData12: [],
      accordion2: false,
      cTree2: true,
      pdText2: '请选择品类',
      isList: false
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categoryChange: {
      handler: function handler(val, oldval) {
        this.categorySelectChange(val, oldval);
      },
      deep: true,
      immediate: true
    },
    search: function search(val) {
      this.list = this.oTableData.filter(function (item) {
        return ~item.name.indexOf(val);
      });
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    }
  },
  created: function created() {
    var _this2 = this;

    if (!this.isCreate) {
      this.getConfigInfo();
    } else {
      (0, _select.getCategorySelect)().then(function (res) {
        _this2.categorySelect = res.data.records;
      });
    }
  },
  methods: {
    // 获取维修代码详细
    getConfigInfo: function getConfigInfo() {
      var _this3 = this;

      (0, _equityExtendConfig.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this3.validateForm = response.data;
          _this3.validateForm.configDate = _this3.validateForm.startTime + ' 至 ' + _this3.validateForm.endTime;
          _this3.listSku = response.data.itemList;

          _this3.listSku.forEach(function (item) {
            item.code = item.skuCode;
            item.name = item.skuName;
          });
        }
      });
    },
    // 新增确认
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this4.validateSkuList()) {
          _this4.validateForm.itemList = _this4.listSku;

          _this4.validateForm.itemList.forEach(function (item) {
            item.skuCode = item.code;
            item.skuName = item.name;
          });

          _this4.validateForm.startTime = (0, _utils.parseTime)(new Date(_this4.configDate[0]), '{y}-{m}-{d}');
          _this4.validateForm.endTime = (0, _utils.parseTime)(new Date(_this4.configDate[1]), '{y}-{m}-{d}');
          (0, _equityExtendConfig.validateConfig)(_this4.validateForm).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '该渠道在此时间范围内已有配置 请重新选择时间范围或渠道',
                type: 'warning'
              });
            } else {
              (0, _equityExtendConfig.createConfig)(_this4.validateForm).then(function (response) {
                if (response.data) {
                  _this4.$message({
                    showClose: true,
                    message: '新增成功！',
                    type: 'success'
                  });

                  _this4.route();
                } else {
                  _this4.$message({
                    showClose: true,
                    message: '新增失败！',
                    type: 'warning'
                  });
                }
              });
            }
          });
        }
      });
    },
    route: function route() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/register/equity_extend_config'
      });
    },
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.listQuery.categoryId = value;
    },
    // 【dialog】【适用型号】初始化
    dialog4modelSelOpen: function dialog4modelSelOpen() {
      var _this5 = this;

      (0, _select.getCategorySelect)().then(function (res) {
        _this5.categorySelect = res.data.records;
      });
      (0, _select.getModelSelectByCategoryId)().then(function (res) {
        _this5.modelSelect = res.data.records;
      });
      (0, _select.getSkuSelectByParams)().then(function (res) {
        _this5.skuSelect = res.data.records;
      });
    },
    categorySelectChange: function categorySelectChange() {
      this.modelSelect = [];
      this.skuSelect = [];
    },
    modelSelectChange: function modelSelectChange() {
      this.modelSelect = [];
    },
    // 获取型号下拉
    loadModelSelect: function loadModelSelect() {
      var _this6 = this;

      var params = {
        categoryId: this.listQuery.categoryId,
        isEnable: '1'
      };
      (0, _select.getModelSelectByCategoryId)(params).then(function (res) {
        _this6.modelSelect = res.data;
      });
    },
    // 获取SKU下拉
    loadSkuSelect: function loadSkuSelect() {
      var _this7 = this;

      var params = {
        categoryId: this.listQuery.categoryId,
        modelId: this.listQuery.modelId,
        isEnable: '1'
      };
      (0, _select.getSkuSelectByParams)(params).then(function (res) {
        _this7.skuSelect = res.data;
      });
    },
    cleanBtnClickDialog: function cleanBtnClickDialog() {
      this.modelInfo = {
        page: 1,
        limit: 1000000,
        isEnable: '1',
        categoryId: '',
        code: '',
        name: ''
      };
    },
    searchBtnClickSkuSel: function searchBtnClickSkuSel() {
      if (this.listQuery.categoryId || this.listQuery.modelId || this.listQuery.skuCode) {
        this.skuSelValidate = false;
        this.getListSkuSel();
      } else {
        this.skuSelValidate = true;
      }
    },
    getListSkuSel: function getListSkuSel() {
      var _this8 = this;

      this.loadingListSkuSelect = true;
      (0, _select.getSkuSelectByParams)(this.listQuery).then(function (response) {
        _this8.listSkuSelect = response.data;
        _this8.loadingListSkuSelect = false;
      });
    },
    // 【型号选择】重置按钮事件
    cleanBtnClickSkuSel: function cleanBtnClickSkuSel() {
      this.listQuery = {
        isEnable: '1'
      };
      this.skuSelValidate = false;
      this.listSkuSelect = [];
    },
    addSkuSwitch: function addSkuSwitch() {
      this.listQuery = {
        isEnable: '1'
      };
      this.skuSelValidate = false;
      this.dialogSkuSelVisible = !this.dialogSkuSelVisible;
      this.listSkuSelect = [];
    },
    // 单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 弹出框 单击一行，则切换该行的checkBox选中状态
    dialogRowClickSelection: function dialogRowClickSelection(row, column, event) {
      this.$refs.dialogTable.toggleRowSelection(row);
    },
    rowClickSelectionSkuSelect: function rowClickSelectionSkuSelect(row, column, event) {
      this.$refs.multipleTableSkuSelect.toggleRowSelection(row);
    },
    handleSelectionChangeSkuSelect: function handleSelectionChangeSkuSelect(val) {
      this.multipleSelectionSkuSel = val;
      this.addBtnDisabled = this.multipleSelectionSkuSel.length < 1;
    },
    // listTable中checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;

      if (this.multipleSelection.length >= 1) {
        this.removeBtnDisabled = false;
      } else {
        this.removeBtnDisabled = true;
      }
    },
    dialogSelectionChange: function dialogSelectionChange(val) {
      this.dialoSelection = val;
    },
    // 【型号选择列表】将选中的型号添加入【关联型号列表】
    addSkuSelectBtnClick: function addSkuSelectBtnClick() {
      this.loadingListSku = true;

      if (this.multipleSelectionSkuSel.length >= 1) {
        var addSkuSels = [];
        this.multipleSelectionSkuSel.map(function (value, index, arry) {
          addSkuSels.push({
            'code': value.code,
            'name': value.name,
            'prefix': value.preSn
          });
        });
        var listSkuCopy = JSON.parse(JSON.stringify(this.listSku)); // 合并数组

        var newListSkuCopy = listSkuCopy ? listSkuCopy.concat(addSkuSels) : addSkuSels; // 数组根据主键modelId去掉重复项

        var temp = {};
        var newListSku = [];

        for (var i = 0; i < newListSkuCopy.length; i++) {
          if (!temp[newListSkuCopy[i].code]) {
            newListSku.push(newListSkuCopy[i]);
            temp[newListSkuCopy[i].code] = true;
          }
        } // 已关联型号数组渲染赋值


        this.listSku = newListSku; // 隐藏弹出框

        this.addSkuSwitch();
      }

      this.loadingListSku = false;
    },
    // 【已关联型号列表】删除方法
    deSkuClick: function deSkuClick() {
      this.loadingListSku = true;

      if (this.multipleSelection.length > 0) {
        var skuCodeSelects = [];
        this.multipleSelection.forEach(function (v) {
          skuCodeSelects.push(v.code);
        });
        var skuCodeSelectCopy = JSON.parse(JSON.stringify(this.listSku));
        var newListSku = [];

        for (var i = 0; i < skuCodeSelectCopy.length; i++) {
          if (skuCodeSelects.indexOf(skuCodeSelectCopy[i].code) === -1) {
            newListSku.push(skuCodeSelectCopy[i]);
          }
        }

        this.listSku = newListSku;
      }

      this.loadingListSku = false;
    },
    // sku列表验证方法
    validateSkuList: function validateSkuList() {
      if (!this.listSku || this.listSku.length === 0) {
        this.skuListValidate = true;
        return false;
      }

      return true;
    }
  }
};
exports.default = _default;