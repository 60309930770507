"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.constructor");

var _list = require("@/api/basics/deliver/list");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'stockForewarnConfig',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  data: function data() {
    return {
      listLoading: true,
      isCommit: false,
      configDate: [],
      configList: [],
      btnLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    commitConfig: function commitConfig() {
      var _this = this;

      var numReg = /^[1-9]\d*$/;
      var numRe = new RegExp(numReg);
      var flag = true;
      this.configList.forEach(function (item) {
        if (!numRe.test(item.stockDdir) || !numRe.test(item.stock1000)) {
          flag = false;
        }
      });

      if (!flag) {
        this.$message({
          showClose: true,
          message: '请输入>0的整数',
          type: 'warning'
        });
        return;
      }

      this.btnLoading = true;
      (0, _list.updateConfig)(this.configList).then(function (response) {
        if (response.data) {
          _this.$message({
            showClose: true,
            message: '更新成功！',
            type: 'success'
          });
        } else {
          _this.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }

        _this.getList();
      });
      this.btnLoading = false;
      this.isCommit = false;
    },
    // 分页查询
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _list.getConfigList)().then(function (response) {
        _this2.configDate = response.data;
        _this2.configList = response.data;
        _this2.listLoading = false;
      });
    },
    dateChange: function dateChange(date, row) {
      var numReg = /^[1-9]\d*$/;
      var numRe = new RegExp(numReg);

      if (!numRe.test(date)) {
        this.$message({
          showClose: true,
          message: '请输入>0的整数',
          type: 'warning'
        });
        return;
      }

      this.configList.forEach(function (item) {
        if (row.id === item.id) {
          item = row;
        }
      });
    },
    updateConfig: function updateConfig() {},
    // 返回
    route: function route() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/dispatchConfig/list'
      });
    }
  }
};
exports.default = _default;