"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.callSaleList = callSaleList;
exports.callout = callout;
exports.complete = complete;
exports.fetchList = fetchList;
exports.qiyuComplete = qiyuComplete;
exports.queryById = queryById;
exports.queryByNum = queryByNum;
exports.queryQiyuSaleById = queryQiyuSaleById;
exports.querySapStatus = querySapStatus;
exports.saveQiyuSale = saveQiyuSale;
exports.saveSale = saveSale;
exports.sendSms = sendSms;
exports.updateSaleCancel = updateSaleCancel;
exports.updateSaleCustomer = updateSaleCustomer;
exports.updateStatus = updateStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 保存工单*/
function saveSale(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/saveSale',
    method: 'post',
    data: data
  });
}

function updateStatus(params) {
  return (0, _request.default)({
    url: '/bus_app/sale/updateStatus',
    method: 'post',
    params: params
  });
}

function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/list',
    method: 'post',
    params: query
  });
}

function callSaleList(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/callSaleList',
    method: 'post',
    params: query
  });
}

function callout(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/callout',
    method: 'post',
    data: data
  });
}

function queryById(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/queryById',
    method: 'post',
    params: query
  });
}

function updateSaleCustomer(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/updateSaleCustomer',
    method: 'post',
    data: data
  });
}

function complete(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/complete',
    method: 'post',
    data: data
  });
}

function updateSaleCancel(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/updateSaleCancel',
    method: 'post',
    data: data
  });
}

function sendSms(params) {
  return (0, _request.default)({
    url: '/bus_app/sale/sendSms',
    method: 'post',
    params: params
  });
}

function queryByNum(params) {
  return (0, _request.default)({
    url: '/bus_app/sale/queryByNum',
    method: 'post',
    params: params
  });
}
/**
 * 拿取该销售单sap回传状态信息
 */


function querySapStatus(id) {
  return (0, _request.default)({
    url: '/bus_app/sale/querySapStatus',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 保存工单*/


function saveQiyuSale(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale/saveSale',
    method: 'post',
    data: data
  });
}

function queryQiyuSaleById(query) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale/queryById',
    method: 'post',
    params: query
  });
}

function qiyuComplete(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale/complete',
    method: 'post',
    data: data
  });
}