var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.taskInfo.taskNo)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnShow,
                  expression: "btnShow",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_TASK_TASK_COMMIT"],
                  expression: "['ROLE_ORDER_TASK_TASK_COMMIT']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.callConfirm()
                },
              },
            },
            [_vm._v("确认")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_TASK_TASK_LIST"],
                  expression: "['ROLE_ORDER_TASK_TASK_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "task" })
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "基本信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("工单信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务单号",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.orderNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "orderNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.orderNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务子类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .subType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "subType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.subType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessSubTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "创建人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createUserName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createUserName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createUserName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "创建时间",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "跟进人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .taskRmkName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "taskRmkName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.taskRmkName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "状态" },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          disabled: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo.state,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "state",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.state",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.stateOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "子状态" },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          disabled: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .subState,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "subState",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.subState",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.subStateOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "临时跟进人",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .taskTempRmkName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "taskTempRmkName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.taskTempRmkName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "客户信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("客户信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "机主" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .ownerName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "ownerName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.ownerName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "机主电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .ownerTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "ownerTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.ownerTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "1",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "报修人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .claimName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "claimName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.claimName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "报修人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .claimTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "claimTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.claimTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "2",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "寄件人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .sendName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "sendName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.sendName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "寄件人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.sendTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "sendTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.sendTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "3",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "收件人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .takeName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "takeName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.takeName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "收件人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.takeTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "takeTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.takeTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "4",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "外呼信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("任务信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "任务单号",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.taskInfo.taskNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.taskInfo,
                                                            "taskNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "taskInfo.taskNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _vm.taskInfo.taskType !== "99"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "任务类型",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              filterable: "",
                                                              clearable: "",
                                                              disabled: "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.taskInfo
                                                                  .taskType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.taskInfo,
                                                                    "taskType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "taskInfo.taskType",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.callSignOptions,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.name,
                                                                    value:
                                                                      item.val,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "任务类型",
                                                          prop: "taskInfoTaskType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              filterable: "",
                                                              clearable: "",
                                                              disabled: "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.taskInfo
                                                                  .functionType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.taskInfo,
                                                                    "functionType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "taskInfo.functionType",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.listAll,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.taskName,
                                                                    value:
                                                                      item.id,
                                                                    disabled:
                                                                      item.isEnable ===
                                                                      "0",
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "创建人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.taskInfo
                                                            .inRmkName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.taskInfo,
                                                            "inRmkName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "taskInfo.inRmkName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "创建日期",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.taskInfo
                                                            .inDateName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.taskInfo,
                                                            "inDateName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "taskInfo.inDateName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "状态" },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.taskInfo.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.taskInfo,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "taskInfo.type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.orderTypeState,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "是否挂起",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.taskInfo
                                                              .isProhibit,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.taskInfo,
                                                              "isProhibit",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "taskInfo.isProhibit",
                                                        },
                                                      },
                                                      [
                                                        _c("el-option", {
                                                          key: "1",
                                                          attrs: {
                                                            label: "是",
                                                            value: "1",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-option", {
                                                          key: "0",
                                                          attrs: {
                                                            label: "否",
                                                            value: "0",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "优先级" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.taskInfo
                                                            .priorityName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.taskInfo,
                                                            "priorityName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "taskInfo.priorityName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.cleanStatus,
                                                        expression:
                                                          "cleanStatus",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "清洁类型",
                                                      prop: "checkReason",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        value:
                                                          _vm.orderInfo
                                                            .cleanType === "1"
                                                            ? "清洁服务"
                                                            : "清洁维修",
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: _vm.label1,
                                                          prop: "callResult",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.btnDisabled,
                                                              filterable: "",
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.getQuotaAmount,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.taskInfo
                                                                  .callResult,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.taskInfo,
                                                                    "callResult",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "taskInfo.callResult",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.callResultOptions,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.name,
                                                                    value:
                                                                      item.val,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.taskInfo.taskType === "8" &&
                                            _vm.cleanIsPay &&
                                            _vm.cleanStatus
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label:
                                                                "维修单沟通结果",
                                                              prop: "callResult2",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.btnDisabled,
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择",
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.getQuotaAmount2,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.taskInfo
                                                                      .callResult2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.taskInfo,
                                                                        "callResult2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "taskInfo.callResult2",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.callResultOptions2,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.val,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.cleanStatus &&
                                            _vm.orderInfo.cleanOrderNo &&
                                            _vm.orderInfo.cleanType === "1"
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label:
                                                                "清洁维修单号",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "link-type",
                                                                attrs: {
                                                                  id: "orderNo",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.gotoEditHtml(
                                                                        _vm
                                                                          .orderInfo
                                                                          .cleanOrderNo
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .orderInfo
                                                                        .cleanOrderNo
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.exchangeFlag
                                              ? _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label:
                                                                "启用换机优惠价",
                                                              prop: "caps",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio-group",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.btnDisabled,
                                                                  size: "medium",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderInfo
                                                                      .exchangePreferentialPriceSwitch,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderInfo,
                                                                        "exchangePreferentialPriceSwitch",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderInfo.exchangePreferentialPriceSwitch",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio-button",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [_vm._v("是")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-radio-button",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "0",
                                                                    },
                                                                  },
                                                                  [_vm._v("否")]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.taskInfo.callResult === "0"
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label:
                                                                "后续计划日期",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-date-picker",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.btnDisabled,
                                                                  type: "date",
                                                                  placeholder:
                                                                    "选择日期",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.taskInfo
                                                                      .planStarDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.taskInfo,
                                                                        "planStarDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "taskInfo.planStarDate",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.orderInfo.callSign === "5"
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "流水号",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.btnDisabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.taskInfo
                                                                    .serialNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.taskInfo,
                                                                      "serialNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "taskInfo.serialNumber",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.cleanStatus &&
                                        _vm.taskInfo.taskType === "8"
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 24,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "关联单号",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .cleanLinkNo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "cleanLinkNo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.cleanLinkNo",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "创建备注",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            type: "textarea",
                                                            autosize: {
                                                              minRows: 2,
                                                            },
                                                            disabled: "",
                                                            maxlength: "1000",
                                                            "show-word-limit":
                                                              "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.taskInfo
                                                                .callCate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.taskInfo,
                                                                "callCate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "taskInfo.callCate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "沟通备注",
                                                          prop: "callRemark1",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled:
                                                              _vm.btnDisabled,
                                                            type: "textarea",
                                                            autosize: {
                                                              minRows: 2,
                                                            },
                                                            maxlength: "1000",
                                                            "show-word-limit":
                                                              "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.taskInfo
                                                                .callRemark,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.taskInfo,
                                                                "callRemark",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "taskInfo.callRemark",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.receiptShow,
                                  callback: function ($$v) {
                                    _vm.receiptShow = $$v
                                  },
                                  expression: "receiptShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.checkShow,
                                  callback: function ($$v) {
                                    _vm.checkShow = $$v
                                  },
                                  expression: "checkShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-check", {
                                      ref: "checkOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.priceShow,
                                  callback: function ($$v) {
                                    _vm.priceShow = $$v
                                  },
                                  expression: "priceShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    !_vm.cleanIsPay
                                      ? _c("order-price", {
                                          ref: "checkPrice",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                            "price-state": true,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.cleanIsPay
                                      ? _c("order-price", {
                                          ref: "checkPrice",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo2,
                                            "price-state": true,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.handleShow,
                                  callback: function ($$v) {
                                    _vm.handleShow = $$v
                                  },
                                  expression: "handleShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-handle", {
                                      ref: "handleOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.returnShow,
                                  callback: function ($$v) {
                                    _vm.returnShow = $$v
                                  },
                                  expression: "returnShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-return", {
                                      ref: "returnOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.paymentShow,
                                  callback: function ($$v) {
                                    _vm.paymentShow = $$v
                                  },
                                  expression: "paymentShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-payment", {
                                      ref: "paymentOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.sfInfoShow,
                                  callback: function ($$v) {
                                    _vm.sfInfoShow = $$v
                                  },
                                  expression: "sfInfoShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c(
                                      "el-row",
                                      { staticClass: "物流信息" },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-divider",
                                              {
                                                staticClass: "myStyle",
                                                attrs: {
                                                  "content-position": "left",
                                                },
                                              },
                                              [_vm._v("收货物流信息")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-card",
                                              {
                                                staticClass: "box-card",
                                                staticStyle: {
                                                  "border-top": "none",
                                                },
                                                attrs: { shadow: "never" },
                                              },
                                              [
                                                _c("sf-route-state", {
                                                  ref: "sfInfoForm",
                                                  attrs: {
                                                    num: _vm.orderInfo.orderNo,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12, push: 1 } },
                                          [
                                            _c(
                                              "el-divider",
                                              {
                                                staticClass: "myStyle",
                                                attrs: {
                                                  "content-position": "left",
                                                },
                                              },
                                              [_vm._v("返机物流信息")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-card",
                                              {
                                                staticClass: "box-card",
                                                staticStyle: {
                                                  "border-top": "none",
                                                },
                                                attrs: { shadow: "never" },
                                              },
                                              [
                                                _c("sf-route-state", {
                                                  ref: "sfInfoTo",
                                                  attrs: {
                                                    num: _vm.orderInfo.orderNo,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.hisShow,
                                  callback: function ($$v) {
                                    _vm.hisShow = $$v
                                  },
                                  expression: "hisShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c(
                                      "el-divider",
                                      {
                                        staticClass: "myStyle",
                                        attrs: { "content-position": "left" },
                                      },
                                      [_vm._v("任务履历")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: { "border-top": "none" },
                                        attrs: { shadow: "never" },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "160px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.listLoading,
                                                            expression:
                                                              "listLoading",
                                                          },
                                                        ],
                                                        attrs: {
                                                          size: "mini",
                                                          border: "",
                                                          stripe: "",
                                                          "tooltip-effect":
                                                            "dark",
                                                          "header-cell-style": {
                                                            background:
                                                              "#eef1f6",
                                                            color: "#606266",
                                                            height: "40px",
                                                          },
                                                          data: _vm.hisList,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "indexNum",
                                                            label: "序号",
                                                            width: "55",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "createUserName",
                                                            label: "沟通人",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "createTime",
                                                            label: "沟通时间",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "callResultName",
                                                            label: "沟通结果",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "callRemark",
                                                            label: "沟通备注",
                                                            align: "center",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.approvalShow,
                                  callback: function ($$v) {
                                    _vm.approvalShow = $$v
                                  },
                                  expression: "approvalShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("approve-lists", {
                                      ref: "approveOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 120 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "70px",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "产品" },
                                            on: {
                                              change: function ($event) {
                                                _vm.productShow === "1"
                                                  ? (_vm.productShow = "0")
                                                  : (_vm.productShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "收货" },
                                            on: {
                                              change: function ($event) {
                                                _vm.receiptShow === "1"
                                                  ? (_vm.receiptShow = "0")
                                                  : (_vm.receiptShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "检测" },
                                            on: {
                                              change: function ($event) {
                                                _vm.checkShow === "1"
                                                  ? (_vm.checkShow = "0")
                                                  : (_vm.checkShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "费用" },
                                            on: {
                                              change: function ($event) {
                                                _vm.priceShow === "1"
                                                  ? (_vm.priceShow = "0")
                                                  : (_vm.priceShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "处理" },
                                            on: {
                                              change: function ($event) {
                                                _vm.handleShow === "1"
                                                  ? (_vm.handleShow = "0")
                                                  : (_vm.handleShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "返机" },
                                            on: {
                                              change: function ($event) {
                                                _vm.returnShow === "1"
                                                  ? (_vm.returnShow = "0")
                                                  : (_vm.returnShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "支付" },
                                            on: {
                                              change: function ($event) {
                                                _vm.paymentShow === "1"
                                                  ? (_vm.paymentShow = "0")
                                                  : (_vm.paymentShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "物流" },
                                            on: {
                                              change: function ($event) {
                                                _vm.sfInfoShow === "1"
                                                  ? (_vm.sfInfoShow = "0")
                                                  : (_vm.sfInfoShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "任务履历" },
                                            on: {
                                              change: function ($event) {
                                                _vm.hisShow === "1"
                                                  ? (_vm.hisShow = "0")
                                                  : (_vm.hisShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.taskInfo.taskType == "7"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: { label: "审批信息" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.approvalShow === "1"
                                                      ? (_vm.approvalShow = "0")
                                                      : (_vm.approvalShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }