"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _orderBy = _interopRequireDefault(require("@/api/order/orderBy"));

var _filterUtils = require("@/api/order/filterUtils");

var _order_task = require("@/api/order_task");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
var _default = {
  name: 'orderTask',
  // eslint-disable-line
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default,
    permission: _index.default
  },
  filters: {
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    postTypeFilter: _filterUtils.postTypeFilter,
    businessTypeFilter: _filterUtils.businessTypeFilter,
    businessSubTypeFilter: _filterUtils.businessSubTypeFilter,
    urgentLevelFilter: _filterUtils.urgentLevelFilter,
    paymentChannelFilter: _filterUtils.paymentChannelFilter
  },
  data: function data() {
    return {
      conditionFold: '0',
      btnLoading: false,
      postTypeOptions: _constant.default.postTypeOptions,
      // 邮寄方式
      businessTypeOptions: _constant.default.businessTypeOptions,
      // 服务类型
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      // 服务类型
      urgentLevelOptions: _constant.default.urgentLevelOptions,
      stateOptions: _constant.default.stateOptions,
      outCallOptions: _constant.default.outCallOptions,
      subStateOptions: _constant.default.subStateOptions,
      accessChannelOptions: _constant.default.accessChannelOptions,
      loginUserOrgId: this.$store.state.user.loginUser.orgId,
      // 当前账号所属机构ID
      printBtnDisabled: true,
      // 弹出层
      dialog4CallerVisible: false,
      // ---------------------------------------------------------------------------------------------------------------------------------------------
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        orderId: this.$route.params.id,
        page: 1,
        limit: 10,
        orderByField: undefined,
        isAsc: ''
      } // ---------------------------------------------------------------------------------------------------------------------------------------------

    };
  },
  computed: {
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    search: function search(val) {
      this.list = this.oTableData.filter(function (item) {
        return ~item.name.indexOf(val);
      });
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    this.listQuery.page = 1;
    this.getList();
  },
  methods: {
    dateFormat: function dateFormat(row, column) {
      var date = row[column.property];

      if (date === undefined || date === null) {
        return '';
      }

      return (0, _utils.parseTime)(new Date(date), '{y}-{m}-{d} {h}:{i}:{s}');
    },
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.listQuery.category = value;
    },
    computeDays: function computeDays(row, column) {
      return (0, _utils.computeDays)(row.inDate, new Date());
    },
    // 详情页面
    gotoEditHtml: function gotoEditHtml(row, event) {
      this.$router.push({
        name: 'queryOrderDetail',
        params: {
          id: row.orderId
        }
      });
    },
    gotoCallHtml: function gotoCallHtml(row, event) {
      this.$router.push({
        name: 'customerTaskDetail',
        params: {
          id: row.orderNo,
          taskId: row.id,
          isQuery: '1'
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 分页查询
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _order_task.findPageList)(this.listQuery).then(function (response) {
        _this.list = response.data.records;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    // 排序
    sortChange: function sortChange(sortInfo) {
      this.listQuery.orderByField = _orderBy.default.taskKeyMap[sortInfo.prop];

      if (sortInfo.order === 'ascending') {
        this.listQuery.isAsc = 'ASC';
      } else {
        this.listQuery.isAsc = 'DESC';
      }

      this.listQuery.page = 1;
      console.log(this.listQuery);
      this.getList();
    }
  }
};
exports.default = _default;