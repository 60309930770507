"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPoolLogList = getPoolLogList;
exports.getRepairLogList = getRepairLogList;
exports.getRepairPoolList = getRepairPoolList;
exports.listByYear = listByYear;
exports.saveQuota = saveQuota;
exports.saveRepairQuota = saveRepairQuota;

var _request = _interopRequireDefault(require("@/utils/request"));

function listByYear(year) {
  return (0, _request.default)({
    url: '/bus_app/quota/listByYear',
    method: 'post',
    params: {
      year: year
    }
  });
}
/**
 * 添加，修改保存
 * @param data
 */


function saveQuota(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/bus_app/quota/saveQuota',
    method: 'post',
    data: data
  });
}
/**
 * 查询配置日志
 * @param data
 */


function getPoolLogList(query) {
  return (0, _request.default)({
    url: '/bus_app/quota/getPoolLogList',
    method: 'post',
    params: {
      year: query.year,
      month: query.month
    }
  });
}
/**
 * 查询维修转换货减免配置
 * @param data
 */


function getRepairPoolList(year) {
  return (0, _request.default)({
    url: '/bus_app/quota/getRepairPoolList',
    method: 'post',
    params: {
      year: year
    }
  });
}
/**
 * 添加，修改保存
 * @param data
 */


function saveRepairQuota(data) {
  return (0, _request.default)({
    url: '/bus_app/quota/saveRepairQuota',
    method: 'post',
    data: data
  });
}
/**
 * 查询维修转换货减免配置日志
 * @param data
 */


function getRepairLogList(query) {
  return (0, _request.default)({
    url: '/bus_app/quota/getRepairLogList',
    method: 'post',
    params: {
      year: query.year,
      month: query.month
    }
  });
}