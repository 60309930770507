"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.confirmSave = confirmSave;
exports.deleteData = deleteData;
exports.fetchAuditList = fetchAuditList;
exports.fetchList = fetchList;
exports.queryById = queryById;
exports.submitSave = submitSave;
exports.tempSave = tempSave;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询额度充值申请分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 删除
 */


function deleteData(ids) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/delete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 查询详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 暂存
 * @param data
 */


function tempSave(data) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/tempSave',
    method: 'post',
    data: data
  });
}
/**
 * 提交
 * @param data
 */


function submitSave(data) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/submitSave',
    method: 'post',
    data: data
  });
}
/**
 * 查询额度充值审核分页
 * @param query
 */


function fetchAuditList(query) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/getAuditPageList',
    method: 'post',
    params: query
  });
}
/**
 * 审核确认
 * @param data
 */


function confirmSave(data) {
  return (0, _request.default)({
    url: '/scm_app/quota_apply/confirmSave',
    method: 'post',
    data: data
  });
}