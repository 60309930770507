"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/*
 * @Author: Lee
 * @Date: 2020-10-09 18:49:19
 * @LastEditTime: 2020-10-13 16:51:37
 * @Description:
 */

/**
 * 财务块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var billRouter = {
  path: '/bill/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'invoice/detail/:id',
    name: 'invoiceDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/invoice/detail'));
      });
    },
    meta: {
      title: '开票管理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/invoice',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'refund/detail/:id',
    name: 'refundDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/refund/detail'));
      });
    },
    meta: {
      title: '退票管理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/refund',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'transaction/detail/:id',
    name: 'transactionDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/transaction/detail'));
      });
    },
    meta: {
      title: '到账管理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/transaction',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'refundAudit/detail/:id',
    name: 'refundAuditDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/refundAudit/detail'));
      });
    },
    meta: {
      title: '退款审核详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/refundAudit',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'fail/detail/:id',
    name: 'failDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/fail/detail'));
      });
    },
    meta: {
      title: '开票失败详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/fail',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'exbill/detail/:id',
    name: 'exbillDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/exbill/detail'));
      });
    },
    meta: {
      title: '换票管理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/bill/invoice',
      parent: {
        path: '/bill',
        redirect: 'noRedirect',
        meta: {
          title: '财务管理',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = billRouter;
exports.default = _default;