"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _transaction = require("@/api/bill/transaction");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      transactionInfo: {},
      loadingList4Model: false,
      rules: {
        receivedFundDate: [{
          required: true,
          message: '请选择到账日期',
          trigger: 'change'
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() >= Date.now();
        }
      }
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _transaction.queryById)(param).then(function (response) {
        if (response.data) {
          _this.transactionInfo = response.data;
          _this.transactionInfo.payName = _this.transactionInfo.payNamePage;
          _this.transactionInfo.takeUser = _this.transactionInfo.takeUserPage;
          _this.transactionInfo.takeMobile = _this.transactionInfo.takeMobilePage;
          _this.transactionInfo.receivedFundDate = _this.transactionInfo.receivedFundDateExp;
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/transaction'
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _transaction.saveTemp)(_this2.transactionInfo).then(function (response) {
            if (response.data) {
              _this2.$message({
                showClose: true,
                message: '操作成功',
                type: 'success'
              });

              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                path: '/bill/transaction'
              });
            } else {
              _this2.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _transaction.saveComplete)(_this3.transactionInfo).then(function (response) {
            if (response.data) {
              _this3.$message({
                showClose: true,
                message: '操作成功',
                type: 'success'
              });

              _this3.$store.dispatch('tagsView/delView', _this3.$route);

              _this3.$router.push({
                path: '/bill/transaction'
              });
            } else {
              _this3.completeBtn = false;

              _this3.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;