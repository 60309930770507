"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _list = require("@/api/quota/quota_apply/list");

var _list2 = require("@/api/quota/quota/list");

var _sysConfigs = require("@/api/common/sysConfigs");

var _vuex = require("vuex");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 查询机构额度
// 获取当前登陆用户信息
// 按钮权限控制
// 悬浮栏
var _default = {
  name: 'quotaApplyDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      // 添加的信息
      quotaApply: {
        state: '',
        orgId: '',
        quotaType: '10',
        quota: '',
        memo: '',
        reason: '',
        annexInfo: '',
        filePaths: []
      },
      isTemp: false,
      isSub: false,
      inOrgName: '',
      quotaSum: '0',
      avaibQuota: '0',
      frozenQuota: '0',
      // 额度类型
      typesOptions: [{
        label: '充值额度',
        value: '10'
      }],
      // 校验规则
      rules: {
        quota: [{
          required: true,
          message: '请填写申请金额',
          trigger: 'blur'
        }, {
          pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
          message: '请正确填写申请金额(保留两位小数)',
          trigger: 'blur'
        }],
        quotaType: [{
          required: true,
          message: '请选择额度类型',
          trigger: 'blur'
        }],
        memo: [{
          max: 200,
          message: '申请备注长度不能超过200个字',
          trigger: 'blur'
        }]
      },
      // 上传图片部分
      dialogImageUrl: '',
      dialogVisible: false,
      imgList: [],
      isMultiple: true,
      imgLimit: 5,
      sizeLimit: 10,
      uploadAction: '/uploadsever/fileUpload'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['loginUser'])),
  // 初始化
  created: function created() {
    if (this.id) {
      // 获取入库单详细信息
      this.getDetailInfo();
    } // 获取额度


    this.initQuota(); // 配置信息获取
    // this.queryFileConfigs()
  },
  methods: {
    // 配置信息获取
    queryFileConfigs: function queryFileConfigs() {
      var _this = this;

      (0, _sysConfigs.queryFileConfigs)().then(function (response) {
        if (response.data) {
          if (response.data.fileLimit !== null) {
            _this.imgLimit = parseInt(response.data.fileLimit);
          }

          if (response.data.fileMaxSize !== null) {
            _this.sizeLimit = parseInt(response.data.fileMaxSize);
          }
        }
      });
    },
    // 获取申请单详细信息
    getDetailInfo: function getDetailInfo() {
      var _this2 = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response != null) {
          _this2.quotaApply = response.data;

          _this2.$set(_this2.quotaApply, 'quota', Math.abs(_this2.quotaApply.quota2Yuan)); // 图片 回显


          if (_this2.quotaApply.filePaths !== null) {
            for (var i = 0; i < _this2.quotaApply.filePaths.length; i++) {
              var obj = {};
              obj.name = i;
              obj.url = _this2.quotaApply.filePaths[i];

              _this2.imgList.push(obj);
            }
          } else {
            _this2.quotaApply.filePaths = [];
          }
        }
      });
    },
    // 获取额度
    initQuota: function initQuota() {
      var _this3 = this;

      if (this.loginUser.orgId) {
        this.inOrgName = this.loginUser.orgName;
        this.quotaApply.orgId = this.loginUser.orgId;
        (0, _list2.getQuota)(this.loginUser.orgId).then(function (response) {
          if (response != null && response.data) {
            _this3.quotaSum = response.data.quota;
            _this3.avaibQuota = response.data.avaibQuota;
            _this3.frozenQuota = response.data.frozenQuota;
          } else {
            _this3.quotaSum = '0';
            _this3.avaibQuota = '0';
            _this3.frozenQuota = '0';
          }
        });
      } else {
        this.quotaSum = '0';
        this.avaibQuota = '0';
        this.frozenQuota = '0';
      }
    },
    // 暂存
    tempSave: function tempSave() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.isTemp = true;
          console.log(_this4.quotaApply);
          (0, _list.tempSave)(_this4.quotaApply).then(function (response) {
            if (response != null && response.data) {
              _this4.$message({
                message: '暂存成功',
                type: 'success'
              });

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/quota/quota_apply/list'
              });
            } else {
              _this4.isTemp = false;

              _this4.$message({
                showClose: true,
                message: '暂存失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 提交
    submitSave: function submitSave() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this5.isSub = true; // 提交后台

          (0, _list.submitSave)(_this5.quotaApply).then(function (response) {
            if (response != null && response.data) {
              _this5.$message({
                message: '提交成功',
                type: 'success'
              });

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/quota/quota_apply/list'
              });
            } else {
              _this5.isSub = false;

              _this5.$message({
                showClose: true,
                message: '提交失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/quota/quota_apply/list'
      });
    },
    // 上传文件部分
    // 移除文件
    handleRemove: function handleRemove(file, fileList) {
      this.quotaApply.filePaths = [];

      for (var i = 0; i < fileList.length; i++) {
        if (this.id) {
          this.quotaApply.filePaths.push(fileList[i].url);
        } else {
          this.quotaApply.filePaths.push(process.env.VUE_APP_FASTDFS_STORAGE_URL + fileList[i].response.data);
        }
      }

      console.log(this.quotaApply.filePaths);
    },
    // 预览图片时调用
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传之前调用做一些拦截限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = true;
      var sizeLimit = file.size / 1024 / 1024 < this.sizeLimit; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }

      if (!sizeLimit) {
        this.$message.error('上传文件大小不能超过' + this.sizeLimit + 'MB!');
      }

      return isJPG && sizeLimit;
    },
    // 文件上传成功
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      if (res.code !== 'success') {
        this.imgUploadError();
      } else {
        this.quotaApply.filePaths.push(process.env.VUE_APP_FASTDFS_STORAGE_URL + res.data);
      }
    },
    // 文件上传失败
    imgUploadError: function imgUploadError() {
      this.$message.error('上传文件失败!');
    },
    // 文件上传超过数量限制
    handleExceed: function handleExceed() {
      this.$message.error('上传文件不能超过' + this.imgLimit + '个!');
    },
    handleDownload: function handleDownload(file) {
      if (file && file.url) {
        window.location.href = file.url + '?parameter=1';
      }
    }
  }
};
exports.default = _default;