"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fcClean = require("@/api/query/fc/fcClean");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'fcDetail',
  // eslint-disable-line
  data: function data() {
    return {
      orderNo: this.$route.params.orderNo,
      fcCleanInfo: {}
    };
  },
  created: function created() {
    if (this.orderNo) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        orderNo: this.orderNo
      };
      (0, _fcClean.queryDetail)(param).then(function (response) {
        if (response.code === 200) {
          console.log(response);
          _this.fcCleanInfo = response.data;
          _this.fcCleanInfo.inDate = (0, _utils.parseTime)(new Date(_this.fcCleanInfo.inDate), '{y}-{m}-{d} {h}:{i}:{s}');
          _this.fcCleanInfo.totalAmount === '' ? _this.fcCleanInfo.totalAmount = '0.00' : _this.fcCleanInfo.totalAmount = (_this.fcCleanInfo.totalAmount / 100).toFixed(2);
          _this.fcCleanInfo.sendAddress = _this.fcCleanInfo.sendProvince + _this.fcCleanInfo.sendCity + _this.fcCleanInfo.sendCountry + _this.fcCleanInfo.sendAddress;
        } else {
          _this.$message.error(response.msg);
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/query/fc'
      });
    }
  }
};
exports.default = _default;