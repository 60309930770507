"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 收费类型
var payType = [{
  label: '须收费',
  value: '01'
}, {
  label: '样品',
  value: '09'
}, {
  label: '赠品',
  value: '07'
}, {
  label: '保内免费',
  value: 'P4'
}, {
  label: '特例免费',
  value: '06'
}];
var _default = {
  payType: payType
};
exports.default = _default;