var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _vm.productInfo.assetStatus === "05"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_SNPRODUCT_UNLOCK"],
                          expression: "['ROLE_SNPRODUCT_UNLOCK']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.unlock },
                    },
                    [_vm._v("解锁")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.productInfo,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              基本信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品SN",
                                            prop: "serialNumber",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.serialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "serialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.serialNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "资产类型",
                                            prop: "assetType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productInfo.assetType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productInfo,
                                                    "assetType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productInfo.assetType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.assetTypeOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否翻新机",
                                            prop: "isRefurb",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.productInfo.isRefurb,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productInfo,
                                                    "isRefurb",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productInfo.isRefurb",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "是",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "否",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "生产日期",
                                            prop: "productDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.productDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "productDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.productDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "出库日期",
                                            prop: "shipDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.productInfo.shipDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "shipDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.shipDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售给公司",
                                            prop: "soldToCompany",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.soldToCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "soldToCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.soldToCompany",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售渠道编码",
                                            prop: "salesChannel",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.salesChannel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "salesChannel",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.salesChannel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售渠道名称",
                                            prop: "salesChannelName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo
                                                  .salesChannelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "salesChannelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.salesChannelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售日期",
                                            prop: "soldDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.productInfo.soldDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "soldDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.soldDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册次数",
                                            prop: "registerTimes",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.registerTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "registerTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.registerTimes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "入保日期",
                                            prop: "warrantyDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.warrantyDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "warrantyDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.warrantyDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品状态",
                                            prop: "productStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productInfo.productStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productInfo,
                                                    "productStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productInfo.productStatus",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "有效",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "无效",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "资产状态",
                                            prop: "assetStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productInfo.assetStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productInfo,
                                                    "assetStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productInfo.assetStatus",
                                              },
                                            },
                                            _vm._l(
                                              _vm.assetStatusOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "创建时间",
                                            prop: "inDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.productInfo.inDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "inDate",
                                                  $$v
                                                )
                                              },
                                              expression: "productInfo.inDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "创建人",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.productInfo.inRmk,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "inRmk",
                                                  $$v
                                                )
                                              },
                                              expression: "productInfo.inRmk",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否普通延保",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.isExtendBenefit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "isExtendBenefit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.isExtendBenefit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "普通延保时长",
                                            prop: "extendBenefitDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo
                                                  .extendBenefitDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "extendBenefitDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.extendBenefitDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否会员权益延保",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.isEquityExtend,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "isEquityExtend",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.isEquityExtend",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "会员权益延保时长",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo
                                                  .equityExtendDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "equityExtendDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.equityExtendDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "会员权益延保渠道",
                                            prop: "extendBenefitDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productInfo.extendSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "extendSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productInfo.extendSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "主机SKU",
                                            prop: "sku1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.productInfo.sku1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productInfo,
                                                  "sku1",
                                                  $$v
                                                )
                                              },
                                              expression: "productInfo.sku1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.productInfo.country !== "CN"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-divider",
                              {
                                staticClass: "myStyle",
                                attrs: { "content-position": "left" },
                              },
                              [
                                _vm._v(
                                  "\n              海外机基本信息\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { "border-top": "none" },
                                attrs: { shadow: "never" },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "海外SKU",
                                                  prop: "overSeasSku",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.productInfo
                                                        .overSeasSku,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.productInfo,
                                                        "overSeasSku",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "productInfo.overSeasSku",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "销售国家",
                                                  prop: "country",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm.productInfo.country,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.productInfo,
                                                          "country",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productInfo.country",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.countryOptions,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.val,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "是否全球联保",
                                                  prop: "globalWarranty",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      fill: "#000",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.productInfo
                                                          .globalWarranty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.productInfo,
                                                          "globalWarranty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productInfo.globalWarranty",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      {
                                                        attrs: {
                                                          label: "true",
                                                        },
                                                      },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      {
                                                        attrs: {
                                                          label: "false",
                                                        },
                                                      },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-divider",
                      {
                        staticClass: "myStyle",
                        attrs: { "content-position": "left" },
                      },
                      [_vm._v("\n            产品权益\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "border-top": "none" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "0px",
                              "margin-right": "0px",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 22 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "50px",
                                        "margin-right": "50px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            border: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                              height: "50px",
                                            },
                                            data: _vm.rightList,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "rightType",
                                              label: "权益类型",
                                              align: "center",
                                              formatter: _vm.typeFormat,
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "startDate",
                                              label: "权益开始时间",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "endDate",
                                              label: "权益结束时间",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "freeReason",
                                              label: "权益原因",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isExpired",
                                              label: "是否在有效期内",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isEnjoy",
                                              label: "是否可用",
                                              align: "center",
                                              formatter: _vm.isEnjoyFormat,
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isUsed",
                                              label: "是否已使用",
                                              align: "center",
                                              formatter: _vm.isUsedFormat,
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "orderNum",
                                              label: "单号",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "link-type",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.gotoEditHtml(
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(row.orderNum)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-divider",
                      {
                        staticClass: "myStyle",
                        attrs: { "content-position": "left" },
                      },
                      [_vm._v("\n            产品履历\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "border-top": "none" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "0px",
                              "margin-right": "0px",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 22 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "50px",
                                        "margin-right": "50px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            border: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                              height: "50px",
                                            },
                                            data: _vm.prodcutHistoryList,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateTime",
                                              label: "操作时间",
                                              align: "center",
                                              width: "180px",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateUser",
                                              label: "操作人",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productName",
                                              label: "产品名称",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "serialNumber",
                                              label: "产品SN",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productStatus",
                                              label: "产品状态",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "registerTimes",
                                              label: "注册次数",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "salesChannel",
                                              label: "销售渠道编码",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "salesChannelName",
                                              label: "销售渠道名称",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "shipDate",
                                              label: "出库日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "soldDate",
                                              label: "销售日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isExtendBenefit",
                                              label: "是否普通延保",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendSource",
                                              label: "延保渠道",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendBenefitDate",
                                              label: "延保时长",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendBenefitNote",
                                              label: "延保原因",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isEquityExtend",
                                              label: "是否会员权益延保",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendSource",
                                              label: "会员权益延保渠道",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendDate",
                                              label: "会员权益延保时长",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendNote",
                                              label: "会员权益延保原因",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "orderNo",
                                              label: "订单号",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendId",
                                              label: "活动ID",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productDate",
                                              label: "生产日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyDate",
                                              label: "入保日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyNote",
                                              label: "入保日期备注",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyDateSource",
                                              label: "修改来源",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "assetType",
                                              label: "资产类型",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "assetStatus",
                                              label: "资产状态",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "country",
                                              label: "销售国家",
                                              "sort-by": "5",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("countryFilter")(
                                                          row.country
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("pagination", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.total > 0,
                                            expression: "total>0",
                                          },
                                        ],
                                        attrs: {
                                          total: _vm.total,
                                          page: _vm.listQuery.page,
                                          limit: _vm.listQuery.limit,
                                        },
                                        on: {
                                          "update:page": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "page",
                                              $event
                                            )
                                          },
                                          "update:limit": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "limit",
                                              $event
                                            )
                                          },
                                          pagination: _vm.getHistoryList,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }