"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activeStatusFilter = activeStatusFilter;
exports.callStateFilter = callStateFilter;
exports.callTypeFilter = callTypeFilter;
exports.isEnableFilter = isEnableFilter;
exports.isValidFilter = isValidFilter;
exports.memberFlagFilter = memberFlagFilter;
exports.pushStateFilter = pushStateFilter;
exports.registerSourceFilter = registerSourceFilter;
exports.registerTypeFilter = registerTypeFilter;
exports.rightsFilter = rightsFilter;

var _constant = _interopRequireDefault(require("@/api/register/constant"));

/** 推送状态 */
function pushStateFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.pushStateMap[state.trim()];
}
/** 是否会员 */


function memberFlagFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.memberFlagMap[state.trim()];
}
/** 注册来源 */


function registerSourceFilter(type) {
  if (!type) {
    return;
  }

  return _constant.default.registerSourceMap[type.trim()];
}
/** 注册类型 */


function registerTypeFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.registerTypeMap[way.trim()];
}
/** sn状态 */


function isValidFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.isValidMap[way.trim()];
}
/** 90天权益 */


function rightsFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.rightsMap[way.trim()];
}
/** 是否可用 */


function isEnableFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.isEnableMap[way.trim()];
}
/** 活跃状态 */


function activeStatusFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.activeStatusMap[way.trim()];
}
/** 调用状态 */


function callStateFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.callStateMap[state.trim()];
}
/** 调用类型 */


function callTypeFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.callTypeMap[state.trim()];
}