var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.billInvoiceInfo,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("发票信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票类型",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择发票类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .invoiceType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "invoiceType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.invoiceType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.invoiceType,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.val,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票号",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "发票号" },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.invoiceNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "invoiceNum",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.invoiceNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票日期",
                                            prop: "billingDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              placeholder: "选择开票日期",
                                              "value-format": "yyyy-MM-dd",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.billingDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "billingDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.billingDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票对象类型",
                                            prop: "custType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                clearable: "",
                                                placeholder:
                                                  "请选择开票对象类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo.custType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "custType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.custType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.custType,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.val,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票抬头",
                                            prop: "custName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "发票抬头" },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.custName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "邮箱地址",
                                            prop: "custEmail",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "邮箱地址" },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.custEmail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custEmail",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custEmail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "纳税人识别号",
                                            prop: "custTax",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              placeholder: "纳税人识别号",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.custTax,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custTax",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custTax",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "el-button",
                                  { on: { click: _vm.submitBtn } },
                                  [_vm._v("确认")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  { on: { click: _vm.returnBtn } },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }