"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.confirmSave = confirmSave;
exports.deleteItems = deleteItems;
exports.deleteStockOut = deleteStockOut;
exports.fetchList = fetchList;
exports.getCostCenterList = getCostCenterList;
exports.getGoods = getGoods;
exports.getItemsList = getItemsList;
exports.importItems = importItems;
exports.queryById = queryById;
exports.saveStockOut = saveStockOut;
exports.submitSave = submitSave;
exports.tempSave = tempSave;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/getPageList',
    method: 'post',
    params: query
  });
}

function getGoods(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/getGoods',
    method: 'post',
    params: query
  });
}

function saveStockOut() {
  return (0, _request.default)({
    url: '/bus_app/stock_out/saveStockOut',
    method: 'post'
  });
}

function addItems(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/addItems',
    method: 'post',
    params: query
  });
}

function getItemsList(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/getItemsList',
    method: 'post',
    params: query
  });
}

function deleteItems(ids) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/deleteItems',
    method: 'post',
    params: {
      id: ids
    }
  });
}

function tempSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/tempSave',
    method: 'post',
    data: data
  });
}

function submitSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/submitSave',
    method: 'post',
    data: data
  });
}

function confirmSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/confirmSave',
    method: 'post',
    data: data
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}

function deleteStockOut(id) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/deleteStockOut',
    method: 'post',
    params: {
      id: id
    }
  });
}

function getCostCenterList(param) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/getCostCenterList',
    method: 'post',
    params: param
  });
}

function importItems(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out/importItems',
    method: 'post',
    params: query
  });
}