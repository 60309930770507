"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _member = require("@/api/member");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  data: function data() {
    var _this = this;

    return {
      dialogFormVisible: true,
      user: {
        // this.$store.loginUser.memberName
        // username: this.$store.state.user.loginUser.memberName,
        username: this.$route.params.memberName,
        memberId: '',
        currentPassword: '',
        password: '',
        rstPassword: ''
      },
      pwdRules: {
        currentPassword: [{
          required: true,
          message: '请输入当前密码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error('请输入当前密码'));
            } else {
              (0, _member.checkPwd)(_this.user.username, value).then(function (response) {
                var ret = response.data;

                if (ret) {
                  // console.log(ret)
                  callback();
                } else {
                  // console.log(response)
                  callback(new Error('当前密码错误'));
                }
              });
            }
          },
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 8,
          message: '密码长度8~20之间',
          trigger: 'blur'
        }, {
          max: 20,
          message: '密码长度8~20之间',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            var ret = false;
            var num = 0;
            var rule1 = /\d+/;
            var rule2 = /[a-z]+/;
            var rule3 = /[A-Z]+/;
            var rule4 = /[!@#\$]/;
            var rule5 = /[%^&*\{\};,.\?\/'`~"_-]/;
            var flag1 = rule1.test(value);
            var flag2 = rule2.test(value);
            var flag3 = rule3.test(value);
            var flag4 = rule4.test(value);
            var flag5 = rule5.test(value);

            if (flag1) {
              num = num + 1;
            }

            if (flag2) {
              num = num + 1;
            }

            if (flag3) {
              num = num + 1;
            }

            if (flag4) {
              num = num + 1;
            }

            if (flag5) {
              num = num - 1;
            }

            if (num > 2) {
              ret = true;
            }

            if (ret) {
              if (value === _this.user.currentPassword) {
                callback(new Error('新密码与上次密码不能一致'));
              } else {
                callback();
              }
            } else {
              callback(new Error('密码是数字,大小写字母,特殊符三种及以上'));
            }
          },
          trigger: 'blur'
        }],
        rstPassword: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error('请再次输入密码'));
            } else if (value !== _this.user.password) {
              callback(new Error('两次输入密码不一致'));
            } else if (value === _this.user.currentPassword) {
              callback(new Error('新密码与上次密码不能一致'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.loginMessage();
  },
  methods: {
    loginMessage: function loginMessage() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$message.error('您的密码已经超过有效期，请修改密码后登录');
      }, 500);
    },
    updatePwdSubmit: function updatePwdSubmit() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _member.updatePwd)(_this3.user.username, _this3.user.password, _this3.user.currentPassword).then(function (response) {
            // console.log(response.data)
            if (response.data) {
              var ret = response.data;

              if (ret) {
                _this3.$message({
                  title: '成功',
                  message: '修改密码成功!',
                  type: 'success'
                });

                _this3.dialogFormVisible = false;

                _store.default.dispatch('user/logout').then(function () {
                  location.reload();
                });

                _this3.$router.push('/login');
              } else {
                _this3.$message({
                  title: '失败',
                  message: response.msg || '修改失败,请重试!',
                  type: 'warning'
                });
              }
            } else {
              _this3.$message({
                title: '失败',
                message: response.msg || '修改密码异常!',
                type: 'warning'
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;