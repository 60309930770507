"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/*
 * @Author: Lee
 * @Date: 2020-12-10 16:11:49
 * @LastEditTime: 2020-12-15 15:03:41
 * @Description:
 */

/**
 * 工单块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var saleRouter = {
  path: '/sale/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/detail/:dialogStatus/:id/:callId',
    name: 'orderSaleCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sale/sale/detail'));
      });
    },
    meta: {
      title: '销售工单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/sale/sale',
      parent: {
        path: '/sale',
        redirect: 'noRedirect',
        meta: {
          title: '销售工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'return/detail/:id/:dialogStatus/:callId',
    name: 'orderSaleReturnCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sale/return/detail'));
      });
    },
    meta: {
      title: '销售退货单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/sale/return',
      parent: {
        path: '/sale',
        redirect: 'noRedirect',
        meta: {
          title: '销售工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'return/detail2/:id/:dialogStatus/:callId',
    name: 'orderSaleReturnCreate2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sale/return/detail2'));
      });
    },
    meta: {
      title: '仅退款单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/sale/return2',
      parent: {
        path: '/sale',
        redirect: 'noRedirect',
        meta: {
          title: '销售工单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = saleRouter;
exports.default = _default;