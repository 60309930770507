var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-width": "280px", "max-width": "970px" } },
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "FixTop",
            { attrs: { "class-name": "sub-navbar default" } },
            [
              (_vm.saleInfo.state === "S1" || _vm.saleInfo.state === "") &&
              (_vm.saleInfo.stateChild === "1" ||
                _vm.saleInfo.stateChild === "2")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.completeSubmit },
                    },
                    [_vm._v("生成提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.saleInfo.state === "S1" || _vm.saleInfo.state === "") &&
              (_vm.saleInfo.stateChild === "1" ||
                _vm.saleInfo.stateChild === "2")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: _vm.tempBtn,
                        type: "primary",
                      },
                      on: { click: _vm.saveSumbit },
                    },
                    [_vm._v("暂存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pageType === "2"
                ? _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.returnBtn } },
                    [_vm._v("返回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.saleInfo,
            "label-width": "120px",
            "label-position": "right",
            size: "small",
          },
        },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("商品列表"),
                  ]),
                  _vm._v(" 　　"),
                  (_vm.saleInfo.state === "S1" || _vm.saleInfo.state === "") &&
                  (_vm.saleInfo.stateChild === "1" ||
                    _vm.saleInfo.stateChild === "2")
                    ? _c("el-button", {
                        attrs: {
                          type: "info",
                          icon: "el-icon-plus",
                          size: "mini",
                          circle: "",
                        },
                        on: { click: _vm.addDomain },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "padding-left": "20px" } }, [
                    _vm._v("   总商品数：" + _vm._s(_vm.saleInfo.amount)),
                  ]),
                  _c("span", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v("   含税总价: " + _vm._s(_vm.saleInfo.priceBefore)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.saleInfo.items, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "图片" } },
                                [
                                  _c("el-image", {
                                    staticStyle: { "margin-top": "0px" },
                                    attrs: {
                                      src: item.src,
                                      "preview-src-list": [item.src],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "category" + index,
                                  attrs: {
                                    label: "品类",
                                    prop: "items." + index + ".categoryId",
                                    rules: {
                                      required: true,
                                      message: "请选择品类",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("tree-select", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      options: _vm.tableData1,
                                      disabled: _vm.isDis,
                                      accordion: _vm.accordion,
                                      clearable: _vm.cTree,
                                      placeholder: "请选择品类",
                                      value: item.categoryId,
                                    },
                                    on: {
                                      getValue: function ($event) {
                                        return _vm.queryTreeSelectNodeKey(
                                          $event,
                                          index
                                        )
                                      },
                                      checkChange: function ($event) {
                                        return _vm.categoryChange($event, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "model" + index,
                                  attrs: {
                                    label: "型号",
                                    prop: "items." + index + ".modelId",
                                    rules: {
                                      required: true,
                                      message: "请选择型号",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择型号",
                                        disabled: _vm.isDis,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.modelChange(index)
                                        },
                                        "visible-change": function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                      model: {
                                        value: item.modelId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "modelId", $$v)
                                        },
                                        expression: "item.modelId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.modelSelect[index],
                                      function (items) {
                                        return _c("el-option", {
                                          key: items.id,
                                          attrs: {
                                            disabled: items.isEnable !== "1",
                                            label: items.name,
                                            value: items.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "sku" + index,
                                  attrs: {
                                    label: "物料选择",
                                    prop: "items." + index + ".skuId",
                                    rules: {
                                      required: true,
                                      message: "请选择部件",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        disabled: _vm.isDis,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.skuChange(index)
                                        },
                                        "visible-change": function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                      model: {
                                        value: item.skuId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "skuId", $$v)
                                        },
                                        expression: "item.skuId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.skuList[index],
                                      function (items) {
                                        return _c("el-option", {
                                          key: items.id,
                                          attrs: {
                                            label: items.name,
                                            disabled: items.isEnable !== "1",
                                            value: items.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "skuName" + index,
                                  attrs: {
                                    label: "物料名称",
                                    prop: "skuName" + index,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.skuName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "skuName", $$v)
                                      },
                                      expression: "item.skuName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "skuCode" + index,
                                  attrs: {
                                    label: "物料编码",
                                    prop: "skuCode" + index,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.skuCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "skuCode", $$v)
                                      },
                                      expression: "item.skuCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "brandId" + index,
                                  attrs: {
                                    label: "品牌",
                                    prop: "brandId" + index,
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: { disabled: true },
                                      model: {
                                        value: item.brandId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "brandId", $$v)
                                        },
                                        expression: "item.brandId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.brandSelect,
                                      function (items, indexs) {
                                        return _c("el-option", {
                                          key: indexs,
                                          attrs: {
                                            label: items.name,
                                            value: items.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "num" + index,
                                  attrs: {
                                    label: "购买数量",
                                    prop: "items." + index + ".num",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写数量",
                                        trigger: "blur",
                                      },
                                      {
                                        pattern: /^(([1-9]+\d*)|0)$/,
                                        message: "请正确填写整数",
                                      },
                                      {
                                        validator: function (
                                          rule,
                                          value,
                                          callback
                                        ) {
                                          if (value === 0) {
                                            return callback("不可等于0")
                                          } else {
                                            if (value > 10) {
                                              return callback(
                                                "单商品最多购买10个"
                                              )
                                            }
                                            if (
                                              _vm.saleInfo.items[index].stock
                                            ) {
                                              if (
                                                _vm.saleInfo.items[index]
                                                  .stock < value
                                              ) {
                                                return callback(
                                                  "不可大于剩余库存"
                                                )
                                              }
                                            }
                                            callback()
                                          }
                                        },
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDis },
                                    on: { blur: _vm.numChange },
                                    model: {
                                      value: item.num,
                                      callback: function ($$v) {
                                        _vm.$set(item, "num", $$v)
                                      },
                                      expression: "item.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "stock" + index,
                                  attrs: {
                                    label: "剩余库存",
                                    prop: "items." + index + ".stock",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.stock,
                                      callback: function ($$v) {
                                        _vm.$set(item, "stock", $$v)
                                      },
                                      expression: "item.stock",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "payType" + index,
                                  attrs: {
                                    label: "收费类型",
                                    prop: "items." + index + ".payType",
                                    rules: {
                                      required: true,
                                      message: "请选择收费类型",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.isDis,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.payTypeChange(index)
                                        },
                                      },
                                      model: {
                                        value: item.payType,
                                        callback: function ($$v) {
                                          _vm.$set(item, "payType", $$v)
                                        },
                                        expression: "item.payType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.payType,
                                      function (items, indexs) {
                                        return _c("el-option", {
                                          key: indexs,
                                          attrs: {
                                            label: items.label,
                                            value: items.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "priceAfterY" + index,
                                  attrs: {
                                    label: "税前单价",
                                    prop: "items." + index + ".priceAfterY",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.priceAfterY,
                                      callback: function ($$v) {
                                        _vm.$set(item, "priceAfterY", $$v)
                                      },
                                      expression: "item.priceAfterY",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "describes" + index,
                                  attrs: {
                                    label: "订购原因",
                                    prop: "items." + index + ".describes",
                                    rules: {
                                      required: true,
                                      message: "请选择商品描述",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.isDis,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: item.describes,
                                        callback: function ($$v) {
                                          _vm.$set(item, "describes", $$v)
                                        },
                                        expression: "item.describes",
                                      },
                                    },
                                    _vm._l(
                                      _vm.describeList[index],
                                      function (items, indexs) {
                                        return _c("el-option", {
                                          key: indexs,
                                          attrs: {
                                            label: items.label,
                                            value: items.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "priceBeforeY" + index,
                                  attrs: {
                                    label: "含税单价",
                                    prop: "items." + index + ".priceBeforeY",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: item.priceBeforeY,
                                      callback: function ($$v) {
                                        _vm.$set(item, "priceBeforeY", $$v)
                                      },
                                      expression: "item.priceBeforeY",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  key: "memo" + index,
                                  attrs: {
                                    label: "订单备注",
                                    prop: "items." + index + ".memo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isDis },
                                    model: {
                                      value: item.memo,
                                      callback: function ($$v) {
                                        _vm.$set(item, "memo", $$v)
                                      },
                                      expression: "item.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _vm._v("　\n              "),
                              ]),
                              _vm._v(" "),
                              (_vm.saleInfo.state === "S1" ||
                                _vm.saleInfo.state === "") &&
                              (_vm.saleInfo.stateChild === "1" ||
                                _vm.saleInfo.stateChild === "2") &&
                              index != 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeDomain(item)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("收件信息"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收件人",
                            prop: "takeUser",
                            rules: {
                              required: true,
                              message: "请填写收件人",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: _vm.isDis,
                              placeholder: "收件人",
                            },
                            model: {
                              value: _vm.saleInfo.takeUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "takeUser", $$v)
                              },
                              expression: "saleInfo.takeUser",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收件手机号",
                            prop: "takePhone",
                            rules: {
                              required: true,
                              message: "请填写收件手机号",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: _vm.isDis,
                              placeholder: "收件手机号",
                            },
                            model: {
                              value: _vm.saleInfo.takePhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "takePhone", $$v)
                              },
                              expression: "saleInfo.takePhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "省",
                            prop: "province",
                            rules: {
                              required: true,
                              message: "请选择省",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择省",
                                filterable: "",
                                clearable: "",
                                disabled: _vm.isDis,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.proviceChange(
                                    _vm.saleInfo.province
                                  )
                                },
                              },
                              model: {
                                value: _vm.saleInfo.province,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo, "province", $$v)
                                },
                                expression: "saleInfo.province",
                              },
                            },
                            _vm._l(_vm.provinceOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "市",
                            prop: "city",
                            rules: {
                              required: true,
                              message: "请选择市",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择市",
                                filterable: "",
                                clearable: "",
                                disabled: _vm.isDis,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.cityChange(_vm.saleInfo.city)
                                },
                              },
                              model: {
                                value: _vm.saleInfo.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo, "city", $$v)
                                },
                                expression: "saleInfo.city",
                              },
                            },
                            _vm._l(_vm.cityOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "区/县",
                            prop: "country",
                            rules: {
                              required: true,
                              message: "请选择区/县",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择区",
                                filterable: "",
                                disabled: _vm.isDis,
                                clearable: "",
                              },
                              model: {
                                value: _vm.saleInfo.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo, "country", $$v)
                                },
                                expression: "saleInfo.country",
                              },
                            },
                            _vm._l(_vm.countryOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收件地址",
                            prop: "takeAddress",
                            rules: {
                              required: true,
                              message: "收件地址",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: _vm.isDis,
                              placeholder: "收件地址",
                            },
                            model: {
                              value: _vm.saleInfo.takeAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "takeAddress", $$v)
                              },
                              expression: "saleInfo.takeAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "80px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("SAP信息"),
                  ]),
                  _vm._v(" 　　\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "物流公司", prop: "amount" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.saleInfo.expressCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "expressCompany", $$v)
                              },
                              expression: "saleInfo.expressCompany",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "物流单号", prop: "priceAfter" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.saleInfo.expressNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "expressNo", $$v)
                              },
                              expression: "saleInfo.expressNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货时间", prop: "priceBefore" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.saleInfo.deliveryDateStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "deliveryDateStr", $$v)
                              },
                              expression: "saleInfo.deliveryDateStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "SAP单号", prop: "spaNo" } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.saleInfo.spaNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo, "spaNo", $$v)
                              },
                              expression: "saleInfo.spaNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("请确认是否生成提交销售单 ")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirmToOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }