var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _vm.refundTransactionInfo.auditStatus === "10" ||
                  _vm.refundTransactionInfo.auditStatus === "40"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_BILL_REFUND_AUDIT_FIRST"],
                              expression: "['ROLE_BILL_REFUND_AUDIT_FIRST']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.updateFirst },
                        },
                        [_vm._v("初审通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refundTransactionInfo.auditStatus !== "30"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_BILL_REFUND_AUDIT_SECOND"],
                              expression: "['ROLE_BILL_REFUND_AUDIT_SECOND']",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            disabled:
                              _vm.refundTransactionInfo.auditStatus === "10" ||
                              _vm.refundTransactionInfo.auditStatus === "40",
                          },
                          on: { click: _vm.updateSecond },
                        },
                        [_vm._v("复审通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refundTransactionInfo.auditStatus !== "30"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_BILL_REFUND_AUDIT_REJECT"],
                              expression: "['ROLE_BILL_REFUND_AUDIT_REJECT']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.updateReject },
                        },
                        [_vm._v("审核拒绝")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.refundTransactionInfo,
                      rules: _vm.rules,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("商品信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "padding-top": "20px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("div", {
                                staticClass: "grid-content bg-purple",
                                staticStyle: { float: "right" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "1px" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadingList4Model,
                                              expression: "loadingList4Model",
                                            },
                                          ],
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            border: "",
                                            fit: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                            },
                                            "tooltip-effect": "dark",
                                            data: _vm.refundTransactionInfo
                                              .items,
                                            "span-method": _vm.objectSpanMethod,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuCode",
                                              "min-width": "120px",
                                              label: "原收款流水号",
                                              formatter: _vm.dataFormat,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuCode",
                                              "min-width": "120px",
                                              label: "退款渠道",
                                              formatter: _vm.dataFormat2,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuCode",
                                              "min-width": "120px",
                                              label: "物料编码",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuName",
                                              "min-width": "180px",
                                              label: "物料名称",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "rateName",
                                              label: "税率",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "priceAfterExp",
                                              label: "税前单价",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "priceBeforeExp",
                                              label: "含税单价",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "quantity",
                                              label: "数量",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "refundReason",
                                              label: "退款原因",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "30px" } },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "本单退货总数" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "本单退货总数",
                                              },
                                              model: {
                                                value:
                                                  _vm.refundTransactionInfo
                                                    .quantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.refundTransactionInfo,
                                                    "quantity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "refundTransactionInfo.quantity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "含税总价" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "含税总价",
                                              },
                                              model: {
                                                value:
                                                  _vm.refundTransactionInfo
                                                    .refundAmountExp,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.refundTransactionInfo,
                                                    "refundAmountExp",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "refundTransactionInfo.refundAmountExp",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "原业务单号" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "原业务单号",
                                              },
                                              model: {
                                                value:
                                                  _vm.refundTransactionInfo
                                                    .sourceOrderNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.refundTransactionInfo,
                                                    "sourceOrderNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "refundTransactionInfo.sourceOrderNum",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "业务单号" } },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "业务单号",
                                              },
                                              model: {
                                                value:
                                                  _vm.refundTransactionInfo
                                                    .orderNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.refundTransactionInfo,
                                                    "orderNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "refundTransactionInfo.orderNum",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "是否审核通过",
                          visible: _vm.dialogVisible1,
                          width: "30%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogVisible1 = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("确认后无法更改，银行转账需提前打款"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { hidden: _vm.isHidden } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                   \n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "refundDate" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: "退款日期",
                                          },
                                          model: {
                                            value:
                                              _vm.refundTransactionInfo
                                                .refundDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.refundTransactionInfo,
                                                "refundDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "refundTransactionInfo.refundDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                   \n                "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.btnLoading,
                                    expression: "btnLoading",
                                  },
                                ],
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.completeBtn,
                                },
                                on: { click: _vm.comfirm },
                              },
                              [_vm._v("确 定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.btnLoading,
                                    expression: "btnLoading",
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisible1 = false
                                  },
                                },
                              },
                              [_vm._v("取 消")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "审核拒绝",
                          visible: _vm.dialogVisible2,
                          width: "30%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogVisible2 = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 4 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "refusedReason" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择拒绝原因",
                                        },
                                        model: {
                                          value:
                                            _vm.refundTransactionInfo
                                              .refusedReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.refundTransactionInfo,
                                              "refusedReason",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "refundTransactionInfo.refusedReason",
                                        },
                                      },
                                      _vm._l(
                                        _vm.refusedReason,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.val,
                                            attrs: {
                                              label: item.name,
                                              value: item.val,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 4 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "remark" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: { placeholder: "拒绝备注" },
                                      model: {
                                        value: _vm.refundTransactionInfo.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.refundTransactionInfo,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "refundTransactionInfo.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _vm._v("\n                   \n              "),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.btnLoading,
                                    expression: "btnLoading",
                                  },
                                ],
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.completeBtn,
                                },
                                on: { click: _vm.comfirmB },
                              },
                              [_vm._v("确 定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.btnLoading,
                                    expression: "btnLoading",
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisible2 = false
                                  },
                                },
                              },
                              [_vm._v("取 消")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否审核通过",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [_c("center", [_vm._v("确认后无法更改，银行转账需提前打款")])],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirm },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否审核通过",
            visible: _vm.dialogVisible4,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible4 = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirmC },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible4 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }