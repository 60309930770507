"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _invoice = require("@/api/bill/invoice");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'invoiceDetail',
  // eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      billInvoiceInfo: {},
      loadingList4Model: false,
      isShow: false,
      imgShow: false
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryById)(param).then(function (response) {
        if (response.data) {
          _this.billInvoiceInfo = response.data;
          _this.billInvoiceInfo.custName = _this.billInvoiceInfo.custNamePage;
          _this.billInvoiceInfo.custTax = _this.billInvoiceInfo.custTaxPage;
          _this.billInvoiceInfo.custAddress = _this.billInvoiceInfo.custAddressPage;
          _this.billInvoiceInfo.custPhone = _this.billInvoiceInfo.custPhonePage;
          _this.billInvoiceInfo.custBank = _this.billInvoiceInfo.custBankPage;
          _this.billInvoiceInfo.custBankAcc = _this.billInvoiceInfo.custBankAccPage;
          _this.billInvoiceInfo.takeUser = _this.billInvoiceInfo.takeUserPage;
          _this.billInvoiceInfo.takeMobile = _this.billInvoiceInfo.takeMobilePage;
          _this.billInvoiceInfo.takeAddress = _this.billInvoiceInfo.takeAddressPage;
          _this.billInvoiceInfo.custEmail = _this.billInvoiceInfo.custEmailPage;

          if (_this.billInvoiceInfo.invoiceType === '10') {
            _this.isShow = true;
          }

          if (_this.billInvoiceInfo.charterPicture !== undefined && _this.billInvoiceInfo.charterPicture !== null && _this.billInvoiceInfo.charterPicture !== '') {
            _this.imgShow = true;
            _this.billInvoiceInfo.charterPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.billInvoiceInfo.charterPicture;
            _this.billInvoiceInfo.custQualificationPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.billInvoiceInfo.custQualificationPicture;
          }

          _this.billInvoiceInfo.items.forEach(function (it, index) {
            it.discountAmount = Number(it.discountAmount) / 100;
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/invoice'
      });
    }
  }
};
exports.default = _default;