var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.orderInfo.orderNo)),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm._f("stateFilter")(_vm.orderInfo.state))),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  _vm._s(_vm._f("subStateFilter")(_vm.orderInfo.subState))
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("new-task", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["ROLE_WORK_TASK_MY_LIST"],
                expression: "['ROLE_WORK_TASK_MY_LIST']",
              },
            ],
            attrs: {
              "work-id": _vm.orderInfo.id,
              "work-no": _vm.orderInfo.orderNo,
              "work-type": "2",
              "task-link": "40",
            },
          }),
          _vm._v(" "),
          _vm.confirmBtnStatus
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_ORDER_HANDLE_COMMIT"],
                      expression: "['ROLE_ORDER_HANDLE_COMMIT']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirm()
                    },
                  },
                },
                [_vm._v("处理完成")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_HANDLE_LIST"],
                  expression: "['ROLE_ORDER_HANDLE_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "handle_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.consumerShow,
                                  callback: function ($$v) {
                                    _vm.consumerShow = $$v
                                  },
                                  expression: "consumerShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-consumer", {
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                      on: { dataChange: _vm.dataChange },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "处理信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("处理信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "最终服务类型",
                                                      prop: "handleType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .handleType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "handleType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.handleType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "最终服务子类型",
                                                      prop: "handleSubType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .handleSubType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "handleSubType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.handleSubType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessSubTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "处理结果",
                                                      prop: "handleResult",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            !_vm.confirmBtnStatus,
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .handleResult,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "handleResult",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.handleResult",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.handleResultOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "处理备注",
                                                      prop: "handleRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.confirmBtnStatus,
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                        maxlength: "1000",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .handleRemark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "handleRemark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.handleRemark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.confirmBtnStatus
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: [
                                                  "ROLE_ORDER_CHECK_COMMIT",
                                                ],
                                                expression:
                                                  "['ROLE_ORDER_CHECK_COMMIT']",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "el-form",
                                              {
                                                ref: "schemeAddForm",
                                                attrs: {
                                                  model: _vm.schemeAdd,
                                                  rules: _vm.schemeAddrules,
                                                  "label-width": "150px",
                                                  size: "50%",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 12,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c("el-col", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "grid-content bg-purple",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        label:
                                                                          "问题分类",
                                                                        prop: "problemType",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            focus:
                                                                              _vm.loadRepairTypeSelect,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .schemeAdd
                                                                                  .problemType,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.schemeAdd,
                                                                                    "problemType",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "schemeAdd.problemType",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.repairTypeSelect,
                                                                          function (
                                                                            item,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: index,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      item.name,
                                                                                    value:
                                                                                      item.id,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("el-col", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "grid-content bg-purple",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        label:
                                                                          "问题代码",
                                                                        prop: "faultId",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              placeholder:
                                                                                "请选择",
                                                                            },
                                                                          on: {
                                                                            focus:
                                                                              _vm.loadFaultSelect,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .schemeAdd
                                                                                  .faultId,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.schemeAdd,
                                                                                    "faultId",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "schemeAdd.faultId",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.faultSelect,
                                                                          function (
                                                                            item,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: index,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      item.name,
                                                                                    value:
                                                                                      item.id,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _vm.addSchemeQ
                                                              ? _c("el-col", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "grid-content bg-purple",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "其他问题",
                                                                              prop: "problem",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              staticClass:
                                                                                "filter-item",
                                                                              attrs:
                                                                                {
                                                                                  type: "textarea",
                                                                                  autosize:
                                                                                    {
                                                                                      minRows: 4,
                                                                                    },
                                                                                  maxlength:
                                                                                    "1000",
                                                                                  placeholder:
                                                                                    "请输入其他问题代码",
                                                                                  "show-word-limit":
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .schemeAdd
                                                                                      .problem,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.schemeAdd,
                                                                                        "problem",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "schemeAdd.problem",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c("el-col", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "grid-content bg-purple",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        label:
                                                                          "处理方式",
                                                                        prop: "repairIds",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              multiple:
                                                                                "",
                                                                              filterable:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              placeholder:
                                                                                "请选择",
                                                                            },
                                                                          on: {
                                                                            focus:
                                                                              _vm.loadRepairSelect,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .schemeAdd
                                                                                  .repairIds,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.schemeAdd,
                                                                                    "repairIds",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "schemeAdd.repairIds",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.repairSelect,
                                                                          function (
                                                                            item,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: index,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      item.name,
                                                                                    value:
                                                                                      item.id,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _vm.addSchemeA
                                                              ? _c("el-col", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "grid-content bg-purple",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "其他处理",
                                                                              prop: "resolution",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              staticClass:
                                                                                "filter-item",
                                                                              attrs:
                                                                                {
                                                                                  type: "textarea",
                                                                                  autosize:
                                                                                    {
                                                                                      minRows: 4,
                                                                                    },
                                                                                  maxlength:
                                                                                    "1000",
                                                                                  placeholder:
                                                                                    "请输入其他处理方式",
                                                                                  "show-word-limit":
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .schemeAdd
                                                                                      .resolution,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.schemeAdd,
                                                                                        "resolution",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "schemeAdd.resolution",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "40px",
                                                        },
                                                        attrs: {
                                                          span: 1,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "loading",
                                                                    rawName:
                                                                      "v-loading",
                                                                    value:
                                                                      _vm.btnLoading,
                                                                    expression:
                                                                      "btnLoading",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.schemeAddBtnDisabled,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.addSchemeClick,
                                                                },
                                                              },
                                                              [_vm._v("添加")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "160px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.schemeListLoading,
                                                        expression:
                                                          "schemeListLoading",
                                                      },
                                                    ],
                                                    attrs: {
                                                      size: "mini",
                                                      border: "",
                                                      stripe: "",
                                                      "tooltip-effect": "dark",
                                                      "header-cell-style": {
                                                        background: "#eef1f6",
                                                        color: "#606266",
                                                        height: "40px",
                                                      },
                                                      data: _vm.schemeList,
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "indexNum",
                                                        label: "序号",
                                                        width: "55",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "problemTypeName",
                                                        label: "问题分类",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "faultName",
                                                        label: "问题代码",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "repairNames",
                                                        label: "处理方式",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.confirmBtnStatus
                                                      ? _c("el-table-column", {
                                                          attrs: {
                                                            label: "编辑",
                                                            align: "center",
                                                            width: "80",
                                                            "class-name":
                                                              "small-padding fixed-width",
                                                            fixed: "right",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "permission",
                                                                              rawName:
                                                                                "v-permission",
                                                                              value:
                                                                                [
                                                                                  "ROLE_ORDER_HANDLE_COMMIT",
                                                                                ],
                                                                              expression:
                                                                                "['ROLE_ORDER_HANDLE_COMMIT']",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          plain:
                                                                            "",
                                                                          type: "primary",
                                                                          size: "mini",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeSchemeClick(
                                                                                row
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "删除"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            45864414
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "部件信息" },
                                  [
                                    _c(
                                      "el-divider",
                                      {
                                        staticClass: "myStyle",
                                        attrs: { "content-position": "left" },
                                      },
                                      [_vm._v("部件信息")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: { "border-top": "none" },
                                        attrs: { shadow: "never" },
                                      },
                                      [
                                        _vm.confirmBtnStatus
                                          ? _c(
                                              "el-row",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value: [
                                                      "ROLE_ORDER_HANDLE_COMMIT",
                                                    ],
                                                    expression:
                                                      "['ROLE_ORDER_HANDLE_COMMIT']",
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "收费类型",
                                                              prop: "feeType",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.skuAdd
                                                                      .feeType,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.skuAdd,
                                                                        "feeType",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "skuAdd.feeType",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.feeTypeOptions,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.val,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 14,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "添加物料",
                                                              prop: "skuId",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择",
                                                                },
                                                                on: {
                                                                  focus:
                                                                    _vm.loadSkuSelect,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.skuAdd
                                                                      .skuCode,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.skuAdd,
                                                                        "skuCode",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "skuAdd.skuCode",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.skuSelect,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.code +
                                                                          "  " +
                                                                          item.name,
                                                                        value:
                                                                          item.code,
                                                                        disabled:
                                                                          item.isEnable ===
                                                                          "0",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "left",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.code
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "right",
                                                                              color:
                                                                                "#8492a6",
                                                                              "font-size":
                                                                                "13px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "40px",
                                                    },
                                                    attrs: { span: 1, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "loading",
                                                                rawName:
                                                                  "v-loading",
                                                                value:
                                                                  _vm.btnLoading,
                                                                expression:
                                                                  "btnLoading",
                                                              },
                                                            ],
                                                            attrs: {
                                                              disabled:
                                                                _vm.skuAddBtnDisabled,
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.addSkuClick,
                                                            },
                                                          },
                                                          [_vm._v("添加")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "160px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.skuListLoading,
                                                            expression:
                                                              "skuListLoading",
                                                          },
                                                        ],
                                                        attrs: {
                                                          size: "mini",
                                                          border: "",
                                                          stripe: "",
                                                          "tooltip-effect":
                                                            "dark",
                                                          "header-cell-style": {
                                                            background:
                                                              "#eef1f6",
                                                            color: "#606266",
                                                            height: "40px",
                                                          },
                                                          data: _vm.skuList,
                                                          "row-class-name":
                                                            _vm.tableRowClassName,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "indexNum",
                                                            label: "序号",
                                                            width: "55",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuCode",
                                                            label: "物料编码",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuName",
                                                            label: "物料名称",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuType",
                                                            label: "物料分类",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "skuTypeFilter"
                                                                      )(
                                                                        row.skuType
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuTag",
                                                            label: "物料标识",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "skuTagFilter"
                                                                      )(
                                                                        row.skuTag
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "categoryName",
                                                            label: "品类",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "modelName",
                                                            label: "型号",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "feeType",
                                                            label: "收费类型",
                                                            width: "150",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  row.edit
                                                                    ? [
                                                                        _c(
                                                                          "el-select",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "100%",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                filterable:
                                                                                  "",
                                                                                placeholder:
                                                                                  "请选择",
                                                                                size: "small",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  row.feeType,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      row,
                                                                                      "feeType",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "row.feeType",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm.feeTypeOptions,
                                                                            function (
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "el-option",
                                                                                {
                                                                                  key: index,
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        item.name,
                                                                                      value:
                                                                                        item.val,
                                                                                    },
                                                                                }
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        ),
                                                                      ]
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "feeTypeFilter"
                                                                              )(
                                                                                row.feeType
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "quantity",
                                                            label: "数量",
                                                            width: "160",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  row.edit
                                                                    ? [
                                                                        _c(
                                                                          "el-input-number",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                min: 1,
                                                                                max: 99,
                                                                                size: "small",
                                                                                width:
                                                                                  "50%",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  row.quantity,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      row,
                                                                                      "quantity",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "row.quantity",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              row.quantity
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "ddirStock",
                                                            label: "SAP数量",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "stock",
                                                            label: "库存数量",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "state",
                                                            label: "状态",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row.state ===
                                                                          "0"
                                                                          ? "已删除"
                                                                          : "可用"
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.confirmBtnStatus
                                                          ? _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label: "编辑",
                                                                  align:
                                                                    "center",
                                                                  width: "160",
                                                                  "class-name":
                                                                    "small-padding fixed-width",
                                                                  fixed:
                                                                    "right",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            row.edit
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        plain:
                                                                                          "",
                                                                                        type: "primary",
                                                                                        size: "mini",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.updateOrderSkuClick(
                                                                                            row
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "保存"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        plain:
                                                                                          "",
                                                                                        type: "primary",
                                                                                        size: "mini",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          ;[
                                                                                            (row.edit =
                                                                                              !row.edit),
                                                                                          ]
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "编辑"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    plain:
                                                                                      "",
                                                                                    type: "primary",
                                                                                    size: "mini",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.removeSkuClick(
                                                                                        row
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "删除"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2491028174
                                                                  ),
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("交付性检测")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _vm._l(
                                      _vm.checkItemList4show,
                                      function (items, index1) {
                                        return _c(
                                          "el-row",
                                          { key: index1 },
                                          _vm._l(
                                            items,
                                            function (item, index2) {
                                              return _c(
                                                "el-col",
                                                {
                                                  key: index2,
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px",
                                                        "padding-right":
                                                          "100px",
                                                        "padding-bottom":
                                                          "10px",
                                                        "padding-left": "100px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            content:
                                                              item.itemName,
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  item.itemName,
                                                                disabled:
                                                                  !_vm.confirmBtnStatus,
                                                                size: "medium",
                                                                border: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.choose,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "choose",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.choose",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "showOverflowTooltipFilter"
                                                                  )(
                                                                    item.itemName
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "padding-top": "30px" },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "前端展示",
                                                      prop: "handleShow",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.confirmBtnStatus,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .handleShow,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "handleShow",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.handleShow",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                !_vm.PricesuBStatus
                                  ? _c(
                                      "el-collapse",
                                      [
                                        _c("order-price", {
                                          ref: "checkPrice",
                                          attrs: {
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-collapse",
                                  {
                                    staticClass: "no-collapse-header",
                                    model: {
                                      value: _vm.calloutShow,
                                      callback: function ($$v) {
                                        _vm.calloutShow = $$v
                                      },
                                      expression: "calloutShow",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-collapse-item",
                                      { attrs: { name: "1" } },
                                      [
                                        _c("order-callout", {
                                          ref: "calloutOrder",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("处理附件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c("upload-file", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isReadOnly &&
                                                              !_vm.confirmBtnStatus,
                                                            "attachment-list":
                                                              _vm.orderInfo
                                                                .attachmentList4,
                                                          },
                                                          on: {
                                                            setUploadFileList:
                                                              _vm.setUploadFileList,
                                                            setBtnsState:
                                                              _vm.setBtnsState,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.step,
                                  callback: function ($$v) {
                                    _vm.step = $$v
                                  },
                                  expression: "step",
                                },
                              },
                              [
                                _vm.orderInfo.handleSubType === "0102"
                                  ? _c(
                                      "el-collapse-item",
                                      { attrs: { name: "7" } },
                                      [
                                        _c("order-recheck", {
                                          ref: "recheckOrder",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "3" } },
                                  [
                                    _c("order-check", {
                                      ref: "checkOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "2" } },
                                  [
                                    _c("order-dispatch", {
                                      ref: "dispatchOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "产品信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("咨询信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "用户描述",
                                                      prop: "userDescribe",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .userDescribe,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "userDescribe",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.userDescribe",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现前",
                                                      prop: "problemBefore",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemBefore,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemBefore",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemBefore",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现后",
                                                      prop: "problemAfter",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemAfter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemAfter",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemAfter",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "resolve方案",
                                                      prop: "problemResolve",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemResolve,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemResolve",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemResolve",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "解决方案",
                                                      prop: "problemResolve",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .solutionMemo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "solutionMemo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.solutionMemo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "咨询备注",
                                                      prop: "problemResolve",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemRemark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemRemark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemRemark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 120 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      attrs: { "tab-position": "right" },
                                      on: { "tab-click": _vm.stepClick },
                                      model: {
                                        value: _vm.step,
                                        callback: function ($$v) {
                                          _vm.step = $$v
                                        },
                                        expression: "step",
                                      },
                                    },
                                    [
                                      _c("el-tab-pane", {
                                        attrs: { label: "返机", name: "6" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "终验", name: "5" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "处理", name: "4" },
                                      }),
                                      _vm._v(" "),
                                      _vm.orderInfo.handleSubType === "0102"
                                        ? _c("el-tab-pane", {
                                            attrs: { label: "复核", name: "7" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "检测", name: "3" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "分派", name: "2" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "收货", name: "1" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-tabs el-tabs--right",
                                      staticStyle: { "padding-top": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "el-tabs__header is-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "客户",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.consumerShow === "0"
                                                      ? (_vm.consumerShow = "1")
                                                      : (_vm.consumerShow = "0")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "产品",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productShow === "0"
                                                      ? (_vm.productShow = "1")
                                                      : (_vm.productShow = "0")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: { label: "外呼" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.calloutShow === "1"
                                                      ? (_vm.calloutShow = "0")
                                                      : (_vm.calloutShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }