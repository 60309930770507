"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateOwnCustomer = createOrUpdateOwnCustomer;
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.queryById = queryById;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/customer_register/getPageList',
    method: 'post',
    params: query
  });
}
/** 查询客户详情*/


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/customer_register/queryById',
    methods: 'get',
    params: {
      id: id
    }
  });
}
/** 产品履历查询*/


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/product_app/customer_register_history/getHistoryList',
    method: 'post',
    params: query
  });
}
/*
*
*新建或更新csrm客户信息
*/


function createOrUpdateOwnCustomer(data) {
  return (0, _request.default)({
    url: '/product_app/customer_register/createOrUpdateOwnCustomer',
    method: 'post',
    data: data
  });
}