var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "5px", "padding-top": "10px" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _vm.stockOutInfo.state === "10"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_STOCK_STOCK_OUT_SAVE"],
                                expression: "['ROLE_STOCK_STOCK_OUT_SAVE']",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.tempSave()
                              },
                            },
                          },
                          [_vm._v("\n            暂存\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.stockOutInfo.state === "10"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_STOCK_STOCK_OUT_SUBMIT"],
                                expression: "['ROLE_STOCK_STOCK_OUT_SUBMIT']",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submitSave()
                              },
                            },
                          },
                          [_vm._v("\n            提交\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.stockOutInfo.state === "10" ||
                    _vm.stockOutInfo.state === "20"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_STOCK_STOCK_OUT_CONFIRM"],
                                expression: "['ROLE_STOCK_STOCK_OUT_CONFIRM']",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmSave()
                              },
                            },
                          },
                          [_vm._v("\n            确认出库\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.returnBtn } }, [
                      _vm._v("\n            返回\n          "),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.stockOutInfo,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "20px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "出库单号", prop: "num" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      placeholder: "单号",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.stockOutInfo.num,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockOutInfo, "num", $$v)
                                      },
                                      expression: "stockOutInfo.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "出库仓库",
                                    prop: "warehouseCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        disabled: _vm.isList,
                                      },
                                      model: {
                                        value: _vm.stockOutInfo.warehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stockOutInfo,
                                            "warehouseCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stockOutInfo.warehouseCode",
                                      },
                                    },
                                    _vm._l(_vm.warehouseList, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                          disabled:
                                            item.orgIsEnable !== "1" ||
                                            item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "提交人/时间", prop: "num" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "提交人/时间",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.stockOutInfo.submitAll,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stockOutInfo,
                                          "submitAll",
                                          $$v
                                        )
                                      },
                                      expression: "stockOutInfo.submitAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "出库人/时间", prop: "num" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "出库人/时间",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.stockOutInfo.confirmAll,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stockOutInfo,
                                          "confirmAll",
                                          $$v
                                        )
                                      },
                                      expression: "stockOutInfo.confirmAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "出库备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      autosize: { minRows: 2, maxRows: 6 },
                                      type: "textarea",
                                      placeholder: "Please input",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.stockOutInfo.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockOutInfo, "memo", $$v)
                                      },
                                      expression: "stockOutInfo.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("物料明细"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.skuListValidate,
                                expression: "skuListValidate",
                              },
                            ],
                            staticClass: "modelList__error",
                          },
                          [_vm._v(" 请添加物料 ！")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: { float: "right" },
                        },
                        [
                          _vm.stockOutInfo.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.addSkuClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                  _vm._v("添加\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.stockOutInfo.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.deleteBtnDisabled,
                                    type: "primary",
                                    size: "small",
                                  },
                                  on: { click: _vm.deleteModelClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              staticStyle: {
                                display: "inline-block",
                                margin: "10px",
                              },
                              attrs: {
                                "show-file-list": false,
                                "before-upload": _vm.handleBeforeUpload,
                                "on-success": _vm.handleSuccess,
                                "on-error": _vm.handleError,
                                action: _vm.uploadAction,
                              },
                            },
                            [
                              _vm.stockOutInfo.state === "10"
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "ROLE_STOCK_STOCK_OUT_ITEM_IMPORT",
                                          ],
                                          expression:
                                            "['ROLE_STOCK_STOCK_OUT_ITEM_IMPORT']",
                                        },
                                      ],
                                      attrs: { type: "primary", size: "small" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document-add",
                                      }),
                                      _vm._v("导入"),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["ROLE_STOCK_STOCK_OUT_ITEM_IMPORT"],
                                  expression:
                                    "['ROLE_STOCK_STOCK_OUT_ITEM_IMPORT']",
                                },
                              ],
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.downloadTemplate },
                            },
                            [_vm._v("导入模板下载")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "1px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingList4Model,
                                      expression: "loadingList4Model",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "header-cell-style": {
                                      background: "#eef1f6",
                                      color: "#606266",
                                    },
                                    size: "small",
                                    border: "",
                                    "tooltip-effect": "dark",
                                    data: _vm.itemList,
                                    "row-class-name": _vm.tableRowClassName,
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "row-click": _vm.rowClickSelection,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      align: "center",
                                      prop: "modelId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuCode",
                                      label: "物料编码",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuName",
                                      label: "物料名称",
                                      resizable: "",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.stockOutInfo.state !== "30"
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "quantity",
                                          label: "数量",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return _vm.stockOutInfo
                                                  .state !== "30"
                                                  ? [
                                                      !scope.row.isOver
                                                        ? _c(
                                                            "el-popover",
                                                            {
                                                              attrs: {
                                                                trigger:
                                                                  "hover",
                                                                placement:
                                                                  "top",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " 库存数量不足"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    slot: "reference",
                                                                  },
                                                                  slot: "reference",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .quantity
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      scope.row.isOver
                                                        ? _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .quantity
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : undefined
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.stockOutInfo.state === "30"
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "quantity",
                                          label: "数量",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.stockOutInfo.state !== "30"
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "stockNum",
                                          label: "库存数量",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "总数量:" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-tag",
                                { staticStyle: { "min-width": "100px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.stockOutInfo.quantity) + "个"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加物料", visible: _vm.addSkudialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.addSkudialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "型号选择", prop: "modelId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择...",
                                  },
                                  model: {
                                    value: _vm.listQuery.modelId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "modelId", $$v)
                                    },
                                    expression: "listQuery.modelId",
                                  },
                                },
                                _vm._l(_vm.modelList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.id,
                                      disabled: item.isEnable !== "1",
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "物料编码/名称(模糊搜索)",
                                },
                                model: {
                                  value: _vm.listQuery.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "sku", $$v)
                                  },
                                  expression: "listQuery.sku",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("搜索")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.resetBtn },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "listTableDiv" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableKey",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark",
                    data: _vm.list,
                    "header-cell-style": {
                      background: "#eef1f6",
                      color: "#606266",
                    },
                    "highlight-current-row": "",
                  },
                },
                [
                  _vm._v("\n        >\n        "),
                  _c("el-table-column", {
                    attrs: { prop: "skuCode", label: "物料编码" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "skuName", label: "物料名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "usableStock", label: "剩余库存" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: row.quantitys,
                                callback: function ($$v) {
                                  _vm.$set(row, "quantitys", $$v)
                                },
                                expression: "row.quantitys",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleModifyStatus(row, "true")
                                  },
                                },
                              },
                              [_vm._v("\n              添加\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleFilter,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addSkudialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }