"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _list = require("@/api/consult/list");

var _return = require("@/api/sale/return");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

var _FixTop = _interopRequireDefault(require("@/components/FixTop"));

var _constant = _interopRequireDefault(require("@/api/sale/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
// 可拖拽弹出框
var _default = {
  name: 'qiyuReturnDetail',
  // eslint-disable-line
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default,
    permission: _index.default
  },
  components: {
    FixTop: _FixTop.default
  },
  data: function data() {
    return {
      checkList: [],
      isSf: false,
      id: this.$route.query.id,
      flag: this.$route.query.dialogStatus,
      sessionId: this.$route.query.sessionId,
      phone: this.$route.query.phone,
      pageType: this.$route.query.pageType,
      isSearch: true,
      tempBtn: false,
      completeBtn: false,
      isDis: false,
      dialogVisible: false,
      btnLoading: false,
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      payType: _constant.default.payType,
      isOverOptions: [{
        name: '是',
        val: '1'
      }, {
        name: '否',
        val: '0'
      }],
      loadingList4Model: false,
      isList: true,
      rules: {},
      bookTimePerList: [{
        'val': '08:00~10:00',
        'name': '08:00~10:00'
      }, {
        'val': '10:00~12:00',
        'name': '10:00~12:00'
      }, {
        'val': '12:00~14:00',
        'name': '12:00~14:00'
      }, {
        'val': '14:00~16:00',
        'name': '14:00~16:00'
      }, {
        'val': '16:00~18:00',
        'name': '16:00~18:00'
      }, {
        'val': '18:00~20:00',
        'name': '18:00~20:00'
      }, {
        'val': '18:00~19:59',
        'name': '18:00~19:59'
      }],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      saleReturnInfo: {
        bookDate: '',
        bookClock: '',
        sendPhone: '',
        salePhone: '',
        sendUser: '',
        sendAddress: '',
        province: '',
        city: '',
        country: '',
        amount: 0,
        priceBefore: '0.00',
        urgent: '1',
        sendType: '1',
        expressMemo: '',
        items: [],
        items2: []
      },
      multipleSelection: [],
      reasonList: [{
        label: '七天无理由',
        value: '01'
      }, {
        label: '发货慢',
        value: '02'
      }, {
        label: '拍错/拍多',
        value: '03'
      }, {
        label: '配件不适用',
        value: '04'
      }, {
        label: '无库存',
        value: '05'
      }, {
        label: '质量问题',
        value: '06'
      }, {
        label: '外观/包装',
        value: '07'
      }, {
        label: '其他',
        value: '08'
      }]
    };
  },
  created: function created() {
    this.getProvinceOptions();

    if (this.id) {
      this.getSaleReturnInfo();
    }
  },
  methods: {
    checkboxInit: function checkboxInit(row) {
      if (this.isDis === false) {
        if (row.isOver === '1') {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    },
    handleSelectionChange: function handleSelectionChange(val, rows) {
      var _this = this;

      var selected = val.length && val.indexOf(rows) !== -1;
      this.$refs['dataForm'].clearValidate();

      if (selected) {
        this.multipleSelection = [];

        if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
          this.saleReturnInfo.items.forEach(function (item, index) {
            if (item.saleNo === val[0].saleNo) {
              item.isDisabled = false;
              item.reduceNum = '0';

              _this.$refs.multipleTable.toggleRowSelection(_this.saleReturnInfo.items[index], true);

              _this.multipleSelection.push(item);
            } else {
              item.isDisabled = true;
              item.reason = '';
              item.memo = '';
              item.reduceNum = '';

              _this.$refs.multipleTable.toggleRowSelection(_this.saleReturnInfo.items[index], false);
            }
          });
        }
      } else {
        if (this.multipleSelection !== null && this.multipleSelection !== undefined && this.multipleSelection.length > 1) {
          if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
            for (var i = 0; i < this.saleReturnInfo.items.length; i++) {
              var items = this.saleReturnInfo.items[i];

              if (items.saleNo === rows.saleNo && items.skuCode === rows.skuCode) {
                items.isDisabled = true;
                items.reason = '';
                items.memo = '';
                items.reduceNum = '';
                this.multipleSelection.some(function (item2, i) {
                  if (item2.saleNo === rows.saleNo && item2.skuCode === rows.skuCode) {
                    _this.multipleSelection.splice(i, 1);
                  }
                });
                return;
              }
            }
          }
        } else {
          this.multipleSelection = [];

          if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
            this.saleReturnInfo.items.forEach(function (item, index) {
              item.isDisabled = true;

              _this.$refs.multipleTable.toggleRowSelection(_this.saleReturnInfo.items[index], false);
            });
          }
        }
      }
    },
    // 查询条件清空
    clearSearch: function clearSearch() {
      this.isSearch = false;
      this.saleReturnInfo.salePhone = '';
      this.saleReturnInfo.items = [];
      this.$refs['dataForm'].clearValidate(['salePhone']);
      this.multipleSelection = [];
    },
    phoneSearch: function phoneSearch() {
      var _this2 = this;

      (0, _return.qiyuPhoneQuery)({
        phone: this.saleReturnInfo.salePhone,
        id: this.id,
        status: 'S2'
      }).then(function (response) {
        if (response.data) {
          _this2.saleReturnInfo.items = response.data;
          console.log(_this2.saleReturnInfo.items);

          if (!_this2.saleReturnInfo.items || _this2.saleReturnInfo.items.length === 0) {
            _this2.isList = true;
          } else {
            _this2.isList = false;
          }

          if (_this2.saleReturnInfo.items !== null && _this2.saleReturnInfo.items.length > 0) {
            _this2.saleReturnInfo.items.forEach(function (item, index) {
              item.isDisabled = true; // this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], false)
            });
          }
        }
      });
      this.isSearch = true;
    },
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this3.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this4 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this4.cityOptions = response.data;
        });
      }

      this.saleReturnInfo.cityId = '';
      this.saleReturnInfo.districtId = '';
    },
    cityChange: function cityChange(city) {
      var _this5 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this5.countryOptions = response.data;
        });
        (0, _return.getCityLastTime)(city).then(function (response) {
          if (response !== null && response.code === 200) {
            _this5.bookTimePerList = [];
            _this5.saleReturnInfo.bookClock = '';
            _this5.bookTimePerList = response.data;
          }
        });
      }

      this.saleReturnInfo.districtId = '';
    },
    // 选顺丰上门取件时间
    changeBookDate: function changeBookDate() {
      this.saleReturnInfo.bookClock = '';
    },
    // 选时间段判断，是否是有效顺丰上门取件时间
    changeBookClock: function changeBookClock() {
      var _this6 = this;

      var param = {
        bookDate: this.saleReturnInfo.bookDate,
        bookClock: this.saleReturnInfo.bookClock
      };
      (0, _list.validateBookClock)(param).then(function (res) {
        if (res.data) {
          _this6.$nextTick(function () {
            _this6.$refs['dataForm'].clearValidate();
          });
        } else {
          _this6.$message({
            showClose: true,
            message: res.msg || '请选择你方便的时间段！',
            type: 'warning'
          });
        }
      });
    },
    sendTypeClick: function sendTypeClick() {
      var _this7 = this;

      if (this.saleReturnInfo.sendType === '1') {
        this.isSf = false;
      } else {
        this.isSf = true;
        this.saleReturnInfo.bookDate = '';
        this.saleReturnInfo.bookClock = '';
        this.saleReturnInfo.expressMemo = '';
        this.$nextTick(function () {
          _this7.$refs['dataForm'].clearValidate();
        });
      }
    },
    numChange: function numChange() {
      var a = 0;
      var b = 0;
      this.saleReturnInfo.items.forEach(function (it) {
        if (it.reduceNum === '' || it.reduceNum === undefined || it.reduceNum === null) {
          a = parseInt(a) + 0;
          b = Number(b) + 0;
        } else {
          a = parseInt(a) + parseInt(it.reduceNum);
          b = Number(b) + Number(it.priceBeforeY) * parseInt(it.reduceNum);
        }
      });
      this.saleReturnInfo.amount = a;
      this.saleReturnInfo.priceBefore = b;
    },
    // 获取详细
    getSaleReturnInfo: function getSaleReturnInfo() {
      var _this8 = this;

      var param = {
        id: this.id
      };

      if (this.flag === 'create' || this.flag === 'query') {
        param = {
          consultId: this.id
        };
      }

      (0, _return.queryQiyuReturnById)(param).then(function (response) {
        if (response.data) {
          _this8.saleReturnInfo = response.data;
          _this8.saleReturnInfo.priceBefore = _this8.saleReturnInfo.priceBeforeY;
          _this8.isList = true;

          if (_this8.saleReturnInfo.stateChild === '1' || _this8.saleReturnInfo.stateChild === '2') {
            _this8.isSearch = true;
            _this8.isDis = false;
          } else {
            _this8.isSearch = false;
            _this8.isDis = true;
          }

          _this8.saleReturnInfo.items = response.data.items;

          if (_this8.flag === 'update' || _this8.flag === 'query') {
            if (!_this8.saleReturnInfo.items || _this8.saleReturnInfo.items.length === 0) {
              _this8.isList = true;
            } else {
              _this8.isList = false;
            }

            _this8.isSearch = true;
          } else {
            _this8.isSearch = false;
          }

          if (_this8.saleReturnInfo.sendType === '1') {
            _this8.isSf = false;
          } else {
            _this8.isSf = true;
          }

          if (_this8.saleReturnInfo.items !== null && _this8.saleReturnInfo.items.length > 0) {
            _this8.saleReturnInfo.items.forEach(function (item, index) {
              if (item.saleNo === _this8.saleReturnInfo.saleNo && item.reason !== null && item.reason !== undefined && item.reason !== '') {
                item.choose = true;
                item.isDisabled = false;

                _this8.multipleSelection.push(item);
              } else {
                item.choose = false;
                item.isDisabled = true;
              }
            });

            if (!(_this8.saleReturnInfo.stateChild === '1' || _this8.saleReturnInfo.stateChild === '2')) {
              _this8.saleReturnInfo.items = _this8.multipleSelection;
            }
          }

          if (_this8.saleReturnInfo.province != null && _this8.saleReturnInfo.province !== '') {
            (0, _select.getAreasByParentId)(_this8.saleReturnInfo.province).then(function (response) {
              _this8.cityOptions = response.data;
            });
          } // 区/县  回显


          if (_this8.saleReturnInfo.city != null && _this8.saleReturnInfo.city !== '') {
            (0, _select.getAreasByParentId)(_this8.saleReturnInfo.city).then(function (response) {
              _this8.countryOptions = response.data;
            });
          }
        }
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this9.saleReturnInfo.items === null || _this9.saleReturnInfo.items.length < 1) {
            _this9.$message({
              showClose: true,
              message: '请查询手机号后 添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this9.multipleSelection === null || _this9.multipleSelection.length < 1) {
            _this9.$message({
              showClose: true,
              message: '请添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this9.saleReturnInfo.sendType === '1') {
            if (_this9.saleReturnInfo.bookDate === null || _this9.saleReturnInfo.bookDate === '' || _this9.saleReturnInfo.bookDate === undefined) {
              _this9.$message({
                showClose: true,
                message: '选择顺丰上门时请选择预约取件日期',
                type: 'warning'
              });

              return;
            }

            if (_this9.saleReturnInfo.bookClock === null || _this9.saleReturnInfo.bookClock === '' || _this9.saleReturnInfo.bookClock === undefined) {
              _this9.$message({
                showClose: true,
                message: '选择顺丰上门时请选择预约取件时间',
                type: 'warning'
              });

              return;
            }
          }

          _this9.saleReturnInfo.items2 = _this9.multipleSelection;
          (0, _return.saveQiyuSaleReturn)(_this9.saleReturnInfo).then(function (response) {
            if (response.data) {
              _this9.$message({
                showClose: true,
                message: '销售退货单暂存成功！',
                type: 'success'
              });

              _this9.$store.dispatch('tagsView/delView', _this9.$route);

              if (_this9.pageType === '1') {
                _this9.$router.push({
                  name: 'qiyuDetail',
                  query: {
                    id: _this9.id,
                    sessionId: _this9.sessionId,
                    phone: _this9.phone
                  }
                });
              } else {
                _this9.$router.push({
                  name: 'qiyuList',
                  query: {
                    id: _this9.id,
                    sessionId: _this9.sessionId,
                    phone: _this9.phone
                  }
                });
              }
            } else {
              _this9.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this10 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this10.saleReturnInfo.items === null || _this10.saleReturnInfo.items.length < 1) {
            _this10.$message({
              showClose: true,
              message: '请查询手机号后 添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this10.multipleSelection === null || _this10.multipleSelection.length < 1) {
            _this10.$message({
              showClose: true,
              message: '请添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this10.saleReturnInfo.sendType === '1') {
            if (_this10.saleReturnInfo.bookDate === null || _this10.saleReturnInfo.bookDate === '' || _this10.saleReturnInfo.bookDate === undefined) {
              _this10.$message({
                showClose: true,
                message: '选择顺丰上门时请选择预约取件日期',
                type: 'warning'
              });

              return;
            }

            if (_this10.saleReturnInfo.bookClock === null || _this10.saleReturnInfo.bookClock === '' || _this10.saleReturnInfo.bookClock === undefined) {
              _this10.$message({
                showClose: true,
                message: '选择顺丰上门时请选择预约取件时间',
                type: 'warning'
              });

              return;
            }
          }

          if (_this10.saleReturnInfo.sendType === '1') {
            var param = {
              bookDate: _this10.saleReturnInfo.bookDate,
              bookClock: _this10.saleReturnInfo.bookClock
            };
            (0, _list.validateBookClock)(param).then(function (res) {
              if (res.data) {
                _this10.dialogVisible = true;
              } else {
                _this10.$message({
                  showClose: true,
                  message: res.msg || '请选择你方便的时间段！',
                  type: 'warning'
                });
              }
            });
          } else {
            _this10.dialogVisible = true;
          }
        }
      });
    },
    comfirmToOrder: function comfirmToOrder() {
      var _this11 = this;

      this.completeBtn = true;
      this.saleReturnInfo.items2 = this.multipleSelection;
      (0, _return.qiyuComplete)(this.saleReturnInfo).then(function (response) {
        if (response.data) {
          _this11.$message({
            showClose: true,
            message: '销售退货单生成提交成功！',
            type: 'success'
          });

          _this11.$store.dispatch('tagsView/delView', _this11.$route);

          if (_this11.pageType === '1') {
            _this11.$router.push({
              name: 'qiyuDetail',
              query: {
                id: _this11.id,
                sessionId: _this11.sessionId,
                phone: _this11.phone
              }
            });
          } else {
            _this11.$router.push({
              name: 'qiyuList',
              query: {
                id: _this11.id,
                sessionId: _this11.sessionId,
                phone: _this11.phone
              }
            });
          }
        } else {
          _this11.completeBtn = false;

          _this11.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);

      if (this.pageType === '1') {
        this.$router.push({
          name: 'qiyuDetail',
          query: {
            id: this.id,
            sessionId: this.sessionId,
            phone: this.phone
          }
        });
      } else {
        this.$router.push({
          name: 'qiyuList',
          query: {
            id: this.id,
            sessionId: this.sessionId,
            phone: this.phone
          }
        });
      }
    },
    remainderNumFormat: function remainderNumFormat(row) {
      if (row.isOver === '1') {
        return '0';
      } else {
        return row.remainderNum;
      }
    },
    isOverFormat: function isOverFormat(row) {
      if (row.isOver !== null && row.isOver !== '' && row.isOver !== undefined) {
        if (row.isOver === '1') {
          return '是';
        } else {
          return '否';
        }
      } else {
        return '否';
      }
    },
    payTypeFormat: function payTypeFormat(row) {
      return this.payType.map(function (type) {
        if (row.payType.trim() === type.value) {
          return type.label;
        }

        return '';
      });
    },
    billTypeFormat: function billTypeFormat(row) {
      return '纸质发票';
    },
    stateFormat: function stateFormat(row) {
      if (row.reason === null || row.reason === undefined) {
        return '';
      }

      return this.reasonList.map(function (type) {
        if (row.reason.trim() === type.value) {
          return type.label;
        }

        return '';
      });
    },
    // 选择产品
    handleChange: function handleChange(val) {
      console.log(val);
      console.log(this.checkList);
    },
    checkRow: function checkRow(checked, itemA, indexA) {
      var _this12 = this;

      console.log(checked);
      console.log(itemA);
      console.log(indexA);

      if (checked) {
        this.multipleSelection = [];

        if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
          this.saleReturnInfo.items.forEach(function (item, index) {
            if (item.saleNo === itemA.saleNo) {
              item.choose = true;
              item.isDisabled = false;
              item.reduceNum = '0'; // this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], true)

              _this12.multipleSelection.push(item);
            } else {
              item.choose = false;
              item.isDisabled = true;
              item.reason = '';
              item.memo = '';
              item.reduceNum = ''; // this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], false)
            }
          });
        }

        this.$nextTick(function () {
          _this12.$refs['dataForm'].clearValidate();
        });
      } else {
        if (this.multipleSelection !== null && this.multipleSelection !== undefined && this.multipleSelection.length > 1) {
          if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
            for (var i = 0; i < this.saleReturnInfo.items.length; i++) {
              var items = this.saleReturnInfo.items[i];

              if (items.saleNo === itemA.saleNo && items.skuCode === itemA.skuCode) {
                items.choose = false;
                items.isDisabled = true;
                items.reason = '';
                items.memo = '';
                items.reduceNum = '';
                this.multipleSelection.some(function (item2, i) {
                  if (item2.saleNo === itemA.saleNo && item2.skuCode === itemA.skuCode) {
                    _this12.multipleSelection.splice(i, 1);
                  }
                });
                return;
              }
            }
          }
        } else {
          this.multipleSelection = [];

          if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
            this.saleReturnInfo.items.forEach(function (item, index) {
              item.choose = false;
              item.isDisabled = true; // this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], false)
            });
          }
        }

        this.$nextTick(function () {
          _this12.$refs['dataForm'].clearValidate();
        });
      }
    }
  }
};
exports.default = _default;