"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _overseasSku = require("@/api/basics/overseasSku/overseasSku");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _constant = _interopRequireDefault(require("@/api/order/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  data: function data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      overseasSku: {},
      countryOptions: _constant.default.countryOptions,
      rules: {
        overseasCode: [{
          required: true,
          message: '海外物料代码是必填的',
          trigger: 'blur'
        }, {
          max: 30,
          message: '最大长度 30 个字符',
          trigger: 'blur'
        }],
        overseasName: [{
          required: true,
          message: '海外物料名称是必填的',
          trigger: 'blur'
        }],
        overseasPre: [{
          required: true,
          message: '海外SN前三位是必填的',
          trigger: 'blur'
        }, {
          len: 3,
          message: '必须为三位',
          trigger: 'blur'
        }],
        skuCode: [{
          required: true,
          message: '国行sku编码是必填的',
          trigger: 'blur'
        }],
        country: [{
          required: true,
          message: '国家是必选的',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    if (!this.isCreate) {
      this.initBaseSku();
    } else {
      this.overseasSku.status = '1';
    }
  },
  methods: {
    initBaseSku: function initBaseSku() {
      var _this = this;

      (0, _overseasSku.queryById)(this.id).then(function (response) {
        if (response.data && response.code === 200) {
          _this.overseasSku = response.data;
        }
      });
    },
    saveSumbit: function saveSumbit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _overseasSku.saveData)(_this2.overseasSku).then(function (res) {
            if (res.data) {
              _this2.$message({
                showClose: true,
                message: '保存成功',
                type: 'success'
              });

              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                path: '/basics/overseasSku/list'
              });
            } else {
              _this2.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              });
            }

            _this2.btnLoading = false;
          });
        } else {
          _this2.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    }
  }
};
exports.default = _default;