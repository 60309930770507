"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billPush = billPush;
exports.fetchList = fetchList;
exports.queryById = queryById;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Author: Lee
 * @Date: 2020-10-13 18:44:20
 * @LastEditTime: 2020-10-20 17:50:45
 * @Description:
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/fail/list',
    method: 'post',
    params: query
  });
}

function queryById(query) {
  return (0, _request.default)({
    url: '/bus_app/fail/queryById',
    method: 'post',
    params: query
  });
}

function update(data) {
  return (0, _request.default)({
    url: '/bus_app/fail/update',
    method: 'post',
    data: data
  });
}

function billPush(data) {
  return (0, _request.default)({
    url: '/bus_app/fail/push',
    method: 'post',
    data: data
  });
}