var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  (_vm.saleReturnInfo.stateChild === "1" ||
                    _vm.saleReturnInfo.stateChild === "2") &&
                  !_vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_RETURN_ORDER_COMPLETE"],
                              expression: "['ROLE_SALE_RETURN_ORDER_COMPLETE']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.completeSubmit },
                        },
                        [_vm._v("生成提交")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.saleReturnInfo.stateChild === "1" ||
                    _vm.saleReturnInfo.stateChild === "2") &&
                  !_vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_RETURN_ORDER_TEMP"],
                              expression: "['ROLE_SALE_RETURN_ORDER_TEMP']",
                            },
                          ],
                          attrs: { disabled: _vm.tempBtn, type: "primary" },
                          on: { click: _vm.saveSumbit },
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_RETURN_ORDER_CANCEL"],
                              expression: "['ROLE_SALE_RETURN_ORDER_CANCEL']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.updateCancel },
                        },
                        [_vm._v("服务取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.saleReturnInfo,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("寄件信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件人",
                                            prop: "sendUser",
                                            rules: {
                                              required: true,
                                              message: "请填写寄件人",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "寄件人",
                                            },
                                            model: {
                                              value:
                                                _vm.saleReturnInfo.sendUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "sendUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleReturnInfo.sendUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "原销售手机号",
                                            prop: "salePhone",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请填写原销售手机号",
                                                trigger: "blur",
                                              },
                                              {
                                                pattern: /^1[123456789]\d{9}$/,
                                                message: "请输入正确的手机号码",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "filter-item",
                                              attrs: {
                                                placeholder: "销售手机号",
                                                disabled: _vm.isSearch,
                                              },
                                              model: {
                                                value:
                                                  _vm.saleReturnInfo.salePhone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saleReturnInfo,
                                                    "salePhone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saleReturnInfo.salePhone",
                                              },
                                            },
                                            [
                                              _vm.isSearch === false
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        slot: "append",
                                                        type: "primary",
                                                        icon: "el-icon-search",
                                                      },
                                                      on: {
                                                        click: _vm.phoneSearch,
                                                      },
                                                      slot: "append",
                                                    },
                                                    [_vm._v("查询")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isSearch === true &&
                                              (_vm.saleReturnInfo.stateChild ===
                                                "1" ||
                                                _vm.saleReturnInfo
                                                  .stateChild === "2")
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        slot: "append",
                                                        type: "primary",
                                                        icon: "el-icon-delete",
                                                      },
                                                      on: {
                                                        click: _vm.clearSearch,
                                                      },
                                                      slot: "append",
                                                    },
                                                    [_vm._v("清空")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件手机号",
                                            prop: "sendPhone",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请填写寄件手机号",
                                                trigger: "blur",
                                              },
                                              {
                                                pattern: /^1[123456789]\d{9}$/,
                                                message: "请输入正确的手机号码",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              placeholder: "寄件手机号",
                                              disabled: _vm.isDis,
                                            },
                                            model: {
                                              value:
                                                _vm.saleReturnInfo.sendPhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "sendPhone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleReturnInfo.sendPhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "省",
                                          prop: "province",
                                          rules: {
                                            required: true,
                                            message: "请选择省",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.proviceChange(
                                                  _vm.saleReturnInfo.province
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.saleReturnInfo.province,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "province",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleReturnInfo.province",
                                            },
                                          },
                                          _vm._l(
                                            _vm.provinceOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "市",
                                          prop: "city",
                                          rules: {
                                            required: true,
                                            message: "请选择市",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择市",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.cityChange(
                                                  _vm.saleReturnInfo.city
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.saleReturnInfo.city,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression: "saleReturnInfo.city",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cityOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "区/县",
                                          prop: "country",
                                          rules: {
                                            required: true,
                                            message: "请选择区/县",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择区",
                                              filterable: "",
                                              disabled: _vm.isDis,
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.saleReturnInfo.country,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleReturnInfo.country",
                                            },
                                          },
                                          _vm._l(
                                            _vm.countryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件地址",
                                            prop: "sendAddress",
                                            rules: {
                                              required: true,
                                              message: "寄件地址",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "寄件地址",
                                            },
                                            model: {
                                              value:
                                                _vm.saleReturnInfo.sendAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleReturnInfo,
                                                  "sendAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleReturnInfo.sendAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "邮寄方式",
                                            prop: "urgent",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.isDis },
                                              on: { change: _vm.sendTypeClick },
                                              model: {
                                                value:
                                                  _vm.saleReturnInfo.sendType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saleReturnInfo,
                                                    "sendType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saleReturnInfo.sendType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("顺丰上门")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("自寄")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { attrs: { hidden: _vm.isSf } },
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "预约取件日期",
                                                prop: "bookDate",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled: _vm.isDis,
                                                  "picker-options":
                                                    _vm.pickerOptions,
                                                  type: "date",
                                                  "value-format": "yyyy-MM-dd",
                                                  placeholder: "预约取件日期",
                                                },
                                                on: {
                                                  change: _vm.changeBookDate,
                                                },
                                                model: {
                                                  value:
                                                    _vm.saleReturnInfo.bookDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saleReturnInfo,
                                                      "bookDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "saleReturnInfo.bookDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "预约取件时间",
                                                prop: "bookClock",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDis,
                                                    filterable: "",
                                                    clearable: "",
                                                    placeholder: "请选择时间段",
                                                  },
                                                  on: {
                                                    change: _vm.changeBookClock,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.saleReturnInfo
                                                        .bookClock,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.saleReturnInfo,
                                                        "bookClock",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "saleReturnInfo.bookClock",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.bookTimePerList,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.val,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { hidden: _vm.isSf } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "顺丰备注",
                                                prop: "expressMemo",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  placeholder: "请输入内容",
                                                  maxlength: "100",
                                                  "show-word-limit": "",
                                                  disabled: _vm.isDis,
                                                },
                                                model: {
                                                  value:
                                                    _vm.saleReturnInfo
                                                      .expressMemo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saleReturnInfo,
                                                      "expressMemo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "saleReturnInfo.expressMemo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover", hidden: _vm.isList },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("商品列表")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "padding-top": "20px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("div", {
                                staticClass: "grid-content bg-purple",
                                staticStyle: { float: "right" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "1px" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadingList4Model,
                                              expression: "loadingList4Model",
                                            },
                                          ],
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            border: "",
                                            fit: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                            },
                                            "tooltip-effect": "dark",
                                            data: _vm.saleReturnInfo.items,
                                          },
                                          on: {
                                            select: _vm.handleSelectionChange,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "55",
                                              selectable: _vm.checkboxInit,
                                              align: "center",
                                              prop: "itemId",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "saleNo",
                                              "min-width": "120px",
                                              label: "所属销售单",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "buyTime",
                                              "min-width": "180px",
                                              label: "支付日期",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "isOver",
                                              label: "超过质保期",
                                              "min-width": "100px",
                                              formatter: _vm.isOverFormat,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuCode",
                                              label: "物料编码",
                                              "min-width": "120px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "skuName",
                                              label: "物料名称",
                                              "min-width": "200px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "payType",
                                              label: "收费类型",
                                              formatter: _vm.payTypeFormat,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "billType",
                                              label: "发票类型",
                                              formatter: _vm.billTypeFormat,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "priceBeforeY",
                                              label: "含税单价",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "buyNum",
                                              label: "购买数量",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "returnNum",
                                              label: "已退货数",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "remainderNum",
                                              label: "可退货数",
                                              formatter: _vm.remainderNumFormat,
                                            },
                                          }),
                                          _vm._v(" "),
                                          !_vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "本单退货数",
                                                  "min-width": "100px",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return !_vm.isDis
                                                          ? [
                                                              !_vm.isDis
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0",
                                                                        label:
                                                                          "",
                                                                        prop:
                                                                          "items." +
                                                                          scope.$index +
                                                                          ".reduceNum",
                                                                        rules: [
                                                                          {
                                                                            validator:
                                                                              function (
                                                                                rule,
                                                                                value,
                                                                                callback
                                                                              ) {
                                                                                if (
                                                                                  scope
                                                                                    .row
                                                                                    .isDisabled !==
                                                                                    undefined &&
                                                                                  !scope
                                                                                    .row
                                                                                    .isDisabled
                                                                                ) {
                                                                                  if (
                                                                                    value ===
                                                                                      "" ||
                                                                                    value ===
                                                                                      null ||
                                                                                    value ===
                                                                                      undefined
                                                                                  ) {
                                                                                    return callback(
                                                                                      "请输入"
                                                                                    )
                                                                                  }
                                                                                  var numRe =
                                                                                    new RegExp(
                                                                                      /^\+?[1-9][0-9]*$/
                                                                                    )
                                                                                  if (
                                                                                    !numRe.test(
                                                                                      value
                                                                                    )
                                                                                  ) {
                                                                                    return callback(
                                                                                      "请输入整数"
                                                                                    )
                                                                                  }
                                                                                  if (
                                                                                    scope
                                                                                      .row
                                                                                      .remainderNum ===
                                                                                    "0"
                                                                                  ) {
                                                                                    if (
                                                                                      parseInt(
                                                                                        value
                                                                                      ) >
                                                                                      0
                                                                                    ) {
                                                                                      return callback(
                                                                                        "不可超过可退货数"
                                                                                      )
                                                                                    }
                                                                                  } else {
                                                                                    if (
                                                                                      parseInt(
                                                                                        value
                                                                                      ) >
                                                                                      parseInt(
                                                                                        scope
                                                                                          .row
                                                                                          .remainderNum
                                                                                      )
                                                                                    ) {
                                                                                      return callback(
                                                                                        "不可超过可退货数"
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                } else {
                                                                                }
                                                                                callback()
                                                                              },
                                                                            trigger:
                                                                              "blur",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.isDis
                                                                        ? _c(
                                                                            "el-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    scope
                                                                                      .row
                                                                                      .isDisabled,
                                                                                  size: "small",
                                                                                },
                                                                              on: {
                                                                                blur: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.numChange()
                                                                                },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .reduceNum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        scope.row,
                                                                                        "reduceNum",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "scope.row.reduceNum",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : undefined
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "reduceNum",
                                                  label: "本单退货数",
                                                  "min-width": "100px",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "本单退货原因",
                                                  "min-width": "160px",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return !_vm.isDis
                                                          ? [
                                                              !_vm.isDis
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0",
                                                                        label:
                                                                          "",
                                                                        prop:
                                                                          "items." +
                                                                          scope.$index +
                                                                          ".reason",
                                                                        rules: [
                                                                          {
                                                                            validator:
                                                                              function (
                                                                                rule,
                                                                                value,
                                                                                callback
                                                                              ) {
                                                                                if (
                                                                                  scope
                                                                                    .row
                                                                                    .isDisabled !==
                                                                                    undefined &&
                                                                                  !scope
                                                                                    .row
                                                                                    .isDisabled
                                                                                ) {
                                                                                  if (
                                                                                    value ===
                                                                                      "" ||
                                                                                    value ===
                                                                                      null ||
                                                                                    value ===
                                                                                      undefined
                                                                                  ) {
                                                                                    return callback(
                                                                                      "请选择"
                                                                                    )
                                                                                  }
                                                                                }
                                                                                callback()
                                                                              },
                                                                            trigger:
                                                                              "blur",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticClass:
                                                                            "filter-item",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              align:
                                                                                "center",
                                                                              disabled:
                                                                                scope
                                                                                  .row
                                                                                  .isDisabled,
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                scope
                                                                                  .row
                                                                                  .reason,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    scope.row,
                                                                                    "reason",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "scope.row.reason",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.reasonList,
                                                                          function (
                                                                            item,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: index,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      item.label,
                                                                                    value:
                                                                                      item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : undefined
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "reason",
                                                  label: "本单退货原因",
                                                  "min-width": "160px",
                                                  formatter: _vm.stateFormat,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "退货备注",
                                                  "min-width": "200px",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return !_vm.isDis
                                                          ? [
                                                              !_vm.isDis
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0",
                                                                        label:
                                                                          "",
                                                                        prop:
                                                                          "items." +
                                                                          scope.$index +
                                                                          ".memo",
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.isDis
                                                                        ? _c(
                                                                            "el-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    scope
                                                                                      .row
                                                                                      .isDisabled,
                                                                                  size: "small",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .memo,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        scope.row,
                                                                                        "memo",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "scope.row.memo",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : undefined
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm.isDis
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "memo",
                                                  label: "退货备注",
                                                  "min-width": "200px",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-form-item", {
                                        attrs: { label: "本单退货总数:" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(_vm.saleReturnInfo.amount) +
                                            "个"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-form-item", {
                                        attrs: { label: "含税总价:" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.saleReturnInfo.priceBefore
                                          ) + " 元"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("请确认是否生成提交退货退款单 ")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirmToOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否确认取消服务?",
            visible: _vm.dialogVisible2,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm2",
              attrs: {
                model: _vm.saleInfo2,
                rules: _vm.rules2,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "取消原因", prop: "cancelReason" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.saleInfo2.cancelReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo2, "cancelReason", $$v)
                                },
                                expression: "saleInfo2.cancelReason",
                              },
                            },
                            _vm._l(_vm.cancelReason, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "取消备注" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.saleInfo2.cancelMemo,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo2, "cancelMemo", $$v)
                              },
                              expression: "saleInfo2.cancelMemo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.comfirmB },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible8,
            width: "30%",
            center: "",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible8 = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialog8Msg))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.comfirmTo } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }