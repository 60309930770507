var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "myPanel-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.orderInfo.orderNo)),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm._f("stateFilter")(_vm.orderInfo.state))),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  _vm._s(_vm._f("subStateFilter")(_vm.orderInfo.subState))
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("new-task", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["ROLE_WORK_TASK_MY_LIST"],
                expression: "['ROLE_WORK_TASK_MY_LIST']",
              },
            ],
            attrs: {
              "work-id": _vm.orderInfo.id,
              "work-no": _vm.orderInfo.orderNo,
              "work-type": "2",
              "task-link": "20",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRelevan,
                  expression: "isRelevan",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_RECEIPT_EXPRESS"],
                  expression: "['ROLE_QUERY_ORDER_RECEIPT_EXPRESS']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showExpressDiv("1")
                },
              },
            },
            [_vm._v("收货单号补充")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRelevan,
                  expression: "isRelevan",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_RETURN_EXPRESS"],
                  expression: "['ROLE_QUERY_ORDER_RETURN_EXPRESS']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showExpressDiv("2")
                },
              },
            },
            [_vm._v("返机单号补充")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRelevan,
                  expression: "isRelevan",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_CLOSE"],
                  expression: "['ROLE_QUERY_ORDER_CLOSE']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { disabled: _vm.isCancelOrder, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showOrderClose()
                },
              },
            },
            [_vm._v("关闭服务单")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRelevan,
                  expression: "isRelevan",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_OUTCALL"],
                  expression: "['ROLE_QUERY_ORDER_OUTCALL']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.outCall()
                },
              },
            },
            [_vm._v("沟通外呼")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRelevan,
                  expression: "isRelevan",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_CALL"],
                  expression: "['ROLE_QUERY_ORDER_CALL']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", disabled: _vm.cellDisabled },
              on: {
                click: function ($event) {
                  return _vm.againCallOver()
                },
              },
            },
            [_vm._v("支付短信")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEngage,
                  expression: "isEngage",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_ENGAGE"],
                  expression: "['ROLE_QUERY_ORDER_ENGAGE']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogTableVisible = true
                },
              },
            },
            [_vm._v("Engage信息")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_QUERY_ORDER_LIST"],
                  expression: "['ROLE_QUERY_ORDER_LIST']",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "order_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "服务单信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("服务单信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务单号",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.orderNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "orderNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.orderNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "创建人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createUserName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createUserName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createUserName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "创建时间",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.orderInfo.cancelReason &&
                                    _vm.orderInfo.cancelReason !== ""
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "取消原因",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled: "",
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderInfo
                                                                      .cancelReason,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderInfo,
                                                                        "cancelReason",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderInfo.cancelReason",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.cancelReasonOptions,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.val,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "取消人",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .cancelUserName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "cancelUserName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.cancelUserName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: { span: 8, pull: 1 },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "取消时间",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .cancelTime,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "cancelTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.cancelTime",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 24,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "取消备注",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                disabled: "",
                                                                type: "textarea",
                                                                autosize: {
                                                                  minRows: 2,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .cancelMemo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "cancelMemo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.cancelMemo",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.historyShow,
                                  callback: function ($$v) {
                                    _vm.historyShow = $$v
                                  },
                                  expression: "historyShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-task-list", {
                                      ref: "historyList",
                                      attrs: {
                                        disabled: true,
                                        "order-id": _vm.orderInfo.id,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.consumerShow,
                                  callback: function ($$v) {
                                    _vm.consumerShow = $$v
                                  },
                                  expression: "consumerShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-consumer", {
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                        "enable-modify": _vm.enableModify,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.orderInfo.jdOrderNo
                              ? _c(
                                  "div",
                                  { staticClass: "JD订单信息" },
                                  [
                                    _c(
                                      "el-divider",
                                      {
                                        staticClass: "myStyle",
                                        attrs: { "content-position": "left" },
                                      },
                                      [_vm._v("JD订单信息")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: { "border-top": "none" },
                                        attrs: { shadow: "never" },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "JD单号",
                                                          prop: "jdOrderNo",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.jdSalesOrderNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.jdSalesOrderNo =
                                                                $$v
                                                            },
                                                            expression:
                                                              "jdSalesOrderNo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "JD补充信息",
                                                          prop: "jdSupplyInfo",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                            type: "textarea",
                                                            autosize: {
                                                              minRows: 5,
                                                            },
                                                            maxlength: "500",
                                                            "show-word-limit":
                                                              "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.jdSupplyInfo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.jdSupplyInfo =
                                                                $$v
                                                            },
                                                            expression:
                                                              "jdSupplyInfo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.receiptShow,
                                  callback: function ($$v) {
                                    _vm.receiptShow = $$v
                                  },
                                  expression: "receiptShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.dispatchShow,
                                  callback: function ($$v) {
                                    _vm.dispatchShow = $$v
                                  },
                                  expression: "dispatchShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-dispatch", {
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.checkShow,
                                  callback: function ($$v) {
                                    _vm.checkShow = $$v
                                  },
                                  expression: "checkShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-check", {
                                      ref: "checkOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.priceShow,
                                  callback: function ($$v) {
                                    _vm.priceShow = $$v
                                  },
                                  expression: "priceShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-price", {
                                      ref: "checkPrice",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.approveShow,
                                  callback: function ($$v) {
                                    _vm.approveShow = $$v
                                  },
                                  expression: "approveShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c(
                                      "el-row",
                                      { staticClass: "审批信息" },
                                      [
                                        _c(
                                          "el-divider",
                                          {
                                            staticClass: "myStyle",
                                            attrs: {
                                              "content-position": "left",
                                            },
                                          },
                                          [_vm._v("审批信息")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-card",
                                          {
                                            staticClass: "box-card",
                                            staticStyle: {
                                              "border-top": "none",
                                            },
                                            attrs: { shadow: "never" },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "减免审批人",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.orderInfo
                                                                .approveRmk,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderInfo,
                                                                "approveRmk",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderInfo.approveRmk",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "减免审批时间",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.orderInfo
                                                                .approveDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderInfo,
                                                                "approveDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderInfo.approveDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.recheckShow,
                                  callback: function ($$v) {
                                    _vm.recheckShow = $$v
                                  },
                                  expression: "recheckShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-recheck", {
                                      ref: "recheckOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.calloutShow,
                                  callback: function ($$v) {
                                    _vm.calloutShow = $$v
                                  },
                                  expression: "calloutShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-callout", {
                                      ref: "calloutOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.stateChangeShow,
                                  callback: function ($$v) {
                                    _vm.stateChangeShow = $$v
                                  },
                                  expression: "stateChangeShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("state-change", {
                                      ref: "stateChange",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.paymentShow,
                                  callback: function ($$v) {
                                    _vm.paymentShow = $$v
                                  },
                                  expression: "paymentShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-payment", {
                                      ref: "paymentOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.handleShow,
                                  callback: function ($$v) {
                                    _vm.handleShow = $$v
                                  },
                                  expression: "handleShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-handle", {
                                      ref: "handleOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.verifyShow,
                                  callback: function ($$v) {
                                    _vm.verifyShow = $$v
                                  },
                                  expression: "verifyShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-verify", {
                                      ref: "verifyOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.returnShow,
                                  callback: function ($$v) {
                                    _vm.returnShow = $$v
                                  },
                                  expression: "returnShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-return", {
                                      ref: "returnOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.sfInfoShow,
                                  callback: function ($$v) {
                                    _vm.sfInfoShow = $$v
                                  },
                                  expression: "sfInfoShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c(
                                      "el-row",
                                      { staticClass: "物流信息" },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 11 } },
                                          [
                                            _c(
                                              "el-divider",
                                              {
                                                staticClass: "myStyle",
                                                attrs: {
                                                  "content-position": "left",
                                                },
                                              },
                                              [_vm._v("收货物流信息")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-card",
                                              {
                                                staticClass: "box-card",
                                                staticStyle: {
                                                  "border-top": "none",
                                                },
                                                attrs: { shadow: "never" },
                                              },
                                              [
                                                _c("sf-route-state", {
                                                  ref: "sfInfoForm",
                                                  attrs: {
                                                    num: _vm.orderInfo.orderNo,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12, push: 1 } },
                                          [
                                            _c(
                                              "el-divider",
                                              {
                                                staticClass: "myStyle",
                                                attrs: {
                                                  "content-position": "left",
                                                },
                                              },
                                              [_vm._v("返机物流信息")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-card",
                                              {
                                                staticClass: "box-card",
                                                staticStyle: {
                                                  "border-top": "none",
                                                },
                                                attrs: { shadow: "never" },
                                              },
                                              [
                                                _c("sf-route-state", {
                                                  ref: "sfInfoTo",
                                                  attrs: {
                                                    num: _vm.orderInfo.orderNo,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 140 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "70px",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "客户",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.consumerShow === "1"
                                                  ? (_vm.consumerShow = "0")
                                                  : (_vm.consumerShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "分派客服",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.historyShow === "1"
                                                  ? (_vm.historyShow = "0")
                                                  : (_vm.historyShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "产品",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.productShow === "1"
                                                  ? (_vm.productShow = "0")
                                                  : (_vm.productShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "收货",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.receiptShow === "1"
                                                  ? (_vm.receiptShow = "0")
                                                  : (_vm.receiptShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "分派",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.dispatchShow === "1"
                                                  ? (_vm.dispatchShow = "0")
                                                  : (_vm.dispatchShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "检测",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.checkShow === "1"
                                                  ? (_vm.checkShow = "0")
                                                  : (_vm.checkShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.orderInfo.checkSubType === "0102" ||
                                      _vm.orderInfo.checkSubType === "0103" ||
                                      (_vm.orderInfo.checkType === "02" &&
                                        _vm.orderInfo.checkSubType === "0203")
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "费用",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.priceShow === "1"
                                                      ? (_vm.priceShow = "0")
                                                      : (_vm.priceShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "复核",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.recheckShow === "1"
                                                  ? (_vm.recheckShow = "0")
                                                  : (_vm.recheckShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "外呼",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.calloutShow === "1"
                                                  ? (_vm.calloutShow = "0")
                                                  : (_vm.calloutShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.orderInfo.checkSubType === "0102" ||
                                      _vm.orderInfo.checkSubType === "0103" ||
                                      (_vm.orderInfo.checkType === "02" &&
                                        _vm.orderInfo.checkSubType === "0203")
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "支付",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.paymentShow === "1"
                                                      ? (_vm.paymentShow = "0")
                                                      : (_vm.paymentShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.orderInfo.type === "01"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "处理",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.handleShow === "1"
                                                      ? (_vm.handleShow = "0")
                                                      : (_vm.handleShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.orderInfo.type === "01"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "终验",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.verifyShow === "1"
                                                      ? (_vm.verifyShow = "0")
                                                      : (_vm.verifyShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "返机",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.returnShow === "1"
                                                  ? (_vm.returnShow = "0")
                                                  : (_vm.returnShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: "物流",
                                              checked: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.sfInfoShow === "1"
                                                  ? (_vm.sfInfoShow = "0")
                                                  : (_vm.sfInfoShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否确认关闭服务单?",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.orderInfo,
                "label-width": "80px",
                size: "50%",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { label: "取消原因" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.cancelReason,
                        callback: function ($$v) {
                          _vm.cancelReason = $$v
                        },
                        expression: "cancelReason",
                      },
                    },
                    _vm._l(_vm.cancelReasonOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.val },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.orderClose } },
                [_vm._v("确 认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.expressTitle,
            visible: _vm.expressDialogVisible,
            width: "30%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.expressDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.orderInfo,
                "label-width": "80px",
                size: "50%",
              },
            },
            [
              _vm.expressType === "1"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { label: "收货单号" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        model: {
                          value: _vm.orderInfo.receiptLogisticsNumberBu,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.orderInfo,
                              "receiptLogisticsNumberBu",
                              $$v
                            )
                          },
                          expression: "orderInfo.receiptLogisticsNumberBu",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.expressType === "2"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { label: "返机单号" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        model: {
                          value: _vm.orderInfo.returnLogisticsNumberBu,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.orderInfo,
                              "returnLogisticsNumberBu",
                              $$v
                            )
                          },
                          expression: "orderInfo.returnLogisticsNumberBu",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveExpress(_vm.expressType)
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.expressDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Engage信息",
            width: "1000px",
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.engageData } },
            [
              _c("el-table-column", {
                attrs: {
                  property: "typeId",
                  label: "类型",
                  width: "150",
                  formatter: _vm.typeFormat,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "notes", label: "详情", width: "580" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "closedDatetime",
                  label: "时间",
                  width: "150",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }