"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _list = require("@/api/consult/list");

var _list2 = require("@/api/basics/consultType/list");

var _FixTop = _interopRequireDefault(require("@/components/FixTop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'qiyuDetail',
  // eslint-disable-line
  components: {
    FixTop: _FixTop.default
  },
  directives: {},
  filters: {
    formatExpState: function formatExpState(val) {
      // 运单路由状态
      var expStateOpions = {
        '10': '已揽件',
        '20': '在途',
        '30': '正在投递',
        '40': '妥投',
        '90': '转寄',
        '99': '派件异常'
      };
      return expStateOpions[val.trim()];
    }
  },
  data: function data() {
    return {
      cusActiveNames: ['1', '2'],
      proRules: {},
      detailActive: ['1', '3'],
      isDetail: true,
      oneLevelSelect: [],
      // 咨询单一级归类
      twoLevelSelect: [],
      // 咨询单二级归类
      threeLevelSelect: [],
      // 咨询单三级归类
      productSearch: '',
      dialogVisible1: false,
      // 弹出框1
      dialogVisible: false,
      // 弹出框
      dialogMsg: '',
      // 弹出框 显示信息
      dialogType: '',
      // 弹出框类型(1->选了产品转工单；2->没选产品转工单；3->完结咨询工单；4->转销售工单，更新咨询单状态；5->转退货单，更新咨询单状态, 6.完结咨询单，再次确认)
      prodName: '',
      btnLoading: false,
      isEnablesOptions: [{
        label: '启用',
        value: '1'
      }, {
        label: '禁用',
        value: '0'
      }],
      typesOptions: [{
        label: '良品库',
        value: '0'
      }, {
        label: '坏品库',
        value: '1'
      }, {
        label: '报废品库',
        value: '2'
      }],
      textMap: {
        update: '编辑',
        create: '新建'
      },
      id: this.$route.query.id,
      isQuery: this.$route.query.isQuery,
      sessionId: this.$route.query.sessionId,
      phone: this.$route.query.phone,
      loading: false,
      multipleSelection: [],
      list: [],
      total: 0,
      listLoading: false,
      orgListValidate: false,
      // >>> 咨询工单信息 >>>
      consultOrderInfo: {
        id: this.$route.params.id,
        num: '',
        consultOrderCustomerInfo: {},
        CustomerProductList: [],
        prodName: '' // 选中的产品名称-》更改为选择的产品sn

      },
      // >>> 咨询工单客户信息 >>>
      consultOrderCustomerInfo: {},
      // >>> 咨询工单产品信息 >>>
      CustomerProductList: [],
      CustomerProductList2: [],
      CustomerProductInfo: {},
      // 详情时查看的产品信息
      rules: {
        applicantMan: [{
          required: true,
          message: '报修人姓名是必填的',
          trigger: 'blur'
        }],
        applicantTel: [{
          required: true,
          message: '报修人手机号是必填的',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '业务类型是必选的',
          trigger: 'blur'
        }],
        oneLevel: [{
          required: true,
          message: '咨询单一级归类是必选的',
          trigger: 'blur'
        }],
        twoLevel: [{
          required: true,
          message: '咨询单二级归类是必须的',
          trigger: 'blur'
        }],
        threeLevel: [{
          required: true,
          message: '咨询单三级归类必选的',
          trigger: 'blur'
        }],
        userDescribe: [{
          required: true,
          message: '用户描述是必填的',
          trigger: 'blur'
        }],
        beforeMemo: [{
          required: true,
          message: '自助服务描述是必填的',
          trigger: 'blur'
        }],
        afterMemo: [{
          required: true,
          message: '问题出现前/后是必填的',
          trigger: 'blur'
        }],
        resolveMemo: [{
          required: true,
          message: 'Resolve方案是必填的',
          trigger: 'blur'
        }],
        crmPhone: [{
          required: true,
          message: '机主手机号是必填的',
          trigger: 'blur'
        }],
        memo: [{
          max: 255,
          message: '最大长度 500 个字符',
          trigger: 'blur'
        }]
      },
      // 咨询工单主状态
      stateOptions: [{
        'val': '00',
        'name': '新建'
      }, {
        'val': '10',
        'name': '处理中'
      }, {
        'val': '20',
        'name': '已结束'
      }],
      // 咨询工单子状态
      subStateOptions: [{
        'val': '1',
        'name': '新建'
      }, {
        'val': '2',
        'name': '待客户上传'
      }, {
        'val': '3',
        'name': '客户已传待审阅'
      }, {
        'val': '4',
        'name': '客户已传已审阅'
      }, {
        'val': '5',
        'name': '已转服务'
      }, {
        'val': '6',
        'name': '已转销售'
      }, {
        'val': '7',
        'name': '已转退货'
      }, {
        'val': '8',
        'name': '仅咨询'
      }, {
        'val': '9',
        'name': '作废'
      }, {
        'val': '10',
        'name': '待下单'
      }, {
        'val': '11',
        'name': '已转退款'
      }, {
        'val': '12',
        'name': '已转VSE'
      }],
      // 咨询工单业务类型
      typeOptions: [{
        'val': '01',
        'name': '维修'
      }, {
        'val': '02',
        'name': '换货'
      }, {
        'val': '03',
        'name': '检测'
      }, // 3月9号版本 没有检测
      {
        'val': '04',
        'name': '销售'
      }, {
        'val': '05',
        'name': '退货'
      }],
      cusRules: {},
      // >>> 弹出框的数据 >>>
      orgDialogData: {},
      addOrgdialogFormVisible: false,
      organizationList: [],
      listQuery: {
        page: 1,
        limit: 500,
        name: '',
        parentId: '',
        isEnabled: '1'
      },
      parentOrgList: [],
      orgDialogSelection: [],
      activeNames: [],
      isEdit: true,
      // 新建状态 可编辑
      isSearch: true,
      // 是否可搜索
      changeActiveNames: [],
      expressActiveNames: [],
      // 运单状态信息
      orderActiveNames: [],
      // 转单信息是否显示
      expressRouteInfoList: [],
      // 运单路由信息
      expressStateInfoList: [] // 运单状态信息

    };
  },
  created: function created() {
    this.getOneLevelOptions();
    this.getDetail();
  },
  methods: {
    handleChange: function handleChange(val) {
      console.log(val);
    },
    // 转工单
    changeOrder: function changeOrder() {
      var _this = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          console.log(_this.consultOrderCustomerInfo);
          console.log(_this.consultOrderCustomerInfo.name);

          if (_this.consultOrderInfo.crmPhone === undefined || _this.consultOrderInfo.crmPhone === null || _this.consultOrderInfo.crmPhone === '') {
            _this.$message({
              showClose: true,
              message: '客户手机号必须填！',
              type: 'warning'
            });

            return false;
          }

          if (_this.consultOrderCustomerInfo.name === undefined || _this.consultOrderCustomerInfo.name === null || _this.consultOrderCustomerInfo.name === '') {
            _this.$message({
              showClose: true,
              message: '转工单时必须要有客户信息！',
              type: 'warning'
            });

            return false;
          } // 维修、换货、检测 转服务工单   转销售 、转退货


          var flag = false;
          var num = 0;
          _this.consultOrderInfo.prodName = '';

          _this.CustomerProductList.forEach(function (item, index) {
            if (item.choose) {
              flag = true;
              num = num + 1;
              _this.prodName = item.serialNumber;
              _this.consultOrderInfo.prodName = item.serialNumber;
            }
          }); // 选中的产品数量不能大于1


          if (num > 1) {
            _this.$message({
              showClose: true,
              message: '只能选择一个产品',
              type: 'warning'
            });

            return;
          }

          console.log(_this.consultOrderInfo.prodName);

          if (_this.consultOrderInfo.type === '01' || _this.consultOrderInfo.type === '02' || _this.consultOrderInfo.type === '03') {
            // 判断有没有选产品 对应 转 服务工单 和 无SN的服务工单
            if (flag) {
              _this.dialogType = '1'; // 选了产品的转服务工单 确认弹出框

              _this.dialogMsg = '请确认是否针对产品 [ ' + _this.prodName + ' ] 转服务工单';
              _this.dialogVisible = true;
            } else {
              _this.dialogType = '2'; // 没有选产品的转工单确认弹出框

              _this.dialogMsg = '请确认是否不针对该用户任一条注册产品进行转工单';
              _this.dialogVisible = true;
            }
          } // 销售转销售单


          if (_this.consultOrderInfo.type === '04') {
            _this.dialogType = '4'; // 销售转工单确认弹出框

            _this.dialogMsg = '请确认是否转销售工单';
            _this.dialogVisible = true;
          } // 退货转退货单


          if (_this.consultOrderInfo.type === '05') {
            _this.dialogType = '5'; // 退货转工单确认弹出框

            _this.dialogMsg = '请确认是否转退货工单';
            _this.dialogVisible = true;
          }
        } else {
          _this.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 转服务工单 保存咨询工单信息
    comfirmToOrder: function comfirmToOrder(type) {
      var _this2 = this;

      console.log(type);
      this.btnLoading = false;
      this.consultOrderInfo.consultOrderCustomerInfo = this.consultOrderCustomerInfo;
      this.CustomerProductList = this.CustomerProductList;

      if (type === '3') {
        // 【完结咨询工单】
        this.dialogVisible1 = true;
      }

      if (type === '1' || type === '2' || type === '4' || type === '5') {
        // 【转工单】
        (0, _list.createQiyuData)(this.consultOrderInfo).then(function (response) {
          _this2.btnLoading = false;

          if (response !== null && response.code === 200) {
            _this2.dialogVisible = false;

            if (type === '1' || type === '2') {
              // 选产品和不选产品，转服务工单（包含三种状态：维修，换货，检测）
              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                name: 'qiyuCreateDetail',
                query: {
                  id: _this2.consultOrderInfo.id,
                  sessionId: _this2.sessionId,
                  phone: _this2.phone,
                  pageType: '1'
                }
              });
            }

            if (type === '4') {
              // 转销售单
              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                name: 'qiyuSaleDetail',
                query: {
                  id: _this2.consultOrderInfo.id,
                  dialogStatus: 'create',
                  sessionId: _this2.sessionId,
                  phone: _this2.phone,
                  pageType: '1'
                }
              });
            }

            if (type === '5') {
              // 转退货单
              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                name: 'qiyuReturnDetail',
                query: {
                  id: _this2.consultOrderInfo.id,
                  dialogStatus: 'create',
                  sessionId: _this2.sessionId,
                  phone: _this2.phone,
                  pageType: '1'
                }
              });
            }
          } else {
            _this2.$message({
              showClose: true,
              message: response.msg || '操作失败！',
              type: 'warning'
            });
          }
        });
      }
    },
    // 完结咨询工单
    finishOrder: function finishOrder() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // 完结工单判断只能选一个产品
          var num = 0;
          _this3.consultOrderInfo.prodName = '';

          _this3.CustomerProductList.forEach(function (item, index) {
            if (item.choose) {
              num = num + 1;
              _this3.prodName = item.serialNumber;
              _this3.consultOrderInfo.prodName = item.serialNumber;
            }
          }); // 选中的产品数量不能大于1


          if (num > 1) {
            _this3.$message({
              showClose: true,
              message: '只能选择一个产品',
              type: 'warning'
            });

            return;
          }

          _this3.dialogType = '3'; // 选了产品的转服务工单 确认弹出框

          _this3.dialogMsg = '请确认是否完结咨询工单'; // 弹出框提示信息

          _this3.dialogVisible = true;
        } else {
          _this3.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 完结-》再次确认是否已解决客户问题
    comfirmAgain: function comfirmAgain(type) {
      var _this4 = this;

      this.consultOrderInfo.isResolve = type;
      (0, _list.finishQiyuConsultOrder)(this.consultOrderInfo).then(function (response) {
        _this4.btnLoading = false;
        console.log(response);

        if (response.data) {
          _this4.$router.go(0); // this.$store.dispatch('tagsView/delView', this.$route)
          // this.$router.push({ name: 'qiyuDetail2', query: { id: this.consultOrderInfo.id, sessionId: this.sessionId, phone: this.phone }})

        } else {
          _this4.$message({
            showClose: true,
            message: response.msg || '操作失败！',
            type: 'warning'
          });
        }
      });
    },
    // 是否已解决客户问题：否
    comfirmAgainNo: function comfirmAgainNo() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    },
    // 初始查询咨询工单信息
    getDetail: function getDetail() {
      var _this5 = this;

      console.log(this.sessionId);
      this.btnLoading = false;
      this.consultOrderInfo.id = this.id;
      this.consultOrderInfo.sessionId = this.sessionId;
      this.consultOrderInfo.crmPhone = this.phone;
      (0, _list.queryQiyuBySessionId)(this.consultOrderInfo).then(function (response) {
        console.log(response);
        _this5.btnLoading = false;

        if (response !== null && response.code === 200) {
          _this5.consultOrderInfo = response.data; // 根据单子是否结束，判断进入编辑页还是详情页

          if (response.data.state === '20') {
            _this5.expressActiveNames = ['1', '2'];
            _this5.changeActiveNames = ['1'];
            _this5.isDetail = true;
          } else {
            _this5.isDetail = false;
          } // 新建状态，可操作


          if (response.data.state === '00') {
            _this5.isEdit = true;
          } else {
            _this5.isEdit = false;
          } // 二级归类回显


          if (_this5.consultOrderInfo.oneLevel != null && _this5.consultOrderInfo.oneLevel !== '') {
            (0, _list2.getConsultTypeByParentId)(_this5.consultOrderInfo.oneLevel).then(function (response) {
              _this5.twoLevelSelect = response.data;
            });
          } // 三级归类回显


          if (_this5.consultOrderInfo.twoLevel != null && _this5.consultOrderInfo.twoLevel !== '') {
            (0, _list2.getConsultTypeByParentId)(_this5.consultOrderInfo.twoLevel).then(function (response) {
              _this5.threeLevelSelect = response.data;
            });
          } // isSearch 是否可查询
          // 客户信息


          if (response.data.consultOrderCustomer) {
            _this5.consultOrderCustomerInfo = response.data.consultOrderCustomer;
            _this5.isSearch = false;
          } else {
            if (response.data.state === '00') {
              _this5.isSearch = true;
            } else {
              _this5.isSearch = false;
            }
          } // 产品信息 + 选中


          _this5.CustomerProductList = [];

          if (response.data.customerProductList) {
            response.data.customerProductList.forEach(function (item, index) {
              if (item.choose) {
                if (response.data.state === '20') {
                  _this5.CustomerProductInfo = item;
                } else {
                  _this5.CustomerProductList.push(item);

                  item.prdName = 1 + '. ' + item.prdName;

                  _this5.activeNames.push(0);
                }
              }
            });
          }

          if (response.data.stateList !== null) {
            _this5.expressStateInfoList = response.data.stateList;
          }

          if (response.data.routeList !== null) {
            _this5.expressRouteInfoList = response.data.routeList;
          }

          _this5.$nextTick(function () {
            _this5.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 根据手机号查询客户信息和产品信息
    queryCusAndProd: function queryCusAndProd() {
      var _this6 = this;

      this.btnLoading = false;
      (0, _list.queryQiyuCusAndProd)(this.consultOrderInfo.crmPhone).then(function (response) {
        if (response.data) {
          if (response.data.consultOrderCustomer) {
            _this6.consultOrderCustomerInfo = response.data.consultOrderCustomer;
            _this6.isSearch = false;
          } else {
            _this6.isSearch = true;
          }

          if (response.data.customerProductList != null) {
            _this6.CustomerProductList = response.data.customerProductList;
            _this6.CustomerProductList2 = response.data.customerProductList;

            _this6.CustomerProductList.forEach(function (item, index) {
              item.prdName = index + 1 + '. ' + item.prdName; // this.activeNames.push(index)
            });
          }

          _this6.btnLoading = false;

          _this6.$nextTick(function () {
            _this6.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 新建页，模糊查询产品/sn
    queryCustomerProduct: function queryCustomerProduct() {
      var _this7 = this;

      var customerProductList1 = [];

      if (this.productSearch !== '') {
        this.productSearch = this.productSearch.trim().toLowerCase();
        this.CustomerProductList2.forEach(function (item, index) {
          item.prdName = item.modelName + ' [ ' + item.serialNumber + ' ]';

          if (item.prdName.toLowerCase().indexOf(_this7.productSearch) !== -1) {
            customerProductList1.push(item);
          }
        });

        if (customerProductList1.length === 0) {
          this.CustomerProductList = [];
        } else {
          customerProductList1.forEach(function (item, index) {
            item.prdName = index + 1 + '. ' + item.prdName;
          });
          this.CustomerProductList = customerProductList1;
        }
      } else {
        this.CustomerProductList2.forEach(function (item, index) {
          item.prdName = index + 1 + '. ' + item.modelName + ' [ ' + item.serialNumber + ' ]'; // this.activeNames.push(index)
        });
        this.CustomerProductList = this.CustomerProductList2;
      }
    },
    // 查询条件清空
    clearSearch: function clearSearch() {
      this.isSearch = true;
      this.consultOrderCustomerInfo = {};
      this.CustomerProductList = [];
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'qiyuList',
        query: {
          id: this.consultOrderInfo.id,
          sessionId: this.sessionId,
          phone: this.phone
        }
      });
    },
    // 初始化咨询单一级归类
    getOneLevelOptions: function getOneLevelOptions() {
      var _this8 = this;

      (0, _list2.getConsultTypeByParentId)('0').then(function (res) {
        _this8.oneLevelSelect = res.data;
      });
    },
    // 咨询单二级归类
    oneLevelChange: function oneLevelChange(oneLevel) {
      var _this9 = this;

      if (oneLevel) {
        (0, _list2.getConsultTypeByParentId)(oneLevel).then(function (response) {
          _this9.twoLevelSelect = response.data;
        });
      }

      this.consultOrderInfo.twoLevel = '';
      this.consultOrderInfo.threeLevel = '';
    },
    // 咨询单三级归类
    twoLevelChange: function twoLevelChange(twoLevel) {
      var _this10 = this;

      if (twoLevel) {
        (0, _list2.getConsultTypeByParentId)(twoLevel).then(function (response) {
          _this10.threeLevelSelect = response.data;
        });
      }

      this.consultOrderInfo.threeLevel = '';
    }
  }
};
exports.default = _default;