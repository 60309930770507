var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.uploadDisabled && !_vm.disabled,
              expression: "!uploadDisabled && !disabled",
            },
          ],
          staticStyle: { "padding-bottom": "20px" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    disabled: _vm.uploadDisabled,
                    "show-file-list": false,
                    action: _vm.uploadAction,
                    limit: _vm.uploadLimit,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-error": _vm.imgUploadError,
                    "auto-upload": false,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "trigger", type: "primary" },
                      slot: "trigger",
                    },
                    [_vm._v("选取文件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 18, pull: 2 } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: "上传文件的描述内容（限制500文字）...",
                  },
                  model: {
                    value: _vm.note,
                    callback: function ($$v) {
                      _vm.note = $$v
                    },
                    expression: "note",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "myInnerBtn",
                          on: { click: _vm.submitUpload },
                        },
                        [_vm._v("确认上传")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-row",
            _vm._l(_vm.uploadFileList, function (item, index) {
              return _c(
                "el-col",
                { key: index, attrs: { span: _vm.mySpan } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        background: "#eef1f6",
                        "padding-right": "5px",
                      },
                      attrs: { span: 23 },
                    },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            shadow: "never",
                            "body-style": { background: "#eef1f6" },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "img_size" },
                            [
                              item.type.indexOf("image") !== -1
                                ? _c("el-image", {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      src: item.attachment,
                                      "preview-src-list": [item.attachment],
                                    },
                                  })
                                : _c("video", {
                                    staticClass: "avatar",
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      src: item.attachment,
                                      controls: "controls",
                                      width: _vm.myVideoWidth,
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.filename,
                                placement: "top-start",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "text-indent": "35px",
                                    "line-height": "200%",
                                    "letter-spacing": "1px",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("showOverflowTooltipFilter")(
                                        item.filename
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: "",
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 10 },
                            },
                            model: {
                              value: item.note,
                              callback: function ($$v) {
                                _vm.$set(item, "note", $$v)
                              },
                              expression: "item.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.disabled,
                          expression: "!disabled",
                        },
                      ],
                      attrs: { span: 1, pull: 3 },
                    },
                    [
                      _c("el-button", {
                        staticClass: "myCircleSmallBtn",
                        attrs: {
                          size: "small",
                          icon: "el-icon-delete",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeUpload(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }