var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "客户信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("客户信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "机主" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.ownerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "ownerName", $$v)
                            },
                            expression: "orderInfo.ownerName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "机主电话" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.ownerTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "ownerTel", $$v)
                            },
                            expression: "orderInfo.ownerTel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagOwnerTelRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(
                                _vm.orderInfo.ownerTelRisk
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, push: 2 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: {
                          label: "1",
                          border: "",
                          disabled: _vm.enableModify,
                        },
                        model: {
                          value: _vm.orderInfo.linkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderInfo, "linkType", $$v)
                          },
                          expression: "orderInfo.linkType",
                        },
                      },
                      [_vm._v("默认联系人")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "报修人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.enableModify },
                          model: {
                            value: _vm.orderInfo.claimName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "claimName", $$v)
                            },
                            expression: "orderInfo.claimName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "报修人电话" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.enableModify },
                          model: {
                            value: _vm.orderInfo.claimTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "claimTel", $$v)
                            },
                            expression: "orderInfo.claimTel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagClaimTelRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(
                                _vm.orderInfo.claimTelRisk
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, push: 2 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: {
                          label: "2",
                          border: "",
                          disabled: _vm.enableModify,
                        },
                        model: {
                          value: _vm.orderInfo.linkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderInfo, "linkType", $$v)
                          },
                          expression: "orderInfo.linkType",
                        },
                      },
                      [_vm._v("默认联系人")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "寄件人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.sendName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "sendName", $$v)
                            },
                            expression: "orderInfo.sendName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "寄件人电话" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.sendTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "sendTel", $$v)
                            },
                            expression: "orderInfo.sendTel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagSendTelRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(_vm.orderInfo.sendTelRisk)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, push: 2 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: {
                          label: "3",
                          border: "",
                          disabled: _vm.enableModify,
                        },
                        model: {
                          value: _vm.orderInfo.linkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderInfo, "linkType", $$v)
                          },
                          expression: "orderInfo.linkType",
                        },
                      },
                      [_vm._v("默认联系人")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "收件人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.enableModify },
                          model: {
                            value: _vm.orderInfo.takeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "takeName", $$v)
                            },
                            expression: "orderInfo.takeName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "收件人电话" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.enableModify },
                          model: {
                            value: _vm.orderInfo.takeTel,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "takeTel", $$v)
                            },
                            expression: "orderInfo.takeTel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagTakeTelRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(_vm.orderInfo.takeTelRisk)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, push: 2 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: {
                          label: "4",
                          border: "",
                          disabled: _vm.enableModify,
                        },
                        model: {
                          value: _vm.orderInfo.linkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderInfo, "linkType", $$v)
                          },
                          expression: "orderInfo.linkType",
                        },
                      },
                      [_vm._v("默认联系人")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "寄件地址" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.sendAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "sendAddress", $$v)
                            },
                            expression: "orderInfo.sendAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagSendAddressRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(
                                _vm.orderInfo.sendAddressRisk
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "收件地址" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: _vm.enableModify },
                          model: {
                            value: _vm.orderInfo.takeAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "takeAddress", $$v)
                            },
                            expression: "orderInfo.takeAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagTakeAddressRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(
                                _vm.orderInfo.takeAddressRisk
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }