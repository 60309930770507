"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _organization = _interopRequireDefault(require("./modules/organization"));

var _basics = _interopRequireDefault(require("./modules/basics"));

var _stock = _interopRequireDefault(require("./modules/stock"));

var _consult = _interopRequireDefault(require("./modules/consult"));

var _order = _interopRequireDefault(require("./modules/order"));

var _quota = _interopRequireDefault(require("./modules/quota"));

var _notice = _interopRequireDefault(require("./modules/notice"));

var _sale = _interopRequireDefault(require("./modules/sale"));

var _query = _interopRequireDefault(require("./modules/query"));

var _callout = _interopRequireDefault(require("./modules/callout"));

var _bill = _interopRequireDefault(require("./modules/bill"));

var _qiyu = _interopRequireDefault(require("./modules/qiyu"));

var _offline = _interopRequireDefault(require("./modules/offline"));

var _modify = _interopRequireDefault(require("./modules/modify"));

var _register = _interopRequireDefault(require("./modules/register"));

var _reduction = _interopRequireDefault(require("./modules/reduction"));

var _orderTask = _interopRequireDefault(require("./modules/orderTask"));

var _user = _interopRequireDefault(require("./modules/user"));

var _express = _interopRequireDefault(require("./modules/express"));

var _vsc = _interopRequireDefault(require("./modules/vsc"));

var _report = _interopRequireDefault(require("@/router/modules/report"));

var _extend = _interopRequireDefault(require("./modules/extend"));

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/redirect/:path*',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
    });
  },
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/idaas',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/idaas/index'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'myHome',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}, // 机构块
_organization.default, // 基础块
_basics.default, // 咨询工单
_consult.default, // 工单块
_order.default, // 库存块
_stock.default, // 额度
_quota.default, // 公告块
_notice.default, // 销售
_sale.default, // 信息查询
_query.default, // 客服管理
_callout.default, // 财务管理
_bill.default, // 七鱼嵌入页面
_qiyu.default, // 线下门店退换
_offline.default, // 服务单修改
_modify.default, // 产品注册
_register.default, // 减免管理
_reduction.default, // 报表管理
_report.default, // 工单任务管理
_orderTask.default, // 账户管理
_user.default, // 零星取件
_express.default, // vsc
_vsc.default, // 批量延保审批
_extend.default];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [
/*
{
  path: 'external-link',
  component: Layout,
  children: [
    {
      path: 'https://panjiachen.github.io/vue-element-admin-site/#/',
      meta: { title: '外部链接', icon: 'link' }
    }
  ]
},
*/
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;