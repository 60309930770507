var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("服务SKU" + _vm._s(!_vm.isCreate ? "详细" : "创建")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_SKU_FEE_ADD", "ROLE_BASE_SKU_FEE_MODIFY"],
                  expression:
                    "['ROLE_BASE_SKU_FEE_ADD','ROLE_BASE_SKU_FEE_MODIFY']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { disabled: _vm.isReadOnly, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSumbit()
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_SKU_FEE_LIST"],
                  expression: "['ROLE_BASE_SKU_FEE_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "skuFee_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.baseSkuFee,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "服务SKU编码", prop: "code" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: {
                                disabled: !_vm.isCreate || _vm.isReadOnly,
                                placeholder: "服务SKU编码",
                              },
                              model: {
                                value: _vm.baseSkuFee.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSkuFee, "code", $$v)
                                },
                                expression: "baseSkuFee.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "适用权益" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  clearable: "",
                                  placeholder: "适用权益",
                                },
                                model: {
                                  value: _vm.baseSkuFee.applyRights,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSkuFee, "applyRights", $$v)
                                  },
                                  expression: "baseSkuFee.applyRights",
                                },
                              },
                              _vm._l(
                                _vm.applyRightsOptions,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "状态", prop: "isEnable" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  fill: "#000000",
                                  size: "medium",
                                },
                                model: {
                                  value: _vm.baseSkuFee.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSkuFee, "isEnable", $$v)
                                  },
                                  expression: "baseSkuFee.isEnable",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("启用")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("禁用")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "服务SKU名称", prop: "name" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "服务SKU名称",
                              },
                              model: {
                                value: _vm.baseSkuFee.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSkuFee, "name", $$v)
                                },
                                expression: "baseSkuFee.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "零售不含税指导价",
                              prop: "priceExcludeTaxYuan",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "零售不含税指导价",
                              },
                              model: {
                                value: _vm.baseSkuFee.priceExcludeTaxYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.baseSkuFee,
                                    "priceExcludeTaxYuan",
                                    $$v
                                  )
                                },
                                expression: "baseSkuFee.priceExcludeTaxYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "零售含税指导价",
                              prop: "priceIncludeTaxYuan",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "零售含税指导价",
                              },
                              model: {
                                value: _vm.baseSkuFee.priceIncludeTaxYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.baseSkuFee,
                                    "priceIncludeTaxYuan",
                                    $$v
                                  )
                                },
                                expression: "baseSkuFee.priceIncludeTaxYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "税务编码", prop: "taxationCode" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "税务编码",
                              },
                              model: {
                                value: _vm.baseSkuFee.taxationCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSkuFee, "taxationCode", $$v)
                                },
                                expression: "baseSkuFee.taxationCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "备注", prop: "memo" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                type: "textarea",
                                rows: 3,
                              },
                              model: {
                                value: _vm.baseSkuFee.memo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSkuFee, "memo", $$v)
                                },
                                expression: "baseSkuFee.memo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSkuFee.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建人", prop: "inRmkName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSkuFee.inRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSkuFee, "inRmkName", $$v)
                                    },
                                    expression: "baseSkuFee.inRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSkuFee.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSkuFee.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSkuFee, "inDateFM", $$v)
                                    },
                                    expression: "baseSkuFee.inDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSkuFee.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改人",
                                  prop: "reRmkName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSkuFee.reRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSkuFee, "reRmkName", $$v)
                                    },
                                    expression: "baseSkuFee.reRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSkuFee.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改时间",
                                  prop: "reDateFM",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSkuFee.reDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSkuFee, "reDateFM", $$v)
                                    },
                                    expression: "baseSkuFee.reDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }