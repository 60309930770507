var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "产品信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("产品信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "产品注册SN" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.sn,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "sn", $$v)
                            },
                            expression: "orderInfo.sn",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.flagSnRisk
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: {
                            position: "relative",
                            left: "60px",
                            bottom: "56px",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toRiskDetail(_vm.orderInfo.snRisk)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "品类" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.categoryName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "categoryName", $$v)
                            },
                            expression: "orderInfo.categoryName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "型号" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.modelName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "modelName", $$v)
                            },
                            expression: "orderInfo.modelName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "主机SKU" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.skuName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "skuName", $$v)
                            },
                            expression: "orderInfo.skuName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "注册时间" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.activeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "activeDate", $$v)
                            },
                            expression: "orderInfo.activeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "购买日期" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.salesDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "salesDate", $$v)
                            },
                            expression: "orderInfo.salesDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "购买渠道" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.salesChannel,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "salesChannel", $$v)
                            },
                            expression: "orderInfo.salesChannel",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "是否自营" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: "", size: "medium" },
                            model: {
                              value: _vm.orderInfo.isSelfChannel,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "isSelfChannel", $$v)
                              },
                              expression: "orderInfo.isSelfChannel",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "产品90天会员" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.isEquity90days,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "isEquity90days", $$v)
                            },
                            expression: "orderInfo.isEquity90days",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "出库日期" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.productDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "productDate", $$v)
                            },
                            expression: "orderInfo.productDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "订单日期" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.orderDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "orderDate", $$v)
                            },
                            expression: "orderInfo.orderDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "入保日期" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", disabled: "" },
                          model: {
                            value: _vm.orderInfo.refDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "refDate", $$v)
                            },
                            expression: "orderInfo.refDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "是否海外机" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: "", size: "medium" },
                            model: {
                              value: _vm.orderInfo.isAbroad,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "isAbroad", $$v)
                              },
                              expression: "orderInfo.isAbroad",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "是否翻新机" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: "", size: "medium" },
                            model: {
                              value: _vm.orderInfo.refurb,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "refurb", $$v)
                              },
                              expression: "orderInfo.refurb",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "延保时长" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.extendBenefitDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "extendBenefitDate", $$v)
                            },
                            expression: "orderInfo.extendBenefitDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "保修结束日期" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "保修结束日期",
                            disabled: "",
                          },
                          model: {
                            value: _vm.orderInfo.benefitEndDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "benefitEndDate", $$v)
                            },
                            expression: "orderInfo.benefitEndDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "是否延长保修" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: "", size: "medium" },
                            model: {
                              value: _vm.orderInfo.isExtendBenefit,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "isExtendBenefit", $$v)
                              },
                              expression: "orderInfo.isExtendBenefit",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("\n      产品权益\n    ")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "0px",
                "margin-right": "0px",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "50px",
                          "margin-right": "50px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "50px",
                              },
                              data: _vm.rightList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "rightType",
                                label: "权益类型",
                                align: "center",
                                formatter: _vm.typeFormat,
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "startDate",
                                label: "权益开始时间",
                                align: "center",
                                resizable: "",
                                "min-width": "100px",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "endDate",
                                label: "权益结束时间",
                                align: "center",
                                resizable: "",
                                "min-width": "100px",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "freeReason",
                                label: "权益原因",
                                align: "center",
                                resizable: "",
                                "min-width": "100px",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isExpired",
                                label: "是否在有效期内",
                                align: "center",
                                resizable: "",
                                "min-width": "100px",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isEnjoy",
                                label: "是否可用",
                                align: "center",
                                formatter: _vm.isEnjoyFormat,
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isUsed",
                                label: "是否已使用",
                                align: "center",
                                formatter: _vm.isUsedFormat,
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "orderNum",
                                label: "单号",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "link-type",
                                          on: {
                                            click: function ($event) {
                                              return _vm.gotoEditHtml(row)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(row.orderNum))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.orderInfo.country !== "CN"
        ? _c(
            "div",
            [
              _c(
                "el-divider",
                {
                  staticClass: "myStyle",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("\n        海外机基本信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "border-top": "none" },
                  attrs: { shadow: "never" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "海外SKU",
                                  prop: "overSeasSku",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.orderInfo.overSeasSku,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderInfo,
                                        "overSeasSku",
                                        $$v
                                      )
                                    },
                                    expression: "orderInfo.overSeasSku",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "销售国家", prop: "country" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.orderInfo.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orderInfo, "country", $$v)
                                      },
                                      expression: "orderInfo.country",
                                    },
                                  },
                                  _vm._l(
                                    _vm.countryOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否全球联保",
                                  prop: "globalWarranty",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { fill: "#000", disabled: "" },
                                    model: {
                                      value: _vm.orderInfo.globalWarranty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderInfo,
                                          "globalWarranty",
                                          $$v
                                        )
                                      },
                                      expression: "orderInfo.globalWarranty",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "true" } },
                                      [_vm._v("是")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "false" } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("咨询附件")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c("upload-file", {
                              attrs: {
                                disabled: true,
                                "attachment-list":
                                  _vm.orderInfo.attachmentList1,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }