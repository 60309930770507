"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _list = require("@/api/basics/dispatch/list");

var _organization = require("@/api/organization");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
// 可拖拽弹出框
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  components: {},
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default,
    permission: _index.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'YES',
        0: 'NO'
      };
      return statusMap[status];
    },
    statusNameFilter: function statusNameFilter(status) {
      var statusMap = {
        1: '启用',
        0: '禁用'
      };
      return statusMap[status];
    },
    tagStatusFilter: function tagStatusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      // this.$route.params.id,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      // <状态控制> 隐藏/显示 型号选择折叠面板
      dialogFormVisible: false,
      // <状态控制> 添加关联型号按钮
      addBtnDisabled: true,
      // <状态控制> 删除关联型号按钮
      deleteBtnDisabled: true,
      organizationList: [],
      // <参数验证> 关联型号列表是否存在
      modelListValidate: false,
      // <参数验证> 查询型号列表条件
      modelSelConditionValidate: false,
      // <下拉数组> 维修分类
      repairTypeSelect: [],
      // <下拉数组> 品类下拉
      categorySelect: [],
      // <下拉数组> 品牌
      brandSelect: [],
      // <下拉数组> 型号
      modelSelect: [],
      defaultSelect: [],
      // 默认选中树节点
      notInData: [],
      // 不显示的节点
      // <多选列表选中数组> 型号选择列表
      multipleSelection4ModelSel: [],
      // <多选列表选中数组> 关联型号列表
      multipleSelection: [],
      loadingList4ModelSelect: false,
      loadingList4Model: false,
      // <列表数组> 型号选择
      list4ModelSelect: null,
      // <列表数组> 关联型号
      list4Model: null,
      // <列表数组> 型号选择条件
      listQuery: {
        categoryId: undefined,
        brandId: undefined,
        id: undefined
      },
      // 入力项目 + 验证规则
      validateForm: {
        orgId: '',
        categoryId: ''
      },
      rules: {
        orgId: [{
          required: true,
          message: '派单对象',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '品类是必选的',
          trigger: 'blur'
        }]
      },
      tableData1: [],
      accordion: false,
      cTree: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      var notInDataT = JSON.parse(JSON.stringify(this.notInData)); // 对源数据深度克隆
      // console.log(11)
      // console.log(notInDataT)

      cloneData.filter(function (father) {
        var f = false;
        notInDataT.forEach(function (v, index) {
          if (father.id === v) {
            f = true;
            return false;
          }
        });

        if (f) {
          return false;
        }

        var branchArr = cloneData.filter(function (child) {
          var f = false;
          notInDataT.forEach(function (v, index) {
            if (child.id === v) {
              f = true;
              return false;
            }
          });

          if (f) {
            return false;
          }

          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : father.children = []; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        // console.log(it)
        if (it.parentId === '0' && it.children.length > 0) {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    }
  },
  activated: function activated() {
    this.getNotInSelect(this.id);
    this.getSelect();
    this.getOrgList();

    if (!this.isCreate) {
      this.getDispatchInfo(this.id);
    } // console.log('activated')

  },
  deactivated: function deactivated() {// console.log('deactivated')
  },
  created: function created() {
    this.getNotInSelect(this.id);
    this.getSelect();
    this.getOrgList();

    if (!this.isCreate) {
      this.getDispatchInfo(this.id);
    } // console.log(this.id)

  },
  methods: {
    changeOrgCategoryList: function changeOrgCategoryList(val) {
      this.getNotInSelect(val);
      this.getDispatchInfo(val);
      this.getSelect(); // console.log(val)
    },
    getNotInSelect: function getNotInSelect(orgId) {
      var _this = this;

      var param = {
        id: orgId,
        type: '2'
      };
      this.notInData = [];
      (0, _list.getCateListByOrgId)(param).then(function (response) {
        response.forEach(function (item, index) {
          _this.notInData.push(item.categoryId);
        });
      });
    },
    getOrgList: function getOrgList() {
      var _this2 = this;

      (0, _organization.getOrganizationList)(this.listQuery).then(function (response) {
        _this2.organizationList = response.data;
      });
    },
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value) {
      this.validateForm.categoryId = value;
    },
    // 获取维修代码详细
    getDispatchInfo: function getDispatchInfo(orgId) {
      var _this3 = this;

      var param = {
        id: orgId,
        type: '1'
      };
      this.defaultSelect = [];
      (0, _list.getCateListByOrgId)(param).then(function (response) {
        var categoryIds = [];
        response.forEach(function (item, index) {
          _this3.validateForm.orgId = item.orgId;
          categoryIds.push(item.categoryId);
        });

        if (categoryIds.length > 0) {
          _this3.validateForm.categoryId = categoryIds.join(',');
        } // console.log(categoryIds)


        _this3.defaultSelect = categoryIds;
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this4 = this;

      var ids = [];
      this.$refs.tree.getCheckedNodes().forEach(function (item, index) {
        if (item.level === 2) {
          ids.push(item.id);
        }
      });
      this.validateForm.categoryId = ids.join(',');
      this.$refs['dataForm'].validate(function (valid) {
        // console.log(this.validateForm)
        if (valid) {
          (0, _list.saveData)(_this4.validateForm).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '新增成功！',
                type: 'success'
              });

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/basics/dispatch/list'
              });
            } else {
              _this4.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 编辑确认
    updateSubmit: function updateSubmit() {
      var _this5 = this;

      var ids = [];
      this.$refs.tree.getCheckedNodes().forEach(function (item, index) {
        if (item.level === 2) {
          ids.push(item.id);
        }
      });
      this.validateForm.categoryId = ids.join(','); // console.log(this.validateForm.categoryId)

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _list.saveData)(_this5.validateForm).then(function (response) {
            if (response.data) {
              _this5.$message({
                showClose: true,
                message: ' 修改成功！',
                type: 'success'
              });

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/basics/dispatch/list'
              });
            } else {
              _this5.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this6 = this;

      var p = {
        level: 2
      }; // 品类下拉

      (0, _select.getCategorySelect)(p).then(function (res) {
        _this6.categorySelect = res.data.records;
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/dispatch/list'
      });
    }
  }
};
exports.default = _default;