"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _quotaPool = require("@/api/reduction/pool/quotaPool");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 按钮权限控制
var _default = {
  name: 'poolLog',
  // eslint-disable-line
  directives: {
    waves: _waves.default,
    permission: _index.default
  },
  filters: {
    disCountPrice: function disCountPrice(discount) {
      var tagMap = {
        1: '1月',
        2: '2月',
        3: '3月',
        4: '4月',
        5: '5月',
        6: '6月',
        7: '7月',
        8: '8月',
        9: '9月',
        10: '10月',
        11: '11月',
        12: '12月'
      };
      return tagMap[discount];
    },
    quotaPoolPrice: function quotaPoolPrice(price) {
      return price / 100;
    },
    toTime: function toTime(time) {
      return (0, _utils.parseTime)(time, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  data: function data() {
    return {
      year: this.$route.params.year,
      tableHeight: 270,
      tableKey: 0,
      list: [],
      total: 0,
      listyear: 0,
      listLoading: false,
      months: [{
        'val': '1',
        'name': '1月'
      }, {
        'val': '2',
        'name': '2月'
      }, {
        'val': '3',
        'name': '3月'
      }, {
        'val': '4',
        'name': '4月'
      }, {
        'val': '5',
        'name': '5月'
      }, {
        'val': '6',
        'name': '6月'
      }, {
        'val': '7',
        'name': '7月'
      }, {
        'val': '8',
        'name': '8月'
      }, {
        'val': '9',
        'name': '9月'
      }, {
        'val': '10',
        'name': '10月'
      }, {
        'val': '11',
        'name': '11月'
      }, {
        'val': '12',
        'name': '12月'
      }],
      listQuery: {
        year: '',
        month: ''
      }
    };
  },
  created: function created() {
    this.getyearandmonth();
    this.getList();
  },
  methods: {
    getyearandmonth: function getyearandmonth() {
      if (this.year === undefined || this.year === null || this.year === '') {
        this.year = new Date();
      }

      this.listQuery.year = this.year;
    },
    // 查询
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.year = this.listQuery.year;
      this.listQuery.year = new Date(this.listQuery.year).getFullYear();
      (0, _quotaPool.getPoolLogList)(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listQuery.year = _this.year;
        _this.listLoading = false;
      });
    },
    // 查询按钮事件
    searchBtnClick: function searchBtnClick() {
      this.$refs['dataForm'].clearValidate();
      this.getList();
    }
  }
};
exports.default = _default;