"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _vseConfig = require("@/api/basics/vseConfig/vseConfig");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { mapGetters } from 'vuex'
var _default = {
  name: 'Dashboard',
  data: function data() {
    return {
      showNotice: false,
      bookDate: '',
      month: '',
      day: '',
      notice: ''
    };
  },
  created: function created() {
    this.getVSEStockNotice();
  },
  activated: function activated() {
    this.getVSEStockNotice();
  },
  methods: {
    getVSEStockNotice: function getVSEStockNotice() {
      var _this = this;

      (0, _vseConfig.getVSEStockNotice)().then(function (res) {
        if (res.data && res.code === 200) {
          _this.showNotice = res.data.state !== '0';
          _this.bookDate = res.data.bookDate.substring(5);
          _this.month = res.data.bookDate.substring(5).split('-')[0];
          _this.day = res.data.bookDate.substring(5).split('-')[1];

          if (res.data.state === '1') {
            _this.notice = _this.month + '月' + _this.day + ' 日VSE库存未配置，请检查后及时处理';
          } else if (res.data.state === '2') {
            _this.notice = _this.month + '月' + _this.day + ' 日VSE可预约库存为0，请检查后及时处理';
          } else {
            _this.notice = '';
          }
        } else {
          _this.showNotice = false;
        }
      });
    }
  }
};
exports.default = _default;