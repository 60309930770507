"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _order = require("@/api/order/order");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _filterUtils = require("@/api/order/filterUtils");

var _select = require("@/api/common/select");

var _utils = require("@/utils");

var _risk = require("@/api/basics/risk/risk");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ModifyDetail',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    linkTypeOptionsFilter: _filterUtils.linkTypeOptionsFilter,
    warrantyFilter: _filterUtils.warrantyFilter,
    countryFilter: _filterUtils.countryFilter
  },
  data: function data() {
    return (0, _defineProperty2.default)({
      id: this.$route.params.id,
      orderInfo: {},
      enableEdit: true,
      showBtn: false,
      modifyHistoryList: [],
      listLoading: false,
      total: 0,
      cancelReasonOptions: _constant.default.cancelReasonOptions,
      listQuery: {
        page: 1,
        limit: 10,
        num: undefined
      },
      modifyHistory: {},
      orderRules: {
        claimTel: [{
          required: true,
          message: '报修人电话是必填的！',
          trigger: 'blur'
        }, {
          max: 11,
          min: 11,
          message: '手机号必须为11位'
        }],
        takeTel: [{
          required: true,
          message: '收件人电话是必填的！',
          trigger: 'blur'
        }, {
          max: 11,
          min: 11,
          message: '手机号必须为11位'
        }],
        takeProvince: [{
          required: true,
          message: '收件省份是必选的',
          trigger: 'blur'
        }],
        takeCity: [{
          required: true,
          message: '收件城市是必填选',
          trigger: 'blur'
        }],
        takeCountry: [{
          required: true,
          message: '收件区域是必选的',
          trigger: 'blur'
        }],
        warrantyNote: [{
          max: 100,
          message: '入保日期备注最多100字符'
        }]
      },
      provinceSelect: [],
      // 省下拉
      citySelect2: [],
      regionSelect2: [],
      tempProvince: '',
      tempCity: '',
      tempCountry: '',
      countryOptions: _constant.default.countryOptions,
      refDateOld: '',
      productShow: false
    }, "countryOptions", _constant.default.countryOptions);
  },
  watch: {
    'orderInfo.claimTel': function orderInfoClaimTel() {
      if (this.orderInfo.claimTel.length === 11) {
        this.getRiskByRiskAndType('1', '20', this.orderInfo.claimTel);
      }
    },
    'orderInfo.takeTel': function orderInfoTakeTel() {
      if (this.orderInfo.takeTel.length === 11) {
        this.getRiskByRiskAndType('2', '20', this.orderInfo.takeTel);
      }
    }
  },
  created: function created() {
    if (this.id) {
      this.getProvinceOptions();
      this.getOrderInfo();
    }
  },
  methods: {
    getCityOption: function getCityOption() {
      var _this = this;

      (0, _select.getAreasByParentId)(this.orderInfo.takeProvince).then(function (response) {
        _this.citySelect2 = response.data;
      });
      (0, _select.getAreasByParentId)(this.orderInfo.takeCity).then(function (response) {
        _this.regionSelect2 = response.data;
      });
    },
    getOrderInfo: function getOrderInfo() {
      var _this2 = this;

      (0, _order.queryOrderInfoById)(this.id).then(function (response) {
        if (response.data) {
          _this2.orderInfo = response.data;

          if (_this2.orderInfo.orderNo) {
            _this2.listQuery.num = _this2.orderInfo.orderNo;

            _this2.getOrderModifyHistory();

            _this2.getCityOption();

            _this2.refDateOld = _this2.orderInfo.refDate;
            _this2.orderInfo.warrantyNote = (0, _utils.HTMLDecode)(_this2.orderInfo.warrantyNote);

            if (_this2.orderInfo.sn !== '' && _this2.orderInfo.sn !== undefined && _this2.orderInfo.sn !== null) {
              _this2.productShow = true;
            }
          }
        }
      });
    },
    getOrderModifyHistory: function getOrderModifyHistory() {
      var _this3 = this;

      this.listLoading = true;
      (0, _order.queryOrderModifyHistory)(this.listQuery).then(function (response) {
        _this3.modifyHistoryList = response.data.records;

        _this3.modifyHistoryList.forEach(function (item) {
          item.warrantyNote = (0, _utils.HTMLDecode)(item.warrantyNote);
        });

        _this3.total = response.data.total;
        _this3.listLoading = false;
      });
    },
    saveOrderInfo: function saveOrderInfo() {
      var _this4 = this;

      if (this.refDateOld !== this.orderInfo.refDate) {
        if (this.orderInfo.warrantyNote === '' || this.orderInfo.warrantyNote === undefined || this.orderInfo.warrantyNote === null) {
          this.$message({
            showClose: true,
            message: '入保日期已改变，请填写入保日期备注！',
            type: 'warning'
          });
          return;
        }

        if (this.orderInfo.warrantyNote.trim() === '') {
          this.$message({
            showClose: true,
            message: '入保日期已改变，请填写入保日期备注！',
            type: 'warning'
          });
          return;
        }
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _order.updateOrder)(_this4.orderInfo).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '更新成功',
                type: 'success'
              });

              _this4.modifyHistory.type = '修改后';
              _this4.modifyHistory.orderNo = _this4.orderInfo.orderNo;
              _this4.modifyHistory.claimName = _this4.orderInfo.claimName;
              _this4.modifyHistory.claimTel = _this4.orderInfo.claimTel;
              _this4.modifyHistory.takeName = _this4.orderInfo.takeName;
              _this4.modifyHistory.takeTel = _this4.orderInfo.takeTel;
              _this4.modifyHistory.takeProvince = _this4.orderInfo.takeProvince;
              _this4.modifyHistory.takeCity = _this4.orderInfo.takeCity;
              _this4.modifyHistory.takeCountry = _this4.orderInfo.takeCountry;
              _this4.modifyHistory.takeAddress = _this4.orderInfo.takeAddress;
              _this4.modifyHistory.linkType = _this4.orderInfo.linkType;
              _this4.modifyHistory.orderJson = JSON.stringify(_this4.orderInfo);
              _this4.modifyHistory.warrantyDate = _this4.orderInfo.refDate;
              _this4.modifyHistory.warrantyNote = (0, _utils.HTMLEncode)(_this4.orderInfo.warrantyNote);
              _this4.modifyHistory.country = _this4.orderInfo.country;
              _this4.modifyHistory.globalWarranty = _this4.orderInfo.globalWarranty;
              (0, _order.updateOrderModifyHistory)(_this4.modifyHistory).then(function (response) {
                if (response.data) {
                  _this4.$message({
                    showClose: true,
                    message: '新增历史记录成功！',
                    type: 'success'
                  });

                  _this4.getOrderModifyHistory();

                  _this4.getOrderInfo();
                }
              });
            }

            _this4.enableEdit = true;
            _this4.showBtn = false;
          });
        }
      });
    },
    modifyOrder: function modifyOrder() {
      var _this5 = this;

      // 对应的服务单状态不能修改
      // 已结束
      if (this.orderInfo.state === 'S5') {
        if (this.orderInfo.subState === 'S500' || this.orderInfo.subState === 'S501' || this.orderInfo.subState === 'S502' || this.orderInfo.subState === 'S503' || this.orderInfo.subState === 'S505' || this.orderInfo.subState === 'S506' || this.orderInfo.subState === 'S508' || this.orderInfo.subState === 'S599' || this.orderInfo.subState === 'S509') {
          this.$message({
            showClose: true,
            message: '该服务单的状态不允许修改！',
            type: 'warning'
          });
          return;
        } // 已检测

      } else if (this.orderInfo.state === 'S3') {
        if (this.orderInfo.subState === 'S504') {
          this.$message({
            showClose: true,
            message: '该服务单的状态不允许修改！',
            type: 'warning'
          });
          return;
        }
      }

      this.enableEdit = false;
      this.showBtn = true;
      this.modifyHistory.type = '修改前';
      this.modifyHistory.orderNo = this.orderInfo.orderNo;
      this.modifyHistory.claimName = this.orderInfo.claimName;
      this.modifyHistory.claimTel = this.orderInfo.claimTel;
      this.modifyHistory.takeName = this.orderInfo.takeName;
      this.modifyHistory.takeTel = this.orderInfo.takeTel;
      this.modifyHistory.takeProvince = this.orderInfo.takeProvince;
      this.modifyHistory.takeCity = this.orderInfo.takeCity;
      this.modifyHistory.takeCountry = this.orderInfo.takeCountry;
      this.modifyHistory.takeAddress = this.orderInfo.takeAddress;
      this.modifyHistory.linkType = this.orderInfo.linkType;
      this.modifyHistory.orderJson = JSON.stringify(this.orderInfo);
      this.modifyHistory.warrantyDate = this.orderInfo.refDate;
      this.modifyHistory.warrantyNote = (0, _utils.HTMLEncode)(this.orderInfo.warrantyNote);
      this.modifyHistory.country = this.orderInfo.country;
      this.modifyHistory.globalWarranty = this.orderInfo.globalWarranty;
      (0, _order.updateOrderModifyHistory)(this.modifyHistory).then(function (response) {
        if (response.data) {
          _this5.$message({
            showClose: true,
            message: '新增历史记录成功！',
            type: 'success'
          });

          _this5.getOrderModifyHistory();
        }
      });
    },
    // 收件人 省份
    proviceChange2: function proviceChange2(province2) {
      var _this6 = this;

      if (province2) {
        (0, _select.getAreasByParentId)(province2).then(function (response) {
          _this6.citySelect2 = response.data;
        });
      }

      this.orderInfo.takeCity = '';
      this.orderInfo.takeCountry = '';
    },
    // 收件人 城市
    cityChange2: function cityChange2(city2) {
      var _this7 = this;

      if (city2) {
        (0, _select.getAreasByParentId)(city2).then(function (response) {
          _this7.regionSelect2 = response.data;
        });
      }

      this.orderInfo.takeCountry = '';
    },
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this8 = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this8.provinceSelect = res.data;
      });
    },
    // 点击详情
    toRiskDetail: function toRiskDetail(id) {
      this.$router.push({
        name: 'createRisk',
        params: {
          id: id
        }
      });
    },
    getRiskByRiskAndType: function getRiskByRiskAndType(name, type, risk) {
      var _this9 = this;

      (0, _risk.getRiskByRiskAndType)({
        type: type,
        risk: risk
      }).then(function (response) {
        if (response.data) {
          // 报修人
          if (name === '1') {
            _this9.orderInfo.claimTelRisk = response.data.id;
            _this9.orderInfo.flagClaimTelRisk = true;
          } else if (name === '2') {
            // 收货人
            _this9.orderInfo.takeTelRisk = response.data.id;
            _this9.orderInfo.flagTakeTelRisk = true;
          } else if (name === '3') {
            // 收货详细地址
            _this9.orderInfo.takeAddressRisk = response.data.id;
            _this9.orderInfo.flagTakeAddressRisk = true;
          }
        }
      });
    }
  }
};
exports.default = _default;