"use strict";

module.exports = {
  title: process.env.VUE_APP_TITLE,

  /**
   * @type {boolean} true | false
   * @description 是否固定页眉
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description 是否在侧栏中显示logo
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description 是否显示设置右面板
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description 是否需要tagsView
   */
  tagsView: true
};