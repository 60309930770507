var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.handleCreate },
        },
        [_vm._v("\n    创建任务\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            "append-to-body": true,
            title: "创建任务",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.orderTask,
                "label-position": "right",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工单任务标题", prop: "title" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: 100,
                          placeholder: "请输入工单标题,最长可输入100个字符",
                          disabled: _vm.codeDisabled,
                        },
                        model: {
                          value: _vm.orderTask.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderTask, "title", $$v)
                          },
                          expression: "orderTask.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "任务类型", prop: "taskType" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择任务类型",
                          },
                          on: { focus: _vm.getTaskTypeSelect },
                          model: {
                            value: _vm.orderTask.taskType,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderTask, "taskType", $$v)
                            },
                            expression: "orderTask.taskType",
                          },
                        },
                        _vm._l(_vm.taskTypeSelect, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              disabled: item.isEnable !== "1",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "小组名称", prop: "taskHandleGroup" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          on: { focus: _vm.loadGroupSelect },
                          model: {
                            value: _vm.orderTask.taskHandleGroup,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderTask, "taskHandleGroup", $$v)
                            },
                            expression: "orderTask.taskHandleGroup",
                          },
                        },
                        _vm._l(_vm.groupSelect, function (item) {
                          return _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isEnable === "1",
                                expression: "item.isEnable==='1'",
                              },
                            ],
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "处理人", prop: "taskHandleRmk" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          on: { focus: _vm.loadUserSelect },
                          model: {
                            value: _vm.orderTask.taskHandleRmk,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderTask, "taskHandleRmk", $$v)
                            },
                            expression: "orderTask.taskHandleRmk",
                          },
                        },
                        _vm._l(_vm.userSelect, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              disabled: item.isEnable === "0",
                              label: item.displayName,
                              value: item.memberId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单任务描述", prop: "describe1" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "2000",
                          autosize: { minRows: 5 },
                          type: "textarea",
                          placeholder: "请输入工单任务描述",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.orderTask.describe1,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderTask, "describe1", $$v)
                          },
                          expression: "orderTask.describe1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传附件" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _vm.dialogFormVisible
                        ? _c("new-upload-file", {
                            ref: "upload1",
                            attrs: {
                              limit: 5,
                              attachment: _vm.orderTask.enclosure,
                            },
                            on: { setFilePaths: _vm.setFilePaths },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单任务优先级", prop: "priority" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.orderTask.priority,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderTask, "priority", $$v)
                            },
                            expression: "orderTask.priority",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "10" } }, [
                            _vm._v("低"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "20" } }, [
                            _vm._v("一般"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "30" } }, [
                            _vm._v("紧急"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "40" } }, [
                            _vm._v("非常紧急"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "期望完成日期", prop: "completeDate" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "right",
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          "picker-options": _vm.pickerOptions,
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.orderTask.completeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderTask, "completeDate", $$v)
                          },
                          expression: "orderTask.completeDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.dbClickDisabled },
                  on: { click: _vm.createData },
                },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }