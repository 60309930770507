"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLabelList = getLabelList;
exports.getLabelListByType = getLabelListByType;
exports.saveLabel = saveLabel;
exports.updateLabel = updateLabel;

var _request = _interopRequireDefault(require("@/utils/request"));

function getLabelList(data) {
  return (0, _request.default)({
    url: '/bus_app/baseLabel/getLabelList',
    method: 'post',
    data: data
  });
}

function saveLabel(data) {
  return (0, _request.default)({
    url: '/bus_app/baseLabel/saveLabel',
    method: 'post',
    data: data
  });
}

function updateLabel(data) {
  return (0, _request.default)({
    url: '/bus_app/baseLabel/updateLabel',
    method: 'post',
    data: data
  });
}

function getLabelListByType(data) {
  return (0, _request.default)({
    url: '/bus_app/baseLabel/getLabelListByType',
    method: 'post',
    data: data
  });
}