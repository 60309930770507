"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 客服管理块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var calloutRouter = {
  path: '/callout/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'service/detail/:id',
    name: 'calloutOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/callout/service/detail'));
      });
    },
    meta: {
      title: '外呼详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/callout/service/list',
      parent: {
        path: '/callout',
        redirect: 'noRedirect',
        meta: {
          title: '客服管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'callBill/detail/:id',
    name: 'callBillDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/callout/callBill/detail'));
      });
    },
    meta: {
      title: '发票信息登记',
      icon: 'table',
      hidden: true,
      activeMenu: '/callout/callBill',
      parent: {
        path: '/callout',
        redirect: 'noRedirect',
        meta: {
          title: '客服管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'callSale/detail/:id/:num',
    name: 'calloutSaleDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/callout/callSale/detail'));
      });
    },
    meta: {
      title: '销售外呼详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/callout/callSale/index',
      parent: {
        path: '/callout',
        redirect: 'noRedirect',
        meta: {
          title: '客服管理',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = calloutRouter;
exports.default = _default;