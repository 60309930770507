var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "myPanel-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 20 } },
            [
              _vm.showBtn
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveOrderInfo },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.showBtn
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.modifyOrder },
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.orderInfo,
            rules: _vm.orderRules,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-left": "0px", "margin-right": "0px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "50px",
                          "margin-right": "50px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "服务单信息" },
                          [
                            _c(
                              "el-divider",
                              {
                                staticClass: "myStyle",
                                attrs: { "content-position": "left" },
                              },
                              [_vm._v("服务单信息")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { "border-top": "none" },
                                attrs: { shadow: "never" },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "服务单号" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.orderNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "orderNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.orderNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "创建人" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .createUserName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "createUserName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.createUserName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "创建时间" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.createTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "createTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.createTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.orderInfo.cancelReason &&
                                _vm.orderInfo.cancelReason !== ""
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "取消原因",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled: "",
                                                              filterable: "",
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.orderInfo
                                                                  .cancelReason,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.orderInfo,
                                                                    "cancelReason",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "orderInfo.cancelReason",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.cancelReasonOptions,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.name,
                                                                    value:
                                                                      item.val,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "取消人",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.orderInfo
                                                                .cancelUserName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderInfo,
                                                                "cancelUserName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderInfo.cancelUserName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "取消时间",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.orderInfo
                                                                .cancelTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderInfo,
                                                                "cancelTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderInfo.cancelTime",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "取消备注",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "filter-item",
                                                          attrs: {
                                                            disabled: "",
                                                            type: "textarea",
                                                            autosize: {
                                                              minRows: 2,
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.orderInfo
                                                                .cancelMemo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderInfo,
                                                                "cancelMemo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderInfo.cancelMemo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "客户信息" },
                          [
                            _c(
                              "el-divider",
                              {
                                staticClass: "myStyle",
                                attrs: { "content-position": "left" },
                              },
                              [_vm._v("客户信息")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { "border-top": "none" },
                                attrs: { shadow: "never" },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "机主" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.ownerName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "ownerName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.ownerName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "机主电话" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.ownerTel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "ownerTel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.ownerTel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagOwnerTelRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .ownerTelRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, push: 2 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: "1",
                                                  border: "",
                                                  disabled: _vm.enableEdit,
                                                },
                                                model: {
                                                  value: _vm.orderInfo.linkType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "linkType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.linkType",
                                                },
                                              },
                                              [_vm._v("默认联系人")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "报修人" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.claimName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "claimName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.claimName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "报修人电话",
                                                  prop: "claimTel",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.claimTel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "claimTel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.claimTel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagClaimTelRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .claimTelRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, push: 2 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: "2",
                                                  border: "",
                                                  disabled: _vm.enableEdit,
                                                },
                                                model: {
                                                  value: _vm.orderInfo.linkType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "linkType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.linkType",
                                                },
                                              },
                                              [_vm._v("默认联系人")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "寄件人" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.sendName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "寄件人电话" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.sendTel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendTel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendTel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagSendTelRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .sendTelRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, push: 2 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: "3",
                                                  border: "",
                                                  disabled: _vm.enableEdit,
                                                },
                                                model: {
                                                  value: _vm.orderInfo.linkType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "linkType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.linkType",
                                                },
                                              },
                                              [_vm._v("默认联系人")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "收件人" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.takeName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "收件人电话",
                                                  prop: "takeTel",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.takeTel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeTel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeTel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagTakeTelRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .takeTelRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, push: 2 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: "4",
                                                  border: "",
                                                  disabled: _vm.enableEdit,
                                                },
                                                model: {
                                                  value: _vm.orderInfo.linkType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "linkType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.linkType",
                                                },
                                              },
                                              [_vm._v("默认联系人")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "寄件地址" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.sendAddress,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendAddress",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagSendAddressRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .sendAddressRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "收件省份",
                                                  prop: "takeProvince",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      disabled: _vm.enableEdit,
                                                      filterable: "",
                                                      placeholder: "请选择",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.proviceChange2(
                                                          _vm.orderInfo
                                                            .takeProvince
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo
                                                          .takeProvince,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "takeProvince",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.takeProvince",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.provinceSelect,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "收件城市",
                                                  prop: "takeCity",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled: _vm.enableEdit,
                                                      filterable: "",
                                                      placeholder: "请选择",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.cityChange2(
                                                          _vm.orderInfo.takeCity
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.takeCity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "takeCity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.takeCity",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.citySelect2,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "收件区域",
                                                  prop: "takeCountry",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled: _vm.enableEdit,
                                                      filterable: "",
                                                      placeholder: "请选择",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo
                                                          .takeCountry,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "takeCountry",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.takeCountry",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.regionSelect2,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "收件地址" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.getRiskByRiskAndType(
                                                        "3",
                                                        "40",
                                                        _vm.orderInfo
                                                          .takeAddress
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.takeAddress,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeAddress",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagTakeAddressRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo
                                                          .takeAddressRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.orderInfo.country !== "CN"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                海外机基本信息\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "海外SKU",
                                                      prop: "overSeasSku",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .overSeasSku,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "overSeasSku",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.overSeasSku",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "销售国家",
                                                      prop: "country",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.enableEdit,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .country,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "country",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.country",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.countryOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "是否全球联保",
                                                      prop: "globalWarranty",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        attrs: {
                                                          fill: "#000",
                                                          disabled:
                                                            _vm.enableEdit,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .globalWarranty,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "globalWarranty",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.globalWarranty",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: {
                                                              label: "true",
                                                            },
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: {
                                                              label: "false",
                                                            },
                                                          },
                                                          [_vm._v("否")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.productShow,
                                expression: "productShow",
                              },
                            ],
                            staticClass: "产品信息",
                          },
                          [
                            _c(
                              "el-divider",
                              {
                                staticClass: "myStyle",
                                attrs: { "content-position": "left" },
                              },
                              [_vm._v("产品信息")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { "border-top": "none" },
                                attrs: { shadow: "never" },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "产品注册SN" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: _vm.orderInfo.sn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sn",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "orderInfo.sn",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.orderInfo.flagSnRisk
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-warning",
                                                  staticStyle: {
                                                    position: "relative",
                                                    left: "60px",
                                                    bottom: "56px",
                                                    color: "red",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toRiskDetail(
                                                        _vm.orderInfo.snRisk
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "品类" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .categoryName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "categoryName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.categoryName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "型号" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.modelName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "modelName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.modelName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "主机SKU" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.skuName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "skuName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.skuName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "注册时间" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.activeDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "activeDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.activeDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "购买日期" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.salesDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "salesDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.salesDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "购买渠道" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .salesChannel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "salesChannel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.salesChannel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "是否自营" },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      size: "medium",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo
                                                          .isSelfChannel,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "isSelfChannel",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.isSelfChannel",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "产品90天会员",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .isEquity90days,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "isEquity90days",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.isEquity90days",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "出库日期" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.productDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "productDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.productDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "订单日期" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.orderDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "orderDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.orderDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "入保日期" },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    placeholder: "选择日期",
                                                    disabled: _vm.enableEdit,
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.refDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "refDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.refDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "入保日期备注",
                                                  prop: "warrantyNote",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: {
                                                    disabled: _vm.enableEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .warrantyNote,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "warrantyNote",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.warrantyNote",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "是否海外机" },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      size: "medium",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.isAbroad,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "isAbroad",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.isAbroad",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "是否翻新机" },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      size: "medium",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.refurb,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "refurb",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.refurb",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { label: "延保时长" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .extendBenefitDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "extendBenefitDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.extendBenefitDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "保修结束日期",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    placeholder: "保修结束日期",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .benefitEndDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "benefitEndDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.benefitEndDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "是否延长保修",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: "",
                                                      size: "medium",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo
                                                          .isExtendBenefit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "isExtendBenefit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.isExtendBenefit",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "0px",
                "margin-right": "0px",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "50px",
                          "margin-right": "50px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "50px",
                              },
                              data: _vm.modifyHistoryList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "reUser",
                                label: "操作人",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inDate",
                                label: "操作时间",
                                align: "center",
                                width: "180px",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "type",
                                label: "类型",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "claimName",
                                label: "报修人",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "claimTel",
                                label: "报修人电话",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeName",
                                label: "收件人",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeTel",
                                label: "收件人电话",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeProvince",
                                label: "收件省",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeCity",
                                label: "收件市",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeCountry",
                                label: "收件区",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "takeAddress",
                                label: "详细地址",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "linkType",
                                label: "默认联系人",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("linkTypeOptionsFilter")(
                                            row.linkType
                                          )
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "warrantyDate",
                                label: "入保日期",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "warrantyNote",
                                label: "入保日期备注",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "country",
                                label: "销售国家",
                                "sort-by": "5",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("countryFilter")(row.country)
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "globalWarranty",
                                label: "是否全球联保",
                                "sort-by": "6",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("warrantyFilter")(
                                            row.globalWarranty
                                          )
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "warrantyDate",
                                label: "入保日期",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "warrantyNote",
                                label: "入保日期备注",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total>0",
                            },
                          ],
                          attrs: {
                            total: _vm.total,
                            page: _vm.listQuery.page,
                            limit: _vm.listQuery.limit,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.listQuery, "page", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.listQuery, "limit", $event)
                            },
                            pagination: _vm.getOrderModifyHistory,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "1rem" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 10 } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/modify/order_modify/list",
                      })
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }