var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _vm.stockTrim.state === "10"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_TRIM_SAVE"],
                      expression: "['ROLE_STOCK_STOCK_TRIM_SAVE']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isTemp,
                    size: "small",
                    type: "warning",
                    icon: "el-icon-folder",
                  },
                  on: { click: _vm.tempSave },
                },
                [_vm._v("暂存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stockTrim.state === "10"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_TRIM_SUBMIT"],
                      expression: "['ROLE_STOCK_STOCK_TRIM_SUBMIT']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isSub,
                    size: "small",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submitSave },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stockTrim.state === "20"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_TRIM_CONFIRM"],
                      expression: "['ROLE_STOCK_STOCK_TRIM_CONFIRM']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isCon,
                    size: "small",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.confirmSave },
                },
                [_vm._v("确认")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.stockTrim,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 60px 0 40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "盘点单号", prop: "num" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.stockTrim.num,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockTrim, "num", $$v)
                                      },
                                      expression: "stockTrim.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "仓库",
                                    prop: "warehouseCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.infoDisabled,
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.stockTrim.warehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stockTrim,
                                            "warehouseCode",
                                            $$v
                                          )
                                        },
                                        expression: "stockTrim.warehouseCode",
                                      },
                                    },
                                    _vm._l(_vm.warehouseList, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                          disabled:
                                            item.orgIsEnable !== "1" ||
                                            item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "盘点备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      autosize: { minRows: 4, maxRows: 6 },
                                      disabled: _vm.stockTrim.state == "20",
                                      type: "textarea",
                                      placeholder: "200字以内",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.stockTrim.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockTrim, "memo", $$v)
                                      },
                                      expression: "stockTrim.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("物料明细"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.skuListValidate,
                                expression: "skuListValidate",
                              },
                            ],
                            staticClass: "modelList__error",
                          },
                          [_vm._v(" 请添加物料 ！")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: {
                            float: "right",
                            "padding-right": "60px",
                          },
                        },
                        [
                          _vm.stockTrim.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "success",
                                    plain: "",
                                  },
                                  on: { click: _vm.addSkuClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _vm._v("添加\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.stockTrim.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.deleteBtnDisabled,
                                    size: "small",
                                    type: "danger",
                                    plain: "",
                                  },
                                  on: { click: _vm.deleteModelClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除\n            "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "1px 60px 0 60px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingList4SkuSelect,
                                      expression: "loadingList4SkuSelect",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "header-cell-style": {
                                      background: "#eef1f6",
                                      color: "#606266",
                                    },
                                    size: "small",
                                    border: "",
                                    stripe: "",
                                    "tooltip-effect": "dark",
                                    data: _vm.list4Sku,
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "row-click": _vm.rowClickSelection,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      selectable: _vm.checkboxT,
                                      width: "50",
                                      align: "center",
                                      prop: "modelId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuCode",
                                      label: "物料编码",
                                      "min-width": "100px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuName",
                                      label: "物料名称",
                                      "min-width": "200px",
                                      resizable: "",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantity",
                                      label: "库存数量",
                                      "min-width": "100px",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "checkQuantity",
                                      label: "盘点数量",
                                      "min-width": "100px",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "",
                                      label: "SN",
                                      "min-width": "100px",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return row.isSn === "1"
                                              ? [
                                                  _vm.stockTrim.state === "10"
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            plain: "",
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleSn(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      选择\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            plain: "",
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showSn(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      查看\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              : undefined
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物料添加",
            visible: _vm.addSkudialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSkudialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryModelForm",
                      attrs: { model: _vm.listQuery, "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "型号选择", prop: "modelId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择...",
                                      },
                                      model: {
                                        value: _vm.listQuery.modelId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "modelId",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.modelId",
                                      },
                                    },
                                    _vm._l(_vm.modelList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "物料名称", prop: "sku" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { placeholder: "物料名称" },
                                    model: {
                                      value: _vm.listQuery.sku,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "sku", $$v)
                                      },
                                      expression: "listQuery.sku",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("搜索")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.resetBtn },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "listTableDiv" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableKey",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": {
                      background: "#eef1f6",
                      color: "#606266",
                    },
                    size: "mini",
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark",
                    data: _vm.skuItemsList,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "skuCode",
                      label: "物料编码",
                      "min-width": "80px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "skuName",
                      label: "物料名称",
                      "min-width": "100px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "usableStock",
                      label: "库存数量",
                      "min-width": "100px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盘点数量",
                      width: "100px",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticClass: "edit-input",
                              staticStyle: { width: "100%" },
                              attrs: { size: "mini", controls: false, min: 1 },
                              model: {
                                value: row.checkQuantity,
                                callback: function ($$v) {
                                  _vm.$set(row, "checkQuantity", $$v)
                                },
                                expression: "row.checkQuantity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleModifyStatus(row)
                                  },
                                },
                              },
                              [_vm._v("\n              添加\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleFilter,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addSkudialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "盘亏序列号",
            visible: _vm.dialogFormVisible_0,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible_0 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-transfer",
                {
                  staticStyle: {
                    "text-align": "left",
                    display: "inline-block",
                  },
                  attrs: {
                    filterable: "",
                    titles: ["串号表", "已添加串号"],
                    data: _vm.snList,
                  },
                  model: {
                    value: _vm.snSure,
                    callback: function ($$v) {
                      _vm.snSure = $$v
                    },
                    expression: "snSure",
                  },
                },
                [
                  _c(
                    "el-tag",
                    {
                      staticClass: "transfer-footer",
                      staticStyle: { "margin-left": "2rem" },
                      attrs: { slot: "right-footer", size: "small" },
                      slot: "right-footer",
                    },
                    [
                      _vm._v(
                        "需添加 " +
                          _vm._s(_vm.snNumber) +
                          "\n          个串号\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "40px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.cancelSn } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.saveSn } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "盘盈序列号",
            visible: _vm.dialogFormVisible_1,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible_1 = $event
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "el-transfer-panel__filter",
            attrs: {
              size: "small",
              placeholder: "请添加序列号（Enter键录入）",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.snInput($event)
              },
            },
            model: {
              value: _vm.snIn,
              callback: function ($$v) {
                _vm.snIn = $$v
              },
              expression: "snIn",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-transfer",
                {
                  staticStyle: {
                    "text-align": "left",
                    display: "inline-block",
                  },
                  attrs: {
                    filterable: "",
                    titles: ["串号表", "已添加串号"],
                    data: _vm.snList,
                  },
                  model: {
                    value: _vm.snSure,
                    callback: function ($$v) {
                      _vm.snSure = $$v
                    },
                    expression: "snSure",
                  },
                },
                [
                  _c(
                    "el-tag",
                    {
                      staticClass: "transfer-footer",
                      staticStyle: { "margin-left": "2rem" },
                      attrs: { slot: "right-footer", size: "small" },
                      slot: "right-footer",
                    },
                    [
                      _vm._v(
                        "需添加 " +
                          _vm._s(_vm.snNumber) +
                          "\n          个串号\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "40px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.cancelSn } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.saveSn } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看序列号", visible: _vm.ShowSnDialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.ShowSnDialogFormVisible = $event
            },
          },
        },
        [
          _c("el-form", { attrs: { "label-width": "120px" } }, [
            _c(
              "div",
              { staticClass: "dashboard-text" },
              _vm._l(_vm.showSnList, function (sn, index) {
                return _c(
                  "el-tag",
                  { key: index, staticStyle: { width: "25%" } },
                  [_vm._v(_vm._s(sn))]
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.ShowSnDialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        关闭\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }