var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.orderInfo.orderNo)),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm._f("stateFilter")(_vm.orderInfo.state))),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  _vm._s(_vm._f("subStateFilter")(_vm.orderInfo.subState))
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("new-task", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["ROLE_WORK_TASK_MY_LIST"],
                expression: "['ROLE_WORK_TASK_MY_LIST']",
              },
            ],
            attrs: {
              "work-id": _vm.orderInfo.id,
              "work-no": _vm.orderInfo.orderNo,
              "work-type": "2",
              "task-link": "30",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_RECHECK_COMMIT"],
                  expression: "['ROLE_ORDER_RECHECK_COMMIT']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.recheckConfirm(true)
                },
              },
            },
            [_vm._v("确认")]
          ),
          _vm._v(" "),
          _vm.orderInfo.recheckType === "10"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_ORDER_RECHECK_COMMIT"],
                      expression: "['ROLE_ORDER_RECHECK_COMMIT']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.recheckConfirm(false)
                    },
                  },
                },
                [_vm._v("拒绝")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_RECHECK_LIST"],
                  expression: "['ROLE_ORDER_RECHECK_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "recheck_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.consumerShow,
                                  callback: function ($$v) {
                                    _vm.consumerShow = $$v
                                  },
                                  expression: "consumerShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-consumer", {
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "检测信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("检测信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "用户描述",
                                                      prop: "userDescribe",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .userDescribe,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "userDescribe",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.userDescribe",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现前",
                                                      prop: "problemBefore",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemBefore,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemBefore",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemBefore",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现后",
                                                      prop: "problemAfter",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemAfter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemAfter",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemAfter",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "resolve方案",
                                                      prop: "problemResolve",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemResolve,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemResolve",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemResolve",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测服务类型",
                                                      prop: "checkType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测服务子类型",
                                                      prop: "checkSubType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkSubType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkSubType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkSubType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessSubTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "用户使用时长",
                                                      prop: "checkUseTime",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-input",
                                                      {
                                                        staticClass:
                                                          "filter-item",
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkUseTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkUseTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkUseTime",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v("小时")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测结果",
                                                      prop: "checkResult",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkResult,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkResult",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkResult",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.checkResultOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "检测人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .checkUserName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "checkUserName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.checkUserName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测时间",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .checkTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "checkTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.checkTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测备注",
                                                      prop: "checkRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                        maxlength: "1000",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .checkRemark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "checkRemark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.checkRemark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "160px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.schemeListLoading,
                                                        expression:
                                                          "schemeListLoading",
                                                      },
                                                    ],
                                                    attrs: {
                                                      size: "mini",
                                                      border: "",
                                                      stripe: "",
                                                      "tooltip-effect": "dark",
                                                      "header-cell-style": {
                                                        background: "#eef1f6",
                                                        color: "#606266",
                                                        height: "40px",
                                                      },
                                                      data: _vm.schemeList,
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "indexNum",
                                                        label: "序号",
                                                        width: "55",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "problemTypeName",
                                                        label: "问题分类",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "faultName",
                                                        label: "问题代码",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "repairNames",
                                                        label: "处理方式",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                (_vm.orderInfo.checkType === "02" &&
                                  _vm.orderInfo.checkSubType === "0203") ||
                                _vm.orderInfo.checkSubType === "0102" ||
                                _vm.orderInfo.checkSubType === "0103"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-divider",
                                          {
                                            staticClass: "myStyle",
                                            attrs: {
                                              "content-position": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "服务SKU\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-card",
                                          {
                                            staticClass: "box-card",
                                            staticStyle: {
                                              "border-top": "none",
                                            },
                                            attrs: { shadow: "never" },
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 24,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "160px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "loading",
                                                                rawName:
                                                                  "v-loading",
                                                                value:
                                                                  _vm.skuFeeListLoading,
                                                                expression:
                                                                  "skuFeeListLoading",
                                                              },
                                                            ],
                                                            attrs: {
                                                              size: "mini",
                                                              border: "",
                                                              stripe: "",
                                                              "tooltip-effect":
                                                                "dark",
                                                              "header-cell-style":
                                                                {
                                                                  background:
                                                                    "#eef1f6",
                                                                  color:
                                                                    "#606266",
                                                                  height:
                                                                    "40px",
                                                                },
                                                              data: _vm.skuFeeList,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  prop: "indexNum",
                                                                  label: "序号",
                                                                  width: "55",
                                                                  align:
                                                                    "center",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  prop: "code",
                                                                  label:
                                                                    "服务SKU编码",
                                                                  resizable: "",
                                                                  align:
                                                                    "center",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  prop: "name",
                                                                  label:
                                                                    "服务SKU名称",
                                                                  resizable: "",
                                                                  align:
                                                                    "center",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  prop: "chargeType",
                                                                  label:
                                                                    "收费类型",
                                                                  width: "150",
                                                                  align:
                                                                    "center",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "feeTypeFilter"
                                                                                    )(
                                                                                      row.chargeType
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1612796705
                                                                  ),
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  prop: "quantity",
                                                                  label: "数量",
                                                                  width: "160",
                                                                  resizable: "",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [[_vm._v("1")]],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("部件信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 16, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "复核类型",
                                                      prop: "recheckType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.loadOrderSkuList,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .recheckType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "recheckType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.recheckType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.recheckTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.exchangeFlag,
                                                expression: "exchangeFlag",
                                              },
                                            ],
                                            attrs: { span: 7, pull: 1 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "启用换机优惠价",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        attrs: {
                                                          size: "medium",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.queryPriceItem,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .exchangePreferentialPriceSwitch,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "exchangePreferentialPriceSwitch",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.exchangePreferentialPriceSwitch",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: {
                                                              label: "1",
                                                            },
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: {
                                                              label: "0",
                                                            },
                                                          },
                                                          [_vm._v("否")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.orderInfo.recheckType === "20"
                                      ? _c(
                                          "el-row",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: [
                                                  "ROLE_ORDER_CHECK_COMMIT",
                                                ],
                                                expression:
                                                  "['ROLE_ORDER_CHECK_COMMIT']",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 14, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          label: "添加物料",
                                                          prop: "skuId",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              filterable: "",
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择添加的物料",
                                                            },
                                                            on: {
                                                              focus:
                                                                _vm.loadSkuSelect,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.skuAdd
                                                                  .skuCode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.skuAdd,
                                                                    "skuCode",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "skuAdd.skuCode",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.skuSelect,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.code +
                                                                      "  " +
                                                                      item.name,
                                                                    value:
                                                                      item.code,
                                                                    disabled:
                                                                      item.isEnable ===
                                                                      "0",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          float:
                                                                            "left",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.code
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          float:
                                                                            "right",
                                                                          color:
                                                                            "#8492a6",
                                                                          "font-size":
                                                                            "13px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "margin-left": "40px",
                                                },
                                                attrs: { span: 1, pull: 1 },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid-content bg-purple",
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.btnLoading,
                                                            expression:
                                                              "btnLoading",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.skuAddBtnDisabled,
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addSkuClick,
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.orderInfo.recheckType === "20"
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "160px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.skuListLoading,
                                                            expression:
                                                              "skuListLoading",
                                                          },
                                                        ],
                                                        attrs: {
                                                          size: "mini",
                                                          border: "",
                                                          stripe: "",
                                                          "tooltip-effect":
                                                            "dark",
                                                          "header-cell-style": {
                                                            background:
                                                              "#eef1f6",
                                                            color: "#606266",
                                                            height: "40px",
                                                          },
                                                          data: _vm.skuList,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "indexNum",
                                                            label: "序号",
                                                            width: "55",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuCode",
                                                            label: "物料编码",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuName",
                                                            label: "物料名称",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuType",
                                                            label: "物料分类",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "skuTypeFilter"
                                                                        )(
                                                                          row.skuType
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1900196742
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuTag",
                                                            label: "物料标识",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "skuTagFilter"
                                                                        )(
                                                                          row.skuTag
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1516746950
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "categoryName",
                                                            label: "品类",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "modelName",
                                                            label: "型号",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "feeType",
                                                            label: "收费类型",
                                                            width: "150",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "feeTypeFilter"
                                                                        )(
                                                                          row.feeType
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1670220358
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "quantity",
                                                            label: "数量",
                                                            width: "160",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row.quantity
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2861028836
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "DDIR库存",
                                                            width: "160",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var row =
                                                                    ref.row
                                                                  return [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row.ddirStock
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3690524708
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.orderInfo
                                                          .recheckType === "20"
                                                          ? _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label: "编辑",
                                                                  align:
                                                                    "center",
                                                                  width: "160",
                                                                  "class-name":
                                                                    "small-padding fixed-width",
                                                                  fixed:
                                                                    "right",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    plain:
                                                                                      "",
                                                                                    type: "primary",
                                                                                    size: "mini",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.removeSkuClick(
                                                                                        row
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "删除"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1852571551
                                                                  ),
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24, pull: 1 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "160px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.skuListLoading,
                                                            expression:
                                                              "skuListLoading",
                                                          },
                                                        ],
                                                        attrs: {
                                                          size: "mini",
                                                          border: "",
                                                          stripe: "",
                                                          "tooltip-effect":
                                                            "dark",
                                                          "header-cell-style": {
                                                            background:
                                                              "#eef1f6",
                                                            color: "#606266",
                                                            height: "40px",
                                                          },
                                                          data: _vm.skuList,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "indexNum",
                                                            label: "序号",
                                                            width: "55",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuCode",
                                                            label: "物料编码",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuName",
                                                            label: "物料名称",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuType",
                                                            label: "物料分类",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "skuTypeFilter"
                                                                      )(
                                                                        row.skuType
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "skuTag",
                                                            label: "物料标识",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "skuTagFilter"
                                                                      )(
                                                                        row.skuTag
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "categoryName",
                                                            label: "品类",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "modelName",
                                                            label: "型号",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "feeType",
                                                            label: "收费类型",
                                                            width: "150",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "feeTypeFilter"
                                                                      )(
                                                                        row.feeType
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "quantity",
                                                            label: "数量",
                                                            width: "160",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.quantity
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            prop: "ddirStock",
                                                            label: "DDIR库存",
                                                            width: "160",
                                                            resizable: "",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var row =
                                                                  ref.row
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.ddirStock
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("order-price", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderPriceShow,
                                      expression: "orderPriceShow",
                                    },
                                  ],
                                  ref: "checkPrice",
                                  attrs: { "order-info": _vm.orderInfo },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-collapse",
                                  {
                                    staticClass: "no-collapse-header",
                                    model: {
                                      value: _vm.calloutShow,
                                      callback: function ($$v) {
                                        _vm.calloutShow = $$v
                                      },
                                      expression: "calloutShow",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-collapse-item",
                                      { attrs: { name: "1" } },
                                      [
                                        _c("order-callout", {
                                          ref: "calloutOrder",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("检测附件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c("upload-file", {
                                                          attrs: {
                                                            disabled: true,
                                                            "attachment-list":
                                                              _vm.orderInfo
                                                                .attachmentList3,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.step,
                                  callback: function ($$v) {
                                    _vm.step = $$v
                                  },
                                  expression: "step",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "2" } },
                                  [
                                    _c("order-dispatch", {
                                      ref: "dispatchOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 120 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      attrs: { "tab-position": "right" },
                                      on: { "tab-click": _vm.stepClick },
                                      model: {
                                        value: _vm.step,
                                        callback: function ($$v) {
                                          _vm.step = $$v
                                        },
                                        expression: "step",
                                      },
                                    },
                                    [
                                      _c("el-tab-pane", {
                                        attrs: { label: "返机", name: "6" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "终验", name: "5" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "处理", name: "4" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "检测", name: "3" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "分派", name: "2" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "收货", name: "1" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-tabs el-tabs--right",
                                      staticStyle: { "padding-top": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "el-tabs__header is-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: { label: "客户" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.consumerShow === "1"
                                                      ? (_vm.consumerShow = "0")
                                                      : (_vm.consumerShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: { label: "产品" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productShow === "1"
                                                      ? (_vm.productShow = "0")
                                                      : (_vm.productShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: { label: "外呼" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.calloutShow === "1"
                                                      ? (_vm.calloutShow = "0")
                                                      : (_vm.calloutShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                visible: _vm.dialogFormVisible,
                "custom-class": "demo-drawer",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "recheckForm",
                  attrs: {
                    rules: _vm.rulesRecheck,
                    model: _vm.orderInfo,
                    "label-width": "180px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "复核拒绝原因",
                        prop: "recheckDenyReason",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: "3" },
                            model: {
                              value: _vm.orderInfo.recheckDenyReason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.orderInfo,
                                  "recheckDenyReason",
                                  $$v
                                )
                              },
                              expression: "orderInfo.recheckDenyReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.clearForm()
                        },
                      },
                    },
                    [_vm._v("\n          取消\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.recheckConfirm(false)
                        },
                      },
                    },
                    [_vm._v("\n          提交\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }