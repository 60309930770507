var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "外呼信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("分派客服履历")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-left": "160px" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          border: "",
                          stripe: "",
                          "tooltip-effect": "dark",
                          "header-cell-style": {
                            background: "#eef1f6",
                            color: "#606266",
                            height: "40px",
                          },
                          data: _vm.hisList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "indexNum",
                            label: "序号",
                            width: "55",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taskTypeName",
                            label: "指派类型",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "createUserName",
                            label: "指派人",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "createTime",
                            label: "指派时间",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taskGroupIdName",
                            label: "跟进小组",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taskRmkName",
                            label: "跟进人",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taskTempRmkName",
                            label: "临时跟进人",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taskTempTime",
                            label: "临时跟进时效",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }