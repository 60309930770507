"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _list = require("@/api/offline/list");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'offlineStoreReturnDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {},
  data: function data() {
    return {
      id: this.$route.params.id,
      offlineStoreReturnInfo: {}
    };
  },
  created: function created() {
    this.getDetail();
  },
  activated: function activated() {
    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.offlineStoreReturnInfo = response.data;
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/offline/offline_store_return'
      });
    }
  }
};
exports.default = _default;