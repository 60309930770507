"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _invoice = require("@/api/bill/invoice");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ['reload'],
  name: 'invoiceDetail',
  // eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      oldInfo: {},
      newInfo: {},
      exchangeInfo: {},
      loadingList4Model: false,
      firstUserShow: false,
      firstResonShow: false,
      lastUserShow: false,
      lastReasonShow: false,
      imgShow: false,
      imgNewShow: false,
      isShow: false,
      isShowNew: false,
      firstDisabled: false,
      lastDisabled: false,
      approveType: [{
        name: '待审核',
        val: '10'
      }, {
        name: '审核通过',
        val: '20'
      }, {
        name: '审核不通过',
        val: '30'
      }]
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }

    this.approveResult();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryExchangeById)(param).then(function (response) {
        if (response.data && response.code === 200) {
          if (response.data.length > 0) {
            var excType = response.data[0].excType;

            if (excType === '1') {
              _this.oldInfo = response.data[0];
              _this.newInfo = response.data[1];
            } else {
              _this.oldInfo = response.data[1];
              _this.newInfo = response.data[0];
            }

            if (_this.oldInfo.invoiceType === '10') {
              _this.isShow = true;
            }

            if (_this.newInfo.invoiceType === '10') {
              _this.isShowNew = true;
            }

            _this.oldInfo.custType === '10' ? _this.oldInfo.custType = '个人' : _this.oldInfo.custType = '公司';
            _this.newInfo.custType === '10' ? _this.newInfo.custType = '个人' : _this.newInfo.custType = '公司';
            _this.oldInfo.invoiceType === '20' ? _this.oldInfo.invoiceType = '电子发票' : _this.oldInfo.invoiceType = '纸质发票';
            _this.newInfo.invoiceType === '20' ? _this.newInfo.invoiceType = '电子发票' : _this.newInfo.invoiceType = '纸质发票';

            if (_this.oldInfo.invoiceType === '纸质发票') {
              if (_this.oldInfo.charterPicture !== undefined && _this.oldInfo.charterPicture !== null && _this.oldInfo.charterPicture !== '') {
                _this.imgShow = true;
                _this.oldInfo.charterPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.oldInfo.charterPicture;
                _this.oldInfo.custQualificationPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.oldInfo.custQualificationPicture;
              }
            }

            if (_this.newInfo.invoiceType === '纸质发票') {
              if (_this.newInfo.charterPicture !== undefined && _this.newInfo.charterPicture !== null && _this.newInfo.charterPicture !== '') {
                _this.imgNewShow = true;
                _this.newInfo.charterPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.newInfo.charterPicture;
                _this.newInfo.custQualificationPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.newInfo.custQualificationPicture;
              }
            }
          }
        } else {
          _this.$message.error(response.msg);
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/exbill'
      });
    },
    // 审核结果
    approveResult: function approveResult() {
      var _this2 = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryInfoById)(param).then(function (response) {
        console.info(response);

        if (response.data && response.code === 200) {
          _this2.exchangeInfo = response.data; // 审核相关按钮展示

          if (_this2.exchangeInfo.firstApproveUser !== '') {
            _this2.firstDisabled = true;
          } // 财务不能进行审批几种情况：1.客服审核结果不是通过 2.流程状态为1已经结束 3.财务已经进行过审批


          if (_this2.exchangeInfo.firstApproveResult !== '20' || _this2.exchangeInfo.processState === '1' || _this2.exchangeInfo.lastApproveUser !== '') {
            _this2.lastDisabled = true;
          } // 客服审批相关展示


          if (!(_this2.exchangeInfo.firstApproveResult === '') && _this2.exchangeInfo.firstApproveResult !== '10') {
            var firstApproveResult = _this2.exchangeInfo.firstApproveResult;
            firstApproveResult === '20' ? _this2.exchangeInfo.firstApproveResult = '审核通过' : _this2.exchangeInfo.firstApproveResult = '审核不通过';
            _this2.firstUserShow = true;

            if (!(_this2.exchangeInfo.firstApproveReason === '')) {
              _this2.firstResonShow = true;
            }
          } // 财务审批相关展示


          if (!(_this2.exchangeInfo.lastApproveResult === '') && _this2.exchangeInfo.lastApproveResult !== '10') {
            var lastApproveResult = _this2.exchangeInfo.lastApproveResult;
            lastApproveResult === '20' ? _this2.exchangeInfo.lastApproveResult = '审核通过' : _this2.exchangeInfo.lastApproveResult = '审核不通过';
            _this2.lastUserShow = true;

            if (!(_this2.exchangeInfo.lastApproveReason === '')) {
              _this2.lastReasonShow = true;
            }
          }
        } else {
          _this2.$message.error(response.msg);
        }
      });
    },
    // 客服审核通过
    approveFirstOk: function approveFirstOk() {
      var _this3 = this;

      var param = {
        id: this.id,
        flag: 'first',
        user: this.$store.state.user.loginUser.membername
      };
      (0, _invoice.approve)(param).then(function (response) {
        if (response.data && response.code === 200) {
          _this3.$message.success(response.msg);

          _this3.reload();
        } else {
          _this3.$message.error(response.msg);
        }
      });
    },
    // 客服审核不同过
    approveFirstFail: function approveFirstFail() {
      var _this4 = this;

      this.$prompt('请输入审核不通过原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '不通过原因为空无法提交'
      }).then(function (_ref) {
        var value = _ref.value;
        var param = {
          id: _this4.id,
          flag: 'first',
          reson: value,
          user: _this4.$store.state.user.loginUser.membername
        };
        (0, _invoice.approveNo)(param).then(function (response) {
          if (response.data && response.code === 200) {
            _this4.$message.success(response.msg);

            _this4.reload();
          } else {
            _this4.$message.error(response.msg);
          }
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '取消审核'
        });
      });
    },
    // 财务审核通过
    approveLastOk: function approveLastOk() {
      var _this5 = this;

      var param = {
        id: this.id,
        flag: 'last',
        user: this.$store.state.user.loginUser.membername
      };
      (0, _invoice.approve)(param).then(function (response) {
        if (response.data && response.code === 200) {
          _this5.$message.success(response.msg);

          _this5.reload();
        } else {
          _this5.$message.error(response.msg);
        }
      });
    },
    // 财务审核不同过
    approveLastFail: function approveLastFail() {
      var _this6 = this;

      this.$prompt('请输入审核不通过原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '不通过原因为空无法提交'
      }).then(function (_ref2) {
        var value = _ref2.value;
        var param = {
          id: _this6.id,
          flag: 'last',
          reson: value,
          user: _this6.$store.state.user.loginUser.membername
        };
        (0, _invoice.approveNo)(param).then(function (response) {
          if (response.data && response.code === 200) {
            _this6.$message.success(response.msg);

            _this6.reload();
          } else {
            _this6.$message.error(response.msg);
          }
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '取消审核'
        });
      });
    }
  }
};
exports.default = _default;