"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _taskType = require("@/api/basics/taskType/taskType");

var _organization = require("@/api/organization");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Sticky: _Sticky.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '1': 'success',
        '0': 'danger',
        undefined: 'info'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      taskType: {},
      listQuery: {},
      rules: {},
      typesOptions: [{
        label: '一级网点',
        value: '10'
      }, {
        label: '二级网点',
        value: '20'
      }],
      typeOptions: [{
        label: 'CC',
        value: '0'
      }, {
        label: 'RC',
        value: '1'
      }, {
        label: 'RC-CC',
        value: '2'
      }],
      organizationList: []
    };
  },
  created: function created() {
    this.getOrganizationList();

    if (!this.isCreate) {
      this.initBaseSku();
    }
  },
  methods: {
    initBaseSku: function initBaseSku() {
      var _this = this;

      (0, _taskType.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.taskType = response.data;
        }
      });
    },
    // 添加页，拿取上级机构
    getOrganizationList: function getOrganizationList() {
      var _this2 = this;

      (0, _organization.getAllOrganizationList)(this.listQuery).then(function (response) {
        _this2.organizationList = response.data;
      });
    },
    returnList: function returnList() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/taskType/list'
      });
    },
    saveSumbit: function saveSumbit() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          (0, _taskType.createTaskType)(_this3.taskType).then(function (res) {
            if (res.data) {
              _this3.$message({
                showClose: true,
                message: '保存成功',
                type: 'success'
              });

              _this3.returnList();
            } else {
              _this3.$message({
                showClose: true,
                message: res.msg,
                type: 'warning'
              });
            }

            _this3.btnLoading = false;
          });
        } else {
          _this3.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    }
  }
};
exports.default = _default;