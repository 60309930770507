var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "top-buttons-group" },
      [
        _c(
          "sticky",
          { attrs: { "class-name": "sub-navbar default" } },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.btnLoading,
                    expression: "btnLoading",
                  },
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["ROLE_CONSULT_ORDER_CANCEL"],
                    expression: "['ROLE_CONSULT_ORDER_CANCEL']",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary" },
                on: { click: _vm.cancelOrderBtnClick },
              },
              [_vm._v("服务取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.btnLoading,
                    expression: "btnLoading",
                  },
                ],
                attrs: { type: "warning" },
                on: { click: _vm.returnBtn },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          "padding-top": "20px",
          "padding-right": "20px",
          "padding-bottom": "20px",
          "padding-left": "20px",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "containerInfo",
            staticStyle: { "padding-top": "10px" },
          },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.orderInfo,
                  rules: _vm.rules,
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("基本信息")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "咨询单号",
                                        prop: "callNo",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.callNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "callNo",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.callNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "服务类型",
                                        prop: "callType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: "",
                                            filterable: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.orderInfo.callType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderInfo,
                                                "callType",
                                                $$v
                                              )
                                            },
                                            expression: "orderInfo.callType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.callTypeOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "服务子类型",
                                        prop: "callSubType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: "",
                                            filterable: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.orderInfo.callSubType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderInfo,
                                                "callSubType",
                                                $$v
                                              )
                                            },
                                            expression: "orderInfo.callSubType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.callSubTypeOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "紧急程度",
                                        prop: "urgentLevel",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: "",
                                            filterable: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.orderInfo.urgentLevel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderInfo,
                                                "urgentLevel",
                                                $$v
                                              )
                                            },
                                            expression: "orderInfo.urgentLevel",
                                          },
                                        },
                                        _vm._l(
                                          _vm.urgentLevelOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("产品信息")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "品类",
                                        prop: "categoryName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.categoryName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "categoryName",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.categoryName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "品牌",
                                        prop: "brandName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.brandName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "brandName",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.brandName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "型号",
                                        prop: "modelName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.modelName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "modelName",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.modelName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品sku",
                                        prop: "skuName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.skuName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "skuName",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.skuName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "产品SN", prop: "sn" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.sn,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.orderInfo, "sn", $$v)
                                          },
                                          expression: "orderInfo.sn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品90天权益",
                                        prop: "isEquity90days",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.isEquity90days,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "isEquity90days",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderInfo.isEquity90days",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "购买日期",
                                        prop: "salesDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: true,
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "销售日期",
                                        },
                                        model: {
                                          value: _vm.orderInfo.salesDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "salesDate",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.salesDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "注册日期",
                                        prop: "activeDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: true,
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "销售日期",
                                        },
                                        model: {
                                          value: _vm.orderInfo.activeDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "activeDate",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.activeDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.orderInfo.callType === "03"
                              ? _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "是否自营渠道",
                                            prop: "isSelfChannel",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                fill: "#000",
                                                disabled: true,
                                              },
                                              model: {
                                                value:
                                                  _vm.orderInfo.isSelfChannel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderInfo,
                                                    "isSelfChannel",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderInfo.isSelfChannel",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("是")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("否")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "购买渠道",
                                        prop: "salesChannel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.salesChannel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "salesChannel",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.salesChannel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户级别",
                                        prop: "customerLevel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.customerLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "customerLevel",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.customerLevel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("问题及建议")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户描述",
                                        prop: "userDescribe",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.userDescribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "userDescribe",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.userDescribe",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "自助服务描述",
                                        prop: "problemBefore",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.problemBefore,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "problemBefore",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.problemBefore",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "问题出现前/后",
                                        prop: "problemAfter",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.problemAfter,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "problemAfter",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.problemAfter",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Resolve方案",
                                        prop: "problemResolve",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.problemResolve,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "problemResolve",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderInfo.problemResolve",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "解决方案",
                                        prop: "solutionMemo",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.solutionMemo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "solutionMemo",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.solutionMemo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "备注",
                                        prop: "problemRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.problemRemark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "problemRemark",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.problemRemark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "关联服务单",
                                        prop: "sourceNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: true,
                                          type: "textarea",
                                          rows: 2,
                                        },
                                        model: {
                                          value: _vm.orderInfo.sourceNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "sourceNum",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.sourceNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_c("span", [_vm._v("上传附件")])])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _vm._l(_vm.uploadList, function (item, index) {
                              return index % 2 == 0
                                ? [
                                    _c(
                                      "el-col",
                                      { key: index, attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-container",
                                          [
                                            _c(
                                              "el-aside",
                                              { attrs: { width: "20%" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "img_size" },
                                                  [
                                                    item.type.indexOf(
                                                      "image"
                                                    ) !== -1
                                                      ? _c("el-image", {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            src: item.fileId,
                                                            "preview-src-list":
                                                              [item.fileId],
                                                          },
                                                        })
                                                      : _c("video", {
                                                          staticClass: "avatar",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            src: item.fileId,
                                                            controls:
                                                              "controls",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-main",
                                              [
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticStyle: {
                                                      "padding-bottom": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传人：" +
                                                                _vm._s(
                                                                  item.uploadUserName
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传时间：" +
                                                                _vm._s(
                                                                  item.uploadDateFM
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "描述：" +
                                                                _vm._s(
                                                                  item.content
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    index + 1 < _vm.uploadList.length
                                      ? _c(
                                          "el-col",
                                          {
                                            key: index + 1,
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c(
                                              "el-container",
                                              [
                                                _c(
                                                  "el-aside",
                                                  { attrs: { width: "20%" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "img_size",
                                                      },
                                                      [
                                                        _vm.uploadList[
                                                          index + 1
                                                        ].type.indexOf(
                                                          "image"
                                                        ) !== -1
                                                          ? _c("el-image", {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                src: _vm
                                                                  .uploadList[
                                                                  index + 1
                                                                ].fileId,
                                                                "preview-src-list":
                                                                  [
                                                                    _vm
                                                                      .uploadList[
                                                                      index + 1
                                                                    ].fileId,
                                                                  ],
                                                              },
                                                            })
                                                          : _c("video", {
                                                              staticClass:
                                                                "avatar",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                src: _vm
                                                                  .uploadList[
                                                                  index + 1
                                                                ].fileId,
                                                                controls:
                                                                  "controls",
                                                              },
                                                            }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-main",
                                                  [
                                                    _c(
                                                      "el-row",
                                                      {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传人：" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .uploadList[
                                                                        index +
                                                                          1
                                                                      ]
                                                                        .uploadUserName
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传时间：" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .uploadList[
                                                                        index +
                                                                          1
                                                                      ]
                                                                        .uploadDateFM
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "描述：" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .uploadList[
                                                                        index +
                                                                          1
                                                                      ].content
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e()
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("下单信息")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "派单对象",
                                        prop: "organizationName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.organizationName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "organizationName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderInfo.organizationName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "机构收货人",
                                        prop: "orgTaker",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.orgTaker,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "orgTaker",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.orgTaker",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "机构收货电话",
                                        prop: "orgTel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.orgTel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "orgTel",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.orgTel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "机构收货地址",
                                        prop: "orgDetailAddress",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.orderInfo.orgDetailAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "orgDetailAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderInfo.orgDetailAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "邮寄方式",
                                        prop: "postType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: true,
                                            filterable: "",
                                            clearable: "",
                                          },
                                          on: { change: _vm.postTypeChange },
                                          model: {
                                            value: _vm.orderInfo.postType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderInfo,
                                                "postType",
                                                $$v
                                              )
                                            },
                                            expression: "orderInfo.postType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.postTypeOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("用户信息")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "默认联系人",
                                        prop: "linkType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: true,
                                            filterable: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.orderInfo.linkType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderInfo,
                                                "linkType",
                                                $$v
                                              )
                                            },
                                            expression: "orderInfo.linkType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.linkTypeOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-collapse",
                          {
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "机主信息", name: "1" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "机主姓名",
                                                prop: "ownerName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "客户姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderInfo.ownerName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "ownerName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.ownerName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "机主电话",
                                                prop: "ownerTel",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.ownerTel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "ownerTel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.ownerTel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "报修人信息", name: "2" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "报修人姓名",
                                                prop: "claimName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "客户姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderInfo.claimName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "claimName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.claimName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "报修人电话",
                                                prop: "claimTel",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.claimTel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "claimTel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.claimTel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "寄件人信息", name: "3" } },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        label: "同步收件人信息",
                                        border: "",
                                      },
                                      on: { change: _vm.tongbu },
                                      model: {
                                        value: _vm.tongChecked,
                                        callback: function ($$v) {
                                          _vm.tongChecked = $$v
                                        },
                                        expression: "tongChecked",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件人姓名",
                                                prop: "sendName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "客户姓名",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.sendName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "sendName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.sendName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件人电话",
                                                prop: "sendTel",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.sendTel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "sendTel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.sendTel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件省份",
                                                prop: "sendProvince",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.proviceChange(
                                                        _vm.orderInfo
                                                          .sendProvince
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .sendProvince,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendProvince",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendProvince",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.provinceSelect,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件城市",
                                                prop: "sendCity",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.cityChange(
                                                        _vm.orderInfo.sendCity
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.sendCity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendCity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendCity",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.citySelect,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件区域",
                                                prop: "sendCountry",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.sendCountry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "sendCountry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.sendCountry",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.regionSelect,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "详细地址",
                                                prop: "sendAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value:
                                                    _vm.orderInfo.sendAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "sendAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.sendAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "收件人信息", name: "4" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收件人姓名",
                                                prop: "takeName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "客户姓名",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.takeName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "takeName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.takeName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收件人电话",
                                                prop: "takeTel",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "联系电话",
                                                },
                                                model: {
                                                  value: _vm.orderInfo.takeTel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "takeTel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.takeTel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收件省份",
                                                prop: "takeProvince",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.proviceChange2(
                                                        _vm.orderInfo
                                                          .takeProvince
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo
                                                        .takeProvince,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeProvince",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeProvince",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.provinceSelect,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收件城市",
                                                prop: "takeCity",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.cityChange2(
                                                        _vm.orderInfo.takeCity
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.takeCity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeCity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeCity",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.citySelect2,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收件区域",
                                                prop: "takeCountry",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled: true,
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderInfo.takeCountry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderInfo,
                                                        "takeCountry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderInfo.takeCountry",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.regionSelect2,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "详细地址",
                                                prop: "takeAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value:
                                                    _vm.orderInfo.takeAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderInfo,
                                                      "takeAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderInfo.takeAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.orderInfo.postType === "1"
                  ? _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_c("el-tag", [_vm._v("预约取件信息")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "物流公司",
                                            prop: "bookLogisticsCompany",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.orderInfo
                                                  .bookLogisticsCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderInfo,
                                                  "bookLogisticsCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderInfo.bookLogisticsCompany",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "预约取件日期",
                                            prop: "bookDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: true,
                                              "picker-options":
                                                _vm.pickerOptions,
                                              type: "date",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "预约取件日期",
                                            },
                                            on: { change: _vm.changeBookDate },
                                            model: {
                                              value: _vm.orderInfo.bookDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderInfo,
                                                  "bookDate",
                                                  $$v
                                                )
                                              },
                                              expression: "orderInfo.bookDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "预约取件时间",
                                            prop: "bookClock",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: true,
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "请选择时间段",
                                              },
                                              on: {
                                                change: _vm.changeBookClock,
                                              },
                                              model: {
                                                value: _vm.orderInfo.bookClock,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderInfo,
                                                    "bookClock",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderInfo.bookClock",
                                              },
                                            },
                                            _vm._l(
                                              _vm.bookTimePerList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "顺丰备注",
                                            prop: "expressMemo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              placeholder: "请输入内容",
                                              maxlength: "100",
                                              "show-word-limit": "",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.orderInfo.expressMemo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderInfo,
                                                  "expressMemo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderInfo.expressMemo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("取件物品")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "20px",
                          "padding-left": "70px",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        disabled: true,
                                        indeterminate: _vm.isIndeterminate,
                                        border: "",
                                      },
                                      on: { change: _vm.handleCheckAllChange },
                                      model: {
                                        value: _vm.checkAll,
                                        callback: function ($$v) {
                                          _vm.checkAll = $$v
                                        },
                                        expression: "checkAll",
                                      },
                                    },
                                    [_vm._v("全选")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticStyle: { margin: "15px 0" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: _vm.handleCheckedGoodsChange,
                                      },
                                      model: {
                                        value: _vm.checkedGoods,
                                        callback: function ($$v) {
                                          _vm.checkedGoods = $$v
                                        },
                                        expression: "checkedGoods",
                                      },
                                    },
                                    _vm._l(_vm.goods, function (city) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: city,
                                          staticStyle: { width: "20%" },
                                          attrs: {
                                            disabled: true,
                                            label: city,
                                          },
                                        },
                                        [_vm._v(_vm._s(city))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "提示",
                      visible: _vm.dialogVisible,
                      width: "30%",
                      center: "",
                      "show-close": false,
                      "close-on-click-modal": false,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 18 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "取消原因",
                                    prop: "cancelReason",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: false,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择取消原因",
                                      },
                                      model: {
                                        value: _vm.orderInfo.cancelReason,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.orderInfo,
                                            "cancelReason",
                                            $$v
                                          )
                                        },
                                        expression: "orderInfo.cancelReason",
                                      },
                                    },
                                    _vm._l(
                                      _vm.cancelOrderOptions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.val,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 18 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "取消备注",
                                    prop: "cancelMemo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea", rows: 2 },
                                    model: {
                                      value: _vm.orderInfo.cancelMemo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderInfo,
                                          "cancelMemo",
                                          $$v
                                        )
                                      },
                                      expression: "orderInfo.cancelMemo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.comfirmToOrder()
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "提示",
                      visible: _vm.dialogVisible8,
                      width: "30%",
                      center: "",
                      "show-close": false,
                      "close-on-click-modal": false,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible8 = $event
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.dialog8Msg))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.comfirmTo },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }