var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_BILL_TICKET_APPROVEONE"],
                          expression: "['ROLE_BILL_TICKET_APPROVEONE']",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: _vm.firstDisabled,
                      },
                      on: { click: _vm.approveFirstOk },
                    },
                    [_vm._v("客服审核通过\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_BILL_TICKET_APPROVEONE"],
                          expression: "['ROLE_BILL_TICKET_APPROVEONE']",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: _vm.firstDisabled,
                      },
                      on: { click: _vm.approveFirstFail },
                    },
                    [_vm._v("客服审核不通过\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_BILL_TICKET_APPROVETWO"],
                          expression: "['ROLE_BILL_TICKET_APPROVETWO']",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: _vm.lastDisabled,
                      },
                      on: { click: _vm.approveLastOk },
                    },
                    [_vm._v("财务审核通过\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_BILL_TICKET_APPROVETWO"],
                          expression: "['ROLE_BILL_TICKET_APPROVETWO']",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: _vm.lastDisabled,
                      },
                      on: { click: _vm.approveLastFail },
                    },
                    [_vm._v("财务审核不通过\n          ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: { model: _vm.oldInfo, "label-width": "150px" },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("原始开票信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票类型",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票类型",
                                            },
                                            model: {
                                              value: _vm.oldInfo.invoiceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oldInfo,
                                                  "invoiceType",
                                                  $$v
                                                )
                                              },
                                              expression: "oldInfo.invoiceType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票号",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票号",
                                            },
                                            model: {
                                              value: _vm.oldInfo.invoiceNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oldInfo,
                                                  "invoiceNum",
                                                  $$v
                                                )
                                              },
                                              expression: "oldInfo.invoiceNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票日期",
                                            prop: "billingDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "开票日期",
                                            },
                                            model: {
                                              value: _vm.oldInfo.billingDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oldInfo,
                                                  "billingDate",
                                                  $$v
                                                )
                                              },
                                              expression: "oldInfo.billingDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票对象类型",
                                            prop: "custType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "开票对象类型",
                                            },
                                            model: {
                                              value: _vm.oldInfo.custType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oldInfo,
                                                  "custType",
                                                  $$v
                                                )
                                              },
                                              expression: "oldInfo.custType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票抬头",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票抬头",
                                            },
                                            model: {
                                              value: _vm.oldInfo.custName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oldInfo,
                                                  "custName",
                                                  $$v
                                                )
                                              },
                                              expression: "oldInfo.custName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮箱地址",
                                                prop: "custEmail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "邮箱地址",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.custEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.custEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "纳税人识别号",
                                                prop: "custTax",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "纳税人识别号",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.custTax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custTax",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "oldInfo.custTax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册地址",
                                                prop: "custAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oldInfo.custAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.custAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册电话",
                                                prop: "custPhone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册电话",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.custPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.custPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "开户行信息",
                                                prop: "custBank",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "开户行信息",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.custBank,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custBank",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.custBank",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "银行账号",
                                                prop: "custBankAcc",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "银行账号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oldInfo.custBankAcc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "custBankAcc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.custBankAcc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票人",
                                                prop: "takeUser",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票人",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.takeUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "takeUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.takeUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票手机号",
                                                prop: "takeMobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票手机号",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.takeMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "takeMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.takeMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票地址",
                                                prop: "takeAddressDetail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oldInfo.takeAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "takeAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.takeAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "快递单号",
                                                prop: "courierNum",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "快递单号",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.courierNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "courierNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.courierNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄出日期",
                                                prop: "sendDate",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "寄出日期",
                                                },
                                                model: {
                                                  value: _vm.oldInfo.sendDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oldInfo,
                                                      "sendDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oldInfo.sendDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.imgShow
                              ? _c("el-row", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "grid-content bg-purple",
                                      staticStyle: { height: "135px" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "营业执照",
                                            prop: "charterPicture",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-container",
                                            [
                                              _c(
                                                "el-aside",
                                                { attrs: { width: "20%" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "img_size" },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                        attrs: {
                                                          src: _vm.oldInfo
                                                            .charterPicture,
                                                          "preview-src-list": [
                                                            _vm.oldInfo
                                                              .charterPicture,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.imgShow
                              ? _c("el-row", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "grid-content bg-purple",
                                      staticStyle: { height: "135px" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "一般纳税人资格证明",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-container",
                                            [
                                              _c(
                                                "el-aside",
                                                { attrs: { width: "20%" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "img_size" },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                        attrs: {
                                                          src: _vm.oldInfo
                                                            .custQualificationPicture,
                                                          "preview-src-list": [
                                                            _vm.oldInfo
                                                              .custQualificationPicture,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: { model: _vm.newInfo, "label-width": "150px" },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("换票信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票类型",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票类型",
                                            },
                                            model: {
                                              value: _vm.newInfo.invoiceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newInfo,
                                                  "invoiceType",
                                                  $$v
                                                )
                                              },
                                              expression: "newInfo.invoiceType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票对象类型",
                                            prop: "custType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "开票对象类型",
                                            },
                                            model: {
                                              value: _vm.newInfo.custType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newInfo,
                                                  "custType",
                                                  $$v
                                                )
                                              },
                                              expression: "newInfo.custType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票抬头",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票抬头",
                                            },
                                            model: {
                                              value: _vm.newInfo.custName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newInfo,
                                                  "custName",
                                                  $$v
                                                )
                                              },
                                              expression: "newInfo.custName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮箱地址",
                                                prop: "custEmail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "邮箱地址",
                                                },
                                                model: {
                                                  value: _vm.newInfo.custEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.custEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "纳税人识别号",
                                                prop: "custTax",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "纳税人识别号",
                                                },
                                                model: {
                                                  value: _vm.newInfo.custTax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custTax",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "newInfo.custTax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册地址",
                                                prop: "custAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.newInfo.custAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.custAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册电话",
                                                prop: "custPhone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册电话",
                                                },
                                                model: {
                                                  value: _vm.newInfo.custPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.custPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "开户行信息",
                                                prop: "custBank",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "开户行信息",
                                                },
                                                model: {
                                                  value: _vm.newInfo.custBank,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custBank",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.custBank",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "银行账号",
                                                prop: "custBankAcc",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "银行账号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.newInfo.custBankAcc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "custBankAcc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.custBankAcc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票人",
                                                prop: "takeUser",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票人",
                                                },
                                                model: {
                                                  value: _vm.newInfo.takeUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "takeUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.takeUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票手机号",
                                                prop: "takeMobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票手机号",
                                                },
                                                model: {
                                                  value: _vm.newInfo.takeMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "takeMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.takeMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票地址",
                                                prop: "takeAddressDetail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.newInfo.takeAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "takeAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.takeAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShowNew
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "快递单号",
                                                prop: "courierNum",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "快递单号",
                                                },
                                                model: {
                                                  value: _vm.newInfo.courierNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "courierNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.courierNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.newInfo.invoiceType === "10"
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄出日期",
                                                prop: "sendDate",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "寄出日期",
                                                },
                                                model: {
                                                  value: _vm.newInfo.sendDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newInfo,
                                                      "sendDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newInfo.sendDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.imgNewShow
                              ? _c("el-row", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "grid-content bg-purple",
                                      staticStyle: { height: "135px" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "营业执照",
                                            prop: "charterPicture",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-container",
                                            [
                                              _c(
                                                "el-aside",
                                                { attrs: { width: "20%" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "img_size" },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                        attrs: {
                                                          src: _vm.newInfo
                                                            .charterPicture,
                                                          "preview-src-list": [
                                                            _vm.newInfo
                                                              .charterPicture,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.imgNewShow
                              ? _c("el-row", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "grid-content bg-purple",
                                      staticStyle: { height: "135px" },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "一般纳税人资格证明",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-container",
                                            [
                                              _c(
                                                "el-aside",
                                                { attrs: { width: "20%" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "img_size" },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                        attrs: {
                                                          src: _vm.newInfo
                                                            .custQualificationPicture,
                                                          "preview-src-list": [
                                                            _vm.newInfo
                                                              .custQualificationPicture,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: { model: _vm.exchangeInfo, "label-width": "150px" },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _vm.firstUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客服审核时间",
                                        prop: "courierNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "客服审核时间",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.firstApproveDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "firstApproveDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.firstApproveDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.firstUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "审核客服",
                                        prop: "courierNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "审核客服",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.firstApproveUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "firstApproveUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.firstApproveUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.firstUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "审核结果",
                                        prop: "sendDate",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "审核结果",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.firstApproveResult,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "firstApproveResult",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.firstApproveResult",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.firstResonShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "不通过原因",
                                        prop: "sendDate",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "不通过原因",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.firstApproveReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "firstApproveReason",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.firstApproveReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _vm.lastUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "财务审核时间",
                                        prop: "courierNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "财务审核时间",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.lastApproveDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "lastApproveDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.lastApproveDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lastUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "审核财务",
                                        prop: "courierNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "审核财务",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.lastApproveUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "lastApproveUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.lastApproveUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lastUserShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "审核结果",
                                        prop: "sendDate",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "审核结果",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.lastApproveResult,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "lastApproveResult",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.lastApproveResult",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lastReasonShow
                          ? _c("el-col", { attrs: { span: 5 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "不通过原因",
                                        prop: "sendDate",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: {
                                          disabled: true,
                                          placeholder: "不通过原因",
                                        },
                                        model: {
                                          value:
                                            _vm.exchangeInfo.lastApproveReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exchangeInfo,
                                              "lastApproveReason",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exchangeInfo.lastApproveReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }