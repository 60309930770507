var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header" },
    [
      _c(
        "div",
        { staticClass: "login-container" },
        [
          _c(
            "div",
            { staticStyle: { margin: "10px", float: "right" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "el-link-stype",
                  attrs: { underline: false },
                  on: {
                    click: function ($event) {
                      return _vm.changeLoginType(_vm.loginTitle)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.loginTitle))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                "auto-complete": "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "title-container" }, [
                _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showIDaas,
                      expression: "showIDaas",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "username",
                        attrs: {
                          placeholder: "请输入账号",
                          name: "username",
                          type: "text",
                          tabindex: "1",
                          "auto-complete": "off",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleNextFocus($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "password" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "请输入密码",
                          name: "password",
                          tabindex: "2",
                          "auto-complete": "off",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "30px" },
                      attrs: { loading: _vm.loading, type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [_vm._v("登录\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showIDaas,
                      expression: "!showIDaas",
                    },
                  ],
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "idaas-button",
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleIdaasLogin($event)
                        },
                      },
                    },
                    [_vm._v("IDaaS登录\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            size: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.pwdRules,
                model: _vm.user,
                "label-width": "120px",
                autocomplete: "new-password",
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: {
                      position: "absolute",
                      left: "-10000px",
                      top: "-10000px",
                    },
                    attrs: {
                      type: "password",
                      hidden: "",
                      autocomplete: "new-password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              1 === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Id", prop: "memberId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.user.memberId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "memberId", $$v)
                          },
                          expression: "user.memberId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "当前账号", prop: "username" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: 1 === 1 },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "当前密码", prop: "currentPassword" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入当前密码",
                        },
                        model: {
                          value: _vm.user.currentPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "currentPassword", $$v)
                          },
                          expression: "user.currentPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "新密码", prop: "password" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder:
                            "密码长度8~20之间,密码是数字,大小写字母,特殊符三种及以上,特殊字符只支持 ! @ # $",
                        },
                        model: {
                          value: _vm.user.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "password", $$v)
                          },
                          expression: "user.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "确认新密码", prop: "rstPassword" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder:
                            "密码长度8~20之间,密码是数字,大小写字母,特殊符三种及以上,特殊字符只支持 ! @ # $",
                        },
                        model: {
                          value: _vm.user.rstPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "rstPassword", $$v)
                          },
                          expression: "user.rstPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePwdSubmit()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }