"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createChannel = createChannel;
exports.deleteData = deleteData;
exports.findPageList = findPageList;
exports.getChannelSelect = getChannelSelect;
exports.queryById = queryById;
exports.updateChannel = updateChannel;
exports.validateCode = validateCode;
exports.validateName = validateName;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/product_channel/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新建保存
 * @param data
 */


function createChannel(data) {
  return (0, _request.default)({
    url: '/product_app/product_channel/create',
    method: 'post',
    data: data
  });
}
/**
 * 编辑
 * @param data
 */


function updateChannel(data) {
  return (0, _request.default)({
    url: '/product_app/product_channel/update',
    method: 'post',
    data: data
  });
}
/**
 * 校验渠道id唯一性
 * @param data
 */


function validateCode(data) {
  return (0, _request.default)({
    url: '/product_app/product_channel/validateCode',
    method: 'post',
    params: {
      code: data
    }
  });
}
/**
 * 校验渠道名称唯一性
 * @param data
 */


function validateName(data) {
  return (0, _request.default)({
    url: '/product_app/product_channel/validateName',
    method: 'post',
    params: {
      name: data
    }
  });
}
/**
 * queryById
 * @param params
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/product_channel/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 删除
 */


function deleteData(ids) {
  return (0, _request.default)({
    url: '/product_app/product_channel/delete',
    method: 'post',
    params: {
      id: ids
    }
  });
}
/**
 * getChannelSelect
 * @param params
 */


function getChannelSelect() {
  return (0, _request.default)({
    url: '/product_app/product_channel/getChannelSelect',
    method: 'post'
  });
}