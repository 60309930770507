"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("@/utils");

var _SFRouteState = require("@/api/common/SFRouteState");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    num: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      stateList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadStateList4SF', function (num) {
      if (num) {
        _this.loadStateList(num);
      }
    });
  },
  methods: {
    dateFormat: function dateFormat(row, column) {
      var date = row[column.property];

      if (date === undefined || date === null) {
        return '';
      }

      return (0, _utils.parseTime)(new Date(date), '{y}-{m}-{d} {h}:{i}:{s}');
    },
    loadStateList: function loadStateList(num) {
      var _this2 = this;

      (0, _SFRouteState.queryStateByNum)(num).then(function (response) {
        if (response && response.data) {
          _this2.stateList = response.data;

          if (_this2.stateList && _this2.stateList.length > 0) {
            _this2.$emit('setSFnum', _this2.stateList[0].mailNo);
          }
        }
      });
    }
  }
};
exports.default = _default;