"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.getByUnNum = getByUnNum;
exports.getByWorkNo = getByWorkNo;
exports.getTaskTypeSelect = getTaskTypeSelect;
exports.listHistoryByOrderNo = listHistoryByOrderNo;
exports.saveTaskOrder = saveTaskOrder;
exports.updateTask = updateTask;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(data) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/getPageList',
    method: 'post',
    data: data
  });
}
/** 创建工单任务信息*/


function saveTaskOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/saveTaskOrder',
    method: 'post',
    data: data
  });
}
/** 查询详细*/


function getByWorkNo(workNo) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/getById',
    method: 'post',
    params: {
      workNo: workNo
    }
  });
}
/** 更新客服工单任务信息*/


function updateTask(data) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/updateTask',
    method: 'post',
    data: data
  });
}
/** 根据单号查询历史记录*/


function listHistoryByOrderNo(workNo) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/listHistoryByOrderNo',
    method: 'post',
    params: {
      workNo: workNo
    }
  });
}
/**
 * 本机构对应任务类型下拉
 */


function getTaskTypeSelect() {
  return (0, _request.default)({
    url: '/bus_app/taskType/orgListAll',
    method: 'get'
  });
}
/**
 * 查询未完成的工单任务个数
 */


function getByUnNum(workNo) {
  return (0, _request.default)({
    url: '/bus_app/orderTask/getByUnNum',
    method: 'get',
    params: {
      workNo: workNo
    }
  });
}