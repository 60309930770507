"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _work_task = require("@/api/work_task");

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile4"));

var _list = require("@/api/basics/baseGroup/list");

var _select = require("@/api/common/select");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 可拖拽弹出框
// 附件上传
var _default = {
  name: 'NewTask',
  components: {
    newUploadFile: _uploadFile.default
  },
  directives: {
    elDragDialog: _elDragDialog.default
  },
  props: {
    workId: {
      type: String,
      default: ''
    },
    workNo: {
      type: String,
      default: ''
    },
    workType: {
      type: String,
      default: ''
    },
    taskLink: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      // 加载动画
      listLoading: true,
      orderTask: {
        priority: '20',
        taskType: undefined,
        taskHandleRmk: undefined,
        taskHandleGroup: undefined,
        enclosure: undefined,
        completeDate: ''
      },
      // 弹出层是否显示
      dialogFormVisible: false,
      // 判断创建还是编辑
      dialogStatus: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      // 表单检查 pattern：正则 trigger：何时触发
      rules: {
        title: [{
          required: true,
          message: '工单任务标题是必填的',
          trigger: 'blur'
        }],
        describe1: [{
          required: true,
          message: '任务描述是必填的',
          trigger: 'blur'
        }],
        taskHandleGroup: [{
          required: true,
          message: '小组名称是必选的',
          trigger: 'change'
        }],
        completeDate: [{
          required: true,
          message: '期望完成时间是必填的',
          trigger: 'change'
        }],
        taskType: [{
          required: true,
          message: '任务类型是必选的',
          trigger: 'change'
        }]
      },
      // 按钮级loading
      downloadLoading: false,
      // 型号code是否可编辑
      codeDisabled: false,
      userSelect: [],
      groupSelect: [],
      taskTypeSelect: [],
      dbClickDisabled: false // 确定按钮：初始化时可点

    };
  },
  watch: {
    'orderTask.taskType': function orderTaskTaskType() {
      this.orderTask.taskHandleRmk = undefined;
      this.orderTask.taskHandleGroup = undefined;
    },
    'orderTask.taskHandleGroup': function orderTaskTaskHandleGroup() {
      this.orderTask.taskHandleRmk = undefined;
    }
  },
  created: function created() {
    // 期望时间设置默认为当前时间+3天
    var now = new Date();
    now.setDate(now.getDate() + 3);

    if (this.orderTask.completeDate === undefined || this.orderTask.completeDate === null || this.orderTask.completeDate === '') {
      this.orderTask.completeDate = (0, _utils.parseTime)(now, '{y}-{m}-{d}');
    }
  },
  // 方法集
  methods: {
    // 新建弹出
    handleCreate: function handleCreate() {
      this.dialogFormVisible = true; // 判断是否有未完成的单子
      // getByUnNum(this.workNo).then((res) => {
      //   if (res) {
      //     if (res.data === 0) {
      //       this.dialogFormVisible = true
      //     } else {
      //       this.$confirm('已存在未完成任务工单，是否查看？')
      //         .then(_ => {
      //           // todo name 待定
      //           this.$router.push({ name: 'work_task_index', params: { workNo: this.workNo }})
      //         })
      //         .catch(_ => {
      //           // this.dialogFormVisible = true
      //         })
      //     }
      //   }
      // })
    },
    getTaskTypeSelect: function getTaskTypeSelect() {
      var _this = this;

      (0, _work_task.getTaskTypeSelect)().then(function (res) {
        if (res) {
          _this.taskTypeSelect = res;
        }
      });
    },
    loadGroupSelect: function loadGroupSelect() {
      var _this2 = this;

      if (this.orderTask.taskType) {
        this.orderTask.taskHandleRmk = undefined;
        var params = {
          pageNumber: 1,
          pageSize: 9999,
          isEnable: '1'
        };
        (0, _list.findPageList)(params).then(function (response) {
          _this2.groupSelect = response.data.records;
        });
      } else {
        this.groupSelect = [];
        this.$message({
          showClose: true,
          message: '请先选择任务类型',
          type: 'warning'
        });
      }
    },
    loadUserSelect: function loadUserSelect() {
      var _this3 = this;

      if (this.orderTask.taskHandleGroup) {
        (0, _select.getMemberList)({
          groupId: this.orderTask.taskHandleGroup
        }).then(function (res) {
          _this3.userSelect = res.data;
        });
      } else {
        this.userSelect = [];
        this.$message({
          showClose: true,
          message: '请先选择小组',
          type: 'warning'
        });
      }
    },
    // 新建提交
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.dbClickDisabled = true;
          _this4.orderTask.workId = _this4.workId;
          _this4.orderTask.workNo = _this4.workNo;
          _this4.orderTask.workType = _this4.workType;
          _this4.orderTask.taskLink = _this4.taskLink;
          _this4.orderTask.taskInGroupId = _this4.$store.state.user.loginUser.groupId; // 调用api

          (0, _work_task.saveTaskOrder)(_this4.orderTask).then(function (res) {
            _this4.dbClickDisabled = false;

            if (res.data) {
              _this4.dialogFormVisible = false;
              _this4.orderTask = {
                priority: '20'
              };

              _this4.$message({
                title: '成功',
                message: '新建成功!',
                type: 'success',
                duration: 2000
              });
            } else {
              _this4.$message({
                title: '失败',
                message: res.msg,
                type: 'error',
                duration: 2000
              });
            }
          });
        }
      });
    },
    setFilePaths: function setFilePaths(data) {
      this.orderTask.enclosure = data;
    }
  }
};
exports.default = _default;