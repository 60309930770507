"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _order = require("@/api/order/order");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _handle = _interopRequireDefault(require("@/components/order/handle"));

var _recheck = _interopRequireDefault(require("@/components/order/recheck"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 处理信息
// 复核信息
// 检测信息
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 外呼信息
// 新建任务工单
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    orderHandle: _handle.default,
    orderRecheck: _recheck.default,
    orderCheck: _check.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCallout: _calloutList.default,
    NewTask: _NewTask.default
  },
  filters: {
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      step: '5',
      consumerShow: '1',
      productShow: '1',
      calloutShow: '0',
      isReadOnly: false,
      verifyResultOptions: _constant.default.verifyResultOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      rules: {
        verifyResult: [{
          required: true,
          message: '终验结果是必填的',
          trigger: 'blur'
        }],
        verifyRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      checkItemList4show: []
    };
  },
  created: function created() {
    var _this = this;

    if (this.id) {
      this.getOrderInfo();
      this.initShowcheckItemList4show();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);
      });
    }
  },
  methods: {
    setUploadFileList: function setUploadFileList(data) {
      this.orderInfo.uploadFiles = data;
    },
    setBtnsState: function setBtnsState(btnState) {
      this.btnLoading = btnState;
    },
    initShowcheckItemList4show: function initShowcheckItemList4show() {
      var _this2 = this;

      (0, _order.getCheckItems)({
        orderId: this.id
      }).then(function (response) {
        if (response.data) {
          var itemArray = response.data;
          var len = itemArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this2.checkItemList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = itemArray.slice(i * n, i * n + n);

            _this2.checkItemList4show.push(temp);
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this3 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this3.orderInfo = response.data;

          _this3.$refs.orderProduct.$emit('loadFreeSn', _this3.orderInfo.sn);
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 【处理完成】
    verifyConfirm: function verifyConfirm() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this4.verifyOrderSumbit();
          }).catch(function () {});
        } else {
          _this4.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    verifyOrderSumbit: function verifyOrderSumbit() {
      var _this5 = this;

      this.btnLoading = true;
      (0, _order.verifyOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this5.$message({
            showClose: true,
            message: '终验完了',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/order/verify/list'
          });
        } else {
          _this5.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 快捷TAG查询
    stepClick: function stepClick(tab, event) {
      this.step = tab.name;

      if (tab.name === '1') {
        this.$refs.receiptOrder.$emit('loadExpressGoods', this.id);
      } else if (tab.name === '4') {
        this.$refs.handleOrder.$emit('loadhandleInfo', this.orderInfo.id);
      } else if (tab.name === '3') {
        this.$refs.checkOrder.$emit('loadcheckInfo', {
          id: this.orderInfo.id
        });
      }
    }
  }
};
exports.default = _default;