"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _order = require("@/api/order/order");

var _order_task = require("@/api/order_task");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _utils = require("@/utils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _payment = _interopRequireDefault(require("@/components/order/payment"));

var _return = _interopRequireDefault(require("@/components/order/return"));

var _handle = _interopRequireDefault(require("@/components/order/handle"));

var _SFRouteState = _interopRequireDefault(require("@/components/order/SFRouteState"));

var _serviceConfig = require("@/api/basics/serviceConfig/serviceConfig");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 检测信息
// 收货信息
// 产品注册信息
// 支付信息
// 返机信息
// 处理信息
// 工单物流信息
var _default = {
  name: 'TaskFunctionDetail',
  components: {
    Sticky: _Sticky.default,
    orderCheck: _check.default,
    orderReceipt: _receipt.default,
    orderProduct: _product.default,
    orderPayment: _payment.default,
    orderReturn: _return.default,
    orderHandle: _handle.default,
    sfRouteState: _SFRouteState.default
  },
  filters: {
    callSignFilter: _filterUtils.callSignFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      btnDisabled: true,
      listLoading: false,
      listAll: null,
      id: this.$route.params.id,
      productShow: '0',
      receiptShow: '0',
      checkShow: '0',
      paymentShow: '0',
      handleShow: '0',
      verifyShow: '0',
      returnShow: '0',
      sfInfoShow: '0',
      callSignOptions: _constant.default.callSignOptions,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      subStateOptions: _constant.default.subStateOptions,
      stateOptions: _constant.default.stateOptions,
      orderTypeState: _constant.default.orderTypeState,
      callResultOptions: [],
      taskInfo: {
        functionType: '',
        isProhibit: '',
        priority: '',
        planStarDate1: undefined
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      rules: {
        functionType: [{
          required: true,
          message: '任务类型是必填的',
          trigger: ['change', 'blur']
        }],
        isProhibit: [{
          required: true,
          message: '是否挂起是必填的',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: '优先级是必填的',
          trigger: 'blur'
        }],
        planStarDate1: [{
          required: true,
          message: '后续计划日期是必填的',
          trigger: 'blur'
        }],
        callRemark1: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      userfeeSelect: [],
      categorySelectTree: [],
      categorySelect: [],
      modelSelect: [],
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3'
      },
      skuSelect: [],
      skuListLoading: false,
      skuList: [],
      userId: this.$store.state.user.loginUser.memberId // 当前账号所属机构ID

    };
  },
  computed: {
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      if (this.orderInfo.calloutResult === '5') {
        return this.skuList.length > 0 || this.skuList.length === 0 && (!this.skuAdd.skuCode || this.skuAdd.skuCode === '');
      } else {
        return true;
      }
    },
    // -----------------------------------------------------------------------------
    category: function category() {
      return this.skuAdd.category;
    },
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    } // -----------------------------------------------------------------------------

  },
  watch: {
    // -----------------------------------------------------------------------------
    category: function category(val, oldval) {
      this.categorySelectChange(val, oldval);
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    } // -----------------------------------------------------------------------------

  },
  created: function created() {
    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
    }

    this.init();
  },
  methods: {
    init: function init() {},
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.skuAdd.category = value;
    },
    initSelect: function initSelect() {
      var _this = this;

      (0, _select.getSkuFeeSelect)({
        isEnable: '1'
      }).then(function (res) {
        _this.userfeeSelect = res.data;
      });
      (0, _select.getCategorySelect)({
        isEnable: '1'
      }).then(function (res) {
        _this.categorySelect = res.data.records;
      });
      this.loadModelSelect();
      this.loadSkuSelect();
      this.listAllService();
    },
    // 查询所有的客服任务
    listAllService: function listAllService() {
      var _this2 = this;

      (0, _serviceConfig.listAll)().then(function (response) {
        console.log(response.data);
        _this2.listAll = response.data;
      });
    },
    loadModelSelect: function loadModelSelect() {
      var _this3 = this;

      var params = {
        categoryId: this.skuAdd.category,
        isEnable: '1'
      };
      (0, _select.getModelSelect)(params).then(function (res) {
        _this3.modelSelect = res.data;
      });
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this4 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };

      if (this.orderInfo.calloutResult === '5') {
        params.categoryId = this.skuAdd.category;
        params.modelId = this.skuAdd.model;
        params.tag = '1';
      }

      (0, _select.getSkuSelect)(params).then(function (res) {
        _this4.skuSelect = res.data;
      });
    },
    categorySelectChange: function categorySelectChange(val, oldval) {
      if (oldval !== undefined && (!val || val !== oldval)) {
        this.skuAdd.model = '';
        this.modelSelect = [];
        this.modelSelectChange();
      }
    },
    modelSelectChange: function modelSelectChange() {
      this.skuAdd.skuCode = '';
      this.skuSelect = [];
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this5 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this5.orderInfo = response.data;

          _this5.$refs.orderProduct.$emit('loadFreeSn', _this5.orderInfo.sn);

          _this5.$refs.receiptOrder.$emit('loadExpressGoods', _this5.orderInfo.id);

          _this5.$refs.checkOrder.$emit('loadcheckInfo', {
            id: _this5.orderInfo.id
          });

          _this5.$refs.handleOrder.$emit('loadhandleInfo', _this5.orderInfo.id);

          _this5.$refs.returnOrder.$emit('loadExpressGoods', _this5.orderInfo.id);

          if (_this5.$refs.sfInfoForm) {
            _this5.$refs.sfInfoForm.$emit('loadSFInfo', _this5.orderInfo.orderNo);
          }

          if (_this5.$refs.sfInfoTo) {
            _this5.$refs.sfInfoTo.$emit('loadSFInfo', _this5.orderInfo.orderNoc);
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 【确认】
    callConfirm: function callConfirm() {
      var _this6 = this;

      this.$refs['dataForm1'].validate(function (valid) {
        if (valid) {
          _this6.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this6.callConfirmSumbit();
          }).catch(function () {});
        } else {
          _this6.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    callConfirmSumbit: function callConfirmSumbit() {
      var _this7 = this;

      this.taskInfo.planStarDate = (0, _utils.parseTime)(this.taskInfo.planStarDate1, '{y}-{m}-{d} {h}:{i}:{s}');
      this.btnLoading = true; // this.orderInfo.orderSkuList = this.skuList

      this.taskInfo.orderId = this.orderInfo.id;
      console.log(this.taskInfo);
      (0, _order_task.addCallout)(this.taskInfo).then(function (response) {
        if (response.data) {
          _this7.$message({
            showClose: true,
            message: '确认完了',
            type: 'success'
          });

          _this7.$store.dispatch('tagsView/delView', _this7.$route);

          _this7.$router.push({
            name: 'TaskFunction'
          });
        } else {
          _this7.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    }
  }
};
exports.default = _default;