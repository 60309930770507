"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/web.dom.iterable");

var _list = require("@/api/consult/list");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect2/treeSelect"));

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 可拖拽弹出框
// 下拉框 树形结构 可检索组件
var _default = {
  // name: 'modifyOrderDetail',  eslint-disable-line
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default
  },
  components: {
    Sticky: _Sticky.default,
    treeSelect: _treeSelect.default
  },
  filters: {},
  data: function data() {
    return {
      dialogVisible: false,
      // 弹出框
      dialogMsg: '',
      // 弹出框 显示信息
      dialogType: '1',
      // 弹出框类型(1->确定生成服务工单；2->确定取消该页面,更新咨询工单状态，跳转咨询工单列表)
      uploadList: [],
      // 上传文件列表
      id: this.$route.params.id,
      btnLoading: false,
      callTypeOptions: [// 服务类型
      {
        'val': '01',
        'name': '维修'
      }, {
        'val': '02',
        'name': '换货'
      }, {
        'val': '03',
        'name': '检测'
      }, {
        'val': '04',
        'name': '销售'
      }, {
        'val': '05',
        'name': '退货'
      }],
      callSubTypeOptions: [],
      callSubTypeOptions1: [// 服务类型 维修 对应 子类型
      {
        'val': '0101',
        'name': '保内'
      }, {
        'val': '0102',
        'name': '保外'
      }
      /* { 'val': '0103', 'name': '海外' } // 先不开放 */
      ],
      callSubTypeOptions2: [// 服务类型 换货 对应 子类型
      {
        'val': '0201',
        'name': '三包换机'
      }, {
        'val': '0202',
        'name': '多维换新'
      }],
      callSubTypeOptions3: [// 服务类型：检测 对应子类型
      {
        'val': '0301',
        'name': '仅检测'
      }],
      postTypeOptions: [{
        'val': '1',
        'name': '顺丰上门'
      }, {
        'val': '2',
        'name': '用户自寄'
      }],
      urgentLevelOptions: [{
        'val': '1',
        'name': '一般'
      }, {
        'val': '2',
        'name': '紧急'
      }],
      activeNames: ['1', '2', '3', '4'],
      // 用户信息默认展开
      linkTypeOptions: [{
        'val': '1',
        'name': '机主'
      }, {
        'val': '2',
        'name': '报修人'
      }, {
        'val': '3',
        'name': '寄件人'
      }, {
        'val': '4',
        'name': '收件人'
      }],
      // 默认联系人
      linkTypeOptions2: [{
        'val': '1',
        'name': '机主'
      }, {
        'val': '2',
        'name': '报修人'
      }, {
        'val': '3',
        'name': '寄件人'
      }, {
        'val': '4',
        'name': '收件人'
      }],
      // 默认联系人
      linkTypeOptions3: [{
        'val': '1',
        'name': '机主'
      }, {
        'val': '2',
        'name': '报修人'
      }, {
        'val': '4',
        'name': '收件人'
      }],
      // 默认联系人
      // -----------------------------------------------------------------------------------------------
      categorySelect: [],
      // <品类> 下拉
      brandSelect: [],
      // <品牌> 下拉
      modelSelect: [],
      // <型号> 下拉
      provinceSelect: [],
      // 省下拉
      citySelect: [],
      // 市下拉
      citySelect2: [],
      regionSelect: [],
      // 区下拉
      regionSelect2: [],
      bookTimePerList: [{
        'val': '08:00~09:59',
        'name': '08:00~09:59'
      }, {
        'val': '10:00~11:59',
        'name': '10:00~11:59'
      }, {
        'val': '12:00~13:59',
        'name': '12:00~13:59'
      }, {
        'val': '14:00~15:59',
        'name': '14:00~15:59'
      }, {
        'val': '16:00~17:59',
        'name': '16:00~17:59'
      }],
      // <上门时间段>下拉subStateFormat
      // -------------------------------------------------------------------------------------------------
      // 工单信息
      orderInfo: {
        id: '',
        callId: '',
        beforeMemo: ''
      },
      jdInfo: {},
      rules: {
        // <<<<<<<<< 基本信息 >>>>>>>>>>>>>>>>>>>>>
        urgentLevel: [{
          required: true,
          message: '紧急程度是必选的',
          trigger: 'blur'
        }],
        isSelfChannel: [{
          required: true,
          message: '是否自营渠道是必选的',
          trigger: 'blur'
        }],
        // <<<<<<<< 基本信息 >>>>>>>>>>>>>>>>>>>>>>>>
        // <<<<<<<< 下单信息 >>>>>>>>>>>>>>>>>>>>>>>>
        organizationName: [{
          required: true,
          message: '派单对象是必选的',
          trigger: 'blur'
        }],
        orgTaker: [{
          required: true,
          message: '机构收货人是必填的',
          trigger: 'blur'
        }],
        orgTel: [{
          required: true,
          message: '机构收货电话是必填的',
          trigger: 'blur'
        }],
        orgDetailAddress: [{
          required: true,
          message: '机构收货详细地址是必填的',
          trigger: 'blur'
        }],
        postType: [{
          required: true,
          message: '邮寄方式是必选的',
          trigger: 'blur'
        }],
        bookLogisticsCompany: [{
          required: true,
          message: '预约物流公司是必填的',
          trigger: 'blur'
        }],
        bookDate: [{
          required: true,
          message: '预约取件日期是必填的',
          trigger: 'blur'
        }],
        bookClock: [{
          required: true,
          message: '预约取件时间是必填的',
          trigger: 'blur'
        }],
        // <<<<<<<<<< 下单信息 >>>>>>>>>>>>>>>>>>>>>
        // <<<<<<<<<< 用户信息 >>>>>>>>>>>>>>>>>>>>>
        linkType: [{
          required: true,
          message: '默认联系人是必选的',
          trigger: 'blur'
        }],
        // 机主信息
        ownerName: [{
          required: true,
          message: '机主姓名是必填的',
          trigger: 'blur'
        }],
        ownerTel: [{
          required: true,
          message: '机主电话是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        // 报修人信息
        claimName: [{
          required: true,
          message: '报修人姓名是必填的',
          trigger: 'blur'
        }],
        claimTel: [{
          required: true,
          message: '报修人电话是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        // 寄件人信息
        sendName: [{
          required: true,
          message: '寄件人姓名是必填的',
          trigger: 'blur'
        }],
        sendTel: [{
          required: true,
          message: '寄件人电话是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        sendProvince: [{
          required: true,
          message: '寄件省份是必选的',
          trigger: 'blur'
        }],
        sendCity: [{
          required: true,
          message: '寄件城市是必选的',
          trigger: 'blur'
        }],
        sendCountry: [{
          required: true,
          message: '寄件区域是必选的',
          trigger: 'blur'
        }],
        sendAddress: [{
          required: true,
          message: '寄件详细地址是必填的',
          trigger: 'blur'
        }],
        // 收件人信息
        takeName: [{
          required: true,
          message: '收件人姓名必填的',
          trigger: 'blur'
        }],
        takeTel: [{
          required: true,
          message: '收件人电话是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        takeProvince: [{
          required: true,
          message: '收件省份是必选的',
          trigger: 'blur'
        }],
        takeCity: [{
          required: true,
          message: '收件城市是必填选',
          trigger: 'blur'
        }],
        takeCountry: [{
          required: true,
          message: '收件区域是必选的',
          trigger: 'blur'
        }],
        takeAddress: [{
          required: true,
          message: '收件详细地址是必填的',
          trigger: 'blur'
        }],
        // <<<<<<<<<< 用户信息 >>>>>>>>>>>>>>>>>>>>>
        category: [{
          required: true,
          message: '品类是必选的',
          trigger: 'blur'
        }],
        brand: [{
          required: true,
          message: '品牌是必选的',
          trigger: 'blur'
        }],
        warrantyNote: [{
          max: 100,
          message: '入保日期备注最多100字符'
        }]
      },
      tongChecked: false,
      // 同步收件人信息
      tableData1: [],
      // 品类树状数据
      cTree: true,
      accordion: true,
      // 顺丰上门取件时间，只能选今天或者未来某天
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      checkAll: false,
      checkedGoods: [],
      goods: [],
      isIndeterminate: false,
      countryOptions: _constant.default.countryOptions,
      showJDInfo: false,
      refDateBefore: '',
      snRiskId: '',
      ownerTelRiskId: '',
      claimTelRiskId: '',
      sendTelRiskId: '',
      sendAddressRiskId: '',
      takeTelRiskId: '',
      takeAddressRiskId: '',
      orderCoupon: {},
      userfeeSelect: [] // -----------------------------------------------------------------------------------

    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    },
    productType: function productType() {
      return this.orderInfo.choosedProductType === '2' ? '仅登记' : '注册';
    },
    'orderCoupon.effectiveDateRange': function orderCouponEffectiveDateRange() {
      return this.orderCouopn.effectiveStartDate + '~' + this.orderCouopn.effectiveEndDate;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    },
    'orderInfo.claimTel': function orderInfoClaimTel(val) {
      if (!val || val.length !== 11) {
        return;
      }

      this.queryRisk(3);
    },
    'orderInfo.sendTel': function orderInfoSendTel(val) {
      if (!val || val.length !== 11) {
        return;
      }

      this.queryRisk(4);
    },
    'orderInfo.sendAddress': function orderInfoSendAddress(val) {
      if (!val) {
        return;
      }

      this.queryRisk(5);
    },
    'orderInfo.takeTel': function orderInfoTakeTel(val) {
      if (!val || val.length !== 11) {
        return;
      }

      this.queryRisk(6);
    },
    'orderInfo.takeAddress': function orderInfoTakeAddress(val) {
      if (!val) {
        return;
      }

      this.queryRisk(7);
    }
  },
  created: function created() {
    // 加载省份列表
    this.getProvinceOptions();
    this.getDetail();
    this.initSelect();
  },
  methods: {
    // 根据咨询工单id生成对应服务工单的信息
    getDetail: function getDetail() {
      var _this = this;

      this.btnLoading = false;
      (0, _list.createOrderById)(this.id).then(function (response) {
        _this.btnLoading = false;

        if (response.data) {
          // 工单主信息
          _this.orderInfo = response.data; // 服务类型 维修   对应子类型

          if (_this.orderInfo.callType === '01') {
            _this.callSubTypeOptions = _this.callSubTypeOptions1;
          } // 服务类型 换货   对应子类型


          if (_this.orderInfo.callType === '02') {
            _this.callSubTypeOptions = _this.callSubTypeOptions2;
          } // 服务类型 检测   对应子类型


          if (_this.orderInfo.callType === '03') {
            _this.callSubTypeOptions = _this.callSubTypeOptions3;
          } // 上传文件信息


          if (response.data.uploadList) {
            _this.uploadList = [];
            response.data.uploadList.forEach(function (item, index) {
              if (item.flag === '0') {
                item.fileId = process.env.VUE_APP_S3_STORAGE_URL + item.fileId;
              }
            });
            _this.uploadList = response.data.uploadList;
          }

          if (_this.orderInfo.sku === '' || _this.orderInfo.sku === null) {
            _this.getSelect();
          } else {
            _this.checkedGoods = [];
            _this.goods = _this.orderInfo.goods;
          }

          if (_this.orderInfo.callSubState === '10' && _this.orderInfo.checkedGoods) {
            _this.checkedGoods = _this.orderInfo.checkedGoods;
          }

          if (_this.orderInfo.category) {
            (0, _list.getOrgByCaId)(_this.orderInfo.category, _this.orderInfo.sendCity).then(function (res) {
              _this.goods = res.data.goods;
            });
          } // 有京东单号 显示京东回寄信息


          if (_this.orderInfo.jdOrderNo) {
            _this.showJDInfo = true;
            (0, _list.queryJDSendInfo)(_this.orderInfo.jdOrderNo).then(function (res) {
              if (res && res.code === 200) {
                _this.jdInfo.userProvince = res.data.userProvince;
                _this.jdInfo.userCity = res.data.userCity;
                _this.jdInfo.userCounty = res.data.userCounty;
                _this.jdInfo.userTown = res.data.userTown;
                _this.jdInfo.userAddress = res.data.userAddress;
                _this.jdInfo.wishBookDate = res.data.wishBookDate;
                _this.jdInfo.userAreaId = res.data.userAreaId;
                _this.jdInfo.fullAddress = res.data.userProvince + res.data.userCity + res.data.userCounty + res.data.userTown;
              }
            });
          }

          _this.getCityOption();

          _this.refDateBefore = _this.orderInfo.refDate;
          _this.orderInfo.refDateBefore = _this.refDateBefore;
          _this.orderInfo.warrantyNote = '';

          if (_this.orderInfo.sn) {
            _this.queryRisk(1);
          }

          if (_this.orderInfo.ownerTel) {
            _this.queryRisk(2);
          }

          if (_this.orderInfo.claimTel) {
            _this.queryRisk(3);
          } // 显示优惠券信息


          if (_this.orderInfo.couponInfo) {
            _this.orderCoupon = _this.orderInfo.couponInfo;
          }
        }
      });
    },
    // 默认联系人
    handleChange: function handleChange(val) {
      this.activeNames = ['1', '2', '3', '4']; // 用户信息默认展开
    },
    // 邮寄方式为顺丰上门，物流公司标记为顺丰
    postTypeChange: function postTypeChange() {
      var _this2 = this;

      // 顺丰上门
      this.orderInfo.linkType = '';

      if (this.orderInfo.postType === '1') {
        this.orderInfo.bookLogisticsCompany = '顺丰';
        this.linkTypeOptions = this.linkTypeOptions2;
      } // 自寄 清空 顺丰上门填写的信息


      if (this.orderInfo.postType === '2') {
        this.orderInfo.bookLogisticsCompany = '';
        this.orderInfo.bookDate = '';
        this.orderInfo.bookClock = '';
        this.linkTypeOptions = this.linkTypeOptions3;
        this.$nextTick(function () {
          _this2.$refs['dataForm'].clearValidate();
        });
      }
    },
    // 新建服务工单
    saveOrderBtnClick: function saveOrderBtnClick() {
      var _this3 = this;

      if (this.refDateBefore !== this.orderInfo.refDate) {
        if (this.orderInfo.warrantyNote === '' || this.orderInfo.warrantyNote === undefined || this.orderInfo.warrantyNote === null) {
          this.$message({
            showClose: true,
            message: '入保日期已改变，请填写入保日期备注！',
            type: 'warning'
          });
          return;
        }
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // 调接口生成工单
          _this3.btnLoading = true; // 默认联系人 根据页面选择 对应联系人

          _this3.orderInfo.callId = _this3.id;
          _this3.orderInfo.goods = _this3.goods;
          _this3.orderInfo.checkedGoods = _this3.checkedGoods;
          _this3.orderInfo.warrantyNote = (0, _utils.HTMLEncode)(_this3.orderInfo.warrantyNote);
          (0, _list.saveOrder)(_this3.orderInfo).then(function (response) {
            if (response !== null && response.code === 200) {
              _this3.dialogMsg = '服务工单 [' + response.data + '] 已经生成!';
              _this3.btnLoading = false;
              _this3.dialogType = '1';
              _this3.dialogVisible = true;
            } else {
              _this3.btnLoading = false;

              _this3.$message({
                showClose: true,
                message: response.msg || '操作失败！',
                type: 'warning'
              });
            }
          });
        } else {
          _this3.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 弹出框确认
    comfirmToOrder: function comfirmToOrder(type) {
      var _this4 = this;

      this.btnLoading = false; // 已生成服务工单，确认直接跳转咨询工单列表

      if (type === '1') {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/consult/consult_order'
        });
      } // 取消 的确认，更新咨询工单状态：主状态：已结束，子状态，仅咨询


      if (type === '2') {
        (0, _list.cancelConfirm)(this.id).then(function (response) {
          console.log(response.data);

          if (response.data) {
            _this4.$store.dispatch('tagsView/delView', _this4.$route);

            _this4.$router.push({
              path: '/consult/consult_order'
            });
          } else {
            _this4.btnLoading = false;

            _this4.$message({
              showClose: true,
              message: response.msg || '操作失败！',
              type: 'warning'
            });
          }
        });
      }
    },
    // 取消新建
    cancelBtnClick: function cancelBtnClick() {
      this.dialogMsg = '请确认是否继续”取消“该页面编辑内容 ';
      this.dialogType = '2';
      this.dialogVisible = true;
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this5 = this;

      // 品类I下拉
      (0, _select.getCategorySelect)().then(function (res) {
        _this5.categorySelect = res.data.records;
      }); // 品牌下拉

      (0, _select.getBrandSelect)().then(function (res) {
        _this5.brandSelect = res.data.records;
        console.log(_this5.brandSelect);
        console.log(_this5.brandSelect[0]);
        console.log(_this5.brandSelect[0].id);

        if (_this5.orderInfo.brand === null || _this5.orderInfo.brand === '') {
          _this5.orderInfo.brand = _this5.brandSelect[0].id;
        }
      });
    },
    // 切换品类--》更新派单信息
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      var _this6 = this;

      this.orderInfo.category = value;

      if (value !== null) {
        (0, _list.getOrgByCaId)(value, this.orderInfo.sendCity).then(function (res) {
          if (res != null && res.code === 200) {
            _this6.orderInfo.organization = res.data.organization;
            _this6.orderInfo.organizationName = res.data.organizationName;
            _this6.orderInfo.orgTaker = res.data.orgTaker;
            _this6.orderInfo.orgTel = res.data.orgTel;
            _this6.orderInfo.orgProvince = res.data.orgProvince;
            _this6.orderInfo.orgCity = res.data.orgCity;
            _this6.orderInfo.orgCountry = res.data.orgCountry;
            _this6.orderInfo.orgAddress = res.data.orgAddress;
            _this6.orderInfo.orgDetailAddress = res.data.orgDetailAddress;
            _this6.checkedGoods = [];
            _this6.goods = res.data.goods;

            _this6.$forceUpdate();

            _this6.$nextTick(function () {
              _this6.$refs['dataForm'].clearValidate();
            });
          } else {
            _this6.orderInfo.organization = '';
            _this6.orderInfo.organizationName = '';
            _this6.orderInfo.orgTaker = '';
            _this6.orderInfo.orgTel = '';
            _this6.orderInfo.orgProvince = '';
            _this6.orderInfo.orgCity = '';
            _this6.orderInfo.orgCountry = '';
            _this6.orderInfo.orgAddress = '';
            _this6.orderInfo.orgDetailAddress = '';
            _this6.checkedGoods = [];
            _this6.goods = res.data.goods;

            _this6.$forceUpdate();

            _this6.$message({
              showClose: true,
              message: res.msg || '品类必须选择到二级品类或三级！',
              type: 'warning'
            });
          }
        });
      }
    },
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this7 = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this7.provinceSelect = res.data;
      });
    },
    // 寄件人 城市
    proviceChange: function proviceChange(province) {
      var _this8 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this8.citySelect = response.data;
        });
      }

      this.orderInfo.sendCity = '';
      this.orderInfo.sendCountry = '';
    },
    // 寄件人区域
    cityChange: function cityChange(city) {
      var _this9 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this9.regionSelect = response.data;
        });
        (0, _list.getCityLastTime)(city).then(function (response) {
          if (response !== null && response.code === 200) {
            _this9.bookTimePerList = [];
            _this9.bookTimePerList = response.data;
          }
        }); // 城市变更后，获取派单信息

        (0, _list.getOrgByCaId)(this.orderInfo.category, this.orderInfo.sendCity).then(function (res) {
          if (res && res.code === 200) {
            _this9.orderInfo.organization = res.data.organization;
            _this9.orderInfo.organizationName = res.data.organizationName;
            _this9.orderInfo.orgTaker = res.data.orgTaker;
            _this9.orderInfo.orgTel = res.data.orgTel;
            _this9.orderInfo.orgProvince = res.data.orgProvince;
            _this9.orderInfo.orgCity = res.data.orgCity;
            _this9.orderInfo.orgCountry = res.data.orgCountry;
            _this9.orderInfo.orgAddress = res.data.orgAddress;
            _this9.orderInfo.orgDetailAddress = res.data.orgDetailAddress;
          } else {
            _this9.orderInfo.organization = '';
            _this9.orderInfo.organizationName = '';
            _this9.orderInfo.orgTaker = '';
            _this9.orderInfo.orgTel = '';
            _this9.orderInfo.orgProvince = '';
            _this9.orderInfo.orgCity = '';
            _this9.orderInfo.orgCountry = '';
            _this9.orderInfo.orgAddress = '';
            _this9.orderInfo.orgDetailAddress = '';

            _this9.$message.warning(res.msg);
          }
        });
      }

      this.orderInfo.sendCountry = '';
    },
    // 收件人 省份
    proviceChange2: function proviceChange2(province2) {
      var _this10 = this;

      if (province2) {
        (0, _select.getAreasByParentId)(province2).then(function (response) {
          _this10.citySelect2 = response.data;
        });
      }

      this.orderInfo.takeCity = '';
      this.orderInfo.takeCountry = '';
    },
    // 收件人 城市
    cityChange2: function cityChange2(city2) {
      var _this11 = this;

      if (city2) {
        (0, _select.getAreasByParentId)(city2).then(function (response) {
          _this11.regionSelect2 = response.data;
        });
      }

      this.orderInfo.takeCountry = '';
    },
    // 选顺丰上门取件时间
    changeBookDate: function changeBookDate() {
      this.orderInfo.bookClock = '';
    },
    // 选时间段判断，是否是有效顺丰上门取件时间
    changeBookClock: function changeBookClock() {
      var _this12 = this;

      var param = {
        bookDate: this.orderInfo.bookDate,
        bookClock: this.orderInfo.bookClock
      };
      (0, _list.validateBookClock)(param).then(function (res) {
        if (res.data) {
          _this12.$nextTick(function () {
            _this12.$refs['dataForm'].clearValidate();
          });
        } else {
          _this12.$message({
            showClose: true,
            message: res.msg || '请选择你方便的时间段！',
            type: 'warning'
          });
        }
      });
    },
    // 同步收件人信息
    tongbu: function tongbu() {
      if (this.tongChecked) {
        this.proviceChange2(this.orderInfo.sendProvince);
        this.cityChange2(this.orderInfo.sendCity);
        this.orderInfo.takeName = this.orderInfo.sendName;
        this.orderInfo.takeTel = this.orderInfo.sendTel;
        this.orderInfo.takeProvince = this.orderInfo.sendProvince;
        this.orderInfo.takeCity = this.orderInfo.sendCity;
        this.orderInfo.takeCountry = this.orderInfo.sendCountry;
        this.orderInfo.takeAddress = this.orderInfo.sendAddress;
      }
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'consultOrderDetail',
        params: {
          id: this.id
        }
      });
    },
    // 取件物品全选操作
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedGoods = val ? this.goods : [];
      this.isIndeterminate = false;
    },
    // 取件物品 单个操作
    handleCheckedGoodsChange: function handleCheckedGoodsChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.goods.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.goods.length;
    },
    // 新建服务工单
    saveOrderTempBtnClick: function saveOrderTempBtnClick() {
      var _this13 = this;

      // 调接口生成工单
      this.btnLoading = true; // 默认联系人 根据页面选择 对应联系人

      this.orderInfo.callId = this.id;
      this.orderInfo.goods = this.goods;
      this.orderInfo.checkedGoods = this.checkedGoods;
      (0, _list.saveOrderTemp)(this.orderInfo).then(function (response) {
        if (response.code === 200) {
          _this13.$message.success('操作成功！');

          _this13.btnLoading = false;

          _this13.$store.dispatch('tagsView/delView', _this13.$route);

          _this13.$router.push({
            path: '/consult/consult_order'
          });
        } else {
          _this13.btnLoading = false;

          _this13.$message({
            showClose: true,
            message: response.msg || '操作失败！',
            type: 'warning'
          });
        }
      });
    },
    getCityOption: function getCityOption() {
      var _this14 = this;

      (0, _select.getAreasByParentId)(this.orderInfo.sendProvince).then(function (response) {
        _this14.citySelect = response.data;
      });
      (0, _select.getAreasByParentId)(this.orderInfo.sendCity).then(function (response) {
        _this14.regionSelect = response.data;
      });
      (0, _select.getAreasByParentId)(this.orderInfo.takeProvince).then(function (response) {
        _this14.citySelect2 = response.data;
      });
      (0, _select.getAreasByParentId)(this.orderInfo.takeCity).then(function (response) {
        _this14.regionSelect2 = response.data;
      });
    },
    refDateChange: function refDateChange() {
      var oldDays = (0, _utils.convertStringToDate)(this.refDateBefore);
      var nowDays = new Date(this.orderInfo.refDate);
      var iDays = (0, _utils.computeDays2)(oldDays, nowDays);
      this.orderInfo.benefitEndDate = this.getDay(iDays);
      this.refDateBefore = (0, _utils.parseTime)(this.orderInfo.refDate, '{y}-{m}-{d}');
      this.orderInfo.refDateBefore = this.refDateBefore;
    },
    getDay: function getDay(day) {
      var today = new Date(this.orderInfo.benefitEndDate);
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + '-' + tMonth + '-' + tDate;
    },
    doHandleMonth: function doHandleMonth(month) {
      var m = month;

      if (month.toString().length === 1) {
        m = '0' + month;
      }

      return m;
    },
    queryRisk: function queryRisk(type) {
      var _this15 = this;

      if (type === 1) {
        if (!this.orderInfo.sn || this.orderInfo.sn.length !== 15) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.sn, '30', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.snRiskId = res.data;
            } else {
              _this15.snRiskId = '';
            }
          } else {
            _this15.snRiskId = '';
          }
        });
      } else if (type === 2) {
        if (!this.orderInfo.ownerTel || this.orderInfo.ownerTel.length !== 11) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.ownerTel, '10', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.ownerTelRiskId = res.data;
            } else {
              _this15.ownerTelRiskId = '';
            }
          } else {
            _this15.ownerTelRiskId = '';
          }
        });
      } else if (type === 3) {
        if (!this.orderInfo.claimTel || this.orderInfo.claimTel.length !== 11) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.claimTel, '20', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.claimTelRiskId = res.data;
            } else {
              _this15.claimTelRiskId = '';
            }
          } else {
            _this15.claimTelRiskId = '';
          }
        });
      } else if (type === 4) {
        if (!this.orderInfo.sendTel || this.orderInfo.sendTel.length !== 11) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.sendTel, '20', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.sendTelRiskId = res.data;
            } else {
              _this15.sendTelRiskId = '';
            }
          } else {
            _this15.sendTelRiskId = '';
          }
        });
      } else if (type === 5) {
        if (!this.orderInfo.sendAddress) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.sendAddress, '40', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.sendAddressRiskId = res.data;
            } else {
              _this15.sendAddressRiskId = '';
            }
          } else {
            _this15.sendAddressRiskId = '';
          }
        });
      } else if (type === 6) {
        if (!this.orderInfo.takeTel || this.orderInfo.takeTel.length !== 11) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.takeTel, '20', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.takeTelRiskId = res.data;
            } else {
              _this15.takeTelRiskId = '';
            }
          } else {
            _this15.takeTelRiskId = '';
          }
        });
      } else if (type === 7) {
        if (!this.orderInfo.takeAddress) {
          return;
        }

        (0, _list.queryRisk)(this.orderInfo.takeAddress, '40', this.orderInfo.callNo).then(function (res) {
          if (res && res.code === 200) {
            if (res.data) {
              _this15.takeAddressRiskId = res.data;
            } else {
              _this15.takeAddressRiskId = '';
            }
          } else {
            _this15.takeAddressRiskId = '';
          }
        });
      }
    },
    toRiskDetail: function toRiskDetail(id) {
      // this.$store.dispatch('tagsView/delView', this.$route)
      // this.$router.go(-1) // 返回上一层
      // this.$router.push({ name: 'riskDetail', params: { type: '10', riskName: this.consultOrderInfo.applicantTel }})
      this.$router.push({
        name: 'createRisk',
        params: {
          id: id
        }
      });
    },
    initSelect: function initSelect() {
      var _this16 = this;

      var params = {
        isEnable: undefined
      };
      (0, _select.getSkuFeeSelect)(params).then(function (res) {
        _this16.userfeeSelect = res.data;
      });
    }
  }
};
exports.default = _default;