var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  "会员权益延保" + _vm._s(!_vm.isCreate ? "详细" : "创建")
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isCreate
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createData()
                    },
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.route()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.validateForm,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动时间段", prop: "configDate" } },
                        [
                          _vm.isCreate
                            ? _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.configDate,
                                  callback: function ($$v) {
                                    _vm.configDate = $$v
                                  },
                                  expression: "configDate",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isCreate
                            ? _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.validateForm.configDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.validateForm,
                                      "configDate",
                                      $$v
                                    )
                                  },
                                  expression: "validateForm.configDate",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "用户群体", prop: "repurchaseFlag" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: !_vm.isCreate,
                              },
                              model: {
                                value: _vm.validateForm.repurchaseFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.validateForm,
                                    "repurchaseFlag",
                                    $$v
                                  )
                                },
                                expression: "validateForm.repurchaseFlag",
                              },
                            },
                            _vm._l(_vm.customerOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道", prop: "extendSource" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: !_vm.isCreate,
                              },
                              model: {
                                value: _vm.validateForm.extendSource,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.validateForm,
                                    "extendSource",
                                    $$v
                                  )
                                },
                                expression: "validateForm.extendSource",
                              },
                            },
                            _vm._l(_vm.sourceOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "延保时长", prop: "extendDate" },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: !_vm.isCreate },
                              model: {
                                value: _vm.validateForm.extendDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "extendDate", $$v)
                                },
                                expression: "validateForm.extendDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "SKU", prop: "sku" },
                          },
                          [
                            _vm.isCreate
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "medium", type: "primary" },
                                    on: { click: _vm.addSkuSwitch },
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCreate
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "medium",
                                      type: "primary",
                                      disabled: _vm.removeBtnDisabled,
                                    },
                                    on: { click: _vm.deSkuClick },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.skuListValidate,
                                    expression: "skuListValidate",
                                  },
                                ],
                                staticClass: "modelList__error",
                              },
                              [_vm._v(" 请添加SKU ！")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "160px" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "40px",
                              },
                              data: _vm.listSku,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.rowClickSelection,
                            },
                          },
                          [
                            _vm.isCreate
                              ? _c("el-table-column", {
                                  attrs: {
                                    type: "selection",
                                    width: "55",
                                    align: "center",
                                    prop: "id",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "code", label: "物料编码" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "SKU名称",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "prefix",
                                label: "Prefix",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建人", prop: "inRmkName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inRmk,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.validateForm, "inRmk", $$v)
                                    },
                                    expression: "validateForm.inRmk",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "inDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.inDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加SKU", visible: _vm.dialogSkuSelVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSkuSelVisible = $event
            },
            open: _vm.dialog4modelSelOpen,
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c("el-row", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.skuSelValidate,
                        expression: "skuSelValidate",
                      },
                    ],
                    staticClass: "modelList__error",
                  },
                  [_vm._v("查询条件：品类、型号、SKU，请至少选择其中一项")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    { ref: "queryModelForm", attrs: { model: _vm.listQuery } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "categoryId" } },
                            [
                              _c("tree-select", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: _vm.listQuery.categoryId,
                                  options: _vm.categorySelectTree,
                                  accordion: false,
                                  clearable: true,
                                  placeholder: "请选择品类",
                                },
                                on: {
                                  getValue: function ($event) {
                                    return _vm.getTreeSelectNodeKey($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "modelId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择型号",
                                  },
                                  on: {
                                    focus: _vm.loadModelSelect,
                                    change: _vm.modelSelectChange,
                                  },
                                  model: {
                                    value: _vm.listQuery.modelId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "modelId", $$v)
                                    },
                                    expression: "listQuery.modelId",
                                  },
                                },
                                _vm._l(_vm.modelSelect, function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "brandId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择SKU",
                                  },
                                  on: { focus: _vm.loadSkuSelect },
                                  model: {
                                    value: _vm.listQuery.skuCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "skuCode", $$v)
                                    },
                                    expression: "listQuery.skuCode",
                                  },
                                },
                                _vm._l(_vm.skuSelect, function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.skuName,
                                        value: item.code,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.code))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.searchBtnClickSkuSel },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "info" },
                            on: { click: _vm.cleanBtnClickSkuSel },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingListSkuSelect,
                  expression: "loadingListSkuSelect",
                },
              ],
              ref: "multipleTableSkuSelect",
              staticStyle: { width: "100%" },
              attrs: {
                size: "medium",
                border: "",
                stripe: "",
                "tooltip-effect": "dark",
                data: _vm.listSkuSelect,
              },
              on: {
                "selection-change": _vm.handleSelectionChangeSkuSelect,
                "row-click": _vm.rowClickSelectionSkuSelect,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  align: "center",
                  prop: "id",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "型号编码" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "型号名称",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "preSn",
                  label: "prefix",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.addSkuSwitch } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.addBtnDisabled },
                  on: { click: _vm.addSkuSelectBtnClick },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }