"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _taskDetail = _interopRequireDefault(require("@/components/work_task/taskDetail"));

var _taskHistory = _interopRequireDefault(require("@/components/work_task/taskHistory"));

var _work_task = require("@/api/work_task");

var _list = require("@/api/basics/baseGroup/list");

var _select = require("@/api/common/select");

var _index = _interopRequireDefault(require("@/components/uploadFile4/index"));

var _index2 = _interopRequireDefault(require("@/components/Sticky/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'detail'
  components: {
    uploadFile: _index.default,
    taskDetail: _taskDetail.default,
    taskHistory: _taskHistory.default,
    Sticky: _index2.default
  },
  filters: {
    ellipsis: function ellipsis(value) {
      if (!value) return '';

      if (value.length > 80) {
        return value.slice(0, 80) + '...';
      }

      return value;
    }
  },
  data: function data() {
    return {
      workNo: this.$route.params.id,
      isMyTask: this.$route.params.isMyTask,
      deliverShow: false,
      setupTimeShow: false,
      accomplishShow: false,
      titleFlag: true,
      userId: this.$store.state.user.loginUser.memberId,
      // 转交
      OrderTask2: {
        taskHandleGroup: '',
        taskHandleRmk: '',
        remarks: '',
        completeDate: ''
      },
      // 期望日期
      OrderTask3: {
        completeDate: '',
        remarks: ''
      },
      // 创建
      OrderTask4: {
        taskInGroupId: '',
        taskInRmk: '',
        remarks: ''
      },
      // 备注
      remarks: '',
      enclosures: undefined,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      btnLoading: false,
      OrderTask: {},
      rules1: {
        taskHandleGroup: [{
          required: true,
          message: '请选择要转交的小组名称',
          trigger: 'blur'
        }],
        taskHandleRmk: [{
          required: false,
          message: '请选择要转交的组员名称',
          trigger: 'blur'
        }],
        completeDate: [{
          required: true,
          message: '请选择期望完成时间',
          trigger: 'change'
        }],
        remarks: [{
          required: true,
          message: '请填写备注',
          trigger: 'blur'
        }]
      },
      rules2: {
        completeDate: [{
          required: true,
          message: '请选择新的期望完成时间',
          trigger: 'blur'
        }],
        remarks: [{
          required: true,
          message: '请填写备注',
          trigger: 'blur'
        }]
      },
      rules3: {
        taskInGroupId: [{
          required: true,
          message: '请选择要转交的小组名称',
          trigger: 'blur'
        }],
        taskInRmk: [{
          required: true,
          message: '请选择要转交的创建人名称',
          trigger: 'blur'
        }],
        remarks: [{
          required: true,
          message: '请填写备注',
          trigger: 'blur'
        }]
      },
      BaseGroup: {
        taskType: ''
      },
      BaseGroupList: [],
      userSelect: [],
      userSelect2: [],
      refresh: true
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.workNo) {
      this.getById();
    }
  },
  methods: {
    getById: function getById() {
      var _this = this;

      (0, _work_task.getByWorkNo)(this.workNo).then(function (response) {
        if (response.data) {
          _this.OrderTask = response.data;

          if (_this.OrderTask.taskType) {
            _this.BaseGroup.taskType = _this.OrderTask.taskType;
          }
        }
      });
    },
    getBaseGroupByType: function getBaseGroupByType() {
      var _this2 = this;

      (0, _list.getBaseGroupByType)(this.BaseGroup).then(function (response) {
        if (response.data) {
          _this2.OrderTask4.taskInRmk = '';
          _this2.OrderTask2.taskHandleRmk = '';
          _this2.BaseGroupList = response.data;
        }
      });
    },
    loadUserSelect: function loadUserSelect() {
      var _this3 = this;

      if (this.OrderTask2.taskHandleGroup) {
        (0, _select.getMemberList)({
          groupId: this.OrderTask2.taskHandleGroup
        }).then(function (res) {
          _this3.userSelect = res.data;
        });
      } else {
        this.$message({
          showClose: true,
          message: '请先选择小组',
          type: 'warning'
        });
      }
    },
    loadUserSelect2: function loadUserSelect2() {
      var _this4 = this;

      if (this.OrderTask4.taskInGroupId) {
        (0, _select.getMemberList)({
          groupId: this.OrderTask4.taskInGroupId
        }).then(function (res) {
          _this4.userSelect2 = res.data;
        });
      } else {
        this.$message({
          showClose: true,
          message: '请先选择小组',
          type: 'warning'
        });
      }
    },
    // 设置图片上传
    setFilePaths: function setFilePaths(data) {
      this.enclosures = data;
    },
    operationSuccessfully: function operationSuccessfully() {
      this.getById();
      this.$refs.his.listHistoryByOrderNo();
      this.$message({
        message: '操作成功',
        type: 'success'
      });
    },
    // 改标题
    editTitle: function editTitle() {
      var _this5 = this;

      var title1 = this.OrderTask.title.replace(/\s*/g, '');

      if (title1 !== '') {
        this.titleFlag = true;
        this.OrderTask.operation = '45';
        (0, _work_task.updateTask)(this.OrderTask).then(function (response) {
          if (response.data) {
            _this5.operationSuccessfully();
          }
        });
      } else {
        this.$message({
          message: '工单任务名称不能为空',
          type: 'warning'
        });
      }
    },
    // 备注
    setRemark: function setRemark() {
      var _this6 = this;

      var remarks1 = this.remarks.replace(/\s*/g, '');

      if (remarks1 !== '') {
        this.$confirm('是否确认提交备注？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          type: 'warning',
          center: true
        }).then(function () {
          _this6.OrderTask.remarks = _this6.remarks;
          _this6.OrderTask.enclosures = _this6.enclosures;
          _this6.OrderTask.operation = '50';
          (0, _work_task.updateTask)(_this6.OrderTask).then(function (response) {
            if (response.data) {
              _this6.remarks = '';
              _this6.enclosures = undefined;
              _this6.refresh = false;

              _this6.$nextTick(function () {
                _this6.refresh = true;
              });

              _this6.operationSuccessfully();
            } else {
              _this6.$message({
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }).catch(function () {});
      } else {
        this.$message({
          message: '备注不能为空',
          type: 'warning'
        });
      }
    },
    // 移交
    handOver: function handOver() {
      var _this7 = this;

      this.$refs['handOverForm'].validate(function (valid) {
        if (valid) {
          _this7.OrderTask.operation = '30';
          _this7.OrderTask.taskHandleGroup = _this7.OrderTask2.taskHandleGroup;
          _this7.OrderTask.taskHandleRmk = _this7.OrderTask2.taskHandleRmk;
          _this7.OrderTask.remarks = _this7.OrderTask2.remarks;
          _this7.OrderTask.completeDate = _this7.OrderTask2.completeDate;
          (0, _work_task.updateTask)(_this7.OrderTask).then(function (response) {
            if (response.data) {
              _this7.return2List();
            } else {
              _this7.getById();

              _this7.$message({
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    handOverOff: function handOverOff() {
      this.OrderTask2.taskHandleGroup = '';
      this.OrderTask2.taskHandleRmk = '';
      this.OrderTask2.remarks = '';
      this.OrderTask2.completeDate = '';
      this.deliverShow = false;
    },
    // 设置新的期望时间
    updateTime: function updateTime() {
      var _this8 = this;

      this.$refs['updateTimeForm'].validate(function (valid) {
        if (valid) {
          _this8.OrderTask.operation = '60';
          _this8.OrderTask.remarks = _this8.OrderTask3.remarks;
          _this8.OrderTask.completeDate = _this8.OrderTask3.completeDate;
          (0, _work_task.updateTask)(_this8.OrderTask).then(function (response) {
            if (response.data) {
              _this8.updateTimeOff();

              _this8.operationSuccessfully();
            } else {
              _this8.getById();

              _this8.$message({
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    updateTimeOff: function updateTimeOff() {
      this.OrderTask3.remarks = '';
      this.OrderTask3.completeDate = '';
      this.setupTimeShow = false;
    },
    // 设置新的创建人
    updateCreate: function updateCreate() {
      var _this9 = this;

      this.$refs['updateCreateForm'].validate(function (valid) {
        if (valid) {
          _this9.OrderTask.operation = '25';
          _this9.OrderTask.taskInGroupId = _this9.OrderTask4.taskInGroupId;
          _this9.OrderTask.taskInRmk = _this9.OrderTask4.taskInRmk;
          _this9.OrderTask.remarks = _this9.OrderTask4.remarks;
          (0, _work_task.updateTask)(_this9.OrderTask).then(function (response) {
            if (response.data) {
              _this9.updateCreateOff();

              _this9.operationSuccessfully();
            }
          });
        }
      });
    },
    updateCreateOff: function updateCreateOff() {
      this.OrderTask4.taskInRmk = '';
      this.OrderTask4.completeDate = '';
      this.OrderTask4.remarks = '';
      this.accomplishShow = false;
    },
    // 完成
    perform: function perform() {
      var _this10 = this;

      if (this.OrderTask.state === '20') {
        this.$confirm('是否确认完成此任务？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          type: 'warning',
          center: true
        }).then(function () {
          _this10.OrderTask.operation = '70';
          (0, _work_task.updateTask)(_this10.OrderTask).then(function (response) {
            if (response.data) {
              _this10.return2List();
            } else {
              _this10.$message({
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }).catch(function () {});
      } else {
        this.$message({
          message: '当前状态不能完成',
          type: 'warning'
        });
      }
    },
    // 关单
    closeTask: function closeTask() {
      var _this11 = this;

      if (this.OrderTask.state === '30') {
        this.$confirm('是否确认关闭此任务？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          type: 'warning',
          center: true
        }).then(function () {
          _this11.OrderTask.operation = '80';
          (0, _work_task.updateTask)(_this11.OrderTask).then(function (response) {
            if (response.data) {
              _this11.return2List();
            } else {
              _this11.$message({
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }).catch(function () {});
      } else {
        this.$message({
          message: '当前状态不能关闭',
          type: 'warning'
        });
      }
    },
    // 返回列表
    return2List: function return2List() {
      this.$store.dispatch('tagsView/delView', this.$route);

      if (this.isMyTask === '0') {
        this.$router.push({
          path: '/work_task/work_task/index'
        });
      } else if (this.isMyTask === '2') {
        // 跳转小组页面
        this.$router.push({
          path: '/work_task/work_task/groupTask'
        });
      } else {
        this.$router.push({
          path: '/work_task/work_task/myTask'
        });
      }
    },
    onChangeGroup: function onChangeGroup() {
      this.OrderTask2.taskHandleRmk = '';
      this.OrderTask4.taskInRmk = '';
    },
    giveTask: function giveTask() {
      this.deliverShow = true;
      this.OrderTask2.completeDate = this.OrderTask.completeDate; // if (this.isMyTask === '1') {
      //   if (this.$store.state.user.loginUser.groupId) {
      //     this.OrderTask2.taskHandleGroup = this.$store.state.user.loginUser.groupId
      //   } else {
      //     this.$message({
      //       message: '当前用户没有分配小组',
      //       type: 'warning'
      //     })
      //     this.deliverShow = false
      //   }
      // }
    }
  }
};
exports.default = _default;