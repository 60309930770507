import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=9672b500&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9672b500')) {
      api.createRecord('9672b500', component.options)
    } else {
      api.reload('9672b500', component.options)
    }
    module.hot.accept("./detail.vue?vue&type=template&id=9672b500&", function () {
      api.rerender('9672b500', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/offline/offline_store_return/detail.vue"
export default component.exports