var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-left": "50px", "margin-right": "50px" } },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("任务操作日志记录")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticClass: "block",
              staticStyle: {
                "min-height": "300px",
                "max-height": "600px",
                overflow: "auto",
              },
            },
            [
              _c(
                "el-timeline",
                { staticStyle: { "padding-right": "28px" } },
                _vm._l(_vm.OrderTaskHistoryList, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: { timestamp: item.inDate, placement: "top" },
                    },
                    [
                      _c(
                        "el-card",
                        [
                          _c("h4", [
                            _vm._v(
                              _vm._s(item.inRmkName) +
                                "     " +
                                _vm._s(item.inDate) +
                                "     " +
                                _vm._s(item.alternate1)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("pre", { staticClass: "content" }, [
                            _vm._v(_vm._s(item.alternate2)),
                          ]),
                          _vm._v(" "),
                          item.operation === "50" || item.operation === "10"
                            ? _c("el-upload", {
                                staticClass: "upload-demo",
                                attrs: {
                                  action: "/uploadsever/s3/uploadFile",
                                  "on-preview": _vm.handlePreview,
                                  disabled: "",
                                  "list-type": "picture",
                                  limit: 5,
                                  "file-list": item.imgList,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }