var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_EXPRESS_ORDER_SAVEORUPDATE"],
                      expression: "['ROLE_EXPRESS_ORDER_SAVEORUPDATE']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    disabled: _vm.btnLoading,
                    loading: _vm.btnLoading,
                  },
                  on: { click: _vm.submitCreate },
                },
                [_vm._v("生成提交\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "Form",
          attrs: {
            model: _vm.sfExpressOrder,
            rules: _vm.expressRules,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              外部订单信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "订单号",
                                          prop: "orderNum",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value: _vm.sfExpressOrder.orderNum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "orderNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.orderNum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { label: "订单来源" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.orderSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "orderSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.orderSource",
                                            },
                                          },
                                          _vm._l(
                                            _vm.sourceSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "订单备注",
                                          prop: "note",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: {
                                            type: "textarea",
                                            disabled: _vm.disableNote,
                                          },
                                          model: {
                                            value: _vm.sfExpressOrder.note,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "note",
                                                $$v
                                              )
                                            },
                                            expression: "sfExpressOrder.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              寄件人信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件机构",
                                          prop: "sendOrg",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: "请选择",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeSendOrgById(
                                                  _vm.sfExpressOrder.sendOrg
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.sfExpressOrder.sendOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendOrg",
                                            },
                                          },
                                          _vm._l(
                                            _vm.expressSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.orgName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件人姓名",
                                          prop: "sendName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { disabled: _vm.isSendEdit },
                                          model: {
                                            value: _vm.sfExpressOrder.sendName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件人电话",
                                          prop: "sendMobile",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { disabled: _vm.isSendEdit },
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.sendMobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendMobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件省份",
                                            prop: "sendProvinceCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isSendEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.sendProviceChange(
                                                    _vm.sfExpressOrder
                                                      .sendProvinceCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .sendProvinceCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "sendProvinceCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.sendProvinceCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.provinceSelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件城市",
                                            prop: "sendCityCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isSendEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.sendCityChange(
                                                    _vm.sfExpressOrder
                                                      .sendCityCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .sendCityCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "sendCityCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.sendCityCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.citySelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件区域",
                                            prop: "sendDistrictCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isSendEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .sendDistrictCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "sendDistrictCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.sendDistrictCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.regionSelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "sendAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isSendEdit },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.sendAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "公司信息",
                                            prop: "sendCompanyInfo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isSendEdit },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .sendCompanyInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendCompanyInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendCompanyInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              收件人信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件机构",
                                          prop: "receiveOrg",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: "请选择",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeReceiveOrgById(
                                                  _vm.sfExpressOrder.receiveOrg
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.receiveOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveOrg",
                                            },
                                          },
                                          _vm._l(
                                            _vm.expressSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.orgName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件人姓名",
                                          prop: "receiveName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: {
                                            disabled: _vm.isReceiveEdit,
                                          },
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.receiveName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "receiveName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.receiveName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件人电话",
                                          prop: "receiveMobile",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: {
                                            disabled: _vm.isReceiveEdit,
                                          },
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.receiveMobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "receiveMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.receiveMobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件省份",
                                            prop: "receiveProvinceCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isReceiveEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.receiveProviceChange(
                                                    _vm.sfExpressOrder
                                                      .receiveProvinceCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .receiveProvinceCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "receiveProvinceCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.receiveProvinceCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.provinceSelect2,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件城市",
                                            prop: "receiveCityCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isReceiveEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.receiveCityChange(
                                                    _vm.sfExpressOrder
                                                      .receiveCityCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .receiveCityCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "receiveCityCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.receiveCityCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.citySelect2,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件区域",
                                            prop: "receiveDistrictCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isReceiveEdit,
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.sfExpressOrder
                                                    .receiveDistrictCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sfExpressOrder,
                                                    "receiveDistrictCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sfExpressOrder.receiveDistrictCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.regionSelect2,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "receiveAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isReceiveEdit,
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "公司信息",
                                            prop: "receiveCompanyInfo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isReceiveEdit,
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveCompanyInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveCompanyInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveCompanyInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              其他信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "预计取件日期",
                                          prop: "expectedDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: "预计取件日期",
                                          },
                                          on: { change: _vm.changeBookDate },
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.expectedDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "expectedDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.expectedDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "预计取件时间",
                                          prop: "expectedTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              placeholder: "请选择时间段",
                                            },
                                            on: { change: _vm.changeBookClock },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.expectedTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "expectedTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.expectedTime",
                                            },
                                          },
                                          _vm._l(
                                            _vm.bookTimePerList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "是否需要包装",
                                          prop: "isPackage",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { fill: "#000" },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.isPackage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "isPackage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.isPackage",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "1" } },
                                              [_vm._v("是")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "0" } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件商品信息",
                                          prop: "sendInfo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.sendInfo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendInfo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendInfo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "顺丰备注",
                                          prop: "sfNote",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.sfNote,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sfNote",
                                                $$v
                                              )
                                            },
                                            expression: "sfExpressOrder.sfNote",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "Dyson备注",
                                          prop: "dysonNote",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.dysonNote,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "dysonNote",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.dysonNote",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }