"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _order = require("@/api/order/order");

var _filterUtils = require("@/api/order/filterUtils");

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
var _default = {
  components: {
    uploadFile: _uploadFile.default
  },
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      skuListLoading: false,
      schemeListLoading: false,
      verifyResultOptions: _constant.default.verifyResultOptions,
      businessTypeOptions: [{
        val: '01',
        name: '维修'
      }],
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      handleResultOptions: _constant.default.handleResultOptions10,
      feeTypeOptions: _constant.default.feeTypeOptions,
      schemeList: [],
      skuList: [],
      checkItemList4show: []
    };
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadhandleInfo', function (orderId) {
      if (orderId) {
        _this.loadSchemeList(orderId);

        _this.loadOrderSkuList(orderId);

        _this.initShowcheckItemList4show(orderId);
      }
    });
  },
  methods: {
    initSelect: function initSelect() {},
    loadSchemeList: function loadSchemeList(orderId) {
      var _this2 = this;

      var params = {
        orderId: orderId,
        step: '4'
      };
      (0, _order.getSchemeList)(params).then(function (response) {
        if (response.data) {
          _this2.schemeList = response.data;
        }
      });
    },
    loadOrderSkuList: function loadOrderSkuList(orderId) {
      var _this3 = this;

      var params = {
        orderId: orderId,
        step: '4'
      };
      (0, _order.getOrderSkuList)(params).then(function (response) {
        if (response.data) {
          _this3.skuList = response.data;
          var indexNum = 0;
          _this3.skuList = response.data.map(function (v) {
            _this3.$set(v, 'indexNum', ++indexNum); // 动态加字段


            return v;
          });
        }
      });
    },
    initShowcheckItemList4show: function initShowcheckItemList4show(orderId) {
      var _this4 = this;

      (0, _order.getCheckItems)({
        orderId: orderId
      }).then(function (response) {
        if (response.data) {
          var itemArray = response.data;
          var len = itemArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this4.checkItemList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = itemArray.slice(i * n, i * n + n);

            _this4.checkItemList4show.push(temp);
          }
        }
      });
    }
  }
};
exports.default = _default;