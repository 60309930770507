"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrderWipList = getOrderWipList;
exports.getWipInCount = getWipInCount;
exports.getWipInWarranty = getWipInWarranty;
exports.getWipOutCount = getWipOutCount;
exports.getWipOutWarranty = getWipOutWarranty;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询数字看板保内图表
 * @param query
 */
function getWipInWarranty(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getWipInWarranty',
    method: 'get',
    params: query
  });
}
/**
 * 查询数字看板保外图表
 * @param query
 */


function getWipOutWarranty(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getWipOutWarranty',
    method: 'get',
    params: query
  });
}
/**
 * 查询数字看板保内总计
 * @param query
 */


function getWipInCount(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getWipInCount',
    method: 'get',
    params: query
  });
}
/**
 * 查询数字看板保外总计
 * @param query
 */


function getWipOutCount(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getWipOutCount',
    method: 'get',
    params: query
  });
}
/**
 * 查询服务单wip统计页面
 * @param params
 */


function getOrderWipList(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderWipList',
    method: 'get',
    params: query
  });
}