"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _list = require("@/api/quota/quota_history/list");

var _list2 = require("@/api/quota/quota/list");

var _utils = require("@/utils");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _asyncExport = require("@/api/common/asyncExport");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 时间格式化
// 分页组件
var _default = {
  name: 'quotaDetail',
  // eslint-disable-line
  components: {
    Pagination: _Pagination.default,
    Sticky: _Sticky.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      tabStripe: true,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        type: '',
        isAsc: '1',
        orderByField: '1'
      },
      quotaInfo: {
        czQuota: '0',
        xyQuota: '0',
        quota: '0',
        frozenQuota: '0',
        avaibQuota: '0'
      },
      id: this.$route.query.id,
      exportQuery: {
        topic: 'async-export-quotahty-info',
        tag: 'TagA',
        inRmk: this.$store.state.user.loginUser.memberId,
        data: ''
      },
      // 额度类型
      typesOptions: [{
        label: '充值额度',
        value: '10'
      }, {
        label: '信用额度',
        value: '20'
      }],
      // 额度变化
      changeTypes: [{
        label: '增加',
        value: '10'
      }, {
        label: '冻结',
        value: '20'
      }, {
        label: '解冻',
        value: '30'
      }, {
        label: '减少',
        value: '40'
      }, {
        label: '返还',
        value: '50'
      }],
      // 单据类型
      busTypes: [{
        label: '额度充值冻结',
        value: '90'
      }, {
        label: '额度充值删除',
        value: '91'
      }, {
        label: '额度充值确认',
        value: '92'
      }, {
        label: '网点充值申请确认',
        value: '93'
      }, {
        label: '物流出入库额度冻结',
        value: '35'
      }, {
        label: '物流出入库额度扣除',
        value: '36'
      }, {
        label: '物流出入库额度解冻',
        value: '37'
      }, {
        label: '物流出入库额度返还',
        value: '38'
      }, {
        label: '订单申请单的物流出库提交',
        value: '39'
      }, {
        label: '订单申请不再发货',
        value: '100'
      }, {
        label: '订单申请审核通过',
        value: '101'
      }, {
        label: '订单申请额度冻结',
        value: '102'
      }, {
        label: '订单申请删除',
        value: '103'
      }, {
        label: '订单申请审核拒绝',
        value: '104'
      }, {
        label: '买断审核通过返还额度',
        value: '105'
      }, {
        label: '核销收货确认',
        value: '43'
      }]
    };
  },
  created: function created() {
    this.getDataInfo();
  },
  methods: {
    // 查看额度明细数据
    getDataInfo: function getDataInfo() {
      if (this.id) {
        // 额度明细
        this.getDetailInfo(); // 列表数据

        this.getItemList();
      }
    },
    // 额度详情
    getDetailInfo: function getDetailInfo() {
      var _this = this;

      var query = {
        inOrgId: this.id
      };
      (0, _list2.fetchList)(query).then(function (response) {
        if (response != null && response.data != null && response.data.records != null) {
          if (response.data.records.length > 0) {
            _this.quotaInfo = response.data.records[0];
          } else {
            _this.$message({
              message: '数据异常，请返回刷新后重新操作！',
              type: 'warning'
            });
          }
        } else {
          _this.$message({
            message: '数据异常，请返回刷新后重新操作！',
            type: 'warning'
          });
        }
      });
    },
    // 额度变动流水明细分页
    getItemList: function getItemList() {
      var _this2 = this;

      this.listLoading = true;
      this.listQuery.orgId = this.id;
      (0, _list.getItemList)(this.listQuery).then(function (response) {
        _this2.list = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getDataInfo();
    },
    // 重置查询条件
    resetBtn: function resetBtn() {
      this.listQuery = {
        page: 1,
        limit: 10
      };
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/quota/quota/list'
      });
    },
    // 额度充值/申请审核 能查看详情
    btnShow: function btnShow(row) {
      if (row.busType === '90' || row.busType === '91' || row.busType === '92' || row.busType === '93') {
        return true;
      } else {
        return false;
      }
    },
    // 查看额度充值/申请审核 详情
    handleDetail: function handleDetail(row) {
      if (row.busType === '90' || row.busType === '91' || row.busType === '92') {
        this.$router.push({
          name: 'quotaInDetail',
          query: {
            id: row.num
          }
        });
      } else if (row.busType === '93') {
        this.$router.push({
          name: 'quotaAuditDetail',
          query: {
            id: row.num
          }
        });
      }
    },
    // 单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 列表额度类型格式化
    typeFormat: function typeFormat(row) {
      return this.typesOptions.map(function (type) {
        if (row.quotaType === type.value) {
          return type.label;
        }

        return '';
      });
    },
    // 列表额度变化状态格式化
    changeTypeFormat: function changeTypeFormat(row) {
      return this.changeTypes.map(function (type) {
        if (row.changeType === type.value) {
          return type.label;
        }

        return '';
      });
    },
    // 列表单据类型格式化
    busTypeFormat: function busTypeFormat(row) {
      return this.busTypes.map(function (type) {
        if (row.busType === type.value) {
          return type.label;
        }

        return '';
      });
    },
    // 时间格式化
    dateFormat: function dateFormat(row, column) {
      var date = row[column.property];

      if (date === undefined || date === null) {
        return '';
      }

      return (0, _utils.parseTime)(new Date(date), '{y}-{m}-{d} {h}:{i}:{s}');
    },
    handelExport: function handelExport() {
      var _this3 = this;

      var Base64 = require('js-base64').Base64;

      this.listQuery.orgId = this.id;
      this.exportQuery.data = Base64.encode(JSON.stringify(this.listQuery));
      (0, _asyncExport.asyncExport)(this.exportQuery).then(function (response) {
        if (response.code === 200) {
          _this3.$message.success('已创建导出任务[' + response.data + ']');
        } else {
          _this3.$message.error('创建导出任务失败:' + response.msg);
        }
      });
    }
  }
};
exports.default = _default;