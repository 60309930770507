"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addNote = addNote;
exports.cancelBookOrder = cancelBookOrder;
exports.createExpressOrder = createExpressOrder;
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.queryById = queryById;
exports.querySfExpressRoute = querySfExpressRoute;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/getById',
    method: 'get',
    params: {
      id: id
    }
  });
}
/**
 * 履历列表
 * @param query
 */


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/getHistoryList',
    method: 'post',
    params: query
  });
}
/**
 * 新建或者更新顺丰下单
 * @param data
 */


function createExpressOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/createOrUpdate',
    method: 'post',
    data: data
  });
}
/**
 * 查询路由
 * @param id
 */


function querySfExpressRoute(id) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/querySfExpressRoute',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 取消预约
 * @param id，reaseon
 */


function cancelBookOrder(id, reason) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/cancelBookOrder',
    method: 'post',
    params: {
      id: id,
      reason: reason
    }
  });
}
/**
 * 添加备注
 *
 */


function addNote(id, note) {
  return (0, _request.default)({
    url: '/bus_app/sf_express_order/addNote',
    method: 'post',
    params: {
      id: id,
      note: note
    }
  });
}