"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _sfExpressOrder = require("@/api/express/sfExpressOrder");

var _expressOrg = require("@/api/express/expressOrg");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _select = require("@/api/common/select");

var _contants = _interopRequireDefault(require("@/api/express/contants"));

var _list = require("@/api/consult/list");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'sfExpressOrderDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {},
  data: function data() {
    return {
      id: this.$route.params.id,
      sfExpressOrder: {
        isPackage: '0',
        cancelReason: '',
        dysonConfirm: '',
        dysonNote: '',
        expectedDate: '',
        expectedTime: '',
        note: '',
        orderNum: '',
        orderSource: '',
        orderTime: '',
        receiveAddress: '',
        receiveCityCode: '',
        receiveCompanyInfo: '',
        receiveDistrictCode: '',
        receiveMobile: '',
        receiveName: '',
        receiveOrg: '',
        receiveProvinceCode: '',
        recepitTime: '',
        sendAddress: '',
        sendCityCode: '',
        sendCompanyInfo: '',
        sendDistrictCode: '',
        sendInfo: '',
        sendMobile: '',
        sendName: '',
        sendOrg: '',
        sendProvinceCode: '',
        sfNote: '',
        sfNum: ''
      },
      btnLoading: false,
      expressRules: {
        num: [{
          required: true,
          message: '订单号是必填的',
          trigger: 'blur'
        }],
        orderSource: [{
          required: true,
          message: '订单来源是必填的',
          trigger: 'blur'
        }],
        sendName: [{
          required: true,
          message: '寄件名称是必填的',
          trigger: 'blur'
        }],
        sendMobile: [{
          required: true,
          message: '寄件人电话是必填的',
          trigger: 'blur'
        }],
        sendOrg: [{
          required: true,
          message: '寄件机构是必填的',
          trigger: 'blur'
        }],
        receiveOrg: [{
          required: true,
          message: '收件机构是必填的',
          trigger: 'blur'
        }],
        sendProvinceCode: [{
          required: true,
          message: '省份是必填的',
          trigger: 'blur'
        }],
        sendCityCode: [{
          required: true,
          message: '城市是必填的',
          trigger: 'blur'
        }],
        sendDistrictCode: [{
          required: true,
          message: '区域是必填的',
          trigger: 'blur'
        }],
        sendAddress: [{
          required: true,
          message: '寄件地址是必填的',
          trigger: 'blur'
        }],
        receiveName: [{
          required: true,
          message: '寄件名称是必填的',
          trigger: 'blur'
        }],
        receiveMobile: [{
          required: true,
          message: '寄件人电话是必填的',
          trigger: 'blur'
        }],
        receiveProvinceCode: [{
          required: true,
          message: '省份是必填的',
          trigger: 'blur'
        }],
        receiveCityCode: [{
          required: true,
          message: '城市是必填的',
          trigger: 'blur'
        }],
        receiveDistrictCode: [{
          required: true,
          message: '区域是必填的',
          trigger: 'blur'
        }],
        receiveAddress: [{
          required: true,
          message: '寄件地址是必填的',
          trigger: 'blur'
        }],
        expectedDate: [{
          required: true,
          message: '预计上门日期是必填的',
          trigger: 'blur'
        }],
        expectedTime: [{
          required: true,
          message: '预计上门时间是必填的',
          trigger: 'blur'
        }]
      },
      sourceSelect: _contants.default.expressSourceSelect,
      // 省下拉
      provinceSelect: [],
      citySelect: [],
      // 区下拉，
      regionSelect: [],
      provinceSelect2: [],
      citySelect2: [],
      // 区下拉，
      regionSelect2: [],
      expressSelect: [],
      otherOrg: {
        id: 'other',
        orgName: '其他'
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      bookTimePerList: [{
        'val': '08:00~09:59',
        'name': '08:00~09:59'
      }, {
        'val': '10:00~11:59',
        'name': '10:00~11:59'
      }, {
        'val': '12:00~13:59',
        'name': '12:00~13:59'
      }, {
        'val': '14:00~15:59',
        'name': '14:00~15:59'
      }, {
        'val': '16:00~17:59',
        'name': '16:00~17:59'
      }],
      isReceiveEdit: false,
      isSendEdit: false
    };
  },
  computed: {
    disableNote: function disableNote() {
      if (this.sfExpressOrder.orderSource === '12') {
        return false;
      } else {
        return true;
      }
    }
  },
  created: function created() {
    this.getProvinceOptions();
    this.getExpressOrgSelect();

    if (this.$route.params.id) {
      this.getDetail();
    } else {
      this.sfExpressOrder.isPackage = '0';
    }
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _sfExpressOrder.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.sfExpressOrder = response.data;

          _this.getCityOption();
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.sfExpressOrder = {};
      this.$router.push({
        path: '/express/sf_express_order'
      });
    },
    submitCreate: function submitCreate() {
      var _this2 = this;

      this.btnLoading = true;

      if (this.sfExpressOrder.orderSource === '12' && !this.sfExpressOrder.note) {
        this.$message.warning('请先填写备注信息！');
        this.btnLoading = false;
        return;
      }

      this.$refs['Form'].validate(function (valid) {
        if (valid) {
          _this2.sfExpressOrder.sendProvince = _this2.getNameByCode(_this2.provinceSelect, _this2.sfExpressOrder.sendProvinceCode);
          _this2.sfExpressOrder.sendCity = _this2.getNameByCode(_this2.citySelect, _this2.sfExpressOrder.sendCityCode);
          _this2.sfExpressOrder.sendDistrict = _this2.getNameByCode(_this2.regionSelect, _this2.sfExpressOrder.sendDistrictCode);
          _this2.sfExpressOrder.receiveProvince = _this2.getNameByCode(_this2.provinceSelect2, _this2.sfExpressOrder.receiveProvinceCode);
          _this2.sfExpressOrder.receiveCity = _this2.getNameByCode(_this2.citySelect2, _this2.sfExpressOrder.receiveCityCode);
          _this2.sfExpressOrder.receiveDistrict = _this2.getNameByCode(_this2.regionSelect2, _this2.sfExpressOrder.receiveDistrictCode);
          (0, _sfExpressOrder.createExpressOrder)(_this2.sfExpressOrder).then(function (res) {
            if (res.data && res.code === 200) {
              _this2.sfExpressOrder = {};

              _this2.$message.success('操作成功');

              _this2.$router.push({
                path: '/express/sf_express_order'
              });
            } else {
              _this2.$message.error(res.msg || '操作失败');
            }

            _this2.btnLoading = false;
          });
        } else {
          _this2.btnLoading = false;
        }
      });
    },
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this3.provinceSelect = res.data;
        _this3.provinceSelect2 = res.data;
      });
    },
    getExpressOrgSelect: function getExpressOrgSelect() {
      var _this4 = this;

      (0, _expressOrg.getExpressOrgSelect)(1).then(function (res) {
        _this4.expressSelect = res.data;

        _this4.expressSelect.push(_this4.otherOrg);
      });
    },
    getNameByCode: function getNameByCode(select, code) {
      var name = '';

      if (select.length > 0) {
        select.forEach(function (item) {
          if (item.id === code) {
            name = item.label;
          }
        });
      }

      return name;
    },
    getCityOption: function getCityOption() {
      var _this5 = this;

      if (this.sfExpressOrder.sendProvinceCode) {
        (0, _select.getAreasByParentId)(this.sfExpressOrder.sendProvinceCode).then(function (response) {
          _this5.citySelect = response.data;
        });
      }

      if (this.sfExpressOrder.sendCityCode) {
        (0, _select.getAreasByParentId)(this.sfExpressOrder.sendCityCode).then(function (response) {
          _this5.regionSelect = response.data;
        });
      }

      if (this.sfExpressOrder.receiveProvinceCode) {
        (0, _select.getAreasByParentId)(this.sfExpressOrder.receiveProvinceCode).then(function (response) {
          _this5.citySelect2 = response.data;
        });
      }

      if (this.sfExpressOrder.receiveCityCode) {
        (0, _select.getAreasByParentId)(this.sfExpressOrder.receiveCityCode).then(function (response) {
          _this5.regionSelect2 = response.data;
        });
      }
    },
    // 寄件人 城市
    sendProviceChange: function sendProviceChange(province) {
      var _this6 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this6.citySelect = response.data;
        });
      }

      this.sfExpressOrder.sendCity = '';
      this.sfExpressOrder.sendDistrict = '';
      this.sfExpressOrder.sendCityCode = '';
      this.sfExpressOrder.sendDistrictCode = '';
    },
    // 寄件人区域
    sendCityChange: function sendCityChange(city) {
      var _this7 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this7.regionSelect = response.data;
        });
      }

      this.sfExpressOrder.sendDistrict = '';
      this.sfExpressOrder.sendDistrictCode = '';
    },
    // 收件人 城市
    receiveProviceChange: function receiveProviceChange(province) {
      var _this8 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this8.citySelect2 = response.data;
        });
      }

      this.sfExpressOrder.receiveCity = '';
      this.sfExpressOrder.receiveDistrict = '';
      this.sfExpressOrder.receiveCityCode = '';
      this.sfExpressOrder.receiveDistrictCode = '';
    },
    // 收件人区域
    receiveCityChange: function receiveCityChange(city) {
      var _this9 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this9.regionSelect2 = response.data;
        });
      }

      this.sfExpressOrder.receiveDistrict = '';
      this.sfExpressOrder.receiveDistrictCode = '';
    },
    // 根据id获得机构信息
    changeSendOrgById: function changeSendOrgById(id) {
      var _this10 = this;

      this.expressSelect.forEach(function (item) {
        if (item.id === id) {
          _this10.$set(_this10.sfExpressOrder, 'sendName', item.receiver);

          _this10.$set(_this10.sfExpressOrder, 'sendMobile', item.receiveMobile);

          _this10.$set(_this10.sfExpressOrder, 'sendProvinceCode', item.provinceCode);

          _this10.$set(_this10.sfExpressOrder, 'sendCityCode', item.cityCode);

          _this10.$set(_this10.sfExpressOrder, 'sendDistrictCode', item.districtCode);

          _this10.$set(_this10.sfExpressOrder, 'sendAddress', item.detailAddress);

          _this10.$set(_this10.sfExpressOrder, 'sendCompanyInfo', item.companyInfo);

          _this10.isSendEdit = false;

          _this10.getCityOption();
        } else {
          _this10.isSendEdit = true;
        }
      });
    },
    changeReceiveOrgById: function changeReceiveOrgById(id) {
      var _this11 = this;

      this.expressSelect.forEach(function (item) {
        if (item.id === id) {
          _this11.$set(_this11.sfExpressOrder, 'receiveName', item.receiver);

          _this11.$set(_this11.sfExpressOrder, 'receiveMobile', item.receiveMobile);

          _this11.$set(_this11.sfExpressOrder, 'receiveProvinceCode', item.provinceCode);

          _this11.$set(_this11.sfExpressOrder, 'receiveCityCode', item.cityCode);

          _this11.$set(_this11.sfExpressOrder, 'receiveDistrictCode', item.districtCode);

          _this11.$set(_this11.sfExpressOrder, 'receiveAddress', item.detailAddress);

          _this11.$set(_this11.sfExpressOrder, 'receiveCompanyInfo', item.companyInfo);

          _this11.isReceiveEdit = false;

          _this11.getCityOption();
        } else {
          _this11.isReceiveEdit = true;
        }
      });
    },
    // 选顺丰上门取件时间
    changeBookDate: function changeBookDate() {// this.sfExpressOrder.expectedTime = ''
    },
    // 选时间段判断，是否是有效顺丰上门取件时间
    changeBookClock: function changeBookClock() {
      var _this12 = this;

      console.log('time', this.sfExpressOrder.expectedTime);
      var param = {
        bookDate: this.sfExpressOrder.expectedDate,
        bookClock: this.sfExpressOrder.expectedTime
      };
      (0, _list.validateBookClock)(param).then(function (res) {
        if (res.data) {
          _this12.$nextTick(function () {
            _this12.$refs['Form'].clearValidate();
          });
        } else {
          _this12.$message({
            showClose: true,
            message: res.msg || '请选择你方便的时间段！',
            type: 'warning'
          });
        }
      });
    }
  }
};
exports.default = _default;