"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.confirmSave = confirmSave;
exports.createData = createData;
exports.deleteData = deleteData;
exports.deleteItems = deleteItems;
exports.fetchList = fetchList;
exports.getItemsList = getItemsList;
exports.queryById = queryById;
exports.submitSave = submitSave;
exports.tempSave = tempSave;
exports.uploadSkuItem = uploadSkuItem;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询入库单分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新建保存入库单
 */


function createData() {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/create',
    method: 'post'
  });
}
/**
 * 删除入库单
 */


function deleteData(ids) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/delete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 查询入库单详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 查询入库单明细列表
 * @param data
 */


function getItemsList(data) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/getItemsList',
    method: 'post',
    params: data
  });
}
/**
 * 添加入库单明细
 */


function addItems(data) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/addItems',
    method: 'post',
    data: data
  });
}
/**
 * 删除入库单明细
 */


function deleteItems(id, ids) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/deleteItems',
    method: 'post',
    params: {
      id: id,
      ids: ids
    }
  });
}
/**
 * 暂存
 * @param data
 */


function tempSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/tempSave',
    method: 'post',
    data: data
  });
}
/**
 * 提交
 * @param data
 */


function submitSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/submitSave',
    method: 'post',
    data: data
  });
}
/**
 * 确认入库
 * @param data
 */


function confirmSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/confirmSave',
    method: 'post',
    data: data
  });
}
/**
 * 入库明细上传
 * @param data
 */


function uploadSkuItem(parentId, fileName) {
  return (0, _request.default)({
    url: '/bus_app/stock/stock_in/uploadSkuItem',
    method: 'post',
    params: {
      parentId: parentId,
      fileName: fileName
    }
  });
}