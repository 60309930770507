var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "5px", "padding-top": "10px" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _vm.stockTransferInfo.state === "10"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.tempSave()
                              },
                            },
                          },
                          [_vm._v("\n            暂存\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.stockTransferInfo.state === "10"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submitSave()
                              },
                            },
                          },
                          [_vm._v("\n            提交\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.stockTransferInfo.state === "20"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmSave()
                              },
                            },
                          },
                          [_vm._v("\n            确认转移\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.returnBtn } }, [
                      _vm._v("\n            返回\n          "),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                size: "small",
                model: _vm.stockTransferInfo,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "20px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "单号", prop: "num" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "单号",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.stockTransferInfo.num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stockTransferInfo,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression: "stockTransferInfo.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "转出库房",
                                    prop: "swarehouseCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        disabled: _vm.isList,
                                      },
                                      model: {
                                        value:
                                          _vm.stockTransferInfo.swarehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stockTransferInfo,
                                            "swarehouseCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stockTransferInfo.swarehouseCode",
                                      },
                                    },
                                    _vm._l(_vm.sWarehouseList, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                          disabled:
                                            item.orgIsEnable !== "1" ||
                                            item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "转入库房",
                                    prop: "twarehouseCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        disabled: _vm.isList,
                                      },
                                      model: {
                                        value:
                                          _vm.stockTransferInfo.twarehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stockTransferInfo,
                                            "twarehouseCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stockTransferInfo.twarehouseCode",
                                      },
                                    },
                                    _vm._l(_vm.tWarehouseList, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                          disabled:
                                            item.orgIsEnable !== "1" ||
                                            item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      autosize: { minRows: 2, maxRows: 6 },
                                      type: "textarea",
                                      placeholder: "200字以内",
                                    },
                                    model: {
                                      value: _vm.stockTransferInfo.memo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stockTransferInfo,
                                          "memo",
                                          $$v
                                        )
                                      },
                                      expression: "stockTransferInfo.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("物料明细"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.skuListValidate,
                                expression: "skuListValidate",
                              },
                            ],
                            staticClass: "modelList__error",
                          },
                          [_vm._v(" 请添加物料 ！")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: { float: "right" },
                        },
                        [
                          _vm.stockTransferInfo.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "warning",
                                    plain: "",
                                  },
                                  on: { click: _vm.addSkuClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                  _vm._v("添加\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.stockTransferInfo.state === "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.deleteBtnDisabled,
                                    size: "small",
                                    type: "danger",
                                    plain: "",
                                  },
                                  on: { click: _vm.deleteModelClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除\n            "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "1px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingList4Model,
                                      expression: "loadingList4Model",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    stripe: "",
                                    "tooltip-effect": "dark",
                                    data: _vm.itemList,
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "row-click": _vm.rowClickSelection,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      align: "center",
                                      prop: "modelId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuCode",
                                      label: "物料编码",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuName",
                                      label: "物料名称",
                                      resizable: "",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "storehouse",
                                      label: "货位码",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { prop: "quantity", label: "数量" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { prop: "pricePage", label: "小计" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序列号选择",
                                      align: "center",
                                      width: "270",
                                      "class-name": "small-padding fixed-width",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return row.isSn === "1"
                                              ? [
                                                  _vm.stockTransferInfo
                                                    .state === "10"
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            plain: "",
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleSn(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      选择\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            plain: "",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.findSn(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      查看\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              : undefined
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "总数量:" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-tag",
                                { staticStyle: { "min-width": "100px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.stockTransferInfo.quantity) +
                                      "个"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "总金额:" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-tag",
                                { staticStyle: { "min-width": "100px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.stockTransferInfo.amountPage) +
                                      " 元"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加物料", visible: _vm.addSkudialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.addSkudialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "型号选择", prop: "code" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  on: { focus: _vm.getModelSelectList },
                                  model: {
                                    value: _vm.listQuery.modelId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "modelId", $$v)
                                    },
                                    expression: "listQuery.modelId",
                                  },
                                },
                                _vm._l(_vm.modelList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料", prop: "name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.listQuery.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "sku", $$v)
                                  },
                                  expression: "listQuery.sku",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("搜索")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.resetBtn },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "listTableDiv" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableKey",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark",
                    data: _vm.list,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "skuCode", label: "物料编码" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "skuName", label: "物料名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "usableStock", label: "剩余库存" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: { size: "small" },
                              model: {
                                value: row.quantitys,
                                callback: function ($$v) {
                                  _vm.$set(row, "quantitys", $$v)
                                },
                                expression: "row.quantitys",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleModifyStatus(row, "true")
                                  },
                                },
                              },
                              [_vm._v("\n              添加\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleFilter,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addSkudialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加序列号",
            visible: _vm.dialogFormVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-transfer",
                {
                  staticStyle: {
                    "text-align": "left",
                    display: "inline-block",
                  },
                  attrs: {
                    filterable: "",
                    titles: ["串号表", "已添加串号"],
                    data: _vm.snList,
                  },
                  model: {
                    value: _vm.snSure,
                    callback: function ($$v) {
                      _vm.snSure = $$v
                    },
                    expression: "snSure",
                  },
                },
                [
                  _c(
                    "el-tag",
                    {
                      staticClass: "transfer-footer",
                      staticStyle: { "margin-left": "2rem" },
                      attrs: { slot: "right-footer", size: "small" },
                      slot: "right-footer",
                    },
                    [
                      _vm._v(
                        "需添加 " +
                          _vm._s(_vm.snNumber) +
                          "\n          个串号\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "40px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.cancelSn } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.stockTransferInfo.state === "10"
                ? _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.saveSn } },
                    [_vm._v("确认")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看序列号", visible: _vm.findSnDialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.findSnDialogFormVisible = $event
            },
          },
        },
        [
          _c("el-form", { attrs: { "label-width": "120px" } }, [
            _c(
              "div",
              { staticClass: "dashboard-text" },
              _vm._l(_vm.snFindList, function (sn, index) {
                return _c(
                  "el-tag",
                  { key: index, staticStyle: { width: "25%" } },
                  [_vm._v(_vm._s(sn))]
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.findSnDialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        关闭\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }