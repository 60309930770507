"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findStockSns = findStockSns;
exports.repetitionSn = repetitionSn;
exports.saveSn = saveSn;
exports.snCheackValid = snCheackValid;
exports.validInPsn = validInPsn;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询库位对应的序列号
 * @param query
 *     orgId: 机构ID  必须,
 *     isEnable: 启用状态  非必须  1： 启用，0：禁用 ，null 全部查询
 *     warehouseCode: 库位编码  必须
 *     skuId: 物料ID  必须
 * @returns 序列号数组集合
 */
function findStockSns(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_sn/findStockSns',
    method: 'post',
    params: query
  });
}
/**
 * 根据num+skuId在串号附属表查询是否有已添加的串号
 * @param query
 * @returns {AxiosPromise}
 */


function repetitionSn(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_sn/repetitionSn',
    method: 'post',
    params: query
  });
}
/**
 * 保存序列号
 * @param data
 * @returns {AxiosPromise}
 */


function saveSn(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_sn/saveSn',
    method: 'post',
    data: data
  });
}
/**
 * 序列号数量校验，是否可用校验
 * @param query
 * @returns {AxiosPromise}
 */


function snCheackValid(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_sn/snCheackValid',
    method: 'post',
    params: query
  });
}
/**
 * 入库检验序列号可用不可用
 * @param query
 * @returns {AxiosPromise}
 */


function validInPsn(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_sn/validInPsn',
    method: 'post',
    params: query
  });
}