"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCallout = addCallout;
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.getHistoryStateList = getHistoryStateList;
exports.queryById = queryById;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(data) {
  return (0, _request.default)({
    url: '/bus_app/order_task/order_customer/getPageList',
    method: 'post',
    data: data
  });
}
/** 查询详细*/


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/order_task/order_customer/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 任务指派履历查询*/


function getHistoryList(id) {
  return (0, _request.default)({
    url: '/bus_app/order_task/order_customer/getHistoryList',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 任务状态履历查询*/


function getHistoryStateList(id) {
  return (0, _request.default)({
    url: '/bus_app/order_task/order_customer/getHistoryStateList',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 客服任务创建 */


function addCallout(data) {
  return (0, _request.default)({
    url: '/bus_app/order_task/order_customer/addCallout',
    method: 'post',
    data: data
  });
}