var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("维修代码" + _vm._s(!_vm.isCreate ? "详细" : "创建")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.id !== null
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BASE_PRD_REPAIR_MODIFY"],
                      expression: "['ROLE_BASE_PRD_REPAIR_MODIFY']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.updateSubmit },
                },
                [_vm._v("提交")]
              )
            : _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BASE_PRD_REPAIR_ADD"],
                      expression: "['ROLE_BASE_PRD_REPAIR_ADD']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSumbit },
                },
                [_vm._v("提交")]
              ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.returnBtn } }, [_vm._v("返回")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.validateForm,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { prop: "categoryId", label: "品类" },
                          },
                          [
                            _vm.isCreate
                              ? _c("tree-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: _vm.validateForm.categoryId,
                                    options: _vm.tableData1,
                                    accordion: _vm.accordion,
                                    clearable: _vm.cTree,
                                    disabled: !_vm.isCreate,
                                    placeholder: "请选择品类",
                                  },
                                  on: {
                                    getValue: function ($event) {
                                      return _vm.queryTreeSelectNodeKey($event)
                                    },
                                  },
                                })
                              : _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: "",
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.validateForm.categoryId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.validateForm,
                                          "categoryId",
                                          $$v
                                        )
                                      },
                                      expression: "validateForm.categoryId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.categorySelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "维修代码编码", prop: "code" },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: !_vm.isCreate },
                              model: {
                                value: _vm.validateForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "code", $$v)
                                },
                                expression: "validateForm.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "维修代码分类", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.validateForm.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.validateForm, "type", $$v)
                                  },
                                  expression: "validateForm.type",
                                },
                              },
                              _vm._l(
                                _vm.repairTypeSelect,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.id,
                                      disabled: item.isEnable !== "1",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "状态", prop: "isEnable" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { fill: "#000000", size: "medium" },
                                model: {
                                  value: _vm.validateForm.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.validateForm, "isEnable", $$v)
                                  },
                                  expression: "validateForm.isEnable",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("启用")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("禁用")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "维修代码名称", prop: "name" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.validateForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "name", $$v)
                                },
                                expression: "validateForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "备注", prop: "memo" },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 3 },
                              model: {
                                value: _vm.validateForm.memo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "memo", $$v)
                                },
                                expression: "validateForm.memo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null && _vm.validateForm.id != ""
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建人", prop: "inRmkName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "inRmkName",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.inRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null && _vm.validateForm.id != ""
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "inDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.inDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null && _vm.validateForm.id != ""
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改人",
                                  prop: "reRmkName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.reRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "reRmkName",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.reRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null && _vm.validateForm.id != ""
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改时间",
                                  prop: "reDateFM",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.reDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "reDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.reDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }