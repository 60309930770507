"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approve = approve;
exports.approveNo = approveNo;
exports.callBillList = callBillList;
exports.createTicketOnPc = createTicketOnPc;
exports.fetchExhcangeList = fetchExhcangeList;
exports.fetchList = fetchList;
exports.fetchRefundList = fetchRefundList;
exports.queryById = queryById;
exports.queryExchangeById = queryExchangeById;
exports.queryInfoById = queryInfoById;
exports.queryInvoiceByNo = queryInvoiceByNo;
exports.queryRefundById = queryRefundById;
exports.updateBill = updateBill;
exports.updateState = updateState;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Author: Lee
 * @Date: 2020-10-09 18:49:19
 * @LastEditTime: 2020-10-20 17:44:52
 * @Description:
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/list',
    method: 'post',
    params: query
  });
}

function callBillList(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/callBillList',
    method: 'post',
    params: query
  });
}

function queryById(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/queryById',
    method: 'post',
    params: query
  });
}

function fetchRefundList(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/refundList',
    method: 'post',
    params: query
  });
}

function queryRefundById(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/queryRefundById',
    method: 'post',
    params: query
  });
}

function updateState(data) {
  return (0, _request.default)({
    url: '/bus_app/invoice/updateState',
    method: 'post',
    data: data
  });
}

function updateBill(data) {
  return (0, _request.default)({
    url: '/bus_app/invoice/updateBill',
    method: 'post',
    data: data
  });
}

function fetchExhcangeList(query) {
  return (0, _request.default)({
    url: '/bus_app/exchangeInvoice/list',
    method: 'post',
    params: query
  });
}

function queryExchangeById(query) {
  return (0, _request.default)({
    url: '/bus_app/exchangeInvoice/queryById',
    method: 'post',
    params: query
  });
}

function queryInfoById(query) {
  return (0, _request.default)({
    url: '/bus_app/exchangeInvoice/queryInfoById',
    method: 'post',
    params: query
  });
}

function approve(data) {
  return (0, _request.default)({
    url: '/bus_app/exchangeInvoice/approve',
    method: 'post',
    params: data
  });
}

function approveNo(data) {
  return (0, _request.default)({
    url: '/bus_app/exchangeInvoice/approveNo',
    method: 'post',
    params: data
  });
}

function queryInvoiceByNo(query) {
  return (0, _request.default)({
    url: '/bus_app/invoice/queryInvoiceByNo',
    method: 'post',
    params: query
  });
}

function createTicketOnPc(data) {
  return (0, _request.default)({
    url: '/bus_app/invoice/createTicketOnPc',
    method: 'post',
    data: data
  });
}