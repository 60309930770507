var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-width": "280px", "max-width": "970px" } },
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "FixTop",
            { attrs: { "class-name": "sub-navbar default" } },
            [
              _vm.saleReturnInfo.stateChild === "1" ||
              _vm.saleReturnInfo.stateChild === "2"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.completeSubmit },
                    },
                    [_vm._v("生成提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.saleReturnInfo.stateChild === "1" ||
              _vm.saleReturnInfo.stateChild === "2"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: _vm.tempBtn,
                        type: "primary",
                      },
                      on: { click: _vm.saveSumbit },
                    },
                    [_vm._v("暂存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pageType === "2"
                ? _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.returnBtn } },
                    [_vm._v("返回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.saleReturnInfo,
            "label-width": "120px",
            "label-position": "right",
            size: "small",
          },
        },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "原销售手机号",
                            prop: "salePhone",
                            rules: {
                              required: true,
                              message: "请填写原销售手机号",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                placeholder: "销售手机号",
                                disabled: _vm.isSearch,
                              },
                              model: {
                                value: _vm.saleReturnInfo.salePhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleReturnInfo, "salePhone", $$v)
                                },
                                expression: "saleReturnInfo.salePhone",
                              },
                            },
                            [
                              _vm.isSearch === false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "append",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.phoneSearch },
                                      slot: "append",
                                    },
                                    [_vm._v("查询")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isSearch === true &&
                              (_vm.saleReturnInfo.stateChild === "1" ||
                                _vm.saleReturnInfo.stateChild === "2")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "append",
                                        type: "primary",
                                        icon: "el-icon-delete",
                                      },
                                      on: { click: _vm.clearSearch },
                                      slot: "append",
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { shadow: "hover", hidden: _vm.isList },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("商品列表"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "padding-left": "20px" } }, [
                    _vm._v("   总商品数：" + _vm._s(_vm.saleReturnInfo.amount)),
                  ]),
                  _c("span", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v(
                      "   含税总价: " + _vm._s(_vm.saleReturnInfo.priceBefore)
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.saleReturnInfo.items, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: item.isOver === "1" },
                        on: {
                          change: function (checked) {
                            return _vm.checkRow(checked, item, index)
                          },
                        },
                        model: {
                          value: item.choose,
                          callback: function ($$v) {
                            _vm.$set(item, "choose", $$v)
                          },
                          expression: "item.choose",
                        },
                      },
                      [_vm._v("是否选择此产品")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "saleNo" + index,
                            attrs: {
                              label: "所属销售单",
                              prop: "items." + index + ".saleNo",
                              "show-overflow-tooltip": "",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.saleNo,
                                callback: function ($$v) {
                                  _vm.$set(item, "saleNo", $$v)
                                },
                                expression: "item.saleNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "buyTime" + index,
                            attrs: {
                              label: "支付日期",
                              prop: "items." + index + ".buyTime",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.buyTime,
                                callback: function ($$v) {
                                  _vm.$set(item, "buyTime", $$v)
                                },
                                expression: "item.buyTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "isOver" + index,
                            attrs: {
                              label: "超过质保期",
                              prop: "items." + index + ".isOver",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { disabled: true },
                                model: {
                                  value: item.isOver,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isOver", $$v)
                                  },
                                  expression: "item.isOver",
                                },
                              },
                              _vm._l(
                                _vm.isOverOptions,
                                function (isOver, overIndex) {
                                  return _c("el-option", {
                                    key: overIndex,
                                    attrs: {
                                      label: isOver.name,
                                      value: isOver.val,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "skuCode" + index,
                            attrs: {
                              label: "物料编码",
                              prop: "items." + index + ".skuCode",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.skuCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "skuCode", $$v)
                                },
                                expression: "item.skuCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "skuName" + index,
                            attrs: {
                              label: "物料名称",
                              prop: "items." + index + ".skuName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.skuName,
                                callback: function ($$v) {
                                  _vm.$set(item, "skuName", $$v)
                                },
                                expression: "item.skuName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "payType" + index,
                            attrs: {
                              label: "收费类型",
                              prop: "items." + index + ".payType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { disabled: true },
                                model: {
                                  value: item.payType,
                                  callback: function ($$v) {
                                    _vm.$set(item, "payType", $$v)
                                  },
                                  expression: "item.payType",
                                },
                              },
                              _vm._l(_vm.payType, function (pay, payIndex) {
                                return _c("el-option", {
                                  key: payIndex,
                                  attrs: { label: pay.label, value: pay.val },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "billType" + index,
                            attrs: {
                              label: "发票类型",
                              prop: "items." + index + ".billType",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { value: "纸质发票", disabled: "" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "priceBeforeY" + index,
                            attrs: {
                              label: "含税单价",
                              prop: "items." + index + ".priceBeforeY",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.priceBeforeY,
                                callback: function ($$v) {
                                  _vm.$set(item, "priceBeforeY", $$v)
                                },
                                expression: "item.priceBeforeY",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "buyNum" + index,
                            attrs: {
                              label: "购买数量",
                              prop: "items." + index + ".buyNum",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.buyNum,
                                callback: function ($$v) {
                                  _vm.$set(item, "buyNum", $$v)
                                },
                                expression: "item.buyNum",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            key: "returnNum" + index,
                            attrs: {
                              label: "已退货数",
                              prop: "items." + index + ".returnNum",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.returnNum,
                                callback: function ($$v) {
                                  _vm.$set(item, "returnNum", $$v)
                                },
                                expression: "item.returnNum",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.isOver === "1"
                          ? _c(
                              "el-form-item",
                              {
                                key: "remainderNum" + index,
                                attrs: {
                                  label: "可退货数",
                                  prop: "items." + index + ".remainderNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { value: "0", disabled: "" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "remainderNum" + index,
                                attrs: {
                                  label: "可退货数",
                                  prop: "items." + index + ".remainderNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: item.remainderNum,
                                    callback: function ($$v) {
                                      _vm.$set(item, "remainderNum", $$v)
                                    },
                                    expression: "item.remainderNum",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        !_vm.isDis
                          ? _c(
                              "el-form-item",
                              {
                                key: "reduceNum" + index,
                                attrs: {
                                  label: "本单退货数",
                                  prop: "items." + index + ".reduceNum",
                                  rules: [
                                    {
                                      validator: function (
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          _vm.saleReturnInfo.items[index]
                                            .isDisabled !== undefined &&
                                          !_vm.saleReturnInfo.items[index]
                                            .isDisabled
                                        ) {
                                          if (
                                            value === "" ||
                                            value === null ||
                                            value === undefined
                                          ) {
                                            return callback("请输入")
                                          }
                                          var numRe = new RegExp(
                                            /^\+?[1-9][0-9]*$/
                                          )
                                          if (!numRe.test(value)) {
                                            return callback("请输入整数")
                                          }
                                          if (
                                            _vm.saleReturnInfo.items[index]
                                              .remainderNum === "0"
                                          ) {
                                            if (value > 0) {
                                              return callback(
                                                "不可超过可退货数"
                                              )
                                            }
                                          } else {
                                            if (
                                              value >
                                              _vm.saleReturnInfo.items[index]
                                                .remainderNum
                                            ) {
                                              return callback(
                                                "不可超过可退货数"
                                              )
                                            }
                                          }
                                        } else {
                                        }
                                        callback()
                                      },
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                !_vm.isDis
                                  ? _c("el-input", {
                                      attrs: {
                                        disabled: item.isDisabled,
                                        size: "small",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.numChange()
                                        },
                                      },
                                      model: {
                                        value: item.reduceNum,
                                        callback: function ($$v) {
                                          _vm.$set(item, "reduceNum", $$v)
                                        },
                                        expression: "item.reduceNum",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "reduceNum" + index,
                                attrs: {
                                  label: "本单退货数",
                                  prop: "items." + index + ".reduceNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: item.reduceNum,
                                    callback: function ($$v) {
                                      _vm.$set(item, "reduceNum", $$v)
                                    },
                                    expression: "item.reduceNum",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        !_vm.isDis
                          ? _c(
                              "el-form-item",
                              {
                                key: "reason" + index,
                                attrs: {
                                  label: "本单退货原因",
                                  prop: "items." + index + ".reason",
                                  rules: [
                                    {
                                      validator: function (
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          _vm.saleReturnInfo.items[index]
                                            .isDisabled !== undefined &&
                                          !_vm.saleReturnInfo.items[index]
                                            .isDisabled
                                        ) {
                                          if (
                                            value === "" ||
                                            value === null ||
                                            value === undefined
                                          ) {
                                            return callback("请选择")
                                          }
                                        }
                                        callback()
                                      },
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      align: "center",
                                      disabled: item.isDisabled,
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.reason,
                                      callback: function ($$v) {
                                        _vm.$set(item, "reason", $$v)
                                      },
                                      expression: "item.reason",
                                    },
                                  },
                                  _vm._l(
                                    _vm.reasonList,
                                    function (reason, idx) {
                                      return _c("el-option", {
                                        key: idx,
                                        attrs: {
                                          label: reason.label,
                                          value: reason.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "reason" + index,
                                attrs: {
                                  label: "本单退货原因",
                                  prop: "items." + index + ".reason",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: item.reason,
                                    callback: function ($$v) {
                                      _vm.$set(item, "reason", $$v)
                                    },
                                    expression: "item.reason",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        !_vm.isDis
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "退货备注",
                                  prop: "items." + index + ".memo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: item.isDisabled,
                                    size: "small",
                                  },
                                  model: {
                                    value: item.memo,
                                    callback: function ($$v) {
                                      _vm.$set(item, "memo", $$v)
                                    },
                                    expression: "item.memo",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "memo" + index,
                                attrs: {
                                  label: "退货备注",
                                  prop: "'items.'+index+'.memo'",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true, size: "small" },
                                  model: {
                                    value: item.memo,
                                    callback: function ($$v) {
                                      _vm.$set(item, "memo", $$v)
                                    },
                                    expression: "item.memo",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("寄件信息"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "寄件人",
                            prop: "sendUser",
                            rules: {
                              required: true,
                              message: "请填写寄件人",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: _vm.isDis,
                              placeholder: "寄件人",
                            },
                            model: {
                              value: _vm.saleReturnInfo.sendUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleReturnInfo, "sendUser", $$v)
                              },
                              expression: "saleReturnInfo.sendUser",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "寄件手机号",
                            prop: "sendPhone",
                            rules: {
                              required: true,
                              message: "请填写寄件手机号",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              placeholder: "寄件手机号",
                              disabled: _vm.isDis,
                            },
                            model: {
                              value: _vm.saleReturnInfo.sendPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleReturnInfo, "sendPhone", $$v)
                              },
                              expression: "saleReturnInfo.sendPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "省",
                            prop: "province",
                            rules: {
                              required: true,
                              message: "请选择省",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择省",
                                filterable: "",
                                clearable: "",
                                disabled: _vm.isDis,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.proviceChange(
                                    _vm.saleReturnInfo.province
                                  )
                                },
                              },
                              model: {
                                value: _vm.saleReturnInfo.province,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleReturnInfo, "province", $$v)
                                },
                                expression: "saleReturnInfo.province",
                              },
                            },
                            _vm._l(_vm.provinceOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "市",
                            prop: "city",
                            rules: {
                              required: true,
                              message: "请选择市",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择市",
                                filterable: "",
                                clearable: "",
                                disabled: _vm.isDis,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.cityChange(_vm.saleReturnInfo.city)
                                },
                              },
                              model: {
                                value: _vm.saleReturnInfo.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleReturnInfo, "city", $$v)
                                },
                                expression: "saleReturnInfo.city",
                              },
                            },
                            _vm._l(_vm.cityOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "区/县",
                            prop: "country",
                            rules: {
                              required: true,
                              message: "请选择区/县",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择区",
                                filterable: "",
                                disabled: _vm.isDis,
                                clearable: "",
                              },
                              model: {
                                value: _vm.saleReturnInfo.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleReturnInfo, "country", $$v)
                                },
                                expression: "saleReturnInfo.country",
                              },
                            },
                            _vm._l(_vm.countryOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "寄件地址",
                            prop: "sendAddress",
                            rules: {
                              required: true,
                              message: "寄件地址",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: {
                              disabled: _vm.isDis,
                              placeholder: "寄件地址",
                            },
                            model: {
                              value: _vm.saleReturnInfo.sendAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleReturnInfo, "sendAddress", $$v)
                              },
                              expression: "saleReturnInfo.sendAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮寄方式", prop: "urgent" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDis },
                              on: { change: _vm.sendTypeClick },
                              model: {
                                value: _vm.saleReturnInfo.sendType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleReturnInfo, "sendType", $$v)
                                },
                                expression: "saleReturnInfo.sendType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("顺丰上门"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("自寄"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { hidden: _vm.isSf } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预约取件日期",
                                prop: "bookDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.isDis,
                                  "picker-options": _vm.pickerOptions,
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "预约取件日期",
                                },
                                on: { change: _vm.changeBookDate },
                                model: {
                                  value: _vm.saleReturnInfo.bookDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.saleReturnInfo,
                                      "bookDate",
                                      $$v
                                    )
                                  },
                                  expression: "saleReturnInfo.bookDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预约取件时间",
                                prop: "bookClock",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.isDis,
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择时间段",
                                  },
                                  on: { change: _vm.changeBookClock },
                                  model: {
                                    value: _vm.saleReturnInfo.bookClock,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saleReturnInfo,
                                        "bookClock",
                                        $$v
                                      )
                                    },
                                    expression: "saleReturnInfo.bookClock",
                                  },
                                },
                                _vm._l(
                                  _vm.bookTimePerList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.val,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { hidden: _vm.isSf } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "顺丰备注", prop: "expressMemo" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入内容",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                  disabled: _vm.isDis,
                                },
                                model: {
                                  value: _vm.saleReturnInfo.expressMemo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.saleReturnInfo,
                                      "expressMemo",
                                      $$v
                                    )
                                  },
                                  expression: "saleReturnInfo.expressMemo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("请确认是否生成提交销售退货单 ")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirmToOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }