"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.replace");

var _user = require("@/api/user");

var _auth = require("../../utils/auth");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
var _default = {
  name: 'SSO',
  data: function data() {
    return {
      loginForm: {
        username: '',
        password: '',
        ticket: ''
      },
      reqData: {
        retPath: null
      }
    };
  },
  created: function created() {
    var _this = this;

    var id_token = this.getUrlKey('id_token');
    var retPath = this.getUrlKey('target_url');

    if (id_token) {
      this.$store.dispatch('user/IDaaSLogin', id_token).then(function () {
        var infoId = _this.getUrlKey('id') || '';

        if (retPath != null) {
          location.href = retPath + '?dialogStatus=update&id=' + infoId;
        } else {
          // 跳转到首页
          _this.$router.push({
            path: '/'
          });
        } // 清除tags缓存


        _this.$store.dispatch('tagsView/delAllViews').then(function () {});

        _this.$message({
          message: '欢迎回来',
          type: 'success'
        });

        _this.loading = false;
      }).catch(function () {
        _this.$message({
          message: '授权失败,请先在IDaaS平台授权！',
          type: 'error'
        });

        _this.loading = false;
      });
    }
  },
  methods: {
    getUrlKey: function getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    }
  }
};
exports.default = _default;