"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createData = createData;
exports.findPageList = findPageList;
exports.getBaseGroupByType = getBaseGroupByType;
exports.queryById = queryById;
exports.updateData = updateData;
exports.updateStatus = updateStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function findPageList(data) {
  return (0, _request.default)({
    url: '/bus_app/base_group/list',
    method: 'post',
    data: data
  });
}

function createData(data) {
  return (0, _request.default)({
    url: '/bus_app/base_group/create',
    method: 'post',
    data: data
  });
}

function updateData(data) {
  return (0, _request.default)({
    url: '/bus_app/base_group/update',
    method: 'post',
    data: data
  });
}

function updateStatus(params) {
  return (0, _request.default)({
    url: '/bus_app/base_group/updateStatus',
    method: 'post',
    params: params
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/base_group/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 根据任务类型查询小组列表 */


function getBaseGroupByType(data) {
  return (0, _request.default)({
    url: '/bus_app/base_group/getBaseGroupByType',
    method: 'post',
    data: data
  });
}