"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IDaaSLogin = IDaaSLogin;
exports.login = login;
exports.logout = logout;
exports.permission = permission;

var _request = _interopRequireDefault(require("@/utils/request"));

var _auth = require("@/utils/auth");

var _qs = _interopRequireDefault(require("qs"));

/**
 * 登入处理
 * @param {username:string,password:string} data 账号密码信息
 */
function login(data) {
  var param = _qs.default.stringify({
    x: (0, _auth.Encrypt)(data.username),
    y: (0, _auth.Encrypt)(data.password)
  });

  return (0, _request.default)({
    url: '/auth/oauth/login',
    method: 'post',
    data: param
  });
}
/**
 * IDaaS单点登入处理
 * @param id_token:string  IDaaS信息
 */


function IDaaSLogin(id_token) {
  var param = _qs.default.stringify({
    id_token: id_token
  });

  return (0, _request.default)({
    url: '/auth/oauth/IDaaSLogin',
    method: 'post',
    data: param
  });
}
/**
 * 登出处理
 */


function logout() {
  return (0, _request.default)({
    url: '/auth/oauth/logout',
    method: 'post'
  });
}
/**
 * 根据访问令牌加载用户信息
 */


function permission() {
  return (0, _request.default)({
    url: '/acc_app/auth/info',
    method: 'post'
  });
}