var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _vm.saleInfo.num
                    ? _c("new-task", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["ROLE_WORK_TASK_MY_LIST"],
                            expression: "['ROLE_WORK_TASK_MY_LIST']",
                          },
                        ],
                        attrs: {
                          "work-id": _vm.saleInfo.id,
                          "work-no": _vm.saleInfo.num,
                          "work-type": "3",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.saleInfo.state === "S1" || _vm.saleInfo.state === "") &&
                  (_vm.saleInfo.stateChild === "1" ||
                    _vm.saleInfo.stateChild === "2") &&
                  !_vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_ORDER_COMPLETE"],
                              expression: "['ROLE_SALE_ORDER_COMPLETE']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.completeSubmit },
                        },
                        [_vm._v("生成提交")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.saleInfo.state === "S1" || _vm.saleInfo.state === "") &&
                  (_vm.saleInfo.stateChild === "1" ||
                    _vm.saleInfo.stateChild === "2") &&
                  !_vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_ORDER_TEMP"],
                              expression: "['ROLE_SALE_ORDER_TEMP']",
                            },
                          ],
                          attrs: { disabled: _vm.tempBtn, type: "primary" },
                          on: { click: _vm.saveSumbit },
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCancel
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_SALE_ORDER_CANCEL"],
                              expression: "['ROLE_SALE_ORDER_CANCEL']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.updateCancel },
                        },
                        [_vm._v("服务取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_SALE_ORDER_SMS"],
                          expression: "['ROLE_SALE_ORDER_SMS']",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        disabled: !(_vm.saleInfo.stateChild === "3"),
                      },
                      on: { click: _vm.sendSms },
                    },
                    [_vm._v("支付短信")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: { model: _vm.saleInfo, "label-width": "150px" },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("收件信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件人",
                                            prop: "takeUser",
                                            rules: {
                                              required: true,
                                              message: "请填写收件人",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件人",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takeUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeUser",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takeUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件手机号",
                                            prop: "takePhone",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请填写收件手机号",
                                                trigger: "blur",
                                              },
                                              {
                                                pattern: /^1[123456789]\d{9}$/,
                                                message: "请输入正确的手机号码",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件手机号",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takePhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takePhone",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takePhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "省",
                                          prop: "province",
                                          rules: {
                                            required: true,
                                            message: "请选择省",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.proviceChange(
                                                  _vm.saleInfo.province
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.saleInfo.province,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "province",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.province",
                                            },
                                          },
                                          _vm._l(
                                            _vm.provinceOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "市",
                                          prop: "city",
                                          rules: {
                                            required: true,
                                            message: "请选择市",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择市",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.cityChange(
                                                  _vm.saleInfo.city
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.saleInfo.city,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.city",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cityOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "区/县",
                                          prop: "country",
                                          rules: {
                                            required: true,
                                            message: "请选择区/县",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择区",
                                              filterable: "",
                                              disabled: _vm.isDis,
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.saleInfo.country,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.country",
                                            },
                                          },
                                          _vm._l(
                                            _vm.countryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件地址",
                                            prop: "takeAddress",
                                            rules: {
                                              required: true,
                                              message: "收件地址",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件地址",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takeAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.takeAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("销售产品")]
                            ),
                            _vm._v(" 　　"),
                            (_vm.saleInfo.state === "S1" ||
                              _vm.saleInfo.state === "") &&
                            (_vm.saleInfo.stateChild === "1" ||
                              _vm.saleInfo.stateChild === "2")
                              ? _c("el-button", {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-plus",
                                    size: "mini",
                                    circle: "",
                                  },
                                  on: { click: _vm.addDomain },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              _vm._l(
                                _vm.saleInfo.items,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-container",
                                        [
                                          _c(
                                            "el-aside",
                                            { attrs: { width: "20%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "block" },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      "margin-top": "70px",
                                                    },
                                                    attrs: {
                                                      src: item.src,
                                                      "preview-src-list": [
                                                        item.src,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: !_vm.isDis,
                                                      expression: "!isDis",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grid-content bg-purple",
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                label:
                                                                  "物料标识",
                                                                prop: "skuTag",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    filterable:
                                                                      "",
                                                                    clearable:
                                                                      "",
                                                                    placeholder:
                                                                      "请选择",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.tagChange(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.skuTag,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "skuTag",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.skuTag",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.tagOptions,
                                                                  function (
                                                                    tag,
                                                                    index2
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: index2,
                                                                        attrs: {
                                                                          label:
                                                                            tag.label,
                                                                          value:
                                                                            tag.value,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "category" + index,
                                                          attrs: {
                                                            label: "品类",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".categoryId",
                                                          },
                                                        },
                                                        [
                                                          _c("tree-select", {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              options:
                                                                _vm.tableData1,
                                                              disabled:
                                                                _vm.isDis,
                                                              accordion:
                                                                _vm.accordion,
                                                              clearable:
                                                                _vm.cTree,
                                                              placeholder:
                                                                "请选择品类",
                                                              value:
                                                                item.categoryId,
                                                            },
                                                            on: {
                                                              getValue:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.queryTreeSelectNodeKey(
                                                                    $event,
                                                                    index
                                                                  )
                                                                },
                                                              checkChange:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.categoryChange(
                                                                    $event,
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "model" + index,
                                                          attrs: {
                                                            label: "型号",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".modelId",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择型号",
                                                                disabled:
                                                                  _vm.isDis,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modelChange(
                                                                      index
                                                                    )
                                                                  },
                                                                "visible-change":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$forceUpdate()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.modelId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "modelId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.modelId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.modelSelect[
                                                                index
                                                              ],
                                                              function (items) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: items.id,
                                                                    attrs: {
                                                                      disabled:
                                                                        items.isEnable !==
                                                                        "1",
                                                                      label:
                                                                        items.name,
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "sku" + index,
                                                          attrs: {
                                                            label: "物料选择",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".skuId",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择部件",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                disabled:
                                                                  _vm.isDis,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.skuChange(
                                                                      index
                                                                    )
                                                                  },
                                                                "visible-change":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$forceUpdate()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.skuId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "skuId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.skuId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.skuList[
                                                                index
                                                              ],
                                                              function (items) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: items.id,
                                                                    attrs: {
                                                                      label:
                                                                        items.code +
                                                                        "  " +
                                                                        items.name,
                                                                      disabled:
                                                                        items.isEnable !==
                                                                        "1",
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            float:
                                                                              "left",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            items.code
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            float:
                                                                              "right",
                                                                            color:
                                                                              "#8492a6",
                                                                            "font-size":
                                                                              "13px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            items.name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "skuName" + index,
                                                          attrs: {
                                                            label: "物料名称",
                                                            prop:
                                                              "skuName" + index,
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.skuName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "skuName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.skuName",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "skuCode" + index,
                                                          attrs: {
                                                            label: "物料编码",
                                                            prop:
                                                              "skuCode" + index,
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.skuCode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "skuCode",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.skuCode",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "brandId" + index,
                                                          attrs: {
                                                            label: "品牌",
                                                            prop:
                                                              "brandId" + index,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "filter-item",
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled: true,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.brandId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "brandId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.brandId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.brandSelect,
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.name,
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "num" + index,
                                                          attrs: {
                                                            label: "购买数量",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".num",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请填写数量",
                                                                trigger: "blur",
                                                              },
                                                              {
                                                                pattern:
                                                                  /^(([1-9]+\d*)|0)$/,
                                                                message:
                                                                  "请正确填写整数",
                                                              },
                                                              {
                                                                validator:
                                                                  function (
                                                                    rule,
                                                                    value,
                                                                    callback
                                                                  ) {
                                                                    if (
                                                                      value ===
                                                                      0
                                                                    ) {
                                                                      return callback(
                                                                        "不可等于0"
                                                                      )
                                                                    } else {
                                                                      if (
                                                                        value >
                                                                          1 &&
                                                                        _vm
                                                                          .saleInfo
                                                                          .isTradeIn ===
                                                                          "1"
                                                                      ) {
                                                                        return callback(
                                                                          "升级优惠购买数量不得>1"
                                                                        )
                                                                      }
                                                                      if (
                                                                        value >
                                                                        10
                                                                      ) {
                                                                        return callback(
                                                                          "单商品最多购买10个"
                                                                        )
                                                                      }
                                                                      callback()
                                                                    }
                                                                  },
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.isDis,
                                                            },
                                                            on: {
                                                              blur: _vm.numChange,
                                                            },
                                                            model: {
                                                              value: item.num,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "num",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.num",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "level1" + index,
                                                          attrs: {
                                                            label: "库存一",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".level1",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.level1,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "level1",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.level1",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "level2" + index,
                                                          attrs: {
                                                            label: "库存二",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".level2",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.level2,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "level2",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.level2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "level3" + index,
                                                          attrs: {
                                                            label: "库存三",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".level3",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.level3,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "level3",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.level3",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "payType" + index,
                                                          attrs: {
                                                            label: "收费类型",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".payType",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择收费类型",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDis,
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.payTypeChange(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.payType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "payType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.payType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.payType[
                                                                index
                                                              ],
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.label,
                                                                      value:
                                                                        items.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "priceAfterY" +
                                                            index,
                                                          attrs: {
                                                            label: "税前单价",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".priceAfterY",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.priceAfterY,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "priceAfterY",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.priceAfterY",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "describes" + index,
                                                          attrs: {
                                                            label: "订购原因",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".describes",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择商品描述",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDis,
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.describes,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "describes",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.describes",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.describeList[
                                                                index
                                                              ],
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.label,
                                                                      value:
                                                                        items.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "priceBeforeY" +
                                                            index,
                                                          attrs: {
                                                            label: "含税单价",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".priceBeforeY",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.priceBeforeY,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "priceBeforeY",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.priceBeforeY",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "memo" + index,
                                                          attrs: {
                                                            label: "订单备注",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".memo",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.isDis,
                                                            },
                                                            model: {
                                                              value: item.memo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "memo",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.memo",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 7 } },
                                                    [
                                                      _vm._v(
                                                        "　\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  (_vm.saleInfo.state ===
                                                    "S1" ||
                                                    _vm.saleInfo.state ===
                                                      "") &&
                                                  (_vm.saleInfo.stateChild ===
                                                    "1" ||
                                                    _vm.saleInfo.stateChild ===
                                                      "2") &&
                                                  index != 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.removeDomain(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("购物车")]
                            ),
                            _vm._v(" 　　\n              "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "总商品数",
                                            prop: "amount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "总数量",
                                            },
                                            model: {
                                              value: _vm.saleInfo.amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "amount",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "税前总价",
                                            prop: "priceAfter",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "税前总价",
                                            },
                                            model: {
                                              value: _vm.saleInfo.priceAfter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "priceAfter",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.priceAfter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "含税总价",
                                            prop: "priceBefore",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "含税总价",
                                            },
                                            model: {
                                              value: _vm.saleInfo.priceBefore,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "priceBefore",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.priceBefore",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("SAP信息")]
                            ),
                            _vm._v(" 　　\n              "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "物流公司",
                                            prop: "amount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.saleInfo.expressCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "expressCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.expressCompany",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "物流单号",
                                            prop: "priceAfter",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.saleInfo.expressNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "expressNo",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.expressNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发货时间",
                                            prop: "priceBefore",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.saleInfo.deliveryDateStr,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "deliveryDateStr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.deliveryDateStr",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "SAP单号",
                                            prop: "spaNo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value: _vm.saleInfo.spaNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "spaNo",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.spaNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发货库位",
                                            prop: "spaNo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.saleInfo.items[0].storage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo.items[0],
                                                  "storage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.items[0].storage",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isDis,
                            expression: "isDis",
                          },
                        ],
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("销售单发货信息")]
                            ),
                            _vm._v(" 　　\n              "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                attrs: { shadow: "never" },
                              },
                              [
                                _c("sf-route-state", {
                                  ref: "sfInfoForm",
                                  attrs: { num: _vm.saleInfo.num },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否确认取消服务?",
            visible: _vm.dialogVisible2,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm2",
              attrs: {
                model: _vm.saleInfo2,
                rules: _vm.rules2,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "取消原因", prop: "cancelReason" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.saleInfo2.cancelReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo2, "cancelReason", $$v)
                                },
                                expression: "saleInfo2.cancelReason",
                              },
                            },
                            _vm._l(_vm.cancelReason, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "取消备注" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.saleInfo2.cancelMemo,
                              callback: function ($$v) {
                                _vm.$set(_vm.saleInfo2, "cancelMemo", $$v)
                              },
                              expression: "saleInfo2.cancelMemo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.comfirmB },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("请确认是否生成提交销售单 ")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.completeBtn },
                  on: { click: _vm.comfirmToOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: { click: _vm.orderCancel },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible8,
            width: "30%",
            center: "",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible8 = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialog8Msg))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.comfirmTo } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible10,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible10 = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialog10Msg))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.comfirmToOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: { click: _vm.orderCancel },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }