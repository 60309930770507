"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _request = require("@/utils/request1");

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _checkHistoryList = _interopRequireDefault(require("@/components/order/checkHistoryList"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 历史检测信息
// 下拉框 树形结构 可检索组件
// 外呼信息
// 费用明细
// 新建任务工单
var _default = {
  name: 'orderCheckDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCheckHistoryList: _checkHistoryList.default,
    treeSelect: _treeSelect.default,
    orderCallout: _calloutList.default,
    orderPrice: _checkPrice.default,
    NewTask: _NewTask.default
  },
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    feeTypeFilter1: _filterUtils.feeTypeFilter1,
    feeOptionFilter: _filterUtils.feeOptionFilter,
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter
  },
  data: function data() {
    // 自定义校验：金额位数小于千万
    var validateNumberMax = function validateNumberMax(rule, value, callback) {
      if (value && value !== '' && parseInt(value) > 9999999) {
        callback('限制数值小于10000000');
      } else {
        callback();
      }
    }; // 自定义校验：不为空或空格


    var validateBlank = function validateBlank(rule, value, callback) {
      if (!value || value.trim() === '') {
        callback('请输入有效信息');
      } else {
        callback();
      }
    };

    return {
      btnLoading: false,
      id: this.$route.params.id,
      step: '3',
      consumerShow: '1',
      productShow: '1',
      checkHisShow: '0',
      calloutShow: '0',
      isReadOnly: false,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      checkResultOptions: _constant.default.checkResultOptions,
      checkReasonOptions: _constant.default.checkReasonOptions,
      feeTypeOptions: _constant.default.feeTypeOptions,
      feeTypeOptions1: _constant.default.feeTypeOptions1,
      feeOptionOptions: _constant.default.feeOptionOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      rules: {
        checkType: [{
          required: true,
          message: '检测服务类型是必填的',
          trigger: 'blur'
        }],
        checkSubType: [{
          required: true,
          message: '检测服务子类型是必填的',
          trigger: 'blur'
        }],
        checkReason: [{
          required: true,
          message: '换货原因是必填的',
          trigger: 'blur'
        }],
        checkResult: [{
          required: true,
          message: '检测结果是必填的',
          trigger: 'blur'
        }],
        isRepair: [{
          required: true,
          message: '是否创建清洁维修单是必填的',
          trigger: 'blur'
        }],
        checkRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }],
        serviceFeeId: [{
          required: true,
          message: '用户收费是必填的',
          trigger: 'blur'
        }],
        checkUseTime: [{
          pattern: /^(([1-9]+\d*)|0)$/,
          message: '请正确填写整数'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }]
      },
      userfeeSelect: [],
      categorySelectTree: [],
      categorySelect: [],
      modelSelect: [],
      // ----------------------------------------------------------------------------------------------------------------------------------
      schemeAdd: {
        problemType: undefined,
        faultId: undefined,
        repairIds: [],
        problem: undefined,
        resolution: undefined,
        step: '3'
      },
      addSchemeQ: false,
      addSchemeA: false,
      schemeList: [],
      schemeListLoading: false,
      // <下拉数组> 维修分类
      repairTypeSelect: [],
      faultSelect: [],
      repairSelect: [],
      schemeAddrules: {
        problemType: [{
          required: true,
          message: '问题分类是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }],
        problem: [{
          required: true,
          message: '其他问题代码是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }],
        resolution: [{
          required: true,
          message: '其他处理方式是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3',
        feeOption: '10'
      },
      skuAdd1: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3'
      },
      skuFeeAdd: {
        feeType: undefined,
        serviceFeeId: undefined,
        feeOption: '10'
      },
      skuList: [],
      // 缺料
      skuList1: [],
      skuFeeList: [],
      skuListLoading: false,
      skuFeeListLoading: false,
      // 费用明细是否显示
      orderPriceShow: false,
      // 服务sku或部件是否修改过
      orderPriceIsModify: true,
      skuSelect: [],
      // 缺料
      skuSelect1: [],
      refDateBefore: '' // ----------------------------------------------------------------------------------------------------------------------------------

    };
  },
  computed: {
    confirmBtnStatus: function confirmBtnStatus() {
      return ['S203', 'S300', 'S301', 'S302', 'S317'].includes(this.orderInfo.subState);
    },
    showPriceItem: function showPriceItem() {
      return ['S203', 'S301'].includes(this.orderInfo.subState);
    },
    confirmBtnStatuss: function confirmBtnStatuss() {
      return ['0102', '0103', '0203'].includes(this.orderInfo.checkSubType);
    },
    schemeAddBtnDisabled: function schemeAddBtnDisabled() {
      return !this.schemeAdd.faultId || this.schemeAdd.faultId === '' || this.schemeAdd.repairIds.length === 0;
    },
    cleanStatus: function cleanStatus() {
      return ['0', '1'].includes(this.orderInfo.cleanType);
    },
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        return !this.skuAdd.feeType || this.skuAdd.feeType === '' || !this.skuAdd.skuCode || this.skuAdd.skuCode === '';
      } else if (this.orderInfo.checkType === '02') {
        return this.skuList.length > 0 || this.skuList.length === 0 && (!this.skuAdd.skuCode || this.skuAdd.skuCode === '');
      } else {
        return true;
      }
    },
    skuAddBtnDisabled1: function skuAddBtnDisabled1() {
      if (this.orderInfo.checkReason === '3' || this.orderInfo.checkReason === '5') {
        return !this.skuAdd1.feeType || this.skuAdd1.feeType === '' || this.skuAdd1.isLack === '' || !this.skuAdd1.skuCode || this.skuAdd1.skuCode === '';
      } else {
        return true;
      }
    },
    skuFeeAddBtnDisabled: function skuFeeAddBtnDisabled() {
      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        return !this.skuFeeAdd.feeType || this.skuFeeAdd.feeType === '' || !this.skuFeeAdd.serviceFeeId || this.skuFeeAdd.serviceFeeId === '';
      } else if (this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203') {
        return !this.skuFeeAdd.feeType || this.skuFeeAdd.feeType === '' || !this.skuFeeAdd.serviceFeeId || this.skuFeeAdd.serviceFeeId === '';
      } else {
        return true;
      }
    },
    checkType: function checkType() {
      return this.orderInfo.checkType;
    },
    checkSubType: function checkSubType() {
      return this.orderInfo.checkSubType;
    },
    faultId4schemeAdd: function faultId4schemeAdd() {
      return this.schemeAdd.faultId;
    },
    repairIds4schemeAdd2: function repairIds4schemeAdd2() {
      return this.schemeAdd.repairIds;
    },
    // -----------------------------------------------------------------------------
    category: function category() {
      return this.skuAdd.category;
    },
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    } // -----------------------------------------------------------------------------

  },
  watch: {
    checkType: function checkType(val, oldval) {
      this.orderPriceShow = false;
      this.orderPriceIsModify = true;
      this.checkSubTypeChange(val, oldval);
    },
    checkSubType: function checkSubType(val, oldval) {
      if (oldval && val !== oldval) {
        this.orderPriceShow = false;
        this.orderPriceIsModify = true;
        this.orderInfo.serviceFeeId = '';
      }

      if (val === '0201') {
        this.orderInfo.checkReason = '0';
      } else if (val === '0202') {
        this.orderInfo.checkReason = '1';
      }
    },
    checkReason: function checkReason(val, oldval) {
      this.orderPriceShow = false;
      this.orderPriceIsModify = true;
      this.orderInfo.checkReason = val;
    },
    // -----------------------------------------------------------------------------------------------------------------
    faultId4schemeAdd: function faultId4schemeAdd(val, oldval) {
      this.addSchemeQ = val === '其他';

      if (oldval && val !== oldval) {
        this.schemeAdd.problem = '';
        this.schemeAdd.repairIds = [];

        if (val === '') {
          this.schemeAdd.repairIds = [];
        }
      }
    },
    repairIds4schemeAdd2: function repairIds4schemeAdd2(val, oldval) {
      this.addSchemeA = val && val.includes('其他');

      if (oldval && val !== oldval) {
        this.schemeAdd.resolution = '';
      }
    },
    // -----------------------------------------------------------------------------
    category: function category(val, oldval) {
      this.categorySelectChange(val, oldval);
    },
    search: function search(val) {
      this.list = this.oTableData.filter(function (item) {
        return ~item.name.indexOf(val);
      });
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    },
    // -----------------------------------------------------------------------------
    checkHisShow: function checkHisShow(val, oldval) {
      this.loadcheckHistoryList(val, oldval);
    }
  },
  created: function created() {
    var _this = this;

    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
      this.loadSchemeList();
      this.loadOrderSkuFeeList();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);
      });
    }
  },
  methods: {
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.skuAdd.category = value;
    },
    setUploadFileList: function setUploadFileList(data) {
      this.orderInfo.uploadFiles = data;
    },
    setBtnsState: function setBtnsState(btnState) {
      this.btnLoading = btnState;
    },
    checkSubTypeChange: function checkSubTypeChange(val, oldval) {
      var _this2 = this;

      if (oldval && val !== oldval) {
        this.skuList.length = 0;
        this.skuFeeList.length = 0;
        this.orderInfo.checkSubType = '';
        this.orderInfo.checkResult = '';
        this.orderInfo.serviceFeeId = '';
        this.skuAdd.skuCode = '';
        this.skuAdd.feeType = '';
        this.skuAdd.category = '';
        this.skuAdd.model = '';
      }

      if (val === '01') {
        this.businessTypeOptions = _constant.default.businessTypeOptions4nocheck;
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions2;
        this.checkResultOptions = _constant.default.checkResultOptions;
        this.orderInfo.checkReason = '';
        this.rules.checkReason = [];
      } else if (val === '02') {
        if (this.skuList.length === 0 || oldval && val !== oldval) {
          var newSkuItem = {};
          newSkuItem.orderId = this.orderInfo.id;
          newSkuItem.orderNo = this.orderInfo.orderNo;
          newSkuItem.quantity = 1;
          var skuCode = null;

          if (this.orderInfo.orderSkuList != null && this.orderInfo.orderSkuList.length > 0) {
            skuCode = this.orderInfo.orderSkuList[0].skuCode;
          } else {
            skuCode = this.orderInfo.sku;
          }

          (0, _select.getModeBySku)({
            code: skuCode,
            takeCity: this.orderInfo.takeCity
          }).then(function (response) {
            if (response.data) {
              newSkuItem.skuId = response.data.skuId;
              newSkuItem.skuCode = response.data.code;
              newSkuItem.skuName = response.data.name;
              newSkuItem.skuType = response.data.type;
              newSkuItem.skuTag = response.data.skuTag;
              newSkuItem.priceExcludeTax = response.data.priceExcludeTax;
              newSkuItem.priceIncludeTax = response.data.priceIncludeTax;
              newSkuItem.brandId = response.data.brandId;
              newSkuItem.categoryId = response.data.categoryId;
              newSkuItem.categoryName = response.data.categoryName;
              newSkuItem.modelId = response.data.modelId;
              newSkuItem.modelName = response.data.modelName;
              newSkuItem.ddirStock = response.data.ddirStock;
              newSkuItem.chargeType = '01';
              newSkuItem.feeType = '01';
              newSkuItem.stock = response.data.stock;
              newSkuItem.isRetail = response.data.isRetail;

              if (_this2.skuList.length === 0) {
                _this2.skuList.push(newSkuItem);

                var indexNum = 0;

                _this2.skuList.map(function (v) {
                  _this2.$set(v, 'indexNum', ++indexNum); // 动态加字段


                  _this2.$set(v, 'edit', false); // 动态加字段


                  return v;
                });
              } else {
                _this2.skuList[0].ddirStock = response.data.ddirStock;
              }

              _this2.$forceUpdate();
            }
          });
        }

        this.rules.checkReason = [{
          required: true,
          message: '换货原因是必填的',
          trigger: 'blur'
        }];
        this.businessTypeOptions = _constant.default.businessTypeOptions4nocheck;
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions3;
        this.checkResultOptions = _constant.default.checkResultOptions;
      } else if (val === '03') {
        this.businessTypeOptions = _constant.default.businessTypeOptions4check;
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions4;
        this.orderInfo.checkSubType = '0301';
        this.checkResultOptions = _constant.default.checkResultOptions4checkOnly;
      } else {
        this.businessSubTypeOptions = [];
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    loadcheckHistoryList: function loadcheckHistoryList(val, oldVal) {
      if (this.$refs.checkHistoryList && (!oldVal || val === '1')) {
        this.$refs.checkHistoryList.$emit('loadcheckHistoryList', this.id);
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    loadRepairTypeSelect: function loadRepairTypeSelect() {
      var _this3 = this;

      var params = {
        isEnable: '1',
        categoryId: this.orderInfo.category
      };
      (0, _select.getCodeTypeSelect)('fault', params).then(function (res) {
        _this3.repairTypeSelect = res.data.records;
      });
      this.schemeAdd.faultId = '';
      this.faultSelect = [];
    },
    loadFaultSelect: function loadFaultSelect() {
      var _this4 = this;

      var params = {
        typeId: this.schemeAdd.problemType,
        categoryId: this.orderInfo.category,
        isEnable: '1'
      };
      (0, _select.getFaultSelect)(params).then(function (res) {
        _this4.faultSelect = res.data;

        _this4.faultSelect.push({
          'id': '其他',
          'name': '其他'
        });
      });
    },
    loadRepairSelect: function loadRepairSelect() {
      var _this5 = this;

      if (this.schemeAdd.faultId && this.schemeAdd.faultId !== '') {
        var faultId = this.schemeAdd.faultId;

        if (this.schemeAdd.faultId === '其他') {
          faultId = undefined;
        }

        var params = {
          categoryId: this.orderInfo.category,
          faultId: faultId,
          isEnable: '1'
        };
        (0, _select.getRepairSelect)(params).then(function (res) {
          _this5.repairSelect = res.data;

          _this5.repairSelect.push({
            'id': '其他',
            'name': '其他'
          });
        });
      } else {
        this.repairSelect = [];
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    initSelect: function initSelect() {
      var _this6 = this;

      // 维修分类下拉
      var params = {};

      if (!this.id) {
        params.isEnable = '1';
      }

      (0, _select.getCodeTypeSelect)('fault', params).then(function (res) {
        _this6.repairTypeSelect = res.data.records;
      }); // 维修分类下拉

      var params1 = {};

      if (this.skuFeeAdd.feeType === '08') {
        params1.isEnable = '1';
        params1.applyRights = '1';
      }

      (0, _select.getSkuFeeSelect)(params1).then(function (res) {
        _this6.userfeeSelect = res.data;
      });
      (0, _select.getCategorySelect)({
        isEnable: '1'
      }).then(function (res) {
        _this6.categorySelect = res.data.records;
      });
      this.loadModelSelect();
      this.loadSkuSelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this7 = this;

      var params = {
        categoryId: this.skuAdd.category,
        isEnable: '1'
      };
      (0, _select.getModelSelect)(params).then(function (res) {
        _this7.modelSelect = res.data;
      });
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this8 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };

      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        params.modelId = this.orderInfo.model;
        params.tag = '2';
      } else if (this.orderInfo.checkType === '02') {
        params.categoryId = this.skuAdd.category;
        params.modelId = this.skuAdd.model;
        params.tag = '1';
      }

      (0, _select.getSkuSelectTrue)(params).then(function (res) {
        _this8.skuSelect = res.data;
      });
    },
    loadSkuSelect1: function loadSkuSelect1() {
      var _this9 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };
      params.modelId = this.orderInfo.model;
      params.tag = '2';
      (0, _select.getSkuSelectTrue)(params).then(function (res) {
        _this9.skuSelect1 = res.data;
      });
    },
    loadUserfeeSelect: function loadUserfeeSelect() {
      var _this10 = this;

      // 维修分类下拉
      var params1 = {};

      if (this.skuFeeAdd.feeType === '08') {
        params1.isEnable = '1';
        params1.applyRights = '1';
      }

      (0, _select.getSkuFeeSelect)(params1).then(function (res) {
        _this10.userfeeSelect = res.data.map(function (v) {
          if (_this10.skuFeeAdd.feeType === '08' && _this10.orderInfo.isFreeClean !== '1') {
            _this10.$set(v, 'isEnable', '0'); // 动态加字段

          }

          return v;
        });
      });
    },
    categorySelectChange: function categorySelectChange(val, oldval) {
      if (oldval !== undefined && (!val || val !== oldval)) {
        this.skuAdd.model = '';
        this.modelSelect = [];
        this.modelSelectChange();
      }
    },
    modelSelectChange: function modelSelectChange() {
      this.skuAdd.skuCode = '';
      this.skuSelect = [];
    },
    feeTypeChange: function feeTypeChange() {
      this.skuFeeAdd.serviceFeeId = '';
      this.userfeeSelect = [];
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this11 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this11.orderInfo = response.data;
          _this11.refDateBefore = _this11.orderInfo.refDate;
          _this11.orderInfo.refDateBefore = _this11.refDateBefore;
          _this11.orderInfo.isRepair = false;

          _this11.$refs.orderProduct.$emit('loadFreeSn', _this11.orderInfo.sn);

          if (_this11.$refs.checkHistoryList) {
            _this11.$refs.checkHistoryList.$emit('loadcheckHistoryList', _this11.id);
          }

          if (_this11.orderInfo.checkType === '02' && !_this11.confirmBtnStatus) {
            _this11.checkSubTypeChange('02', '01');
          } else {
            _this11.rules.checkReason = [];

            _this11.loadOrderSkuList();
          }
        }
      });
    },
    loadSchemeList: function loadSchemeList() {
      var _this12 = this;

      this.schemeListLoading = true;
      var params = {
        orderId: this.id,
        step: '3'
      };
      (0, _order.getSchemeList)(params).then(function (response) {
        if (response.data) {
          _this12.schemeList = response.data;
        }
      });
      this.schemeListLoading = false;
    },
    loadOrderSkuList: function loadOrderSkuList() {
      var _this13 = this;

      this.skuListLoading = true;
      var params = {
        orderId: this.id,
        step: '3'
      }; // 缺料

      var params1 = {
        orderId: this.id,
        step: '10'
      };
      (0, _order.getOrderSkuList)(params).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this13.skuList = response.data.map(function (v) {
            _this13.$set(v, 'indexNum', ++indexNum); // 动态加字段


            _this13.$set(v, 'edit', false); // 动态加字段


            return v;
          });
        }
      });
      (0, _order.getOrderSkuList)(params1).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this13.skuList1 = response.data.map(function (v) {
            _this13.$set(v, 'indexNum', ++indexNum); // 动态加字段


            _this13.$set(v, 'edit', false); // 动态加字段


            return v;
          });
        }
      });
      this.skuListLoading = false;
    },
    loadOrderSkuFeeList: function loadOrderSkuFeeList() {
      var _this14 = this;

      this.skuListLoading = true;
      var params = {
        orderId: this.id
      };
      (0, _order.getOrderSkuFeeList)(params).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this14.skuFeeList = response.data.map(function (v) {
            _this14.$set(v, 'indexNum', ++indexNum); // 动态加字段


            _this14.$set(v, 'edit', false); // 动态加字段


            return v;
          });
        }
      });
      this.skuListLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 验证方法：[换货]必须添加一个“成品”物料
    validateList: function validateList() {
      if (this.schemeList.length === 0) {
        this.$message({
          showClose: true,
          message: '【问题代码 + 处理方式】列表至少添加一行',
          type: 'error'
        });
        return false;
      }

      if (this.orderInfo.checkType === '02' && this.skuList.length === 0) {
        this.$message({
          showClose: true,
          message: '【换货服务】必须添加一个成品物料SKU',
          type: 'error'
        });
        return false;
      } // if ((this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103' ||
      //   (this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203')) &&
      //   this.skuFeeList.length === 0) {
      //   this.$message({ showClose: true, message: '必须添加一个服务SKU', type: 'error' })
      //   return false
      // }
      // 要求PC品类不再必须添加服务sku
      // if (this.orderInfo.firstClassCategory !== null &&
      //   this.orderInfo.firstClassCategory.isEnable === '1' && this.skuFeeList.length !== 1) {
      //   this.$message({ showClose: true, message: 'PC品类必须添加一个服务SKU', type: 'error' })
      //   return false
      // }


      return true;
    },
    // 【检测完成】
    checkConfirm: function checkConfirm() {
      var _this15 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this15.validateList()) {
          _this15.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this15.checkOrderSumbit();
          }).catch(function () {});
        } else {
          _this15.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    checkOrderSumbit: function checkOrderSumbit() {
      var _this16 = this;

      this.btnLoading = true;
      this.orderInfo.orderSkuList = this.skuList;
      this.orderInfo.orderSkuList1 = this.skuList1;
      this.orderInfo.orderSkuFeeList = this.skuFeeList;
      (0, _order.checkOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          if (response.data.cleanOrderNo !== null && response.data.cleanOrderNo !== '' && response.data.cleanType === '1') {
            _this16.$message({
              showClose: true,
              message: '检测完了,新建维修单号为：' + response.data.cleanOrderNo,
              type: 'success'
            });
          } else {
            _this16.$message({
              showClose: true,
              message: '检测完了',
              type: 'success'
            });
          }

          _this16.$store.dispatch('tagsView/delView', _this16.$route);

          _this16.$router.push({
            path: '/order/check/list'
          });
        } else {
          _this16.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【问题解决方案】
    addSchemeClick: function addSchemeClick() {
      var _this17 = this;

      this.$refs['schemeAddForm'].validate(function (valid) {
        if (valid) {
          _this17.btnLoading = true;
          _this17.schemeAdd.orderId = _this17.orderInfo.id;
          _this17.schemeAdd.orderNo = _this17.orderInfo.orderNo;
          (0, _order.addScheme)(_this17.schemeAdd).then(function (response) {
            if (response.data) {
              _this17.$message({
                showClose: true,
                message: '添加成功！',
                type: 'success'
              });

              _this17.loadSchemeList();
            } else {
              _this17.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
          _this17.btnLoading = false;
        } else {
          _this17.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【问题解决方案】
    removeSchemeClick: function removeSchemeClick(row) {
      var _this18 = this;

      this.btnLoading = true;
      var params = {
        step: '3',
        orderId: this.orderInfo.id,
        faultId: row.faultId
      };
      (0, _order.removeScheme)(params).then(function (response) {
        if (response.data) {
          _this18.$message({
            showClose: true,
            message: '删除成功！',
            type: 'success'
          });

          _this18.loadSchemeList();
        } else {
          _this18.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单物料SKU】
    addSkuClick: function addSkuClick() {
      var _this19 = this;

      // 检查是否已经添加
      if (this.skuList.find(function (v) {
        return v.skuCode === _this19.skuAdd.skuCode;
      })) {
        return;
      }

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.feeType = this.skuAdd.feeType;
      newSkuItem.feeOption = this.skuAdd.feeOption;
      newSkuItem.quantity = 1;
      var skuInfo = this.skuSelect.filter(function (ob) {
        return _this19.skuAdd.skuCode === ob.code;
      })[0];
      newSkuItem.skuId = skuInfo.id;
      newSkuItem.skuCode = skuInfo.code;
      newSkuItem.skuName = skuInfo.name;
      newSkuItem.skuType = skuInfo.type;
      newSkuItem.skuTag = skuInfo.skuTag;
      newSkuItem.priceExcludeTax = skuInfo.priceExcludeTax;
      newSkuItem.priceIncludeTax = skuInfo.priceIncludeTax;
      newSkuItem.stock = skuInfo.stock;
      newSkuItem.ddirStock = skuInfo.ddirStock;
      newSkuItem.isRetail = skuInfo.isRetail;

      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        newSkuItem.brandId = this.orderInfo.brand;
        newSkuItem.categoryId = this.orderInfo.category;
        newSkuItem.categoryName = this.orderInfo.categoryName;
        newSkuItem.modelId = this.orderInfo.model;
        newSkuItem.modelName = this.orderInfo.modelName;
        this.skuList.push(newSkuItem);
        var indexNum = 0;
        this.skuList.map(function (v) {
          _this19.$set(v, 'indexNum', ++indexNum); // 动态加字段


          _this19.$set(v, 'edit', false); // 动态加字段


          return v;
        });
      } else if (this.orderInfo.checkType === '02') {
        (0, _select.getModeBySku)({
          code: skuInfo.code,
          takeCity: this.orderInfo.takeCity
        }).then(function (response) {
          if (response.data) {
            newSkuItem.brandId = response.data.brandId;
            newSkuItem.categoryId = response.data.categoryId;
            newSkuItem.categoryName = response.data.categoryName;
            newSkuItem.modelId = response.data.modelId;
            newSkuItem.modelName = response.data.modelName;
            newSkuItem.ddirStock = response.data.ddirStock;
            newSkuItem.stock = response.data.stock;
            newSkuItem.feeType = '01';
            newSkuItem.isRetail = response.data.isRetail;

            _this19.skuList.push(newSkuItem);

            var _indexNum = 0;

            _this19.skuList.map(function (v) {
              _this19.$set(v, 'indexNum', ++_indexNum); // 动态加字段


              _this19.$set(v, 'edit', false); // 动态加字段


              return v;
            });
          }
        });
      }

      this.btnLoading = false;
    },
    // 添加【工单物料SKU】----------缺料
    addSkuClick1: function addSkuClick1() {
      var _this20 = this;

      // 检查是否已经添加
      if (this.skuList1.find(function (v) {
        return v.skuCode === _this20.skuAdd1.skuCode;
      })) {
        return;
      }

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.feeType = this.skuAdd1.feeType;
      newSkuItem.isLack = this.skuAdd1.isLack;
      newSkuItem.quantity = 1;
      var skuInfo = this.skuSelect1.filter(function (ob) {
        return _this20.skuAdd1.skuCode === ob.code;
      })[0];
      newSkuItem.skuId = skuInfo.id;
      newSkuItem.skuCode = skuInfo.code;
      newSkuItem.skuName = skuInfo.name;
      newSkuItem.skuType = skuInfo.type;
      newSkuItem.skuTag = skuInfo.skuTag;
      newSkuItem.priceExcludeTax = skuInfo.priceExcludeTax;
      newSkuItem.priceIncludeTax = skuInfo.priceIncludeTax;
      newSkuItem.stock = skuInfo.stock;
      newSkuItem.ddirStock = skuInfo.ddirStock;

      if (this.orderInfo.checkReason === '3' || this.orderInfo.checkReason === '5') {
        newSkuItem.brandId = this.orderInfo.brand;
        newSkuItem.categoryId = this.orderInfo.category;
        newSkuItem.categoryName = this.orderInfo.categoryName;
        newSkuItem.modelId = this.orderInfo.model;
        newSkuItem.modelName = this.orderInfo.modelName;
        this.skuList1.push(newSkuItem);
        var indexNum = 0;
        this.skuList1.map(function (v) {
          _this20.$set(v, 'indexNum', ++indexNum); // 动态加字段


          _this20.$set(v, 'edit', false); // 动态加字段


          return v;
        });
      }

      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单服务SKU】
    addFeeSkuClick: function addFeeSkuClick() {
      var _this21 = this;

      // 检查是否已经添加
      if (this.skuFeeList.find(function (v) {
        return v.serviceFeeId === _this21.skuFeeAdd.serviceFeeId;
      })) {
        return;
      }

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.chargeType = this.skuFeeAdd.feeType;
      newSkuItem.feeOption = this.skuFeeAdd.feeOption;
      var skuFeeInfo = this.userfeeSelect.filter(function (ob) {
        return _this21.skuFeeAdd.serviceFeeId === ob.id;
      })[0];
      newSkuItem.serviceFeeId = skuFeeInfo.id;
      newSkuItem.skuFeeId = skuFeeInfo.id;
      newSkuItem.code = skuFeeInfo.code;
      newSkuItem.name = skuFeeInfo.name;

      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103' || this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203') {
        this.skuFeeList.push(newSkuItem);
        var indexNum = 0;
        this.skuFeeList.map(function (v) {
          _this21.$set(v, 'indexNum', ++indexNum); // 动态加字段


          _this21.$set(v, 'edit', false); // 动态加字段


          return v;
        });
      }

      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单物料SKU】
    removeSkuClick: function removeSkuClick(row) {
      var _this22 = this;

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      this.skuList.splice(this.skuList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this22.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this22.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // 删除【工单物料SKU】
    removeSkuClick1: function removeSkuClick1(row) {
      var _this23 = this;

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      this.skuList1.splice(this.skuList1.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuList1.map(function (v) {
        _this23.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this23.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单服务SKU】
    removeSkuFeeClick: function removeSkuFeeClick(row) {
      var _this24 = this;

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      this.skuFeeList.splice(this.skuFeeList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuFeeList.map(function (v) {
        _this24.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this24.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 更新【工单物料SKU】
    updateOrderSkuClick: function updateOrderSkuClick(row) {
      var _this25 = this;

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this25.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this25.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 更新【工单缺料物料SKU】
    updateOrderSkuClick1: function updateOrderSkuClick1(row) {
      var _this26 = this;

      this.orderPriceIsModify = true;
      this.btnLoading = true;
      var indexNum = 0;
      this.skuList1.map(function (v) {
        _this26.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this26.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 快捷TAG查询
    stepClick: function stepClick(tab, event) {
      this.step = tab.name;

      if (tab.name === '1') {
        this.$refs.receiptOrder.$emit('loadExpressGoods', this.id);
      } // if (tab.name === '4') {
      //   this.$refs.handleOrder.$emit('loadhandleInfo', this.orderInfo.id)
      // } else if (tab.name === '3') {
      //   this.$refs.checkOrder.$emit('loadcheckInfo', { id: this.orderInfo.id })
      // }

    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    printDown: function printDown(type) {
      (0, _request.downLoadFile)('/bus_app/order/print/check/download?type=' + type + '&id=' + this.id).then(function (res) {
        var fileName = res.headers['content-disposition'].split('=')[1];
        var _res = res.data;
        var blob = new Blob([_res]);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    dataChange: function dataChange(val) {
      this.orderInfo.refDate = (0, _utils.parseTime)(val.refDate, '{y}-{m}-{d}');
      this.orderInfo.refDateBefore = val.refDateBefore;
    },
    // 查询费用明细
    queryPriceItem: function queryPriceItem() {
      var _this27 = this;

      // this.orderPriceIsModify = true  检查是否修改
      if (this.orderPriceIsModify) {
        // 保存俩sku 且计算价格
        // 如果为缺料 则skuList1
        if (this.orderInfo.checkReason === '3' || this.orderInfo.checkReason === '5') {
          this.orderInfo.orderSkuList = this.skuList1;
        } else {
          this.orderInfo.orderSkuList = this.skuList;
        }

        this.orderInfo.orderSkuFeeList = this.skuFeeList;
        (0, _order.saveOrderSKUs)(this.orderInfo).then(function (response) {
          if (response.data) {
            _this27.$message({
              showClose: true,
              message: '查询成功',
              type: 'success'
            });

            _this27.orderPriceIsModify = false;
            _this27.orderPriceShow = true;

            _this27.$refs.checkPrice.$emit('loadCheckInfo', _this27.orderInfo.id);
          } else {
            _this27.orderPriceShow = !_this27.orderPriceShow;

            _this27.$refs.checkPrice.$emit('loadCheckInfo', _this27.orderInfo.id);
          }
        });
      } else {
        this.orderPriceShow = true;
        this.$refs.checkPrice.$emit('loadCheckInfo', this.orderInfo.id);
      }
    }
  }
};
exports.default = _default;