"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeState = changeState;
exports.findPageList = findPageList;
exports.queryById = queryById;
exports.updateReadState = updateReadState;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/sn_register_fail/getPageList',
    method: 'post',
    params: query
  });
}
/** 产品注册失败修改状态*/


function changeState(id, state, channelCode) {
  return (0, _request.default)({
    url: '/product_app/sn_register_fail/updateState',
    method: 'post',
    params: {
      id: id,
      state: state,
      channelCode: channelCode
    }
  });
}
/** 已读注册失败信息*/


function updateReadState(ids) {
  return (0, _request.default)({
    url: '/product_app/sn_register_fail/updateReadState',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/** 详情页查询*/


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/sn_register_fail/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}