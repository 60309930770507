"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _wip = require("@/api/basics/wip/wip");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _filterUtils = require("@/api/order/filterUtils");

var _asyncExport = require("@/api/common/asyncExport");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
// 时间格式化
var _default = {
  name: 'reportDetail',
  // eslint-disable-line
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default,
    permission: _index.default
  },
  filters: {
    subStateFilter: _filterUtils.subStateFilter
  },
  data: function data() {
    return {
      conditionFold: '0',
      btnLoading: false,
      loginUserOrgId: this.$store.state.user.loginUser.orgId,
      // 当前账号所属机构ID
      ccFollow: this.$route.params.ccFollow,
      day: this.$route.params.day,
      subType1: this.$route.params.subType1,
      query: this.$route.params.query,
      // ---------------------------------------------------------------------------------------------------------------
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        menuTask: '1',
        page: 1,
        limit: 10,
        isAsc: ''
      },
      exportQuery: {
        queue: process.env.VUE_APP_SQS_QUEUE_EXPORT_WIP_01,
        taskName: '数字看板详情导出',
        inRmk: this.$store.state.user.loginUser.memberId,
        data: ''
      } // ---------------------------------------------------------------------------------------------------------------------------------------------

    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {
    this.listQuery = this.query;
    this.listQuery.ccFollow = this.ccFollow;
    this.listQuery.day = this.day;
    this.listQuery.subType1 = this.subType1;
    this.getList();
  },
  activated: function activated() {
    this.listQuery.page = 1;
    this.listQuery = this.query;
    this.listQuery.ccFollow = this.ccFollow;
    this.listQuery.day = this.day;
    this.listQuery.subType1 = this.subType1;
    this.getList();
  },
  methods: {
    // 详情页面
    gotoEditHtml: function gotoEditHtml(row, event) {
      this.$router.push({
        name: 'queryOrderDetail',
        params: {
          id: row.id
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 分页查询
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      console.log(JSON.stringify(this.listQuery));
      (0, _wip.getOrderWipList)(this.listQuery).then(function (response) {
        _this.list = response.data.records;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    handelExport: function handelExport() {
      var _this2 = this;

      var Base64 = require('js-base64').Base64;

      this.exportQuery.data = Base64.encode(JSON.stringify(this.listQuery));
      (0, _asyncExport.asyncExport)(this.exportQuery).then(function (response) {
        if (response.code === 200) {
          _this2.$message.success('创建导出任务成功! 任务号:[' + response.data + '] 请至 [信息查询] -> [任务查询] 中查看任务详情');
        } else {
          _this2.$message.error('创建导出任务失败:' + response.msg);
        }
      });
    }
  }
};
exports.default = _default;