"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 产品注册路由
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var extendRouter = {
  path: '/extend/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'batchApprove/:num',
    name: 'BatchApproveDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/extend/batch_approve/detail'));
      });
    },
    meta: {
      title: '批量延保详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/extend/batch_approve',
      parent: {
        path: '/extend',
        redirect: 'noRedirect',
        meta: {
          title: '批量延保',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = extendRouter;
exports.default = _default;