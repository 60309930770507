"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.confirmSave = confirmSave;
exports.deleteItems = deleteItems;
exports.deleteStockTransfer = deleteStockTransfer;
exports.fetchList = fetchList;
exports.getGoods = getGoods;
exports.getItemsList = getItemsList;
exports.queryById = queryById;
exports.saveStockTransfer = saveStockTransfer;
exports.submitSave = submitSave;
exports.tempSave = tempSave;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/getPageList',
    method: 'post',
    params: query
  });
}

function getGoods(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/getGoods',
    method: 'post',
    params: query
  });
}

function saveStockTransfer() {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/saveStockTransfer',
    method: 'post'
  });
}

function addItems(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/addItems',
    method: 'post',
    params: query
  });
}

function getItemsList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/getItemsList',
    method: 'post',
    params: query
  });
}

function deleteItems(ids) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/deleteItems',
    method: 'post',
    params: {
      id: ids
    }
  });
}

function tempSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/tempSave',
    method: 'post',
    data: data
  });
}

function submitSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/submitSave',
    method: 'post',
    data: data
  });
}

function confirmSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/confirmSave',
    method: 'post',
    data: data
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}

function deleteStockTransfer(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_transfer/deleteStockTransfer',
    method: 'post',
    params: {
      id: id
    }
  });
}