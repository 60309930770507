"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.saveOverseas = saveOverseas;
exports.updateStateById = updateStateById;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/overseas_sale_return/getPageList',
    method: 'post',
    params: query
  });
}
/** 更新状态*/


function updateStateById(id) {
  return (0, _request.default)({
    url: '/bus_app/overseas_sale_return/updateStateById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 新建海外机退货单*/


function saveOverseas(data) {
  return (0, _request.default)({
    url: '/bus_app/overseas_sale_return/saveOverseasSaleReturn',
    method: 'post',
    data: data
  });
}