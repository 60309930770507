var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitCreate } },
                [_vm._v("生成提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "Form",
          attrs: {
            model: _vm.expressOrg,
            rules: _vm.orgRules,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              机构详情\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "单位名称",
                                            prop: "orgName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value: _vm.expressOrg.orgName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expressOrg,
                                                  "orgName",
                                                  $$v
                                                )
                                              },
                                              expression: "expressOrg.orgName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "联系人姓名",
                                            prop: "receiver",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value: _vm.expressOrg.receiver,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expressOrg,
                                                  "receiver",
                                                  $$v
                                                )
                                              },
                                              expression: "expressOrg.receiver",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "联系人电话",
                                            prop: "receiveMobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.expressOrg.receiveMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expressOrg,
                                                  "receiveMobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "expressOrg.receiveMobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "省份",
                                            prop: "provinceCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.proviceChange(
                                                    _vm.expressOrg.provinceCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.expressOrg.provinceCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.expressOrg,
                                                    "provinceCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "expressOrg.provinceCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.provinceSelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "城市",
                                            prop: "cityCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.cityChange(
                                                    _vm.expressOrg.cityCode
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.expressOrg.cityCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.expressOrg,
                                                    "cityCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "expressOrg.cityCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.citySelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "区域",
                                            prop: "districtCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.expressOrg.districtCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.expressOrg,
                                                    "districtCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "expressOrg.districtCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.regionSelect,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "detailAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.expressOrg.detailAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expressOrg,
                                                  "detailAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "expressOrg.detailAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "公司信息(选填)",
                                            prop: "companyInfo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value: _vm.expressOrg.companyInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expressOrg,
                                                  "companyInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "expressOrg.companyInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }