var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "处理信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("处理信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "最终服务类型" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.handleType,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "handleType", $$v)
                              },
                              expression: "orderInfo.handleType",
                            },
                          },
                          _vm._l(
                            _vm.businessTypeOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "最终服务子类型" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.handleSubType,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "handleSubType", $$v)
                              },
                              expression: "orderInfo.handleSubType",
                            },
                          },
                          _vm._l(
                            _vm.businessSubTypeOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "处理结果" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.handleResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "handleResult", $$v)
                              },
                              expression: "orderInfo.handleResult",
                            },
                          },
                          _vm._l(
                            _vm.handleResultOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "处理人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.handleUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "handleUserName", $$v)
                            },
                            expression: "orderInfo.handleUserName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "处理时间" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.handleTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "handleTime", $$v)
                            },
                            expression: "orderInfo.handleTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "处理备注" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                            maxlength: "1000",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.orderInfo.handleRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "handleRemark", $$v)
                            },
                            expression: "orderInfo.handleRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { ":span": 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-left": "160px" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.schemeListLoading,
                            expression: "schemeListLoading",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          border: "",
                          stripe: "",
                          "tooltip-effect": "dark",
                          "header-cell-style": {
                            background: "#eef1f6",
                            color: "#606266",
                            height: "40px",
                          },
                          data: _vm.schemeList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "indexNum",
                            label: "序号",
                            resizable: "",
                            align: "center",
                            "min-width": "10",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "problemTypeName",
                            label: "问题分类",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "faultName",
                            label: "问题代码",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "repairNames",
                            label: "处理方式",
                            resizable: "",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "部件信息" },
        [
          _c(
            "el-divider",
            { staticClass: "myStyle", attrs: { "content-position": "left" } },
            [_vm._v("部件信息")]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "border-top": "none" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "160px" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.skuListLoading,
                                expression: "skuListLoading",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "40px",
                              },
                              data: _vm.skuList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "indexNum",
                                label: "序号",
                                resizable: "",
                                align: "center",
                                "min-width": "20",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "skuCode",
                                label: "物料编码",
                                resizable: "",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "skuName",
                                label: "物料名称",
                                resizable: "",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "skuType",
                                label: "物料分类",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("skuTypeFilter")(row.skuType)
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "skuTag",
                                label: "物料标识",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("skuTagFilter")(row.skuTag)
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "categoryName",
                                label: "品类",
                                resizable: "",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "modelName",
                                label: "型号",
                                resizable: "",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "feeType",
                                label: "收费类型",
                                resizable: "",
                                align: "center",
                                "min-width": "40",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("feeTypeFilter")(row.feeType)
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "quantity",
                                label: "数量",
                                resizable: "",
                                align: "center",
                                "min-width": "20",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(row.quantity))]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("交付性检测")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _vm._l(_vm.checkItemList4show, function (items, index1) {
            return _c(
              "el-row",
              { key: index1 },
              _vm._l(items, function (item, index2) {
                return _c("el-col", { key: index2, attrs: { span: 6 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-right": "100px",
                        "padding-bottom": "10px",
                        "padding-left": "100px",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        { attrs: { content: item.itemName, placement: "top" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: item.itemName,
                                size: "medium",
                                border: "",
                                disabled: "",
                              },
                              model: {
                                value: item.choose,
                                callback: function ($$v) {
                                  _vm.$set(item, "choose", $$v)
                                },
                                expression: "item.choose",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("showOverflowTooltipFilter")(
                                    item.itemName
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "30px" } },
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "前端展示", prop: "handleShow" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.handleShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "handleShow", $$v)
                            },
                            expression: "orderInfo.handleShow",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("处理附件")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c("upload-file", {
                              attrs: {
                                disabled: true,
                                "attachment-list":
                                  _vm.orderInfo.attachmentList4,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }