var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "返机信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("返机信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "返机方式" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.returnType,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "returnType", $$v)
                              },
                              expression: "orderInfo.returnType",
                            },
                          },
                          _vm._l(_vm.returnTypeOptions, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.name, value: item.val },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.orderInfo.returnType === "1"
                ? _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "物流公司" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.orderInfo.returnLogisticsCompany,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.orderInfo,
                                    "returnLogisticsCompany",
                                    $$v
                                  )
                                },
                                expression: "orderInfo.returnLogisticsCompany",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.returnType === "1"
                ? _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "物流单号" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.orderInfo.returnLogisticsNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.orderInfo,
                                    "returnLogisticsNumber",
                                    $$v
                                  )
                                },
                                expression: "orderInfo.returnLogisticsNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "返机人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.confirmUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "confirmUserName", $$v)
                            },
                            expression: "orderInfo.confirmUserName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "返机时间" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.confirmTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "confirmTime", $$v)
                            },
                            expression: "orderInfo.confirmTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "维修SAP单号" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.orderNoSapNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "orderNoSapNo", $$v)
                            },
                            expression: "orderInfo.orderNoSapNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 16, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "新产品SN" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.newSn,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "newSn", $$v)
                            },
                            expression: "orderInfo.newSn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "换货SAP单号" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.orderNocSapNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "orderNocSapNo", $$v)
                            },
                            expression: "orderInfo.orderNocSapNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "返机备注" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                            maxlength: "1000",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.orderInfo.confirmRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "confirmRemark", $$v)
                            },
                            expression: "orderInfo.confirmRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("返件物品")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        _vm._l(_vm.goodsList4show, function (goods, index1) {
          return _c(
            "el-row",
            { key: index1 },
            _vm._l(goods, function (good, index2) {
              return _c("el-col", { key: index2, attrs: { span: 6 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-top": "10px",
                      "padding-right": "100px",
                      "padding-bottom": "10px",
                      "padding-left": "100px",
                    },
                  },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { content: good.goodsName, placement: "top" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              label: good.goodsName,
                              size: "medium",
                              border: "",
                              disabled: "",
                            },
                            model: {
                              value: good.choose,
                              callback: function ($$v) {
                                _vm.$set(good, "choose", $$v)
                              },
                              expression: "good.choose",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("showOverflowTooltipFilter")(
                                  good.goodsName
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }