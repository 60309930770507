"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDelete = batchDelete;
exports.codeList = codeList;
exports.createDeliver = createDeliver;
exports.deleteById = deleteById;
exports.deleteCategory = deleteCategory;
exports.getCateListByOrgId = getCateListByOrgId;
exports.getConfigList = getConfigList;
exports.getPageList = getPageList;
exports.saveData = saveData;
exports.updateConfig = updateConfig;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/deliver/getPageList',
    method: 'post',
    params: query
  });
}

function saveData(data) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/save',
    method: 'post',
    data: data
  });
}

function deleteCategory(id) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/deleteCategory',
    method: 'post',
    params: {
      categoryId: id
    }
  });
}

function getCateListByOrgId(param) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/getCateListByOrgId',
    method: 'post',
    params: {
      orgId: param.id,
      type: param.type
    }
  });
}

function createDeliver(levels, cityIds) {
  return (0, _request.default)({
    url: '/bus_app/deliver/createDeliver',
    method: 'post',
    params: {
      levels: levels,
      cityIds: cityIds
    }
  });
}

function batchDelete(ids) {
  return (0, _request.default)({
    url: '/bus_app/deliver/batchDelete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/bus_app/deliver/deleteById',
    method: 'post',
    params: {
      id: id
    }
  });
}

function codeList(code) {
  return (0, _request.default)({
    url: '/bus_app/deliver/codeList',
    method: 'post',
    params: {
      code: code
    }
  });
}

function getConfigList() {
  return (0, _request.default)({
    url: '/bus_app/stock_forewarn_config/getList',
    method: 'post'
  });
}

function updateConfig(data) {
  return (0, _request.default)({
    url: '/bus_app/stock_forewarn_config/update',
    method: 'post',
    data: data
  });
}