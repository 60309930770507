"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createExpressOrg = createExpressOrg;
exports.findPageList = findPageList;
exports.getExpressOrgSelect = getExpressOrgSelect;
exports.queryById = queryById;
exports.updateStatus = updateStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/express_org/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/express_org/getById',
    method: 'get',
    params: {
      id: id
    }
  });
}
/**
 * 创建或者更新机构
 * @param data
 */


function createExpressOrg(data) {
  return (0, _request.default)({
    url: '/bus_app/express_org/createOrUpdate',
    method: 'post',
    data: data
  });
}
/**
 * 查询机构列表
 * @param data
 */


function getExpressOrgSelect(type) {
  return (0, _request.default)({
    url: '/bus_app/express_org/getExpressOrgSelect',
    method: 'get',
    params: {
      type: type
    }
  });
}
/**
 * 更新状态
 */


function updateStatus(id, status) {
  return (0, _request.default)({
    url: '/bus_app/express_org/updateStatus',
    method: 'post',
    params: {
      id: id,
      status: status
    }
  });
}