var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          attrs: {
            disabled: _vm.imgDisabled,
            action: _vm.uploadAction,
            "list-type": "picture-card",
            accept: "image/*",
            drag: true,
            limit: _vm.limit,
            "file-list": _vm.imgList,
            multiple: _vm.isMultiple,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleAvatarSuccess,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.beforeAvatarUpload,
            "on-error": _vm.imgUploadError,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }