"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _list = require("@/api/consult/list");

var _list2 = require("@/api/basics/consultType/list");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile"));

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _index = require("@/utils/index");

var _customer = require("@/api/register/customer");

var _productRegister = require("@/api/register/productRegister");

var _productEnrollment = require("@/api/register/productEnrollment");

var _overseas = require("@/api/sale/overseas");

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _utils = require("@/utils");

var _select = require("@/api/common/select");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件图片上传
// 新建任务工单
var _default = {
  name: 'consultOrderDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    NewTask: _NewTask.default
  },
  directives: {},
  filters: {
    formatExpState: function formatExpState(val) {
      // 运单路由状态
      if (val === undefined || val === null || val === '') {
        return '';
      } else {
        var expStateOpions = {
          '10': '已揽件',
          '20': '在途',
          '30': '正在投递',
          '40': '妥投',
          '90': '转寄',
          '99': '派件异常'
        };
        return expStateOpions[val.trim()];
      }
    },
    couponNameFilter: function couponNameFilter(val) {
      var couponStatusOptions = {
        '5': '未生效',
        '10': '已生效',
        '15': '已核销',
        '20': '已过期',
        '25': '注销'
      };
      return couponStatusOptions[val.trim()];
    }
  },
  data: function data() {
    var _this = this;

    var validate = function validate(rule, value, callback) {
      var warrantyDate = _this.ownCustomerProduct.warrantyDate;

      if (_this.warrantyDateOld !== warrantyDate) {
        if (value === undefined || value === null) {
          callback('入保日期备注是必填的');
        } else {
          if (value.trim() === '') {
            callback('入保日期备注是必填的');
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };

    var validateTrade = function validateTrade(rule, value, callback) {
      if (_this.consultOrderInfo.customChannel === 'FB49') {
        if (value === undefined || value === null || value === '') {
          callback('升级优惠服务单号是必填的');
        } else {
          if (value.trim() === '') {
            callback('升级优惠服务单号是必填的');
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };

    return {
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        num: undefined,
        phone: undefined
      },
      timer: '',
      imgDisabled: false,
      uploadDialogVisible: false,
      // 上传文件
      isupload: false,
      // 是否显示 待客户上传按钮
      oneLevelSelect: [],
      // 咨询单一级归类
      twoLevelSelect: [],
      // 咨询单二级归类
      threeLevelSelect: [],
      // 咨询单三级归类
      productSearch: '',
      enrollSnSearch: '',
      dialogVisible1: false,
      // 弹出框1
      dialogVisible: false,
      // 弹出框
      dialogMsg: '',
      // 弹出框 显示信息
      dialogType: '',
      // 弹出框类型(1->选了产品转工单；2->没选产品转工单；3->完结咨询工单；4->转销售工单，更新咨询单状态；5->转退货单，更新咨询单状态, 6.完结咨询单，再次确认，7->待客户上传附件)
      prodName: '',
      select708: [{
        name: '375423-01',
        id: '375423-01'
      }, {
        name: '376519-01',
        id: '376519-01'
      }, {
        name: '375411-01',
        id: '375411-01'
      }, {
        name: '376510-01',
        id: '376510-01'
      }, {
        name: '413149-01',
        id: '413149-01'
      }],
      btnLoading: false,
      isEnablesOptions: [{
        label: '启用',
        value: '1'
      }, {
        label: '禁用',
        value: '0'
      }],
      typesOptions: [{
        label: '良品库',
        value: '0'
      }, {
        label: '坏品库',
        value: '1'
      }, {
        label: '报废品库',
        value: '2'
      }],
      textMap: {
        update: '编辑',
        create: '新建'
      },
      id: this.$route.params.id,
      isQuery: this.$route.params.isQuery,
      loading: false,
      multipleSelection: [],
      orgListValidate: false,
      // >>> 咨询工单信息 >>>
      consultOrderInfo: {
        id: this.$route.params.id,
        num: '',
        consultOrderCustomerInfo: {},
        CustomerProductList: [],
        uploadList: [],
        uploadInfo: [],
        prodName: '' // 选中的产品名称-》更改为选择的产品sn

      },
      uploadInfo: {},
      // 上传图片信息
      // >>> 咨询工单上传文件信息 >>>
      uploadList: [],
      // >>> 咨询工单客户信息 >>>
      consultOrderCustomerInfo: {},
      // >>> 咨询工单产品信息 >>>
      CustomerProductList: [],
      // 仅登记产品列表
      enrollmentProductList: [],
      enrollmentProductList2: [],
      CustomerProductList2: [],
      warrantyDateOld: '',
      rules: {
        applicantMan: [{
          required: true,
          message: '报修人姓名是必填的',
          trigger: 'blur'
        }],
        applicantTel: [{
          required: true,
          message: '报修人手机号是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        type: [{
          required: true,
          message: '业务类型是必选的',
          trigger: 'blur'
        }],
        accessChannel: [{
          required: true,
          message: '接入渠道必选的',
          trigger: 'blur'
        }],
        oneLevel: [{
          required: true,
          message: '咨询单一级归类是必选的',
          trigger: 'blur'
        }],
        twoLevel: [{
          required: true,
          message: '咨询单二级归类是必须的',
          trigger: 'blur'
        }],
        threeLevel: [{
          required: true,
          message: '咨询单三级归类必选的',
          trigger: 'blur'
        }],
        userDescribe: [{
          required: true,
          message: '用户描述是必填的',
          trigger: 'blur'
        }],
        tradeInNo: [{
          validator: validateTrade,
          trigger: 'blur'
        }, {
          required: true,
          message: '升级优惠服务单号是必填的',
          trigger: 'blur'
        }],
        beforeMemo: [{
          required: true,
          message: '自助服务描述是必填的',
          trigger: 'blur'
        }],
        afterMemo: [{
          required: true,
          message: '问题出现前/后是必填的',
          trigger: 'blur'
        }],
        crmPhone: [{
          required: true,
          message: '机主手机号是必填的',
          trigger: 'blur'
        }, {
          pattern: /^1[123456789]\d{9}$/,
          message: '请输入正确的手机号码'
        }],
        memo: [{
          max: 255,
          message: '最大长度 500 个字符',
          trigger: 'blur'
        }],
        sourceNum: [{
          max: 255,
          message: '最大长度 64 个字符',
          trigger: 'blur'
        }]
      },
      // 咨询工单主状态
      stateOptions: [{
        'val': '00',
        'name': '新建'
      }, {
        'val': '10',
        'name': '处理中'
      }, {
        'val': '20',
        'name': '已结束'
      }],
      // 咨询工单子状态
      subStateOptions: [{
        'val': '1',
        'name': '新建'
      }, {
        'val': '2',
        'name': '待客户上传'
      }, {
        'val': '3',
        'name': '客户已传待审阅'
      }, {
        'val': '4',
        'name': '客户已传已审阅'
      }, {
        'val': '5',
        'name': '已转服务'
      }, {
        'val': '6',
        'name': '已转销售'
      }, {
        'val': '7',
        'name': '已转退货'
      }, {
        'val': '8',
        'name': '仅咨询'
      }, {
        'val': '9',
        'name': '作废'
      }, {
        'val': '10',
        'name': '待下单'
      }, {
        'val': '11',
        'name': '已转退款'
      }, {
        'val': '12',
        'name': '已转VSE'
      }],
      // 咨询工单业务类型
      typeOptions: [{
        'val': '01',
        'name': '维修'
      }, {
        'val': '02',
        'name': '换货'
      }, {
        'val': '03',
        'name': '检测'
      }, // 3月9号版本 没有检测,3月31开放检测功能*/
      {
        'val': '04',
        'name': '销售'
      }, {
        'val': '05',
        'name': '退货'
      }, {
        'val': '06',
        'name': '咨询'
      }, {
        'val': '10',
        'name': '仅退款'
      }, {
        'val': '11',
        'name': '571海外销售单退货'
      }, {
        'val': '12',
        'name': 'GDPR@(GDPR)'
      }],
      // 接入渠道
      accessChannelOptions: [{
        'val': '1',
        'name': '电话'
      }, {
        'val': '2',
        'name': '微信'
      }, {
        'val': '3',
        'name': '京东'
      }, {
        'val': '4',
        'name': '天猫'
      }, {
        'val': '5',
        'name': '海外机'
      }, {
        'val': '7',
        'name': 'JD服务平台'
      }, {
        'val': '6',
        'name': '其他'
      }, {
        'val': '8',
        'name': '微信（清洁服务）'
      }, {
        'val': '9',
        'name': '微信（AIO自助）'
      }],
      accessChannelOptions1: [{
        'val': '1',
        'name': '电话'
      }, {
        'val': '2',
        'name': '微信'
      }, {
        'val': '3',
        'name': '京东'
      }, {
        'val': '4',
        'name': '天猫'
      }, {
        'val': '6',
        'name': '其他'
      }, {
        'val': '7',
        'name': 'JD服务平台'
      }],
      accessChannelOptions2: [{
        'val': '1',
        'name': '电话'
      }, {
        'val': '2',
        'name': '微信'
      }, {
        'val': '3',
        'name': '京东'
      }, {
        'val': '4',
        'name': '天猫'
      }, {
        'val': '5',
        'name': '海外机'
      }, {
        'val': '6',
        'name': '其他'
      }, {
        'val': '7',
        'name': 'JD服务平台'
      }],
      cusRules: {},
      // >>> 弹出框的数据 >>>
      orgDialogData: {},
      addOrgdialogFormVisible: false,
      organizationList: [],
      parentOrgList: [],
      orgDialogSelection: [],
      activeNames: [],
      isEdit: true,
      // 新建状态 可编辑
      isSnEdit: true,
      isSearch: true,
      // 是否可搜索
      isSearch2: true,
      applicantUnionid: '',
      isProSearch: true,
      changeActiveNames: [],
      expressActiveNames: [],
      // 运单状态信息
      orderActiveNames: [],
      // 转单信息是否显示
      expressRouteInfoList: [],
      // 运单路由信息
      expressStateInfoList: [],
      // 运单状态信息

      /** 服务单状态*/
      orderStateOptions: _constant.default.stateOptions,

      /** 服务单子状态*/
      orderSubStateOptions: _constant.default.subStateOptions,
      // 销售单主状态
      saleStateOptions: [{
        name: '待处理',
        val: 'S1'
      }, {
        name: '已支付',
        val: 'S2'
      }, {
        name: '已结束',
        val: 'S3'
      }, {
        name: '已取消',
        val: 'S4'
      }],
      // 销售单子状态
      saleSubStateOptions: [{
        name: '新建',
        val: '1'
      }, {
        name: '订单暂存',
        val: '2'
      }, {
        name: '待支付',
        val: '3'
      }, {
        name: '待下发',
        val: '4'
      }, {
        name: '已下发',
        val: '5'
      }, {
        name: '已确认',
        val: '6'
      }, {
        name: '已发货',
        val: '7'
      }, {
        name: '订单已取消',
        val: '8'
      }, {
        name: '支付已失效',
        val: '9'
      }],
      // 退货单主状态
      returnStateOptions: [{
        name: '待收货',
        val: 'S1'
      }, {
        name: '已收货',
        val: 'S2'
      }, {
        name: '已取消',
        val: 'S3'
      }],
      // 退货单子状态
      returnSubStateOptions: [{
        name: '新建',
        val: '1'
      }, {
        name: '订单暂存',
        val: '2'
      }, {
        name: '待下顺丰单',
        val: '4'
      }, {
        name: '待揽件',
        val: '41'
      }, {
        name: '揽件成功',
        val: '42'
      }, {
        name: '客户取消寄件',
        val: '43'
      }, {
        name: '揽件终止',
        val: '44'
      }, {
        name: '已下发',
        val: '5'
      }, {
        name: '已确认',
        val: '6'
      }, {
        name: '已入库收货正常',
        val: '7'
      }, {
        name: '已入库收货异常',
        val: '8'
      }, {
        name: '取消取件',
        val: '9'
      }],
      interValNum: 0,
      drawer: false,
      cusDrawer: false,
      direction: 'ltr',
      ownCustomerProduct: {},
      pickerOptions: {},
      systemModelList: [],
      ownProductRule: {
        serialNumber: [{
          required: true,
          message: '产品sn是必填的',
          trigger: 'blur'
        }, {
          len: 15,
          message: 'sn必须为13位！'
        }],
        modelName: [{
          required: true,
          message: '产品型号是必填的',
          trigger: 'blur'
        }],
        productName: [{
          required: true,
          message: '产品名称是必填的',
          trigger: 'blur'
        }],
        registerDate: [{
          required: true,
          message: '注册日期是必填的',
          trigger: 'blur'
        }],
        purchaseDate: [{
          required: true,
          message: '购买日期是必填的',
          trigger: 'blur'
        }],
        salesChannel: [{
          required: true,
          message: '购买渠道是必填的',
          trigger: 'blur'
        }],
        cusPhone: [{
          required: true,
          message: '绑定客户手机是必填的',
          trigger: 'blur'
        }, {
          max: 11,
          min: 11,
          message: '手机号必须为11位'
        }],
        country: [{
          required: true,
          message: '国家是必选的',
          trigger: 'blur'
        }],
        warrantyNote: [{
          validator: validate,
          trigger: 'blur'
        }]
      },
      ownCustomerRule: {
        name: [{
          required: true,
          message: '客户名称是必填的',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '客户电话是必填的',
          trigger: 'blur'
        }, {
          max: 11,
          min: 11,
          message: '手机号必须为11位'
        }],
        detailAddress: [{
          required: true,
          message: '详细地址是必填的',
          trigger: 'blur'
        }]
      },
      overseasRules: {
        serialNumber: [{
          required: true,
          message: '产品sn是必填的',
          trigger: 'blur'
        }, {
          len: 15,
          message: 'sn必须为13位！'
        }],
        skuCode: [{
          required: true,
          message: '国行sku是必填的',
          trigger: 'blur'
        }],
        overseasSku: [{
          required: true,
          message: '海外sku是必填的',
          trigger: 'blur'
        }],
        overseas: [{
          required: true,
          message: '是否海外机是必选的',
          trigger: 'blur'
        }],
        globalWarranty: [{
          required: true,
          message: '是否全球联保是必选的',
          trigger: 'blur'
        }],
        buyDate: [{
          required: true,
          message: '购买日期是必填的',
          trigger: 'blur'
        }],
        returnReason: [{
          required: true,
          message: '退回原因是必选的',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '退款金额是必填的',
          trigger: 'blur'
        }]
      },
      modelSelect: [],
      ownCustomer: {},
      showBtn: false,
      showPromoation: false,
      showPromoation2: false,
      signDate: '',
      signDate2: '',
      promotionMessage2: '',
      promotionMessage: '',
      promotionClose: false,
      registerType: '1',
      isSend: false,
      send: false,
      num: 60,
      sendMessage: '发送验证码',
      sendCode: '',
      operateTime: false,
      countryOptions: _constant.default.countryOptions,
      isOverseas: false,
      overseasSaleReturn: {},
      returnReasonOptions: [{
        'val': '7天无理由',
        'name': '7天无理由'
      }, {
        'val': '配件不适用',
        'name': '配件不适用'
      }, {
        'val': '质量问题',
        'name': '质量问题'
      }, {
        'val': '外观/包装',
        'name': '外观/包装'
      }],
      showOverseas: false,
      vseVisiable: false,
      chooseValue: false,
      bookTime: '',
      timePerList: [],
      showVSEDialog: false,
      refuseNote: '',
      consultOrderVseInfo: {},
      jdSupplyInfo: '',
      // 京东补充信息
      command: '1',
      showLabel: false,
      labelType: '',
      labelLevel: '',
      labelList: [],
      labels: [],
      labelTypeOptions: [{
        name: '用户',
        val: '10'
      }, {
        name: '手机号',
        val: '20'
      }, {
        name: '详细地址',
        val: '40'
      }],
      labelTypeOptions2: [{
        name: '产品SN',
        val: '30'
      }],
      tags: [{
        name: '标签一',
        type: ''
      }, {
        name: '标签二',
        type: 'success'
      }, {
        name: '标签三',
        type: 'info'
      }, {
        name: '标签四',
        type: 'warning'
      }, {
        name: '标签五',
        type: 'danger'
      }],
      userLabelList: [],
      mobileLabelList: [],
      addressLabelList: [],
      snLabelList: [],
      riskId: '',
      snRiskId: '',
      snRiskLevel: '',
      riskList: [],
      isDeleteLabel: false,
      crurrentTag: {},
      crurrentList: [],
      currentType: '',
      exchangeId: '',
      // 需发送换票链接的订单
      vsePickerOptions: {
        disabledDate: this.chooseDisableDate
      },
      bookDate: '',
      couponList: [],
      userfeeSelect: [],
      serviceFeeId: '',
      coupon: {},
      couponActiveNames: [],
      activeNames2: [],
      couponStatusOptions: [{
        val: '5',
        name: '未生效'
      }, {
        val: '10',
        name: '已生效'
      }, {
        val: '15',
        name: '已核销'
      }, {
        val: '20',
        name: '已过期'
      }, {
        val: '25',
        name: '注销'
      }]
    };
  },
  computed: {
    disableSn: function disableSn() {
      return !this.isProSearch;
    },
    disableVSE: function disableVSE() {
      var _this2 = this;

      // 判断一级归类是不是vse
      var newSelect = this.oneLevelSelect.filter(function (item) {
        return item.id === _this2.consultOrderInfo.oneLevel;
      });

      if (newSelect.length === 0) {
        return true;
      }

      var flag = newSelect[0].name.toLowerCase().indexOf('@vse') !== -1;

      if (!flag) {
        return true;
      } // 如果二级分类没有则禁止vse按钮


      if (!this.consultOrderInfo.twoLevel) {
        return true;
      } else {
        return false;
      }
    },
    disable571: function disable571() {
      var _this3 = this;

      // 判断一级归类是不是571
      var newSelect = this.oneLevelSelect.filter(function (item) {
        return item.id === _this3.consultOrderInfo.oneLevel;
      });

      if (newSelect.length === 0) {
        return false;
      }

      return newSelect[0].name.toLowerCase().indexOf('@571') !== -1;
    },
    disable708: function disable708() {
      var _this4 = this;

      // 判断1级归类是不是708
      var newSelect = this.oneLevelSelect.filter(function (item) {
        return item.id === _this4.consultOrderInfo.oneLevel;
      });

      if (newSelect.length === 0) {
        return false;
      }

      return newSelect[0].name.toLowerCase().indexOf('708') !== -1;
    },
    registerTitle: function registerTitle() {
      return this.command === '2' ? '仅登记不注册' : '注册产品信息';
    },
    showChangeOrderBtn: function showChangeOrderBtn() {
      return this.isEdit && this.consultOrderInfo.type !== '06' && this.consultOrderInfo.type !== '12' || this.showBtn;
    },
    showGDPR: function showGDPR() {
      return !this.isEdit && this.consultOrderInfo.type === '12';
    },
    // 清洁服务类型的工单是否可以转单
    disableCleanType: function disableCleanType() {
      var _this5 = this;

      // 如果二级分类包括CSRM清洁服务，就必须选择一个优惠券
      if (this.consultOrderInfo.twoLevel) {
        var newSelect = this.twoLevelSelect.filter(function (item) {
          return item.id === _this5.consultOrderInfo.twoLevel;
        });

        if (newSelect.length !== 0 && newSelect[0].name.indexOf('CSRM清洁服务') !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    signDate: function signDate(val) {
      if (!val) {
        this.promotionMessage = '';
      }
    },
    signDate2: function signDate2(val) {
      if (!val) {
        this.promotionMessage2 = '';
      }
    },
    showLabel: function showLabel(val) {
      if (!val) {
        this.clearLabelInfo();
      }
    },
    'consultOrderInfo.applicantTel': function consultOrderInfoApplicantTel(val) {
      if (!val || val.length !== 11) {
        this.riskId = '';
        return;
      }

      this.queryRisk();
    },
    'ownCustomerProduct.serialNumber': function ownCustomerProductSerialNumber(val) {
      if (!val || val.length !== 15) {
        this.snRiskId = '';
        return;
      }

      this.consultOrderInfo.prodName = val;

      if (this.riskList.length > 0) {
        this.initLabelInfo(this.riskList);
      }

      this.querySnRisk();
    }
  },
  // 关闭前结束定时器
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    this.timer = '';
  },
  created: function created() {
    this.getOneLevelOptions();
    this.getDetail();
    this.initSelect();
  },
  activated: function activated() {
    this.getOneLevelOptions();
    this.getDetail();
  },
  methods: {
    changeChannel: function changeChannel(val) {
      if (val === '04' || val === '05') {
        this.accessChannelOptions = this.accessChannelOptions1;
      } else {
        this.accessChannelOptions = this.accessChannelOptions2;
      }

      if (val === '11') {
        this.showOverseas = true;
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.queryHistory();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.queryHistory();
    },
    handleChange: function handleChange(val) {},
    setFilePaths: function setFilePaths(data) {
      this.uploadInfo.filePath = data;
    },
    setFilePath: function setFilePath(data) {
      this.uploadInfo.fileId = data;
    },
    setFile: function setFile(data) {
      this.uploadInfo.type = data.raw.type;
      this.$refs.content.$el.querySelector('input').focus();
    },
    // 待客户上传
    handleUpload: function handleUpload() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if ((_this6.consultOrderInfo.type === '01' || _this6.consultOrderInfo.type === '02' || _this6.consultOrderInfo.type === '03') && _this6.consultOrderInfo.accessChannel !== '5') {
            if (_this6.consultOrderInfo.crmPhone === undefined || _this6.consultOrderInfo.crmPhone === null || _this6.consultOrderInfo.crmPhone === '') {
              _this6.$message({
                showClose: true,
                message: '客户手机号必须填！',
                type: 'warning'
              });

              return false;
            }

            if (_this6.consultOrderCustomerInfo.mobile === undefined || _this6.consultOrderCustomerInfo.mobile === null || _this6.consultOrderCustomerInfo.mobile === '') {
              _this6.$message({
                showClose: true,
                message: '转工单时必须要有客户手机号！',
                type: 'warning'
              });

              return false;
            }
          } // 维修、换货、检测 转服务工单   转销售 、转退货


          var num = 0;
          var list = [];

          if (_this6.consultOrderInfo.choosedProductType === '2') {
            list = _this6.enrollmentProductList;
          } else {
            list = _this6.CustomerProductList;
          }

          list.forEach(function (item, index) {
            if (item.choose) {
              num = num + 1;
              _this6.prodName = item.serialNumber;
              _this6.consultOrderInfo.prodName = item.serialNumber;
            }
          }); // 选中的产品数量不能大于1

          if (num > 1) {
            _this6.$message({
              showClose: true,
              message: '只能选择一个产品',
              type: 'warning'
            });

            return;
          }

          _this6.dialogType = '7'; // 待客户上传 确认弹出框

          _this6.dialogMsg = ' 请确认是否发送短信链接给到客户进行附件上传';
          _this6.dialogVisible = true;
        }
      });
    },
    // 客户上传图片
    addDomain: function addDomain() {
      this.uploadInfo = {};
      this.uploadDialogVisible = true;
    },
    // 删除图片
    removeDomain: function removeDomain(item) {
      var _this7 = this;

      // 删除图片保存所选产品信息
      var num = 0;
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          num = num + 1;
          _this7.prodName = item.serialNumber;
          _this7.consultOrderInfo.prodName = item.serialNumber;
        }
      }); // 选中的产品数量不能大于1

      if (num > 1) {
        this.$message({
          showClose: true,
          message: '只能选择一个产品',
          type: 'warning'
        });
        return;
      }

      this.consultOrderInfo.uploadInfo = item;
      (0, _list.delUpload)(this.consultOrderInfo).then(function (response) {
        _this7.btnLoading = false;

        if (response.data) {
          _this7.getDetail();
        } else {
          _this7.$message({
            showClose: true,
            message: response.msg || '操作失败',
            type: 'warning'
          });
        }
      });
    },
    // 提交图片
    submitUpload: function submitUpload() {
      var _this8 = this;

      if (this.uploadInfo.fileId === undefined || this.uploadInfo.fileId === null || this.uploadInfo.fileId === '') {
        this.$message({
          showClose: true,
          message: '请上传文件！',
          type: 'warning'
        });
        return false;
      }

      if (this.uploadInfo.content === undefined || this.uploadInfo.content === null || this.uploadInfo.content === '') {
        this.$message({
          showClose: true,
          message: '请填写描述！',
          type: 'warning'
        });
        return false;
      } // 上传图片保存所选产品信息


      var num = 0;
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          num = num + 1;
          _this8.prodName = item.serialNumber;
          _this8.consultOrderInfo.prodName = item.serialNumber;
        }
      }); // 选中的产品数量不能大于1

      if (num > 1) {
        this.$message({
          showClose: true,
          message: '只能选择一个产品',
          type: 'warning'
        });
        return;
      } // 保存图片


      this.uploadInfo.parentId = this.consultOrderInfo.id;
      this.uploadInfo.num = this.consultOrderInfo.num;
      this.consultOrderInfo.uploadInfo = this.uploadInfo;
      (0, _list.saveUpload)(this.consultOrderInfo).then(function (response) {
        _this8.btnLoading = false;

        if (response.data) {
          _this8.uploadInfo = {};

          _this8.$refs.upload1.handleClearFiles();

          _this8.uploadDialogVisible = false;

          _this8.getDetail();
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg || '操作失败',
            type: 'warning'
          });
        }
      });
    },
    // 取消
    resetUpload: function resetUpload() {
      this.uploadInfo = {
        fileId: '',
        content: '',
        filePath: ''
      };
      this.$refs.upload1.handleClearFiles();
      this.uploadDialogVisible = false;
    },
    // 转工单
    changeOrder: function changeOrder() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // 首先判断是否符合转vse的条件
          if (!_this9.consultOrderVseInfo.id && !_this9.disableVSE) {
            _this9.showVSEDialog = true;
            return;
          }

          if (_this9.consultOrderVseInfo.isVse === '0' && !_this9.consultOrderVseInfo.note) {
            _this9.showVSEDialog = true;
            return;
          }

          if ((_this9.consultOrderInfo.type === '01' || _this9.consultOrderInfo.type === '02' || _this9.consultOrderInfo.type === '03') && _this9.consultOrderInfo.accessChannel !== '5') {
            if (_this9.consultOrderInfo.crmPhone === undefined || _this9.consultOrderInfo.crmPhone === null || _this9.consultOrderInfo.crmPhone === '') {
              _this9.$message({
                showClose: true,
                message: '客户手机号必须填！',
                type: 'warning'
              });

              return false;
            }

            if (_this9.consultOrderCustomerInfo.mobile === undefined || _this9.consultOrderCustomerInfo.mobile === null || _this9.consultOrderCustomerInfo.mobile === '') {
              _this9.$message({
                showClose: true,
                message: '转工单时必须要有客户手机号！',
                type: 'warning'
              });

              return false;
            }
          } // 维修、换货、检测 转服务工单   转销售 、转退货


          var flag = false;
          var num = 0;
          var list = [];

          if (_this9.consultOrderInfo.choosedProductType === '2') {
            list = _this9.enrollmentProductList;
          } else {
            list = _this9.CustomerProductList;
          }

          list.forEach(function (item, index) {
            if (item.choose) {
              flag = true;
              num = num + 1;
              _this9.prodName = item.serialNumber;
              _this9.consultOrderInfo.prodName = item.serialNumber;
            }
          }); // 选中的产品数量不能大于1

          if (num > 1) {
            _this9.$message({
              showClose: true,
              message: '只能选择一个产品',
              type: 'warning'
            });

            return;
          } // vse单则判断接单时间有没有填


          if (_this9.consultOrderInfo.subState === '12' && !_this9.consultOrderInfo.vseStartTime) {
            _this9.$message({
              showClose: true,
              message: '请选择vse接单时间',
              type: 'warning'
            });

            return;
          } // 如果是清洁服务单据必须选择优惠券才能提交


          if (_this9.disableCleanType) {
            // 沒有勾選券不允许转单
            if (!_this9.coupon.choose) {
              _this9.$message({
                showClose: true,
                message: '请先选择优惠券后提交',
                type: 'warning'
              });

              return;
            } else {
              if (!_this9.coupon.serviceFeeId) {
                _this9.$message({
                  showClose: true,
                  message: '请选择服务SKU后提交',
                  type: 'warning'
                });

                return;
              }
            }
          }

          if (_this9.consultOrderInfo.type === '01' || _this9.consultOrderInfo.type === '02' || _this9.consultOrderInfo.type === '03') {
            // 如果是海外机，不管有没有 选产品，都走无产品的转工单
            if (_this9.consultOrderInfo.accessChannel === '5') {
              flag = false;
            } // 判断有没有选产品 对应 转 服务工单 和 无SN的服务工单


            if (flag) {
              _this9.dialogType = '1'; // 选了产品的转服务工单 确认弹出框

              _this9.dialogMsg = '请确认是否针对产品 [ ' + _this9.prodName + ' ] 转服务工单';
              _this9.dialogVisible = true;
            } else {
              _this9.dialogType = '2'; // 没有选产品的转工单确认弹出框

              _this9.dialogMsg = '请确认是否不针对该用户任一条注册产品进行转工单';
              _this9.dialogVisible = true;
            }
          } // 销售转销售单


          if (_this9.consultOrderInfo.type === '04') {
            _this9.dialogType = '4'; // 销售转工单确认弹出框

            _this9.dialogMsg = '请确认是否转销售工单';
            _this9.dialogVisible = true;
          } // 退货转退货单


          if (_this9.consultOrderInfo.type === '05') {
            _this9.dialogType = '5'; // 退货转工单确认弹出框

            _this9.dialogMsg = '请确认是否转退货工单';
            _this9.dialogVisible = true;
          } // 转仅退款


          if (_this9.consultOrderInfo.type === '10') {
            _this9.dialogType = '10'; // 仅退款确认弹出框

            _this9.dialogMsg = '请确认是否转仅退款工单';
            _this9.dialogVisible = true;
          }
        } else {
          _this9.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 转服务工单 保存咨询工单信息
    comfirmToOrder: function comfirmToOrder(type) {
      var _this10 = this;

      this.btnLoading = false;
      this.consultOrderInfo.consultOrderCustomerInfo = this.consultOrderCustomerInfo;
      this.consultOrderInfo.CustomerProductList = this.CustomerProductList;
      this.consultOrderInfo.consultOrderCoupon = this.coupon;

      if (type === '3') {
        // 【完结咨询工单】
        this.dialogVisible1 = true;
      }

      if (type === '1' || type === '2' || type === '4' || type === '5' || type === '10') {
        // 【转工单】
        (0, _list.createData)(this.consultOrderInfo).then(function (response) {
          _this10.btnLoading = false;

          if (response !== null && response.code === 200) {
            _this10.dialogVisible = false;

            if (type === '1' || type === '2') {
              // 选产品和不选产品，转服务工单（包含三种状态：维修，换货，检测）
              _this10.$router.push({
                name: 'consultCreateDetail',
                params: {
                  id: _this10.id
                }
              });
            }

            if (type === '4') {
              // 转销售单
              _this10.$router.push({
                name: 'orderSaleCreate',
                params: {
                  id: _this10.id,
                  dialogStatus: 'create',
                  callId: '1'
                }
              });
            }

            if (type === '5') {
              // 转退货单
              _this10.$router.push({
                name: 'orderSaleReturnCreate',
                params: {
                  id: _this10.id,
                  dialogStatus: 'create',
                  callId: '1'
                }
              });
            }

            if (type === '10') {
              // 转仅退款单
              _this10.$router.push({
                name: 'orderSaleReturnCreate2',
                params: {
                  id: _this10.id,
                  dialogStatus: 'create',
                  callId: '1'
                }
              });
            }
          } else {
            _this10.$message({
              showClose: true,
              message: response.msg || '操作失败！',
              type: 'warning'
            });
          }
        });
      } // 待客户上传文件 确认


      if (type === '7') {
        // 待客户上传 ，发送短信
        this.dialogVisible = false;
        (0, _list.handleUploader)(this.consultOrderInfo).then(function (response) {
          _this10.btnLoading = false;

          if (response.data) {
            _this10.getDetail();
          } else {
            _this10.$message({
              showClose: true,
              message: response.msg || '操作失败',
              type: 'warning'
            });
          }
        });
      }
    },
    // 完结咨询工单
    finishOrder: function finishOrder() {
      var _this11 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // 首先判断是否符合转vse的条件
          if (!_this11.consultOrderVseInfo.id && !_this11.disableVSE) {
            _this11.showVSEDialog = true;
            return;
          }

          if (_this11.consultOrderVseInfo.isVse === '0' && !_this11.consultOrderVseInfo.note) {
            _this11.showVSEDialog = true;
            return;
          } // vse单则判断接单时间有没有填


          if (_this11.consultOrderInfo.subState === '12' && !_this11.consultOrderInfo.vseStartTime) {
            _this11.$message({
              showClose: true,
              message: '请选择vse接单时间',
              type: 'warning'
            });

            return;
          }

          _this11.dialogType = '3'; // 选了产品的转服务工单 确认弹出框

          _this11.dialogMsg = '请确认是否完结咨询工单'; // 弹出框提示信息

          _this11.dialogVisible = true;
        } else {
          _this11.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 完结-》再次确认是否已解决客户问题
    comfirmAgain: function comfirmAgain(type) {
      var _this12 = this;

      this.consultOrderInfo.isResolve = type;
      (0, _list.finishConsultOrder)(this.consultOrderInfo).then(function (response) {
        _this12.btnLoading = false;

        if (response.data) {
          _this12.$store.dispatch('tagsView/delView', _this12.$route);

          _this12.$router.push({
            path: '/consult/consult_order'
          });
        } else {
          _this12.$message({
            showClose: true,
            message: response.msg || '操作失败！',
            type: 'warning'
          });
        }
      });
    },
    // 是否已解决客户问题：否
    comfirmAgainNo: function comfirmAgainNo() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    },
    // 初始查询咨询工单信息
    getDetail: function getDetail() {
      var _this13 = this;

      this.btnLoading = false;
      (0, _list.queryById)(this.id).then(function (response) {
        _this13.btnLoading = false;

        if (response.data) {
          _this13.consultOrderInfo = response.data; // 新建和处理中状态，可操作

          if (response.data.state === '00' || response.data.state === '10') {
            _this13.isEdit = true;
          } else {
            _this13.isEdit = false;
          }

          if (response.data.state === '00') {
            _this13.isupload = true;
          } else {
            _this13.isupload = false;
          }

          if (response.data.subState === '10') {
            _this13.isEdit = false;
            _this13.showBtn = true;
            _this13.isupload = false;
          } // 二级归类回显


          if (_this13.consultOrderInfo.oneLevel != null && _this13.consultOrderInfo.oneLevel !== '') {
            (0, _list2.getConsultTypeByParentId)(_this13.consultOrderInfo.oneLevel).then(function (response) {
              _this13.twoLevelSelect = response.data;
            });
          } // 三级归类回显


          if (_this13.consultOrderInfo.twoLevel != null && _this13.consultOrderInfo.twoLevel !== '') {
            (0, _list2.getConsultTypeByParentId)(_this13.consultOrderInfo.twoLevel).then(function (response) {
              _this13.threeLevelSelect = response.data;
            });
          } // isSearch 是否可查询
          // 客户信息


          if (response.data.consultOrderCustomer) {
            _this13.consultOrderCustomerInfo = response.data.consultOrderCustomer;
            _this13.isSearch = false;
            _this13.isSearch2 = false;
          } else {
            _this13.isSearch = response.data.state === '00' || response.data.state === '10';
            _this13.isSearch2 = response.data.state === '00' || response.data.state === '10';
          } // 产品信息 + 选中


          _this13.CustomerProductList = [];

          if (response.data.customerProductList) {
            response.data.customerProductList.forEach(function (item, index) {
              if (item.choose) {
                _this13.prodName = item.serialNumber;
                _this13.consultOrderInfo.prodName = item.serialNumber;

                _this13.activeNames.push(index);
              }

              item.prdName = index + 1 + '. ' + item.prdName;
            });
            _this13.CustomerProductList = response.data.customerProductList;
          } // 仅登记产品信息


          if (response.data.enrollmentProductList) {
            _this13.enrollmentProductList = response.data.enrollmentProductList;
            _this13.enrollmentProductList2 = response.data.enrollmentProductList;

            _this13.enrollmentProductList.forEach(function (item, index) {
              item.prdName = index + 1 + '. ' + item.prdName;
            });
          } // 上传文件信息


          if (response.data.uploadList) {
            _this13.uploadList = [];
            response.data.uploadList.forEach(function (item, index) {
              if (item.flag === '0') {
                item.fileId = process.env.VUE_APP_S3_STORAGE_URL + item.fileId;
              }
            });
            _this13.uploadList = response.data.uploadList;
          }

          if (response.data.stateList !== null) {
            _this13.expressStateInfoList = response.data.stateList;
          }

          if (response.data.routeList !== null) {
            _this13.expressRouteInfoList = response.data.routeList;
          } // 再查询是否需要添加不转vse备注


          (0, _list.getConsultOrderVseInfo)(_this13.consultOrderInfo.num).then(function (res) {
            if (res.data && res.code === 200) {
              _this13.consultOrderVseInfo = res.data;
            }
          });

          _this13.$nextTick(function () {
            _this13.$refs['dataForm'].clearValidate();
          }); // 查询京东补充信息


          if (_this13.consultOrderInfo.jdOrderNo !== '' && _this13.consultOrderInfo.jdOrderNo !== null) {
            (0, _list.getJdOrderSupplyInfo)(_this13.consultOrderInfo.jdOrderNo).then(function (info) {
              if (info.data && info.code === 200) {
                _this13.jdSupplyInfo = info.data.supplyInfo;
              }
            });
          } // 初始化标签信息


          if (_this13.consultOrderInfo.labelInfoList) {
            _this13.riskList = _this13.consultOrderInfo.labelInfoList;

            _this13.initLabelInfo(_this13.consultOrderInfo.labelInfoList);
          }
        }
      });
    },
    // 根据手机号查询客户信息和产品信息
    // 区分crm查询和csrm查询
    queryCusAndProd: function queryCusAndProd() {
      var _this14 = this;

      this.btnLoading = false;
      (0, _list.queryCusAndProd)(this.consultOrderInfo.crmPhone, this.consultOrderInfo.num).then(function (response) {
        if (response.data) {
          if (response.data.consultOrderCustomer) {
            _this14.consultOrderCustomerInfo = response.data.consultOrderCustomer;
            _this14.ownCustomer = response.data.consultOrderCustomer;
            _this14.consultOrderInfo.applicantMan = _this14.consultOrderCustomerInfo.name;
            _this14.consultOrderInfo.applicantTel = _this14.consultOrderCustomerInfo.mobile;
            _this14.isSearch = false;
          } else {
            _this14.$message({
              showClose: true,
              message: response.msg || '该手机号在系统中没有查到客户信息！',
              type: 'warning'
            });

            _this14.isSearch = true;
          }

          if (response.data.customerProductList) {
            _this14.CustomerProductList = response.data.customerProductList;
            _this14.CustomerProductList2 = response.data.customerProductList;

            _this14.CustomerProductList.forEach(function (item, index) {
              item.prdName = index + 1 + '. ' + item.prdName;
              var equityExtendDate = item.equityExtendDate;

              if (equityExtendDate === null || equityExtendDate === '' || equityExtendDate === undefined) {
                equityExtendDate = 0;
              }

              var extendBenefitDate = item.extendBenefitDate;

              if (extendBenefitDate === null || extendBenefitDate === '' || extendBenefitDate === undefined) {
                extendBenefitDate = 0;
              }

              var extendDate = Number(extendBenefitDate) + Number(equityExtendDate);

              if (extendDate === 0) {
                item.extendBenefitDate = '';
              } else {
                item.extendBenefitDate = extendDate + '天';
              }
            });
          }

          if (response.data.enrollmentProductList) {
            _this14.enrollmentProductList = response.data.enrollmentProductList;
            _this14.enrollmentProductList2 = response.data.enrollmentProductList;

            _this14.enrollmentProductList.forEach(function (item, index) {
              item.prdName = index + 1 + '. ' + item.prdName;
              item.warrantyNote = '';
            });

            _this14.enrollmentProductList2.forEach(function (item) {
              item.warrantyNote = '';
            });
          } // 初始化标签信息


          if (response.data.labelInfoList) {
            _this14.riskList = response.data.labelInfoList;

            _this14.initLabelInfo(response.data.labelInfoList);
          }

          _this14.btnLoading = false;

          _this14.$nextTick(function () {
            _this14.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 新建页，模糊查询产品/sn
    queryCustomerProduct: function queryCustomerProduct() {
      var _this15 = this;

      var CustomerProductList1 = [];

      if (this.productSearch !== '') {
        this.productSearch = this.productSearch.trim().toLowerCase();
        this.CustomerProductList2.forEach(function (item, index) {
          item.prdName = item.modelName + ' [ ' + item.serialNumber + ' ]';

          if (item.prdName.toLowerCase().indexOf(_this15.productSearch) !== -1) {
            CustomerProductList1.push(item);
          }
        });

        if (CustomerProductList1.length === 0) {
          this.CustomerProductList = [];
        } else {
          CustomerProductList1.forEach(function (item, index) {
            item.prdName = index + 1 + '. ' + item.prdName;
          });
          this.CustomerProductList = CustomerProductList1;
        }
      } else {
        this.CustomerProductList2.forEach(function (item, index) {
          item.prdName = index + 1 + '. ' + item.modelName + ' [ ' + item.serialNumber + ' ]'; // this.activeNames.push(index)
        });
        this.CustomerProductList = this.CustomerProductList2;
      }
    },
    // 过滤仅登记sn
    filterProduct: function filterProduct() {
      var _this16 = this;

      this.enrollmentProductList = this.enrollmentProductList2;
      this.enrollmentProductList = this.enrollmentProductList.filter(function (prd) {
        return prd.prdName.toLowerCase().indexOf(_this16.enrollSnSearch.toLowerCase()) !== -1;
      });
    },
    // 查询条件清空
    clearSearch: function clearSearch() {
      var _this17 = this;

      this.isSearch = true;
      this.consultOrderCustomerInfo = {};
      this.CustomerProductList = [];
      this.enrollmentProductList = [];
      this.ownCustomer = {};
      this.consultOrderInfo.crmPhone = '';
      (0, _list.clearLabels)(this.consultOrderInfo.num).then(function (res) {
        if (res && res.code === 200) {
          _this17.riskList = [];

          _this17.initLabelInfo(_this17.riskList);
        }
      });
    },
    // 清空产品查询清空
    clearProductSearch: function clearProductSearch() {
      this.isProSearch = true;
      this.modelSelect = [];
      this.systemModelList = [];
      this.ownCustomerProduct.serialNumber = '';
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route); // this.$router.go(-1) // 返回上一层

      this.$router.push({
        path: '/consult/consult_order'
      });
    },
    // 初始化咨询单一级归类
    getOneLevelOptions: function getOneLevelOptions() {
      var _this18 = this;

      (0, _list2.getConsultTypeByParentId)('0').then(function (res) {
        _this18.oneLevelSelect = res.data;
      });
    },
    // 咨询单二级归类
    oneLevelChange: function oneLevelChange(oneLevel) {
      var _this19 = this;

      if (oneLevel) {
        (0, _list2.getConsultTypeByParentId)(oneLevel).then(function (response) {
          _this19.twoLevelSelect = response.data;
        });
      }

      this.consultOrderInfo.twoLevel = '';
      this.consultOrderInfo.threeLevel = '';
    },
    // 咨询单三级归类
    twoLevelChange: function twoLevelChange(twoLevel) {
      var _this20 = this;

      if (twoLevel) {
        (0, _list2.getConsultTypeByParentId)(twoLevel).then(function (response) {
          _this20.threeLevelSelect = response.data;
        });
      }

      this.consultOrderInfo.customChannel = this.twoLevelSelect.filter(function (item) {
        return item.id === twoLevel;
      })[0].code;
      this.consultOrderInfo.threeLevel = '';
    },
    // 接口查询resolve 解决方案
    resolveClick: function resolveClick() {
      var _this21 = this;

      // 维修、换货、检测 转服务工单   转销售 、转退货
      var num = 0;
      var sku = '';
      var list = [];

      if (this.disable708) {
        if (!this.consultOrderInfo.skuCode708) {
          this.$message.warning('请先选择708sku!');
          return;
        }

        sku = this.consultOrderInfo.skuCode708;
      } else {
        if (this.consultOrderInfo.choosedProductType === '2') {
          list = this.enrollmentProductList;
        } else {
          list = this.CustomerProductList;
        }

        list.forEach(function (item, index) {
          if (item.choose) {
            num = num + 1;
            sku = item.sku;
          }
        });
      }

      var param = {
        sku: sku,
        consultNum: this.consultOrderInfo.num
      };
      (0, _list.getTokenUrl)(param).then(function (response) {
        if (response.data != null) {
          window.open(response.data, '_blank');
          _this21.timer = setInterval(_this21.getResolveMemo, 3000);
        } else {
          _this21.$message({
            showClose: true,
            message: response.msg || '操作失败！',
            type: 'warning'
          });
        }
      });
    },
    // 定时拿取resolve查询结果
    getResolveMemo: function getResolveMemo() {
      var _this22 = this;

      this.interValNum = this.interValNum + 1;

      if (this.interValNum > 60) {
        clearInterval(this.timer);
        return;
      } // console.log(this.interValNum)


      (0, _list.getResolveResult)(this.consultOrderInfo.num).then(function (response) {
        // console.log(response)
        if (response.data != null && response.data !== '') {
          _this22.consultOrderInfo.resolveMemo = response.data;
          clearInterval(_this22.timer);
        }
      });
    },
    // 业务类型格式化
    typeFormat: function typeFormat(row) {
      return this.typeOptions.map(function (type) {
        if (row.type !== null) {
          if (row.type.trim() === type.val) {
            return type.name;
          }
        }

        return '';
      });
    },
    // 工单主状态格式化
    stateFormat: function stateFormat(row) {
      if (row.type === '01' || row.type === '02' || row.type === '03') {
        return this.orderStateOptions.map(function (type) {
          if (row.state !== null) {
            if (row.state.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }

      if (row.type === '04') {
        return this.saleStateOptions.map(function (type) {
          if (row.state !== null) {
            if (row.state.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }

      if (row.type === '05') {
        return this.returnStateOptions.map(function (type) {
          if (row.state !== null) {
            if (row.state.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }
    },
    // 工单子状态格式化
    subStateFormat: function subStateFormat(row) {
      if (row.type === '01' || row.type === '02' || row.type === '03') {
        return this.orderSubStateOptions.map(function (type) {
          if (row.subState !== null) {
            if (row.subState.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }

      if (row.type === '04') {
        return this.saleSubStateOptions.map(function (type) {
          if (row.subState !== null) {
            if (row.subState.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }

      if (row.type === '05') {
        return this.returnSubStateOptions.map(function (type) {
          if (row.subState !== null) {
            if (row.subState.trim() === type.val) {
              return type.name;
            }
          }

          return '';
        });
      }
    },
    // 跳转业务单详情页面
    gotoQueryHtml: function gotoQueryHtml(row) {
      if (row.type === '01' || row.type === '02' || row.type === '03') {
        this.$router.push({
          name: 'ordercancelDetail',
          params: {
            id: row.id,
            callId: this.consultOrderInfo.id
          }
        });
      }

      if (row.type === '04') {
        // 转销售单
        this.$router.push({
          name: 'orderSaleCreate',
          params: {
            id: row.id,
            dialogStatus: 'cancel',
            callId: this.consultOrderInfo.id
          }
        });
      }

      if (row.type === '05') {
        // 转退货单
        this.$router.push({
          name: 'orderSaleReturnCreate',
          params: {
            id: row.id,
            dialogStatus: 'cancel',
            callId: this.consultOrderInfo.id
          }
        });
      }
    },
    // 查询历史工单记录
    queryHistory: function queryHistory() {
      var _this23 = this;

      // 保存暂存内容
      var num = 0;
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          num = num + 1;
          _this23.prodName = item.serialNumber;
          _this23.consultOrderInfo.prodName = item.serialNumber;
        }
      }); // 选中的产品数量不能大于1

      if (num > 1) {
        this.$message({
          showClose: true,
          message: '只能选择一个产品',
          type: 'warning'
        });
        return;
      }

      (0, _list.tempSave)(this.consultOrderInfo).then(function (response) {});
      this.listQuery.num = this.consultOrderInfo.num;
      this.listQuery.phone = this.consultOrderInfo.crmPhone;
      this.listQuery.applicantTel = this.consultOrderInfo.applicantTel;
      (0, _list.queryOrderListByPhone)(this.listQuery).then(function (response) {
        if (response.data != null) {
          _this23.list = response.data.records;
          _this23.total = response.data.total;
          _this23.listLoading = false;
        }
      });
      this.$nextTick(function () {});
    },
    // 确认关闭
    closeDrawer: function closeDrawer(done) {
      var _this24 = this;

      this.$confirm('确认关闭？').then(function (_) {
        done();

        _this24.queryCusAndProd();
      }).catch(function (_) {});
    },
    // 提交自建产品信息 进行产品注册
    submitOwnProductInfo: function submitOwnProductInfo() {
      var _this25 = this;

      this.btnLoading = true;
      this.$refs['ownCustomerProductForm'].validate(function (valid) {
        if (valid) {
          _this25.ownCustomerProduct.mobile = _this25.consultOrderInfo.crmPhone;

          if (!_this25.ownCustomerProduct.mobile) {
            _this25.$message.error('注册手机号不能为空');

            return;
          }

          _this25.ownCustomerProduct.registerType = _this25.registerType;
          _this25.ownCustomerProduct.warrantyNote = (0, _utils.HTMLEncode)(_this25.ownCustomerProduct.warrantyNote);

          if (!_this25.ownCustomerProduct.country) {
            _this25.ownCustomerProduct.country = 'CN';
          } // 判断是仅登记还是注册


          if (_this25.command === '2') {
            (0, _productEnrollment.enrollProduct)(_this25.ownCustomerProduct).then(function (response) {
              _this25.btnLoading = false;

              if (response.data && response.code === 200) {
                _this25.$message.success(response.msg || '操作成功');

                _this25.drawer = false;

                _this25.$refs['ownCustomerProductForm'].resetFields();

                _this25.clearProductSearch();
              } else {
                _this25.$message({
                  showClose: true,
                  message: response.msg || '操作失败',
                  type: 'warning'
                });
              }

              _this25.queryCusAndProd();
            });
          } else {
            (0, _productRegister.registerProduct)(_this25.ownCustomerProduct).then(function (response) {
              _this25.btnLoading = false;

              if (response.data && response.code === 200) {
                _this25.$message.success(response.msg || '操作成功');

                _this25.drawer = false;

                _this25.$refs['ownCustomerProductForm'].resetFields();

                _this25.clearProductSearch();
              } else {
                _this25.$message({
                  showClose: true,
                  message: response.msg || '操作失败',
                  type: 'warning'
                });
              }

              _this25.queryCusAndProd();
            });
          }
        } else {
          _this25.btnLoading = false;
        }
      });
    },
    // 提交自建客户信息，首先校验是否发送验证码
    submitOwnCustomerInfo: function submitOwnCustomerInfo() {
      this.btnLoading = true;

      if (this.send) {
        this.checkSendCode();
      } else {
        this.commitForm();
      }
    },
    // 提交表单数据
    commitForm: function commitForm() {
      var _this26 = this;

      this.$refs['ownCustomerForm'].validate(function (valid) {
        if (valid) {
          (0, _customer.createOrUpdateOwnCustomer)(_this26.ownCustomer).then(function (response) {
            _this26.btnLoading = false;

            if (response.data) {
              _this26.$message.success(response.msg || '操作成功！');

              _this26.cusDrawer = false;

              _this26.$refs['ownCustomerForm'].resetFields();

              _this26.queryCusAndProd();
            } else {
              _this26.$message({
                showClose: true,
                message: response.msg || '操作失败',
                type: 'warning'
              });
            }
          });
        } else {
          _this26.btnLoading = false;
        }
      });
    },
    // 选择产品
    choosedProduct: function choosedProduct(product, choosed) {
      this.isOverseas = false;
      var templist = this.CustomerProductList.filter(function (item) {
        return item.choose;
      });

      if (choosed) {
        this.consultOrderInfo.prodName = product.serialNumber;
        this.initLabelInfo(this.riskList);
        this.ownCustomerProduct = product;
        this.ownCustomerProduct.cusPhone = this.consultOrderInfo.crmPhone;

        if (this.consultOrderInfo.state !== '20') {
          if (product.sku === '323071-01') {
            this.showPromoation2 = true;
          }
        }

        this.CustomerProductList.forEach(function (item) {
          if (item.serialNumber !== product.serialNumber) {
            item.choose = false;
          }
        });
        this.enrollmentProductList.forEach(function (item) {
          item.choose = false;
        });
      } else {
        if (templist.length === 1) {
          this.ownCustomerProduct = templist[0];
          this.consultOrderInfo.prodName = this.ownCustomerProduct.serialNumber;
          this.CustomerProductList.forEach(function (item) {
            if (item.serialNumber !== templist[0].serialNumber) {
              item.choose = false;
            }
          });
          this.enrollmentProductList.forEach(function (item) {
            item.choose = false;
          });
        }

        this.ownCustomerProduct.cusPhone = this.consultOrderInfo.crmPhone;
      }
    },
    // 选择产品
    choosedEnrollProduct: function choosedEnrollProduct(product, choosed) {
      var templist = this.enrollmentProductList.filter(function (item) {
        return item.choose;
      });

      if (choosed) {
        this.ownCustomerProduct = product;
        this.consultOrderInfo.prodName = product.serialNumber;
        this.ownCustomerProduct.cusPhone = this.consultOrderInfo.crmPhone;
        this.enrollmentProductList.forEach(function (item) {
          if (item.serialNumber !== product.serialNumber) {
            item.choose = false;
          }
        });
        this.CustomerProductList.forEach(function (item) {
          item.choose = false;
        });
      } else {
        if (templist.length === 1) {
          this.ownCustomerProduct = templist[0];
          this.consultOrderInfo.prodName = this.ownCustomerProduct.serialNumber;
          this.enrollmentProductList.forEach(function (item) {
            if (item.serialNumber !== templist[0].serialNumber) {
              item.choose = false;
            }
          });
          this.CustomerProductList.forEach(function (item) {
            item.choose = false;
          });
        }

        this.ownCustomerProduct.cusPhone = this.consultOrderInfo.crmPhone;
      }
    },
    // 显示表单
    showDrawer: function showDrawer(index, type) {
      this.command = type;
      this.registerType = index;

      if (!this.consultOrderInfo.crmPhone) {
        this.$message({
          showClose: true,
          message: '手机号是必填的！',
          type: 'warning'
        });
        return;
      }

      var num = 0;
      var tempList = [];

      if (type === '2') {
        tempList = this.enrollmentProductList;
      } else {
        tempList = this.CustomerProductList;
      }

      tempList.forEach(function (item) {
        if (item.choose) {
          num = num + 1;
        }
      });

      if (index === 1) {
        this.ownCustomerProduct = {};
        this.isSnEdit = true;
        this.isProSearch = true;
        this.isOverseas = false;
      } else {
        this.isSnEdit = false;
        this.isProSearch = false;

        if (num > 1) {
          this.$message({
            showClose: true,
            message: '只能选择一个产品',
            type: 'warning'
          });
          return;
        } else if (num === 0) {
          this.$message({
            showClose: true,
            message: '请先选择一个产品',
            type: 'warning'
          });
          return;
        }
      }

      this.warrantyDateOld = this.ownCustomerProduct.warrantyDate;
      this.drawer = true;
    },
    // 显示表单2
    showCusDrawer: function showCusDrawer() {
      if (!this.consultOrderInfo.crmPhone) {
        this.$message({
          showClose: true,
          message: '手机号是必填的！',
          type: 'warning'
        });
        return;
      }

      this.sendCode = '';
      this.ownCustomer.mobile = this.consultOrderInfo.crmPhone;
      this.cusDrawer = true;
    },
    // 监听sn输入
    checkSnIn: function checkSnIn(val) {
      var str = val.replace(/-/g, '');
      var str2 = '';

      if (str.length <= 3) {
        str2 = str;
      }

      if (str.length > 3 && str.length <= 5) {
        str2 = str.substring(0, 3) + '-';
        str2 = str2 + str.substring(3, str.length);
      }

      if (str.length > 5) {
        str2 = str.substring(0, 3) + '-';
        str2 = str2 + str.substring(3, 5);
        str2 = str2 + '-';
        str2 = str2 + str.substring(5, str.length);
      }

      this.ownCustomerProduct.serialNumber = str2.replace(/\s*/g, '').toUpperCase();
      this.overseasSaleReturn.serialNumber = str2.replace(/\s*/g, '').toUpperCase();

      if (this.ownCustomerProduct.serialNumber.length === 15) {
        var countryCode = this.ownCustomerProduct.serialNumber.substring(4, 6);

        if (countryCode.toUpperCase() === 'XD') {
          this.isOverseas = true;
        } else if (countryCode.toUpperCase() === 'CN') {
          this.isOverseas = false;
        } else {
          this.$message.warning('SN中间两位有误');
        }
      }
    },
    deleteConsultOrder: function deleteConsultOrder() {
      var _this27 = this;

      (0, _list.deleteConsult)(this.consultOrderInfo.id).then(function (response) {
        if (response.code === 200) {
          _this27.$message.success('作废成功！');

          _this27.$store.dispatch('tagsView/delView', _this27.$route); // this.$router.go(-1) // 返回上一层


          _this27.$router.push({
            path: '/consult/consult_order'
          });
        }
      });
    },
    // 显示提示信息
    showPromoationMessage: function showPromoationMessage() {
      var tempDate = (0, _index.convertStringToDate)(this.signDate);
      var beforeTime = (0, _index.convertStringToDate)('2020-11-19');
      var afterTime = (0, _index.convertStringToDate)('2020-11-29');

      if (tempDate) {
        if (tempDate >= beforeTime && tempDate <= afterTime) {
          this.promotionMessage = '当前填写的购买/签收日期可以享受30天内只换不修服务，转服务必须选择业务类型“维修”';
        } else if (tempDate < beforeTime || tempDate > afterTime) {
          this.promotionMessage = '请按正常流程处理PH02的售后服务诉求';
        }
      } else {
        this.promotionMessage = '';
      }
    },
    // 显示保修提示信息
    showPromoationMessage2: function showPromoationMessage2() {
      var tempDate = (0, _index.convertStringToDate)(this.signDate2);
      var time = (0, _index.convertStringToDate)('2020-12-12');

      if (tempDate) {
        if (tempDate >= time) {
          this.promotionMessage2 = '当前购买可享受2年保修服务';
        } else {
          this.promotionMessage2 = '当前购买可享受1年保修服务';
        }
      } else {
        this.promotionMessage2 = '';
      }
    },
    // 促销信息确认
    promotionConfirm: function promotionConfirm() {
      if (this.signDate) {
        this.showPromoation = false;
        this.signDate = '';
        this.promotionMessage = '';
      } else {
        this.$message.error('必须选择购买日期！');
      }
    },
    // 保修信息确认
    WarrantyConfirm: function WarrantyConfirm() {
      if (this.signDate2) {
        this.showPromoation2 = false;
        this.signDate2 = '';
        this.promotionMessage2 = '';
      } else {
        this.$message.error('必须选择购买日期！');
      }
    },
    // 发送验证码
    sendMsg: function sendMsg() {
      var _this28 = this;

      if (!this.isSend) {
        this.isSend = true;
        this.send = true;
        (0, _list.getSendCode)(this.ownCustomer.mobile, this.consultOrderInfo.num).then(function (response) {
          if (response.data && response.code === 200) {
            _this28.$message.success('发送成功');
          } else {
            _this28.$message.error(response.msg || '操作失败');
          }
        });
      }

      var timer = setInterval(function () {
        _this28.num--;
        _this28.sendMessage = _this28.num;

        if (_this28.num <= 0) {
          _this28.isSend = false;
          clearInterval(timer);
          _this28.num = 60;
          _this28.sendMessage = '重新发送';
        }
      }, 1000);
    },
    // 检测验证码
    checkSendCode: function checkSendCode() {
      var _this29 = this;

      if (!this.sendCode) {
        this.$message.warning('请输入验证码！');
        this.btnLoading = false;
        return;
      }

      (0, _list.checkSendCode)(this.sendCode, this.ownCustomer.mobile).then(function (response) {
        if (response.data && response.code === 200) {
          _this29.$message.success('验证通过！');

          _this29.commitForm();
        } else {
          _this29.btnLoading = false;

          _this29.$message.error('验证失败！');
        }
      });
    },
    // 取消
    cancelClick: function cancelClick() {
      this.cusDrawer = false;
      this.btnLoading = false;
    },
    // 根据手机号查询客户信息和产品信息
    queryCusAndProdByUnionId: function queryCusAndProdByUnionId() {
      var _this30 = this;

      this.btnLoading = false;

      if (!this.applicantUnionid) {
        this.$message.warning('unionId不能为空！');
        return;
      }

      (0, _list.queryCusAndProdByUnionId)(this.applicantUnionid).then(function (response) {
        if (response.data) {
          if (response.data.consultOrderCustomer) {
            _this30.consultOrderCustomerInfo = response.data.consultOrderCustomer;
            _this30.consultOrderInfo.applicantMan = _this30.consultOrderCustomerInfo.name;
            _this30.ownCustomer = response.data.consultOrderCustomer;
            _this30.isSearch2 = false;
          } else {
            _this30.$message({
              showClose: true,
              message: response.msg || '该unionId在系统中没有查到客户信息！',
              type: 'warning'
            });

            _this30.isSearch2 = true;
          }

          if (response.data.customerProductList != null) {
            _this30.CustomerProductList = response.data.customerProductList;
            _this30.CustomerProductList2 = response.data.customerProductList;

            _this30.CustomerProductList.forEach(function (item, index) {
              item.prdName = index + 1 + '. ' + item.prdName;
            });
          }

          _this30.btnLoading = false;

          _this30.$nextTick(function () {
            _this30.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 查询条件清空
    clearSearch2: function clearSearch2() {
      this.isSearch2 = true;
      this.consultOrderCustomerInfo = {};
      this.CustomerProductList = [];
      this.ownCustomer = {};
      this.applicantUnionid = '';
    },
    // 预订VSE
    bookVSE: function bookVSE() {
      var _this31 = this;

      if (!this.consultOrderInfo.crmPhone) {
        this.$message.warning('请输入手机号!');
        return;
      } // 只能选一个产品


      var num = 0;
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          num = num + 1;
          _this31.prodName = item.serialNumber;
          _this31.consultOrderInfo.prodName = item.serialNumber;
        }
      }); // 选中的产品数量不能大于1

      if (num > 1) {
        this.$message({
          showClose: true,
          message: '只能选择一个产品',
          type: 'warning'
        });
        return;
      }

      this.btnLoading = true;

      if (this.consultOrderInfo.twoLevel) {
        this.consultOrderInfo.customChannel = this.twoLevelSelect.filter(function (item) {
          return item.id === _this31.consultOrderInfo.twoLevel;
        })[0].code;
      }

      if (!this.chooseValue) {
        (0, _list.bookVSE)(this.consultOrderInfo).then(function (response) {
          if (response.data && response.code === 200) {
            _this31.$message.success('已经成功预订VSE工程师并已经给用户发送短信链接！');

            _this31.operateTime = true;

            _this31.getDetail();
          } else {
            _this31.$message.error(response.msg || '预订失败！');
          }

          _this31.vseVisiable = false;
          _this31.btnLoading = false; // 再查询是否需要添加不转vse备注

          (0, _list.getConsultOrderVseInfo)(_this31.consultOrderInfo.num).then(function (res) {
            if (res.data && res.code === 200) {
              _this31.consultOrderVseInfo = res.data;
            }
          });
        });
      } else {
        if (!this.bookTime) {
          this.$message.warning('预约时间段是必选的');
          return;
        }

        this.consultOrderInfo.bookTime = this.bookTime;
        this.consultOrderInfo.bookDate = this.bookDate;
        (0, _list.preBookVSE)(this.consultOrderInfo).then(function (response) {
          if (response.data && response.code === 200) {
            _this31.$message.success('预约成功！');
          } else {
            _this31.$message.error(response.msg || '预订失败！');
          }

          _this31.vseVisiable = false;
          _this31.btnLoading = false;
        });
      }
    },
    // 发送vse短信
    sendSms: function sendSms() {
      var _this32 = this;

      if (!this.consultOrderInfo.crmPhone) {
        this.$message.warning('请输入手机号!');
        return;
      }

      (0, _list.sendSms)(this.consultOrderInfo.id, this.consultOrderInfo.crmPhone).then(function (response) {
        if (response.data && response.code === 200) {
          _this32.$message.success('发送成功！');
        } else {
          _this32.btnLoading = false;

          _this32.$message.error(response.msg || '发送失败！');
        }
      });
    },
    // 暂存
    tempSave: function tempSave() {
      var _this33 = this;

      (0, _list.tempSave)(this.consultOrderInfo).then(function (response) {
        if (response.data && response.code === 200) {
          _this33.$message.success('操作成功！');
        } else {
          _this33.$message.error(response.msg || '操作失败！');
        }
      });
    },
    // 获得海外机信息
    getOverseasInfo: function getOverseasInfo() {
      var _this34 = this;

      if (!this.ownCustomerProduct.serialNumber) {
        this.$message.warning('请先填写序列号');
        return;
      }

      if (this.ownCustomerProduct.serialNumber.length < 10) {
        this.$message.warning('序列号长度错误');
        return;
      }

      var countryCode = this.ownCustomerProduct.serialNumber.substring(4, 6);

      if (countryCode.toUpperCase() === 'XD') {
        this.isOverseas = true;

        if (!this.ownCustomerProduct.purchaseDate || !this.ownCustomerProduct.country || !this.ownCustomerProduct.globalWarranty || !this.ownCustomerProduct.productDate || !this.ownCustomerProduct.warrantyDate) {
          this.$message.warning('请补充海外机信息');
        }
      } else if (countryCode.toUpperCase() === 'CN') {
        this.isOverseas = false;
        this.$message.success('验证通过');
      } else {
        this.$message.warning('SN中间两位有误');
        return;
      }

      (0, _list.getOverseasInfo)(this.ownCustomerProduct.serialNumber, this.ownCustomerProduct.country, this.command).then(function (res) {
        if (res.data && res.code === 200) {
          if (res.data.id) {
            _this34.$set(_this34.ownCustomerProduct, 'productDate', res.data.productDate);

            _this34.$set(_this34.ownCustomerProduct, 'warrantyDate', res.data.warrantyDate);

            _this34.$set(_this34.ownCustomerProduct, 'globalWarranty', res.data.globalWarranty);
          } else {
            _this34.$set(_this34.ownCustomerProduct, 'productDate', res.data.productDate);

            _this34.$set(_this34.ownCustomerProduct, 'warrantyDate', res.data.warrantyDate);

            _this34.$set(_this34.ownCustomerProduct, 'globalWarranty', false);
          }

          _this34.warrantyDateOld = _this34.ownCustomerProduct.warrantyDate;
        } else {
          _this34.$message.warning(res.msg || '系统异常');
        }
      });
    },
    // 保存海外退货单信息
    saveOverseasSaleReturn: function saveOverseasSaleReturn() {
      var _this35 = this;

      this.$refs['overseasForm'].validate(function (valid) {
        if (valid) {
          _this35.btnLoading = true;
          _this35.overseasSaleReturn.consultNum = _this35.consultOrderInfo.num;
          (0, _overseas.saveOverseas)(_this35.overseasSaleReturn).then(function (res) {
            _this35.btnLoading = false;

            if (res.data && res.code === 200) {
              _this35.$message.success('保存成功');

              (0, _list.finishConsultOrder)(_this35.consultOrderInfo).then(function (response) {
                _this35.btnLoading = false;

                if (response.data) {
                  _this35.$store.dispatch('tagsView/delView', _this35.$route);

                  _this35.$router.push({
                    path: '/sale/overseas_return'
                  });
                } else {
                  _this35.$message({
                    showClose: true,
                    message: response.msg || '操作失败！',
                    type: 'warning'
                  });
                }
              });
            } else {
              _this35.$message.error(res.msg || '系统异常');
            }

            _this35.showOverseas = false;
          });
        }
      });
    },
    // 取消
    cancelBtn: function cancelBtn() {
      this.consultOrderInfo.type = '';
      this.showOverseas = false;
    },
    // 刷新京东电话
    refreshJDphone: function refreshJDphone() {
      var _this36 = this;

      if (!this.consultOrderInfo.jdOrderNo) {
        this.$message.warning('不存在京东单号！');
        return;
      }

      (0, _list.refreshPhone)(this.consultOrderInfo.jdOrderNo).then(function (res) {
        if (res.data && res.code === 200) {
          _this36.consultOrderInfo.phoneBindNumber = res.data.phoneBindNumber;
          _this36.consultOrderInfo.phoneBindTimeout = res.data.phoneBindTimeout;

          _this36.$message.success('查询成功');
        } else {
          _this36.$message.warning(res.msg || '系统异常');
        }
      });
    },
    // 显示提示语句
    showHint: function showHint() {
      if (!this.consultOrderInfo.crmPhone) {
        this.$message.warning('请输入手机号!');
        return;
      }
    },
    getVSEStockList: function getVSEStockList(bookDate) {
      var _this37 = this;

      (0, _list.getVSEStockList)(bookDate).then(function (res) {
        if (res.data && res.code === 200) {
          _this37.timePerList = res.data;
        } else {
          _this37.timePerList = [];
        }
      });
    },
    // 提交不转vse信息
    submitVseRefuseNote: function submitVseRefuseNote() {
      var _this38 = this;

      if (!this.refuseNote) {
        this.$message.warning('请填写不转VSE备注');
        return;
      }

      (0, _list.saveConsultOrderVseInfo)(this.refuseNote, this.consultOrderInfo.num).then(function (res) {
        if (res.data && res.code === 200) {
          _this38.$message.success('保存成功');
        } else {
          _this38.$message.error(res.msg || '保存失败');
        }

        _this38.showVSEDialog = false; // 再查询是否需要添加不转vse备注

        (0, _list.getConsultOrderVseInfo)(_this38.consultOrderInfo.num).then(function (res) {
          if (res.data && res.code === 200) {
            _this38.consultOrderVseInfo = res.data;
          }
        });
      });
    },
    // 单选框再次选中取消
    isCheck: function isCheck(row) {
      if (row.type === '05' || row.type === '06' || row.type === '10') {
        this.$message.warning('退货、咨询、仅退款类业务不支持换票');
        this.exchangeId = '';
        return;
      }

      row.bizNum === this.exchangeId ? this.exchangeId = '' : this.exchangeId = row.bizNum;
    },
    // 发送换票链接
    sendExchange: function sendExchange() {
      var _this39 = this;

      if (this.exchangeId === '') {
        this.$message.warning('请先选择一笔订单！');
        return;
      } // 弹框确认


      this.$confirm('将为该笔订单发送换票链接，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // const param = { num: this.exchangeId, phone: this.consultOrderInfo.crmPhone }
        (0, _list.sendExchangeSms)(_this39.exchangeId, _this39.consultOrderInfo.crmPhone).then(function (response) {
          if (response.data && response.code === 200) {
            _this39.$message.success(response.msg);
          } else {
            _this39.btnLoading = false;

            _this39.$message.error(response.msg || '发送失败！');
          }
        });
      }).catch(function () {
        return;
      });
    },
    // 切换查询的产品注册/产品登记列表
    switchProdcut: function switchProdcut() {},
    // 仅登记 Or 注册产品
    handleCommand: function handleCommand(command) {
      this.showDrawer(1, command);
    },
    // 取消
    cancelProductClick: function cancelProductClick() {
      this.drawer = false;
      this.queryCusAndProd();
    },
    // 发送vse短信
    sendGdprSms: function sendGdprSms() {
      var _this40 = this;

      if (!this.consultOrderInfo.applicantTel) {
        this.$message.warning('请输入手机号!');
        return;
      }

      (0, _list.sendGdprSms)(this.consultOrderInfo.applicantTel, this.consultOrderInfo.num).then(function (response) {
        if (response.data && response.code === 200) {
          _this40.$message.success('发送成功！');
        } else {
          _this40.btnLoading = false;

          _this40.$message.error(response.msg || '发送失败！');
        }
      });
    },
    // 显示打标签对活框
    showLabelDialog: function showLabelDialog(type) {
      this.clearLabelInfo();

      if (type === 1) {
        if (!this.consultOrderCustomerInfo.mobile) {
          this.$message.warning('请先查询客户信息');
          return;
        }

        this.labelTypeOptions = [{
          name: '用户',
          val: '10'
        }, {
          name: '手机号',
          val: '20'
        }, {
          name: '详细地址',
          val: '40'
        }];
        this.showLabel = true;
      } else {
        this.labelTypeOptions = [{
          name: '产品SN',
          val: '30'
        }];
        var num = 0;
        var list = [];

        if (this.consultOrderInfo.choosedProductType === '2') {
          list = this.enrollmentProductList;
        } else {
          list = this.CustomerProductList;
        }

        list.forEach(function (item, index) {
          if (item.choose) {
            num = num + 1;
          }
        }); // 没选产品不能打标签

        if (num === 0) {
          this.$message({
            showClose: true,
            message: '请先选择一个产品',
            type: 'warning'
          });
          return;
        } // 选中的产品数量不能大于1


        if (num > 1) {
          this.$message({
            showClose: true,
            message: '只能选择一个产品',
            type: 'warning'
          });
          return;
        }

        this.showLabel = true;
      }
    },
    queryLabel: function queryLabel() {
      var _this41 = this;

      (0, _list.queryLabel)(this.labelType, this.labelLevel).then(function (res) {
        if (res && res.code === 200) {
          _this41.labels = res.data;
        }
      });
    },
    // 保存咨询单标签
    saveLabels: function saveLabels() {
      var _this42 = this;

      if (this.labelList.length === 0) {
        this.$message.warning('请选择标签后再操作');
        this.showLabel = false;
        return;
      }

      (0, _list.saveLabels)(this.consultOrderInfo.num, this.labelList.toString(), this.consultOrderCustomerInfo.mobile, this.consultOrderCustomerInfo.detailAddress, this.consultOrderInfo.prodName).then(function (res) {
        if (res && res.code === 200) {
          _this42.$message.success('保存成功'); // 初始化标签信息


          if (res.data) {
            _this42.riskList = res.data;

            _this42.initLabelInfo(res.data);
          }
        } else {
          _this42.$message.warning(res.msg || '保存失败');
        }

        _this42.showLabel = false;

        _this42.clearLabelInfo();
      });
    },
    // 清楚打标签初始值
    clearLabelInfo: function clearLabelInfo() {
      this.labelType = '';
      this.labelLevel = '';
      this.labelList = [];
      this.labels = [];
      this.labelTypeOptions = [];
    },
    removeTag: function removeTag(tag, list, type) {
      // 已结束状态不能删除
      if (this.consultOrderInfo.state === '20') {
        this.$message.warning('操作失败');
        return;
      }

      this.isDeleteLabel = true;
      this.crurrentTag = tag;
      this.crurrentList = list;
      this.currentType = type;
    },
    // 初始化标签列表
    initLabelInfo: function initLabelInfo(list) {
      var _this43 = this;

      this.userLabelList = list.filter(function (label) {
        return label.labelType === '10' && label.riskName === _this43.consultOrderCustomerInfo.mobile;
      });
      this.userLabelList.sort(function (a, b) {
        return b.labelLevel - a.labelLevel;
      });
      this.mobileLabelList = list.filter(function (label) {
        return label.labelType === '20' && label.riskName === _this43.consultOrderCustomerInfo.mobile;
      });
      this.mobileLabelList.sort(function (a, b) {
        return b.labelLevel - a.labelLevel;
      });
      this.addressLabelList = list.filter(function (label) {
        return label.labelType === '40' && label.riskName === _this43.consultOrderCustomerInfo.detailAddress;
      });
      this.addressLabelList.sort(function (a, b) {
        return b.labelLevel - a.labelLevel;
      });
      this.snLabelList = list.filter(function (label) {
        return label.labelType === '30' && label.riskName === _this43.consultOrderInfo.prodName;
      });
      this.snLabelList.sort(function (a, b) {
        return b.labelLevel - a.labelLevel;
      });
    },
    toRiskDetail: function toRiskDetail(id) {
      this.drawer = false;
      (0, _list.tempSave)(this.consultOrderInfo); // this.$store.dispatch('tagsView/delView', this.$route)
      // this.$router.go(-1) // 返回上一层
      // this.$router.push({ name: 'riskDetail', params: { type: '10', riskName: this.consultOrderInfo.applicantTel }})

      this.$router.push({
        name: 'createRisk',
        params: {
          id: id
        }
      });
    },
    // 查询风险
    queryRisk: function queryRisk() {
      var _this44 = this;

      (0, _list.queryRisk)(this.consultOrderInfo.applicantTel, '20', this.consultOrderInfo.num).then(function (res) {
        if (res && res.code === 200) {
          if (res.data) {
            _this44.riskId = res.data;
          } else {
            _this44.riskId = '';
          }
        } else {
          _this44.riskId = '';
        }
      });
    },
    querySnRisk: function querySnRisk() {
      var _this45 = this;

      if (!this.ownCustomerProduct.serialNumber || this.ownCustomerProduct.serialNumber.length !== 15) {
        return;
      }

      (0, _list.querySnRisk)(this.ownCustomerProduct.serialNumber, '30', this.consultOrderInfo.num).then(function (res) {
        if (res && res.code === 200) {
          if (res.data) {
            _this45.snRiskId = res.data.riskId;
            _this45.snRiskLevel = res.data.riskLevel;
          } else {
            _this45.snRiskId = '';
            _this45.snRiskLevel = '';
          }
        } else {
          _this45.snRiskId = '';
          _this45.snRiskLevel = '';
        }
      });
    },
    confirmDeleteLabel: function confirmDeleteLabel() {
      var _this46 = this;

      (0, _list.deleteLabel)(this.crurrentTag.id, this.consultOrderInfo.num).then(function (res) {
        if (res && res.code === 200) {
          _this46.riskList = res.data;

          _this46.initLabelInfo(_this46.riskList);

          _this46.$message.success('删除成功');
        } else {
          _this46.$message.error(res.msg || '删除失败');

          _this46.getDetail();
        }

        _this46.isDeleteLabel = false;
      });
    },
    // 初始化时间段选择
    initStocks: function initStocks(bookDate) {
      if (bookDate) {
        this.getVSEStockList(bookDate);
      }
    },
    chooseDisableDate: function chooseDisableDate(time) {
      if (time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || time.getTime() > Date.now() + 2 * 24 * 60 * 60 * 1000) {
        return true;
      } else {
        return false;
      }
    },
    initSelect: function initSelect() {
      var _this47 = this;

      var params = {
        isEnable: undefined
      };
      (0, _select.getSkuFeeSelect)(params).then(function (res) {
        _this47.userfeeSelect = res.data;
      });
    },
    // 查询用户的优惠券信息
    queryCustomerCoupon: function queryCustomerCoupon() {
      var _this48 = this;

      var num = 0;
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          num = num + 1;
        }
      }); // 没选产品不能打标签

      if (num === 0) {
        this.$message({
          showClose: true,
          message: '请先选择一个产品',
          type: 'warning'
        });
        return;
      } // 选中的产品数量不能大于1


      if (num > 1) {
        this.$message({
          showClose: true,
          message: '只能选择一个产品',
          type: 'warning'
        });
        return;
      }

      (0, _list.queryCustomerCoupon)(this.consultOrderInfo.crmPhone).then(function (res) {
        if (res && res.code === 200) {
          _this48.couponList = res.data;

          if (_this48.couponList.length > 0) {
            var open = [];

            for (var i = 0; i < _this48.couponList.length; i++) {
              open.push(i);
            }

            _this48.couponActiveNames = open;
          }
        }
      });
    },
    // 判断优惠券是否可以勾选
    disabledCouponCheck: function disabledCouponCheck(coupon) {
      // 如果coupon没有适用Sn，name只要选择一个Sn就可以勾选了
      var productSn = '';
      var list = [];

      if (this.consultOrderInfo.choosedProductType === '2') {
        list = this.enrollmentProductList;
      } else {
        list = this.CustomerProductList;
      }

      list.forEach(function (item, index) {
        if (item.choose) {
          productSn = item.serialNumber;
        }
      });

      if (!productSn) {
        coupon.choosed = false;
        return true;
      }

      if (!coupon.sn) {
        return true;
      }

      if (productSn === coupon.sn && coupon.status === '10') {
        return false;
      } else {
        coupon.choosed = false;
        return true;
      }
    },
    // 选中保存的优惠券
    choosedCoupon: function choosedCoupon(coupon, choosed) {
      var templist = this.couponList.filter(function (item) {
        return item.choosed;
      });

      if (choosed) {
        this.coupon = coupon;
        this.couponList.forEach(function (item) {
          if (item.couponCode !== coupon.couponCode) {
            item.choosed = false;
          }
        });
      } else {
        if (templist.length === 1) {
          this.coupon = templist[0];
          this.couponList.forEach(function (item) {
            if (item.couponCode !== templist[0].couponCode) {
              item.choosed = false;
            }
          });
        }
      }
    }
  }
};
exports.default = _default;