"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/** 邮寄方式[对象] */
var postTypeMap = {
  1: '顺丰上门',
  2: '自寄'
};
/** 邮寄方式[数组] */

var postTypeOptions = [{
  val: '1',
  name: '顺丰上门'
}, {
  val: '2',
  name: '自寄'
}];
/** 服务类型[对象] */

var businessTypeMap = {
  '01': '维修',
  '02': '换货',
  '03': '检测'
};
/** 服务类型[数组] */

var businessTypeOptions = [{
  val: '01',
  name: '维修'
}, {
  val: '02',
  name: '换货'
}, {
  val: '03',
  name: '检测'
}];
/** 服务类型[数组] */

var businessTypeOptions4check = [{
  val: '03',
  name: '检测'
}];
/** 服务类型[数组] */

var businessTypeOptions4nocheck = [{
  val: '01',
  name: '维修'
}, {
  val: '02',
  name: '换货'
}];
/** 服务子类型[对象] */

var businessSubTypeMap = {
  '0101': '保内',
  '0102': '保外',
  '0103': '海外',
  '0201': '三包换机',
  '0202': '多维换新',
  '0203': '保外转换货',
  '0204': '保内转换货',
  '0301': '仅检测'
};
/** 服务子类型[数组] */

var businessSubTypeOptions = [{
  val: '0101',
  name: '保内'
}, {
  val: '0102',
  name: '保外'
}, {
  val: '0103',
  name: '海外'
}, {
  val: '0201',
  name: '三包换机'
}, {
  val: '0202',
  name: '多维换新'
}, {
  val: '0203',
  name: '保外转换货'
}, {
  val: '0204',
  name: '保内转换货'
}, {
  val: '0301',
  name: '仅检测'
}];
/** 服务子类型[数组]---- 维修 */

var businessSubTypeOptions2 = [{
  val: '0101',
  name: '保内'
}, {
  val: '0102',
  name: '保外'
}]; // const businessSubTypeOptions2 = [{ val: '0101', name: '保内' }, { val: '0102', name: '保外' }, { val: '0103', name: '海外' }]

/** 服务子类型[数组]---- 换货 */

var businessSubTypeOptions3 = [{
  val: '0201',
  name: '三包换机'
}, {
  val: '0202',
  name: '多维换新'
}, {
  val: '0203',
  name: '保外转换货'
}, {
  val: '0204',
  name: '保内转换货'
}];
/** 服务子类型[数组]---- 检测 */

var businessSubTypeOptions4 = [{
  val: '0301',
  name: '仅检测'
}];
/** 工单状态[对象] */

var stateMap = {
  'S1': '待收货',
  'S2': '已收货',
  'S3': '已检测',
  'S4': '处理完毕',
  'S5': '已结束'
};
/** 工单状态[数组] */

var stateOptions = [{
  val: 'S1',
  name: '待收货'
}, {
  val: 'S2',
  name: '已收货'
}, {
  val: 'S3',
  name: '已检测'
}, {
  val: 'S4',
  name: '处理完毕'
}, {
  val: 'S5',
  name: '已结束'
}];
/** 工单状态[数组] */

var outCallOptions = [{
  val: '6',
  name: '是'
}];
/** 工单子状态 气泡[数组] */

var subStateBadge = {
  'S100': '收货',
  'S101': '收货',
  'S102': '收货',
  'S103': '收货',
  'S104': '收货',
  'S105': '收货',
  'S106': '收货',
  'S200': '检测',
  // 'S201': '收货异常申请作废',
  'S202': '检测',
  'S203': '检测',
  'S300': '检测',
  'S301': '检测',
  'S302': '检测',
  'S303': '检测',
  'S304': '检测',
  // 'S304': '外呼待派工',
  'S305': '检测',
  'S306': '检测',
  // 'S306': '报价超时待外呼',
  // 'S307': '申请变更待外呼',
  'S308': '检测',
  'S309': '检测',
  'S310': '检测',
  'S311': '检测',
  'S312': '处理',
  'S313': '处理',
  'S314': '返机',
  'S315': '处理',
  'S316': '检测',
  'S317': '检测',
  'S318': '检测',
  'S400': '返机',
  'S401': '返机',
  'S402': '返机',
  'S500': '返机',
  'S501': '返机',
  'S502': '签收',
  'S503': '',
  'S504': '返机',
  'S505': '返机',
  'S506': '',
  'S507': '',
  'S508': '',
  'S509': '返机',
  // 'S510': '超时取消已返机',
  'S511': '返机',
  'S512': '返机',
  'S599': ''
};
/** 工单子状态[数组] */

var subStateMap = {
  'S100': '待客户自寄',
  'S101': '待下单',
  'S102': '待揽件',
  'S103': '揽件成功待收货',
  'S104': '客户取消寄件',
  'S105': '揽件终止',
  'S106': '服务取消待收货',
  'S200': '收货异常待外呼',
  // 'S201': '收货异常申请作废',
  'S202': '待派工',
  'S203': '已派工待检测',
  'S300': '已检测待处理',
  'S301': '外呼已回复待复检',
  'S302': '已检测待复核',
  'S303': '已检测待换机',
  'S304': '已检测换机复核',
  // 'S304': '外呼待派工',
  'S305': '已检测待外呼',
  'S306': '已检测待清洁外呼',
  // 'S306': '报价超时待外呼',
  // 'S307': '申请变更待外呼',
  'S308': '报价已同意待支付',
  'S309': '外呼已回复弃修',
  'S310': '2次超时弃修',
  'S311': '已检测减免待审批',
  'S312': '终验退回待处理',
  'S313': '已支付待处理',
  'S314': '已检测待返机',
  'S315': '已检测待移交',
  'S316': '审批完成待外呼',
  'S317': '复核被拒待检测',
  'S318': '复检完成待复核',
  'S400': '处理完毕待返机',
  'S401': '终验完毕待返机',
  'S402': '服务取消待返机',
  'S500': '已返机待下单',
  'S501': '已下单待揽件',
  'S502': '已揽件待投递',
  'S503': '已签收',
  'S504': '换机待发货',
  'S505': '换机已发货',
  'S506': '收货异常关闭',
  'S507': '内部移交完成',
  'S508': '服务关闭',
  'S509': '服务取消已返机',
  // 'S510': '超时取消已返机',
  'S511': '退款待维修单返机',
  'S512': '弃修待清洁服务返机',
  'S513': '已清洁返机',
  'S599': '已返机'
};
/** 工单状态[数组] */

var subStateOptions = [{
  val: 'S100',
  name: '待客户自寄'
}, {
  val: 'S101',
  name: '待下单'
}, {
  val: 'S102',
  name: '待揽件'
}, {
  val: 'S103',
  name: '揽件成功待收货'
}, {
  val: 'S104',
  name: '客户取消寄件'
}, {
  val: 'S105',
  name: '揽件终止'
}, {
  val: 'S106',
  name: '服务取消待收货'
}, {
  val: 'S200',
  name: '收货异常待外呼'
}, {
  val: 'S201',
  name: '收货异常申请作废'
}, {
  val: 'S202',
  name: '待派工'
}, {
  val: 'S203',
  name: '已派工待检测'
}, {
  val: 'S300',
  name: '已检测待处理'
}, {
  val: 'S301',
  name: '外呼已回复待复检'
}, {
  val: 'S302',
  name: '已检测待复核'
}, {
  val: 'S303',
  name: '已检测待换机'
}, {
  val: 'S304',
  name: '已检测换机复核'
}, // { val: 'S304', name: '外呼待派工' },
{
  val: 'S305',
  name: '已检测待外呼'
}, {
  val: 'S306',
  name: '已检测待清洁外呼'
}, // { val: 'S306', name: '报价超时待外呼' },
// { val: 'S307', name: '申请变更待外呼' },
{
  val: 'S308',
  name: '报价已同意待支付'
}, {
  val: 'S309',
  name: '外呼已回复弃修'
}, {
  val: 'S310',
  name: '2次超时弃修'
}, {
  val: 'S311',
  name: '已检测减免待审批'
}, {
  val: 'S312',
  name: '终验退回待处理'
}, {
  val: 'S313',
  name: '已支付待处理'
}, {
  val: 'S314',
  name: '已检测待返机'
}, {
  val: 'S315',
  name: '已检测待移交'
}, {
  val: 'S316',
  name: '审批完成待外呼'
}, {
  val: 'S317',
  name: '复核被拒待检测'
}, {
  val: 'S318',
  name: '复检完成待复核'
}, {
  val: 'S400',
  name: '处理完毕待返机'
}, {
  val: 'S401',
  name: '终验完毕待返机'
}, {
  val: 'S402',
  name: '服务取消待返机'
}, {
  val: 'S500',
  name: '已返机待下单'
}, {
  val: 'S501',
  name: '已下单待揽件'
}, {
  val: 'S502',
  name: '已揽件待投递'
}, {
  val: 'S503',
  name: '已签收'
}, {
  val: 'S504',
  name: '换机待发货'
}, {
  val: 'S505',
  name: '换机已发货'
}, {
  val: 'S506',
  name: '收货异常关闭'
}, {
  val: 'S507',
  name: '内部移交完成'
}, {
  val: 'S508',
  name: '服务关闭'
}, {
  val: 'S509',
  name: '服务取消已返机'
}, // { val: 'S510', name: '超时取消已返机' },
{
  val: 'S511',
  name: '退款待维修单返机'
}, {
  val: 'S512',
  name: '弃修待清洁服务返机'
}, {
  val: 'S513',
  name: '已清洁返机'
}, {
  val: 'S599',
  name: '已返机'
}];
/** 紧急程度[对象] */

var urgentLevelMap = {
  1: '一般',
  2: '紧急'
};
/** 紧急程度[数组] */

var urgentLevelOptions = [{
  val: '1',
  name: '一般'
}, {
  val: '2',
  name: '紧急'
}];
/** 检测结果[对象] */

var checkResultMap = {
  1: '符合结果',
  2: '超出服务范围'
};
/** 检测结果[数组] */

var checkResultOptions = [{
  val: '1',
  name: '符合结果'
}, {
  val: '2',
  name: '超出服务范围'
}];
/** 换货原因[数组] */

var checkReasonOptions = [{
  val: '0',
  name: '三包换机'
}, {
  val: '1',
  name: '多维换机'
}, {
  val: '2',
  name: '未授权换机'
}, {
  val: '3',
  name: '缺料换机'
}, {
  val: '4',
  name: '安全问题换机'
}, {
  val: '5',
  name: '升级换机'
}];
/** 检测结果[对象]_仅检测 */

var checkResultMap4checkOnly = {
  3: '符合换货',
  4: '不符合换货'
};
/** 检测结果[数组]_仅检测 */

var checkResultOptions4checkOnly = [{
  val: '3',
  name: '符合换货'
}, {
  val: '4',
  name: '不符合换货'
}];
var checkResultMap4all = {
  1: '符合结果',
  2: '超出服务范围',
  3: '符合换货',
  4: '不符合换货'
};
var checkResultOptions4all = [{
  val: '1',
  name: '符合结果'
}, {
  val: '2',
  name: '超出服务范围'
}, {
  val: '3',
  name: '符合换货'
}, {
  val: '4',
  name: '不符合换货'
}];
/** 收费类型[对象] */

var feeTypeMap = {
  '01': '须收费',
  '02': '保修',
  '06': '特例免费',
  '07': '服务用料',
  '08': '权益使用',
  '09': '免费'
};
/** 收费类型[对象] */

var feeTypeMap1 = {
  '01': '须收费',
  '02': '保修',
  '06': '特例免费',
  '08': '权益使用',
  '09': '免费'
};
/** 服务选项[对象] */

var feeOptionMap = {
  '10': '必修',
  '20': '可修'
};
/** 收费类型[数组] */

var feeTypeOptions = [{
  val: '01',
  name: '须收费'
}, {
  val: '02',
  name: '保修'
}, {
  val: '07',
  name: '服务用料'
}];
/** 收费类型[数组]  , { val: '06', name: '特例免费' }*/

var feeTypeOptions1 = [{
  val: '01',
  name: '须收费'
}, {
  val: '02',
  name: '保修'
}, {
  val: '08',
  name: '权益使用'
}, {
  val: '09',
  name: '免费'
}];
/** 服务选项[数组] */

var feeOptionOptions = [{
  val: '10',
  name: '必修'
}, {
  val: '20',
  name: '可修'
}];
/** 处理结果[对象] */

var handleResultMap = {
  1: '已完成',
  2: '用户弃修',
  3: '超出服务范围',
  4: '联系失败返机'
};
/** 处理结果[对象] */

var handleResultMap1 = {
  1: '已完成',
  2: '用户弃修',
  3: '超出服务范围',
  4: '联系失败返机',
  5: '升级优惠'
};
/** 处理结果[数组] */

var handleResultOptions = [{
  val: '1',
  name: '已完成'
}, {
  val: '2',
  name: '用户弃修'
}, {
  val: '3',
  name: '超出服务范围'
}, {
  val: '4',
  name: '联系失败返机'
}];
/** 处理结果[数组] 信息查询专用 */

var handleResultOptions10 = [{
  val: '1',
  name: '已完成'
}, {
  val: '2',
  name: '用户弃修'
}, {
  val: '3',
  name: '超出服务范围'
}, {
  val: '4',
  name: '联系失败返机'
}, {
  val: '5',
  name: '升级优惠'
}];
/** 终验结果[对象] */

var verifyResultMap = {
  1: '通过',
  2: '未通过',
  3: '无需终验'
};
/** 终验结果[数组] */

var verifyResultOptions = [{
  val: '1',
  name: '通过'
}, {
  val: '2',
  name: '未通过'
}];
var verifyResultOptions2 = [{
  val: '1',
  name: '通过'
}, {
  val: '2',
  name: '未通过'
}, {
  val: '3',
  name: '无需终验'
}];
/** 返机方式[对象] */

var returnTypeMap = {
  1: '邮寄',
  2: '其他'
};
/** 返机方式[数组] */

var returnTypeOptions = [{
  val: '1',
  name: '邮寄'
}, {
  val: '2',
  name: '其他'
}];
/** 默认联系人[对象] */

var linkTypeMap = {
  1: '机主',
  2: '报修人',
  3: '寄件人',
  4: '收件人'
};
/** 默认联系人[数组] */

var linkTypeOptions = [{
  val: '1',
  name: '机主'
}, {
  val: '2',
  name: '报修人'
}, {
  val: '3',
  name: '寄件人'
}, {
  val: '4',
  name: '收件人'
}];
/** 外呼类型[对象] */

var callSignMap = {
  1: '收货异常',
  2: '保外报价',
  3: '支付超时',
  4: '业务变更',
  5: '到账确认',
  6: '沟通外呼',
  7: '服务减免结果',
  8: '升级套餐外呼',
  99: '功能类任务'
};
/** 外呼类型[数组] */

var callSignOptions = [{
  val: '1',
  name: '收货异常'
}, {
  val: '2',
  name: '保外报价'
}, {
  val: '3',
  name: '支付超时'
}, {
  val: '4',
  name: '业务变更'
}, {
  val: '5',
  name: '到账确认'
}, {
  val: '6',
  name: '沟通外呼'
}, {
  val: '7',
  name: '服务减免结果'
}, {
  val: '8',
  name: '升级套餐外呼'
}, {
  val: '99',
  name: '功能类任务'
}];
/** 外呼结果[对象] */

var callResultMap = {
  1: '用户弃修',
  2: '同意支付',
  3: '重新检测',
  4: '申请减免',
  5: '保外换货',
  6: '用户同意',
  7: '关单',
  8: '重新收货',
  11: '超出服务范围',
  12: '联系失败',
  13: '变更套餐',
  14: '不变更套餐',
  15: '升级优惠'
};
/** 外呼结果[数组] */

var callResultOptions = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '4',
  name: '申请减免'
}, // { val: '5', name: '保外换货' },
{
  val: '6',
  name: '用户同意'
}, {
  val: '7',
  name: '关单'
}, {
  val: '8',
  name: '重新收货'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}, {
  val: '13',
  name: '变更套餐'
}, {
  val: '14',
  name: '不变更套餐'
}, {
  val: '15',
  name: '升级优惠'
}];
/** 收货异常--外呼结果[数组] */

var callResultOptions1 = [{
  val: '7',
  name: '关单'
}, {
  val: '8',
  name: '重新收货'
}];
var callResultOptions10 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '7',
  name: '关单'
}, {
  val: '8',
  name: '重新收货'
}];
/** 保外报价--非缺料换机--外呼结果[数组] */

var callResultOptions2 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '15',
  name: '升级优惠'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}, {
  val: '4',
  name: '申请减免'
} // { val: '5', name: '保外换货' }
];
var callResultOptions20 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '15',
  name: '升级优惠'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}, {
  val: '4',
  name: '申请减免'
} // { val: '5', name: '保外换货' }
];
/** 保外报价--缺料换机--外呼结果[数组] */

var callResultOptions21 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
} // { val: '5', name: '保外换货' }
];
var callResultOptions201 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
} // { val: '5', name: '保外换货' }
];
/** 支付超时--非缺料换机--外呼结果[数组] */

var callResultOptions3 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}, {
  val: '4',
  name: '申请减免'
} // { val: '5', name: '保外换货' }
];
var callResultOptions30 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}, {
  val: '4',
  name: '申请减免'
} // { val: '5', name: '保外换货' }
];
/** 支付超时--缺料换机--外呼结果[数组] */

var callResultOptions31 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
} // { val: '5', name: '保外换货' }
];
var callResultOptions301 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '2',
  name: '同意支付'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
} // { val: '5', name: '保外换货' }
];
/** 业务变更--外呼结果[数组] */

var callResultOptions4 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '6',
  name: '用户同意'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}];
var callResultOptions40 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '6',
  name: '用户同意'
}, {
  val: '11',
  name: '超出服务范围'
}, {
  val: '12',
  name: '联系失败'
}];
var callResultOptions7 = [{
  val: '1',
  name: '用户弃修'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '2',
  name: '同意支付'
}];
var callResultOptions50 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '3',
  name: '重新检测'
}, {
  val: '2',
  name: '同意支付'
}];
/**
 * 升级套餐外呼--外呼结果[数组]
 * @type {({val: string, name: string}|{val: string, name: string}|{val: string, name: string})[]}
 */

var callResultOptions8 = [{
  val: '13',
  name: '变更套餐'
}, {
  val: '14',
  name: '不变更套餐'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '12',
  name: '联系失败'
}];
var callResultOptions80 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '13',
  name: '变更套餐'
}, {
  val: '14',
  name: '不变更套餐'
}, {
  val: '1',
  name: '用户弃修'
}, {
  val: '12',
  name: '联系失败'
}];
/** 流程类到账确认--功能类[自定义] */

var callResultOptions00 = [{
  val: '0',
  name: '跟进中'
}, {
  val: '9',
  name: '完结'
}];
/** 支付渠道[对象] */

var paymentChannelMap = {
  10: '微信',
  20: '支付宝',
  30: '银联',
  40: '银行转账'
};
/** 支付渠道[数组] */

var paymentChannelOptions = [{
  val: '10',
  name: '微信'
}, {
  val: '20',
  name: '支付宝'
}, {
  val: '30',
  name: '银联'
}, {
  val: '40',
  name: '银行转账'
}];
/** 取消原因[对象] */

var cancelReasonMap = {
  0: '测试订单',
  1: '取消服务',
  2: '客服下错单',
  3: '时间过长',
  4: '维修站下错单',
  5: '价格调整'
};
/** 取消原因[数组] */

var cancelReasonOptions = [{
  val: '0',
  name: '测试订单'
}, {
  val: '1',
  name: '取消服务'
}, {
  val: '2',
  name: '客服下错单'
}, {
  val: '3',
  name: '时间过长'
}, {
  val: '4',
  name: '维修站下错单'
}, {
  val: '5',
  name: '价格调整'
}];
/** 复核类型[数组] */

var recheckTypeOptions = [{
  val: '10',
  name: '价格复核'
}, {
  val: '20',
  name: '换机复核'
}];
/** 接入渠道[数组] */

var accessChannelOptions = [{
  val: '1',
  name: '电话'
}, {
  val: '2',
  name: '微信'
}, {
  val: '3',
  name: '京东'
}, {
  val: '4',
  name: '天猫'
}, {
  val: '5',
  name: '海外机'
}, {
  val: '7',
  name: 'JD服务平台'
}, {
  val: '6',
  name: '其他'
}, {
  val: '8',
  'name': '微信（清洁服务）'
}, {
  val: '9',
  'name': '微信（AIO自助）'
}];
/**
 *  减免审批状态
 * @type {{"0": string, "1": string, "2": string}}
 */

var quotaStateMap = {
  0: '未审批',
  1: '通过',
  2: '未通过',
  3: '已审批'
};
var quotaStates = [{
  val: '0',
  name: '未审批'
}, {
  val: '1',
  name: '通过'
}, {
  val: '2',
  name: '未通过'
}, {
  val: '3',
  name: '已审批'
}];
/**
 *  减免审批状态
 * @type {{"0": string, "1": string, "2": string}}
 */

var quotaStateMap2 = {
  0: '未审批',
  1: '通过',
  2: '未通过'
};
var quotaStates2 = [{
  val: '0',
  name: '未审批'
}, {
  val: '1',
  name: '通过'
}, {
  val: '2',
  name: '未通过'
}];
/**
 *  减免审批状态
 * @type {{"0": string, "1": string, "2": string}}
 */

var quotaStateMap3 = {
  0: '未审批',
  1: '已审批',
  2: '已审批',
  3: '已审批'
};
var quotaStates3 = [{
  val: '0',
  name: '未审批'
}, {
  val: '1',
  name: '已审批'
}, {
  val: '2',
  name: '已审批'
}, {
  val: '3',
  name: '已审批'
}];
/** 销售减免审批状态[数组] */

var saleQuotaStates = [{
  val: '1',
  name: '通过'
}, {
  val: '2',
  name: '未通过'
}];
/** 客服工单状态[数组] */

var orderTypeState = [{
  val: '10',
  name: '待跟进'
}, {
  val: '20',
  name: '跟进中'
}, {
  val: '30',
  name: '完结'
}, {
  val: '40',
  name: '已取消'
}];
/** 服务单阶段[数组] */

var orderStageOptions = [{
  val: 'S1',
  name: '创建->收货'
}, {
  val: 'S2',
  name: '收货->检测'
}, {
  val: 'S3',
  name: '检测->处理'
}, {
  val: 'S4',
  name: '处理->返机'
}, {
  val: 'S5',
  name: '返机->签收'
}, {
  val: 'S6',
  name: '检测->大仓发货'
}, {
  val: 'S7',
  name: '大仓发货->签收'
}, {
  val: 'S8',
  name: '检测->返机'
}];
var orderStageMap = {
  'S1': '创建->收货',
  'S2': '收货->检测',
  'S3': '检测->处理',
  'S4': '处理->返机',
  'S5': '返机->签收',
  'S6': '检测->大仓发货',
  'S7': '大仓发货->签收',
  'S8': '检测->返机'
};
/** 物料sku,服务sku状态[对象] */

var SkuStateMap = {
  '0': '删除',
  '1': '可用'
};
/** 物料sku,服务sku状态[数组] */

var SkuStateOptions = [{
  val: '0',
  name: '删除'
}, {
  val: '1',
  name: '可用'
}];
/** 收费类型[对象] */

var feeTypeMap2 = {
  '01': '须收费',
  '06': '特例免费'
};
/** 收费类型[数组] */

var feeTypeOption2 = [{
  val: '01',
  name: '须收费'
}, {
  val: '06',
  name: '特例免费'
}];
/** 工单任务状态【对象】 */

var TaskStateMap = {
  '10': '未受理',
  '20': '受理中',
  '30': '完成',
  '40': '已关闭'
};
/** 工单任务状态【数组】 */

var TaskStateOption = [{
  val: '10',
  name: '未受理'
}, {
  val: '20',
  name: '受理中'
}, {
  val: '30',
  name: '完成'
}, {
  val: '40',
  name: '已关闭'
}];
/** 国家枚举*/

var countryOptions = [{
  val: 'CN',
  name: '中国'
}, {
  val: 'AE',
  name: '阿联酋'
}, {
  val: 'AU',
  name: '澳大利亚'
}, {
  val: 'CA',
  name: '加拿大'
}, {
  val: 'CEE',
  name: '中东欧'
}, {
  val: 'CH',
  name: '瑞士'
}, {
  val: 'EU',
  name: '欧盟'
}, {
  val: 'HK',
  name: '香港'
}, {
  val: 'ID',
  name: '印度尼西亚'
}, {
  val: 'IL',
  name: '以色列'
}, {
  val: 'IN',
  name: '印度'
}, {
  val: 'JP',
  name: '日本'
}, {
  val: 'KR',
  name: '北朝鲜'
}, {
  val: 'MX',
  name: '墨西哥'
}, {
  val: 'MY',
  name: '马来西亚'
}, {
  val: 'NZ',
  name: '新西兰'
}, {
  val: 'PH',
  name: '菲律宾'
}, {
  val: 'RU',
  name: '俄罗斯'
}, {
  val: 'SA',
  name: '沙特阿拉伯'
}, {
  val: 'SG',
  name: '新加坡'
}, {
  val: 'TH',
  name: '泰国'
}, {
  val: 'TR',
  name: '土耳其'
}, {
  val: 'TW',
  name: '台湾'
}, {
  val: 'UK',
  name: '英国'
}, {
  val: 'US',
  name: '美国'
}, {
  val: 'IRL',
  name: '爱尔兰'
}, {
  val: 'ITA',
  name: '意大利'
}, {
  val: 'OT',
  name: '其他'
}];
/** 国家 map*/

var countryMap = {
  'CN': '中国',
  'AE': '阿联酋',
  'AU': '澳大利亚',
  'CA': '加拿大',
  'CEE': '中东欧',
  'CH': '瑞士',
  'EU': '欧盟',
  'HK': '香港',
  'ID': '印度尼西亚',
  'IL': '以色列',
  'IN': '印度',
  'JP': '日本',
  'KR': '北朝鲜',
  'MX': '墨西哥',
  'MY': '马来西亚',
  'NZ': '新西兰',
  'PH': '菲律宾',
  'RU': '俄罗斯',
  'SA': '沙特阿拉伯',
  'SG': '新加坡',
  'TH': '泰国',
  'TR': '土耳其',
  'TW': '台湾',
  'UK': '英国',
  'US': '美国',
  'IRL': '爱尔兰',
  'ITA': '意大利',
  'OT': '其他'
};
/** 清洁类型AIO【数组】 */

var cleanTypeOptions = [{
  val: '0',
  name: '清洁维修'
}, {
  val: '1',
  name: '清洁服务'
}];
/** 清洁类型【数组】 */

var cleanAllOptions = [{
  val: '10',
  name: '清洁服务AIO'
}, {
  val: '20',
  name: '清洁服务优惠券'
}, {
  val: '30',
  name: '清洁服务权益使用'
}];
/**
 * 优惠券使用状态
 */

var couponMap = {
  '0': '未使用',
  '1': '已使用'
};
var couponOptions = [{
  val: '0',
  name: '未使用'
}, {
  val: '1',
  name: '已使用'
}]; // 收费类型

var payType = [{
  label: '须收费',
  value: '01'
}, {
  label: '样品',
  value: '09'
}, {
  label: '没有保修，用不到',
  value: '04'
}, {
  label: '赠品',
  value: '06'
}, {
  label: '保内免费',
  value: 'P4'
}, {
  label: '特例免费',
  value: '06'
}];
var auditStatusOptions = [{
  val: '01',
  name: '待审核'
}, {
  val: '02',
  name: '审核通过'
}, {
  val: '03',
  name: '审核拒绝'
}];
/**
 * 批量延保单据状态
 */

var orderStatusOptions = [{
  val: '01',
  name: '暂存'
}, {
  val: '02',
  name: '已提交待审批'
}, {
  val: '03',
  name: '已审批'
}];
/**
 * 批量延保审批结果
 */

var auditResultOptions = [{
  val: '01',
  name: '审批通过'
}, {
  val: '02',
  name: '审批拒绝'
}];
var _default = {
  postTypeMap: postTypeMap,
  postTypeOptions: postTypeOptions,
  businessTypeMap: businessTypeMap,
  businessTypeOptions: businessTypeOptions,
  businessTypeOptions4check: businessTypeOptions4check,
  businessTypeOptions4nocheck: businessTypeOptions4nocheck,
  businessSubTypeMap: businessSubTypeMap,
  businessSubTypeOptions: businessSubTypeOptions,
  businessSubTypeOptions2: businessSubTypeOptions2,
  businessSubTypeOptions3: businessSubTypeOptions3,
  businessSubTypeOptions4: businessSubTypeOptions4,
  stateMap: stateMap,
  stateOptions: stateOptions,
  outCallOptions: outCallOptions,
  subStateBadge: subStateBadge,
  subStateMap: subStateMap,
  subStateOptions: subStateOptions,
  urgentLevelMap: urgentLevelMap,
  urgentLevelOptions: urgentLevelOptions,
  checkResultMap: checkResultMap,
  checkResultOptions: checkResultOptions,
  checkReasonOptions: checkReasonOptions,
  checkResultMap4checkOnly: checkResultMap4checkOnly,
  checkResultOptions4checkOnly: checkResultOptions4checkOnly,
  checkResultMap4all: checkResultMap4all,
  checkResultOptions4all: checkResultOptions4all,
  feeTypeMap: feeTypeMap,
  feeTypeMap1: feeTypeMap1,
  feeOptionMap: feeOptionMap,
  feeTypeOptions: feeTypeOptions,
  feeTypeOptions1: feeTypeOptions1,
  feeOptionOptions: feeOptionOptions,
  handleResultMap: handleResultMap,
  handleResultMap1: handleResultMap1,
  handleResultOptions: handleResultOptions,
  handleResultOptions10: handleResultOptions10,
  verifyResultMap: verifyResultMap,
  verifyResultOptions: verifyResultOptions,
  verifyResultOptions2: verifyResultOptions2,
  returnTypeMap: returnTypeMap,
  returnTypeOptions: returnTypeOptions,
  linkTypeMap: linkTypeMap,
  linkTypeOptions: linkTypeOptions,
  callSignMap: callSignMap,
  callSignOptions: callSignOptions,
  callResultMap: callResultMap,
  callResultOptions: callResultOptions,
  callResultOptions1: callResultOptions1,
  callResultOptions2: callResultOptions2,
  callResultOptions21: callResultOptions21,
  callResultOptions3: callResultOptions3,
  callResultOptions31: callResultOptions31,
  callResultOptions4: callResultOptions4,
  callResultOptions7: callResultOptions7,
  callResultOptions8: callResultOptions8,
  paymentChannelMap: paymentChannelMap,
  paymentChannelOptions: paymentChannelOptions,
  cancelReasonMap: cancelReasonMap,
  cancelReasonOptions: cancelReasonOptions,
  recheckTypeOptions: recheckTypeOptions,
  accessChannelOptions: accessChannelOptions,
  orderTypeState: orderTypeState,
  callResultOptions10: callResultOptions10,
  callResultOptions20: callResultOptions20,
  callResultOptions201: callResultOptions201,
  callResultOptions30: callResultOptions30,
  callResultOptions301: callResultOptions301,
  callResultOptions40: callResultOptions40,
  callResultOptions50: callResultOptions50,
  callResultOptions80: callResultOptions80,
  callResultOptions00: callResultOptions00,
  quotaStateMap: quotaStateMap,
  quotaStates: quotaStates,
  quotaStateMap2: quotaStateMap2,
  quotaStates2: quotaStates2,
  quotaStateMap3: quotaStateMap3,
  quotaStates3: quotaStates3,
  orderStageMap: orderStageMap,
  orderStageOptions: orderStageOptions,
  SkuStateMap: SkuStateMap,
  SkuStateOptions: SkuStateOptions,
  feeTypeMap2: feeTypeMap2,
  feeTypeOption2: feeTypeOption2,
  saleQuotaStates: saleQuotaStates,
  TaskStateMap: TaskStateMap,
  TaskStateOption: TaskStateOption,
  cleanTypeOptions: cleanTypeOptions,
  cleanAllOptions: cleanAllOptions,
  auditStatusOptions: auditStatusOptions,
  couponMap: couponMap,
  couponOptions: couponOptions,
  countryOptions: countryOptions,
  countryMap: countryMap,
  payType: payType,
  orderStatusOptions: orderStatusOptions,
  auditResultOptions: auditResultOptions
};
exports.default = _default;