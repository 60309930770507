"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteData = deleteData;
exports.getPageList = getPageList;
exports.queryById = queryById;
exports.saveData = saveData;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/cityTime/getPageList',
    method: 'post',
    params: query
  });
}

function saveData(data) {
  return (0, _request.default)({
    url: '/bus_app/cityTime/saveData',
    method: 'post',
    data: data
  });
}

function deleteData(id) {
  return (0, _request.default)({
    url: '/bus_app/cityTime/deleteData',
    method: 'post',
    params: {
      id: id
    }
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/cityTime/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}