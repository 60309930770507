"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

var _work_task = require("@/api/work_task");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TaskHistory',
  props: {
    workNo: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      OrderTaskHistoryList: [],
      imgList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.workNo) {
      this.listHistoryByOrderNo();
    }
  },
  methods: {
    listHistoryByOrderNo: function listHistoryByOrderNo() {
      var _this = this;

      (0, _work_task.listHistoryByOrderNo)(this.workNo).then(function (response) {
        if (response.data) {
          _this.OrderTaskHistoryList = response.data;

          for (var i = 0; i < _this.OrderTaskHistoryList.length; i++) {
            _this.updateAttachment(_this.OrderTaskHistoryList[i].enclosure);

            _this.OrderTaskHistoryList[i].imgList = _this.imgList;
            _this.imgList = [];
          }
        }
      });
    },
    updateAttachment: function updateAttachment(val) {
      if (val && val !== '') {
        this.imgList = [];
        this.imgPaths = val.split('`');

        for (var i = 0; i < this.imgPaths.length; i++) {
          var obj = {};
          var fileNames = this.imgPaths[i].substring(this.imgPaths[i].lastIndexOf('.') + 1).toUpperCase();
          obj.name = '点击下载';
          obj.url = this.imgPaths[i];
          var isJPG1 = ['JPG', 'JPEG', 'PNG'].includes(fileNames);
          var isMP4 = ['MP4', 'AVI', 'MOV'].includes(fileNames);

          if (isMP4) {
            obj.url = process.env.BASE_URL + 'MP4.png';
            obj.baseUrl = this.imgPaths[i];
          } else if (!isJPG1) {
            obj.url = process.env.BASE_URL + 'XLSX.png';
            obj.baseUrl = this.imgPaths[i];
          }

          this.imgList.push(obj);
        }
      }
    },
    handlePreview: function handlePreview(file) {
      if (file.baseUrl !== undefined && file.baseUrl !== null && file.baseUrl !== '') {
        window.location.href = file.baseUrl;
      } else {
        window.location.href = file.url;
      }
    }
  }
};
exports.default = _default;