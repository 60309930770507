"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _select = require("@/api/common/select");

var _list = require("@/api/stock/stock_out_plan/list");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 时间格式化
var _default = {
  name: 'stockOutPlanCreate',
  // eslint-disable-line
  data: function data() {
    var validate = function validate(rule, value, callback) {
      (0, _list.validatePlanDate)(value).then(function (res) {
        if (!res.data) {
          callback();
        } else {
          callback('禁止提交与当前计划出库日期相同的新申请,已免重复,请重新选择');
        }
      });
    };

    return {
      // 上传图片部分
      dialogImageUrl: '',
      dialogVisible: false,
      imgList: [],
      isMultiple: true,
      imgLimit: 5,
      sizeLimit: 10,
      downloadLoading: false,
      dialogStatus: this.$route.params.dialogStatus,
      // this.$route.params.dialogStatus,
      id: this.$route.params.id,
      // this.$route.params.id,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      textMap: {
        update: '编辑',
        create: '新建'
      },
      listQuery: {
        page: 1,
        limit: 10,
        modelId: '',
        sku: '',
        warehouseCode: ''
      },
      stockOutPlanInfo: {
        num: '',
        warehouseCode: '',
        planDate: '',
        memo: ''
      },
      // 添加的信息
      warehouseList: [],
      supplierList: [],
      total: 0,
      list: [],
      disableVisible: false,
      addSkudialogFormVisible: false,
      itemList: [],
      deleteBtnDisabled: true,
      skuListValidate: false,
      // 明细不能为空校验标识
      loadingList4Model: false,
      listLoading: false,
      isList: false,
      dialogFormVisible: false,
      snNumber: 99999,
      snList: [],
      snSure: [],
      sku: {},
      findSnDialogFormVisible: false,
      snFindList: [],
      costCenterList: [],
      rules: {
        memo: [{
          max: 200,
          message: '长度不能超过200 个字符',
          trigger: 'blur'
        }],
        planDate: [{
          required: true,
          message: '计划截止日期必选',
          trigger: 'blur'
        }, {
          validator: validate,
          trigger: 'blur'
        }]
      },
      uploadQuery: {
        inRmk: this.$store.state.user.loginUser.memberId,
        timestamp: '',
        sign: ''
      },
      uploadAction: '/uploadsever/s3/uploadFile'
    };
  },
  // 初始化
  created: function created() {
    this.getstockOutPlanInfo();
  },
  methods: {
    submitSave: function submitSave() {
      var _this = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this.stockOutPlanInfo.planDate = (0, _utils.parseTime)(new Date(_this.stockOutPlanInfo.planDate), '{y}-{m}-{d}');
          (0, _list.submitSave)(_this.stockOutPlanInfo).then(function (response) {
            if (response != null && response.data) {
              _this.$message({
                message: response.msg,
                type: 'success'
              });

              _this.$store.dispatch('tagsView/delView', _this.$route);

              _this.$router.push({
                path: '/stock/stock_out_plan'
              });
            } else {
              _this.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    getWarehouseList: function getWarehouseList() {
      var _this2 = this;

      (0, _select.getHouseSelect)(this.stockOutPlanInfo.orgId).then(function (response) {
        _this2.warehouseList = response.data;
      });
    },
    getstockOutPlanInfo: function getstockOutPlanInfo() {
      var _this3 = this;

      if (this.dialogStatus === 'create') {
        (0, _list.createData)().then(function (response) {
          _this3.stockOutPlanInfo = response.data;
        });
      } else {
        (0, _list.queryById)(this.id).then(function (response) {
          if (response != null) {
            _this3.stockOutPlanInfo = response.data;

            if (_this3.stockOutPlanInfo.planDate !== null && _this3.stockOutPlanInfo.planDate !== '') {
              _this3.disableVisible = true;
            }
          }
        });
      }

      this.getWarehouseList();
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/stock/stock_out_plan'
      });
    }
  }
};
exports.default = _default;