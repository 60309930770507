var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("物料编码" + _vm._s(!_vm.isCreate ? "详细" : "创建")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_PRD_SKU_ADD", "ROLE_BASE_PRD_SKU_MODIFY"],
                  expression:
                    "['ROLE_BASE_PRD_SKU_ADD','ROLE_BASE_PRD_SKU_MODIFY']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { disabled: _vm.isReadOnly, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSumbit()
                },
              },
            },
            [_vm._v("保存\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_PRD_SKU_LIST"],
                  expression: "['ROLE_BASE_PRD_SKU_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "sku_list" })
                },
              },
            },
            [_vm._v("返回\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.baseSku,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "物料图片" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _c("upload-imgs", {
                                  attrs: {
                                    disabled: _vm.isReadOnly,
                                    limit: 1,
                                    attachment: _vm.baseSku.picturePath,
                                  },
                                  on: { setFilePaths: _vm.setFilePaths },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "物料编码", prop: "code" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: {
                                        disabled:
                                          !_vm.isCreate || _vm.isReadOnly,
                                        placeholder: "物料编码",
                                      },
                                      model: {
                                        value: _vm.baseSku.code,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.baseSku, "code", $$v)
                                        },
                                        expression: "baseSku.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "状态", prop: "isEnable" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          fill: "#000000",
                                          size: "medium",
                                        },
                                        model: {
                                          value: _vm.baseSku.isEnable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseSku,
                                              "isEnable",
                                              $$v
                                            )
                                          },
                                          expression: "baseSku.isEnable",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: "1" } },
                                          [_vm._v("启用")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: "0" } },
                                          [_vm._v("禁用")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "物料名称", prop: "name" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: {
                                        placeholder: "物料名称",
                                        disabled: _vm.isReadOnly,
                                      },
                                      model: {
                                        value: _vm.baseSku.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.baseSku, "name", $$v)
                                        },
                                        expression: "baseSku.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "物料名称(英文)" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: {
                                        placeholder: "物料名称(英文)",
                                        disabled: _vm.isReadOnly,
                                      },
                                      model: {
                                        value: _vm.baseSku.nameEn,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.baseSku, "nameEn", $$v)
                                        },
                                        expression: "baseSku.nameEn",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "物料分类", prop: "type" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.baseSku.type,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.baseSku, "type", $$v)
                                          },
                                          expression: "baseSku.type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.typeOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      label: "物料标识",
                                      prop: "skuTag",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.baseSku.skuTag,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.baseSku, "skuTag", $$v)
                                          },
                                          expression: "baseSku.skuTag",
                                        },
                                      },
                                      _vm._l(
                                        _vm.tagOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 14 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "供应商名称", prop: "supplier" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "供应商名称",
                              },
                              model: {
                                value: _vm.baseSku.supplier,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "supplier", $$v)
                                },
                                expression: "baseSku.supplier",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "税务编码" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: {
                                placeholder: "税务编码",
                                disabled: _vm.isReadOnly,
                              },
                              model: {
                                value: _vm.baseSku.taxationCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "taxationCode", $$v)
                                },
                                expression: "baseSku.taxationCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "参考含税成本价",
                              prop: "costYuan",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "参考含税成本价",
                              },
                              model: {
                                value: _vm.baseSku.costYuan,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "costYuan", $$v)
                                },
                                expression: "baseSku.costYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "零售不含税指导价",
                              prop: "priceExcludeTaxYuan",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "零售不含税指导价",
                              },
                              model: {
                                value: _vm.baseSku.priceExcludeTaxYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.baseSku,
                                    "priceExcludeTaxYuan",
                                    $$v
                                  )
                                },
                                expression: "baseSku.priceExcludeTaxYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "零售含税指导价",
                              prop: "priceIncludeTaxYuan",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "零售含税指导价",
                              },
                              model: {
                                value: _vm.baseSku.priceIncludeTaxYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.baseSku,
                                    "priceIncludeTaxYuan",
                                    $$v
                                  )
                                },
                                expression: "baseSku.priceIncludeTaxYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "保修时长（天）",
                              prop: "warrantyDays",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "保修时长（天）",
                              },
                              model: {
                                value: _vm.baseSku.warrantyDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "warrantyDays", $$v)
                                },
                                expression: "baseSku.warrantyDays",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "采购周期（天）",
                              prop: "purchaseDays",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "采购周期（天）",
                              },
                              model: {
                                value: _vm.baseSku.purchaseDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "purchaseDays", $$v)
                                },
                                expression: "baseSku.purchaseDays",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "最小库存数", prop: "stockMin" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "最小库存数",
                              },
                              model: {
                                value: _vm.baseSku.stockMin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "stockMin", $$v)
                                },
                                expression: "baseSku.stockMin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "SN前三位", prop: "preSn" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "SN前三位",
                              },
                              model: {
                                value: _vm.baseSku.preSn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "preSn", $$v)
                                },
                                expression: "baseSku.preSn",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "开启可零售", prop: "isRetail" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  fill: "#000000",
                                  disabled: _vm.isReadOnly,
                                  size: "medium",
                                },
                                model: {
                                  value: _vm.baseSku.isRetail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSku, "isRetail", $$v)
                                  },
                                  expression: "baseSku.isRetail",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("是")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "开启SN管理", prop: "isSn" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  fill: "#000000",
                                  disabled: "",
                                  size: "medium",
                                },
                                model: {
                                  value: _vm.baseSku.isSn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSku, "isSn", $$v)
                                  },
                                  expression: "baseSku.isSn",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("是")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "是否使用封顶价", prop: "caps" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  fill: "#000000",
                                  disabled: _vm.isReadOnly,
                                  size: "medium",
                                },
                                model: {
                                  value: _vm.baseSku.caps,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSku, "caps", $$v)
                                  },
                                  expression: "baseSku.caps",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("是")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              label: "保外转换货价格",
                              prop: "replacement",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "保外转换货价格" },
                              model: {
                                value: _vm.baseSku.replacement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "replacement", $$v)
                                },
                                expression: "baseSku.replacement",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "换机优惠价", prop: "exchange" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "换机优惠价",
                              },
                              model: {
                                value: _vm.baseSku.exchange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "exchange", $$v)
                                },
                                expression: "baseSku.exchange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "升级优惠含税价", prop: "trade" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "升级优惠含税价",
                              },
                              model: {
                                value: _vm.baseSku.trade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "trade", $$v)
                                },
                                expression: "baseSku.trade",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "是否为样品", prop: "sample" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { fill: "#000000", size: "medium" },
                                model: {
                                  value: _vm.baseSku.sample,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseSku, "sample", $$v)
                                  },
                                  expression: "baseSku.sample",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("是")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "升级优惠含税价", prop: "trade" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isReadOnly,
                                placeholder: "升级优惠含税价",
                              },
                              model: {
                                value: _vm.baseSku.trade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseSku, "trade", $$v)
                                },
                                expression: "baseSku.trade",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "适用型号", prop: "models" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  size: "medium",
                                  type: "primary",
                                  plain: "",
                                },
                                on: { click: _vm.addModelSwitch },
                              },
                              [_vm._v("添加\n              ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    _vm.deleteBtnDisabled || _vm.isReadOnly,
                                  size: "medium",
                                  type: "primary",
                                  plain: "",
                                },
                                on: { click: _vm.delModelClick },
                              },
                              [_vm._v("删除\n              ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "160px" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingList4Model,
                                expression: "loadingList4Model",
                              },
                            ],
                            ref: "multipleTable",
                            attrs: {
                              size: "medium",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "40px",
                              },
                              data: _vm.list4Model,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.rowClickSelection,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "modelId",
                                type: "selection",
                                width: "55",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "modelCode", label: "型号编码" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "modelName",
                                label: "型号名称",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "categoryName",
                                label: "品类",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "brandName",
                                label: "品牌",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSku.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建人", prop: "inRmkName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSku.inRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSku, "inRmkName", $$v)
                                    },
                                    expression: "baseSku.inRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSku.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSku.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSku, "inDateFM", $$v)
                                    },
                                    expression: "baseSku.inDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSku.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改人",
                                  prop: "reRmkName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSku.reRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSku, "reRmkName", $$v)
                                    },
                                    expression: "baseSku.reRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.baseSku.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改时间",
                                  prop: "reDateFM",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.baseSku.reDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.baseSku, "reDateFM", $$v)
                                    },
                                    expression: "baseSku.reDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加型号", visible: _vm.dialog4modelSelVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialog4modelSelVisible = $event
            },
            open: _vm.dialog4modelSelOpen,
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c("el-row", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modelSelValidate,
                        expression: "modelSelValidate",
                      },
                    ],
                    staticClass: "modelList__error",
                  },
                  [_vm._v("查询条件：品类、品牌、型号，请至少选择其中一项")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    { ref: "queryModelForm", attrs: { model: _vm.listQuery } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "categoryId" } },
                            [
                              _c("tree-select", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: _vm.listQuery.categoryId,
                                  options: _vm.categorySelectTree,
                                  accordion: false,
                                  clearable: true,
                                  placeholder: "请选择品类",
                                },
                                on: {
                                  getValue: function ($event) {
                                    return _vm.getTreeSelectNodeKey($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "brandId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择品牌",
                                  },
                                  on: { change: _vm.brandSelectChange },
                                  model: {
                                    value: _vm.listQuery.brandId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "brandId", $$v)
                                    },
                                    expression: "listQuery.brandId",
                                  },
                                },
                                _vm._l(_vm.brandSelect, function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "modelId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择型号",
                                  },
                                  on: { focus: _vm.loadModelSelect },
                                  model: {
                                    value: _vm.listQuery.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "id", $$v)
                                    },
                                    expression: "listQuery.id",
                                  },
                                },
                                _vm._l(_vm.modelSelect, function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.searchBtnClick4ModelSel },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "info" },
                            on: { click: _vm.cleanBtnClick4ModelSel },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingList4ModelSelect,
                  expression: "loadingList4ModelSelect",
                },
              ],
              ref: "multipleTable4ModelSelect",
              staticStyle: { width: "100%" },
              attrs: {
                size: "medium",
                border: "",
                stripe: "",
                "tooltip-effect": "dark",
                data: _vm.list4ModelSelect,
              },
              on: {
                "selection-change": _vm.handleSelectionChange4ModelSelect,
                "row-click": _vm.rowClickSelection4ModelSelect,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  align: "center",
                  prop: "id",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "型号编码" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "型号名称",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: "品类",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brandName",
                  label: "品牌",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.addModelSwitch } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.addBtnDisabled },
                  on: { click: _vm.addModelSelectBtnClick },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }