var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("el-main", [
        _c(
          "div",
          { staticStyle: { "padding-bottom": "10px" } },
          [
            _c(
              "sticky",
              { attrs: { "class-name": "sub-navbar default" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "grid-content bg-purple",
                    staticStyle: { float: "left", "padding-left": "1.3rem" },
                  },
                  [
                    _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                      _vm._v("库存预警设置"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isCommit
                  ? _c(
                      "el-button",
                      {
                        attrs: { plain: "", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.commitConfig()
                          },
                        },
                      },
                      [_vm._v("保存")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { plain: "", type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isCommit = true
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.btnLoading,
                        expression: "btnLoading",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.route()
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "listTableDiv" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.configDate,
                  border: "",
                  size: "small",
                  fit: "",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#eef1f6",
                    color: "#606266",
                    height: "50px",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "storage", label: "机构", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "stockDdir",
                    label: "DDIR仓库预警值",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "stock1000",
                    label: "1000仓库预警值",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm.isCommit
                            ? [
                                _c("el-input", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.dateChange(row.stock1000, row)
                                    },
                                  },
                                  model: {
                                    value: row.stock1000,
                                    callback: function ($$v) {
                                      _vm.$set(row, "stock1000", $$v)
                                    },
                                    expression: "row.stock1000",
                                  },
                                }),
                              ]
                            : _c("span", [_vm._v(_vm._s(row.stock1000))]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }