var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _vm.showNotice
      ? _c("div", { staticStyle: { color: "red", "text-align": "center" } }, [
          _c("i", { staticClass: "el-icon-message-solid" }),
          _vm._v("\n    " + _vm._s(_vm.notice) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }