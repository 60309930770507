"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryRouteByNum = queryRouteByNum;
exports.queryStateByNum = queryStateByNum;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 顺丰状态履历列表查询
 * @param num
 */
function queryStateByNum(num) {
  return (0, _request.default)({
    url: '/api/sf/queryStateByNum',
    method: 'post',
    params: {
      num: num
    }
  });
}
/**
 * 顺丰路由履历列表查询
 * @param num
 */


function queryRouteByNum(num) {
  return (0, _request.default)({
    url: '/api/sf/queryRouteByNum',
    method: 'post',
    params: {
      num: num
    }
  });
}