var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _vm.quotaIn.state !== "30" && _vm.quotaIn.state !== "99"
        ? _c(
            "sticky",
            { attrs: { "class-name": "sub-navbar default" } },
            [
              _vm.id === "" || _vm.quotaIn.state === "10"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_QUOTA_IN_SAVE"],
                          expression: "['ROLE_QUOTA_IN_SAVE']",
                        },
                      ],
                      attrs: {
                        size: "small",
                        type: "warning",
                        icon: "el-icon-folder",
                        disabled: _vm.isTemp,
                      },
                      on: { click: _vm.tempSave },
                    },
                    [_vm._v("暂存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.id === "" || _vm.quotaIn.state === "10"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_QUOTA_IN_SUBMIT"],
                          expression: "['ROLE_QUOTA_IN_SUBMIT']",
                        },
                      ],
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-check",
                        disabled: _vm.isSub,
                      },
                      on: { click: _vm.submitSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.quotaIn.state === "20"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_QUOTA_IN_CONFIRM"],
                          expression: "['ROLE_QUOTA_IN_CONFIRM']",
                        },
                      ],
                      attrs: {
                        size: "small",
                        type: "danger",
                        icon: "el-icon-tickets",
                        disabled: _vm.isCon,
                      },
                      on: { click: _vm.confirmSave },
                    },
                    [_vm._v("确认")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.quotaIn,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 60px 0 40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "filter-item",
                                  attrs: { label: "机构", prop: "inOrgId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                        disabled:
                                          _vm.id !== "" &&
                                          _vm.quotaIn.state !== "10",
                                      },
                                      on: { change: _vm.orgChange },
                                      model: {
                                        value: _vm.quotaIn.inOrgId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.quotaIn, "inOrgId", $$v)
                                        },
                                        expression: "quotaIn.inOrgId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.organizationList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "当前额度",
                                    prop: "quotaSum",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.quotaSum,
                                      callback: function ($$v) {
                                        _vm.quotaSum = $$v
                                      },
                                      expression: "quotaSum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "可用额度",
                                    prop: "avaibQuota",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.avaibQuota,
                                      callback: function ($$v) {
                                        _vm.avaibQuota = $$v
                                      },
                                      expression: "avaibQuota",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "冻结额度",
                                    prop: "frozenQuota",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.frozenQuota,
                                      callback: function ($$v) {
                                        _vm.frozenQuota = $$v
                                      },
                                      expression: "frozenQuota",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "额度类型",
                                    prop: "quotaType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.id !== "" &&
                                          _vm.quotaIn.state !== "10",
                                      },
                                      model: {
                                        value: _vm.quotaIn.quotaType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.quotaIn,
                                            "quotaType",
                                            $$v
                                          )
                                        },
                                        expression: "quotaIn.quotaType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.typesOptions,
                                      function (item, index) {
                                        return _c(
                                          "el-radio-button",
                                          {
                                            key: index,
                                            attrs: {
                                              label: item.value,
                                              value: item.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "方式", prop: "isPlus" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.id !== "" &&
                                          _vm.quotaIn.state !== "10",
                                      },
                                      model: {
                                        value: _vm.quotaIn.isPlus,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.quotaIn, "isPlus", $$v)
                                        },
                                        expression: "quotaIn.isPlus",
                                      },
                                    },
                                    _vm._l(
                                      _vm.plusOptions,
                                      function (item, index) {
                                        return _c(
                                          "el-radio-button",
                                          {
                                            key: index,
                                            attrs: {
                                              label: item.value,
                                              value: item.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "金额", prop: "quota" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled:
                                        _vm.id !== "" &&
                                        _vm.quotaIn.state !== "10",
                                    },
                                    model: {
                                      value: _vm.quotaIn.quota,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.quotaIn, "quota", $$v)
                                      },
                                      expression: "quotaIn.quota",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "充值备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.id !== "" &&
                                        _vm.quotaIn.state !== "10",
                                      autosize: { minRows: 4, maxRows: 6 },
                                      type: "textarea",
                                      placeholder: "200字以内",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.quotaIn.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.quotaIn, "memo", $$v)
                                      },
                                      expression: "quotaIn.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "附件信息",
                                    prop: "annexInfo",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.uploadAction,
                                        "list-type": "picture-card",
                                        limit: _vm.imgLimit,
                                        "file-list": _vm.imgList,
                                        multiple: _vm.isMultiple,
                                        "on-preview":
                                          _vm.handlePictureCardPreview,
                                        "on-remove": _vm.handleRemove,
                                        "on-success": _vm.handleAvatarSuccess,
                                        "on-exceed": _vm.handleExceed,
                                        "before-upload": _vm.beforeAvatarUpload,
                                        "on-error": _vm.imgUploadError,
                                        disabled:
                                          _vm.id !== "" &&
                                          _vm.quotaIn.state !== "10",
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: { visible: _vm.dialogVisible },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.dialogVisible = $event
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: _vm.dialogImageUrl,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }