"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.queryDetail = queryDetail;
exports.querySplitOrder = querySplitOrder;
exports.updatePaymentSuc = updatePaymentSuc;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 清洁服务查询
 * @param query
 */
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/dys_fc/queryInfo',
    method: 'post',
    params: query
  });
}
/**
 * 清洁服务明细查询
 * @param query
 */


function queryDetail(query) {
  return (0, _request.default)({
    url: '/bus_app/dys_fc/queryDetailById',
    method: 'post',
    params: query
  });
}
/**
 * 清洁服务明细查询
 * @param query
 */


function querySplitOrder(query) {
  return (0, _request.default)({
    url: '/bus_app/dys_fc/querySplitOrder',
    method: 'post',
    params: query
  });
}
/**
 * 清洁服务拆单
 * @param query
 */


function updatePaymentSuc(data) {
  return (0, _request.default)({
    url: '/bus_app/dys_fc/updatePaymentSuc',
    method: 'post',
    data: data
  });
}