"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 售后工单块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var orderRouter = {
  path: '/order/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'receipt/detail/:id',
    name: 'orderReceiptDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/receipt/detail'));
      });
    },
    meta: {
      title: '收货详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/receipt/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'check/detail/:id',
    name: 'orderCheckDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/check/detail'));
      });
    },
    meta: {
      title: '检测详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/check/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'checkHistory/detail/:id',
    name: 'orderCheckHistory',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/check/history'));
      });
    },
    meta: {
      title: '历史检测详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/check/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'recheck/detail/:id',
    name: 'orderRecheckDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/recheck/detail'));
      });
    },
    meta: {
      title: '复核详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/recheck/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'handle/detail/:id',
    name: 'orderHandleDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/handle/detail'));
      });
    },
    meta: {
      title: '处理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/handle/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'verify/detail/:id',
    name: 'orderVerifyDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/verify/detail'));
      });
    },
    meta: {
      title: '终验详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/verify/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'return/detail/:id',
    name: 'orderReturnDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/return/detail'));
      });
    },
    meta: {
      title: '返机详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/order/return/list',
      parent: {
        path: '/order',
        redirect: 'noRedirect',
        meta: {
          title: '服务单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = orderRouter;
exports.default = _default;