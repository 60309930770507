"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order/order");

var _filterUtils = require("@/api/order/filterUtils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    checkResultFilter: _filterUtils.checkResultFilter
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: false,
      hisList: []
    };
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadcheckHistoryList', function (orderId) {
      if (orderId) {
        _this.loadcheckHistoryList(orderId);
      }
    });
  },
  methods: {
    // 历史检测页面
    gotoCheckHistoryHtml: function gotoCheckHistoryHtml(row, event) {
      this.$router.push({
        name: 'orderCheckHistory',
        params: {
          id: row.id
        }
      });
    },
    initSelect: function initSelect() {},
    loadcheckHistoryList: function loadcheckHistoryList(orderId) {
      var _this2 = this;

      var params = {
        orderId: orderId
      };
      (0, _order.getCheckHistoryList)(params).then(function (response) {
        if (response.data) {
          _this2.hisList = response.data;
          var indexNum = _this2.hisList.length;
          _this2.hisList = response.data.map(function (v) {
            _this2.$set(v, 'indexNum', indexNum--); // 动态加字段


            return v;
          });
        }
      });
    }
  }
};
exports.default = _default;