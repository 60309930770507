"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _sfExpressOrder = require("@/api/express/sfExpressOrder");

var _list = require("@/api/consult/list");

var _expressOrg = require("@/api/express/expressOrg");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _select = require("@/api/common/select");

var _contants = _interopRequireDefault(require("@/api/express/contants"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'sfExpressOrderModify',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  directives: {},
  data: function data() {
    return {
      id: this.$route.params.id,
      sfExpressOrder: {},
      expressRules: {
        num: [{
          required: true,
          message: '订单号是必填的',
          trigger: 'blur'
        }],
        orderSource: [{
          required: true,
          message: '订单来源是必填的',
          trigger: 'blur'
        }],
        sendOrg: [{
          required: true,
          message: '寄件机构是必填的',
          trigger: 'blur'
        }],
        sendName: [{
          required: true,
          message: '寄件名称是必填的',
          trigger: 'blur'
        }],
        sendMobile: [{
          required: true,
          message: '寄件人电话是必填的',
          trigger: 'blur'
        }],
        sendProvinceCode: [{
          required: true,
          message: '省份是必填的',
          trigger: 'blur'
        }],
        sendCityCode: [{
          required: true,
          message: '城市是必填的',
          trigger: 'blur'
        }],
        sendDistrictCode: [{
          required: true,
          message: '区域是必填的',
          trigger: 'blur'
        }],
        sendAddress: [{
          required: true,
          message: '寄件地址是必填的',
          trigger: 'blur'
        }],
        receiveOrg: [{
          required: true,
          message: '寄件机构是必填的',
          trigger: 'blur'
        }],
        receiveName: [{
          required: true,
          message: '寄件名称是必填的',
          trigger: 'blur'
        }],
        receiveMobile: [{
          required: true,
          message: '寄件人电话是必填的',
          trigger: 'blur'
        }],
        receiveProvinceCode: [{
          required: true,
          message: '省份是必填的',
          trigger: 'blur'
        }],
        receiveCityCode: [{
          required: true,
          message: '城市是必填的',
          trigger: 'blur'
        }],
        receiveDistrictCode: [{
          required: true,
          message: '区域是必填的',
          trigger: 'blur'
        }],
        receiveAddress: [{
          required: true,
          message: '寄件地址是必填的',
          trigger: 'blur'
        }],
        expectedDate: [{
          required: true,
          message: '预计上门日期是必填的',
          trigger: 'blur'
        }],
        expectedTime: [{
          required: true,
          message: '预计上门时间是必填的',
          trigger: 'blur'
        }]
      },
      sourceSelect: _contants.default.expressSourceSelect,
      // 省下拉
      provinceSelect: [],
      citySelect: [],
      // 区下拉，
      regionSelect: [],
      provinceSelect2: [],
      citySelect2: [],
      // 区下拉，
      regionSelect2: [],
      expressSelect: [],
      otherOrg: {
        id: 'other',
        orgName: '其他'
      },
      listLoading: false,
      historyList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      bookTimePerList: [{
        'val': '08:00~09:59',
        'name': '08:00~09:59'
      }, {
        'val': '10:00~11:59',
        'name': '10:00~11:59'
      }, {
        'val': '12:00~13:59',
        'name': '12:00~13:59'
      }, {
        'val': '14:00~15:59',
        'name': '14:00~15:59'
      }, {
        'val': '16:00~17:59',
        'name': '16:00~17:59'
      }],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      sourceMap: [{
        name: '天猫戴森旗舰店',
        val: '01'
      }, {
        name: '京东自营店',
        val: '02'
      }, {
        name: '淘宝直销店',
        val: '03'
      }, {
        name: '微店',
        val: '04'
      }, {
        name: '官网',
        val: '05'
      }, {
        name: '线下门店',
        val: '06'
      }, {
        name: '抖音',
        val: '07'
      }, {
        name: '小红书',
        val: '08'
      }, {
        name: '销售单',
        val: '09'
      }, {
        name: '备用机',
        val: '10'
      }, {
        name: '客服中心',
        val: '11'
      }, {
        name: '其他',
        val: '12'
      }]
    };
  },
  computed: {
    isEdit: function isEdit() {
      return true;
    }
  },
  created: function created() {
    this.getProvinceOptions();
    this.getExpressOrgSelect();

    if (!this.$route.params.id) {
      this.sfExpressOrder.isPackage = '0';
    }

    this.getDetail();
  },
  activated: function activated() {
    this.getProvinceOptions();
    this.getExpressOrgSelect();

    if (!this.$route.params.id) {
      this.sfExpressOrder.isPackage = '0';
    }

    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _sfExpressOrder.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.sfExpressOrder = response.data;

          _this.getHistoryList();
        }
      });
    },
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      this.listQuery.parentId = this.id;
      (0, _sfExpressOrder.getHistoryList)(this.listQuery).then(function (response) {
        _this2.historyList = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/express/sf_express_order'
      });
    },
    // 类型格式化
    orgFormat: function orgFormat(row) {
      if (!row.receiveOrg) {
        return '';
      } else {
        return row.receiveOrg;
      }
    },
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this3.provinceSelect = res.data;
        _this3.provinceSelect2 = res.data;
      });
    },
    getExpressOrgSelect: function getExpressOrgSelect() {
      var _this4 = this;

      (0, _expressOrg.getExpressOrgSelect)(2).then(function (res) {
        _this4.expressSelect = res.data;

        _this4.expressSelect.push(_this4.otherOrg);
      });
    },
    getNameByCode: function getNameByCode(select, code) {
      var name = '';

      if (select.length > 0) {
        select.forEach(function (item) {
          if (item.id === code) {
            name = item.label;
          }
        });
      }

      return name;
    },
    // 根据id获得机构信息
    changeSendOrgById: function changeSendOrgById(id) {
      var _this5 = this;

      this.expressSelect.forEach(function (item) {
        if (item.id === id) {
          _this5.sfExpressOrder.sendName = item.receiver;
          _this5.sfExpressOrder.sendMobile = item.receiveMobile;
          _this5.sfExpressOrder.sendProvinceCode = item.provinceCode;
          _this5.sfExpressOrder.sendCityCode = item.cityCode;
          _this5.sfExpressOrder.sendDistrictCode = item.districtCode;
          _this5.sfExpressOrder.sendAddress = item.detailAddress;
          _this5.sfExpressOrder.sendCompanyInfo = item.companyInfo;
        }
      });
    },
    changeReceiveOrgById: function changeReceiveOrgById(id) {
      var _this6 = this;

      this.expressSelect.forEach(function (item) {
        if (item.id === id) {
          _this6.sfExpressOrder.receiveName = item.receiver;
          _this6.sfExpressOrder.receiveMobile = item.receiveMobile;
          _this6.sfExpressOrder.receiveProvinceCode = item.provinceCode;
          _this6.sfExpressOrder.receiveCityCode = item.cityCode;
          _this6.sfExpressOrder.receiveDistrictCode = item.districtCode;
          _this6.sfExpressOrder.receiveAddress = item.detailAddress;
          _this6.sfExpressOrder.receiveCompanyInfo = item.companyInfo;
        }
      });
    },
    packageFormat: function packageFormat(row) {
      return row.isPackage === '1' ? '是' : '否';
    },
    sourceFormat: function sourceFormat(row) {
      return this.sourceMap.map(function (type) {
        if (row.orderSource.trim() === type.val) {
          return type.name;
        }

        return '';
      });
    },
    // 选顺丰上门取件时间
    changeBookDate: function changeBookDate() {
      this.sfExpressOrder.expectedTime = '';
    },
    // 选时间段判断，是否是有效顺丰上门取件时间
    changeBookClock: function changeBookClock() {
      var _this7 = this;

      var param = {
        bookDate: this.sfExpressOrder.expectedDate,
        bookClock: this.sfExpressOrder.expectedTime
      };
      (0, _list.validateBookClock)(param).then(function (res) {
        if (res.data) {
          _this7.$nextTick(function () {
            _this7.$refs['Form'].clearValidate();
          });
        } else {
          _this7.$message({
            showClose: true,
            message: res.msg || '请选择你方便的时间段！',
            type: 'warning'
          });
        }
      });
    }
  }
};
exports.default = _default;