"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 额度块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var quotaRouter = {
  path: '/quota/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'quota',
    name: 'quotaDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/quota/quota/detail'));
      });
    },
    meta: {
      title: '额度查询详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/quota/quota/list',
      parent: {
        path: '/quota',
        meta: {
          title: '额度管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'quota_in',
    name: 'quotaInDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/quota/quota_in/detail'));
      });
    },
    meta: {
      title: '额度充值详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/quota/quota_in/list',
      parent: {
        path: '/quota',
        meta: {
          title: '额度管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'quota_apply',
    name: 'quotaApplyDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/quota/quota_apply/detail'));
      });
    },
    meta: {
      title: '额度充值申请详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/quota/quota_apply/list',
      parent: {
        path: '/quota',
        meta: {
          title: '额度管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'quota_audit',
    name: 'quotaAuditDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/quota/quota_audit/detail'));
      });
    },
    meta: {
      title: '额度充值审核详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/quota/quota_audit/list',
      parent: {
        path: '/quota',
        meta: {
          title: '额度管理',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = quotaRouter;
exports.default = _default;