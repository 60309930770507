"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _notice = require("@/api/notice/notice");

var _organization = require("@/api/organization");

var _utils = require("@/utils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _MDinput = _interopRequireDefault(require("@/components/MDinput"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _request = require("@/utils/request1");

var _tools = require("@/utils/tools");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-line

/* eslint-disable-line */
// 获取枚举label
// 标题样式
// 富文本
var _default = {
  name: 'NoticeDetail',
  components: {
    Sticky: _Sticky.default,
    MDinput: _MDinput.default,
    Tinymce: _Tinymce.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '1': 'success',
        '0': 'danger',
        undefined: 'info'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      // 状态枚举
      stateEnum: [{
        label: '未发布',
        value: '0'
      }, {
        label: '已发布',
        value: '1'
      }],
      typesOptions: [{
        label: '一级网点',
        value: '10'
      }, {
        label: '二级网点',
        value: '20'
      }, {
        label: '高维中心',
        value: '30'
      }, {
        label: '其他',
        value: '40'
      }],
      loading: false,
      loadingList4ModelSelect: false,
      loadingList4Model: false,
      listData: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 500,
        // isSystem: '0', // 查询除去总部的所有机构
        sort: '+id'
      },
      // 查询条件
      // >>> 添加编辑FORM项目 >>>
      notice: {
        id: '',
        title: '',
        noticeLevel: 0,
        state: '',
        content: '',
        noticeOrgInfos: []
      },
      multipleSelection: [],
      // <<< 添加编辑FORM项目 <<<
      rules: {
        title: [{
          required: true,
          message: '公告标题是必填的',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '公告内容是必填的',
          trigger: 'blur'
        }]
      },
      // 上传附件
      fileUrl: undefined,
      fileList: [],
      isMultiple: true,
      fileLimit: 10,
      sizeLimit: 10,
      uploadAction: '/uploadsever/fileUpload',
      address: [],
      isReadOnly: false
    };
  },
  created: function created() {
    this.getList();

    if (this.id && this.id !== 'new') {
      this.initNotice();
    } else {
      this.initPrices();
    }
  },
  methods: {
    // 适用机构列表数据渲染
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _organization.fetchList)(this.listQuery).then(function (response) {
        _this.listData = response.data.records;
        _this.listLoading = false; // 恢复默认选中的适用机构

        if (_this.id) {
          (0, _notice.getNoticeOrgId)(_this.id).then(function (response) {
            if (response !== null && response.data.length !== 0) {
              _this.listData.forEach(function (data) {
                response.data.forEach(function (item) {
                  if (data.id === item) {
                    _this.$refs.multipleTable.toggleRowSelection(data, true);
                  }
                });
              });
            }
          });
        } else {
          // 新建默认全选
          _this.$nextTick(function () {
            var _this2 = this;

            this.listData.forEach(function (data) {
              _this2.$refs.multipleTable.toggleRowSelection(data, true);
            });
          });
        }
      });
    },
    initNotice: function initNotice() {
      var _this3 = this;

      (0, _notice.editNoticeById)(this.id).then(function (response) {
        response.data.content = (0, _utils.HTMLDecode)(response.data.content);
        _this3.notice = response.data;

        if (response.data.state === _this3.stateEnum[1].value) {
          _this3.isReadOnly = true;
        }

        if (response.data.address) {
          _this3.fileList = JSON.parse(response.data.address);
          _this3.address = JSON.parse(response.data.address);
        }
      });
    },
    initPrices: function initPrices() {},
    confirmEdit: function confirmEdit(row) {
      // console.log(row)
      row.edit = !row.edit;
    },
    // 类型格式化
    typeFormat: function typeFormat(row) {
      return this.typesOptions.map(function (type) {
        if (row.type === type.value) {
          return type.label;
        }

        return '';
      });
    },
    // 获取table数据
    createRoUpdate: function createRoUpdate(val) {
      var _this4 = this;

      var operation = this.stateEnum[0].value === val ? '保存' : '发布';
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.listLoading = true; // 转义

          _this4.notice.content = (0, _utils.HTMLEncode)(_this4.notice.content);
          _this4.notice.noticeOrgInfos = _this4.multipleSelection.map(function (data) {
            return data.id;
          });
          _this4.notice.state = _this4.stateEnum[val].value;
          _this4.notice.address = JSON.stringify(_this4.address);
          (0, _notice.createRoUpdate)(_this4.notice).then(function (res) {
            if (res.success) {
              _this4.$notify({
                title: '成功',
                message: operation + '公告成功',
                type: 'success',
                duration: 2000
              });

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/notice/list'
              });
            } // eslint-disable-line

            /* else if (res.msg !== null && res.msg !== "") {
                this.$message({
                  message: res.msg + '' + res.data,
                  type: 'error'
                })
            }*/
            else {
              _this4.$message({
                message: operation + '失败',
                type: 'error'
              });

              _this4.loading = false;
            }
          });
          _this4.listLoading = false;
        } else {
          _this4.loading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    getLabelByValue: function getLabelByValue(value, enumName) {
      return (0, _utils.getLabelByValue)(value, enumName);
    },
    // 【已关联型号列表】单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 【已关联型号列表】checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 上传附件
    // 点击上传的文件
    handlePreview: function handlePreview(file) {
      if (this.isReadOnly) {
        var url = '/order_app/assessment/fileDownload?fileName=' + file.name + '&fileUrl=' + file.url;
        (0, _request.downLoadFile)(url, function (res) {
          console.log(res.data);

          if (res.data) {
            (0, _tools.operateFile1)(res.data, file.name);
          } else {
            console.log('失败');
          }
        }, function (err) {
          console.log(err);
        });
      }

      console.log("\u70B9\u51FB\u4E0A\u4F20\u7684\u6587\u4EF6: ".concat(file.name, " !"));
    },
    // 删除处理事件
    handleRemove: function handleRemove(file) {
      console.log("\u79FB\u9664\u4E0A\u4F20\u7684\u6587\u4EF6: ".concat(file.name, " !"));
      this.address.forEach(function (item, index, arr) {
        if (item.name === file.name) {
          arr.splice(index, 1);
        }
      });
    },
    // 文件上传成功
    handleSuccess: function handleSuccess(response, file) {
      if (response.code !== 'success') {
        this.imgUploadError();
      } else {
        console.log('上传成功');
        var fileInfo = {};
        fileInfo.name = file.name;
        fileInfo.url = process.env.VUE_APP_FASTDFS_STORAGE_URL + response.data;
        this.address.push(fileInfo);
      }
    },
    // 上传文件超出限制数
    handleExceed: function handleExceed(files, fileList) {
      console.log('上传文件超出限制数');
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 ".concat(this.fileLimit, " \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ").concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    // 文件上传失败
    uploadError: function uploadError(file) {
      this.$message.error("\u6587\u4EF6 ".concat(file.name, " ,\u4E0A\u4F20\u5931\u8D25!"));
    },
    // 文件上传之前的限制
    beforeUpload: function beforeUpload(file) {
      var sizeLimit = file.size / 1024 / 1024 < this.sizeLimit;

      if (!sizeLimit) {
        this.$message.error('上传文件大小不能超过' + this.sizeLimit + 'MB!');
      }

      return sizeLimit;
    },
    // 触发删除
    beforeRemove: function beforeRemove(file) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, " \uFF1F"));
    }
  }
};
exports.default = _default;