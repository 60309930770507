"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _state = _interopRequireDefault(require("@/components/SF/state"));

var _route = _interopRequireDefault(require("@/components/SF/route"));

//
//
//
//
//
//
//
//
//
//
// 物流状态信息
// 物流路由信息
var _default = {
  components: {
    sfState: _state.default,
    sfRoute: _route.default
  },
  props: {
    num: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      sfNum: '暂无物流信息'
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadSFInfo', function (num) {
      if (num) {
        _this.loadSFInfo(num);
      }
    });
  },
  methods: {
    setSFnum: function setSFnum(sfNum) {
      this.sfNum = sfNum; // console.log(this.sfNum = sfNum)
    },
    loadSFInfo: function loadSFInfo(num) {
      if (this.$refs.sfState) {
        this.$refs.sfState.$emit('loadStateList4SF', num);
      }

      if (this.$refs.sfRoute) {
        this.$refs.sfRoute.$emit('loadRouteList4SF', num);
      }
    }
  }
};
exports.default = _default;