var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  "海外物料编码" + _vm._s(!_vm.isCreate ? "详细" : "创建")
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: [
                    "ROLE_BASE_OVERSEAS_SKU_ADD",
                    "ROLE_BASE_OVERSEAS_SKU_MODIFY",
                  ],
                  expression:
                    "['ROLE_BASE_OVERSEAS_SKU_ADD','ROLE_BASE_OVERSEAS_SKU_MODIFY']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSumbit()
                },
              },
            },
            [_vm._v("保存\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.$router.push({ path: "/basics/overseasSku/list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.overseasSku,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "overseasCode",
                            label: "海外物料编码",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.overseasSku.overseasCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSku, "overseasCode", $$v)
                              },
                              expression: "overseasSku.overseasCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否全球联保",
                            prop: "globalWarranty",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { fill: "#000" },
                              model: {
                                value: _vm.overseasSku.globalWarranty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.overseasSku,
                                    "globalWarranty",
                                    $$v
                                  )
                                },
                                expression: "overseasSku.globalWarranty",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "true" } },
                                [_vm._v("是")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "false" } },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "overseasName",
                            label: "海外物料名称",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.overseasSku.overseasName,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSku, "overseasName", $$v)
                              },
                              expression: "overseasSku.overseasName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "overseasPre", label: "海外SN前三位" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.overseasSku.overseasPre,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSku, "overseasPre", $$v)
                              },
                              expression: "overseasSku.overseasPre",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "skuCode", label: "物料编码（国行）" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            model: {
                              value: _vm.overseasSku.skuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSku, "skuCode", $$v)
                              },
                              expression: "overseasSku.skuCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "country", label: "国家" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.overseasSku.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.overseasSku, "country", $$v)
                                },
                                expression: "overseasSku.country",
                              },
                            },
                            _vm._l(_vm.countryOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否启用", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.overseasSku.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.overseasSku, "status", $$v)
                                },
                                expression: "overseasSku.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "否", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }