var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "Form",
          attrs: {
            model: _vm.sfExpressOrder,
            rules: _vm.expressRules,
            disabled: _vm.isEdit,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              外部订单信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "订单号",
                                          prop: "orderNum",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value: _vm.sfExpressOrder.orderNum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "orderNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.orderNum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { label: "订单来源" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.orderSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "orderSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.orderSource",
                                            },
                                          },
                                          _vm._l(
                                            _vm.sourceSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "订单备注",
                                          prop: "note",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.note,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "note",
                                                $$v
                                              )
                                            },
                                            expression: "sfExpressOrder.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              寄件人信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件机构",
                                          prop: "sendOrg",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeSendOrgById(
                                                  _vm.sfExpressOrder.sendOrg
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.sfExpressOrder.sendOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendOrg",
                                            },
                                          },
                                          _vm._l(
                                            _vm.expressSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.orgName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件人姓名",
                                          prop: "sendName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value: _vm.sfExpressOrder.sendName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件人电话",
                                          prop: "sendMobile",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.sendMobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendMobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件省份",
                                            prop: "sendProvince",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.sendProvince,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendProvince",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendProvince",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件城市",
                                            prop: "sendCity",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.sendCity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendCity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendCity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "寄件区域",
                                            prop: "sendDistrict",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.sendDistrict,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendDistrict",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendDistrict",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "sendAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.sendAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "公司信息",
                                            prop: "sendCompanyInfo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .sendCompanyInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "sendCompanyInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.sendCompanyInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              收件人信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件机构",
                                          prop: "receiveOrg",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeReceiveOrgById(
                                                  _vm.sfExpressOrder.receiveOrg
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.receiveOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveOrg",
                                            },
                                          },
                                          _vm._l(
                                            _vm.expressSelect,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.orgName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件人姓名",
                                          prop: "receiveName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.receiveName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "receiveName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.receiveName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "收件人电话",
                                          prop: "receiveMobile",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.receiveMobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "receiveMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.receiveMobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件省份",
                                            prop: "receiveProvince",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveProvince,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveProvince",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveProvince",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件城市",
                                            prop: "receiveCity",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.receiveCity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveCity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveCity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "收件区域",
                                            prop: "receiveDistrict",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveDistrict,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveDistrict",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveDistrict",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "receiveAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "公司信息",
                                            prop: "receiveCompanyInfo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value:
                                                _vm.sfExpressOrder
                                                  .receiveCompanyInfo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "receiveCompanyInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.receiveCompanyInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              其他信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "预计取件日期",
                                          prop: "expectedDate",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: "预计取件日期",
                                          },
                                          on: { change: _vm.changeBookDate },
                                          model: {
                                            value:
                                              _vm.sfExpressOrder.expectedDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "expectedDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.expectedDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "预计取件时间",
                                          prop: "expectedTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: "请选择时间段",
                                            },
                                            on: { change: _vm.changeBookClock },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.expectedTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "expectedTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.expectedTime",
                                            },
                                          },
                                          _vm._l(
                                            _vm.bookTimePerList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "是否需要包装",
                                          prop: "isPackage",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { fill: "#000" },
                                            model: {
                                              value:
                                                _vm.sfExpressOrder.isPackage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sfExpressOrder,
                                                  "isPackage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sfExpressOrder.isPackage",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "1" } },
                                              [_vm._v("是")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "0" } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "寄件商品信息",
                                          prop: "sendInfo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.sendInfo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sendInfo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.sendInfo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "顺丰备注",
                                          prop: "sfNote",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.sfNote,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "sfNote",
                                                $$v
                                              )
                                            },
                                            expression: "sfExpressOrder.sfNote",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: "Dyson备注",
                                          prop: "dysonNote",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: _vm.sfExpressOrder.dysonNote,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfExpressOrder,
                                                "dysonNote",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sfExpressOrder.dysonNote",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("\n    顺丰取件单履历\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "0px",
                "margin-right": "0px",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "50px",
                          "margin-right": "50px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "50px",
                              },
                              data: _vm.historyList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "reDate",
                                label: "修改时间",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "reRmk",
                                label: "修改人",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "orderSource",
                                label: "订单来源",
                                align: "center",
                                formatter: _vm.sourceFormat,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "num",
                                label: "业务单号",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sendName",
                                label: "寄件人名称",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sendMobile",
                                label: "寄件人电话",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "寄件地址", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.sendProvince +
                                              row.sendCity +
                                              row.sendDistrict +
                                              row.sendAddress
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sendInfo",
                                label: "寄件信息",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "receiveOrg",
                                label: "收件机构",
                                formatter: _vm.orgFormat,
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sfNote",
                                label: "顺丰备注",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dysonNote",
                                label: "dyson备注",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "expectedDate",
                                label: "预计取件日期",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "expectedTime",
                                label: "预计取件时间",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isPackage",
                                label: "是否需要包装",
                                align: "center",
                                formatter: _vm.packageFormat,
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total>0",
                            },
                          ],
                          attrs: {
                            total: _vm.total,
                            page: _vm.listQuery.page,
                            limit: _vm.listQuery.limit,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.listQuery, "page", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.listQuery, "limit", $event)
                            },
                            pagination: _vm.getHistoryList,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }