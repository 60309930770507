var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "顺丰路由状态履历信息" },
    [
      _c("label", { staticClass: "myLabelStyle" }, [
        _vm._v("顺丰单号： " + _vm._s(_vm.sfNum)),
      ]),
      _vm._v(" "),
      _c("sf-state", {
        ref: "sfState",
        staticStyle: { "padding-top": "20px" },
        attrs: { num: _vm.num },
        on: { setSFnum: _vm.setSFnum },
      }),
      _vm._v(" "),
      _c("sf-route", {
        ref: "sfRoute",
        staticStyle: { "padding-top": "20px" },
        attrs: { num: _vm.num },
        on: { setSFnum: _vm.setSFnum },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }