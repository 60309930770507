"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SFRouteState = require("@/api/common/SFRouteState");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    num: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      routeList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadRouteList4SF', function (num) {
      if (num) {
        _this.loadRouteList(num);
      }
    });
  },
  methods: {
    loadRouteList: function loadRouteList(num) {
      var _this2 = this;

      (0, _SFRouteState.queryRouteByNum)(num).then(function (response) {
        if (response && response.data) {
          _this2.routeList = response.data;

          if (_this2.routeList && _this2.routeList.length > 0) {
            _this2.$emit('setSFnum', _this2.routeList[0].waybillCode);
          }
        }
      });
    }
  }
};
exports.default = _default;