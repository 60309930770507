"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _list = require("@/api/quota/quota_in/list");

var _select = require("@/api/common/select");

var _list2 = require("@/api/quota/quota/list");

var _sysConfigs = require("@/api/common/sysConfigs");

var _vuex = require("vuex");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 机构下拉信息
// 查询机构额度
// 获取当前登陆用户信息
// 按钮权限控制
// 悬浮栏
var _default = {
  name: 'quotaInDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      isTemp: false,
      isSub: false,
      isCon: false,
      // 添加的信息
      quotaIn: {
        state: '',
        inOrgId: '',
        quotaType: '10',
        isPlus: '1',
        quota: '',
        memo: '',
        annexInfo: '',
        filePaths: []
      },
      quotaSum: '0',
      avaibQuota: '0',
      frozenQuota: '0',
      // 充值机构
      organizationList: [],
      // 额度类型
      typesOptions: [{
        label: '充值额度',
        value: '10'
      }, {
        label: '信用额度',
        value: '20'
      }],
      // 变动类型
      plusOptions: [{
        label: '增加',
        value: '1'
      }, {
        label: '减少',
        value: '0'
      }],
      // 校验规则
      rules: {
        inOrgId: [{
          required: true,
          message: '请选择机构',
          trigger: 'blur'
        }],
        quota: [{
          required: true,
          message: '请填写金额',
          trigger: 'blur'
        }, {
          pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
          message: '请正确填写金额(保留两位小数)',
          trigger: 'blur'
        }],
        quotaType: [{
          required: true,
          message: '请选择额度类型',
          trigger: 'blur'
        }],
        isPlus: [{
          required: true,
          message: '请选择方式',
          trigger: 'blur'
        }],
        memo: [{
          max: 200,
          message: '充值备注长度不能超过200个字',
          trigger: 'blur'
        }]
      },
      // 上传图片部分
      dialogImageUrl: '',
      dialogVisible: false,
      imgList: [],
      isMultiple: true,
      imgLimit: 5,
      sizeLimit: 10,
      uploadAction: '/uploadsever/fileUpload'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['loginUser'])),
  // 初始化
  created: function created() {
    if (this.id) {
      // 获取入库单详细信息
      this.getDetailInfo();
    } // 获取充值机构下拉框数据


    this.getOrgList(); // 配置信息获取
    // this.queryFileConfigs()
  },
  methods: {
    // 配置信息获取
    queryFileConfigs: function queryFileConfigs() {
      var _this = this;

      (0, _sysConfigs.queryFileConfigs)().then(function (response) {
        if (response.data) {
          if (response.data.fileLimit !== null) {
            _this.imgLimit = parseInt(response.data.fileLimit);
          }

          if (response.data.fileMaxSize !== null) {
            _this.sizeLimit = parseInt(response.data.fileMaxSize);
          }
        }
      });
    },
    // 获取入库单详细信息
    getDetailInfo: function getDetailInfo() {
      var _this2 = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response != null) {
          _this2.quotaIn = response.data;

          _this2.$set(_this2.quotaIn, 'quota', Math.abs(_this2.quotaIn.quota2Yuan)); // 获取额度


          _this2.initQuota(); // 图片 回显


          if (_this2.quotaIn.filePaths !== null) {
            for (var i = 0; i < _this2.quotaIn.filePaths.length; i++) {
              var obj = {};
              obj.name = i;
              obj.url = _this2.quotaIn.filePaths[i];

              _this2.imgList.push(obj);
            }
          } else {
            _this2.quotaIn.filePaths = [];
          }
        }
      });
    },
    // 获取充值机构下拉框数据
    getOrgList: function getOrgList() {
      var _this3 = this;

      // 只能直属下级
      var param = {
        orgId: this.loginUser.orgId
      };
      (0, _select.getOrgSubList)(param).then(function (response) {
        _this3.organizationList = response.data;
      });
    },
    // 机构切换 重新加载额度
    orgChange: function orgChange() {
      this.initQuota();
    },
    // 获取额度
    initQuota: function initQuota() {
      var _this4 = this;

      if (this.quotaIn.inOrgId) {
        (0, _list2.getQuota)(this.quotaIn.inOrgId).then(function (response) {
          if (response != null && response.data) {
            _this4.quotaSum = response.data.quota;
            _this4.avaibQuota = response.data.avaibQuota;
            _this4.frozenQuota = response.data.frozenQuota;
          } else {
            _this4.quotaSum = '0';
            _this4.avaibQuota = '0';
            _this4.frozenQuota = '0';
          }
        });
      } else {
        this.quotaSum = '0';
        this.avaibQuota = '0';
        this.frozenQuota = '0';
      }
    },
    // 暂存
    tempSave: function tempSave() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this5.isTemp = true;
          (0, _list.tempSave)(_this5.quotaIn).then(function (response) {
            if (response != null && response.data) {
              _this5.$message({
                message: '暂存成功',
                type: 'success'
              });

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/quota/quota_in/list'
              });
            } else {
              _this5.isTemp = false;

              _this5.$message({
                showClose: true,
                message: '暂存失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 提交
    submitSave: function submitSave() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this6.isSub = true; // 提交后台

          (0, _list.submitSave)(_this6.quotaIn).then(function (response) {
            if (response != null && response.success) {
              _this6.$message({
                message: '提交成功',
                type: 'success'
              });

              _this6.$store.dispatch('tagsView/delView', _this6.$route);

              _this6.$router.push({
                path: '/quota/quota_in/list'
              });
            } else {
              _this6.isSub = false;

              _this6.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 确认
    confirmSave: function confirmSave() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this7.isCon = true;
          (0, _list.confirmSave)(_this7.quotaIn).then(function (response) {
            if (response !== null && response.success) {
              _this7.$message({
                message: '确认操作成功',
                type: 'success'
              });

              _this7.$store.dispatch('tagsView/delView', _this7.$route);

              _this7.$router.push({
                path: '/quota/quota_in/list'
              });
            } else {
              _this7.isCon = false;

              _this7.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/quota/quota_in/list'
      });
    },
    // 上传文件部分
    // 移除文件
    handleRemove: function handleRemove(file, fileList) {
      this.quotaIn.filePaths = [];

      for (var i = 0; i < fileList.length; i++) {
        if (this.id) {
          this.quotaIn.filePaths.push(fileList[i].url);
        } else {
          this.quotaIn.filePaths.push(process.env.VUE_APP_FASTDFS_STORAGE_URL + fileList[i].response.data);
        }
      } // this.imgList = []
      // if (this.dialogStatus === 'update') {
      //   this.quotaIn.filePaths.splice(this.quotaIn.filePaths.indexOf(file.url), 1)
      // } else {
      //   this.quotaIn.filePaths.splice(this.quotaIn.filePaths.indexOf(process.env.VUE_APP_FASTDFS_STORAGE_URL + file.response.data), 1)
      // }
      // for (let i = 0; i < this.quotaIn.filePaths.length; i++) {
      //   const obj = {}
      //   obj.name = i
      //   obj.url = this.quotaIn.filePaths[i]
      //   this.imgList.push(obj)
      // }


      console.log(this.quotaIn.filePaths);
    },
    // 预览图片时调用
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传之前调用做一些拦截限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = true;
      var sizeLimit = file.size / 1024 / 1024 < this.sizeLimit; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }

      if (!sizeLimit) {
        this.$message.error('上传文件大小不能超过' + this.sizeLimit + 'MB!');
      }

      return isJPG && sizeLimit;
    },
    // 文件上传成功
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      if (res.code !== 'success') {
        this.imgUploadError();
      } else {
        this.quotaIn.filePaths.push(process.env.VUE_APP_FASTDFS_STORAGE_URL + res.data);
      }
    },
    // 文件上传失败
    imgUploadError: function imgUploadError() {
      this.$message.error('上传文件失败!');
    },
    // 文件上传超过数量限制
    handleExceed: function handleExceed() {
      this.$message.error('上传文件不能超过' + this.imgLimit + '个!');
    },
    handleDownload: function handleDownload(file) {
      if (file && file.url) {
        window.location.href = file.url + '?parameter=1';
      }
    }
  }
};
exports.default = _default;