"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      paymentChannelOptions: _constant.default.paymentChannelOptions // 支付方式

    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;