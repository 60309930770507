"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.replace");

var _list = require("@/api/consult/list");

var _list2 = require("@/api/basics/consultType/list");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _asyncExport = require("@/api/common/asyncExport");

var _FixTop = _interopRequireDefault(require("@/components/FixTop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
var _default = {
  name: 'qiyuList',
  // eslint-disable-line
  components: {
    Pagination: _Pagination.default,
    FixTop: _FixTop.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      id: this.$route.query.id,
      sessionId: this.$route.query.sessionId,
      phone: this.$route.query.phone,
      oneLevelSelect: [],
      // 咨询单一级归类
      twoLevelSelect: [],
      // 咨询单二级归类
      threeLevelSelect: [],
      // 咨询单三级归类
      // 检索条件隐藏显示控制
      activeNames4Condition: '1',
      // 咨询工单主状态
      stateOptions: [{
        'val': '00',
        'name': '新建'
      }, {
        'val': '10',
        'name': '处理中'
      }, {
        'val': '20',
        'name': '已结束'
      }],
      // 咨询工单子状态
      subStateOptions: [{
        'val': '1',
        'name': '新建'
      }, {
        'val': '2',
        'name': '待客户上传'
      }, {
        'val': '3',
        'name': '客户已传待审阅'
      }, {
        'val': '4',
        'name': '客户已传已审阅'
      }, {
        'val': '5',
        'name': '已转服务'
      }, {
        'val': '6',
        'name': '已转销售'
      }, {
        'val': '7',
        'name': '已转退货'
      }, {
        'val': '8',
        'name': '仅咨询'
      }, {
        'val': '9',
        'name': '作废'
      }, {
        'val': '10',
        'name': '待下单'
      }, {
        'val': '11',
        'name': '已转退款'
      }, {
        'val': '12',
        'name': '已转VSE'
      }],
      // 咨询工单业务类型
      typeOptions: [{
        'val': '01',
        'name': '维修'
      }, {
        'val': '02',
        'name': '换货'
      }, {
        'val': '03',
        'name': '检测'
      }, {
        'val': '04',
        'name': '销售'
      }, {
        'val': '05',
        'name': '退货'
      }],
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        num: undefined,
        oneLevel: undefined,
        twoLevel: undefined,
        threeLevel: undefined,
        state: undefined,
        subState: undefined,
        type: undefined,
        applicantTel: undefined,
        inStartDate: undefined,
        inEndDate: undefined,
        createDate: undefined,
        sn: undefined,
        content: '',
        orderByField: undefined,
        isAsc: ''
      },
      createDate: '',
      // ------------------------------------------------------------------------------------------------------------------------
      // 导出
      exportQuery: {
        queue: process.env.VUE_APP_SQS_QUEUE_EXPORT_ORDER_01,
        taskName: '咨询工单导出',
        inRmk: this.$store.state.user.loginUser.memberId,
        data: ''
      }
    };
  },
  created: function created() {
    this.getOneLevelOptions();
    this.getList();
  },
  activated: function activated() {
    this.listQuery.page = 1;
    this.getOneLevelOptions();
    this.getList();
  },
  methods: {
    // 监听sn输入
    checkSnIn: function checkSnIn(val) {
      var str = val.replace(/-/g, '');
      var str2 = '';

      if (str.length <= 3) {
        str2 = str;
      }

      if (str.length > 3 && str.length <= 5) {
        str2 = str.substring(0, 3) + '-';
        str2 = str2 + str.substring(3, str.length);
      }

      if (str.length > 5) {
        str2 = str.substring(0, 3) + '-';
        str2 = str2 + str.substring(3, 5);
        str2 = str2 + '-';
        str2 = str2 + str.substring(5, str.length);
      }

      this.listQuery.sn = str2;
    },
    // 检索条件隐藏显示控制
    condtionShowSwitch: function condtionShowSwitch() {
      this.activeNames4Condition = this.activeNames4Condition === '1' ? '0' : '1';
    },
    // 跳转业务单详情页面
    gotoQueryHtml: function gotoQueryHtml(row) {
      if (row.type === '01' || row.type === '02' || row.type === '03') {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          name: 'qiyuOrderDetail',
          query: {
            id: row.bizNum,
            sessionId: this.sessionId,
            phone: this.phone,
            pageType: '2'
          }
        });
      }

      if (row.type === '04') {
        // 转销售单
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          name: 'qiyuSaleDetail',
          query: {
            id: row.id,
            dialogStatus: 'create',
            sessionId: this.sessionId,
            phone: this.phone,
            pageType: '2'
          }
        });
      }

      if (row.type === '05') {
        // 转退货单
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          name: 'qiyuReturnDetail',
          query: {
            id: row.id,
            dialogStatus: 'query',
            sessionId: this.sessionId,
            phone: this.phone,
            pageType: '2'
          }
        });
      }
    },
    // 详情页面
    gotoEditHtml: function gotoEditHtml(row, event) {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'qiyuDetail2',
        query: {
          sessionId: this.sessionId,
          phone: this.phone,
          isQuery: '0',
          id: row.id
        }
      });
    },
    // 分页查询
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _list.findQiyuPageList)(this.listQuery).then(function (response) {
        _this.list = response.data.records;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    // 重置按钮事件
    cleanBtnClick: function cleanBtnClick() {
      this.listQuery = {
        menu: '6',
        page: 1,
        limit: 10
      };
    },
    // 查询按钮事件
    searchBtnClick: function searchBtnClick() {
      this.listQuery.page = 1;
      this.getList();
    },
    // 排序
    sortChange: function sortChange(sortInfo) {
      this.listQuery.orderByField = sortInfo.prop;

      if (sortInfo.order === 'ascending') {
        this.listQuery.isAsc = '1';
      } else {
        this.listQuery.isAsc = '0';
      }

      this.listQuery.page = 1;
      this.getList();
    },
    // 快捷TAG查询
    searchTagHandleClick: function searchTagHandleClick(tab, event) {
      this.listQuery.page = 1;
      this.getList();
    },
    // 删除新建状态的咨询单  状态变更为已结束  子状态变更为 作废
    deleteConsult: function deleteConsult(row) {
      var _this2 = this;

      (0, _list.deleteQiyuConsult)(row.id).then(function (response) {
        if (response.data) {
          _this2.$message({
            showClose: true,
            message: '删除成功！',
            type: 'success'
          });

          _this2.getList();
        } else {
          _this2.$message({
            showClose: true,
            message: response.msg || '操作失败！',
            type: 'warning'
          });
        }
      });
    },
    // 工单主状态格式化
    stateFormat: function stateFormat(row) {
      return this.stateOptions.map(function (type) {
        if (row.state.trim() === type.val) {
          return type.name;
        }

        return '';
      });
    },
    // 工单子状态格式化
    subStateFormat: function subStateFormat(row) {
      return this.subStateOptions.map(function (type) {
        if (row.subState.trim() === type.val) {
          return type.name;
        }

        return '';
      });
    },
    // 业务类型格式化
    typeFormat: function typeFormat(row) {
      return this.typeOptions.map(function (type) {
        if (row.type.trim() === type.val) {
          return type.name;
        }

        return '';
      });
    },
    // 初始化咨询单一级归类
    getOneLevelOptions: function getOneLevelOptions() {
      var _this3 = this;

      (0, _list2.getConsultTypeByParentId)('0').then(function (res) {
        _this3.oneLevelSelect = res.data;
      });
    },
    // 咨询单二级归类
    oneLevelChange: function oneLevelChange(oneLevel) {
      var _this4 = this;

      if (oneLevel) {
        (0, _list2.getConsultTypeByParentId)(oneLevel).then(function (response) {
          _this4.twoLevelSelect = response.data;
        });
      }

      this.listQuery.twoLevel = '';
      this.listQuery.threeLevel = '';
    },
    // 咨询单三级归类
    twoLevelChange: function twoLevelChange(twoLevel) {
      var _this5 = this;

      if (twoLevel) {
        (0, _list2.getConsultTypeByParentId)(twoLevel).then(function (response) {
          _this5.threeLevelSelect = response.data;
        });
      }

      this.listQuery.threeLevel = '';
    },
    // 导出
    handelExport: function handelExport() {
      var _this6 = this;

      var Base64 = require('js-base64').Base64;

      this.exportQuery.data = Base64.encode(JSON.stringify(this.listQuery));
      (0, _asyncExport.asyncExport)(this.exportQuery).then(function (response) {
        if (response.code === 200) {
          _this6.$message.success('创建导出任务成功! 任务号:[' + response.data + '] 请至 [信息查询] -> [任务查询] 中查看任务详情');
        } else {
          _this6.$message.error('创建导出任务失败:' + response.msg);
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'qiyuDetail',
        query: {
          id: this.id,
          sessionId: this.sessionId,
          phone: this.phone
        }
      });
    }
  }
};
exports.default = _default;