"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _router = require("@/router");

var _auth = require("@/utils/auth");

var _user = require("@/api/user");

/** 用户登录态 */
var state = {
  token: (0, _auth.getToken)(),
  loginUser: {},
  avatar: '',
  roles: [],
  pCodes: []
};
/** 用户登录态修改器 */

var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LOGINUSER: function SET_LOGINUSER(state, loginUser) {
    state.loginUser = loginUser;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_PER_CODE: function SET_PER_CODE(state, perCodes) {
    state.pCodes = perCodes;
  }
};
/** 用户登录态处理器 */

var actions = {
  /**
   * 登入认证
   * @param {*} context
   * @param {username:string,password:string} userInfo 账号密码信息
   */
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    (0, _auth.removeToken)();
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        // alert('access_token:' + response.access_token)
        commit('SET_TOKEN', response.access_token);
        (0, _auth.setToken)(response.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * 取得当前认证用户信息
   * @param {*} context
   */
  permission: function permission(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.permission)(state.token).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('验证失败，请重新登录.');
        } // console.log(state.token)
        // console.log(response)


        var user = data.user,
            roles = data.wit,
            pcodes = data.pcodes; // console.log(roles)
        // console.log(loginUser)
        // roles must be a non-empty array

        if (!roles || roles.length <= 0) {
          reject('警告: 登录已过期!');
        }

        commit('SET_LOGINUSER', user);
        commit('SET_ROLES', roles);
        commit('SET_PER_CODE', pcodes);
        commit('SET_AVATAR', user.avatar || require('@/assets/avatar/default.png'));
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * 登出处理
   * @param {*} context
   */
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        (0, _auth.removeToken)();
        (0, _router.resetRouter)();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * 清空本地访问令牌
   * @param {*} context
   */
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      resolve();
    });
  },

  /**
   * IDaaS登入认证
   * @param {*} context
   * @param {username:string,password:string} userInfo 账号密码信息
   */
  IDaaSLogin: function IDaaSLogin(_ref5, id_token) {
    var commit = _ref5.commit;
    (0, _auth.removeToken)();
    return new Promise(function (resolve, reject) {
      (0, _user.IDaaSLogin)(id_token).then(function (response) {
        // alert('access_token:' + response.access_token)
        commit('SET_TOKEN', response.access_token);
        (0, _auth.setToken)(response.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;