var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "dataForm",
      attrs: { model: _vm.quotaApprove, "label-width": "150px", size: "50%" },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-left": "0px", "margin-right": "0px" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "审批附件" },
                    [
                      _c(
                        "el-divider",
                        {
                          staticClass: "myStyle",
                          attrs: { "content-position": "left" },
                        },
                        [_vm._v("审批附件")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          staticStyle: { "border-top": "none" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("upload-imgs", {
                                            attrs: {
                                              limit: 1,
                                              disabled: _vm.isAudit,
                                              attachment:
                                                _vm.quotaApprove.adjunctPicture,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("upload-file-msg", {
                                            attrs: {
                                              limit: 1,
                                              disabled: _vm.isAudit,
                                              "email-name":
                                                _vm.quotaApprove
                                                  .adjunctEmailName,
                                              attachment:
                                                _vm.quotaApprove.adjunctEmail,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }