"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeBrandById = changeBrandById;
exports.createServiceConfig = createServiceConfig;
exports.fetchList = fetchList;
exports.listAll = listAll;
exports.queryById = queryById;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 添加，修改保存
 * @param data
 */
function createServiceConfig(data) {
  return (0, _request.default)({
    url: '/bus_app/serviceConfig/save',
    method: 'post',
    data: data
  });
}
/**
 * 查询分页
 * @param query
 */


function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/serviceConfig/list',
    method: 'get',
    params: query
  });
}
/**
 * 禁用、启用
 * @param data
 */


function changeBrandById(data) {
  return (0, _request.default)({
    url: '/bus_app/serviceConfig/updateEnable',
    method: 'get',
    params: data
  });
}
/**
 * queryById
 * @param params
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/serviceConfig/getTaskConfigById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 查询所有
 */


function listAll() {
  return (0, _request.default)({
    url: '/bus_app/serviceConfig/listAll',
    method: 'get'
  });
}