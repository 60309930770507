"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.getQuota = getQuota;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询额度分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/quota/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据机构ID查询额度
 * @param query
 */


function getQuota(orgId) {
  return (0, _request.default)({
    url: '/scm_app/quota/getQuota',
    method: 'post',
    params: {
      orgId: orgId
    }
  });
}