"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order/order");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
var _default = {
  components: {
    uploadFile: _uploadFile.default
  },
  filters: {
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      verifyResultOptions: _constant.default.verifyResultOptions2,
      // ----------------------------------------------------------------------------------------------------------------------------------
      checkItemList4show: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadCheckItems', function (id) {
      if (id) {
        _this.initShowcheckItemList4show(id);
      }
    });
  },
  methods: {
    initShowcheckItemList4show: function initShowcheckItemList4show(id) {
      var _this2 = this;

      (0, _order.getCheckItems)({
        orderId: id
      }).then(function (response) {
        if (response.data) {
          var itemArray = response.data;
          var len = itemArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this2.checkItemList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = itemArray.slice(i * n, i * n + n);

            _this2.checkItemList4show.push(temp);
          }
        }
      });
    }
  }
};
exports.default = _default;