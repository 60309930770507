"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _toConsumableArray2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _list = require("@/api/stock/stock_trim/list");

var _list2 = require("@/api/logistics/stock_log/list");

var _select = require("@/api/common/select");

var _stock_sn = require("@/api/common/stock_sn");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// 分页组件
// 按钮波纹
var _default = {
  name: 'StockTrimDetail',
  components: {
    Pagination: _Pagination.default,
    Sticky: _Sticky.default
  },
  directives: {
    waves: _waves.default,
    permission: _index.default
  },
  data: function data() {
    return {
      ShowSnDialogFormVisible: false,
      // 串号查看弹出框
      showSnList: [],
      downloadLoading: false,
      id: this.$route.query.id,
      total: 0,
      // 查询条件
      listQuery: {
        page: 1,
        limit: 10,
        sapTag: '',
        modelId: '',
        sku: ''
      },
      stockTrim: {
        warehouseCode: '',
        memo: ''
      },
      // 添加的信息
      stockTrimItemsInfo: {
        id: '',
        sysCode: '',
        num: '',
        parentId: this.$route.query.id,
        skuId: '',
        skuCode: '',
        price: '',
        quantity: '',
        checkQuantity: ''
      },
      rules: {
        warehouseCode: [{
          required: true,
          message: '请选择仓库',
          trigger: 'blur'
        }],
        memo: [{
          max: 200,
          message: '库存调整备注长度不能超过200个字',
          trigger: 'blur'
        }]
      },
      // 校验规则
      warehouseList: [],
      modelList: [],
      // 弹出框select 型号
      skuListValidate: false,
      // 明细不能为空校验标识
      addSkudialogFormVisible: false,
      // 弹出框是否可见
      list4Sku: [],
      // 明细列表数据
      loadingList4Sku: false,
      // 明细加载
      // <状态控制> 删除关联型号按钮
      deleteBtnDisabled: true,
      // <状态控制> 隐藏/显示 型号选择折叠面板
      dialogFormVisible_0: false,
      dialogFormVisible_1: false,
      // <状态控制> 添加关联型号按钮
      addBtnDisabled: true,
      loadingList4SkuSelect: false,
      // 明细的数据加载
      infoDisabled: false,
      // 主信息不可操作标识
      isTemp: false,
      // 暂存按钮不可操作标识
      isSub: false,
      // 提交按钮不可操作标识
      isCon: false,
      // 确认按钮不可操作标识
      listLoading: false,
      // 弹出框的数据加载
      skuItemsList: [],
      // 弹出框的数据
      addSnDialogFormVisible: false,
      findSnDialogFormVisible: false,
      snIn: '',
      snNumber: 0,
      snList: [],
      snSure: [],
      rowSku: {},
      snFindList: []
    };
  },
  // 初始化
  created: function created() {
    // 获取详细信息
    this.getDetailInfo(); // 获取明细信息

    this.getDetailItems(); // 获取下拉框数据

    this.getModelList();
  },
  methods: {
    // 获取库存调整单详细信息
    getDetailInfo: function getDetailInfo() {
      var _this = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response != null) {
          _this.stockTrim = response.data;

          _this.getWarehouseList();
        }
      });
    },
    // 获取库存调整单明细信息
    getDetailItems: function getDetailItems() {
      var _this2 = this;

      (0, _list.getItemsList)(this.id).then(function (response) {
        if (response != null && response.data != null && response.data.length > 0) {
          _this2.infoDisabled = true;
          _this2.isSub = false;
          _this2.list4Sku = response.data;
        } else {
          _this2.infoDisabled = false;
          _this2.isSub = true;
          _this2.list4Sku = [];
        }
      });
    },
    // 弹出框型号下拉选择
    getModelList: function getModelList() {
      var _this3 = this;

      (0, _select.getModelSelect)().then(function (response) {
        _this3.modelList = response.data;
      });
    },
    // 入库仓库下拉
    getWarehouseList: function getWarehouseList() {
      var _this4 = this;

      (0, _select.getHouseSelect)(this.stockTrim.orgId).then(function (response) {
        _this4.warehouseList = response.data;
      });
    },
    checkboxT: function checkboxT(row) {
      if (row.state === '10') {
        return false;
      } else {
        return true;
      }
    },
    // 暂存
    tempSave: function tempSave() {
      var _this5 = this;

      this.isTemp = true;
      (0, _list.tempSave)(this.stockTrim).then(function (response) {
        if (response != null && response.data) {
          _this5.$message({
            message: '暂存成功',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/stock/stock_trim'
          });
        } else {
          _this5.isTemp = false;

          _this5.$message({
            showClose: true,
            message: '暂存失败！',
            type: 'warning'
          });
        }
      });
    },
    // 提交
    submitSave: function submitSave() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this6.isSub = true;
          (0, _list.submitSave)(_this6.stockTrim).then(function (res) {
            if (res != null && res.data) {
              _this6.$message({
                message: '提交成功',
                type: 'success'
              });

              _this6.$store.dispatch('tagsView/delView', _this6.$route);

              _this6.$router.push({
                path: '/stock/stock_trim'
              });
            } else {
              _this6.isSub = false;

              _this6.$message({
                showClose: true,
                message: '提交失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 确认
    confirmSave: function confirmSave() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this7.isSub = true;
          (0, _list.confirmSave)(_this7.stockTrim).then(function (res) {
            if (res != null && res.data) {
              _this7.$message({
                message: '确认成功',
                type: 'success'
              });

              _this7.$store.dispatch('tagsView/delView', _this7.$route);

              _this7.$router.push({
                path: '/stock/stock_trim'
              });
            } else {
              _this7.isSub = false;

              _this7.$message({
                showClose: true,
                message: '确认失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // // 返回
    // returnBtn() {
    //   this.$router.push({ path: '/stock/stock_trim' })
    // },
    // 添加物料明细弹出框
    addSkuClick: function addSkuClick() {
      var _this8 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _list.tempSave)(_this8.stockTrim).then(function (response) {
            if (response != null && response.data) {
              _this8.getDetailInfo();

              _this8.addSkudialogFormVisible = true;
            }
          });
        }
      });
    },
    // 【已关联型号列表】checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.deleteBtnDisabled = this.multipleSelection.length < 1;
    },
    // 【已关联型号列表】单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 弹出框查询按钮事件
    handleFilter: function handleFilter() {
      var _this9 = this;

      this.listLoading = true;
      this.listQuery.sapTag = '1';
      this.listQuery.warehouse_code = this.stockTrim.warehouseCode;
      (0, _list2.findPageList)(this.listQuery).then(function (response) {
        if (response != null) {
          _this9.skuItemsList = response.data.records;
          _this9.total = response.data.total;
        }

        _this9.listLoading = false;
      });
    },
    // 弹出框按钮重置
    resetBtn: function resetBtn() {
      this.listQuery = {
        page: 1,
        limit: 10
      };
    },
    // 弹出框物料添加
    handleModifyStatus: function handleModifyStatus(row) {
      var _this10 = this;

      var tempData = Object.assign({}, this.stockTrimItemsInfo);
      tempData.skuId = row.skuId;
      tempData.quantity = row.usableStock;
      tempData.checkQuantity = row.checkQuantity;
      (0, _list.addItems)(tempData).then(function (response) {
        if (response != null) {
          if (response.data) {
            var index = _this10.skuItemsList.indexOf(row);

            _this10.skuItemsList.splice(index, 1);

            _this10.getDetailInfo();

            _this10.getDetailItems();

            _this10.$message({
              message: '添加成功',
              type: 'success'
            });
          } else {
            _this10.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        } else {
          _this10.$message({
            showClose: true,
            message: '添加失败',
            type: 'warning'
          });
        }
      });
    },
    // 【明细列表】删除方法
    deleteModelClick: function deleteModelClick() {
      var _this11 = this;

      this.loadingList4SkuSelect = true;

      if (this.multipleSelection.length > 0) {
        var ids = [];
        this.multipleSelection.forEach(function (v) {
          ids.push(v.id);
        });
        this.deleteBtnDisabled = true;
        (0, _list.deleteItems)(this.id, ids.join(',')).then(function (response) {
          if (response != null && response.data) {
            _this11.getDetailInfo();

            _this11.getDetailItems();

            _this11.$message({
              message: '删除成功',
              type: 'success'
            });
          } else {
            _this11.deleteBtnDisabled = false;

            _this11.$message({
              showClose: true,
              message: '删除失败',
              type: 'warning'
            });
          }
        });
      }

      this.loadingList4SkuSelect = false;
    },
    // 选择SN
    handleSn: function handleSn(row) {
      var _this12 = this;

      this.resetSn();
      this.sku = row;
      var query = {
        num: this.stockTrim.num,
        orgId: this.stockTrim.orgId,
        skuId: row.skuId,
        warehouseCode: this.stockTrim.warehouseCode,
        isEnable: '1',
        type: '04'
      };

      if (Number(row.quantity) >= Number(row.checkQuantity)) {
        this.snNumber = row.quantity - row.checkQuantity; // 根据num+skuId在串号附属表查询是否有已添加的串号

        (0, _stock_sn.repetitionSn)(query).then(function (response) {
          _this12.snSure = response.data;
        }); // 根据skuId在串号表查询所有

        (0, _stock_sn.findStockSns)(query).then(function (response) {
          response.data.map(function (data) {
            var sn = {
              'key': data,
              'label': data
            };

            _this12.snList.push(sn);
          });
        });
        this.dialogFormVisible_0 = true;
      } else {
        this.snNumber = row.checkQuantity; // 根据skuId在串号表查询所有

        (0, _stock_sn.findStockSns)(query).then(function (res1) {
          (0, _stock_sn.repetitionSn)(query).then(function (res2) {
            _this12.snSure = res2.data;
            res1.data.concat(res2.data);
            Array.from(new Set(res1.data.concat(res2.data))).map(function (data) {
              var sn = {
                'key': data,
                'label': data
              };

              _this12.snList.push(sn);
            });
            _this12.dialogFormVisible_1 = true;
          });
        });
      }
    },
    // 保存串号到附属表
    saveSn: function saveSn() {
      var _this13 = this;

      console.log(this.snNumber);
      console.log(this.snSure.length);

      if (this.snNumber !== this.snSure.length) {
        this.$message({
          message: '还需要添加' + (this.snNumber - this.snSure.length) + '个串号',
          type: 'warning'
        });
        return false;
      }

      var snData = {
        'snSure': this.snSure,
        'num': this.stockTrim.num,
        'skuId': this.sku.skuId,
        'skuCode': this.sku.skuCode,
        'type': '04',
        'linkId': this.sku.id
      };
      (0, _stock_sn.saveSn)(snData).then(function (response) {
        if (response.data) {
          _this13.$message({
            message: '保存SN成功',
            type: 'success'
          });

          _this13.resetSn();

          _this13.dialogFormVisible_0 = false;
          _this13.dialogFormVisible_1 = false;
        } else {
          _this13.$message({
            message: '保存SN失败',
            type: 'error'
          });
        }
      });
      this.resetSn();
    },
    // 取消
    cancelSn: function cancelSn() {
      this.resetSn();
      this.dialogFormVisible_0 = false;
      this.dialogFormVisible_1 = false;
    },
    // 重置
    resetSn: function resetSn() {
      this.snNumber = '';
      this.snList = [];
      this.snSure = [];
      this.sku = undefined;
    },
    // 查看串号(串号附属表)
    showSn: function showSn(row) {
      var _this14 = this;

      var query = {
        num: this.stockTrim.num,
        orgId: this.stockTrim.orgId,
        skuId: row.skuId,
        warehouseCode: this.stockTrim.warehouseCode,
        isEnable: '1',
        type: '04'
      }; // 根据num+skuId在串号附属表查询是否有已添加的串号

      (0, _stock_sn.repetitionSn)(query).then(function (response) {
        _this14.showSnList = response.data;
      });
      this.ShowSnDialogFormVisible = true;
    },
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>串号操作<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
    snInput: function snInput() {
      var _this15 = this;

      // 字符串转数组并去重
      var sns = (0, _toConsumableArray2.default)(new Set(this.snIn.split(' ')));
      sns.map(function (value) {
        _this15.snAdd(value);
      });

      if (this.snMessage !== undefined && this.snMessage !== '') {
        this.$message({
          message: this.snMessage + '已经添加过了',
          type: 'warning'
        });
      }

      this.snMessage = '';
      this.snIn = '';
    },
    // sn录入
    snAdd: function snAdd(sn) {
      var _this16 = this;

      var is = true;
      this.snList.map(function (value) {
        if (value.key === sn.trim()) {
          _this16.snMessage += sn + ','; // return false 不跳出继续走 (= _ =)

          is = false;
        }
      });

      if (is && sn !== undefined && sn.trim() !== '') {
        // 验证串号是否可用
        var snQuery = {
          num: this.stockTrim.num,
          skuId: this.sku.skuId,
          sn: sn.trim()
        };
        (0, _stock_sn.validInPsn)(snQuery).then(function (response) {
          var sn1 = {
            'key': sn.trim(),
            'label': sn.trim(),
            'disabled': response.data
          };

          _this16.snList.unshift(sn1);
        });
      }
    }
  }
};
exports.default = _default;