"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/** 订单来源*/
var expressSourceSelect = [{
  val: '01',
  name: '天猫戴森旗舰店'
}, {
  val: '02',
  name: '京东自营店'
}, {
  val: '03',
  name: '淘宝直销店'
}, {
  val: '04',
  name: '微店'
}, {
  val: '05',
  name: '官网'
}, {
  val: '06',
  name: '线下门店'
}, {
  val: '07',
  name: '抖音'
}, {
  val: '08',
  name: '小红书'
}, {
  val: '09',
  name: '销售单'
}, {
  val: '10',
  name: '备用机'
}, {
  val: '11',
  name: '客服中心'
}, {
  val: '12',
  name: '其他'
}];
/** 运单状态*/

var expressState = [{
  val: '00',
  name: '待下单'
}, {
  val: '01',
  name: '已下单'
}, {
  val: '10',
  name: '已揽件'
}, {
  val: '40',
  name: '已签收'
}, {
  val: '110',
  name: '已拦截'
}, {
  val: '120',
  name: '客户取消寄件'
}, {
  val: '130',
  name: '无法揽收'
}, {
  val: '140',
  name: '客户重复下单'
}, {
  val: '99',
  name: '已销毁'
}];
/** 來源*/

var sourceMap = [{
  name: '天猫戴森旗舰店',
  val: '01'
}, {
  name: '京东自营店',
  val: '02'
}, {
  name: '淘宝直销店',
  val: '03'
}, {
  name: '微店',
  val: '04'
}, {
  name: '官网',
  val: '05'
}, {
  name: '线下门店',
  val: '06'
}, {
  name: '抖音',
  val: '07'
}, {
  name: '小红书',
  val: '08'
}, {
  name: '销售单',
  val: '09'
}, {
  name: '备用机',
  val: '10'
}, {
  name: '客服中心',
  val: '11'
}, {
  name: '其他',
  val: '12'
}];
var _default = {
  expressSourceSelect: expressSourceSelect,
  expressState: expressState,
  sourceMap: sourceMap
};
exports.default = _default;