"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 外呼信息
// 费用明细
// 新建任务工单
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCallout: _calloutList.default,
    orderPrice: _checkPrice.default,
    NewTask: _NewTask.default
  },
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      serviceFeeIdDisabled: true,
      id: this.$route.params.id,
      step: '3',
      consumerShow: '0',
      productShow: '0',
      calloutShow: '0',
      isReadOnly: false,
      dialogFormVisible: false,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      checkResultOptions: _constant.default.checkResultOptions,
      feeTypeOptions: _constant.default.feeTypeOptions,
      recheckTypeOptions: _constant.default.recheckTypeOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      rules: {
        serviceFeeId: [{
          required: true,
          message: '用户收费是必填的',
          trigger: 'blur'
        }],
        recheckType: [{
          required: true,
          message: '复核类型是必填的',
          trigger: 'blur'
        }]
      },
      rulesRecheck: {
        recheckDenyReason: [{
          required: true,
          message: '复核拒绝原因是必填的',
          trigger: 'blur'
        }, {
          max: 20,
          message: '长度不能超过20 个字符',
          trigger: 'blur'
        }]
      },
      userfeeSelect: [],
      // ----------------------------------------------------------------------------------------------------------------------------------
      schemeList: [],
      schemeListLoading: false,
      // ----------------------------------------------------------------------------------------------------------------------------------
      skuList: [],
      skuFeeList: [],
      skuListLoading: false,
      skuFeeListLoading: false,
      orderPriceShow: false,
      exchangeFlag: false,
      // ----------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------------------------------------------------------------------
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3'
      },
      skuFeeAdd: {
        feeType: undefined,
        serviceFeeId: undefined
      },
      skuSelect: [] // ---------------------------------------------------------------------------------------------------------------

    };
  },
  computed: {
    confirmBtnStatus: function confirmBtnStatus() {
      return ['S203', 'S300', 'S301', 'S302'].includes(this.orderInfo.subState);
    },
    skuFeeAddBtnDisabled: function skuFeeAddBtnDisabled() {
      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        return !this.skuFeeAdd.feeType || this.skuFeeAdd.feeType === '' || !this.skuFeeAdd.serviceFeeId || this.skuFeeAdd.serviceFeeId === '';
      } else if (this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203') {
        return !this.skuFeeAdd.feeType || this.skuFeeAdd.feeType === '' || !this.skuFeeAdd.serviceFeeId || this.skuFeeAdd.serviceFeeId === '';
      } else {
        return true;
      }
    },
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        return !this.skuAdd.feeType || this.skuAdd.feeType === '' || !this.skuAdd.skuCode || this.skuAdd.skuCode === '';
      } else if (this.orderInfo.checkType === '02') {
        return this.skuList.length > 0 || this.skuList.length === 0 && (!this.skuAdd.skuCode || this.skuAdd.skuCode === '');
      } else {
        return true;
      }
    }
  },
  created: function created() {
    var _this = this;

    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
      this.loadSchemeList();
      this.loadOrderSkuList();
      this.loadOrderSkuFeeList();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);
      });
    }
  },
  methods: {
    // -------------------------------------------------------------------------------------------------------------------------------------------
    initSelect: function initSelect() {
      var _this2 = this;

      (0, _select.getSkuFeeSelect)({
        isEnable: '1'
      }).then(function (res) {
        _this2.userfeeSelect = res.data;
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this3 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this3.orderInfo = response.data; // console.log(JSON.stringify(this.orderInfo))

          if (_this3.orderInfo.checkSubType === '0102' || _this3.orderInfo.checkSubType === '0103' || _this3.orderInfo.checkType === '02' && _this3.orderInfo.checkSubType === '0203') {
            _this3.$refs.checkPrice.$emit('loadCheckInfo', _this3.orderInfo.id);

            _this3.orderPriceShow = true;
          } // cbc29pc559fe64085aaea9430842f52cd  为PC品类id


          if (_this3.orderInfo.checkSubType === '0203' && _this3.orderInfo.orderSkuList !== null && _this3.orderInfo.orderSkuList[0].exchange !== null && _this3.orderInfo.orderSkuList[0].exchange !== '0' && _this3.orderInfo.firstClassCategory !== null && _this3.orderInfo.firstClassCategory.isEnable === '1') {
            _this3.exchangeFlag = true;
          }

          if (_this3.orderInfo.subState === 'S304') {
            _this3.orderInfo.recheckType = _this3.recheckTypeOptions[1].val;

            if (_this3.orderInfo.checkSubType !== '0203') {
              _this3.rules = {
                recheckType: [{
                  required: true,
                  message: '复核类型是必填的',
                  trigger: 'blur'
                }]
              };
            }
          } else {
            _this3.serviceFeeIdDisabled = false;
            _this3.orderInfo.recheckType = _this3.recheckTypeOptions[0].val;
          }

          _this3.loadOrderSkuList();

          _this3.$refs.orderProduct.$emit('loadFreeSn', _this3.orderInfo.sn);
        }
      });
    },
    loadSchemeList: function loadSchemeList() {
      var _this4 = this;

      var params = {
        orderId: this.id,
        step: '3'
      };
      (0, _order.getSchemeList)(params).then(function (response) {
        if (response.data) {
          _this4.schemeList = response.data;
        }
      });
    },
    loadOrderSkuList: function loadOrderSkuList() {
      var _this5 = this;

      var params = {
        orderId: this.id,
        step: '3'
      };
      (0, _order.getOrderSkuList)(params).then(function (response) {
        // console.log(JSON.stringify(response))
        if (response.data) {
          var indexNum = 0;
          _this5.skuList = response.data.map(function (v) {
            _this5.$set(v, 'indexNum', ++indexNum); // 动态加字段


            return v;
          });

          if (_this5.orderInfo.checkType === '01') {
            _this5.skuList = response.data.map(function (v) {
              (0, _select.getModeBySku)({
                code: v.skuCode,
                isRepair: true
              }).then(function (response) {
                if (response.data) {
                  _this5.$set(v, 'ddirStock', response.data.ddirStock);
                }
              });
              return v;
            });
          } else if (_this5.orderInfo.checkType === '02') {
            var skuCode = null;

            if (_this5.skuList != null && _this5.skuList.length > 0) {
              skuCode = _this5.skuList[0].skuCode;
            } else {
              skuCode = _this5.orderInfo.sku;
            }

            (0, _select.getModeBySku)({
              code: skuCode,
              takeCity: _this5.orderInfo.takeCity
            }).then(function (response) {
              if (response.data) {
                _this5.skuList[0].ddirStock = response.data.ddirStock;
              }
            });
          }
        }
      });
    },
    loadOrderSkuFeeList: function loadOrderSkuFeeList() {
      var _this6 = this;

      this.skuListLoading = true;
      var params = {
        orderId: this.id
      };
      (0, _order.getOrderSkuFeeList)(params).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this6.skuFeeList = response.data.map(function (v) {
            _this6.$set(v, 'indexNum', ++indexNum); // 动态加字段


            _this6.$set(v, 'edit', false); // 动态加字段


            return v;
          });
        }
      });
      this.skuListLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 验证方法：[换货]必须添加一个“成品”物料
    validateSkulList: function validateSkulList() {
      if (this.orderInfo.checkType === '02' && this.skuList.length === 0) {
        this.$message({
          showClose: true,
          message: '【换货服务】必须添加一个成品物料SKU',
          type: 'error'
        });
        return false;
      }

      if (this.orderInfo.recheckType === '20') {
        console.log(JSON.stringify(this.skuList));

        if (this.skuList[0].ddirStock <= 0 || this.skuList[0].ddirStock === '0') {
          this.$message({
            showClose: true,
            message: 'DDIR库存不足',
            type: 'warning'
          });
          return false;
        }
      } // if ((this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103' ||
      //   (this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203')) &&
      //   this.skuFeeList.length === 0) {
      //   this.$message({ showClose: true, message: '必须添加一个服务SKU', type: 'error' })
      //   return false
      // }
      // if (this.orderInfo.firstClassCategory !== null &&
      //   this.orderInfo.firstClassCategory.isEnable === '1' && this.skuFeeList.length !== 1) {
      //   this.$message({ showClose: true, message: 'PC品类必须添加一个服务SKU', type: 'error' })
      //   return false
      // }


      return true;
    },
    // 【检测完成】
    recheckConfirm: function recheckConfirm(recheckFlag) {
      var _this7 = this;

      if (recheckFlag) {
        this.orderInfo.recheckState = 1;
      } else {
        this.orderInfo.recheckState = 0;
      }

      if (this.dialogFormVisible) {
        this.$refs['recheckForm'].validate(function (valid) {
          if (valid) {
            recheckFlag = true;
          }
        });
      }

      if (!recheckFlag) {
        this.dialogFormVisible = !recheckFlag;
      } else {
        this.$refs['dataForm'].validate(function (valid) {
          if (valid && _this7.validateSkulList()) {
            _this7.$confirm('是否确认？', '提示', {
              cancelButtonText: '取消',
              confirmButtonText: '确认',
              type: 'warning',
              center: true
            }).then(function () {
              _this7.recheckOrderSumbit();
            }).catch(function () {});
          } else {
            _this7.$nextTick(function () {
              var isError = document.getElementsByClassName('is-error');

              if (isError && isError.length > 0) {
                isError[0].querySelector('input, textarea').focus();
              }
            });
          }
        });
      }
    },
    clearForm: function clearForm() {
      this.dialogFormVisible = false;
      this.$refs['recheckForm'].clearValidate('recheckDenyReason');
      this.orderInfo.recheckDenyReason = null;
    },
    recheckOrderSumbit: function recheckOrderSumbit() {
      var _this8 = this;

      this.btnLoading = true;
      this.orderInfo.orderSkuList = this.skuList;
      this.orderInfo.orderSkuFeeList = this.skuFeeList;
      (0, _order.recheckOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this8.$message({
            showClose: true,
            message: '复核完了',
            type: 'success'
          });

          _this8.$store.dispatch('tagsView/delView', _this8.$route);

          _this8.$router.push({
            path: '/order/recheck/list'
          });
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单物料SKU】
    addSkuClick: function addSkuClick() {
      var _this9 = this;

      // 检查是否已经添加
      if (this.skuList.find(function (v) {
        return v.skuCode === _this9.skuAdd.skuCode;
      })) {
        return;
      }

      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.feeType = this.skuAdd.feeType;
      newSkuItem.quantity = 1;
      var skuInfo = this.skuSelect.filter(function (ob) {
        return _this9.skuAdd.skuCode === ob.code;
      })[0];
      newSkuItem.skuId = skuInfo.id;
      newSkuItem.skuCode = skuInfo.code;
      newSkuItem.skuName = skuInfo.name;
      newSkuItem.skuType = skuInfo.type;
      newSkuItem.skuTag = skuInfo.skuTag;
      newSkuItem.priceExcludeTax = skuInfo.priceExcludeTax;
      newSkuItem.priceIncludeTax = skuInfo.priceIncludeTax;
      newSkuItem.stock = skuInfo.stock;
      newSkuItem.ddirStock = skuInfo.ddirStock;

      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103') {
        newSkuItem.brandId = this.orderInfo.brand;
        newSkuItem.categoryId = this.orderInfo.category;
        newSkuItem.categoryName = this.orderInfo.categoryName;
        newSkuItem.modelId = this.orderInfo.model;
        newSkuItem.modelName = this.orderInfo.modelName;
        this.skuList.push(newSkuItem);
        var indexNum = 0;
        this.skuList.map(function (v) {
          _this9.$set(v, 'indexNum', ++indexNum); // 动态加字段


          _this9.$set(v, 'edit', false); // 动态加字段


          return v;
        });
      } else if (this.orderInfo.checkType === '02') {
        var skuCode = null;

        if (this.skuAdd.skuCode) {
          skuCode = this.skuAdd.skuCode;
        } else if (this.orderInfo.orderSkuList != null && this.orderInfo.orderSkuList.length > 0) {
          skuCode = this.orderInfo.orderSkuList[this.orderInfo.orderSkuList.length - 1].skuCode;
        } else {
          skuCode = this.orderInfo.sku;
        }

        (0, _select.getModeBySku)({
          code: skuCode,
          takeCity: this.orderInfo.takeCity
        }).then(function (response) {
          if (response.data) {
            newSkuItem.brandId = response.data.brandId;
            newSkuItem.categoryId = response.data.categoryId;
            newSkuItem.categoryName = response.data.categoryName;
            newSkuItem.modelId = response.data.modelId;
            newSkuItem.modelName = response.data.modelName;
            newSkuItem.ddirStock = response.data.ddirStock;
            newSkuItem.stock = response.data.stock;
            newSkuItem.feeType = '01';

            _this9.skuList.push(newSkuItem);

            var _indexNum = 0;

            _this9.skuList.map(function (v) {
              _this9.$set(v, 'indexNum', ++_indexNum); // 动态加字段


              _this9.$set(v, 'edit', false); // 动态加字段


              return v;
            });
          }
        });
      }

      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单服务SKU】
    addFeeSkuClick: function addFeeSkuClick() {
      var _this10 = this;

      // 检查是否已经添加
      if (this.skuFeeList.find(function (v) {
        return v.serviceFeeId === _this10.skuFeeAdd.serviceFeeId;
      })) {
        return;
      }

      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.chargeType = this.skuFeeAdd.feeType;
      var skuFeeInfo = this.userfeeSelect.filter(function (ob) {
        return _this10.skuFeeAdd.serviceFeeId === ob.id;
      })[0];
      newSkuItem.serviceFeeId = skuFeeInfo.id;
      newSkuItem.skuFeeId = skuFeeInfo.id;
      newSkuItem.code = skuFeeInfo.code;
      newSkuItem.name = skuFeeInfo.name;

      if (this.orderInfo.checkSubType === '0102' || this.orderInfo.checkSubType === '0103' || this.orderInfo.checkType === '02' && this.orderInfo.checkSubType === '0203') {
        this.skuFeeList.push(newSkuItem);
        var indexNum = 0;
        this.skuFeeList.map(function (v) {
          _this10.$set(v, 'indexNum', ++indexNum); // 动态加字段


          _this10.$set(v, 'edit', false); // 动态加字段


          return v;
        });
      }

      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单物料SKU】
    removeSkuClick: function removeSkuClick(row) {
      var _this11 = this;

      this.btnLoading = true;
      this.skuList.splice(this.skuList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this11.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this11.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this12 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };
      params.tag = '1';
      (0, _select.getSkuSelectTrue)(params).then(function (res) {
        _this12.skuSelect = res.data;
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单服务SKU】
    removeSkuFeeClick: function removeSkuFeeClick(row) {
      var _this13 = this;

      this.btnLoading = true;
      this.skuFeeList.splice(this.skuFeeList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuFeeList.map(function (v) {
        _this13.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this13.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 快捷TAG查询
    stepClick: function stepClick(tab, event) {
      this.step = tab.name;

      if (tab.name === '1') {
        this.$refs.receiptOrder.$emit('loadExpressGoods', this.id);
      } // if (tab.name === '4') {
      //   this.$refs.handleOrder.$emit('loadhandleInfo', this.orderInfo.id)
      // } else if (tab.name === '3') {
      //   this.$refs.checkOrder.$emit('loadcheckInfo', { id: this.orderInfo.id })
      // }

    },
    // 查询费用明细
    queryPriceItem: function queryPriceItem() {
      var _this14 = this;

      this.orderInfo.orderSkuList = this.skuList;
      this.orderInfo.orderSkuFeeList = this.skuFeeList;
      (0, _order.saveOrderSKUs)(this.orderInfo).then(function (response) {
        if (response.data) {
          console.log(response);

          _this14.$message({
            showClose: true,
            message: '查询成功',
            type: 'success'
          });

          _this14.orderPriceShow = true;

          _this14.$refs.checkPrice.$emit('loadCheckInfo', _this14.orderInfo.id);
        } else {
          _this14.$refs.checkPrice.$emit('loadCheckInfo', _this14.orderInfo.id);
        }
      });
    }
  }
};
exports.default = _default;