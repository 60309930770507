var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("故障原因" + _vm._s(!_vm.isCreate ? "详细" : "创建")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: [
                    "ROLE_BASE_PRD_FAULT_ADD",
                    "ROLE_BASE_PRD_FAULT_MODIFY",
                  ],
                  expression:
                    "['ROLE_BASE_PRD_FAULT_ADD', 'ROLE_BASE_PRD_FAULT_MODIFY']",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.isCreate ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "fault_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.validateForm,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "品类", prop: "categoryId" },
                          },
                          [
                            _c("tree-select", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                value: _vm.validateForm.categoryId,
                                options: _vm.tableData1,
                                accordion: _vm.accordion,
                                clearable: _vm.cTree,
                                placeholder: "请选择品类",
                                disabled: _vm.isList,
                              },
                              on: {
                                getValue: function ($event) {
                                  return _vm.queryTreeSelectNodeKey($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "故障原因编码", prop: "code" },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isCreate ? false : true },
                              model: {
                                value: _vm.validateForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "code", $$v)
                                },
                                expression: "validateForm.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "故障原因分类", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: _vm.validateForm.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.validateForm, "type", $$v)
                                  },
                                  expression: "validateForm.type",
                                },
                              },
                              _vm._l(
                                _vm.repairTypeSelect,
                                function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                        disabled: item.isEnable !== "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name) + " ")]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "状态", prop: "isEnable" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "medium" },
                                model: {
                                  value: _vm.validateForm.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.validateForm, "isEnable", $$v)
                                  },
                                  expression: "validateForm.isEnable",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "1" } },
                                  [_vm._v("启用")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "0" } },
                                  [_vm._v("禁用")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "故障原因名称", prop: "name" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.validateForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "name", $$v)
                                },
                                expression: "validateForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "备注", prop: "memo" },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 3 },
                              model: {
                                value: _vm.validateForm.memo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.validateForm, "memo", $$v)
                                },
                                expression: "validateForm.memo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "关联维修代码", prop: "models" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: [
                                      "ROLE_BASE_PRD_FAULT_ADD",
                                      "ROLE_BASE_PRD_FAULT_MODIFY",
                                    ],
                                    expression:
                                      "['ROLE_BASE_PRD_FAULT_ADD', 'ROLE_BASE_PRD_FAULT_MODIFY']",
                                  },
                                ],
                                attrs: { size: "medium", type: "primary" },
                                on: { click: _vm.addModel },
                              },
                              [_vm._v("添加")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: [
                                      "ROLE_BASE_PRD_FAULT_ADD",
                                      "ROLE_BASE_PRD_FAULT_MODIFY",
                                    ],
                                    expression:
                                      "['ROLE_BASE_PRD_FAULT_ADD', 'ROLE_BASE_PRD_FAULT_MODIFY']",
                                  },
                                ],
                                attrs: {
                                  size: "medium",
                                  type: "primary",
                                  disabled: _vm.removeBtnDisabled,
                                },
                                on: { click: _vm.deleteModel },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.modelListValidate,
                                    expression: "modelListValidate",
                                  },
                                ],
                                staticClass: "modelList__error",
                              },
                              [_vm._v(" 请添加关联维修代码 ！")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "160px" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                                height: "40px",
                              },
                              data: _vm.list,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.rowClickSelection,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                                prop: "id",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "repairCode",
                                label: "维修代码编码",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "repairName",
                                label: "维修代码名称",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "categoryName",
                                label: "品类",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建人", prop: "inRmkName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "inRmkName",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.inRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "inDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.inDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改人",
                                  prop: "reRmkName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.reRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "reRmkName",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.reRmkName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _vm.validateForm.id != null
                          ? _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: "最后修改时间",
                                  prop: "reDateFM",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.validateForm.reDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateForm,
                                        "reDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "validateForm.reDateFM",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加维修代码", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c("el-row", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modelSelConditionValidate,
                        expression: "modelSelConditionValidate",
                      },
                    ],
                    staticClass: "modelList__error",
                  },
                  [_vm._v("查询条件：品类、品牌、维修代码，请至少选择其中一项")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    { ref: "dataFormDialog", attrs: { model: _vm.modelInfo } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "categoryId" } },
                            [
                              _c("tree-select", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: _vm.modelInfo.categoryId,
                                  options: _vm.tableData12,
                                  accordion: _vm.accordion2,
                                  clearable: _vm.cTree2,
                                  placeholder: "请选择品类",
                                },
                                on: {
                                  getValue: function ($event) {
                                    return _vm.queryTreeSelectNodeKey2($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "code" } },
                            [
                              _c(
                                "el-col",
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入维修代码" },
                                    model: {
                                      value: _vm.modelInfo.code,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelInfo, "code", $$v)
                                      },
                                      expression: "modelInfo.code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7, offset: 1 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name" } },
                            [
                              _c(
                                "el-col",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入维修代码名称",
                                    },
                                    model: {
                                      value: _vm.modelInfo.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelInfo, "name", $$v)
                                      },
                                      expression: "modelInfo.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.searchBtnClickDialog },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "info" },
                            on: { click: _vm.cleanBtnClickDialog },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: false,
                  expression: "false",
                },
              ],
              ref: "dialogTable",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                border: "",
                stripe: "",
                "tooltip-effect": "dark",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                  height: "50px",
                },
                data: _vm.dialogList,
              },
              on: {
                "selection-change": _vm.dialogSelectionChange,
                "row-click": _vm.dialogRowClickSelection,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  align: "center",
                  prop: "id",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "code", label: "维修代码编码" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "维修代码名称",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: "品类",
                  resizable: "",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveModelInfo },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }