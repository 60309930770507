"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approve = approve;
exports.commit = commit;
exports.createDate = createDate;
exports.deleteItems = deleteItems;
exports.findPageList = findPageList;
exports.getItemList = getItemList;
exports.queryByNum = queryByNum;
exports.updateTaskNum = updateTaskNum;
exports.uploadSnItem = uploadSnItem;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/batch_extend/list',
    method: 'post',
    params: query
  });
}
/**
 * 新建批量延保审批
 * @param data
 */


function createDate() {
  return (0, _request.default)({
    url: '/product_app/batch_extend/save',
    method: 'post'
  });
}
/** 查询批量审批详情*/


function queryByNum(num) {
  return (0, _request.default)({
    url: '/product_app/batch_extend/queryByNum',
    method: 'post',
    params: {
      num: num
    }
  });
}
/** 查询批量审批SN详情*/


function getItemList(query) {
  return (0, _request.default)({
    url: '/product_app/batch_extend_item/getList',
    method: 'post',
    params: query
  });
}
/** 提交批量审批*/


function commit(num) {
  return (0, _request.default)({
    url: '/product_app/batch_extend/commit',
    method: 'post',
    params: {
      num: num
    }
  });
}
/** 审批*/


function approve(num, type) {
  return (0, _request.default)({
    url: '/product_app/batch_extend/approve',
    method: 'post',
    params: {
      num: num,
      type: type
    }
  });
}
/** 更新任务号*/


function updateTaskNum(num, taskNum) {
  return (0, _request.default)({
    url: '/product_app/batch_extend/updateTaskNum',
    method: 'post',
    params: {
      num: num,
      taskNum: taskNum
    }
  });
}
/** 上传延保SN明细*/


function uploadSnItem(num, fileName) {
  return (0, _request.default)({
    url: '/product_app/batch_extend_item/uploadSnItem',
    method: 'post',
    params: {
      num: num,
      fileName: fileName
    }
  });
}
/** 删除延保SN明细*/


function deleteItems(data) {
  return (0, _request.default)({
    url: '/product_app/batch_extend_item/deleteItems',
    method: 'post',
    data: data
  });
}