"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 基础数据块
 *
 * @type {*[]}
 */
var basicsRouter = {
  path: '/basics/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'sku/:id',
    name: 'basicsSkuCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/sku/detail'));
      });
    },
    meta: {
      title: '物料编码创建',
      hidden: true,
      activeMenu: '/basics/sku/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'sku/detail/:id',
    name: 'basicsSkuDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/sku/detail'));
      });
    },
    meta: {
      title: '物料编码详细',
      hidden: true,
      activeMenu: '/basics/sku/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'repair/:id',
    name: 'basicsRepairCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/repair/detail'));
      });
    },
    meta: {
      title: '维修代码创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/repair/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'repair/detail/:id',
    name: 'basicsRepairDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/repair/detail'));
      });
    },
    meta: {
      title: '维修代码详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/repair/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'fault/:id',
    name: 'basicsFaultCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/fault/detail'));
      });
    },
    meta: {
      title: '故障原因创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/fault/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'fault/detail/:id',
    name: 'basicsFaultDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/fault/detail'));
      });
    },
    meta: {
      title: '故障原因详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/fault/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'warehouse/:id',
    name: 'warehouse_create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/warehouse/detail'));
      });
    },
    meta: {
      title: '仓库创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/warehouse/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'warehouse/detail/:id',
    name: 'warehouse_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/warehouse/detail'));
      });
    },
    meta: {
      title: '仓库详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/warehouse/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'dispatch/:id',
    name: 'dispatch_create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/dispatch/detail'));
      });
    },
    meta: {
      title: '派单配置创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/dispatch/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'dispatch/detail/:id',
    name: 'dispatch_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/dispatch/detail'));
      });
    },
    meta: {
      title: '派单配置详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/dispatch/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'skuFee/:id',
    name: 'basicsSkuFeeCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/skuFee/detail'));
      });
    },
    meta: {
      title: '服务SKU创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/skuFee/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'skuFee/detail/:id',
    name: 'basicsSkuFeeDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/skuFee/detail'));
      });
    },
    meta: {
      title: '服务SKU详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/skuFee/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'cityTime/:id',
    name: 'city_time_create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/cityTime/detail'));
      });
    },
    meta: {
      title: '重要城市最晚取件时间创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/cityTime/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'cityTime/detail/:id',
    name: 'city_time_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/cityTime/detail'));
      });
    },
    meta: {
      title: '重要城市最晚取件时间详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/cityTime/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'sku/detail/:id',
    name: 'serviceConfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/sku/detail'));
      });
    },
    meta: {
      title: '客服任务配置',
      hidden: true,
      activeMenu: '/basics/sku/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'task/:id',
    name: 'taskTypeCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/taskType/detail'));
      });
    },
    meta: {
      title: '任务类型创建',
      hidden: true,
      activeMenu: '/basics/taskType/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'task/detail/:id',
    name: 'taskTypeDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/taskType/detail'));
      });
    },
    meta: {
      title: '任务类型详细',
      hidden: true,
      activeMenu: '/basics/taskType/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'basics/overseasSku/:id',
    name: 'overseasSkuDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/overseasSku/detail'));
      });
    },
    meta: {
      title: '海外物料编码创建',
      hidden: true,
      activeMenu: '/basics/overseasSku/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'create/:id',
    name: 'createLabel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/label/detail'));
      });
    },
    meta: {
      title: '标签创建',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/label/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'riskDetail/:id',
    name: 'createRisk',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/risk/detail'));
      });
    },
    meta: {
      title: '风险详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/risk/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'basics/overseasSku/:id',
    name: 'overseasSkuDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/overseasSku/detail'));
      });
    },
    meta: {
      title: '海外物料编码创建',
      hidden: true,
      activeMenu: '/basics/overseasSku/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'dispatchConfig/detail',
    name: 'stockForewarnConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basics/dispatchConfig/detail'));
      });
    },
    meta: {
      title: '库存预警详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/basics/dispatchConfig/list',
      parent: {
        path: '/basics',
        redirect: 'noRedirect',
        meta: {
          title: '基础数据',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = basicsRouter;
exports.default = _default;