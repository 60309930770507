var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.orderInfo.orderNo)),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm._f("stateFilter")(_vm.orderInfo.state))),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  _vm._s(_vm._f("subStateFilter")(_vm.orderInfo.subState))
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("new-task", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["ROLE_WORK_TASK_MY_LIST"],
                expression: "['ROLE_WORK_TASK_MY_LIST']",
              },
            ],
            attrs: {
              "work-id": _vm.orderInfo.id,
              "work-no": _vm.orderInfo.orderNo,
              "work-type": "2",
              "task-link": "50",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_VERIFY_COMMIT"],
                  expression: "['ROLE_ORDER_VERIFY_COMMIT']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.verifyConfirm()
                },
              },
            },
            [_vm._v("终验完成")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ORDER_VERIFY_LIST"],
                  expression: "['ROLE_ORDER_VERIFY_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "verify_list" })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.consumerShow,
                                  callback: function ($$v) {
                                    _vm.consumerShow = $$v
                                  },
                                  expression: "consumerShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-consumer", {
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "终验信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("终验信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "终验结果",
                                                      prop: "verifyResult",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .verifyResult,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "verifyResult",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.verifyResult",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.verifyResultOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "终验备注",
                                                      prop: "verifyRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                        maxlength: "1000",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .verifyRemark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "verifyRemark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.verifyRemark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("交付性检测")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _vm._l(
                                      _vm.checkItemList4show,
                                      function (items, index1) {
                                        return _c(
                                          "el-row",
                                          { key: index1 },
                                          _vm._l(
                                            items,
                                            function (item, index2) {
                                              return _c(
                                                "el-col",
                                                {
                                                  key: index2,
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px",
                                                        "padding-right":
                                                          "100px",
                                                        "padding-bottom":
                                                          "10px",
                                                        "padding-left": "100px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            content:
                                                              item.itemName,
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  item.itemName,
                                                                size: "medium",
                                                                border: "",
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.choose,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "choose",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.choose",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "showOverflowTooltipFilter"
                                                                  )(
                                                                    item.itemName
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "padding-top": "30px" },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "前端展示",
                                                      prop: "handleShow",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .handleShow,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "handleShow",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.handleShow",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse",
                                  {
                                    staticClass: "no-collapse-header",
                                    model: {
                                      value: _vm.calloutShow,
                                      callback: function ($$v) {
                                        _vm.calloutShow = $$v
                                      },
                                      expression: "calloutShow",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-collapse-item",
                                      { attrs: { name: "1" } },
                                      [
                                        _c("order-callout", {
                                          ref: "calloutOrder",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("终验附件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c("upload-file", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isReadOnly,
                                                            "attachment-list":
                                                              _vm.orderInfo
                                                                .attachmentList5,
                                                          },
                                                          on: {
                                                            setUploadFileList:
                                                              _vm.setUploadFileList,
                                                            setBtnsState:
                                                              _vm.setBtnsState,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.step,
                                  callback: function ($$v) {
                                    _vm.step = $$v
                                  },
                                  expression: "step",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "4" } },
                                  [
                                    _c("order-handle", {
                                      ref: "handleOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.orderInfo.handleSubType === "0102"
                                  ? _c(
                                      "el-collapse-item",
                                      { attrs: { name: "7" } },
                                      [
                                        _c("order-recheck", {
                                          ref: "recheckOrder",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "3" } },
                                  [
                                    _c("order-check", {
                                      ref: "checkOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "2" } },
                                  [
                                    _c("order-dispatch", {
                                      ref: "dispatchOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 120 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      attrs: { "tab-position": "right" },
                                      on: { "tab-click": _vm.stepClick },
                                      model: {
                                        value: _vm.step,
                                        callback: function ($$v) {
                                          _vm.step = $$v
                                        },
                                        expression: "step",
                                      },
                                    },
                                    [
                                      _c("el-tab-pane", {
                                        attrs: { label: "返机", name: "6" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "终验", name: "5" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "处理", name: "4" },
                                      }),
                                      _vm._v(" "),
                                      _vm.orderInfo.handleSubType === "0102"
                                        ? _c("el-tab-pane", {
                                            attrs: { label: "复核", name: "7" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "检测", name: "3" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "分派", name: "2" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-tab-pane", {
                                        attrs: { label: "收货", name: "1" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-tabs el-tabs--right",
                                      staticStyle: { "padding-top": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "el-tabs__header is-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "客户",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.consumerShow === "0"
                                                      ? (_vm.consumerShow = "1")
                                                      : (_vm.consumerShow = "0")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "产品",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productShow === "0"
                                                      ? (_vm.productShow = "1")
                                                      : (_vm.productShow = "0")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-tabs__item is-right",
                                              staticStyle: {
                                                "line-height": "30px",
                                                height: "30px",
                                              },
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: { label: "外呼" },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.calloutShow === "1"
                                                      ? (_vm.calloutShow = "0")
                                                      : (_vm.calloutShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }