var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("风险详情"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_RISK_UPDATE"],
                  expression: "['ROLE_BASE_RISK_UPDATE']",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.dialogCreate },
            },
            [_vm._v("添加标签")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_RISK_UPDATE"],
                  expression: "['ROLE_BASE_RISK_UPDATE']",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.saveSumbit },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.returnBtn } }, [_vm._v("返回")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-divider",
            { staticClass: "myStyle", attrs: { "content-position": "left" } },
            [_vm._v("标签设置")]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "border-top": "none" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.riskInfo,
                    rules: _vm.rules,
                    "label-width": "150px",
                    size: "50%",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  label: _vm._f("typeFilter")(
                                    _vm.riskInfo.type
                                  ),
                                  prop: "isEnable",
                                },
                              },
                              _vm._l(
                                _vm.riskInfo.labelNameList,
                                function (tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag.name,
                                      style: _vm._f("levelFilters")(tag.level),
                                      attrs: { closable: "" },
                                      on: {
                                        close: function ($event) {
                                          return _vm.closeRisk(tag.name)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(tag.name) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-divider",
            { staticClass: "myStyle", attrs: { "content-position": "left" } },
            [_vm._v("标签履历")]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "border-top": "none" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    stripe: "",
                    "header-cell-style": {
                      background: "#eef1f6",
                      color: "#606266",
                      height: "50px",
                    },
                    data: _vm.riskHis,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "reRmk",
                      label: "操作人",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reDateFM",
                      label: "操作时间",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "risk",
                      label: "风险对象",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "labelName",
                      label: "标签名称",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "drawer",
          attrs: {
            size: "30%",
            visible: _vm.dialog,
            direction: "rtl",
            "custom-class": "demo-drawer",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { value: "first" }, on: { "tab-click": _vm.handleClick } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "低风险", name: "first" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { max: 5 },
                      model: {
                        value: _vm.labelNameList,
                        callback: function ($$v) {
                          _vm.labelNameList = $$v
                        },
                        expression: "labelNameList",
                      },
                    },
                    _vm._l(_vm.labelList10, function (item, index) {
                      return _c(
                        "el-checkbox-button",
                        { key: index, attrs: { label: item.name } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "中风险", name: "second" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { max: 5 },
                      model: {
                        value: _vm.labelNameList,
                        callback: function ($$v) {
                          _vm.labelNameList = $$v
                        },
                        expression: "labelNameList",
                      },
                    },
                    _vm._l(_vm.labelList20, function (item, index) {
                      return _c(
                        "el-checkbox-button",
                        { key: index, attrs: { label: item.name } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "高风险", name: "third" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { max: 5 },
                      model: {
                        value: _vm.labelNameList,
                        callback: function ($$v) {
                          _vm.labelNameList = $$v
                        },
                        expression: "labelNameList",
                      },
                    },
                    _vm._l(_vm.labelList30, function (item, index) {
                      return _c(
                        "el-checkbox-button",
                        { key: index, attrs: { label: item.name } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.dialogClose },
                },
                [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 认"))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }