import { render, staticRenderFns } from "./saleDetail.vue?vue&type=template&id=68034aa0&"
import script from "./saleDetail.vue?vue&type=script&lang=js&"
export * from "./saleDetail.vue?vue&type=script&lang=js&"
import style0 from "./saleDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('68034aa0')) {
      api.createRecord('68034aa0', component.options)
    } else {
      api.reload('68034aa0', component.options)
    }
    module.hot.accept("./saleDetail.vue?vue&type=template&id=68034aa0&", function () {
      api.rerender('68034aa0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/reduction/approve/saleDetail.vue"
export default component.exports