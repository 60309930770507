var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                border: "",
                stripe: "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                  height: "50px",
                },
                data: _vm.list,
              },
              on: {
                "sort-change": _vm.sortChange,
                "cell-dblclick": _vm.gotoEditHtml,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: "服务单号",
                  align: "center",
                  "min-width": "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function ($event) {
                                return _vm.gotoEditHtml(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.orderNo))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskNo",
                  label: "任务单号",
                  align: "center",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function ($event) {
                                return _vm.gotoCallHtml(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.taskNo))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskTypeName",
                  label: "任务类型",
                  align: "center",
                  "min-width": "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "inDate",
                  label: "创建日期",
                  formatter: _vm.dateFormat,
                  "min-width": "140PX;",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "planStarDate",
                  label: "计划开始日期",
                  formatter: _vm.dateFormat,
                  align: "center",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskDurationTime",
                  label: "任务创建时长(天)",
                  formatter: _vm.computeDays,
                  resizable: "",
                  "show-overflow-tooltip": "",
                  align: "center",
                  "min-width": "140px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskGroupIdName",
                  label: "跟进小组",
                  align: "center",
                  "min-width": "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskRmkName",
                  label: "跟进人",
                  align: "center",
                  "min-width": "120px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskTempRmkName",
                  label: "临时跟进人",
                  align: "center",
                  "min-width": "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priorityName",
                  label: "优先级",
                  sortable: "custom",
                  align: "center",
                  "min-width": "180px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isProhibitName",
                  label: "是否挂起",
                  resizable: "",
                  "show-overflow-tooltip": "",
                  align: "center",
                  "min-width": "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "typeName",
                  label: "状态",
                  align: "center",
                  "min-width": "100px",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }