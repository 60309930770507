"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createData = createData;
exports.deleteStockOut = deleteStockOut;
exports.fetchList = fetchList;
exports.queryById = queryById;
exports.submitSave = submitSave;
exports.updateStockOut = updateStockOut;
exports.validatePlanDate = validatePlanDate;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询批量出库计划分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新增
 * @param query
 */


function createData() {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/create',
    method: 'post'
  });
}
/**
 * 修改计划状态
 * @param query
 */


function updateStockOut(ids) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/update',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 删除
 * @param query
 */


function deleteStockOut(ids) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/delete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 校验计划截止日期
 * @param query
 */


function validatePlanDate(planDate) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/validatePlanDate',
    method: 'post',
    params: {
      planDate: planDate
    }
  });
}
/**
 * 确认提交
 * @param query
 */


function submitSave(data) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/submitSave',
    method: 'post',
    data: data
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/stock_out_plan/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}