var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.orderInfo.orderNo)),
              ]),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(
                  _vm._s(_vm._f("callSignFilter")(_vm.orderInfo.callSign))
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_CALLOUT_SERVICE_COMMIT"],
                  expression: "['ROLE_CALLOUT_SERVICE_COMMIT']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.callConfirm()
                },
              },
            },
            [_vm._v("确认")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_CALLOUT_SERVICE_LIST"],
                  expression: "['ROLE_CALLOUT_SERVICE_LIST']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.$store.dispatch("tagsView/delView", _vm.$route)
                  _vm.$router.push({ name: "service_list" })
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "基本信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("基本信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务单号",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.orderNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "orderNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.orderNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "服务子类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .subType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "subType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.subType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessSubTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "外呼类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .callSign,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "callSign",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.callSign",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.callSignOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "创建人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createUserName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createUserName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createUserName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "创建时间",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .createTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "createTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.createTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "客户信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("客户信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "机主" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .ownerName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "ownerName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.ownerName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "机主电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .ownerTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "ownerTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.ownerTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "1",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "报修人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .claimName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "claimName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.claimName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "报修人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .claimTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "claimTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.claimTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "2",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "寄件人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .sendName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "sendName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.sendName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "寄件人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.sendTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "sendTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.sendTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "3",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "收件人" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .takeName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "takeName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.takeName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "收件人电话",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.takeTel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "takeTel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.takeTel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, push: 2 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label: "4",
                                                      border: "",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderInfo.linkType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderInfo,
                                                          "linkType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderInfo.linkType",
                                                    },
                                                  },
                                                  [_vm._v("默认联系人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "外呼信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("外呼信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _vm.orderInfo.callSign !== "5"
                                      ? _c(
                                          "div",
                                          [
                                            _vm.cleanStatus
                                              ? _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    "清洁类型",
                                                                  prop: "checkReason",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    value:
                                                                      _vm
                                                                        .orderInfo
                                                                        .cleanType ===
                                                                      "1"
                                                                        ? "清洁服务"
                                                                        : "清洁维修",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-row",
                                              [
                                                _vm.orderInfo.callSign !== "6"
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    _vm.label1,
                                                                  prop: "calloutResult",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      filterable:
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      placeholder:
                                                                        "请选择",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .orderInfo
                                                                          .calloutResult,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.orderInfo,
                                                                            "calloutResult",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "orderInfo.calloutResult",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.callResultOptions,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.name,
                                                                              value:
                                                                                item.val,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.orderInfo.callSign ===
                                                  "8" &&
                                                _vm.cleanIsPay &&
                                                _vm.cleanStatus
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    "维修单外呼结果",
                                                                  prop: "calloutResult2",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      filterable:
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      placeholder:
                                                                        "请选择",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.changeCall,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .orderInfo
                                                                          .calloutResult2,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.orderInfo,
                                                                            "calloutResult2",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "orderInfo.calloutResult2",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.callResultOptions2,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.name,
                                                                              value:
                                                                                item.val,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.cleanStatus &&
                                                _vm.orderInfo.cleanOrderNo &&
                                                _vm.orderInfo.cleanType === "1"
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    "清洁维修单号",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "link-type",
                                                                    attrs: {
                                                                      id: "orderNo",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotoEditHtml(
                                                                            _vm
                                                                              .orderInfo
                                                                              .cleanOrderNo
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .orderInfo
                                                                            .cleanOrderNo
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.orderInfo.calloutResult ===
                                                "5"
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 8,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    "用户收费",
                                                                  prop: "serviceFeeId",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      filterable:
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      placeholder:
                                                                        "请选择",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .orderInfo
                                                                          .serviceFeeId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.orderInfo,
                                                                            "serviceFeeId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "orderInfo.serviceFeeId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.userfeeSelect,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.name +
                                                                                "（¥" +
                                                                                item.priceIncludeTaxYuan +
                                                                                "）",
                                                                              value:
                                                                                item.id,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  float:
                                                                                    "left",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  float:
                                                                                    "right",
                                                                                  color:
                                                                                    "#8492a6",
                                                                                  "font-size":
                                                                                    "13px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "¥" +
                                                                                  _vm._s(
                                                                                    item.priceIncludeTaxYuan
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.cleanStatus &&
                                            _vm.orderInfo.callSign === "8"
                                              ? _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          span: 24,
                                                          pull: 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid-content bg-purple",
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    "关联单号",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .orderInfo
                                                                        .cleanLinkNo,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.orderInfo,
                                                                          "cleanLinkNo",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "orderInfo.cleanLinkNo",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.orderInfo.calloutResult === "5"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 8,
                                                              pull: 1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "grid-content bg-purple",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    attrs: {
                                                                      label:
                                                                        "品类",
                                                                      prop: "category",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "tree-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .skuAdd
                                                                              .category,
                                                                          options:
                                                                            _vm.categorySelectTree,
                                                                          accordion: false,
                                                                          clearable: true,
                                                                          placeholder:
                                                                            "请选择",
                                                                        },
                                                                        on: {
                                                                          getValue:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getTreeSelectNodeKey(
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 8,
                                                              pull: 1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "grid-content bg-purple",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    attrs: {
                                                                      label:
                                                                        "型号",
                                                                      prop: "model",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          filterable:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          placeholder:
                                                                            "请选择",
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            _vm.loadModelSelect,
                                                                          change:
                                                                            _vm.modelSelectChange,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .skuAdd
                                                                              .model,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.skuAdd,
                                                                                "model",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "skuAdd.model",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.modelSelect,
                                                                        function (
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: index,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.name,
                                                                                  value:
                                                                                    item.id,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      float:
                                                                                        "left",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.name
                                                                                    ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 16,
                                                              pull: 1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "grid-content bg-purple",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    attrs: {
                                                                      label:
                                                                        "物料",
                                                                      prop: "skuId",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          filterable:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          placeholder:
                                                                            "请选择添加的物料",
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            _vm.loadSkuSelect,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .skuAdd
                                                                              .skuCode,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.skuAdd,
                                                                                "skuCode",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "skuAdd.skuCode",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.skuSelect,
                                                                        function (
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: index,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.code +
                                                                                    "  " +
                                                                                    item.name,
                                                                                  value:
                                                                                    item.code,
                                                                                  disabled:
                                                                                    item.isEnable ===
                                                                                    "0",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      float:
                                                                                        "left",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.code
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      float:
                                                                                        "right",
                                                                                      color:
                                                                                        "#8492a6",
                                                                                      "font-size":
                                                                                        "13px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 5,
                                                              push: 1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "grid-content bg-purple",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "loading",
                                                                          rawName:
                                                                            "v-loading",
                                                                          value:
                                                                            _vm.btnLoading,
                                                                          expression:
                                                                            "btnLoading",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.skuAddBtnDisabled,
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.addSkuClick,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "添加"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 24,
                                                              pull: 1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "160px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-table",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "loading",
                                                                          rawName:
                                                                            "v-loading",
                                                                          value:
                                                                            _vm.skuListLoading,
                                                                          expression:
                                                                            "skuListLoading",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      size: "mini",
                                                                      border:
                                                                        "",
                                                                      stripe:
                                                                        "",
                                                                      "tooltip-effect":
                                                                        "dark",
                                                                      "header-cell-style":
                                                                        {
                                                                          background:
                                                                            "#eef1f6",
                                                                          color:
                                                                            "#606266",
                                                                          height:
                                                                            "40px",
                                                                        },
                                                                      data: _vm.skuList,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "indexNum",
                                                                          label:
                                                                            "序号",
                                                                          width:
                                                                            "55",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "skuCode",
                                                                          label:
                                                                            "物料编码",
                                                                          resizable:
                                                                            "",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "skuName",
                                                                          label:
                                                                            "物料名称",
                                                                          resizable:
                                                                            "",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "skuType",
                                                                          label:
                                                                            "物料分类",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var row =
                                                                                    ref.row
                                                                                  return [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "skuTypeFilter"
                                                                                        )(
                                                                                          row.skuType
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1900196742
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "skuTag",
                                                                          label:
                                                                            "物料标识",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var row =
                                                                                    ref.row
                                                                                  return [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "skuTagFilter"
                                                                                        )(
                                                                                          row.skuTag
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1516746950
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "categoryName",
                                                                          label:
                                                                            "品类",
                                                                          resizable:
                                                                            "",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "modelName",
                                                                          label:
                                                                            "型号",
                                                                          resizable:
                                                                            "",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "quantity",
                                                                          label:
                                                                            "数量",
                                                                          width:
                                                                            "160",
                                                                          resizable:
                                                                            "",
                                                                          align:
                                                                            "center",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var row =
                                                                                    ref.row
                                                                                  return [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        row.quantity
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2861028836
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "编辑",
                                                                          align:
                                                                            "center",
                                                                          width:
                                                                            "160",
                                                                          "class-name":
                                                                            "small-padding fixed-width",
                                                                          fixed:
                                                                            "right",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var row =
                                                                                    ref.row
                                                                                  return [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            plain:
                                                                                              "",
                                                                                            type: "primary",
                                                                                            size: "mini",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.removeSkuClick(
                                                                                                row
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "删除"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1852571551
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 24,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "外呼沟通",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                type: "textarea",
                                                                autosize: {
                                                                  minRows: 2,
                                                                },
                                                                disabled: "",
                                                                maxlength:
                                                                  "1000",
                                                                "show-word-limit":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .callCate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "callCate",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.callCate",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 24,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label: "外呼备注",
                                                              prop: "calloutRemark",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              attrs: {
                                                                type: "textarea",
                                                                autosize: {
                                                                  minRows: 2,
                                                                },
                                                                maxlength:
                                                                  "1000",
                                                                "show-word-limit":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .calloutRemark,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "calloutRemark",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.calloutRemark",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      span: 16,
                                                      pull: 1,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid-content bg-purple",
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              label:
                                                                "到账流水号",
                                                              prop: "calloutPaymentNum",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticClass:
                                                                "filter-item",
                                                              model: {
                                                                value:
                                                                  _vm.orderInfo
                                                                    .calloutPaymentNum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderInfo,
                                                                      "calloutPaymentNum",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderInfo.calloutPaymentNum",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.productShow,
                                  callback: function ($$v) {
                                    _vm.productShow = $$v
                                  },
                                  expression: "productShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-product", {
                                      ref: "orderProduct",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.receiptShow,
                                  callback: function ($$v) {
                                    _vm.receiptShow = $$v
                                  },
                                  expression: "receiptShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-receipt", {
                                      ref: "receiptOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.checkShow,
                                  callback: function ($$v) {
                                    _vm.checkShow = $$v
                                  },
                                  expression: "checkShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("order-check", {
                                      ref: "checkOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.priceShow,
                                  callback: function ($$v) {
                                    _vm.priceShow = $$v
                                  },
                                  expression: "priceShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    !_vm.cleanIsPay
                                      ? _c("order-price", {
                                          ref: "checkPrice",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo,
                                            "price-state": true,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.cleanIsPay
                                      ? _c("order-price", {
                                          ref: "checkPrice",
                                          attrs: {
                                            disabled: true,
                                            "order-info": _vm.orderInfo2,
                                            "price-state": true,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "no-collapse-header",
                                model: {
                                  value: _vm.approvalShow,
                                  callback: function ($$v) {
                                    _vm.approvalShow = $$v
                                  },
                                  expression: "approvalShow",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  { attrs: { name: "1" } },
                                  [
                                    _c("approve-lists", {
                                      ref: "approveOrder",
                                      attrs: {
                                        disabled: true,
                                        "order-info": _vm.orderInfo,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 2, pull: 1 } },
                        [
                          _c(
                            "sticky",
                            { attrs: { "z-index": 10, "sticky-top": 120 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "70px",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "产品" },
                                            on: {
                                              change: function ($event) {
                                                _vm.productShow === "1"
                                                  ? (_vm.productShow = "0")
                                                  : (_vm.productShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "收货" },
                                            on: {
                                              change: function ($event) {
                                                _vm.receiptShow === "1"
                                                  ? (_vm.receiptShow = "0")
                                                  : (_vm.receiptShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { label: "检测" },
                                            on: {
                                              change: function ($event) {
                                                _vm.checkShow === "1"
                                                  ? (_vm.checkShow = "0")
                                                  : (_vm.checkShow = "1")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _vm.orderInfo.checkSubType ===
                                            "0102" ||
                                          _vm.orderInfo.checkSubType ===
                                            "0103" ||
                                          (_vm.orderInfo.checkType === "02" &&
                                            _vm.orderInfo.checkSubType ===
                                              "0203")
                                            ? _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "费用",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.priceShow === "1"
                                                      ? (_vm.priceShow = "0")
                                                      : (_vm.priceShow = "1")
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.orderInfo.callSign === "7"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: "审批附件",
                                                  checked: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.approvalShow === "1"
                                                      ? (_vm.approvalShow = "0")
                                                      : (_vm.approvalShow = "1")
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }