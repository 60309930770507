"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _audit = require("@/api/bill/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      refundTransactionInfo: {},
      loadingList4Model: false,
      payChannel: [{
        name: '微信',
        val: '10'
      }, {
        name: '支付宝',
        val: '20'
      }, {
        name: '银行转账',
        val: '40'
      }],
      refusedReason: [{
        name: '不符合退货标准',
        val: '10'
      }, {
        name: '超出退款时效',
        val: '20'
      }, {
        name: '取消退款',
        val: '30'
      }, {
        name: '其他',
        val: '40'
      }],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible4: false,
      btnLoading: false,
      completeBtn: false,
      isHidden: false,
      rules: {},
      rule: {},
      rules1: {
        refundDate: [{
          required: true,
          message: '请选择退款日期',
          trigger: 'change'
        }]
      },
      rules2: {
        refusedReason: [{
          required: true,
          message: '请选择退款原因',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _audit.queryById)(param).then(function (response) {
        if (response.data) {
          var a = 0;
          _this.refundTransactionInfo = response.data;

          _this.refundTransactionInfo.items.forEach(function (it) {
            if (it.quantity === '' || it.quantity === undefined || it.quantity === null) {
              a = parseInt(a) + 0;
            } else {
              a = parseInt(a) + parseInt(it.quantity);
            }
          });

          _this.refundTransactionInfo.quantity = a;
        }
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0 || columnIndex === 1) {
        var a = this.refundTransactionInfo.items.length;

        if (rowIndex % a === 0) {
          return {
            rowspan: a,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/refundAudit'
      });
    },
    dataFormat: function dataFormat(row) {
      return this.refundTransactionInfo.sourceTradeNo;
    },
    dataFormat2: function dataFormat2(row) {
      var _this2 = this;

      var a = '';
      this.payChannel.map(function (type) {
        if (_this2.refundTransactionInfo.payChannel.trim() === type.val) {
          a = type.name;
        }
      });
      return a;
    },
    comfirm: function comfirm() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (_this3.refundTransactionInfo.isOnline === '20') {
          if (_this3.refundTransactionInfo.refundDate === '' || _this3.refundTransactionInfo.refundDate === undefined || _this3.refundTransactionInfo.refundDate === null) {
            return;
          }
        }

        _this3.completeBtn = true;
        _this3.btnLoading = true;
        (0, _audit.updateSecond)(_this3.refundTransactionInfo).then(function (response) {
          if (response.data) {
            _this3.$message({
              showClose: true,
              message: '操作成功',
              type: 'success'
            });

            _this3.$store.dispatch('tagsView/delView', _this3.$route);

            _this3.$router.push({
              path: '/bill/refundAudit'
            });
          } else {
            _this3.completeBtn = false;
            _this3.btnLoading = false;

            _this3.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
      });
    },
    comfirmB: function comfirmB() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (_this4.refundTransactionInfo.refusedReason === '' || _this4.refundTransactionInfo.refusedReason === undefined || _this4.refundTransactionInfo.refusedReason === null) {
          return;
        }

        _this4.completeBtn = true;
        _this4.btnLoading = true;
        (0, _audit.updateReject)(_this4.refundTransactionInfo).then(function (response) {
          if (response.data) {
            _this4.$message({
              showClose: true,
              message: '操作成功',
              type: 'success'
            });

            _this4.$store.dispatch('tagsView/delView', _this4.$route);

            _this4.$router.push({
              path: '/bill/refundAudit'
            });
          } else {
            _this4.completeBtn = false;
            _this4.btnLoading = false;

            _this4.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
      });
    },
    updateFirst: function updateFirst() {
      this.dialogVisible4 = true;
      this.completeBtn = false;
      this.btnLoading = false;
    },
    comfirmC: function comfirmC() {
      var _this5 = this;

      this.completeBtn = true;
      this.btnLoading = true;
      (0, _audit.updateFirst)(this.refundTransactionInfo).then(function (response) {
        if (response.data) {
          _this5.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/bill/refundAudit'
          });
        } else {
          _this5.completeBtn = false;
          _this5.btnLoading = false;

          _this5.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    updateSecond: function updateSecond() {
      if (this.refundTransactionInfo.isOnline === '10') {
        this.isHidden = true;
        this.rules = this.rule;
      } else {
        this.isHidden = false;
        this.rules = this.rules1;
      }

      this.dialogVisible1 = true;
      this.refundTransactionInfo.refundDate = '';
      this.completeBtn = false;
      this.btnLoading = false;
    },
    updateReject: function updateReject() {
      this.rules = this.rules2;
      this.dialogVisible2 = true;
      this.refundTransactionInfo.remark = '';
      this.refundTransactionInfo.refusedReason = '';
      this.completeBtn = false;
      this.btnLoading = false;
    }
  }
};
exports.default = _default;