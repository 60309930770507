"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _order = require("@/api/order/order");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _filterUtils = require("@/api/order/filterUtils");

var _return = _interopRequireDefault(require("@/components/order/return"));

var _verify = _interopRequireDefault(require("@/components/order/verify"));

var _handle = _interopRequireDefault(require("@/components/order/handle"));

var _recheck = _interopRequireDefault(require("@/components/order/recheck"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _stateChange = _interopRequireDefault(require("@/components/order/stateChange"));

var _payment = _interopRequireDefault(require("@/components/order/payment"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _SFRouteState = _interopRequireDefault(require("@/components/order/SFRouteState"));

var _orderTaskList = _interopRequireDefault(require("@/components/order/orderTaskList"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 返机信息
// 终验信息
// 处理信息
// 复核信息
// 外呼信息
// 外呼信息
// 支付信息
// 检测信息
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 工单物流信息
// 分派客服履历
// 费用明细
// 新建任务工单
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    orderReturn: _return.default,
    orderVerify: _verify.default,
    orderHandle: _handle.default,
    orderRecheck: _recheck.default,
    orderCheck: _check.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    sfRouteState: _SFRouteState.default,
    orderPayment: _payment.default,
    orderCallout: _calloutList.default,
    stateChange: _stateChange.default,
    orderTaskList: _orderTaskList.default,
    orderPrice: _checkPrice.default,
    NewTask: _NewTask.default
  },
  filters: {
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      isCancelOrder: true,
      cellDisabled: true,
      consumerShow: '1',
      productShow: '1',
      historyShow: '1',
      receiptShow: '1',
      dispatchShow: '1',
      checkShow: '1',
      priceShow: '0',
      recheckShow: '1',
      calloutShow: '1',
      stateChangeShow: '1',
      paymentShow: '0',
      handleShow: '1',
      verifyShow: '1',
      approveShow: '0',
      isEngage: false,
      dialogTableVisible: false,
      isRelevan: true,
      returnShow: '1',
      sfInfoShow: '1',
      id: this.$route.params.id,
      cancelReasonOptions: _constant.default.cancelReasonOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      dialogVisible: false,
      cancelReason: '',
      orderInfo: {},
      rules: {},
      engageData: [],
      typesOptions: [{
        label: '备注',
        value: 'ADDNOTE'
      }, {
        label: '收货',
        value: 'GOODSRCPT'
      }, {
        label: '检测',
        value: 'ASSESSMENT'
      }, {
        label: '处理',
        value: 'REPAIR'
      }, {
        label: '返机',
        value: 'DESPATCH'
      }, {
        label: '工程师',
        value: 'ENGINEER'
      }],
      expressTitle: '',
      expressType: '',
      expressDialogVisible: false,
      enableModify: true,
      jdSalesOrderNo: '',
      jdSupplyInfo: '',
      refDateBefore: ''
    };
  },
  created: function created() {
    if (this.id) {
      this.getOrderInfo();
    }
  },
  methods: {
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this = this;

      // 【注意】此处的id可能是code
      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.orderInfo = response.data;
          _this.refDateBefore = _this.orderInfo.refDate;
          _this.orderInfo.refDateBefore = _this.refDateBefore;

          if (_this.orderInfo.approveRmk !== null && _this.orderInfo.approveRmk !== '') {
            _this.approveShow = '1';
          }

          if (_this.orderInfo.checkSubType === '0102' || _this.orderInfo.checkSubType === '0103' || _this.orderInfo.checkType === '02' && _this.orderInfo.checkSubType === '0203') {
            _this.priceShow = '1';
            _this.paymentShow = '1';
          }

          _this.$refs.orderProduct.$emit('loadFreeSn', _this.orderInfo.sn);

          _this.$refs.checkPrice.$emit('loadCheckInfo', _this.orderInfo.id);

          _this.$refs.receiptOrder.$emit('loadExpressGoods', _this.orderInfo.id);

          _this.$refs.checkOrder.$emit('loadcheckInfo', {
            id: _this.orderInfo.id
          });

          _this.$refs.calloutOrder.$emit('loadcalloutList', _this.orderInfo.id);

          _this.$refs.stateChange.$emit('loadCalloutList', _this.orderInfo.id);

          _this.$refs.historyList.$emit('loadHistoryList', _this.orderInfo.id);

          if (_this.orderInfo.subState === 'S308') {
            _this.cellDisabled = false;
          }

          if (_this.$refs.verifyOrder) {
            _this.$refs.verifyOrder.$emit('loadCheckItems', _this.orderInfo.id);
          }

          if (_this.$refs.returnOrder) {
            _this.$refs.returnOrder.$emit('loadExpressGoods', _this.orderInfo.id);
          }

          if (_this.orderInfo.type === '01') {
            _this.$refs.handleOrder.$emit('loadhandleInfo', _this.orderInfo.id);
          } else {
            _this.handleShow = '0';
            _this.verifyShow = '0';
          }

          if (_this.$refs.sfInfoForm) {
            _this.$refs.sfInfoForm.$emit('loadSFInfo', _this.orderInfo.orderNo);
          }

          if (_this.$refs.sfInfoTo) {
            _this.$refs.sfInfoTo.$emit('loadSFInfo', _this.orderInfo.orderNoc);
          }

          if (_this.orderInfo.isEngage) {
            _this.isEngage = true;

            _this.getOrderEngageList();
          } // 关闭服务单是否可用


          _this.isCancelOrder = _this.orderInfo.isOrderClose; // 如果不是本机构或上级机构 隐藏按钮

          var orgId = _this.$store.state.user.loginUser.orgId;
          _this.isRelevan = _this.orderInfo.orgPath.indexOf(orgId) !== -1; // 查询京东补充信息

          if (_this.orderInfo.jdOrderNo !== '' && _this.orderInfo.jdOrderNo !== null) {
            (0, _order.getJdOrderSupplyInfo)(_this.orderInfo.jdOrderNo).then(function (info) {
              if (info.data && info.code === 200) {
                _this.jdSalesOrderNo = info.data.jdSalesOrderNo;
                _this.jdSupplyInfo = info.data.supplyInfo;
              }
            });
          }
        }
      });
    },
    againCallOver: function againCallOver() {
      var _this2 = this;

      var orderInfo1 = {
        id: this.id
      };
      (0, _order.againCallOver)(orderInfo1).then(function (response) {
        _this2.btnLoading = true;

        if (response.data) {
          _this2.btnLoading = false;

          _this2.$message({
            showClose: true,
            message: '发送成功',
            type: 'success'
          });
        }
      });
    },
    getOrderEngageList: function getOrderEngageList() {
      var _this3 = this;

      (0, _order.getOrderEngageList)(this.id).then(function (response) {
        if (response.data) {
          _this3.engageData = response.data.map(function (item) {
            item.closedDatetime = (0, _utils.parseTime)(item.closedDatetime, '{y}-{m}-{d} {h}:{i}:{s}');
            return item;
          });
        } // else {
        //   this.$message({ showClose: true, message: '没有对应的engage信息', type: 'warning' })
        // }

      });
    },
    outCall: function outCall() {
      var _this4 = this;

      this.$prompt('请输入沟通内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: false,
        inputType: 'textarea',
        closeOnClickModal: false
      }).then(function (_ref) {
        var value = _ref.value;

        if (!value) {
          _this4.$message({
            showClose: true,
            message: '内容必填的',
            type: 'warning'
          });

          return;
        }

        console.log(value);
        var callCate = {
          id: _this4.orderInfo.id,
          callCate: value
        };
        (0, _order.outCallComm)(callCate).then(function (response) {
          _this4.btnLoading = true;

          if (response.data) {
            _this4.btnLoading = false;

            _this4.$message({
              showClose: true,
              message: '外呼沟通成功',
              type: 'success'
            });
          } else {
            _this4.btnLoading = false;

            _this4.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
      }).catch(function () {});
    },
    showOrderClose: function showOrderClose() {
      this.dialogVisible = true;
    },
    orderClose: function orderClose() {
      var _this5 = this;

      this.isCancelOrder = false;

      if (this.cancelReason) {
        this.$confirm('是否新建咨询单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.orderClose1(true);
        }).catch(function () {
          _this5.orderClose1(false);
        });
      } else {
        this.$message({
          showClose: true,
          message: '取消原因必选的',
          type: 'warning'
        });
        this.btnLoading = false;
      }
    },
    orderClose1: function orderClose1(val) {
      var _this6 = this;

      var callCate = {
        id: this.orderInfo.id,
        cancelReason: this.cancelReason,
        isNewConsult: val
      };
      (0, _order.orderClose)(callCate).then(function (response) {
        _this6.btnLoading = true;
        console.log(response);

        if (response.data) {
          _this6.btnLoading = false;

          _this6.$message({
            showClose: true,
            message: '关闭服务单成功',
            type: 'success'
          });

          if (val) {
            _this6.$router.push({
              name: 'consultOrderDetail',
              params: {
                id: response.data,
                isQuery: '0'
              }
            });
          } else {
            _this6.$router.push({
              name: 'order_list',
              params: {
                id: _this6.orderInfo.id
              }
            });
          }
        } else {
          _this6.btnLoading = false;

          _this6.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    // 补录顺丰单号 弹出框
    showExpressDiv: function showExpressDiv(val) {
      if (val === '1') {
        if (this.orderInfo.receiptLogisticsNumber !== null && this.orderInfo.receiptLogisticsNumber.length > 0) {
          this.$message({
            showClose: true,
            message: '已有收货物流单号，不需要补充',
            type: 'warning'
          });
          return false;
        }

        this.expressTitle = '收货单号补充';
        this.expressType = '1';
      } else {
        if (this.orderInfo.returnLogisticsNumber !== null && this.orderInfo.returnLogisticsNumber.length > 0) {
          this.$message({
            showClose: true,
            message: '已有返机物流单号，不需要补充',
            type: 'warning'
          });
          return false;
        }

        this.expressTitle = '返机单号补充';
        this.expressType = '2';
      }

      this.expressDialogVisible = true;
    },
    // 保存顺丰单号信息
    saveExpress: function saveExpress(type) {
      var _this7 = this;

      var expressInfo = {
        id: this.orderInfo.id,
        returnLogisticsNumber: this.orderInfo.returnLogisticsNumberBu,
        receiptLogisticsNumber: this.orderInfo.receiptLogisticsNumberBu,
        type: type
      };
      (0, _order.saveExpress)(expressInfo).then(function (response) {
        if (response.data) {
          _this7.$message({
            showClose: true,
            message: response.msg || '操作成功',
            type: 'success'
          });
        } else {
          _this7.$message({
            showClose: true,
            message: response.msg || '操作失败',
            type: 'warning'
          });
        }

        _this7.getOrderInfo();

        _this7.expressDialogVisible = false;
      });
    },
    // 类型格式化
    typeFormat: function typeFormat(row) {
      return this.typesOptions.map(function (type) {
        if (row.typeId.trim() === type.value) {
          return type.label;
        }

        return '';
      });
    } // -------------------------------------------------------------------------------------------------------------------------------------------

  }
};
exports.default = _default;