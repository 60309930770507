var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("div", { staticClass: "main-container" }, [_c("app-main")], 1),
      _vm._v(" "),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "back to Top" } },
        [
          _c("back-to-top", {
            attrs: {
              "custom-style": _vm.myBackToTopStyle,
              "visibility-height": 300,
              "back-position": 50,
              "transition-name": "fade",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }