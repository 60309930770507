"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enrollProduct = enrollProduct;
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.queryById = queryById;
exports.updateState = updateState;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/product_enrollment/getPageList',
    method: 'post',
    params: query
  });
}
/** 登记详情*/


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/product_enrollment/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 登记履历*/


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/product_app/product_enrollment_history/getHistoryList',
    method: 'post',
    params: query
  });
}
/** 失效*/


function updateState(id, type) {
  return (0, _request.default)({
    url: '/product_app/product_enrollment/updateState',
    method: 'post',
    params: {
      id: id,
      type: type
    }
  });
}
/** csrm 产品仅登记 */


function enrollProduct(data) {
  return (0, _request.default)({
    url: '/product_app/product_enrollment/enrollProduct',
    method: 'post',
    data: data
  });
}