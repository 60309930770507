"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 减免管理块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var reductionRouter = {
  path: '/reduction/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'approve/detail/:id/:level',
    name: 'approveDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approve/detail'));
      });
    },
    meta: {
      title: '减免审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'approveStair/detail/:id/:level',
    name: 'approveDetail2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approveStair/detail'));
      });
    },
    meta: {
      title: '减免审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'approveTertiary/detail/:id/:level',
    name: 'approveDetail3',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approveTertiary/detail'));
      });
    },
    meta: {
      title: '减免审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'approve/saleDetail/:orderNo',
    name: 'approveSaleDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approve/saleDetail'));
      });
    },
    meta: {
      title: '销售审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'approveStair/saleDetail/:orderNo',
    name: 'approveSaleDetail2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approveStair/saleDetail'));
      });
    },
    meta: {
      title: '销售审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'approveTertiary/saleDetail/:orderNo',
    name: 'approveSaleDetail3',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/approveTertiary/saleDetail'));
      });
    },
    meta: {
      title: '销售审批详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/approve/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'pool/poolLog',
    name: 'poolLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/pools/poolLog'));
      });
    },
    meta: {
      title: '减免配置日志',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/pools/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'poolRepair/poolRepairLog',
    name: 'poolRepairLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/reduction/poolRepair/poolRepairLog'));
      });
    },
    meta: {
      title: '维修转换货减免配置日志',
      icon: 'table',
      hidden: true,
      activeMenu: '/reduction/poolRepair/index',
      parent: {
        path: '/reduction',
        redirect: 'noRedirect',
        meta: {
          title: '减免管理',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = reductionRouter;
exports.default = _default;