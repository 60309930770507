var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.billInvoiceInfo,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("发票信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票类型",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票类型",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo
                                                  .invoiceTypeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "invoiceTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.invoiceTypeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票号",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票号",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.invoiceNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "invoiceNum",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.invoiceNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "红票日期",
                                            prop: "returnDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "红票日期",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.returnDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "returnDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.returnDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票对象类型",
                                            prop: "custType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "开票对象类型",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo
                                                  .custTypeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custTypeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票抬头",
                                            prop: "custName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "发票抬头",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.custName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "退票方式",
                                            prop: "refundTypeName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "退票方式",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo
                                                  .refundTypeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "refundTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.refundTypeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.billInvoiceInfo.invoiceType === "20"
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮箱地址",
                                                prop: "custEmail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "邮箱地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow ||
                                (_vm.billInvoiceInfo.invoiceType === "20" &&
                                  _vm.billInvoiceInfo.custType === "20")
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "纳税人识别号",
                                                prop: "custTax",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "纳税人识别号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo.custTax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custTax",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custTax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册地址",
                                                prop: "custAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册电话",
                                                prop: "custPhone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "公司注册电话",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "开户行信息",
                                                prop: "custBank",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "开户行信息",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custBank,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custBank",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custBank",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "银行账号",
                                                prop: "custBankAcc",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "银行账号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custBankAcc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custBankAcc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custBankAcc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票人",
                                                prop: "takeUser",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票人",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票手机号",
                                                prop: "takeMobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票手机号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票地址",
                                                prop: "takeAddressDetail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "收票地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeAddressDetail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeAddressDetail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeAddressDetail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "原发票号",
                                            prop: "sourceInvoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "原发票号",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo
                                                  .sourceInvoiceNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "sourceInvoiceNum",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.sourceInvoiceNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票日期",
                                            prop: "billingDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "开票日期",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.billingDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "billingDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.billingDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "快递单号",
                                                prop: "courierNum",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "快递单号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .courierNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "courierNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.courierNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShow
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄出日期",
                                                prop: "sendDate",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "寄出日期",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .sendDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "sendDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.sendDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("商品明细")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "padding-top": "20px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("div", {
                                staticClass: "grid-content bg-purple",
                                staticStyle: { float: "right" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "1px" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadingList4Model,
                                              expression: "loadingList4Model",
                                            },
                                          ],
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            border: "",
                                            fit: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                            },
                                            "tooltip-effect": "dark",
                                            data: _vm.billInvoiceInfo.items,
                                          },
                                        },
                                        [
                                          _vm.billInvoiceInfo.busType === "30"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "skuCode",
                                                  "min-width": "120px",
                                                  label: "物料编码",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.billInvoiceInfo.busType === "40"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "skuCode",
                                                  "min-width": "120px",
                                                  label: "服务编码",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.billInvoiceInfo.busType === "30"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "skuName",
                                                  "min-width": "180px",
                                                  label: "物料名称",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.billInvoiceInfo.busType === "40"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "skuName",
                                                  "min-width": "180px",
                                                  label: "服务名称",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "priceAfterExp",
                                              label: "税前单价",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "priceBeforeExp",
                                              label: "含税单价",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.billInvoiceInfo.busType === "30"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  prop: "quantity",
                                                  label: "数量",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              prop: "rateName",
                                              label: "税率",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "30px" } },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "本单商品总数",
                                              prop: "amount",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "总数量",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .goodsCount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "goodsCount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.goodsCount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "含税总价",
                                              prop: "grossValueExp",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "含税总价",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .grossValueExp,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "grossValueExp",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.grossValueExp",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }