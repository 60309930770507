var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "padding-bottom": "5px", "padding-top": "10px" } },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _vm.dialogStatus === "create"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_STOCK_STOCK_OUT_PLAN_SUBMIT"],
                              expression:
                                "['ROLE_STOCK_STOCK_OUT_PLAN_SUBMIT']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSave()
                            },
                          },
                        },
                        [_vm._v("\n            确认提交\n          ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("\n            返回\n          "),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              rules: _vm.rules,
              model: _vm.stockOutPlanInfo,
              "label-position": "right",
              "label-width": "140px",
            },
          },
          [
            _c(
              "el-card",
              {
                staticClass: "box-card",
                staticStyle: { "margin-bottom": "20px" },
                attrs: { shadow: "hover" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("el-tag", { attrs: { effect: "plain" } }, [
                      _vm._v("基本信息"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "padding-top": "20px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "出库单号", prop: "num" } },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "单号",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.stockOutPlanInfo.num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.stockOutPlanInfo, "num", $$v)
                                    },
                                    expression: "stockOutPlanInfo.num",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出库仓库",
                                  prop: "warehouseCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.stockOutPlanInfo.warehouseCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stockOutPlanInfo,
                                          "warehouseCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "stockOutPlanInfo.warehouseCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.warehouseList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "提交人/时间", prop: "num" } },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "提交人/时间",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.stockOutPlanInfo.submitAll,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stockOutPlanInfo,
                                        "submitAll",
                                        $$v
                                      )
                                    },
                                    expression: "stockOutPlanInfo.submitAll",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "出库时间", prop: "num" } },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "出库时间",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.stockOutPlanInfo.confirmDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stockOutPlanInfo,
                                        "confirmDate",
                                        $$v
                                      )
                                    },
                                    expression: "stockOutPlanInfo.confirmDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "出库备注", prop: "memo" } },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: {
                                    autosize: { minRows: 2, maxRows: 6 },
                                    type: "textarea",
                                    placeholder: "Please input",
                                    maxlength: "200",
                                    disabled: _vm.disableVisible,
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.stockOutPlanInfo.memo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stockOutPlanInfo,
                                        "memo",
                                        $$v
                                      )
                                    },
                                    expression: "stockOutPlanInfo.memo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "计划出库截止日期", prop: "planDate" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "50%" },
                          attrs: {
                            type: "date",
                            placeholder: "计划出库截止日期",
                            "picker-options": _vm.pickerOptions,
                            disabled: _vm.disableVisible,
                          },
                          model: {
                            value: _vm.stockOutPlanInfo.planDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockOutPlanInfo, "planDate", $$v)
                            },
                            expression: "stockOutPlanInfo.planDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "span" }, [
              _vm._v(
                "实际出库时间为提交日期的次日凌晨0~6点期间；如“计划出库日期”为2021-03-01，提交该计划是在3月3日中午12点操作，则实际出库时间是2021-03-04的凌晨5点左右，将入库时间在2021-03-01（含）的物料进行批量出库"
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }