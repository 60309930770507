var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确认\n        ")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.fcCleanInfo,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("小程序寄件人信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件人姓名",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "寄件人姓名",
                                            },
                                            model: {
                                              value: _vm.fcCleanInfo.sendName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "sendName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fcCleanInfo.sendName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件人电话",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "寄件人电话",
                                            },
                                            model: {
                                              value: _vm.fcCleanInfo.sendTel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "sendTel",
                                                  $$v
                                                )
                                              },
                                              expression: "fcCleanInfo.sendTel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "寄件地址",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "寄件地址",
                                            },
                                            model: {
                                              value:
                                                _vm.fcCleanInfo.sendAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "sendAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fcCleanInfo.sendAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "box-card",
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: { shadow: "hover" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "20px" } },
                                  [_vm._v("CSRM寄件人信息")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "20px" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件人姓名",
                                                prop: "invoiceType",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "寄件人姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.fcCleanInfo.sendName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fcCleanInfo,
                                                      "sendName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fcCleanInfo.sendName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "寄件人电话",
                                                prop: "invoiceType",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "寄件人电话",
                                                },
                                                model: {
                                                  value:
                                                    _vm.fcCleanInfo.sendTel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fcCleanInfo,
                                                      "sendTel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fcCleanInfo.sendTel",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "省",
                                          prop: "provinceId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.proviceChange(
                                                  _vm.fcCleanInfo.provinceId
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.fcCleanInfo.provinceId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "provinceId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fcCleanInfo.provinceId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.provinceOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "市", prop: "cityId" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择市",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.cityChange(
                                                  _vm.fcCleanInfo.cityId
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.fcCleanInfo.cityId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "cityId",
                                                  $$v
                                                )
                                              },
                                              expression: "fcCleanInfo.cityId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cityOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "区/县",
                                          prop: "districtId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择区",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                            model: {
                                              value: _vm.fcCleanInfo.districtId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "districtId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fcCleanInfo.districtId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.countryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "详细地址",
                                            prop: "sudAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "详细地址" },
                                            model: {
                                              value: _vm.fcCleanInfo.sudAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fcCleanInfo,
                                                  "sudAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fcCleanInfo.sudAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }