"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _label = require("@/api/basics/label/label");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 可拖拽弹出框
var _default = {
  components: {
    Sticky: _Sticky.default
  },
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      // 标签类型
      labelType: [{
        'id': '10',
        'name': '用户'
      }, {
        'id': '20',
        'name': '手机号'
      }, {
        'id': '30',
        'name': '产品sn'
      }, {
        'id': '40',
        'name': '详细地址'
      }],
      // 标签级别
      labelLevel: [{
        'id': '10',
        'name': '低风险'
      }, {
        'id': '20',
        'name': '中风险'
      }, {
        'id': '30',
        'name': '高风险'
      }],
      label: {
        page: 1,
        limit: 10,
        type: undefined,
        name: undefined,
        isEnable: true,
        level: undefined
      },
      rules: {
        level: [{
          required: true,
          message: '标签级别是必填的',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '标签名称是必填的',
          trigger: 'blur'
        }, {
          pattern: /^([\u4e00-\u9fa5\da-zA-z]{0,10})$/,
          message: '最多10个字符，由汉字数字和字母组成'
        }],
        type: [{
          required: true,
          message: '标签类型是必填的',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _label.saveLabel)(_this.label).then(function (response) {
            if (response.data) {
              _this.$message({
                showClose: true,
                message: '标签（' + _this.label.name + ') 新增成功！',
                type: 'success'
              });

              _this.$store.dispatch('tagsView/delView', _this.$route);

              _this.$router.push({
                path: '/basics/label/list'
              });
            } else {
              _this.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/label/list'
      });
    }
  }
};
exports.default = _default;