"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _label = require("@/api/basics/label/label");

var _risk = require("@/api/basics/risk/risk");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 可拖拽弹出框
var _default = {
  components: {
    Sticky: _Sticky.default
  },
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default
  },
  filters: {
    typeFilter: function typeFilter(status) {
      var statusMap = {
        10: '用户',
        20: '手机号',
        30: '产品sn',
        40: '详细地址'
      };
      return statusMap[status];
    },
    levelFilter: function levelFilter(status) {
      var statusMap = {
        10: '低风险',
        20: '中风险',
        30: '高风险'
      };
      return statusMap[status];
    },
    levelFilters: function levelFilters(status) {
      var statusMap = {
        10: 'background-color: purple;color: white',
        20: 'background-color: blue;color: white',
        30: 'background-color: red;color: white'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      // 标签类型
      labelType: [{
        'id': '10',
        'name': '用户'
      }, {
        'id': '20',
        'name': '手机号'
      }, {
        'id': '30',
        'name': '产品sn'
      }, {
        'id': '40',
        'name': '详细地址'
      }],
      total: 0,
      riskInfo: {
        type: undefined,
        labelNameList: [],
        labelList: []
      },
      labelNameList: [],
      riskQuery1: {
        type: undefined,
        level: '10'
      },
      riskQuery2: {
        type: undefined,
        level: '20'
      },
      riskQuery3: {
        type: undefined,
        level: '30'
      },
      dialog: false,
      riskHis: null,
      labelList10: null,
      labelList20: null,
      labelList30: null,
      loading: false,
      rules: {
        level: [{
          required: true,
          message: '标签级别是必填的',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '标签名称是必填的',
          trigger: 'blur'
        }, {
          pattern: /^([\u4e00-\u9fa5\da-zA-z]{0,10})$/,
          message: '最多10个字符，由汉字数字和字母组成'
        }],
        type: [{
          required: true,
          message: '标签类型是必填的',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getRiskById();
  },
  methods: {
    getRiskById: function getRiskById() {
      var _this = this;

      (0, _risk.getRiskById)(this.id).then(function (response) {
        if (response.data) {
          _this.riskInfo = response.data;

          _this.getLabelList();

          _this.getRiskHisList();
        }
      });
    },
    getLabelList: function getLabelList() {
      var _this2 = this;

      this.riskQuery1.type = this.riskInfo.type;
      this.riskQuery2.type = this.riskInfo.type;
      this.riskQuery3.type = this.riskInfo.type;
      (0, _label.getLabelListByType)(this.riskQuery1).then(function (response) {
        if (response.data) {
          _this2.labelList10 = response.data;
        }
      });
      (0, _label.getLabelListByType)(this.riskQuery2).then(function (response) {
        if (response.data) {
          _this2.labelList20 = response.data;
        }
      });
      (0, _label.getLabelListByType)(this.riskQuery3).then(function (response) {
        if (response.data) {
          _this2.labelList30 = response.data;
        }
      });
    },
    getRiskHisList: function getRiskHisList() {
      var _this3 = this;

      (0, _risk.getRiskHisList)(this.riskInfo).then(function (response) {
        if (response.data) {
          _this3.riskHis = response.data;
        }
      });
    },
    closeRisk: function closeRisk(risk) {
      this.riskInfo.labelNameList.forEach(function (item, index, arr) {
        if (item.name === risk) {
          arr.splice(index, 1);
        }
      });
      this.riskInfo.labelList.forEach(function (item, index, arr) {
        if (item === risk) {
          arr.splice(index, 1);
        }
      });
    },
    dialogCreate: function dialogCreate() {
      this.dialog = true;
      this.loading = false;

      if (this.riskInfo.labelList) {
        this.labelNameList = this.riskInfo.labelList;
      }
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    dialogClose: function dialogClose() {
      this.dialog = false;
      this.riskInfo.labelList = this.labelNameList;
      this.saveSumbit();
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this4 = this;

      (0, _risk.updateRisk)(this.riskInfo).then(function (response) {
        if (response.data) {
          _this4.$message({
            showClose: true,
            message: '风险信息更新成功！',
            type: 'success'
          });

          _this4.getRiskById();
        } else {
          _this4.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1); // 返回上一层
    }
  }
};
exports.default = _default;