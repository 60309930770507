"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _components = require("./components");

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

var _BackToTop = _interopRequireDefault(require("@/layout/components/BackToTop"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Layout1',
  components: {
    AppMain: _components.AppMain,
    BackToTop: _BackToTop.default
  },
  mixins: [_ResizeHandler.default],
  data: function data() {
    return {
      // customizable button style, show/hide critical point, return position
      myBackToTopStyle: {
        right: '50px',
        bottom: '50px',
        width: '40px',
        height: '40px',
        'border-radius': '4px',
        'line-height': '45px',
        // 请保持与高度一致以垂直居中 Please keep consistent with height to center vertically
        background: '#e7eaf1' // 按钮的背景颜色 The background color of the button

      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: false,
    needTagsView: true,
    fixedHeader: true
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened || true,
        openSidebar: this.sidebar.opened || false,
        withoutAnimation: true,
        mobile: ''
      };
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};
exports.default = _default;