"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _sale = require("@/api/sale/sale");

var _select = require("@/api/common/select");

var _list = require("@/api/basics/deliver/list");

var _sku = require("@/api/basics/sku/sku");

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _SFRouteState = _interopRequireDefault(require("@/components/order/SFRouteState"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 下拉框 树形结构 可检索组件
// 新建任务工单
// 工单物流信息
var _default = {
  name: 'orderSaleCreate',
  // eslint-disable-line
  components: {
    treeSelect: _treeSelect.default,
    NewTask: _NewTask.default,
    sfRouteState: _SFRouteState.default
  },
  data: function data() {
    var _ref;

    return _ref = {
      boolSap: false,
      bl1: true,
      bl2: false,
      bl3: false
    }, (0, _defineProperty2.default)(_ref, "boolSap", false), (0, _defineProperty2.default)(_ref, "blList", []), (0, _defineProperty2.default)(_ref, "config1", true), (0, _defineProperty2.default)(_ref, "config2", false), (0, _defineProperty2.default)(_ref, "config3", false), (0, _defineProperty2.default)(_ref, "stock1", false), (0, _defineProperty2.default)(_ref, "stock2", false), (0, _defineProperty2.default)(_ref, "stock3", false), (0, _defineProperty2.default)(_ref, "configFlagList", []), (0, _defineProperty2.default)(_ref, "stockFlag", false), (0, _defineProperty2.default)(_ref, "configFlag", false), (0, _defineProperty2.default)(_ref, "skuStockList", []), (0, _defineProperty2.default)(_ref, "id", this.$route.params.id), (0, _defineProperty2.default)(_ref, "flag", this.$route.params.dialogStatus), (0, _defineProperty2.default)(_ref, "isDis", false), (0, _defineProperty2.default)(_ref, "dialogVisible", false), (0, _defineProperty2.default)(_ref, "dialogVisible2", false), (0, _defineProperty2.default)(_ref, "dialogVisible8", false), (0, _defineProperty2.default)(_ref, "dialogVisible10", false), (0, _defineProperty2.default)(_ref, "dialog8Msg", ''), (0, _defineProperty2.default)(_ref, "dialog10Msg", ''), (0, _defineProperty2.default)(_ref, "btnLoading", false), (0, _defineProperty2.default)(_ref, "tempBtn", false), (0, _defineProperty2.default)(_ref, "completeBtn", false), (0, _defineProperty2.default)(_ref, "categorySelect", []), (0, _defineProperty2.default)(_ref, "brandSelect", []), (0, _defineProperty2.default)(_ref, "modelSelect", []), (0, _defineProperty2.default)(_ref, "sku708", ['375423-01', '376519-01', '375411-01', '376510-01', '413149-01']), (0, _defineProperty2.default)(_ref, "payType", [// { label: '须收费', value: '01' },
      // { label: '保内维修', value: 'P4' },
      // { label: '特例免费', value: '06' }
    ]), (0, _defineProperty2.default)(_ref, "describeList", [[{
      label: '购买配件（无原因）',
      value: '01'
    }, {
      label: '配件丢失',
      value: '02'
    }, {
      label: '人为损坏',
      value: '03'
    }, {
      label: '消耗品',
      value: '04'
    }, {
      label: '保内损坏（非人为）',
      value: '05'
    }, {
      label: '开箱破损',
      value: '06'
    }, {
      label: '开箱缺件',
      value: '07'
    }, {
      label: '保外/特例补偿',
      value: '08'
    }, {
      label: '活动赠品补发',
      value: '09'
    }, {
      label: '其他',
      value: '10'
    }]]), (0, _defineProperty2.default)(_ref, "cancelReason", [{
      label: '取消服务',
      value: '10'
    }, {
      label: '客服下错单',
      value: '20'
    }, {
      label: '时间过长',
      value: '30'
    }, {
      label: '测试订单',
      value: '40'
    }, {
      label: '价格调整',
      value: '50'
    }]), (0, _defineProperty2.default)(_ref, "tagOptions", [{
      label: '成品',
      value: '1'
    }, {
      label: '配件',
      value: '2'
    }]), (0, _defineProperty2.default)(_ref, "validateForm", []), (0, _defineProperty2.default)(_ref, "storageList", []), (0, _defineProperty2.default)(_ref, "provinceOptions", []), (0, _defineProperty2.default)(_ref, "cityOptions", []), (0, _defineProperty2.default)(_ref, "countryOptions", []), (0, _defineProperty2.default)(_ref, "isCancel", false), (0, _defineProperty2.default)(_ref, "saleInfo2", {
      cancelMemo: '',
      cancelReason: ''
    }), (0, _defineProperty2.default)(_ref, "listQuery", {
      categoryId: undefined,
      brandId: undefined,
      id: undefined
    }), (0, _defineProperty2.default)(_ref, "levelName1", ''), (0, _defineProperty2.default)(_ref, "levelName2", ''), (0, _defineProperty2.default)(_ref, "levelName3", ''), (0, _defineProperty2.default)(_ref, "levelLength", ''), (0, _defineProperty2.default)(_ref, "sapStatus", {}), (0, _defineProperty2.default)(_ref, "skuList", []), (0, _defineProperty2.default)(_ref, "rules", {}), (0, _defineProperty2.default)(_ref, "tableData1", []), (0, _defineProperty2.default)(_ref, "accordion", false), (0, _defineProperty2.default)(_ref, "cTree", true), (0, _defineProperty2.default)(_ref, "rules2", {
      cancelReason: [{
        required: true,
        message: '请选择取消原因',
        trigger: 'change'
      }]
    }), (0, _defineProperty2.default)(_ref, "baseSku", {}), (0, _defineProperty2.default)(_ref, "saleInfo", {
      deliveryDate: '',
      spaNo: '',
      amount: 0,
      priceAfter: '0.00',
      priceBefore: '0.00',
      urgent: '1',
      province: '',
      city: '',
      district: '',
      state: '',
      items: [{
        categoryId: '',
        modelId: '',
        skuCode: '',
        brandId: '',
        skuName: '',
        skuId: '',
        num: 0,
        payType: '',
        describes: '',
        memo: '',
        priceBeforeY: '',
        priceBeforeYY: '',
        priceAfterY: '',
        priceAfterYY: '',
        stock: '',
        storage: '',
        level1: '',
        level2: '',
        level3: '',
        levelStock1: '',
        levelStock2: '',
        levelStock3: '',
        src: 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
      }]
    }), (0, _defineProperty2.default)(_ref, "configList", []), _ref;
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    }
  },
  created: function created() {
    this.getSelect();

    if (this.id) {
      this.getSaleInfo();
    }
  },
  methods: {
    getProvinceOptions: function getProvinceOptions() {
      var _this = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this2 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this2.cityOptions = response.data;
        });
      }

      this.saleInfo.cityId = '';
      this.saleInfo.districtId = '';
    },
    cityChange: function cityChange(city) {
      var _this3 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this3.countryOptions = response.data;
        });
      }

      if (this.saleInfo.items[0].modelId && this.saleInfo.items[0].skuCode) {
        if (this.saleInfo.items != null && this.saleInfo.items.length > 0) {
          this.saleInfo.items.forEach(function (it, index) {
            var p2 = {
              modelId: it.modelId,
              isRetail: '1',
              tag: it.skuTag
            };
            (0, _select.getSkuSelectSale)(p2).then(function (res) {
              _this3.skuList[index] = res.data;

              if (_this3.saleInfo.isTradeIn === '1') {
                for (var i = _this3.skuList[index].length - 1; i >= 0; i--) {
                  if (!Number(_this3.skuList[index][i].trade) > 0) {
                    _this3.skuList[index].splice(i, 1);
                  }
                }
              }

              if (_this3.saleInfo.city) {
                _this3.queryStorage(index);
              }
            });
          });
        }
      }

      this.saleInfo.districtId = '';
    },
    payTypeChange: function payTypeChange(index) {
      this.describeList[index] = [];
      this.saleInfo.items[index].describes = '';

      if (this.saleInfo.items[index].payType === '01') {
        this.describeList[index] = [{
          label: '购买配件（无原因）',
          value: '01'
        }, {
          label: '配件丢失',
          value: '02'
        }, {
          label: '人为损坏',
          value: '03'
        }, {
          label: '消耗品',
          value: '04'
        }, {
          label: '其他',
          value: '10'
        }];
        this.saleInfo.items[index].priceAfterY = this.saleInfo.items[index].priceAfterYY;
        this.saleInfo.items[index].priceBeforeY = this.saleInfo.items[index].priceBeforeYY;
      } else {
        this.saleInfo.items[index].priceAfterY = 0;
        this.saleInfo.items[index].priceBeforeY = 0;
        this.describeList[index] = [{
          label: '保内损坏（非人为）',
          value: '05'
        }, {
          label: '开箱破损',
          value: '06'
        }, {
          label: '开箱缺件',
          value: '07'
        }, {
          label: '保外/特例补偿',
          value: '08'
        }, {
          label: '活动赠品补发',
          value: '09'
        }, {
          label: '其他',
          value: '10'
        }];
      }

      this.numChange();
    },
    numChange: function numChange() {
      var a = 0;
      var b = 0;
      var c = 0;
      this.saleInfo.items.forEach(function (it) {
        if (it.num === '' || it.num === undefined || it.num === null) {
          a = parseInt(a) + 0;
        } else {
          a = parseInt(a) + parseInt(it.num);

          if (it.payType === '01') {
            b = Number(b) + Number(it.priceBeforeY) * 100 * parseInt(it.num);
            c = Number(c) + Number((Number(it.priceAfterY) * 100 * parseInt(it.num)).toFixed(0));
          }
        }
      });
      this.saleInfo.amount = a;
      this.saleInfo.priceAfter = c / 100;
      this.saleInfo.priceBefore = b / 100;
    },
    removeDomain: function removeDomain(item) {
      var _this4 = this;

      if (item.num !== '' && item.num !== null && item.num !== undefined) {
        this.saleInfo.amount = parseInt(this.saleInfo.amount) - parseInt(item.num);
      }

      var index = this.saleInfo.items.indexOf(item);

      if (index !== -1) {
        this.saleInfo.items.splice(index, 1);
        this.skuList.splice(index, 1);
        this.modelSelect.splice(index, 1);
      }

      this.saleInfo.items.forEach(function (it, index) {
        if (it.payType === '01') {
          _this4.describeList[index] = [{
            label: '购买配件（无原因）',
            value: '01'
          }, {
            label: '配件丢失',
            value: '02'
          }, {
            label: '人为损坏',
            value: '03'
          }, {
            label: '消耗品',
            value: '04'
          }, {
            label: '其他',
            value: '10'
          }];
        } else {
          _this4.describeList[index] = [{
            label: '保内损坏（非人为）',
            value: '05'
          }, {
            label: '开箱破损',
            value: '06'
          }, {
            label: '开箱缺件',
            value: '07'
          }, {
            label: '保外/特例补偿',
            value: '08'
          }, {
            label: '活动赠品补发',
            value: '09'
          }, {
            label: '其他',
            value: '10'
          }];
        }
      });
    },
    addDomain: function addDomain() {
      var _this5 = this;

      if (this.saleInfo.isTradeIn === '1') {
        this.$message({
          showClose: true,
          message: '升级优惠只能选择一个SKU',
          type: 'warning'
        });
        return;
      }

      if (this.saleInfo.items !== null && this.saleInfo.items.length >= 10) {
        this.$message({
          showClose: true,
          message: '单笔订单最多购买10种商品',
          type: 'warning'
        });
        return;
      }

      this.saleInfo.items.push({
        categoryId: '',
        modelId: '',
        skuCode: '',
        brandId: '',
        skuName: '',
        skuId: '',
        num: '',
        payType: '',
        memo: '',
        describes: '',
        priceBeforeY: '',
        priceBeforeYY: '',
        priceAfterY: '',
        priceAfterYY: '',
        stock: '',
        storage: '',
        level1: '',
        level2: '',
        level3: '',
        levelStock1: '',
        levelStock2: '',
        levelStock3: '',
        src: 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
      });
      var p2 = {
        isRetail: '1'
      };
      (0, _select.getSkuSelectSale)(p2).then(function (res) {
        _this5.skuList[_this5.saleInfo.items.length - 1] = res.data;
      });
    },
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value, index) {
      this.saleInfo.items[index].categoryId = value;
    },
    // 获取维修代码详细
    getSaleInfo: function getSaleInfo() {
      var _this6 = this;

      var param = {
        id: this.id
      };

      if (this.flag === 'create' || this.flag === 'cancel') {
        param = {
          consultId: this.id
        };
      } else {// querySapStatus(this.id).then(res => {
        //   this.sapStatus = res.data
        // })
      }

      (0, _select.getCategorySelect)().then(function (res) {
        _this6.categorySelect = res.data.records;
        (0, _sale.queryById)(param).then(function (response) {
          if (response.data) {
            _this6.saleInfo = response.data;

            if (_this6.saleInfo.deliveryDate === '' || _this6.saleInfo.deliveryDate === null || _this6.saleInfo.deliveryDate === undefined) {
              _this6.boolSap = true;
            } else {
              _this6.boolSap = false;
            }

            if (_this6.saleInfo.items != null && _this6.saleInfo.items.length > 0) {
              _this6.saleInfo.items.forEach(function (it, index) {
                if (it.src === '' || it.src === null || it.src === undefined) {
                  it.src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
                }

                it.priceAfterYY = Number(it.priceAfterYY) / 100;
                it.priceBeforeYY = Number(it.priceBeforeYY) / 100;

                if (it.payType === '01') {
                  _this6.describeList[index] = [{
                    label: '购买配件（无原因）',
                    value: '01'
                  }, {
                    label: '配件丢失',
                    value: '02'
                  }, {
                    label: '人为损坏',
                    value: '03'
                  }, {
                    label: '消耗品',
                    value: '04'
                  }, {
                    label: '其他',
                    value: '10'
                  }];
                } else {
                  it.priceBeforeY = 0;
                  it.priceAfterY = 0;
                  _this6.describeList[index] = [{
                    label: '保内损坏（非人为）',
                    value: '05'
                  }, {
                    label: '开箱破损',
                    value: '06'
                  }, {
                    label: '开箱缺件',
                    value: '07'
                  }, {
                    label: '保外/特例补偿',
                    value: '08'
                  }, {
                    label: '活动赠品补发',
                    value: '09'
                  }, {
                    label: '其他',
                    value: '10'
                  }];
                }

                if (_this6.sku708.indexOf(it.skuCode) === -1) {
                  _this6.payType[index] = [{
                    label: '须收费',
                    value: '01'
                  }, {
                    label: '保内维修',
                    value: 'P4'
                  }, {
                    label: '特例免费',
                    value: '06'
                  }];
                } else {
                  (0, _sku.queryByCode)(it.skuCode).then(function (response) {
                    _this6.baseSku = response.data;

                    if (_this6.baseSku.sample === '1') {
                      _this6.payType[index] = [{
                        label: '正常收费',
                        value: '01'
                      }, {
                        label: '样品',
                        value: '09'
                      }, {
                        label: '赠品',
                        value: '07'
                      }];
                    } else {
                      _this6.payType[index] = [{
                        label: '正常收费',
                        value: '01'
                      }, {
                        label: '赠品',
                        value: '07'
                      }];
                    }
                  });
                }

                if (_this6.saleInfo.isTradeIn === '1') {
                  _this6.payType[index] = [{
                    label: '须收费',
                    value: '01'
                  }];
                }

                var p1 = {
                  categoryId: it.categoryId
                };
                (0, _select.getModelSelectSale)(p1).then(function (res) {
                  _this6.modelSelect[index] = res.data;
                });
                var p2 = {
                  modelId: it.modelId,
                  isRetail: '1',
                  tag: it.skuTag
                };
                (0, _select.getSkuSelectSale)(p2).then(function (res) {
                  _this6.skuList[index] = res.data;

                  if (_this6.saleInfo.isTradeIn === '1') {
                    for (var i = _this6.skuList[index].length - 1; i >= 0; i--) {
                      if (!Number(_this6.skuList[index][i].trade) > 0) {
                        _this6.skuList[index].splice(i, 1);
                      }
                    }
                  }

                  if (_this6.saleInfo.stateChild === 2 || _this6.saleInfo.stateChild === '2') {
                    if (_this6.saleInfo.city) {
                      _this6.queryStorage(index);
                    }
                  }
                });

                if (it.skuId === null || it.skuId === '' || it.skuId === undefined) {
                  it.priceBeforeY = '';
                  it.priceAfterY = '';
                }
              });
            }

            _this6.saleInfo.priceAfter = _this6.saleInfo.priceAfterY;
            _this6.saleInfo.priceBefore = _this6.saleInfo.priceBeforeY;

            if ((_this6.saleInfo.state === 'S1' || _this6.saleInfo.state === '') && (_this6.saleInfo.stateChild === '1' || _this6.saleInfo.stateChild === '2')) {
              _this6.isDis = false;
            } else {
              _this6.isDis = true;
            }

            if (_this6.flag === 'cancel' && (_this6.saleInfo.state === 'S1' || _this6.saleInfo.state === '') && (_this6.saleInfo.stateChild === '1' || _this6.saleInfo.stateChild === '2' || _this6.saleInfo.stateChild === '3')) {
              _this6.isCancel = true;
              _this6.isDis = true;
            }

            _this6.getProvinceOptions();

            if (_this6.isDis) {
              _this6.$refs.sfInfoForm.$emit('loadSFInfo', _this6.saleInfo.num);
            }

            if (_this6.saleInfo.province !== null && _this6.saleInfo.province !== '') {
              (0, _select.getAreasByParentId)(_this6.saleInfo.province).then(function (response) {
                _this6.cityOptions = response.data;
              });
            } // 区/县  回显


            if (_this6.saleInfo.city !== null && _this6.saleInfo.city !== '') {
              (0, _select.getAreasByParentId)(_this6.saleInfo.city).then(function (response) {
                _this6.countryOptions = response.data;
              });
            }
          }
        });
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          for (var i = 0; i < _this7.saleInfo.items.length; i++) {
            for (var j = i + 1; j < _this7.saleInfo.items.length; j++) {
              if (_this7.saleInfo.items[i].skuCode === _this7.saleInfo.items[j].skuCode) {
                _this7.$message({
                  showClose: true,
                  message: '部件:' + _this7.saleInfo.items[j].skuName + '已重复',
                  type: 'warning'
                });

                return;
              }
            }
          }

          _this7.saleInfo.items.forEach(function (it) {
            it.priceBefore = it.priceBeforeY;
            it.priceAfter = it.priceAfterY;
          });

          (0, _sale.saveSale)(_this7.saleInfo).then(function (response) {
            if (response.data) {
              _this7.$message({
                showClose: true,
                message: response.msg,
                type: 'success'
              });

              _this7.$store.dispatch('tagsView/delView', _this7.$route);

              _this7.$router.push({
                path: '/sale/sale'
              });
            } else {
              _this7.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    sendSms: function sendSms() {
      var _this8 = this;

      (0, _sale.sendSms)({
        id: this.saleInfo.id
      }).then(function (response) {
        if (response.data) {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'success'
          });

          _this8.$store.dispatch('tagsView/delView', _this8.$route);

          _this8.$router.push({
            path: '/sale/sale'
          });
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this9 = this;

      // 库位1是否满足发货数量
      this.bl1 = true; // 库位2是否满足发货数量

      this.bl2 = true; // 库位3是否满足发货数量

      this.bl3 = true; // 所选城市库位1是否设置

      var bl4 = false; // 所选城市库位2是否设置

      var bl5 = false; // 所选城市库位3是否设置

      var bl6 = false; // 库位1是否大于所选库存

      var bl7 = true; // 库位2是否大于所选库存

      var bl8 = true; // 库位3是否大于所选库存

      var bl9 = true; // 库位1

      var storage1 = ''; // 库位2

      var storage2 = ''; // 库位3

      var storage3 = ''; // 库位1是否高于预警值

      this.config1 = true; // 库位2是否高于预警值

      this.config2 = true; // 库位3是否高于预警值

      this.config3 = true; // 库位1是否高于预警值

      var num1 = true; // 库位2是否高于预警值

      var num2 = true; // 库位3是否高于预警值

      var num3 = true;
      this.$refs['dataForm'].validate(function (valid) {
        // 校验部件是否重复 及 库位 库存
        if (valid) {
          if (_this9.saleInfo.isTradeIn === '1' && _this9.saleInfo.priceBefore === 0) {
            _this9.$message({
              showClose: true,
              message: '升级优惠含税价格为0无法提交订单',
              type: 'warning'
            });

            return;
          }

          for (var i = 0; i < _this9.saleInfo.items.length; i++) {
            for (var j = i + 1; j < _this9.saleInfo.items.length; j++) {
              if (_this9.saleInfo.items[i].skuCode === _this9.saleInfo.items[j].skuCode) {
                _this9.$message({
                  showClose: true,
                  message: '部件:' + _this9.saleInfo.items[j].skuName + '已重复',
                  type: 'warning'
                });

                return;
              }
            }
          }

          _this9.storageList.forEach(function (item) {
            var _loop = function _loop(_j) {
              if (item.level === '1') {
                bl4 = true;

                if (_this9.saleInfo.items[_j].levelStock1 < _this9.saleInfo.items[_j].num) {
                  bl7 = false;
                }

                storage1 = item.storage;

                _this9.configList.forEach(function (config) {
                  if (config.id === item.storage && _this9.saleInfo.items[_j].levelStock1 < config.stock1000) {
                    num1 = false;
                  }
                });
              }

              if (item.level === '2') {
                bl5 = true;

                if (_this9.saleInfo.items[_j].levelStock2 < _this9.saleInfo.items[_j].num) {
                  bl8 = false;
                }

                storage2 = item.storage;

                _this9.configList.forEach(function (config) {
                  if (config.id === item.storage && _this9.saleInfo.items[_j].levelStock2 < config.stock1000) {
                    num2 = false;
                  }
                });
              }

              if (item.level === '3') {
                bl6 = true;

                if (_this9.saleInfo.items[_j].levelStock3 < _this9.saleInfo.items[_j].num) {
                  bl9 = false;
                }

                storage3 = item.storage;

                _this9.configList.forEach(function (config) {
                  if (config.id === item.storage && _this9.saleInfo.items[_j].levelStock3 < config.stock1000) {
                    num3 = false;
                  }
                });
              }
            };

            for (var _j = 0; _j < _this9.saleInfo.items.length; _j++) {
              _loop(_j);
            }
          });

          _this9.bl1 = bl4 && bl7;
          _this9.bl2 = bl5 && bl8;
          _this9.bl3 = bl6 && bl9;

          if (!_this9.bl1 && !_this9.bl2 && !_this9.bl3) {
            _this9.$message({
              showClose: true,
              message: '库存不够',
              type: 'warning'
            });

            return;
          }

          _this9.config1 = num1 && bl4;
          _this9.config2 = num2 && bl5;
          _this9.config3 = num3 && bl6; // 库位1库存足够且 SKU库存不低于预警值

          if (_this9.bl1 && _this9.config1) {
            for (var _j2 = 0; _j2 < _this9.saleInfo.items.length; _j2++) {
              _this9.saleInfo.items[_j2].storage = storage1;
            } // 匹配到库位


            _this9.stockFlag = true;
          } // 未匹配到库位


          if (!_this9.stockFlag) {
            // 库位2库存足够且 SKU库存不低于预警值
            if (_this9.bl2 && _this9.config2) {
              for (var _j3 = 0; _j3 < _this9.saleInfo.items.length; _j3++) {
                _this9.saleInfo.items[_j3].storage = storage2;
              } // 匹配到库位


              _this9.stockFlag = true;
            }
          } // 未匹配到库位


          if (!_this9.stockFlag) {
            // 库位3库存足够且 SKU库存不低于预警值
            if (_this9.bl3 && _this9.config3) {
              for (var _j4 = 0; _j4 < _this9.saleInfo.items.length; _j4++) {
                _this9.saleInfo.items[_j4].storage = storage3;
              } // 匹配到库位


              _this9.stockFlag = true;
            }
          } // 未匹配到库位


          if (!_this9.stockFlag) {
            // 库位1库存足够
            if (_this9.bl1) {
              for (var _j5 = 0; _j5 < _this9.saleInfo.items.length; _j5++) {
                _this9.saleInfo.items[_j5].storage = storage1;
              } // 匹配到库位


              _this9.stockFlag = true; // SKU库存低于预警值

              if (!_this9.config1) {
                _this9.configFlag = true;
              }
            }
          } // 未匹配到库位


          if (!_this9.stockFlag) {
            // 库位2库存足够
            if (_this9.bl2) {
              for (var _j6 = 0; _j6 < _this9.saleInfo.items.length; _j6++) {
                _this9.saleInfo.items[_j6].storage = storage2;
              } // 匹配到库位


              _this9.stockFlag = true; // SKU库存低于预警值

              if (!_this9.config2) {
                _this9.configFlag = true;
              }
            }
          } // 未匹配到库位


          if (!_this9.stockFlag) {
            // 库位3库存足够
            if (_this9.bl3) {
              for (var _j7 = 0; _j7 < _this9.saleInfo.items.length; _j7++) {
                _this9.saleInfo.items[_j7].storage = storage3;
              } // 匹配到库位


              _this9.stockFlag = true; // SKU库存低于预警值

              if (!_this9.config3) {
                _this9.configFlag = true;
              }
            }
          }

          _this9.saleInfo.items.forEach(function (it) {
            it.priceBefore = it.priceBeforeY;
            it.priceAfter = it.priceAfterY;
          });

          var storageLeven = ''; // SKU库存低于预警值

          if (_this9.configFlag) {
            _this9.saleInfo.items.forEach(function (item) {
              _this9.configList.forEach(function (config) {
                storageLeven = '';

                if (config.id === item.storage) {
                  _this9.storageList.forEach(function (storage) {
                    if (storage.storage === config.id) {
                      storageLeven = 'levelStock' + storage.level;
                    }
                  });

                  Object.keys(item).forEach(function (key) {
                    if (storageLeven === key) {
                      if (item[key] < config.stock1000) {
                        _this9.skuStockList.push(item.skuCode);
                      }
                    }
                  });
                }
              });
            });

            _this9.dialog10Msg = _this9.skuStockList + '库存已达到预警值是否确认下单？';
            _this9.dialogVisible10 = true;
          } else {
            _this9.dialogVisible = true;
          }
        }
      });
    },
    updateCancel: function updateCancel() {
      this.dialogVisible2 = true;
    },
    comfirmB: function comfirmB() {
      var _this10 = this;

      this.$refs['dataForm2'].validate(function (valid) {
        _this10.saleInfo.cancelReason = _this10.saleInfo2.cancelReason;
        _this10.saleInfo.cancelMemo = _this10.saleInfo2.cancelMemo;
        (0, _sale.updateSaleCancel)(_this10.saleInfo).then(function (response) {
          if (response.data) {
            _this10.dialogVisible8 = true;
            _this10.dialogVisible2 = false;
            _this10.dialog8Msg = response.msg; // this.$message({ showClose: true, message: response.msg, type: 'success' })
            // this.$store.dispatch('tagsView/delView', this.$route)
            // this.$router.push({ name: 'consultOrderDetail', params: { id: this.$route.params.callId, isQuery: '0' }})
          } else {
            _this10.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
      });
    },
    comfirmTo: function comfirmTo() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'consultOrderDetail',
        params: {
          id: this.$route.params.callId,
          isQuery: '0'
        }
      });
    },
    comfirmToOrder: function comfirmToOrder() {
      var _this11 = this;

      this.completeBtn = true;
      (0, _sale.complete)(this.saleInfo).then(function (response) {
        if (response.data) {
          _this11.$message({
            showClose: true,
            message: response.msg,
            type: 'success'
          });

          _this11.$store.dispatch('tagsView/delView', _this11.$route);

          _this11.$router.push({
            path: '/sale/sale'
          });
        } else {
          _this11.completeBtn = false;

          _this11.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);

      if (this.flag === 'cancel') {
        this.$router.push({
          name: 'consultOrderDetail',
          params: {
            id: this.$route.params.callId,
            isQuery: '0'
          }
        });
      } else {
        this.$router.push({
          path: '/sale/sale'
        });
      }
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this12 = this;

      var params = {}; // 品类下拉
      // getCategorySelect(params).then(res => {
      //   this.categorySelect = res.data.records
      // })
      // 品牌下拉

      (0, _select.getBrandSelect)(params).then(function (res) {
        _this12.brandSelect = res.data.records;
      }); // // sku下拉
      // getSkuSelect({ isRetail: '1' }).then(res => {
      //   this.skuList = res.data
      // })
      // // 型号下拉
      // getModelSelect(params).then(res => {
      //   this.modelSelect = res.data.records
      // })

      (0, _list.getConfigList)().then(function (response) {
        _this12.configList = response.data;
      });
    },
    tagChange: function tagChange(index) {
      var _this13 = this;

      this.saleInfo.items[index].skuId = '';
      this.saleInfo.items[index].skuCode = '';
      this.saleInfo.items[index].skuName = '';
      this.saleInfo.items[index].priceAfterY = '';
      this.saleInfo.items[index].priceBeforeY = '';
      this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
      this.saleInfo.items[index].stock = '';
      var params = {
        isRetail: '1',
        tag: this.saleInfo.items[index].skuTag,
        modelId: this.saleInfo.items[index].modelId,
        categoryId: this.saleInfo.items[index].modelId.categoryId
      };
      (0, _select.getSkuSelectSale)(params).then(function (res) {
        _this13.skuList[index] = res.data;

        if (_this13.saleInfo.isTradeIn === '1') {
          for (var i = _this13.skuList[index].length - 1; i >= 0; i--) {
            if (!Number(_this13.skuList[index][i].trade) > 0) {
              _this13.skuList[index].splice(i, 1);
            }
          }
        }
      });
      this.numChange();
    },
    categoryChange: function categoryChange(value, index) {
      var _this14 = this;

      this.saleInfo.items[index].skuId = '';
      this.saleInfo.items[index].skuCode = '';
      this.saleInfo.items[index].skuName = '';
      this.saleInfo.items[index].priceAfterY = '';
      this.saleInfo.items[index].priceBeforeY = '';
      this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
      this.saleInfo.items[index].brandId = '';
      this.saleInfo.items[index].stock = '';
      this.saleInfo.items[index].modelId = '';
      var params = {
        categoryId: this.saleInfo.items[index].categoryId
      };
      (0, _select.getModelSelectSale)(params).then(function (res) {
        _this14.modelSelect[index] = res.data;
      });
      var skuParams = {
        categoryId: this.saleInfo.items[index].categoryId,
        isRetail: '1',
        tag: this.saleInfo.items[index].skuTag
      };
      (0, _select.getSkuSelectSale)(skuParams).then(function (res) {
        _this14.skuList[index] = res.data;

        if (_this14.saleInfo.isTradeIn === '1') {
          for (var i = _this14.skuList[index].length - 1; i >= 0; i--) {
            if (!Number(_this14.skuList[index][i].trade) > 0) {
              _this14.skuList[index].splice(i, 1);
            }
          }
        }
      });
      this.numChange();
    },
    modelChange: function modelChange(index) {
      var _this15 = this;

      this.modelSelect[index].find(function (i) {
        if (i.id === _this15.saleInfo.items[index].modelId) {
          _this15.saleInfo.items[index].brandId = i.brandId;
        }
      });
      this.saleInfo.items[index].skuId = '';
      this.saleInfo.items[index].skuCode = '';
      this.saleInfo.items[index].skuName = '';
      this.saleInfo.items[index].priceAfterY = '';
      this.saleInfo.items[index].priceBeforeY = '';
      this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
      this.saleInfo.items[index].stock = '';
      var params = {
        modelId: this.saleInfo.items[index].modelId,
        isRetail: '1',
        tag: this.saleInfo.items[index].skuTag
      };
      (0, _select.getSkuSelectSale)(params).then(function (res) {
        _this15.skuList[index] = res.data;

        if (_this15.saleInfo.isTradeIn === '1') {
          for (var i = _this15.skuList[index].length - 1; i >= 0; i--) {
            if (!Number(_this15.skuList[index][i].trade) > 0) {
              _this15.skuList[index].splice(i, 1);
            }
          }
        }
      });
      this.numChange();
    },
    skuChange: function skuChange(index) {
      // this.skuList[index].find((i) => {
      //   if (i.id === this.saleInfo.items[index].skuId) {
      //     this.payType[index] = []
      //     this.saleInfo.items[index].payType = ''
      //     if (this.sku708.indexOf(i.code) === -1) {
      //       this.payType[index] = [
      //         { label: '须收费', value: '01' },
      //         { label: '保内维修', value: 'P4' },
      //         { label: '特例免费', value: '06' }
      //       ]
      //     } else {
      //       if (i.sample === '1') {
      //         this.payType[index] = [
      //           { label: '正常收费', value: '01' },
      //           { label: '样品', value: '09' },
      //           { label: '赠品', value: '07' }
      //         ]
      //       } else {
      //         this.payType[index] = [
      //           { label: '正常收费', value: '01' },
      //           { label: '赠品', value: '07' }
      //         ]
      //       }
      //     }
      //     this.saleInfo.items[index].priceBeforeY = Number(i.priceIncludeTax) / 100
      //     this.saleInfo.items[index].priceAfterY = Number((Number(i.priceIncludeTax) / 113).toFixed(2))
      //     this.saleInfo.items[index].priceBeforeYY = Number(i.priceIncludeTax) / 100
      //     this.saleInfo.items[index].priceAfterYY = Number((Number(i.priceIncludeTax) / 113).toFixed(2))
      //     this.saleInfo.items[index].stock = i.ddirStock
      //     this.saleInfo.items[index].skuName = i.name
      //     this.saleInfo.items[index].skuCode = i.code
      //     if (i.picturePath !== null && i.picturePath !== undefined && i.picturePath !== '') {
      //       this.saleInfo.items[index].src = i.picturePath
      //     } else {
      //       this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
      if (!this.saleInfo.city) {
        this.$message({
          showClose: true,
          message: '请先选择城市',
          type: 'warning'
        });
        this.saleInfo.items[index].skuId = '';
        return;
      }

      this.queryStorage(index);
    },
    queryStorage: function queryStorage(index) {
      var _this16 = this;

      // 查询库位
      (0, _list.codeList)(this.saleInfo.city).then(function (res) {
        _this16.storageList = res.data;

        if (_this16.storageList.length === 0) {
          _this16.$message({
            showClose: true,
            message: '发货库位未维护',
            type: 'warning'
          });

          _this16.saleInfo.items[index].skuId = '';
          _this16.saleInfo.items[index].priceBeforeY = '';
          _this16.saleInfo.items[index].priceAfterY = '';
          _this16.saleInfo.items[index].priceBeforeYY = '';
          _this16.saleInfo.items[index].priceAfterYY = '';
          _this16.saleInfo.items[index].stock = '';
          _this16.saleInfo.items[index].skuName = '';
          _this16.saleInfo.items[index].skuCode = '';
          _this16.saleInfo.items[index].level1 = '';
          _this16.saleInfo.items[index].level2 = '';
          _this16.saleInfo.items[index].level3 = '';
          return;
        }

        _this16.levelLength = _this16.storageList.length;

        _this16.skuList[index].find(function (i) {
          if (i.id === _this16.saleInfo.items[index].skuId) {
            if (_this16.sku708.indexOf(i.code) === -1) {
              _this16.payType[index] = [{
                label: '须收费',
                value: '01'
              }, {
                label: '保内维修',
                value: 'P4'
              }, {
                label: '特例免费',
                value: '06'
              }];
            } else {
              if (i.sample === '1') {
                _this16.payType[index] = [{
                  label: '正常收费',
                  value: '01'
                }, {
                  label: '样品',
                  value: '09'
                }, {
                  label: '赠品',
                  value: '07'
                }];
              } else {
                _this16.payType[index] = [{
                  label: '正常收费',
                  value: '01'
                }, {
                  label: '赠品',
                  value: '07'
                }];
              }
            }

            if (_this16.saleInfo.isTradeIn === '1') {
              _this16.payType[index] = [{
                label: '须收费',
                value: '01'
              }];
            }

            _this16.saleInfo.items[index].priceBeforeY = Number(i.priceIncludeTax) / 100;
            _this16.saleInfo.items[index].priceAfterY = Number((Number(i.priceIncludeTax) / 113).toFixed(2));
            _this16.saleInfo.items[index].priceBeforeYY = Number(i.priceIncludeTax) / 100;
            _this16.saleInfo.items[index].priceAfterYY = Number((Number(i.priceIncludeTax) / 113).toFixed(2));

            if (_this16.saleInfo.isTradeIn === '1' && i.skuTag === '1') {
              _this16.saleInfo.items[index].priceBeforeY = Number(i.trade) / 100;
              _this16.saleInfo.items[index].priceAfterY = Number((Number(i.trade) / 113).toFixed(2));
              _this16.saleInfo.items[index].priceBeforeYY = Number(i.trade) / 100;
              _this16.saleInfo.items[index].priceAfterYY = Number((Number(i.trade) / 113).toFixed(2));
            }

            _this16.saleInfo.items[index].stock = i.ddirStock;
            _this16.saleInfo.items[index].skuName = i.name;
            _this16.saleInfo.items[index].skuCode = i.code;

            _this16.storageList.forEach(function (it) {
              if (it.level === '1') {
                Object.keys(i).forEach(function (key) {
                  if ('thousand' + it.storage === key) {
                    _this16.saleInfo.items[index].level1 = i[key] ? '【' + it.storage + '】：' + i[key] : '【' + it.storage + '】：' + 0;
                    _this16.saleInfo.items[index].levelStock1 = i[key] ? i[key] : 0;
                  }
                });
              }

              if (it.level === '2') {
                Object.keys(i).forEach(function (key) {
                  if ('thousand' + it.storage === key) {
                    _this16.saleInfo.items[index].level2 = i[key] ? '【' + it.storage + '】：' + i[key] : '【' + it.storage + '】：' + 0;
                    _this16.saleInfo.items[index].levelStock2 = i[key] ? i[key] : 0;
                  }
                });
              }

              if (it.level === '3') {
                Object.keys(i).forEach(function (key) {
                  if ('thousand' + it.storage === key) {
                    _this16.saleInfo.items[index].level3 = i[key] ? '【' + it.storage + '】：' + i[key] : '【' + it.storage + '】：' + 0;
                    _this16.saleInfo.items[index].levelStock3 = i[key] ? i[key] : 0;
                  }
                });
              }
            });

            if (i.picturePath !== null && i.picturePath !== undefined && i.picturePath !== '') {
              _this16.saleInfo.items[index].src = i.picturePath;
            } else {
              _this16.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
            }

            _this16.numChange();
          }
        });
      });
    },
    orderCancel: function orderCancel() {
      this.configFlag = false;
      this.stockFlag = false;
      this.skuStockList = [];
      this.dialog10Msg = '';
      this.dialogVisible10 = false;
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;