"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _skuFee = require("@/api/basics/skuFee/skuFee");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '1': 'success',
        '0': 'danger',
        undefined: 'info'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    // 自定义校验：金额位数小于千万
    var validateNumberMax = function validateNumberMax(rule, value, callback) {
      if (value && value !== '' && parseInt(value) > 9999999) {
        callback('限制数值小于10000000');
      } else {
        callback();
      }
    };

    return {
      btnLoading: false,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      applyRightsOptions: [{
        label: '免费清洁服务',
        value: '1'
      }],
      // 只读模式
      isReadOnly: this.$route.params.isReadOnly || this.$store.state.user.pCodes.indexOf('ROLE_BASE_SKU_FEE_ADD') < 0 && this.$store.state.user.pCodes.indexOf('ROLE_BASE_SKU_FEE_MODIFY') < 0,
      // ----------------------------------------------------------------------------------------------------------------------------------
      baseSkuFee: {
        id: '',
        code: '',
        name: '',
        priceExcludeTax: undefined,
        priceIncludeTax: undefined,
        costYuan: undefined,
        priceExcludeTaxYuan: undefined,
        priceIncludeTaxYuan: undefined,
        isEnable: '1',
        memo: '',
        applyRights: undefined
      },
      rules: {
        code: [{
          required: true,
          message: '服务SKU编码是必填的',
          trigger: 'blur'
        }, {
          max: 30,
          message: '最大长度 30 个字符',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '服务SKU名称是必填的',
          trigger: 'blur'
        }, {
          max: 100,
          message: '最大长度 100 个字符',
          trigger: 'blur'
        }],
        memo: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }],
        isEnable: [{
          required: true,
          message: '状态是必填的',
          trigger: 'blur'
        }],
        priceExcludeTaxYuan: [{
          required: true,
          message: '零售不含税指导价是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        priceIncludeTaxYuan: [{
          required: true,
          message: '零售含税指导价是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        taxationCode: [{
          required: true,
          message: '税务编码是必填的',
          trigger: 'blur'
        }, {
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }]
      } // ----------------------------------------------------------------------------------------------------------------------------------

    };
  },
  created: function created() {
    if (!this.isCreate) {
      this.initBaseSkuFee();
    }
  },
  methods: {
    initBaseSkuFee: function initBaseSkuFee() {
      var _this = this;

      (0, _skuFee.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.baseSkuFee = response.data;
          _this.list4Model = _this.baseSkuFee.modelList;
        }
      });
    },
    saveSumbit: function saveSumbit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          _this2.baseSkuFee.priceExcludeTax = _this2.baseSkuFee.priceExcludeTaxYuan * 100;
          _this2.baseSkuFee.priceIncludeTax = _this2.baseSkuFee.priceIncludeTaxYuan * 100;
          (0, _skuFee.saveData)(_this2.baseSkuFee).then(function (res) {
            if (res.data) {
              _this2.$message({
                showClose: true,
                message: '保存成功',
                type: 'success'
              });

              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                path: '/basics/skuFee/list'
              });
            } else {
              _this2.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              });
            }

            _this2.btnLoading = false;
          });
        } else {
          _this2.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    } // -------------------------------------------------------------------------------------------------------------------------------------------

  }
};
exports.default = _default;