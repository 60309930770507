"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.queryById = queryById;
exports.registerProduct = registerProduct;
exports.unBundling = unBundling;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/product_register/getPageList',
    method: 'post',
    params: query
  });
}
/** 查询产品注册详情*/


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/product_register/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 产品注册履历*/


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/product_app/product_register_history/getHistoryList',
    method: 'post',
    params: query
  });
}
/** 解绑*/


function unBundling(id) {
  return (0, _request.default)({
    url: '/product_app/product_register/unBundling',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** csrm 产品注册 */


function registerProduct(data) {
  return (0, _request.default)({
    url: '/product_app/product_register/productRegister',
    method: 'post',
    data: data
  });
}