var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDetail
    ? _c(
        "div",
        { staticStyle: { "min-width": "280px", "max-width": "970px" } },
        [
          _c(
            "div",
            { staticClass: "top-buttons-group" },
            [
              _c(
                "FixTop",
                { attrs: { "class-name": "sub-navbar default" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit",
                        },
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.changeOrder },
                    },
                    [_vm._v("转工单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit",
                        },
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.finishOrder },
                    },
                    [_vm._v("完结咨询单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.returnBtn },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.consultOrderInfo,
                rules: _vm.rules,
                "label-width": "120px",
                "label-position": "right",
                size: "small",
              },
            },
            [
              _c(
                "div",
                { staticClass: "el-row" },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("\n          CRM查询条件\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "手机号" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "filter-item",
                                      attrs: {
                                        type: "primary",
                                        placeholder: "手机号",
                                        disabled: _vm.isSearch === false,
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.crmPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "crmPhone",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.crmPhone",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isEdit && _vm.isSearch,
                                              expression: "isEdit && isSearch",
                                            },
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.btnLoading,
                                              expression: "btnLoading",
                                            },
                                          ],
                                          attrs: {
                                            slot: "append",
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-search",
                                          },
                                          on: { click: _vm.queryCusAndProd },
                                          slot: "append",
                                        },
                                        [_vm._v("查询")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.isEdit &&
                                                _vm.isSearch === false &&
                                                _vm.consultOrderInfo.state ===
                                                  "00",
                                              expression:
                                                "isEdit && (isSearch===false && consultOrderInfo.state==='00')",
                                            },
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.btnLoading,
                                              expression: "btnLoading",
                                            },
                                          ],
                                          attrs: {
                                            slot: "append",
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-delete",
                                          },
                                          on: { click: _vm.clearSearch },
                                          slot: "append",
                                        },
                                        [_vm._v("清空")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("\n          机主信息\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "customerForm",
                          staticStyle: { "margin-left": "5px" },
                          attrs: {
                            rules: _vm.cusRules,
                            model: _vm.consultOrderCustomerInfo,
                            "label-position": "right",
                            "label-width": "120px",
                            size: "small",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "10px" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "姓名", prop: "name" } },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "手机号",
                                        prop: "mobile",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo.mobile,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "mobile",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.mobile",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "性别", prop: "gender" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo.gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.gender",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "地区",
                                        prop: "districtDetail",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo
                                              .districtDetail,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "districtDetail",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.districtDetail",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "详细地址",
                                        prop: "detailAddress",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo
                                              .detailAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "detailAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.detailAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户级别",
                                        prop: "proMember",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderCustomerInfo
                                              .proMember,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderCustomerInfo,
                                              "proMember",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderCustomerInfo.proMember",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _vm._v(
                            "\n          注册产品信息（" +
                              _vm._s(_vm.CustomerProductList.length) +
                              "个）\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.isEdit &&
                                _vm.consultOrderInfo.state === "00",
                              expression:
                                "isEdit && consultOrderInfo.state==='00'",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    type: "primary",
                                    placeholder: "产品型号名称/SN",
                                  },
                                  model: {
                                    value: _vm.productSearch,
                                    callback: function ($$v) {
                                      _vm.productSearch = $$v
                                    },
                                    expression: "productSearch",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "append",
                                        size: "small",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.queryCustomerProduct },
                                      slot: "append",
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse",
                        {
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.activeNames,
                            callback: function ($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames",
                          },
                        },
                        _vm._l(_vm.CustomerProductList, function (item, index) {
                          return _c(
                            "el-collapse-item",
                            {
                              key: index,
                              attrs: { title: item.prdName, name: index },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { name: "" },
                                  model: {
                                    value: item.choose,
                                    callback: function ($$v) {
                                      _vm.$set(item, "choose", $$v)
                                    },
                                    expression: "item.choose",
                                  },
                                },
                                [_vm._v("是否选择此产品")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品名称",
                                        prop: "productName",
                                        "show-overflow-tooltip": "",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.productName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "productName", $$v)
                                          },
                                          expression: "item.productName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品型号",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.modelName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "modelName", $$v)
                                          },
                                          expression: "item.modelName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品SKU",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.sku,
                                          callback: function ($$v) {
                                            _vm.$set(item, "sku", $$v)
                                          },
                                          expression: "item.sku",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品SN",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.serialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(item, "serialNumber", $$v)
                                          },
                                          expression: "item.serialNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "购买日期",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.purchaseDate,
                                          callback: function ($$v) {
                                            _vm.$set(item, "purchaseDate", $$v)
                                          },
                                          expression: "item.purchaseDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "购买渠道",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.salesChannel,
                                          callback: function ($$v) {
                                            _vm.$set(item, "salesChannel", $$v)
                                          },
                                          expression: "item.salesChannel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "注册日期",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.registerDate,
                                          callback: function ($$v) {
                                            _vm.$set(item, "registerDate", $$v)
                                          },
                                          expression: "item.registerDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品90天权益",
                                        prop: "bookRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.rights,
                                          callback: function ($$v) {
                                            _vm.$set(item, "rights", $$v)
                                          },
                                          expression: "item.rights",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("咨询内容")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "20px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "会话ID", prop: "sessionId" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.consultOrderInfo.sessionId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "sessionId",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.sessionId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "咨询单号", prop: "num" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.consultOrderInfo.num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "num",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "创建人", prop: "inRmkName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.consultOrderInfo.inRmkName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "inRmkName",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.inRmkName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "创建时间",
                                    prop: "inDateFM",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.consultOrderInfo.inDateFM,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "inDateFM",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.inDateFM",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEdit,
                                      expression: "!isEdit",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "主状态", prop: "state" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            disabled: "",
                                            placeholder: "请选择状态",
                                          },
                                          model: {
                                            value: _vm.consultOrderInfo.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.state",
                                          },
                                        },
                                        _vm._l(
                                          _vm.stateOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "子状态",
                                        prop: "subState",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            disabled: "",
                                            clearable: "",
                                            placeholder: "请选择子状态",
                                          },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.subState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "subState",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.subState",
                                          },
                                        },
                                        _vm._l(
                                          _vm.subStateOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "报修人姓名",
                                    prop: "applicantMan",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: !_vm.isEdit },
                                    model: {
                                      value: _vm.consultOrderInfo.applicantMan,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "applicantMan",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.applicantMan",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "报修人电话",
                                    prop: "applicantTel",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: !_vm.isEdit },
                                    model: {
                                      value: _vm.consultOrderInfo.applicantTel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "applicantTel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.applicantTel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "业务类型", prop: "type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: !_vm.isEdit,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择业务类型",
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.typeOptions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.val,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "咨询单一级归类",
                                    prop: "oneLevel",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: !_vm.isEdit,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.oneLevelChange(
                                            _vm.consultOrderInfo.oneLevel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.oneLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "oneLevel",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.oneLevel",
                                      },
                                    },
                                    _vm._l(
                                      _vm.oneLevelSelect,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                            disabled: item.isEnable !== "1",
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "咨询单二级归类",
                                    prop: "twoLevel",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: !_vm.isEdit,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "咨询单二级归类",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.twoLevelChange(
                                            _vm.consultOrderInfo.twoLevel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.twoLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "twoLevel",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.twoLevel",
                                      },
                                    },
                                    _vm._l(
                                      _vm.twoLevelSelect,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                            disabled: item.isEnable !== "1",
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "咨询单三级归类",
                                    prop: "threeLevel",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: !_vm.isEdit,
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "咨询单三级归类",
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.threeLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "threeLevel",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.threeLevel",
                                      },
                                    },
                                    _vm._l(
                                      _vm.threeLevelSelect,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                            disabled: item.isEnable !== "1",
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "用户描述",
                                    prop: "userDescribe",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      disabled: !_vm.isEdit,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.userDescribe,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "userDescribe",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.userDescribe",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "自助服务描述",
                                    prop: "beforeMemo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      disabled: !_vm.isEdit,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.beforeMemo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "beforeMemo",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.beforeMemo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "问题出现前/后",
                                    prop: "afterMemo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      disabled: !_vm.isEdit,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.afterMemo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "afterMemo",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.afterMemo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Resolve方案",
                                    prop: "resolveMemo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      disabled: !_vm.isEdit,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.resolveMemo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "resolveMemo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.resolveMemo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      disabled: !_vm.isEdit,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.memo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "memo",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEdit,
                          expression: "!isEdit",
                        },
                      ],
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("  转单信息 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.changeActiveNames,
                                callback: function ($$v) {
                                  _vm.changeActiveNames = $$v
                                },
                                expression: "changeActiveNames",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "转单信息", name: "1" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工单类型",
                                        prop: "applicantMan",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { disabled: true },
                                          model: {
                                            value: _vm.consultOrderInfo.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "consultOrderInfo.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeOptions,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.val,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工单号",
                                        prop: "applicantTel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.consultOrderInfo.bizNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "bizNum",
                                              $$v
                                            )
                                          },
                                          expression: "consultOrderInfo.bizNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "是否上门取件",
                                        prop: "applicantMan",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.consultOrderInfo.postType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "postType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.postType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预约快递公司",
                                        prop: "applicantTel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.consultOrderInfo.express,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "express",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.express",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "快递单号",
                                        prop: "applicantMan",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.consultOrderInfo.expressNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "expressNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.expressNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEdit,
                          expression: "!isEdit",
                        },
                      ],
                      staticClass: "box-card",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { shadow: "hover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("  运单记录 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.expressActiveNames,
                                callback: function ($$v) {
                                  _vm.expressActiveNames = $$v
                                },
                                expression: "expressActiveNames",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "运单状态记录", name: "1" } },
                                _vm._l(
                                  _vm.expressStateInfoList,
                                  function (item, index) {
                                    return _c(
                                      "el-row",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "border-bottom": "1px solid #e6ebf5",
                                          padding: "5px",
                                        },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "left",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.orderStateCode)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 13 } }, [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "【" +
                                                    _vm._s(item.empCode) +
                                                    "】  " +
                                                    _vm._s(item.empPhone) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(_vm._s(item.orderStateDesc)),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.inDateFM))]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "运单路由", name: "2" } },
                                _vm._l(
                                  _vm.expressRouteInfoList,
                                  function (item, index) {
                                    return _c(
                                      "el-row",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "border-bottom": "1px solid #e6ebf5",
                                          padding: "5px",
                                        },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "left",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatExpState")(
                                                    item.expState
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 13 } }, [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "【" +
                                                    _vm._s(item.opeTitle) +
                                                    "】  " +
                                                    _vm._s(item.acceptAddress)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(_vm._s(item.opeRemark)),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.opeTime))]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                visible: _vm.dialogVisible,
                width: "80%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.dialogMsg))]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.comfirmToOrder(_vm.dialogType)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                visible: _vm.dialogVisible1,
                width: "80%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("span", [_vm._v("请确认是否已解决客户问题")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.comfirmAgain("1")
                        },
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.comfirmAgain("0")
                        },
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm.isDetail
    ? _c(
        "div",
        { staticStyle: { "min-width": "280px", "max-width": "970px" } },
        [
          _c(
            "div",
            { staticClass: "top-buttons-group" },
            [
              _c(
                "FixTop",
                { attrs: { "class-name": "sub-navbar default" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.returnBtn },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.consultOrderInfo,
                rules: _vm.rules,
                "label-width": "130px",
                "label-position": "right",
                size: "small",
              },
            },
            [
              _c("div", { staticClass: "el-row" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-left": "10px",
                      "padding-right": "10px",
                    },
                  },
                  [
                    _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.detailActive,
                          callback: function ($$v) {
                            _vm.detailActive = $$v
                          },
                          expression: "detailActive",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          { attrs: { title: "咨询内容", name: "1" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "20px" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "会话ID",
                                          prop: "sessionId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.sessionId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "sessionId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.sessionId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "咨询单号",
                                          prop: "num",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.consultOrderInfo.num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "num",
                                                $$v
                                              )
                                            },
                                            expression: "consultOrderInfo.num",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "创建人",
                                          prop: "inRmkName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.inRmkName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "inRmkName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.inRmkName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "创建时间",
                                          prop: "inDateFM",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.inDateFM,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "inDateFM",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.inDateFM",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "主状态",
                                          prop: "state",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              disabled: "",
                                              placeholder: "请选择状态",
                                            },
                                            model: {
                                              value: _vm.consultOrderInfo.state,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.consultOrderInfo,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "consultOrderInfo.state",
                                            },
                                          },
                                          _vm._l(
                                            _vm.stateOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "子状态",
                                          prop: "subState",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              disabled: "",
                                              clearable: "",
                                              placeholder: "请选择子状态",
                                            },
                                            model: {
                                              value:
                                                _vm.consultOrderInfo.subState,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.consultOrderInfo,
                                                  "subState",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "consultOrderInfo.subState",
                                            },
                                          },
                                          _vm._l(
                                            _vm.subStateOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "报修人姓名",
                                          prop: "applicantMan",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.applicantMan,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "applicantMan",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.applicantMan",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "报修人电话",
                                          prop: "applicantTel",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.applicantTel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "applicantTel",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.applicantTel",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "业务类型",
                                          prop: "type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: "",
                                              filterable: "",
                                              clearable: "",
                                              placeholder: "请选择业务类型",
                                            },
                                            model: {
                                              value: _vm.consultOrderInfo.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.consultOrderInfo,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "consultOrderInfo.type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.typeOptions,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "用户描述",
                                          prop: "userDescribe",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 2,
                                            disabled: "",
                                          },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.userDescribe,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "userDescribe",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.userDescribe",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "自助服务描述",
                                          prop: "beforeMemo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 2,
                                            disabled: "",
                                          },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.beforeMemo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "beforeMemo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.beforeMemo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "问题出现前/后",
                                          prop: "afterMemo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 2,
                                            disabled: "",
                                          },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.afterMemo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "afterMemo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.afterMemo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Resolve方案",
                                          prop: "resolveMemo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 2,
                                            disabled: "",
                                          },
                                          model: {
                                            value:
                                              _vm.consultOrderInfo.resolveMemo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "resolveMemo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderInfo.resolveMemo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "备注", prop: "memo" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 2,
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.consultOrderInfo.memo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderInfo,
                                                "memo",
                                                $$v
                                              )
                                            },
                                            expression: "consultOrderInfo.memo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-collapse-item",
                          { attrs: { title: "机主信息", name: "3" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "padding-top": "20px",
                                  "padding-left": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-collapse",
                                  {
                                    model: {
                                      value: _vm.cusActiveNames,
                                      callback: function ($$v) {
                                        _vm.cusActiveNames = $$v
                                      },
                                      expression: "cusActiveNames",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-collapse-item",
                                      {
                                        attrs: { title: "机主信息", name: "1" },
                                      },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "customerForm",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              rules: _vm.cusRules,
                                              model:
                                                _vm.consultOrderCustomerInfo,
                                              "label-position": "right",
                                              "label-width": "120px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-top": "20px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "姓名",
                                                          prop: "name",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.name",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "手机号",
                                                          prop: "mobile",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .mobile,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "mobile",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.mobile",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "性别",
                                                          prop: "gender",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .gender,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "gender",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.gender",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "地区",
                                                          prop: "districtDetail",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .districtDetail,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "districtDetail",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.districtDetail",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "详细地址",
                                                          prop: "detailAddress",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .detailAddress,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "detailAddress",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.detailAddress",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "客户级别",
                                                          prop: "proMember",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .consultOrderCustomerInfo
                                                                .proMember,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.consultOrderCustomerInfo,
                                                                "proMember",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "consultOrderCustomerInfo.proMember",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-collapse-item",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.CustomerProductInfo !==
                                                null &&
                                              _vm.CustomerProductInfo.sku !=
                                                null,
                                            expression:
                                              "CustomerProductInfo!==null && CustomerProductInfo.sku!=null",
                                          },
                                        ],
                                        attrs: {
                                          title: "注册产品信息",
                                          name: "2",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "productForm",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              rules: _vm.proRules,
                                              model: _vm.CustomerProductInfo,
                                              "label-position": "right",
                                              "label-width": "120px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "产品名称",
                                                      prop: "productName",
                                                      "show-overflow-tooltip":
                                                        "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .productName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "productName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.productName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "产品型号",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .modelName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "modelName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.modelName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "产品SKU",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .sku,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "sku",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.sku",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "产品SN",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .serialNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "serialNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.serialNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "购买日期",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .purchaseDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "purchaseDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.purchaseDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "购买渠道",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .salesChannel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "salesChannel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.salesChannel",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "注册日期",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .registerDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "registerDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.registerDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "产品90天权益",
                                                      prop: "bookRemark",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CustomerProductInfo
                                                            .rights,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CustomerProductInfo,
                                                            "rights",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CustomerProductInfo.rights",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }