"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.getSkuBySn = getSkuBySn;
exports.getWarrantyDate = getWarrantyDate;
exports.queryById = queryById;
exports.queryBySn = queryBySn;
exports.unlock = unlock;
exports.updateBenefitDate = updateBenefitDate;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/product_app/product/getPageList',
    method: 'post',
    params: query
  });
}
/** 产品履历查询*/


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/product_app/product_history/getHistoryList',
    method: 'post',
    params: query
  });
}
/** 查询产品详情*/


function queryById(id) {
  return (0, _request.default)({
    url: '/product_app/product/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 根据sn查询产品详情*/


function queryBySn(sn) {
  return (0, _request.default)({
    url: '/product_app/product/queryBySn',
    method: 'post',
    params: {
      sn: sn
    }
  });
}
/** 解锁*/


function unlock(sn) {
  return (0, _request.default)({
    url: '/product_app/product/unlock',
    method: 'post',
    params: {
      sn: sn
    }
  });
}
/** 根据sn查询SKuInfo*/


function getSkuBySn(sn, modelId, category) {
  return (0, _request.default)({
    url: '/product_app/product/getSkuBySn',
    method: 'post',
    params: {
      sn: sn,
      modelId: modelId,
      category: category
    }
  });
}
/** 根据sn查询产品详情*/


function getWarrantyDate(sn) {
  return (0, _request.default)({
    url: '/product_app/product/getWarrantyDate',
    method: 'post',
    params: {
      sn: sn
    }
  });
}
/**
 * 延保
 * @param data
 */


function updateBenefitDate(data) {
  return (0, _request.default)({
    url: '/product_app/product/updateBenefitDate',
    method: 'post',
    data: data
  });
}