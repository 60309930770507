var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "终验信息" },
    [
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("终验信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "终验结果" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.verifyResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "verifyResult", $$v)
                              },
                              expression: "orderInfo.verifyResult",
                            },
                          },
                          _vm._l(
                            _vm.verifyResultOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "终验人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.verifyUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "verifyUserName", $$v)
                            },
                            expression: "orderInfo.verifyUserName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "终验时间" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.verifyTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "verifyTime", $$v)
                            },
                            expression: "orderInfo.verifyTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "终验备注" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                            maxlength: "1000",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.orderInfo.verifyRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "verifyRemark", $$v)
                            },
                            expression: "orderInfo.verifyRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("交付性检测")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _vm._l(_vm.checkItemList4show, function (items, index1) {
            return _c(
              "el-row",
              { key: index1 },
              _vm._l(items, function (item, index2) {
                return _c("el-col", { key: index2, attrs: { span: 6 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-right": "100px",
                        "padding-bottom": "10px",
                        "padding-left": "100px",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        { attrs: { content: item.itemName, placement: "top" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: item.itemName,
                                size: "medium",
                                border: "",
                                disabled: "",
                              },
                              model: {
                                value: item.choose,
                                callback: function ($$v) {
                                  _vm.$set(item, "choose", $$v)
                                },
                                expression: "item.choose",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("showOverflowTooltipFilter")(
                                    item.itemName
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "30px" } },
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "前端展示", prop: "handleShow" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.handleShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "handleShow", $$v)
                            },
                            expression: "orderInfo.handleShow",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("终验附件")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c("upload-file", {
                              attrs: {
                                disabled: true,
                                "attachment-list":
                                  _vm.orderInfo.attachmentList5,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }