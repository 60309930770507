"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order/order");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _check = _interopRequireDefault(require("@/components/order/check"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 检测信息
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    orderCheck: _check.default
  },
  data: function data() {
    return {
      hisId: this.$route.params.id,
      orderInfo: {},
      rules: {} // ----------------------------------------------------------------------------------------------------------------------------------

    };
  },
  created: function created() {
    if (this.hisId) {
      this.getOrderInfo();
    }
  },
  methods: {
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this = this;

      (0, _order.getCheckHistory)({
        hisId: this.hisId
      }).then(function (response) {
        if (response.data) {
          _this.orderInfo = response.data;

          if (_this.$refs.checkOrder) {
            var param = {
              isCheckHis: true,
              id: _this.orderInfo.id,
              hisId: _this.hisId
            };

            _this.$refs.checkOrder.$emit('loadcheckInfo', param);
          }
        }
      });
    } // -------------------------------------------------------------------------------------------------------------------------------------------

  }
};
exports.default = _default;