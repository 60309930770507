"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.changeSendQuantity = changeSendQuantity;
exports.changeTakeQuantity = changeTakeQuantity;
exports.confirmIn = confirmIn;
exports.confirmOut = confirmOut;
exports.deleteItems = deleteItems;
exports.deleteStockLog = deleteStockLog;
exports.fetchList = fetchList;
exports.findPageList = findPageList;
exports.getAddress = getAddress;
exports.getCheckPageList = getCheckPageList;
exports.getItemsList = getItemsList;
exports.queryById = queryById;
exports.saveSn = saveSn;
exports.saveStockLog = saveStockLog;
exports.submitSave = submitSave;
exports.tempSave = tempSave;
exports.updateAddress = updateAddress;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/getPageList',
    method: 'get',
    params: query
  });
}
/**
 * 物流入库查询分页
 * @param query
 */


function getCheckPageList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/getCheckPageList',
    method: 'get',
    params: query
  });
}
/**
 * 查询库存可用的物料分页
 * @param query
 */


function findPageList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/getStockPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新建保存（点新建 创建一单）
 * @param data
 */


function saveStockLog() {
  console.log(1);
  return (0, _request.default)({
    url: '/scm_app/stock_log/saveStockLog',
    method: 'post'
  });
}
/**
 * 查询明细列表
 * @param data
 */


function getItemsList(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/getItemsList',
    method: 'post',
    params: data
  });
}
/**
 * 添加明细
 */


function addItems(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/addItems',
    method: 'post',
    data: data
  });
}
/**
 * 删除明细
 * @param data
 */


function deleteItems(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/deleteItems',
    method: 'post',
    data: data
  });
}
/**
 * 修改收货地址
 * @param data
 */


function updateAddress(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/updateAddress',
    method: 'post',
    data: data
  });
}
/**
 * 切换机构修改收货地址
 * @param data
 */


function getAddress(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/getAddress',
    method: 'post',
    data: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 删除
 * @param id
 */


function deleteStockLog(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/deleteStockLog',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 暂存
 * @param data
 */


function tempSave(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/tempSave',
    method: 'post',
    data: data
  });
}
/**
 * 提交
 * @param data
 */


function submitSave(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/submitSave',
    method: 'post',
    data: data
  });
}
/**
 * 确认出库
 * @param data
 */


function confirmOut(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/confirmOut',
    method: 'post',
    data: data
  });
}
/**
 * 确认入库
 * @param data
 */


function confirmIn(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/confirmIn',
    method: 'post',
    data: data
  });
}
/**
 * 修改实际出库数量
 * @param data
 */


function changeSendQuantity(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/changeSendQuantity',
    method: 'post',
    data: data
  });
}
/**
 * 修改实际收货数量
 * @param data
 */


function changeTakeQuantity(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/scm_app/stock_log/changeTakeQuantity',
    method: 'post',
    data: data
  });
}
/**
 * 保存序列号
 * @param data
 * @returns {AxiosPromise}
 */


function saveSn(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_log/saveSn',
    method: 'post',
    data: data
  });
}