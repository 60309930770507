"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _default = _vue.default.directive('loadmore', {
  bind: function bind(el, binding, vnode) {
    var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      var CONDITION = (this.scrollHeight - this.scrollTop) * 0.9 <= this.clientHeight;

      if (CONDITION) {
        binding.value();
      }
    });
  }
});

exports.default = _default;