"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCacheConfigValueByKey = getCacheConfigValueByKey;
exports.getConfigValueByKey = getConfigValueByKey;
exports.queryFileConfigs = queryFileConfigs;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 根据key获取配置value
 * @param key
 */
function getConfigValueByKey(key) {
  return (0, _request.default)({
    url: '/sys_app/sysConfigs/getConfigValueByKey',
    method: 'post',
    params: {
      key: key
    }
  });
}
/**
 * 根据key获取缓存配置value
 * @param key
 */


function getCacheConfigValueByKey(key) {
  return (0, _request.default)({
    url: '/sys_app/sysConfigs/getCacheConfigValueByKey',
    method: 'post',
    params: {
      key: key
    }
  });
}
/**
 * 获取上传文件参数
 * @param params
 */


function queryFileConfigs(params) {
  return (0, _request.default)({
    url: '/sys_app/sysConfigs/queryFileConfigs',
    method: 'post',
    params: params
  });
}