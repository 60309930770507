"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReceiveConsultOrder = ReceiveConsultOrder;
exports.bookVSE = bookVSE;
exports.cancelConfirm = cancelConfirm;
exports.cancelOrder = cancelOrder;
exports.cancelQiyuConfirm = cancelQiyuConfirm;
exports.checkSendCode = checkSendCode;
exports.clearLabels = clearLabels;
exports.createData = createData;
exports.createOrUpdateOwnCustomer = createOrUpdateOwnCustomer;
exports.createOrUpdateOwnProduct = createOrUpdateOwnProduct;
exports.createOrderById = createOrderById;
exports.createQiyuData = createQiyuData;
exports.createQiyuOrderById = createQiyuOrderById;
exports.delUpload = delUpload;
exports.deleteConsult = deleteConsult;
exports.deleteLabel = deleteLabel;
exports.deleteQiyuConsult = deleteQiyuConsult;
exports.findPageList = findPageList;
exports.findQiyuPageList = findQiyuPageList;
exports.finishConsultOrder = finishConsultOrder;
exports.finishQiyuConsultOrder = finishQiyuConsultOrder;
exports.getCityLastTime = getCityLastTime;
exports.getConsultOrderVseInfo = getConsultOrderVseInfo;
exports.getJdOrderSupplyInfo = getJdOrderSupplyInfo;
exports.getOrgByCaId = getOrgByCaId;
exports.getOverseasInfo = getOverseasInfo;
exports.getPurchaseDate = getPurchaseDate;
exports.getResolveResult = getResolveResult;
exports.getSendCode = getSendCode;
exports.getTokenUrl = getTokenUrl;
exports.getVSEStockList = getVSEStockList;
exports.getVideoCallList = getVideoCallList;
exports.getWarrantyDate = getWarrantyDate;
exports.handleUploader = handleUploader;
exports.preBookVSE = preBookVSE;
exports.queryById = queryById;
exports.queryBySessionId = queryBySessionId;
exports.queryCusAndProd = queryCusAndProd;
exports.queryCusAndProdByUnionId = queryCusAndProdByUnionId;
exports.queryCustomerCoupon = queryCustomerCoupon;
exports.queryJDSendInfo = queryJDSendInfo;
exports.queryLabel = queryLabel;
exports.queryOrderInfoById = queryOrderInfoById;
exports.queryOrderListByPhone = queryOrderListByPhone;
exports.queryQiyuById = queryQiyuById;
exports.queryQiyuBySessionId = queryQiyuBySessionId;
exports.queryQiyuCusAndProd = queryQiyuCusAndProd;
exports.queryRisk = queryRisk;
exports.querySnRisk = querySnRisk;
exports.refreshPhone = refreshPhone;
exports.saveConsult = saveConsult;
exports.saveConsultOrderVseInfo = saveConsultOrderVseInfo;
exports.saveCrmPhone = saveCrmPhone;
exports.saveLabels = saveLabels;
exports.saveOrder = saveOrder;
exports.saveOrderTemp = saveOrderTemp;
exports.saveQiyuOrder = saveQiyuOrder;
exports.saveUpload = saveUpload;
exports.sendExchangeSms = sendExchangeSms;
exports.sendGdprSms = sendGdprSms;
exports.sendSms = sendSms;
exports.tempSave = tempSave;
exports.validateBookClock = validateBookClock;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 根据手机号，查询历史工单记录
 * @param id
 */


function queryOrderListByPhone(query) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryOrderListByPhone',
    method: 'post',
    params: query
  });
}
/**
 * 根据手机号，查询历史工单记录
 * @param id
 */


function saveCrmPhone(query) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/saveCrmPhone',
    method: 'post',
    params: query
  });
}
/** 七鱼暂存咨询工单*/


function tempSave(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/tempSave',
    method: 'post',
    data: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryBySessionId(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryBySessionId',
    method: 'post',
    data: data
  });
}
/**
 * 根据机主手机号查询客户信息和产品信息
 * @param id
 */


function queryCusAndProd(mobile, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryCusAndProd',
    method: 'post',
    params: {
      mobile: mobile,
      num: num
    }
  });
}
/** 新建咨询工单*/


function saveConsult(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/save',
    method: 'post',
    data: data
  });
}
/**
 * 根据id删除咨询单
 * 变更咨询单状态 主状态 ：已结束 ，子状态：作废
 * @param id
 */


function deleteConsult(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/deleteConsultOrder',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 咨询工单转工单*/


function createData(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/createData',
    method: 'post',
    data: data
  });
}
/** 咨询工单 待客户上传*/


function handleUploader(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/handleUploader',
    method: 'post',
    data: data
  });
}
/** 咨询工单 删除图片*/


function delUpload(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/delUpload',
    method: 'post',
    data: data
  });
}
/** 咨询工单 保存图片*/


function saveUpload(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/saveUpload',
    method: 'post',
    data: data
  });
}
/** 完结咨询工单*/


function finishConsultOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/finishConsultOrder',
    method: 'post',
    data: data
  });
}
/**
 * 根据咨询工单id，初始化生成对应工单信息
 * @param id
 */


function createOrderById(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/createOrderById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 取消服务单使用
 * 根据咨询工单id，查询对应信息
 * @param id
 */


function queryOrderInfoById(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryOrderInfoById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 新建服务工单*/


function saveOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/saveOrder',
    method: 'post',
    data: data
  });
}
/** 服务单取消 服务取消*/


function cancelOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/cancel',
    method: 'post',
    data: data
  });
}
/** 咨询工单 待客户上传*/


function getTokenUrl(param) {
  console.log(param);
  return (0, _request.default)({
    url: '/api/resolve/getTokenUrl',
    method: 'post',
    params: {
      sku: param.sku,
      consultNum: param.consultNum
    }
  });
}
/**
 * 拿取 resolve 查询结果
 * @param param
 */


function getResolveResult(num) {
  return (0, _request.default)({
    url: '/api/resolve/getResolveResult',
    method: 'post',
    params: {
      num: num
    }
  });
}
/**
 * 咨询工单转工单，点击取消确认
 * 更新咨询工单状态：主状态：已结束，子状态，仅咨询
 * @param id
 */


function cancelConfirm(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/cancelConfirm',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 根据品类id查询派单对象
 * @param id
 */


function getOrgByCaId(id, cityCode) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getOrgByCaId',
    method: 'post',
    params: {
      id: id,
      cityCode: cityCode
    }
  });
}
/** 校验上门取件时间段是否合理*/


function validateBookClock(param) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/validateBookClock',
    method: 'post',
    params: {
      bookDate: param.bookDate,
      bookClock: param.bookClock
    }
  });
}
/**
 * 根据寄件城市id获取配置，生成顺丰取件时间段
 * @param id
 */


function getCityLastTime(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getCityLastTime',
    method: 'post',
    params: {
      cityId: id
    }
  });
}
/**
 * 新建或者更新自建产品信息库
 */


function createOrUpdateOwnProduct(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/createOrUpdateOwnProduct',
    method: 'post',
    data: data
  });
}
/*
*
*新建或更新自建客户信息
*/


function createOrUpdateOwnCustomer(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/createOrUpdateOwnCustomer',
    method: 'post',
    data: data
  });
} // 新建自建产品信息，根据sn获取购买日期


function getPurchaseDate(sn) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getPurchaseDate',
    method: 'post',
    params: {
      sn: sn
    }
  });
}
/** 保存服务工单到临时表*/


function saveOrderTemp(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/saveOrderTemp',
    method: 'post',
    data: data
  });
}
/** 咨询单领用*/


function ReceiveConsultOrder(ids) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/receiveConsultOrder',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 根据unionId查询客户信息和产品信息
 * @param id
 */


function queryCusAndProdByUnionId(unionId) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryCusAndProdByUnionId',
    method: 'post',
    params: {
      unionId: unionId
    }
  });
}
/**
 * 发送短信验证码
 */


function getSendCode(mobile, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getSendCode',
    method: 'post',
    params: {
      mobile: mobile,
      num: num
    }
  });
}
/**
 * 校验验证码
 */


function checkSendCode(code, mobile) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/checkSendCode',
    method: 'post',
    params: {
      code: code,
      mobile: mobile
    }
  });
}
/**
 * 预订vse
 */


function bookVSE(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/bookVSE',
    method: 'post',
    data: data
  });
}
/**
 * 发送vse短信
 */


function sendSms(id, mobile) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/sendSms',
    method: 'post',
    params: {
      id: id,
      mobile: mobile
    }
  });
}
/** 获得海外机信息*/


function getOverseasInfo(serialNumber, country, type) {
  return (0, _request.default)({
    url: '/bus_app/overseasSku/getOverseasInfo',
    method: 'post',
    params: {
      serialNumber: serialNumber,
      country: country,
      type: type
    }
  });
}
/** 查询京东回寄信息*/


function queryJDSendInfo(num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryJDSendInfo',
    method: 'post',
    params: {
      num: num
    }
  });
}
/** 刷新JD虚拟电话*/


function refreshPhone(num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/refreshPhone',
    method: 'post',
    params: {
      num: num
    }
  });
}
/**
 * 提前预订vse
 */


function preBookVSE(data) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/preBookVSE',
    method: 'post',
    data: data
  });
}
/**
 * 查询可预约库存vse列表
 */


function getVSEStockList(bookDate) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getVSEStockList',
    method: 'post',
    params: {
      bookDate: bookDate
    }
  });
}
/**
 * 查询不转vse信息
 */


function getConsultOrderVseInfo(num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getConsultOrderVseInfo',
    method: 'get',
    params: {
      num: num
    }
  });
}
/**
 * 保存不转vse信息
 */


function saveConsultOrderVseInfo(note, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/saveConsultOrderVseInfo',
    method: 'post',
    params: {
      note: note,
      num: num
    }
  });
}
/** 发送换票链接短信*/


function sendExchangeSms(num, phone) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/sendExchangeSms',
    method: 'post',
    params: {
      num: num,
      phone: phone
    }
  });
}
/**
 * 查询京东信息
 */


function getJdOrderSupplyInfo(jdOrderNo) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getJdOrderSupplyInfo',
    method: 'get',
    params: {
      orderNo: jdOrderNo
    }
  });
}
/** 根据sn查询产品详情*/


function getWarrantyDate(sn, type) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getWarrantyDate',
    method: 'post',
    params: {
      sn: sn,
      type: type
    }
  });
}
/**
 * 发送Gdpr短信
 */


function sendGdprSms(mobile, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/sendGdprSms',
    method: 'post',
    params: {
      mobile: mobile,
      num: num
    }
  });
}
/** 查询分页*/


function getVideoCallList(query) {
  return (0, _request.default)({
    url: '/bus_app/vse_video_call/getPageList',
    method: 'post',
    params: query
  });
}
/** 查询风险标签*/


function queryLabel(type, level) {
  return (0, _request.default)({
    url: '/bus_app/baseLabel/queryLabel',
    method: 'post',
    params: {
      type: type,
      level: level
    }
  });
}
/** 保存风险标签*/


function saveLabels(num, labelList, mobile, detailAddress, sn) {
  return (0, _request.default)({
    url: '/bus_app/consult_order_label/saveLabels',
    method: 'post',
    params: {
      num: num,
      labelList: labelList,
      mobile: mobile,
      detailAddress: detailAddress,
      sn: sn
    }
  });
}
/** 删除风险标签*/


function deleteLabel(id, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order_label/deleteLabel',
    method: 'post',
    params: {
      num: num,
      id: id
    }
  });
}
/** 查询风险风险标签*/


function queryRisk(riskName, type, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order_label/queryRisk',
    method: 'post',
    params: {
      riskName: riskName,
      type: type,
      num: num
    }
  });
}
/** 查询sn风险标签*/


function querySnRisk(riskName, type, num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order_label/querySnRisk',
    method: 'post',
    params: {
      riskName: riskName,
      type: type,
      num: num
    }
  });
}
/** 清空标签*/


function clearLabels(num) {
  return (0, _request.default)({
    url: '/bus_app/consult_order_label/deleteByConsultNum',
    method: 'post',
    params: {
      num: num
    }
  });
}
/** 查询分页*/


function findQiyuPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id删除咨询单
 * 变更咨询单状态 主状态 ：已结束 ，子状态：作废
 * @param id
 */


function deleteQiyuConsult(id) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/deleteConsultOrder',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 根据咨询工单id，初始化生成对应工单信息
 * @param id
 */


function createQiyuOrderById(id) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/createOrderById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 新建服务工单*/


function saveQiyuOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/saveOrder',
    method: 'post',
    data: data
  });
}
/**
 * 咨询工单转工单，点击取消确认
 * 更新咨询工单状态：主状态：已结束，子状态，仅咨询
 * @param id
 */


function cancelQiyuConfirm(id) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/cancelConfirm',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 咨询工单转工单*/


function createQiyuData(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/createData',
    method: 'post',
    data: data
  });
}
/**
 * 根据机主手机号查询客户信息和产品信息
 * @param id
 */


function queryQiyuCusAndProd(mobile, num) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/queryCusAndProd',
    method: 'post',
    params: {
      mobile: mobile,
      num: num
    }
  });
}
/** 完结咨询工单*/


function finishQiyuConsultOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/finishConsultOrder',
    method: 'post',
    data: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryQiyuBySessionId(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/queryBySessionId',
    method: 'post',
    data: data
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryQiyuById(id) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_consult_order/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 查询用户优惠券*/


function queryCustomerCoupon(phone) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/queryCustomerCoupon',
    method: 'post',
    params: {
      phone: phone
    }
  });
}