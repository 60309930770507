var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _vm.productRegisterInfo.activeStatus !== "02"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_SNPRODUCT_REGISTER_UNBUNDLING"],
                          expression: "['ROLE_SNPRODUCT_REGISTER_UNBUNDLING']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleModifyStatus },
                    },
                    [_vm._v("解绑")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.productRegisterInfo,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              基本信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品SN",
                                            prop: "serialNumber",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .serialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "serialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.serialNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否翻新机",
                                            prop: "isRefurb",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .isRefurb,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "isRefurb",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.isRefurb",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "是",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "否",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品是否有效",
                                            prop: "isValid",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .isValid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "isValid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.isValid",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "无效",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "有效",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "生产日期",
                                            prop: "productDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .productDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "productDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.productDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "出库日期",
                                            prop: "shipDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .shipDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "shipDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.shipDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "入保日期",
                                            prop: "warrantyDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .warrantyDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "warrantyDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.warrantyDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售日期",
                                            prop: "soldDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .soldDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "soldDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.soldDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "购买日期",
                                            prop: "purchaseDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .purchaseDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "purchaseDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.purchaseDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售渠道名称",
                                            prop: "saleChannelName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .saleChannelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "saleChannelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.saleChannelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "CRM ID",
                                            prop: "crmId",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.crmId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "crmId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.crmId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "客户名称",
                                            prop: "customerName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .customerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "customerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.customerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "手机号",
                                            prop: "mobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "Union ID",
                                            prop: "unionid",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.unionid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "unionid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.unionid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "邮箱",
                                            prop: "email",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册日期",
                                            prop: "registerDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .registerDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "registerDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.registerDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册来源",
                                            prop: "registerSource",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .registerSource,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "registerSource",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.registerSource",
                                              },
                                            },
                                            _vm._l(
                                              _vm.registerSourceOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册类型",
                                            prop: "registrationType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .registrationType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "registrationType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.registrationType",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "自动注册",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "2",
                                                  label: "手动注册",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "90天福利截至日期",
                                            prop: "benefit90DaysEndDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .benefit90DaysEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "benefit90DaysEndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.benefit90DaysEndDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "活跃状态",
                                            prop: "activeStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .activeStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "activeStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.activeStatus",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "01",
                                                  label: "已绑定",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "02",
                                                  label: "已注销",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "锁定状态",
                                            prop: "email",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .tradeStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "tradeStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.tradeStatus",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "已锁定",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "未锁定",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "创建时间",
                                            prop: "inDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.inDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "inDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.inDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "90天权益",
                                            prop: "email",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterInfo
                                                    .rights,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterInfo,
                                                    "rights",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterInfo.rights",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "有",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "无",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "主机SKU",
                                            prop: "inDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo.sku1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "sku1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.sku1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否普通延保",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .isExtendBenefit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "isExtendBenefit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.isExtendBenefit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "普通延保时长",
                                            prop: "extendBenefitDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .extendBenefitDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "extendBenefitDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.extendBenefitDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否会员权益延保",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .isEquityExtend,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "isEquityExtend",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.isEquityExtend",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "会员权益延保时长",
                                            prop: "inRmk",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .equityExtendDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "equityExtendDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.equityExtendDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "会员权益延保渠道",
                                            prop: "extendBenefitDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterInfo
                                                  .extendSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterInfo,
                                                  "extendSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterInfo.extendSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.productRegisterInfo.country !== "CN"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-divider",
                              {
                                staticClass: "myStyle",
                                attrs: { "content-position": "left" },
                              },
                              [
                                _vm._v(
                                  "\n              海外机基本信息\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { "border-top": "none" },
                                attrs: { shadow: "never" },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  label: "海外SKU",
                                                  prop: "overSeasSku",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "filter-item",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.productRegisterInfo
                                                        .overSeasSku,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.productRegisterInfo,
                                                        "overSeasSku",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "productRegisterInfo.overSeasSku",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "销售国家",
                                                  prop: "country",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm.productRegisterInfo
                                                          .country,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.productRegisterInfo,
                                                          "country",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productRegisterInfo.country",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.countryOptions,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.val,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8, pull: 1 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid-content bg-purple",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "是否全球联保",
                                                  prop: "globalWarranty",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      fill: "#000",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.productRegisterInfo
                                                          .globalWarranty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.productRegisterInfo,
                                                          "globalWarranty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productRegisterInfo.globalWarranty",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      {
                                                        attrs: {
                                                          label: "true",
                                                        },
                                                      },
                                                      [_vm._v("是")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      {
                                                        attrs: {
                                                          label: "false",
                                                        },
                                                      },
                                                      [_vm._v("否")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-divider",
                      {
                        staticClass: "myStyle",
                        attrs: { "content-position": "left" },
                      },
                      [_vm._v("\n            产品注册履历\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "border-top": "none" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "0px",
                              "margin-right": "0px",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 22 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "50px",
                                        "margin-right": "50px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            border: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                              height: "50px",
                                            },
                                            data: _vm.prodcutHistoryList,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateUser",
                                              label: "操作人",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateTime",
                                              label: "操作时间",
                                              align: "center",
                                              width: "180px",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "serialNumber",
                                              label: "产品SN",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isRefurb",
                                              label: "是否翻新机",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "mobile",
                                              label: "手机号",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "activeStatus",
                                              label: "活跃状态",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "activeStatusFilter"
                                                        )(row.activeStatus)
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "tradeStatus",
                                              label: "锁定状态",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "registerDate",
                                              label: "注册时间",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productDate",
                                              label: "生产日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "shipDate",
                                              label: "出库日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyDate",
                                              label: "入保日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyNote",
                                              label: "入保日期备注",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "warrantyDateSource",
                                              label: "修改来源",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "benefitEndDate",
                                              label: "保修结束日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isExtendBenefit",
                                              label: "是否普通延保",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendSource",
                                              label: "延保渠道",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendBenefitDate",
                                              label: "延保时长",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "extendBenefitNote",
                                              label: "延保原因",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "benefitEndLogic",
                                              label: "计算规则",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isEquityExtend",
                                              label: "是否会员权益延保",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendSource",
                                              label: "会员权益延保渠道",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendDate",
                                              label: "会员权益延保时长",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendNote",
                                              label: "会员权益延保原因",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "orderNo",
                                              label: "订单号",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "equityExtendId",
                                              label: "活动ID",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "isValid",
                                              label: "产品是否有效",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "soldDate",
                                              label: "销售日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "purchaseDate",
                                              label: "购买时间",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "benefit90DaysEndDate",
                                              label: "90天福利截至日期",
                                              align: "center",
                                              resizable: "",
                                              "min-width": "150px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "rights",
                                              label: "90天权益",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("rightsFilter")(
                                                          row.rights
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "customerName",
                                              label: "客户姓名",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "crmId",
                                              label: "CRM ID",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "unionid",
                                              label: "union ID",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "email",
                                              label: "邮箱",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "country",
                                              label: "销售国家",
                                              "sort-by": "5",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("countryFilter")(
                                                          row.country
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("pagination", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.total > 0,
                                            expression: "total>0",
                                          },
                                        ],
                                        attrs: {
                                          total: _vm.total,
                                          page: _vm.listQuery.page,
                                          limit: _vm.listQuery.limit,
                                        },
                                        on: {
                                          "update:page": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "page",
                                              $event
                                            )
                                          },
                                          "update:limit": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "limit",
                                              $event
                                            )
                                          },
                                          pagination: _vm.getHistoryList,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }