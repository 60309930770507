"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _sku = require("@/api/basics/sku/sku");

var _select = require("@/api/common/select");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadImgs = _interopRequireDefault(require("@/components/uploadImgs"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件图片上传
// 下拉框 树形结构 可检索组件
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadImgs: _uploadImgs.default,
    treeSelect: _treeSelect.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '1': 'success',
        '0': 'danger',
        undefined: 'info'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    var _this = this;

    // 自定义校验：金额位数小于千万
    var validateNumberMax = function validateNumberMax(rule, value, callback) {
      if (value && value !== '' && parseInt(value) > 9999999) {
        callback('限制数值小于10000000');
      } else {
        callback();
      }
    };

    var validateTrade = function validateTrade(rule, value, callback) {
      if (_this.baseSku.skuTag === '2' && value && value !== '' && value > 0) {
        callback('配件不支持升级优惠');
      } else {
        callback();
      }
    };

    return {
      btnLoading: false,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      // 只读模式
      isReadOnly: this.$route.params.isReadOnly || this.$store.state.user.pCodes.indexOf('ROLE_BASE_PRD_SKU_ADD') < 0 && this.$store.state.user.pCodes.indexOf('ROLE_BASE_PRD_SKU_MODIFY') < 0,
      typeOptions: [{
        label: '核心件',
        value: '1'
      }, {
        label: '重要件',
        value: '2'
      }, {
        label: '一般件',
        value: '3'
      }],
      tagOptions: [{
        label: '成品',
        value: '1'
      }, {
        label: '配件',
        value: '2'
      }],
      // ----------------------------------------------------------------------------------------------------------------------------------
      baseSku: {
        id: '',
        code: '',
        name: '',
        type: '',
        skuTag: '',
        caps: '0',
        cost: undefined,
        priceExcludeTax: undefined,
        priceIncludeTax: undefined,
        costYuan: undefined,
        priceExcludeTaxYuan: undefined,
        priceIncludeTaxYuan: undefined,
        warrantyDays: undefined,
        stockMin: undefined,
        purchaseDays: undefined,
        isRetail: '0',
        isSn: '0',
        sample: '',
        supplier: '',
        picturePath: '',
        isEnable: '1',
        memo: ''
      },
      rules: {
        code: [{
          required: true,
          message: '物料代码是必填的',
          trigger: 'blur'
        }, {
          max: 30,
          message: '最大长度 30 个字符',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '物料名称是必填的',
          trigger: 'blur'
        }, {
          max: 100,
          message: '最大长度 100 个字符',
          trigger: 'blur'
        }],
        supplier: [{
          max: 50,
          message: '最大长度 50 个字符',
          trigger: 'blur'
        }],
        preSn: [{
          max: 3,
          message: '最大长度 3 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '物料分类是必填的',
          trigger: 'blur'
        }],
        skuTag: [{
          required: true,
          message: '物料标识是必填的',
          trigger: 'blur'
        }],
        isRetail: [{
          required: true,
          message: '是否可零售是必填的',
          trigger: 'blur'
        }],
        isSn: [{
          required: true,
          message: '是否启用序列号是必填的',
          trigger: 'blur'
        }],
        isEnable: [{
          required: true,
          message: '状态是必填的',
          trigger: 'blur'
        }],
        replacement: [{
          required: true,
          message: '保外转换货价格是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        caps: [{
          required: true,
          message: '是否启用封顶价是必填的',
          trigger: 'blur'
        }],
        costYuan: [{
          required: true,
          message: '参考含税成本价是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        priceExcludeTaxYuan: [{
          required: true,
          message: '零售不含税指导价是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        priceIncludeTaxYuan: [{
          required: true,
          message: '零售含税指导价是必填的',
          trigger: 'blur'
        }, {
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        warrantyDays: [{
          required: true,
          message: '保修时长（天）是必填的',
          trigger: 'blur'
        }, {
          pattern: /^(([1-9]+\d*)|0)$/,
          message: '请正确填写整数'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        stockMin: [{
          pattern: /^(([1-9]+\d*)|0)$/,
          message: '请正确填写整数'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        purchaseDays: [{
          pattern: /^(([1-9]+\d*)|0)$/,
          message: '请正确填写整数'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        exchange: [{
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }],
        trade: [{
          pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.\d{1,2})|([1-9]+\d*)|0)$/,
          message: '请正确填写价格（小数点后仅两位）'
        }, {
          validator: validateNumberMax,
          trigger: 'blur'
        }, {
          validator: validateTrade,
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      addBtnDisabled: true,
      deleteBtnDisabled: true,
      dialog4modelSelVisible: false,
      modelSelValidate: false,
      loadingList4ModelSelect: false,
      loadingList4Model: false,
      list4Model: [],
      multipleSelection: [],
      // ----------------------------------------------------------------------------------------------------------------------------------
      categorySelectTree: [],
      categorySelect: [],
      brandSelect: [],
      modelSelect: [],
      list4ModelSelect: [],
      multipleSelection4ModelSel: [],
      listQuery: {
        categoryId: undefined,
        brandId: undefined,
        id: undefined,
        categoryBrandName: '1',
        isEnable: '1'
      }
    };
  },
  computed: {
    categoryChange: function categoryChange() {
      return this.listQuery.categoryId;
    },
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categoryChange: {
      handler: function handler(val, oldval) {
        this.categorySelectChange(val, oldval);
      },
      deep: true,
      immediate: true
    },
    search: function search(val) {
      this.list = this.oTableData.filter(function (item) {
        return ~item.name.indexOf(val);
      });
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    }
  },
  created: function created() {
    if (!this.isCreate) {
      this.initBaseSku();
    }
  },
  methods: {
    setFilePaths: function setFilePaths(data) {
      this.baseSku.picturePath = data;
    },
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.listQuery.categoryId = value;
    },
    initBaseSku: function initBaseSku() {
      var _this2 = this;

      (0, _sku.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this2.baseSku = response.data;

          if (_this2.baseSku.replacement != null && _this2.baseSku.replacement !== '') {
            _this2.baseSku.replacement = _this2.baseSku.replacement / 100;
          }

          _this2.baseSku.exchange = _this2.baseSku.exchange / 100;
          _this2.baseSku.trade = _this2.baseSku.trade / 100;
          _this2.list4Model = _this2.baseSku.modelList;
        }
      });
    },
    // 【已关联型号列表】验证方法
    validateModelList: function validateModelList() {
      if (!this.list4Model || this.list4Model.length === 0) {
        this.$message({
          showClose: true,
          message: '请添加适用型号',
          type: 'error'
        });
        return false;
      } else if (this.baseSku.skuTag === '1' && this.list4Model.length > 1) {
        // 成品只能关联一个型号
        this.$message({
          showClose: true,
          message: '成品物料只能关联一个型号',
          type: 'error'
        });
        return false;
      }

      return true;
    },
    // saveSumbit() {
    //   this.$refs['dataForm'].validate((valid) => {
    //     if (valid && this.validateModelList()) {
    //       this.btnLoading = true
    //       this.baseSku.modelList = this.list4Model
    //       this.baseSku.cost = this.baseSku.costYuan * 100
    //       this.baseSku.priceExcludeTax = this.baseSku.priceExcludeTaxYuan * 100
    //       this.baseSku.priceIncludeTax = this.baseSku.priceIncludeTaxYuan * 100
    //       saveData(this.baseSku).then(res => {
    //         if (res.data) {
    //           this.$message({showClose: true, message: '保存成功', type: 'success'})
    //           this.$store.dispatch('tagsView/delView', this.$route)
    //           this.$router.push({path: '/basics/sku/list'})
    //         } else {
    //           this.$message({showClose: true, message: res.msg, type: 'error'})
    //         }
    //         this.btnLoading = false
    //       })
    //     } else {
    //       this.$nextTick(() => {
    //         const isError = document.getElementsByClassName('is-error')
    //         if (isError && isError.length > 0) {
    //           isError[0].querySelector('input, textarea').focus()
    //         }
    //       })
    //     }
    //   })
    // },
    saveSumbit: function saveSumbit() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this3.validateModelList()) {
          _this3.btnLoading = true;
          _this3.baseSku.modelList = _this3.list4Model;
          _this3.baseSku.replacement = _this3.baseSku.replacement * 100;
          _this3.baseSku.exchange = _this3.baseSku.exchange * 100;
          _this3.baseSku.trade = _this3.baseSku.trade * 100;
          _this3.baseSku.cost = (_this3.baseSku.costYuan * 100).toFixed();
          _this3.baseSku.priceExcludeTax = (_this3.baseSku.priceExcludeTaxYuan * 100).toFixed();
          _this3.baseSku.priceIncludeTax = (_this3.baseSku.priceIncludeTaxYuan * 100).toFixed();
          (0, _sku.saveData)(_this3.baseSku).then(function (res) {
            if (res.data) {
              _this3.$message({
                showClose: true,
                message: '保存成功',
                type: 'success'
              });

              _this3.$store.dispatch('tagsView/delView', _this3.$route);

              _this3.$router.push({
                path: '/basics/sku/list'
              });
            } else {
              _this3.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              });
            }

            _this3.btnLoading = false;
          });
        } else {
          _this3.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 【已关联型号列表】删除方法
    delModelClick: function delModelClick() {
      this.loadingList4Model = true;

      if (this.multipleSelection.length > 0) {
        var modelIds4Selects = [];
        this.multipleSelection.forEach(function (v) {
          modelIds4Selects.push(v.modelId);
        });
        var list4ModelSelectCopy = JSON.parse(JSON.stringify(this.list4Model));
        var newList4Model = [];

        for (var i = 0; i < list4ModelSelectCopy.length; i++) {
          if (modelIds4Selects.indexOf(list4ModelSelectCopy[i].modelId) === -1) {
            newList4Model.push(list4ModelSelectCopy[i]);
          }
        }

        this.list4Model = newList4Model;
      }

      this.loadingList4Model = false;
    },
    // 【已关联型号列表】单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 【已关联型号列表】checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.deleteBtnDisabled = this.multipleSelection.length < 1;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    addModelSwitch: function addModelSwitch() {
      this.listQuery = {
        categoryBrandName: '1',
        isEnable: '1'
      };
      this.modelSelValidate = false;
      this.dialog4modelSelVisible = !this.dialog4modelSelVisible;
      this.list4ModelSelect = [];
    },
    // 【dialog】【适用型号】初始化
    dialog4modelSelOpen: function dialog4modelSelOpen() {
      var _this4 = this;

      (0, _select.getCategorySelect)().then(function (res) {
        _this4.categorySelect = res.data.records;
      });
      (0, _select.getBrandSelect)().then(function (res) {
        _this4.brandSelect = res.data.records;
      });
      this.loadModelSelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this5 = this;

      (0, _select.getModelSelect)(this.listQuery).then(function (res) {
        _this5.modelSelect = res.data;
      });
    },
    categorySelectChange: function categorySelectChange(val, oldval) {
      if (oldval !== undefined && (!val || val !== oldval)) {
        this.listQuery.id = '';
        this.modelSelect = [];
      }
    },
    brandSelectChange: function brandSelectChange() {
      this.listQuery.id = '';
      this.modelSelect = [];
    },
    // 【型号选择】重置按钮事件
    cleanBtnClick4ModelSel: function cleanBtnClick4ModelSel() {
      this.listQuery = {
        categoryBrandName: '1',
        isEnable: '1'
      };
      this.modelSelValidate = false;
      this.list4ModelSelect = [];
    },
    // 【型号选择】查询按钮事件
    searchBtnClick4ModelSel: function searchBtnClick4ModelSel() {
      if (this.listQuery.id || this.listQuery.brandId || this.listQuery.categoryId) {
        this.modelSelValidate = false;
        this.getList4ModelSel();
      } else {
        this.modelSelValidate = true;
      }
    },
    getList4ModelSel: function getList4ModelSel() {
      var _this6 = this;

      this.loadingList4ModelSelect = true;
      (0, _select.getModelSelect)(this.listQuery).then(function (response) {
        _this6.list4ModelSelect = response.data;
        _this6.loadingList4ModelSelect = false;
      });
    },
    rowClickSelection4ModelSelect: function rowClickSelection4ModelSelect(row, column, event) {
      this.$refs.multipleTable4ModelSelect.toggleRowSelection(row);
    },
    handleSelectionChange4ModelSelect: function handleSelectionChange4ModelSelect(val) {
      this.multipleSelection4ModelSel = val;
      this.addBtnDisabled = this.multipleSelection4ModelSel.length < 1;
    },
    // 【型号选择列表】将选中的型号添加入【关联型号列表】
    addModelSelectBtnClick: function addModelSelectBtnClick() {
      this.loadingList4Model = true;

      if (this.multipleSelection4ModelSel.length >= 1) {
        var addModelSels = [];
        this.multipleSelection4ModelSel.map(function (value, index, arry) {
          addModelSels.push({
            'modelId': value.id,
            'modelCode': value.code,
            'modelName': value.name,
            'categoryName': value.categoryName,
            'brandName': value.brandName
          });
        });
        var list4ModelCopy = JSON.parse(JSON.stringify(this.list4Model)); // 合并数组

        var newList4ModelCopy = list4ModelCopy ? list4ModelCopy.concat(addModelSels) : addModelSels; // 数组根据主键modelId去掉重复项

        var temp = {};
        var newList4Model = [];

        for (var i = 0; i < newList4ModelCopy.length; i++) {
          if (!temp[newList4ModelCopy[i].modelId]) {
            newList4Model.push(newList4ModelCopy[i]);
            temp[newList4ModelCopy[i].modelId] = true;
          }
        } // 已关联型号数组渲染赋值


        this.list4Model = newList4Model; // 隐藏弹出框

        this.addModelSwitch();
      }

      this.loadingList4Model = false;
    } // -------------------------------------------------------------------------------------------------------------------------------------------

  }
};
exports.default = _default;