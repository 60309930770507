var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              !_vm.isConfirm
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_BATCH_CHECK_ORDER_UPDATE"],
                          expression: "['ROLE_BATCH_CHECK_ORDER_UPDATE']",
                        },
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.showDialog },
                    },
                    [_vm._v("确认\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.batchCheckOrderInfo,
            rules: _vm.formRules,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              批量检测详情\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "姓名",
                                            prop: "customer",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isConfirm },
                                            model: {
                                              value:
                                                _vm.batchCheckOrderInfo
                                                  .customer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.batchCheckOrderInfo,
                                                  "customer",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "batchCheckOrderInfo.customer",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "联系电话",
                                            prop: "contactTel",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.batchCheckOrderInfo
                                                  .contactTel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.batchCheckOrderInfo,
                                                  "contactTel",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "batchCheckOrderInfo.contactTel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "工程师备注" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              type: "textarea",
                                              rows: 2,
                                              placeholder: "请输入内容",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.batchCheckOrderInfo
                                                  .engineerNote,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.batchCheckOrderInfo,
                                                  "engineerNote",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "batchCheckOrderInfo.engineerNote",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "nmyStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              设备信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { "padding-bottom": "10px" } },
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "grid-content bg-purple",
                                      staticStyle: { float: "right" },
                                    },
                                    [
                                      !_vm.isConfirm
                                        ? _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: [
                                                    "ROLE_BATCH_CHECK_ORDER_ITEM_ADD",
                                                  ],
                                                  expression:
                                                    "['ROLE_BATCH_CHECK_ORDER_ITEM_ADD']",
                                                },
                                              ],
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.centerDialogVisible = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "添加\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            plain: "",
                                          },
                                          on: { click: _vm.handelTempExport },
                                        },
                                        [_vm._v("模板下载")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-upload",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            margin: "10px",
                                          },
                                          attrs: {
                                            "show-file-list": false,
                                            "before-upload":
                                              _vm.handleBeforeUpload,
                                            "on-success": _vm.handleSuccess,
                                            "on-error": _vm.handleError,
                                            action: _vm.uploadAction,
                                          },
                                        },
                                        [
                                          !_vm.isConfirm
                                            ? _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value: [
                                                        "ROLE_BATCH_CHECK_ORDER_ITEM_IMPORT",
                                                      ],
                                                      expression:
                                                        "['ROLE_BATCH_CHECK_ORDER_ITEM_IMPORT']",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "primary",
                                                    size: "small",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        导入\n                      "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.listLoading,
                                    expression: "listLoading",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  border: "",
                                  stripe: "",
                                  "header-cell-style": {
                                    background: "#eef1f6",
                                    color: "#606266",
                                    height: "50px",
                                  },
                                  data: _vm.checkItemList,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "编号",
                                    type: "index",
                                    align: "center",
                                    width: "50",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "sn",
                                    label: "sn号",
                                    align: "center",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "materialCode",
                                    label: "物料编码",
                                    align: "center",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "materialName",
                                    label: "物料名称",
                                    align: "center",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "picUrl",
                                    label: "图片",
                                    align: "center",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.isEdit[scope.$index]
                                            ? _c(
                                                "el-upload",
                                                {
                                                  ref: "upload" + scope.$index,
                                                  class: {
                                                    uoloadSty: _vm.showBtnImg,
                                                    disUoloadSty:
                                                      _vm.noneBtnImg,
                                                  },
                                                  attrs: {
                                                    action: _vm.uploadAction,
                                                    "list-type": "text",
                                                    limit: _vm.imgLimit,
                                                    "file-list":
                                                      _vm.imgList[scope.$index],
                                                    multiple: _vm.isMultiple,
                                                    "on-preview": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      _vm.handlePictureCardPreview(
                                                        file,
                                                        fileList,
                                                        scope.$index
                                                      )
                                                    },
                                                    "on-remove": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      _vm.handleRemove(
                                                        file,
                                                        fileList,
                                                        scope.row.id,
                                                        scope.$index
                                                      )
                                                    },
                                                    "on-success": function (
                                                      res,
                                                      file
                                                    ) {
                                                      _vm.handleAvatarSuccess(
                                                        res,
                                                        file,
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                    "on-exceed":
                                                      _vm.handleExceed,
                                                    "before-upload":
                                                      _vm.beforeAvatarUpload,
                                                    "on-error": function (
                                                      err,
                                                      file,
                                                      fileList
                                                    ) {
                                                      _vm.imgUploadError(
                                                        err,
                                                        file,
                                                        fileList,
                                                        scope.$index
                                                      )
                                                    },
                                                    "on-change": function (
                                                      file,
                                                      fileList
                                                    ) {
                                                      _vm.dealImgChange(
                                                        file,
                                                        fileList,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  !_vm.isConfirm
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "small",
                                                            type: "primary",
                                                          },
                                                        },
                                                        [_vm._v("上传图片")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showImg(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.isConfirm
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "permission",
                                                              rawName:
                                                                "v-permission",
                                                              value: [
                                                                "ROLE_BATCH_CHECK_ORDER_ITEM_UPDATE",
                                                              ],
                                                              expression:
                                                                "['ROLE_BATCH_CHECK_ORDER_ITEM_UPDATE']",
                                                            },
                                                          ],
                                                          attrs: {
                                                            size: "small",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleRemove(
                                                                scope.row,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "删除\n                      "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dialog",
                                            {
                                              attrs: {
                                                visible:
                                                  _vm.dialogVisible[
                                                    scope.$index
                                                  ],
                                              },
                                              on: {
                                                "update:visible": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.dialogVisible,
                                                    scope.$index,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  width: "80%",
                                                  src: _vm.imgUrlList[
                                                    scope.$index
                                                  ],
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "id",
                                    label: "操作",
                                    align: "center",
                                    width: "100px",
                                    resizable: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          !_vm.isConfirm
                                            ? _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value: [
                                                        "ROLE_BATCH_CHECK_ORDER_ITEM_DELETE",
                                                      ],
                                                      expression:
                                                        "['ROLE_BATCH_CHECK_ORDER_ITEM_DELETE']",
                                                    },
                                                  ],
                                                  attrs: {
                                                    size: "mini",
                                                    type: "danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteCheckOrder(
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "删除\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.centerDialogVisible, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm2",
              attrs: {
                model: _vm.checkItemInfo,
                rules: _vm.itemRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { label: "产品sn", prop: "sn" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "filter-item",
                          on: { input: _vm.checkSnIn },
                          model: {
                            value: _vm.checkItemInfo.sn,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkItemInfo, "sn", $$v)
                            },
                            expression: "checkItemInfo.sn",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.loadSkuSelect },
                              slot: "append",
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { label: "物料编码", prop: "materialCode" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          on: { focus: _vm.loadSkuSelect },
                          model: {
                            value: _vm.checkItemInfo.materialCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkItemInfo, "materialCode", $$v)
                            },
                            expression: "checkItemInfo.materialCode",
                          },
                        },
                        _vm._l(_vm.skuSelect, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.code, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmAddItem },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.cancelConfirmAddItem },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.comfirmDialog, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.comfirmDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否确认提交？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.updateBatchCheckOrder },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.confirmSubmit } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isPrint, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.isPrint = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否需要打印？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.printCheckItem },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isPrint = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }