"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '售后系统',
      // TODO: logo待定
      logo: require('@/assets/logo/' + process.env.VUE_APP_LOGO),
      title1: 'dyson售后系统',
      logo1: require('@/assets/logo/' + process.env.VUE_APP_LOGO2)
    };
  }
};
exports.default = _default;