"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _drag = _interopRequireDefault(require("./drag"));

var install = function install(Vue) {
  Vue.directive('el-drag-dialog', _drag.default);
};

if (window.Vue) {
  window['el-drag-dialog'] = _drag.default;
  Vue.use(install); // eslint-disable-line
}

_drag.default.install = install;
var _default = _drag.default;
exports.default = _default;