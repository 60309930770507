"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getSkuFeeSelect } from '@/api/common/select'
var _default = {
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      userfeeRechekSelect: []
    };
  },
  created: function created() {
    this.initSelect();
  },
  methods: {
    initSelect: function initSelect() {// const params = { isEnable: undefined }
      // getSkuFeeSelect(params).then(res => { this.userfeeRechekSelect = res.data })
    }
  }
};
exports.default = _default;