"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _list = _interopRequireDefault(require("@/layout/list"));

/**
 * 工单块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var qiyuRouter = {
  path: '/qiyu/detail',
  component: _list.default,
  hidden: true,
  children: [{
    path: '/qiyu/qiyu_list',
    name: 'qiyuList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_list'));
      });
    },
    meta: {
      title: '咨询单列表',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_detail',
    name: 'qiyuDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_detail'));
      });
    },
    meta: {
      title: '咨询工单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_create/',
    name: 'qiyuCreateDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_create'));
      });
    },
    meta: {
      title: '转服务工单',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_sale/',
    name: 'qiyuSaleDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_sale'));
      });
    },
    meta: {
      title: '销售单',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_return/',
    name: 'qiyuReturnDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_return'));
      });
    },
    meta: {
      title: '退货单',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_detail2',
    name: 'qiyuDetail2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_detail2'));
      });
    },
    meta: {
      title: '咨询工单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/qiyu/qiyu_order_detail',
    name: 'qiyuOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qiyu/qiyu_order/qiyu_order_detail'));
      });
    },
    meta: {
      title: '转工单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/qiyu/qiyu_order',
      parent: {
        path: '/qiyu',
        redirect: 'noRedirect',
        meta: {
          title: '七鱼咨询',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = qiyuRouter;
exports.default = _default;