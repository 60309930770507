"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/** 服务单列表排序[对象] */
var orderKeyMap = {
  'orderNo': '1',
  'type': '2',
  'subType': '3',
  'state': '4',
  'subState': '5',
  'urgentLevel': '6',
  'categoryName': '7',
  'modelName': '8',
  'sn': '9',
  'organizationName': '10',
  'postType': '11',
  'receiptLogisticsNumber': '12',
  'returnType': '13',
  'returnLogisticsNumber': '14',
  'ownerName': '15',
  'ownerTel': '16',
  'claimName': '17',
  'claimTel': '18',
  'sendName': '19',
  'sendTel': '20',
  'receiptUserName': '21',
  'receiptTime': '22',
  'dispatchUserName': '23',
  'dispatchTime': '24',
  'checkUserName': '25',
  'checkTime': '26',
  'handleUserName': '27',
  'handleTime': '28',
  'verifyUserName': '29',
  'verifyTime': '30',
  'confirmUserName': '31',
  'confirmTime': '32',
  'createUser': '33',
  'createTime': '34',
  'callSign': '35',
  'callerName': '36',
  'engineerName': '37',
  'callNo': '50',
  'inDate': '51',
  'assignTime': '52'
};
/** 客服任务列表排序[对象] */

var taskKeyMap = {
  'orderNo': 't.order_no',
  'callNo': 't.call_no',
  'inDate': 't.in_date',
  'planStarDate': 't.plan_star_date',
  'priorityName': 'priority'
};
var _default = {
  orderKeyMap: orderKeyMap,
  taskKeyMap: taskKeyMap
};
exports.default = _default;