"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.queryById = queryById;
exports.updateFirst = updateFirst;
exports.updateReject = updateReject;
exports.updateSecond = updateSecond;

var _request = _interopRequireDefault(require("@/utils/request"));

function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/refund_transaction/list',
    method: 'post',
    params: query
  });
}

function queryById(query) {
  return (0, _request.default)({
    url: '/bus_app/refund_transaction/queryById',
    method: 'post',
    params: query
  });
}

function updateFirst(data) {
  return (0, _request.default)({
    url: '/bus_app/refund_transaction/updateFirst',
    method: 'post',
    data: data
  });
}

function updateSecond(data) {
  return (0, _request.default)({
    url: '/bus_app/refund_transaction/updateSecond',
    method: 'post',
    data: data
  });
}

function updateReject(data) {
  return (0, _request.default)({
    url: '/bus_app/refund_transaction/updateReject',
    method: 'post',
    data: data
  });
}