var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "检测信息" },
    [
      _vm.hisShow
        ? _c("order-check-history-list", {
            ref: "checkHistoryList",
            attrs: { disabled: true, "order-info": _vm.orderInfo },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("检测信息")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "用户描述", prop: "userDescribe" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                          },
                          model: {
                            value: _vm.orderInfo.userDescribe,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "userDescribe", $$v)
                            },
                            expression: "orderInfo.userDescribe",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "问题出现前" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                          },
                          model: {
                            value: _vm.orderInfo.problemBefore,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "problemBefore", $$v)
                            },
                            expression: "orderInfo.problemBefore",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "问题出现前/后" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                          },
                          model: {
                            value: _vm.orderInfo.problemAfter,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "problemAfter", $$v)
                            },
                            expression: "orderInfo.problemAfter",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "resolve方案" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                          },
                          model: {
                            value: _vm.orderInfo.problemResolve,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "problemResolve", $$v)
                            },
                            expression: "orderInfo.problemResolve",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测服务类型" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.checkType,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "checkType", $$v)
                              },
                              expression: "orderInfo.checkType",
                            },
                          },
                          _vm._l(
                            _vm.businessTypeOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测服务子类型" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.checkSubType,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "checkSubType", $$v)
                              },
                              expression: "orderInfo.checkSubType",
                            },
                          },
                          _vm._l(
                            _vm.businessSubTypeOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "用户使用时长" },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "filter-item",
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.orderInfo.checkUseTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "checkUseTime", $$v)
                              },
                              expression: "orderInfo.checkUseTime",
                            },
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _vm._v("小时"),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测结果" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.orderInfo.checkResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.orderInfo, "checkResult", $$v)
                              },
                              expression: "orderInfo.checkResult",
                            },
                          },
                          _vm._l(
                            _vm.checkResultOptions,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测人" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.checkUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "checkUserName", $$v)
                            },
                            expression: "orderInfo.checkUserName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测时间" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orderInfo.checkTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "checkTime", $$v)
                            },
                            expression: "orderInfo.checkTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { label: "检测备注" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            autosize: { minRows: 2 },
                            maxlength: "1000",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.orderInfo.checkRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderInfo, "checkRemark", $$v)
                            },
                            expression: "orderInfo.checkRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-left": "160px" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.schemeListLoading,
                            expression: "schemeListLoading",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          border: "",
                          stripe: "",
                          "tooltip-effect": "dark",
                          "header-cell-style": {
                            background: "#eef1f6",
                            color: "#606266",
                            height: "40px",
                          },
                          data: _vm.schemeCheckList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "indexNum",
                            label: "序号",
                            resizable: "",
                            align: "center",
                            "min-width": "10",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "problemTypeName",
                            label: "问题分类",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "faultName",
                            label: "问题代码",
                            resizable: "",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "repairNames",
                            label: "处理方式",
                            resizable: "",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      (_vm.orderInfo.serviceFeeId === "" ||
        _vm.orderInfo.serviceFeeId === null) &&
      (_vm.orderInfo.checkSubType === "0102" ||
        _vm.orderInfo.checkSubType === "0103")
        ? _c(
            "div",
            [
              _c(
                "el-divider",
                {
                  staticClass: "myStyle",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("服务sku信息")]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "border-top": "none" },
                  attrs: { shadow: "never" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "160px" } },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.skuFeeListLoading,
                                    expression: "skuFeeListLoading",
                                  },
                                ],
                                attrs: {
                                  size: "mini",
                                  border: "",
                                  stripe: "",
                                  "tooltip-effect": "dark",
                                  "header-cell-style": {
                                    background: "#eef1f6",
                                    color: "#606266",
                                    height: "40px",
                                  },
                                  data: _vm.skuFeeList,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "indexNum",
                                    label: "序号",
                                    width: "55",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "code",
                                    label: "服务SKU编码",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "name",
                                    label: "服务SKU名称",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "chargeType",
                                    label: "收费类型",
                                    width: "150",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("feeTypeFilter")(
                                                    row.chargeType
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1612796705
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      prop: "quantity",
                                      label: "数量",
                                      width: "160",
                                      resizable: "",
                                      align: "center",
                                    },
                                  },
                                  [[_vm._v("1")]],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderInfo.checkType === "02"
        ? _c(
            "div",
            [
              _c(
                "el-divider",
                {
                  staticClass: "myStyle",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("部件信息")]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "border-top": "none" },
                  attrs: { shadow: "never" },
                },
                [
                  _vm.orderInfo.checkSubType === "0203" &&
                  _vm.orderInfo.serviceFeeId !== null &&
                  _vm.orderInfo.serviceFeeId !== ""
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 16, pull: 1 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      label: "用户收费",
                                      prop: "serviceFeeId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: "",
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.orderInfo.serviceFeeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "serviceFeeId",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.serviceFeeId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.userfeeSelect,
                                        function (item, index) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: index,
                                              attrs: {
                                                label:
                                                  item.name +
                                                  "（¥" +
                                                  item.priceIncludeTaxYuan +
                                                  "）",
                                                value: item.id,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "¥" +
                                                      _vm._s(
                                                        item.priceIncludeTaxYuan
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "160px" } },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.skuListLoading,
                                    expression: "skuListLoading",
                                  },
                                ],
                                attrs: {
                                  size: "mini",
                                  border: "",
                                  stripe: "",
                                  "tooltip-effect": "dark",
                                  "header-cell-style": {
                                    background: "#eef1f6",
                                    color: "#606266",
                                    height: "40px",
                                  },
                                  data: _vm.skuCheckList,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "indexNum",
                                    label: "序号",
                                    resizable: "",
                                    align: "center",
                                    "min-width": "20",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuCode",
                                    label: "物料编码",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuName",
                                    label: "物料名称",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuType",
                                    label: "物料分类",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("skuTypeFilter")(
                                                  row.skuType
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1900196742
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuTag",
                                    label: "物料标识",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("skuTagFilter")(
                                                  row.skuTag
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1516746950
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "categoryName",
                                    label: "品类",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "modelName",
                                    label: "型号",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "quantity",
                                    label: "数量",
                                    resizable: "",
                                    align: "center",
                                    "min-width": "20",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [_vm._v(_vm._s(row.quantity))]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2861028836
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderInfo.checkSubType === "0102" ||
      _vm.orderInfo.checkSubType === "0103"
        ? _c(
            "div",
            [
              _c(
                "el-divider",
                {
                  staticClass: "myStyle",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("部件信息")]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "border-top": "none" },
                  attrs: { shadow: "never" },
                },
                [
                  _vm.orderInfo.serviceFeeId !== null &&
                  _vm.orderInfo.serviceFeeId !== ""
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 16, pull: 1 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "用户收费" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: "",
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.orderInfo.serviceFeeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderInfo,
                                              "serviceFeeId",
                                              $$v
                                            )
                                          },
                                          expression: "orderInfo.serviceFeeId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.userfeeSelect,
                                        function (item, index) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: index,
                                              attrs: {
                                                label:
                                                  item.name +
                                                  "（¥" +
                                                  item.priceIncludeTaxYuan +
                                                  "）",
                                                value: item.id,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "¥" +
                                                      _vm._s(
                                                        item.priceIncludeTaxYuan
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "160px" } },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.skuListLoading,
                                    expression: "skuListLoading",
                                  },
                                ],
                                attrs: {
                                  size: "mini",
                                  border: "",
                                  stripe: "",
                                  "tooltip-effect": "dark",
                                  "header-cell-style": {
                                    background: "#eef1f6",
                                    color: "#606266",
                                    height: "40px",
                                  },
                                  data: _vm.skuCheckList,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "indexNum",
                                    label: "序号",
                                    resizable: "",
                                    align: "center",
                                    "min-width": "20",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuCode",
                                    label: "物料编码",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuName",
                                    label: "物料名称",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuType",
                                    label: "物料分类",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("skuTypeFilter")(
                                                  row.skuType
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1900196742
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "skuTag",
                                    label: "物料标识",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("skuTagFilter")(
                                                  row.skuTag
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1516746950
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "categoryName",
                                    label: "品类",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "modelName",
                                    label: "型号",
                                    resizable: "",
                                    align: "center",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "feeType",
                                    label: "收费类型",
                                    align: "center",
                                    "min-width": "40",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("feeTypeFilter")(
                                                  row.feeType
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1670220358
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "quantity",
                                    label: "数量",
                                    resizable: "",
                                    align: "center",
                                    "min-width": "20",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [_vm._v(_vm._s(row.quantity))]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2861028836
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-divider",
        { staticClass: "myStyle", attrs: { "content-position": "left" } },
        [_vm._v("检测附件")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { "border-top": "none" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c("upload-file", {
                              attrs: {
                                disabled: true,
                                "attachment-list":
                                  _vm.orderInfo.attachmentList3,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }