"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers.common['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */
function (response) {
  var res = response.data; // console.dir(res)
  // Determine the request status by custom code

  if (res.code && res.code !== 200 && res.code !== 401 && res.code !== 403 && res.code !== 500) {
    (0, _elementUi.Message)({
      message: res.msg || '系统异常请联系系统管理员！',
      type: 'warning',
      duration: 5 * 1000
    }); // 重新登陆判断

    if (res.code === 203) {
      _elementUi.MessageBox.confirm('您的会话已过期，继续操作需要重新登陆。', '会话过期', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/logout').then(function () {
          location.reload();
        });
      });
    }

    return Promise.reject(new Error(res.msg || 'Error'));
  }

  return res;
}, function (error) {
  // console.log('err:' + error) // for debug
  // console.dir(error.response.data)
  var errmsg = error.message;

  if (error.response && error.response.data) {
    // 重新登陆判断
    if (error.response.data.code === 203) {
      _elementUi.MessageBox.confirm('您的会话已过期，继续操作需要重新登陆。', '会话过期', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/logout').then(function () {
          location.reload();
        });
      });

      return Promise.reject(error);
    }

    errmsg = error.response.data.error_description || error.response.data.msg || error.message;
  }

  (0, _elementUi.Message)({
    message: errmsg,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;