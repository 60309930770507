var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticClass: "form-container",
          attrs: { model: _vm.notice, rules: _vm.rules, size: "50%" },
        },
        [
          _c(
            "sticky",
            { attrs: { "class-name": "sub-navbar default" } },
            [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple",
                  staticStyle: { float: "left", "padding-left": "1.3rem" },
                },
                [
                  _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                    _vm._v("公告管理详细"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notice.state !== _vm.stateEnum[1].value,
                      expression: "notice.state !== stateEnum[1].value",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_NOTICE_ADD"],
                      expression: "['ROLE_NOTICE_ADD']",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { disabled: _vm.loading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createRoUpdate("0")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-folder" }),
                  _vm._v("\n        保存\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notice.state !== _vm.stateEnum[1].value,
                      expression: "notice.state !== stateEnum[1].value",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_NOTICE_ASSIGN"],
                      expression: "['ROLE_NOTICE_ASSIGN']",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { disabled: _vm.loading, type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.createRoUpdate("1")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-success" }),
                  _vm._v("\n        确认发布\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { plain: "", type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back(-1)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-position" }),
                  _vm._v("\n        返回\n      "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: { prop: "title" },
                        },
                        [
                          _c(
                            "MDinput",
                            {
                              attrs: {
                                maxlength: 100,
                                name: "name",
                                required: "",
                              },
                              model: {
                                value: _vm.notice.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.notice, "title", $$v)
                                },
                                expression: "notice.title",
                              },
                            },
                            [_vm._v("\n              公告标题\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: { "label-width": "90px", label: "重要性:" },
                        },
                        [
                          _c("el-rate", {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              max: 3,
                              colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
                              "low-threshold": 1,
                              "high-threshold": 3,
                            },
                            model: {
                              value: _vm.notice.noticeLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.notice, "noticeLevel", $$v)
                              },
                              expression: "notice.noticeLevel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: { prop: "content" },
                },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.notice.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.notice, "content", $$v)
                      },
                      expression: "notice.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "附件信息", prop: "address" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.uploadAction,
                                multiple: _vm.isMultiple,
                                limit: _vm.fileLimit,
                                "file-list": _vm.fileList,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "on-success": _vm.handleSuccess,
                                "on-exceed": _vm.handleExceed,
                                "on-error": _vm.uploadError,
                                "before-upload": _vm.beforeUpload,
                                "before-remove": _vm.beforeRemove,
                                disabled: _vm.isReadOnly,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    disabled: _vm.isReadOnly,
                                  },
                                },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [_vm._v("最多上传10个附件，且不超过10M")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("选择通知机构"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "listTableDiv" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            "header-cell-style": {
                              background: "#eef1f6",
                              color: "#606266",
                              height: "50px",
                            },
                            border: "",
                            stripe: "",
                            "tooltip-effect": "dark",
                            data: _vm.listData,
                            "highlight-current-row": "",
                          },
                          on: {
                            "selection-change": _vm.handleSelectionChange,
                            "row-click": _vm.rowClickSelection,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "55",
                              align: "center",
                              prop: "id",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "code", label: "机构编码" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "机构名称",
                              resizable: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "orgName", label: "上级机构" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: "机构类型",
                              resizable: "",
                              formatter: _vm.typeFormat,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "memo",
                              label: "备注",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("a", { attrs: { id: "a_id" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }