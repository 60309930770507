"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/uploadImgs/index"));

var _index2 = _interopRequireDefault(require("@/components/uploadFileMsg/index"));

var _quotaApprove = require("@/api/reduction/approve/quotaApprove");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ApproveAdjunct',
  components: {
    uploadImgs: _index.default,
    uploadFileMsg: _index2.default
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      isAudit: true,
      quotaApprove: {}
    };
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadCheckInfo', function (orderNo) {
      _this.getApproveByOrderNo(orderNo);
    });
  },
  methods: {
    initSelect: function initSelect() {},
    // 查询审批详情
    getApproveByOrderNo: function getApproveByOrderNo(orderNo) {
      var _this2 = this;

      (0, _quotaApprove.getApproveByOrderNo)(orderNo).then(function (response) {
        if (response.data) {
          _this2.quotaApprove = response.data;
        }
      });
    }
  }
};
exports.default = _default;