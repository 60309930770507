"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _defineProperty2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _utils = require("@/utils");

var _fcFree = require("@/api/register/fcFree");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
var _default = {
  components: {
    uploadFile: _uploadFile.default
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return (0, _defineProperty2.default)({
      countryOptions: _constant.default.countryOptions,
      refDateBefore: '',
      rightList: [],
      typeOptions: [{
        'name': '免费清洁服务',
        'val': '1'
      }]
    }, "countryOptions", _constant.default.countryOptions);
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadFreeSn', function (sn) {
      if (sn) {
        setTimeout(function () {
          _this.getRightList(sn);
        }, 1500);
      }
    });
  },
  methods: {
    change: function change() {
      this.refDateChange();
      this.$emit('dataChange', this.orderInfo);
    },
    refDateChange: function refDateChange() {
      var oldDays = (0, _utils.convertStringToDate)(this.orderInfo.refDateBefore);
      var nowDays = new Date(this.orderInfo.refDate);
      var iDays = (0, _utils.computeDays2)(oldDays, nowDays);
      this.orderInfo.benefitEndDate = this.getDay(iDays);
      this.refDateBefore = (0, _utils.parseTime)(this.orderInfo.refDate, '{y}-{m}-{d}');
      this.orderInfo.refDateBefore = this.refDateBefore;
    },
    getDay: function getDay(day) {
      var benefitEndDate = new Date(this.orderInfo.benefitEndDate);
      var targetday_milliseconds = benefitEndDate.getTime() + 1000 * 60 * 60 * 24 * day;
      benefitEndDate.setTime(targetday_milliseconds);
      var tYear = benefitEndDate.getFullYear();
      var tMonth = benefitEndDate.getMonth();
      var tDate = benefitEndDate.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + '-' + tMonth + '-' + tDate;
    },
    doHandleMonth: function doHandleMonth(month) {
      var m = month;

      if (month.toString().length === 1) {
        m = '0' + month;
      }

      return m;
    },
    // 点击详情
    toRiskDetail: function toRiskDetail(id) {
      this.$router.push({
        name: 'createRisk',
        params: {
          id: id
        }
      });
    },
    getRightList: function getRightList(sn) {
      var _this2 = this;

      (0, _fcFree.getRightList)(sn).then(function (response) {
        _this2.rightList = response.data;
      });
    },
    gotoEditHtml: function gotoEditHtml(row) {
      this.$router.push({
        name: 'queryOrderDetail',
        params: {
          id: row.orderNum
        }
      });
    },
    typeFormat: function typeFormat(row) {
      return this.typeOptions.map(function (type) {
        if (row.rightType === type.val) {
          return type.name;
        }

        return '';
      });
    },
    isEnjoyFormat: function isEnjoyFormat(row) {
      return row.isEnjoy === '1' ? '是' : '否';
    },
    isUsedFormat: function isUsedFormat(row) {
      return row.isUsed === '1' ? '是' : '否';
    }
  }
};
exports.default = _default;