"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskStateFilter = TaskStateFilter;
exports.businessSubTypeFilter = businessSubTypeFilter;
exports.businessTypeFilter = businessTypeFilter;
exports.callResultFilter = callResultFilter;
exports.callSignFilter = callSignFilter;
exports.checkResultFilter = checkResultFilter;
exports.countryFilter = countryFilter;
exports.couponFilter = couponFilter;
exports.feeOptionFilter = feeOptionFilter;
exports.feeTypeFilter = feeTypeFilter;
exports.feeTypeFilter1 = feeTypeFilter1;
exports.linkTypeFilter = linkTypeFilter;
exports.linkTypeOptionsFilter = linkTypeOptionsFilter;
exports.orderStageFilter = orderStageFilter;
exports.paymentChannelFilter = paymentChannelFilter;
exports.postTypeFilter = postTypeFilter;
exports.quotaFilter = quotaFilter;
exports.showOverflowTooltipFilter = showOverflowTooltipFilter;
exports.skuTagFilter = skuTagFilter;
exports.skuTypeFilter = skuTypeFilter;
exports.stateFilter = stateFilter;
exports.subStateBadgeFilter = subStateBadgeFilter;
exports.subStateFilter = subStateFilter;
exports.urgentLevelFilter = urgentLevelFilter;
exports.warrantyFilter = warrantyFilter;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

/** 状态 */
function subStateBadgeFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.subStateBadge[state.trim()];
}
/** 状态 */


function stateFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.stateMap[state.trim()];
}
/** 子状态 */


function subStateFilter(state) {
  if (!state) {
    return;
  }

  return _constant.default.subStateMap[state.trim()];
}
/** 邮寄方式 */


function postTypeFilter(type) {
  if (!type) {
    return;
  }

  return _constant.default.postTypeMap[type.trim()];
}
/** 服务类型 */


function businessTypeFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.businessTypeMap[way.trim()];
}
/** 服务子类型 */


function businessSubTypeFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.businessSubTypeMap[way.trim()];
}
/** 紧急程度 */


function urgentLevelFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.urgentLevelMap[way.trim()];
}
/** 收费类型 */


function feeTypeFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.feeTypeMap[way.trim()];
}
/** 收费类型 */


function feeTypeFilter1(way) {
  if (!way) {
    return;
  }

  return _constant.default.feeTypeMap1[way.trim()];
}
/** 服务选项 */


function feeOptionFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.feeOptionMap[way.trim()];
}
/** 默认联系人 */


function linkTypeFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.linkTypeMap[way.trim()];
}
/** 外呼类型 */


function callSignFilter(sign) {
  if (!sign) {
    return;
  }

  return _constant.default.callSignMap[sign.trim()];
}
/** 检测结果 */


function checkResultFilter(sign) {
  if (!sign) {
    return;
  }

  return _constant.default.checkResultMap4all[sign.trim()];
}
/** 超长字段截取长度显示 */


function showOverflowTooltipFilter(remark) {
  if (!remark) {
    return '';
  }

  if (remark.length > 12) {
    return remark.substring(0, 12) + '...';
  }

  return remark;
}
/** SKU分类 */


function skuTypeFilter(type) {
  var typeMap = {
    1: '核心件',
    2: '重要件',
    3: '一般件'
  };
  return typeMap[type];
}
/** SKU标识 */


function skuTagFilter(tag) {
  var tagMap = {
    1: '成品',
    2: '配件'
  };
  return tagMap[tag];
}
/** 外呼结果 */


function callResultFilter(sign) {
  if (!sign) {
    return;
  }

  return _constant.default.callResultMap[sign.trim()];
}
/** 支付渠道 */


function paymentChannelFilter(sign) {
  if (!sign) {
    return;
  }

  return _constant.default.paymentChannelMap[sign.trim()];
}

function linkTypeOptionsFilter(type) {
  if (!type) {
    return;
  }

  return _constant.default.linkTypeMap[type.trim()];
}
/** 减免审批状态 */


function quotaFilter(tag) {
  var tagMap = {
    0: '未审批',
    1: '审批通过',
    2: '审批未通过',
    3: '已审批'
  };
  return tagMap[tag];
}
/** 工单任务状态 */


function TaskStateFilter(type) {
  if (!type) {
    return;
  }

  return _constant.default.TaskStateMap[type.trim()];
}
/** 服务单阶段 */


function orderStageFilter(way) {
  if (!way) {
    return;
  }

  return _constant.default.orderStageMap[way.trim()];
}
/** 优惠券使用状态 */


function couponFilter(type) {
  if (!type) {
    return;
  }

  return _constant.default.couponMap[type.trim()];
}

function warrantyFilter(type) {
  return type ? '是' : '否';
}

function countryFilter(country) {
  if (!country) {
    return;
  }

  return _constant.default.countryMap[country];
}