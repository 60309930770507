"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncImport = asyncImport;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 创建异步导入任务
 * @param params
 * @returns {AxiosPromise}
 */
function asyncImport(params) {
  return (0, _request.default)({
    url: '/sys_app/asyncImport/beforeImport',
    method: 'post',
    async: false,
    params: params
  });
}