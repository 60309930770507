"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Decrypt = Decrypt;
exports.Encrypt = Encrypt;
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;

require("core-js/modules/es6.regexp.to-string");

var _cryptoJs = _interopRequireDefault(require("crypto-js/crypto-js"));

// import Cookies from 'js-cookie'
// 访问令牌Cookie名称
var TokenKey = '$token';
/**
 * Cookies取得TokenKey
 */

function getToken() {
  return sessionStorage.getItem(TokenKey); // return Cookies.get(TokenKey)
}
/**
 * Cookies保存TokenKey
 * @param {string} token 访问令牌
 */


function setToken(token) {
  sessionStorage.setItem(TokenKey, token); // return Cookies.set(TokenKey, token)
}
/**
 * Cookies删除TokenKey
 */


function removeToken() {
  sessionStorage.removeItem(TokenKey); // return Cookies.remove(TokenKey)
}

// 加密解密秘钥
var KEY = _cryptoJs.default.enc.Utf8.parse(process.env.VUE_APP_AES_KEY);
/**
 * AES秘钥
 * @param {string} keyStr 加密秘钥字符串
 */


function getAesKey(keyStr) {
  var key = KEY;

  if (keyStr) {
    key = _cryptoJs.default.enc.Utf8.parse(keyStr);
  }

  return key;
}
/**
 * AES加密 返回base64
 * @param {string} word 需要加密的字符串
 * @param {string} keyStr 加密秘钥字符串
 */


function Encrypt(word, keyStr) {
  var key = getAesKey(keyStr);

  var src = _cryptoJs.default.enc.Utf8.parse(word);

  var encrypted = _cryptoJs.default.AES.encrypt(src, key, {
    mode: _cryptoJs.default.mode.ECB,
    padding: _cryptoJs.default.pad.Pkcs7
  });

  return _cryptoJs.default.enc.Base64.stringify(encrypted.ciphertext);
}
/**
 * AES 解密 返回原文字符串
 * @param {string} word 待解密的base64字符串
 * @param {string} keyStr 加密秘钥字符串
 */


function Decrypt(word, keyStr) {
  var key = getAesKey(keyStr);

  var src = _cryptoJs.default.enc.Base64.stringify(_cryptoJs.default.enc.Base64.parse(word));

  var decrypt = _cryptoJs.default.AES.decrypt(src, key, {
    mode: _cryptoJs.default.mode.ECB,
    padding: _cryptoJs.default.pad.Pkcs7
  });

  var decryptedStr = decrypt.toString(_cryptoJs.default.enc.Utf8);
  return decryptedStr.toString();
}