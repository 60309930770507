var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { "min-width": "280px", "max-width": "970px" } },
    [
      _c(
        "el-main",
        { staticStyle: { padding: "0px" } },
        [
          _c(
            "div",
            { staticClass: "top-buttons-group" },
            [
              _c(
                "FixTop",
                { attrs: { "class-name": "sub-navbar default" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.returnBtn },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              staticClass: "no-collapse-header",
              model: {
                value: _vm.activeNames4Condition,
                callback: function ($$v) {
                  _vm.activeNames4Condition = $$v
                },
                expression: "activeNames4Condition",
              },
            },
            [
              _c("el-collapse-item", { attrs: { name: "1" } }, [
                _c(
                  "div",
                  { staticClass: "searchInfoDiv" },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: {
                          "padding-left": "10px",
                          "padding-right": "10px",
                          "padding-top": "10px",
                        },
                        attrs: {
                          "label-width": "100px",
                          "label-position": "left",
                          size: "small",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "报修手机号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "报修手机号" },
                                  model: {
                                    value: _vm.listQuery.applicantTel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "applicantTel",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.applicantTel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "报修人姓名" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "报修人姓名" },
                                  model: {
                                    value: _vm.listQuery.applicantMan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "applicantMan",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.applicantMan",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "咨询单主状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择状态",
                                    },
                                    model: {
                                      value: _vm.listQuery.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "state", $$v)
                                      },
                                      expression: "listQuery.state",
                                    },
                                  },
                                  _vm._l(
                                    _vm.stateOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "咨询单子状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择子状态",
                                    },
                                    model: {
                                      value: _vm.listQuery.subState,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "subState", $$v)
                                      },
                                      expression: "listQuery.subState",
                                    },
                                  },
                                  _vm._l(
                                    _vm.subStateOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "业务类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择业务类型",
                                    },
                                    model: {
                                      value: _vm.listQuery.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "type", $$v)
                                      },
                                      expression: "listQuery.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.typeOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品SN号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "产品SN号" },
                                  on: { input: _vm.checkSnIn },
                                  model: {
                                    value: _vm.listQuery.sn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "sn", $$v)
                                    },
                                    expression: "listQuery.sn",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy 年 MM 月 dd 日",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.listQuery.createDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "createDate", $$v)
                                    },
                                    expression: "listQuery.createDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "特定内容" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "特定内容" },
                                  model: {
                                    value: _vm.listQuery.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "content", $$v)
                                    },
                                    expression: "listQuery.content",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                "padding-bottom": "20px",
                "padding-right": "10px",
              },
            },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticClass: "grid-content bg-purple",
                    staticStyle: { float: "right" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.searchBtnClick },
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default", size: "small" },
                        on: { click: _vm.cleanBtnClick },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "padding-bottom": "20px",
                "padding-right": "10px",
                "padding-left": "10px",
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    stripe: "",
                    "header-cell-style": {
                      background: "#eef1f6",
                      color: "#606266",
                      height: "50px",
                    },
                    data: _vm.list,
                  },
                  on: { "cell-dblclick": _vm.gotoEditHtml },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "num", label: "咨询单号", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "link-type",
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoEditHtml(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.num))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "type",
                      label: "业务类型",
                      formatter: _vm.typeFormat,
                      align: "center",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bizNum",
                      label: "转单单号",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "link-type",
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoQueryHtml(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.bizNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "state",
                      label: "主状态",
                      formatter: _vm.stateFormat,
                      align: "center",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subState",
                      label: "子状态",
                      formatter: _vm.subStateFormat,
                      align: "center",
                      resizable: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                  layout: "total,sizes,prev,pager,next",
                  background: "",
                  small: "",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("a", { attrs: { id: "a_id" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }