"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 产品注册路由
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var registerRouter = {
  path: '/register/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'product/:id',
    name: 'ProductDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/product/detail'));
      });
    },
    meta: {
      title: '产品详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/product',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '产品',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'customerRegister/:id',
    name: 'CustomerRegisterDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/customer_register/detail'));
      });
    },
    meta: {
      title: '客户信息详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/customer_register',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '客户',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'productRegister/detail:id',
    name: 'SnProductRegisterDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/product_register/detail'));
      });
    },
    meta: {
      title: '产品注册详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/product_register',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '产品注册',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'SnRegisterFail/detail:id',
    name: 'SnRegisterFailDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/sn_register_fail/detail'));
      });
    },
    meta: {
      title: '产品注册异常详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/sn_register_fail',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '产品注册',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'productEnrollment/detail:id',
    name: 'productEnrollmentDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/product_enrollment/detail'));
      });
    },
    meta: {
      title: '仅登记信息详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/product_enrollment',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '仅登记信息',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'enrollProduct/detail:id',
    name: 'enrollProductDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/enroll_product/detail'));
      });
    },
    meta: {
      title: '仅登记产品详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/enroll_product',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '仅登记产品',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'equityExtendConfig/detail:id',
    name: 'EquityExtendConfigDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/register/equity_extend_config/detail'));
      });
    },
    meta: {
      title: '会员权益延保配置',
      icon: 'table',
      hidden: true,
      activeMenu: '/register/equity_extend_config',
      parent: {
        path: '/register',
        redirect: 'noRedirect',
        meta: {
          title: '会员权益延保',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = registerRouter;
exports.default = _default;