"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

var _approveAdjunct = _interopRequireDefault(require("@/components/reduction/approve/approveAdjunct"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 检测信息
// 收货信息
// 产品注册信息
// 费用明细
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    orderCheck: _check.default,
    orderReceipt: _receipt.default,
    orderProduct: _product.default,
    treeSelect: _treeSelect.default,
    orderPrice: _checkPrice.default,
    approveLists: _approveAdjunct.default
  },
  filters: {
    callSignFilter: _filterUtils.callSignFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      productShow: '0',
      receiptShow: '0',
      checkShow: '0',
      priceShow: '0',
      approvalShow: '0',
      callSignOptions: _constant.default.callSignOptions,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      callResultOptions: [],
      callResultOptions2: [],
      cleanIsPay: false,
      label1: '外呼结果',
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      orderInfo2: {},
      rules: {
        calloutResult: [{
          required: true,
          message: '外呼结果是必填的',
          trigger: 'blur'
        }],
        calloutResult2: [{
          required: true,
          message: '外呼结果是必填的!',
          trigger: 'blur'
        }],
        calloutRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }],
        calloutPaymentNum: [{
          required: true,
          message: '到账流水号是必填的',
          trigger: 'blur'
        }, {
          max: 50,
          message: '最大长度 50 个字符',
          trigger: 'blur'
        }],
        serviceFeeId: [{
          required: true,
          message: '用户收费是必填的',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      userfeeSelect: [],
      categorySelectTree: [],
      categorySelect: [],
      modelSelect: [],
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3'
      },
      skuSelect: [],
      skuListLoading: false,
      skuList: [],
      exchangeFlag: false
    };
  },
  computed: {
    calloutResult: function calloutResult() {
      return this.orderInfo.calloutResult;
    },
    cleanStatus: function cleanStatus() {
      return ['0', '1'].includes(this.orderInfo.cleanType);
    },
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      if (this.orderInfo.calloutResult === '5') {
        return this.skuList.length > 0 || this.skuList.length === 0 && (!this.skuAdd.skuCode || this.skuAdd.skuCode === '');
      } else {
        return true;
      }
    },
    // -----------------------------------------------------------------------------
    category: function category() {
      return this.skuAdd.category;
    },
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    } // -----------------------------------------------------------------------------

  },
  watch: {
    calloutResult: function calloutResult(val, oldval) {
      this.calloutResultChange(val, oldval);
    },
    // -----------------------------------------------------------------------------
    category: function category(val, oldval) {
      this.categorySelectChange(val, oldval);
    },
    search: function search(val) {
      this.list = this.oTableData.filter(function (item) {
        return ~item.name.indexOf(val);
      });
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    } // -----------------------------------------------------------------------------

  },
  created: function created() {
    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
    }
  },
  methods: {
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.skuAdd.category = value;
    },
    initCalloutResult: function initCalloutResult(sign) {
      if (sign === '1') {
        this.callResultOptions = _constant.default.callResultOptions1;
      } else if (sign === '2') {
        this.callResultOptions = _constant.default.callResultOptions2;
      } else if (sign === '3') {
        this.callResultOptions = _constant.default.callResultOptions3;
      } else if (sign === '4') {
        this.callResultOptions = _constant.default.callResultOptions4;
      } else if (sign === '7') {
        this.callResultOptions = _constant.default.callResultOptions7;
      } else if (sign === '8') {
        this.callResultOptions = _constant.default.callResultOptions8;
        this.callResultOptions2 = _constant.default.callResultOptions2;
      }
    },
    calloutResultChange: function calloutResultChange(val, oldval) {
      var _this = this;

      if (val === '5') {
        var newSkuItem = {};
        newSkuItem.orderId = this.orderInfo.id;
        newSkuItem.orderNo = this.orderInfo.orderNo;
        newSkuItem.quantity = 1;
        (0, _select.getModeBySku)({
          code: this.orderInfo.sku
        }).then(function (response) {
          if (response.data) {
            newSkuItem.skuId = response.data.skuId;
            newSkuItem.skuCode = response.data.code;
            newSkuItem.skuName = response.data.name;
            newSkuItem.skuType = response.data.type;
            newSkuItem.skuTag = response.data.skuTag;
            newSkuItem.priceExcludeTax = response.data.priceExcludeTax;
            newSkuItem.priceIncludeTax = response.data.priceIncludeTax;
            newSkuItem.brandId = response.data.brandId;
            newSkuItem.categoryId = response.data.categoryId;
            newSkuItem.categoryName = response.data.categoryName;
            newSkuItem.modelId = response.data.modelId;
            newSkuItem.modelName = response.data.modelName;
            newSkuItem.ddirStock = response.data.ddirStock;
            newSkuItem.stock = response.data.stock;

            if (_this.skuList.length === 0) {
              _this.skuList.push(newSkuItem);

              var indexNum = 0;

              _this.skuList.map(function (v) {
                _this.$set(v, 'indexNum', ++indexNum); // 动态加字段


                _this.$set(v, 'edit', false); // 动态加字段


                return v;
              });
            }
          }
        });
      }
    },
    initSelect: function initSelect() {
      var _this2 = this;

      (0, _select.getSkuFeeSelect)({
        isEnable: '1'
      }).then(function (res) {
        _this2.userfeeSelect = res.data;
      });
      (0, _select.getCategorySelect)({
        isEnable: '1'
      }).then(function (res) {
        _this2.categorySelect = res.data.records;
      });
      this.loadModelSelect();
      this.loadSkuSelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this3 = this;

      var params = {
        categoryId: this.skuAdd.category,
        isEnable: '1'
      };
      (0, _select.getModelSelect)(params).then(function (res) {
        _this3.modelSelect = res.data;
      });
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this4 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };

      if (this.orderInfo.calloutResult === '5') {
        params.categoryId = this.skuAdd.category;
        params.modelId = this.skuAdd.model;
        params.tag = '1';
      }

      (0, _select.getSkuSelect)(params).then(function (res) {
        _this4.skuSelect = res.data;
      });
    },
    categorySelectChange: function categorySelectChange(val, oldval) {
      if (oldval !== undefined && (!val || val !== oldval)) {
        this.skuAdd.model = '';
        this.modelSelect = [];
        this.modelSelectChange();
      }
    },
    modelSelectChange: function modelSelectChange() {
      this.skuAdd.skuCode = '';
      this.skuSelect = [];
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this5 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this5.orderInfo = response.data;

          if (_this5.orderInfo.cleanType === '1') {
            _this5.label1 = '清洁服务外呼结果';
          }

          _this5.initCalloutResult(_this5.orderInfo.callSign);

          if (_this5.orderInfo.checkSubType === '0102' || _this5.orderInfo.checkSubType === '0103' || _this5.orderInfo.checkType === '02' && _this5.orderInfo.checkSubType === '0203') {
            _this5.priceShow = '1';
          }

          if (_this5.orderInfo.callSign === '7') {
            _this5.approvalShow = '1';
          }

          if (_this5.orderInfo.subType === '0203' && _this5.orderInfo.orderSkuList !== null && _this5.orderInfo.orderSkuList[0].exchange !== null && _this5.orderInfo.orderSkuList[0].exchange !== '0' && _this5.orderInfo.firstClassCategory !== null && _this5.orderInfo.firstClassCategory.isEnable === '0') {
            _this5.exchangeFlag = true;
          }

          _this5.$refs.orderProduct.$emit('loadFreeSn', _this5.orderInfo.sn);

          _this5.$refs.checkPrice.$emit('loadCheckInfo', _this5.orderInfo.id);

          _this5.$refs.approveOrder.$emit('loadCheckInfo', _this5.orderInfo.orderNo);

          _this5.$refs.receiptOrder.$emit('loadExpressGoods', _this5.id);

          _this5.$refs.checkOrder.$emit('loadcheckInfo', {
            id: _this5.id
          }); // 是清洁服务单，且产生了清洁维修单。查询清洁维修单详情


          if (_this5.orderInfo.cleanType === '1' && _this5.orderInfo.cleanOrderNo) {
            (0, _order.queryById)(_this5.orderInfo.cleanOrderNo).then(function (response) {
              if (response.data) {
                _this5.orderInfo2 = response.data;
                _this5.orderInfo2.callSign = '2'; // 清洁维修单检测子类型为保外

                if (_this5.orderInfo2.checkSubType === '0102' || _this5.orderInfo2.checkSubType === '0103' || _this5.orderInfo2.checkSubType === '0203') {
                  _this5.$refs.checkPrice.$emit('loadCheckInfo', _this5.orderInfo2.id);

                  _this5.$refs.approveOrder.$emit('loadCheckInfo', _this5.orderInfo2.orderNo);

                  _this5.cleanIsPay = true;
                }
              }
            });
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 验证方法：[保外转换货]必须添加一个“成品”物料  W2106300001  W2109170001
    validateList: function validateList() {
      if (this.orderInfo.calloutResult === '5' && this.skuList.length === 0) {
        this.$message({
          showClose: true,
          message: '【换货服务】必须添加一个成品物料SKU',
          type: 'error'
        });
        return false;
      }

      return true;
    },
    verifyReduction: function verifyReduction() {
      if (!this.cleanIsPay) {
        if (this.orderInfo.calloutResult === '4' && (this.orderInfo.quotaState === '' || this.orderInfo.quotaState === null)) {
          this.$message({
            showClose: true,
            message: '申请减免必须选择减免',
            type: 'error'
          });
          return false;
        } else if (!this.$refs.checkPrice.priceFlag) {
          this.$message({
            showClose: true,
            message: '确认外呼必须保存费用明细',
            type: 'warning'
          });
          return false;
        }
      } else if (this.cleanIsPay) {
        if (this.orderInfo.calloutResult2 === '4' && (this.orderInfo2.quotaState === '' || this.orderInfo2.quotaState === null)) {
          this.$message({
            showClose: true,
            message: '申请减免必须选择减免',
            type: 'error'
          });
          return false;
        } else if (!this.$refs.checkPrice.priceFlag) {
          this.$message({
            showClose: true,
            message: '确认外呼必须保存费用明细',
            type: 'warning'
          });
          return false;
        }
      }

      return true;
    },
    // 【外呼确认】
    callConfirm: function callConfirm() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this6.validateList() && _this6.verifyReduction()) {
          _this6.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this6.callConfirmSumbit();
          }).catch(function () {});
        } else {
          _this6.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    callConfirmSumbit: function callConfirmSumbit() {
      var _this7 = this;

      this.btnLoading = true;

      if (this.cleanIsPay) {
        this.orderInfo.quota = this.orderInfo2.quota;
      }

      this.orderInfo.orderSkuList = this.skuList;
      (0, _order.callOver)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this7.$message({
            showClose: true,
            message: '确认完了',
            type: 'success'
          });

          _this7.$store.dispatch('tagsView/delView', _this7.$route);

          _this7.$router.push({
            path: '/callout/service/list'
          });
        } else {
          _this7.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单物料SKU】
    addSkuClick: function addSkuClick() {
      var _this8 = this;

      // 检查是否已经添加
      if (this.skuList.find(function (v) {
        return v.skuCode === _this8.skuAdd.skuCode;
      })) {
        return;
      }

      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.feeType = this.skuAdd.feeType;
      newSkuItem.quantity = 1;
      var skuInfo = this.skuSelect.filter(function (ob) {
        return _this8.skuAdd.skuCode === ob.code;
      })[0];
      newSkuItem.skuId = skuInfo.id;
      newSkuItem.skuCode = skuInfo.code;
      newSkuItem.skuName = skuInfo.name;
      newSkuItem.skuType = skuInfo.type;
      newSkuItem.skuTag = skuInfo.skuTag;
      newSkuItem.priceExcludeTax = skuInfo.priceExcludeTax;
      newSkuItem.priceIncludeTax = skuInfo.priceIncludeTax;

      if (this.orderInfo.calloutResult === '5') {
        (0, _select.getModeBySku)({
          code: skuInfo.code
        }).then(function (response) {
          if (response.data) {
            newSkuItem.brandId = response.data.brandId;
            newSkuItem.categoryId = response.data.categoryId;
            newSkuItem.categoryName = response.data.categoryName;
            newSkuItem.modelId = response.data.modelId;
            newSkuItem.modelName = response.data.modelName;
            newSkuItem.ddirStock = response.data.ddirStock;
            newSkuItem.stock = response.data.stock;

            _this8.skuList.push(newSkuItem);

            var indexNum = 0;

            _this8.skuList.map(function (v) {
              _this8.$set(v, 'indexNum', ++indexNum); // 动态加字段


              _this8.$set(v, 'edit', false); // 动态加字段


              return v;
            });
          }
        });
      }

      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单物料SKU】
    removeSkuClick: function removeSkuClick(row) {
      var _this9 = this;

      this.btnLoading = true;
      this.skuList.splice(this.skuList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this9.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this9.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 更新【工单物料SKU】
    updateOrderSkuClick: function updateOrderSkuClick(row) {
      var _this10 = this;

      this.btnLoading = true;
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this10.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this10.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    changeCall: function changeCall() {
      this.orderInfo2.calloutResult = this.orderInfo.calloutResult2;
    },
    // 工单详情页面
    gotoEditHtml: function gotoEditHtml(orderNo) {
      if (orderNo) {
        if (orderNo.indexOf('W') === 0 || orderNo.indexOf('E') === 0 || orderNo.indexOf('C') === 0) {
          this.$router.push({
            name: 'queryOrderDetail',
            params: {
              id: orderNo
            }
          });
        }
      }
    }
  }
};
exports.default = _default;