"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 工单块
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var consultRouter = {
  path: '/consult/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/consult_order/detail/:isQuery/:id',
    name: 'consultOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/consult_order/detail'));
      });
    },
    meta: {
      title: '咨询工单详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/consult_order',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/consult_order/create/:id',
    name: 'consultCreateDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/consult_order/create'));
      });
    },
    meta: {
      title: '转服务工单',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/consult_order',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/consult_order/cancel/:id/:callId',
    name: 'ordercancelDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/consult_order/cancel'));
      });
    },
    meta: {
      title: '服务工单修改',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/consult_order',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/fcSplitOrder/detail/:orderNo',
    name: 'fcSplitOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/fcSplitOrder/detail'));
      });
    },
    meta: {
      title: '清洁服务待拆单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/fcSplitOrder',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/gdpr_task/detail/:isQuery/:id',
    name: 'gdprTaskDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/gdpr_task/detail'));
      });
    },
    meta: {
      title: 'gdpr任务详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/gdpr_task',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/vse_video_call/:num',
    name: 'VseVideoCallDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consult/vse_video_call/index'));
      });
    },
    meta: {
      title: 'vse通话详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/consult/consult_order',
      parent: {
        path: '/consult',
        redirect: 'noRedirect',
        meta: {
          title: '咨询工单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = consultRouter;
exports.default = _default;