"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.beforeUpload = beforeUpload;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 上传前签名
 * @param params
 */
function beforeUpload(params) {
  return (0, _request.default)({
    url: '/sys_app/sysUpload/beforeUpload',
    method: 'post',
    async: false,
    params: params
  });
}