"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _filterUtils = require("@/api/order/filterUtils");

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _checkHistoryList = _interopRequireDefault(require("@/components/order/checkHistoryList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 历史检测信息
var _default = {
  components: {
    uploadFile: _uploadFile.default,
    orderCheckHistoryList: _checkHistoryList.default
  },
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      skuListLoading: false,
      skuFeeListLoading: false,
      schemeListLoading: false,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      checkResultOptions: _constant.default.checkResultOptions4all,
      feeTypeOptions: _constant.default.feeTypeOptions,
      userfeeSelect: [],
      schemeCheckList: [],
      skuCheckList: [],
      skuFeeList: [],
      hisShow: true
    };
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadcheckInfo', function (param) {
      // const param = { isCheckHis: true, id: this.orderInfo.id }
      if (param && param.id) {
        _this.loadHistoryCheckList(param);

        _this.loadSchemeCheckList(param);

        _this.loadOrderSkuCheckList(param);

        _this.loadOrderSkuFeeList(param);
      }
    });
  },
  methods: {
    initSelect: function initSelect() {
      var _this2 = this;

      var params = {
        isEnable: undefined
      };
      (0, _select.getSkuFeeSelect)(params).then(function (res) {
        _this2.userfeeSelect = res.data;
      });
    },
    loadSchemeCheckList: function loadSchemeCheckList(param) {
      var _this3 = this;

      var params = {
        isCheckHis: param.isCheckHis,
        orderId: param.id,
        step: '3'
      };
      (0, _order.getSchemeList)(params).then(function (response) {
        if (response.data) {
          _this3.schemeCheckList = response.data;
        }
      });
    },
    loadOrderSkuCheckList: function loadOrderSkuCheckList(param) {
      var _this4 = this;

      var params = {
        isCheckHis: param.isCheckHis,
        orderId: param.id,
        step: '3'
      };
      (0, _order.getOrderSkuList)(params).then(function (response) {
        if (response.data) {
          _this4.skuCheckList = response.data;
          var indexNum = 0;
          _this4.skuCheckList = response.data.map(function (v) {
            _this4.$set(v, 'indexNum', ++indexNum); // 动态加字段


            return v;
          });
        }
      });
    },
    loadHistoryCheckList: function loadHistoryCheckList(param) {
      if (!param.isCheckHis && this.$refs.checkHistoryList) {
        this.$refs.checkHistoryList.$emit('loadcheckHistoryList', param.id);
      } else {
        this.hisShow = false;
      }
    },
    loadOrderSkuFeeList: function loadOrderSkuFeeList(param) {
      var _this5 = this;

      this.skuListLoading = true;

      if (param.isCheckHis) {
        var params = {
          hisId: param.hisId
        };
        (0, _order.getServiceSkuCheckHistory)(params).then(function (response) {
          if (response.data) {
            var indexNum = 0;
            _this5.skuFeeList = response.data.map(function (v) {
              _this5.$set(v, 'indexNum', ++indexNum); // 动态加字段


              _this5.$set(v, 'quantity', 1); // 动态加字段


              _this5.$set(v, 'edit', false); // 动态加字段


              return v;
            });
          }
        });
      } else {
        var _params = {
          orderId: param.id
        };
        (0, _order.getOrderSkuFeeList)(_params).then(function (response) {
          if (response.data) {
            var indexNum = 0;
            _this5.skuFeeList = response.data.map(function (v) {
              _this5.$set(v, 'indexNum', ++indexNum); // 动态加字段


              _this5.$set(v, 'edit', false); // 动态加字段


              return v;
            });
          }
        });
      }

      this.skuListLoading = false;
    }
  }
};
exports.default = _default;