"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addScheme = addScheme;
exports.againCallOver = againCallOver;
exports.appointCaller = appointCaller;
exports.assignCaller = assignCaller;
exports.assignEngineer = assignEngineer;
exports.callOver = callOver;
exports.checkOrder = checkOrder;
exports.confirmOrder = confirmOrder;
exports.doPersonalClose = doPersonalClose;
exports.findPageList = findPageList;
exports.getAdditionalDate = getAdditionalDate;
exports.getCallTradeInList = getCallTradeInList;
exports.getCallerList = getCallerList;
exports.getCalloutList = getCalloutList;
exports.getCheckHistory = getCheckHistory;
exports.getCheckHistoryList = getCheckHistoryList;
exports.getCheckItems = getCheckItems;
exports.getEngineerList = getEngineerList;
exports.getExpressGoods = getExpressGoods;
exports.getJdOrderSupplyInfo = getJdOrderSupplyInfo;
exports.getOrderEngageList = getOrderEngageList;
exports.getOrderPriceById = getOrderPriceById;
exports.getOrderPriceList = getOrderPriceList;
exports.getOrderSkuFeeList = getOrderSkuFeeList;
exports.getOrderSkuList = getOrderSkuList;
exports.getQiyuExpressGoods = getQiyuExpressGoods;
exports.getSchemeList = getSchemeList;
exports.getServiceSkuCheckHistory = getServiceSkuCheckHistory;
exports.handleOrder = handleOrder;
exports.orderClose = orderClose;
exports.outCallComm = outCallComm;
exports.queryById = queryById;
exports.queryOrderInfoById = queryOrderInfoById;
exports.queryOrderModifyHistory = queryOrderModifyHistory;
exports.queryQiyuOrderById = queryQiyuOrderById;
exports.receiptError = receiptError;
exports.receiptOrder = receiptOrder;
exports.recheckOrder = recheckOrder;
exports.recheckOrders = recheckOrders;
exports.removeScheme = removeScheme;
exports.saveExpress = saveExpress;
exports.saveOrderSKUs = saveOrderSKUs;
exports.savePrice = savePrice;
exports.tradeInOutCall = tradeInOutCall;
exports.transferOver = transferOver;
exports.updateOrder = updateOrder;
exports.updateOrderModifyHistory = updateOrderModifyHistory;
exports.updateOrderTask = updateOrderTask;
exports.validateOrderNo = validateOrderNo;
exports.verifyOrder = verifyOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/order/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 工单详情
 * @param id 工单ID
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/order/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 工单详情
 * @param id 工单ID
 */


function getOrderEngageList(id) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderEngageList',
    method: 'get',
    params: {
      orderId: id
    }
  });
}
/** 确认收货*/


function receiptOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/receipt',
    method: 'post',
    data: data
  });
}
/** 异常收货*/


function receiptError(data) {
  return (0, _request.default)({
    url: '/bus_app/order/receiptError',
    method: 'post',
    data: data
  });
}
/** 检测完成*/


function checkOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/check',
    method: 'post',
    data: data
  });
}
/** 复核完成*/


function recheckOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/recheck',
    method: 'post',
    data: data
  });
}
/** 复核完成*/


function recheckOrders(data) {
  return (0, _request.default)({
    url: '/bus_app/order/rechecks',
    method: 'post',
    data: data
  });
}
/** 处理完成*/


function handleOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/handle',
    method: 'post',
    data: data
  });
}
/** 终验完成*/


function verifyOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/verify',
    method: 'post',
    data: data
  });
}
/** 确认返机*/


function confirmOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/confirm',
    method: 'post',
    data: data
  });
}
/**
 * 获取工程师列表
 *  @param  orgId 所属机构ID
 */


function getEngineerList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getEngineerList',
    method: 'post',
    params: params
  });
}
/** 派工程师*/


function assignEngineer(data) {
  return (0, _request.default)({
    url: '/bus_app/order/assignEngineer',
    method: 'post',
    data: data
  });
}
/**
 * 获取客服列表
 *  @param  orgId 所属机构ID
 */


function getCallerList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getCallerList',
    method: 'post',
    params: params
  });
}
/** 派客服*/


function assignCaller(data) {
  return (0, _request.default)({
    url: '/bus_app/order/assignCaller',
    method: 'post',
    data: data
  });
}
/** 升级优惠外呼 */


function getCallTradeInList(data) {
  return (0, _request.default)({
    url: '/bus_app/order/getCallTradeInList',
    method: 'post',
    data: data
  });
}
/** 客服工单指派*/


function appointCaller(data) {
  return (0, _request.default)({
    url: '/bus_app/order/appointCaller',
    method: 'post',
    data: data
  });
}
/** 检测环节保存服务sku与sku并更新服务单价格附属表*/


function saveOrderSKUs(data) {
  return (0, _request.default)({
    url: '/bus_app/order/saveOrderSKUs',
    method: 'post',
    data: data
  });
}
/** 外呼确认*/


function callOver(data) {
  return (0, _request.default)({
    url: '/bus_app/order/callOver',
    method: 'post',
    data: data
  });
}
/** 客服任务确认*/


function updateOrderTask(data) {
  return (0, _request.default)({
    url: '/bus_app/order/updateOrderTask',
    method: 'post',
    data: data
  });
}
/** 客服任务关闭*/


function doPersonalClose(data) {
  return (0, _request.default)({
    url: '/bus_app/order/doPersonalClose',
    method: 'post',
    data: data
  });
}
/** 重新发送外呼确认短信*/


function againCallOver(data) {
  return (0, _request.default)({
    url: '/bus_app/order/againCallOver',
    method: 'post',
    data: data
  });
}
/** 外呼沟通 */


function outCallComm(data) {
  return (0, _request.default)({
    url: '/bus_app/order/outCallComm',
    method: 'post',
    data: data
  });
}
/** 查询【问题解决方案】*/


function getSchemeList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getSchemeList',
    method: 'post',
    params: params
  });
}
/** 添加【问题解决方案】*/


function addScheme(data) {
  return (0, _request.default)({
    url: '/bus_app/order/addScheme',
    method: 'post',
    data: data
  });
}
/** 删除【问题解决方案】*/


function removeScheme(data) {
  return (0, _request.default)({
    url: '/bus_app/order/removeScheme',
    method: 'post',
    data: data
  });
}
/** 查询【工单物料SKU】*/


function getOrderSkuList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderSkuList',
    method: 'post',
    params: params
  });
}
/** 查询【工单价格】*/


function getOrderPriceList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderPriceList',
    method: 'post',
    params: params
  });
}
/** 修改【工单价格】*/


function savePrice(data) {
  return (0, _request.default)({
    url: '/bus_app/order/savePrice',
    method: 'post',
    data: data
  });
}
/** 查询【工单服务SKU】*/


function getOrderSkuFeeList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderSkuFeeList',
    method: 'post',
    params: params
  });
}
/** 查询【工单服务SKU】*/


function getOrderPriceById(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getOrderPriceById',
    method: 'post',
    params: params
  });
}
/** 查询【取件物品】*/


function getExpressGoods(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getExpressGoods',
    method: 'post',
    params: params
  });
}
/** 查询【检测项目】*/


function getCheckItems(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getCheckItems',
    method: 'post',
    params: params
  });
}
/** 查询【历史检测信息】列表*/


function getCheckHistoryList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getCheckHistoryList',
    method: 'post',
    params: params
  });
}
/** 查询【历史检测信息】详细*/


function getCheckHistory(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getCheckHistory',
    method: 'post',
    params: params
  });
}
/** 坏品移交*/


function transferOver(data) {
  return (0, _request.default)({
    url: '/bus_app/order/transferOver',
    method: 'post',
    data: data
  });
}
/** 升级优惠重新外呼*/


function tradeInOutCall(data) {
  return (0, _request.default)({
    url: '/bus_app/order/tradeInOutCall',
    method: 'post',
    data: data
  });
}
/** 查询【客服外呼履历】列表*/


function getCalloutList(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getCalloutList',
    method: 'post',
    params: params
  });
}
/** 获取投保日期 */


function getAdditionalDate(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getAdditionalDate',
    method: 'post',
    params: params
  });
}
/** 关闭服务单 */


function orderClose(params) {
  return (0, _request.default)({
    url: '/bus_app/order/orderClose',
    method: 'post',
    params: params
  });
}
/** 补录顺丰单号 */


function saveExpress(params) {
  return (0, _request.default)({
    url: '/bus_app/order/saveExpress',
    method: 'post',
    params: params
  });
}
/* 修改服务单信息*/


function updateOrder(data) {
  return (0, _request.default)({
    url: '/bus_app/order/updateOrder',
    method: 'post',
    data: data
  });
}

function queryOrderModifyHistory(params) {
  return (0, _request.default)({
    url: '/bus_app/order/queryOrderModifyHistory',
    method: 'get',
    params: params
  });
}

function updateOrderModifyHistory(data) {
  return (0, _request.default)({
    url: '/bus_app/order/updateOrderModifyHistory',
    method: 'post',
    data: data
  });
}
/**
 * 工单详情
 * @param id 工单ID
 */


function queryOrderInfoById(id) {
  return (0, _request.default)({
    url: '/bus_app/order_modify/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 查询服务sku历史检测信息
 * @param params 履历ID
 * @return 集合
 */


function getServiceSkuCheckHistory(params) {
  return (0, _request.default)({
    url: '/bus_app/order/getServiceSkuCheckHistory',
    method: 'post',
    params: params
  });
}
/**
 * 查询京东信息
 */


function getJdOrderSupplyInfo(jdOrderNo) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getJdOrderSupplyInfo',
    method: 'get',
    params: {
      orderNo: jdOrderNo
    }
  });
}
/**
 * 工单详情
 * @param id 工单ID
 */


function queryQiyuOrderById(id) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_order/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/** 查询【取件物品】*/


function getQiyuExpressGoods(params) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_order/getExpressGoods',
    method: 'post',
    params: params
  });
}
/**
 * 校验单号是否存在
 * @param id 工单号
 */


function validateOrderNo(orderNo) {
  return (0, _request.default)({
    url: '/bus_app/order/validateOrderNo',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  });
}