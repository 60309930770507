"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _enrollProduct = require("@/api/register/enrollProduct");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EnrollProductDetail',
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      enrollProductInfo: {},
      btnLoading: false,
      listLoading: false,
      enrollProductHistoryList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _enrollProduct.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.enrollProductInfo = response.data;

          if (_this.enrollProductInfo.id) {
            _this.listQuery.parentId = _this.enrollProductInfo.id;

            _this.getHistoryList();
          }
        }
      });
    },
    // 获取明细
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _enrollProduct.getHistoryList)(this.listQuery).then(function (response) {
        _this2.enrollProductHistoryList = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$router.push({
        path: '/register/enroll_product'
      });
    }
  }
};
exports.default = _default;