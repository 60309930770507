"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _request = require("@/utils/request1");

var _order = require("@/api/order/order");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _verify = _interopRequireDefault(require("@/components/order/verify"));

var _handle = _interopRequireDefault(require("@/components/order/handle"));

var _recheck = _interopRequireDefault(require("@/components/order/recheck"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _index = _interopRequireDefault(require("@/components/order/checkPrice/index"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 终验信息
// 处理信息
// 复核信息
// 检测信息
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 外呼信息
// 费用明细
// 新建任务工单
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    orderVerify: _verify.default,
    orderHandle: _handle.default,
    orderRecheck: _recheck.default,
    orderCheck: _check.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCallout: _calloutList.default,
    orderPrice: _index.default,
    NewTask: _NewTask.default
  },
  filters: {
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  data: function data() {
    return {
      printBtnDisabled: true,
      btnLoading: false,
      step: '6',
      consumerShow: '0',
      productShow: '0',
      calloutShow: '0',
      id: this.$route.params.id,
      returnTypeOptions: _constant.default.returnTypeOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      handleResultMap1: _constant.default.handleResultMap1,
      rules: {
        returnType: [{
          required: true,
          message: '返机方式是必填的',
          trigger: 'blur'
        }],
        confirmRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      goodsList4show: []
    };
  },
  created: function created() {
    var _this = this;

    if (this.id) {
      this.getOrderInfo();
      this.initShowgoodsList4show();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);

        _this.$refs.checkPrice.$emit('loadCheckInfo', _this.id);
      });
    }
  },
  methods: {
    initShowgoodsList4show: function initShowgoodsList4show() {
      var _this2 = this;

      (0, _order.getExpressGoods)({
        orderId: this.id
      }).then(function (response) {
        if (response.data) {
          var goodsArray = response.data;
          var len = goodsArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this2.goodsList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = goodsArray.slice(i * n, i * n + n);

            _this2.goodsList4show.push(temp);
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this3 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this3.orderInfo = response.data;
          _this3.orderInfo.returnType = '1';

          _this3.$refs.orderProduct.$emit('loadFreeSn', _this3.orderInfo.sn);
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 【确认返机】
    returnConfirm: function returnConfirm() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this4.confirmOrderSumbit();
          }).catch(function () {});
        } else {
          _this4.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    confirmOrderSumbit: function confirmOrderSumbit() {
      var _this5 = this;

      this.btnLoading = true;
      (0, _order.confirmOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this5.$message({
            showClose: true,
            message: '确认完了',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/order/return/list'
          });
        } else {
          _this5.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }

        _this5.btnLoading = false;
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 快捷TAG查询
    stepClick: function stepClick(tab, event) {
      this.step = tab.name;

      if (tab.name === '1') {
        this.$refs.receiptOrder.$emit('loadExpressGoods', this.id);
      } else if (tab.name === '4') {
        this.$refs.handleOrder.$emit('loadhandleInfo', this.id);
      } else if (tab.name === '3') {
        this.$refs.checkOrder.$emit('loadcheckInfo', {
          id: this.orderInfo.id
        });
      } else if (tab.name === '5') {
        this.$refs.verifyOrder.$emit('loadCheckItems', this.id);
      }
    },
    printDown: function printDown(type) {
      var handleResult1 = this.orderInfo.handleResult;

      if (type === '1') {
        // 如果检测单打印
        if (handleResult1 === '1') {
          // { 1: '已完成', 2: '用户弃修', 3: '超出服务范围', 4: '联系失败返机', 5: '升级优惠' }
          this.$message.warning('处理结果已完成不允许打印检测单');
          return;
        }
      } else if (type === '2') {
        // 如果交付性打印
        if (handleResult1 !== '1') {
          var handleResultMapName = this.handleResultMap1[handleResult1];

          if (!handleResultMapName) {
            handleResultMapName = '无';
          }

          this.$message.warning('处理结果' + handleResultMapName + '，不允许打印交付性检测单');
          return;
        }
      }

      (0, _request.downLoadFile)('/bus_app/order/print/check/download?type=' + type + '&id=' + this.id).then(function (res) {
        var fileName = res.headers['content-disposition'].split('=')[1];
        var _res = res.data;
        var blob = new Blob([_res]);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    }
  }
};
exports.default = _default;