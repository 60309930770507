"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteData = deleteData;
exports.findPageList = findPageList;
exports.queryById = queryById;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/offline_store_return/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/offline_store_return/queryById',
    method: 'get',
    params: {
      id: id
    }
  });
}
/**
 * 根据id删除咨询单
 * 变更咨询单状态 主状态 ：已结束 ，子状态：作废
 * @param id
 */


function deleteData(id) {
  return (0, _request.default)({
    url: '/bus_app/offline_store_return/deleteOfflineStoreReturn',
    method: 'post',
    params: {
      ids: id
    }
  });
}