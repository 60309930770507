"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.getHistoryList = getHistoryList;
exports.handleState = handleState;
exports.queryById = queryById;

var _request = _interopRequireDefault(require("@/utils/request"));

/** 查询分页*/
function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/gdpr_task/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 根据id查详细信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/gdpr_task/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 根据id查履历
 * @param id
 */


function getHistoryList(query) {
  return (0, _request.default)({
    url: '/bus_app/gdpr_task/getHistoryList',
    method: 'post',
    params: query
  });
}
/**
 * 更新状态
 * @param id
 */


function handleState(state, id) {
  return (0, _request.default)({
    url: '/bus_app/gdpr_task/handleState',
    method: 'post',
    params: {
      state: state,
      id: id
    }
  });
}