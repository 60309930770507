"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fcClean = require("@/api/query/fc/fcClean");

var _select = require("@/api/common/select");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'fcDetail',
  components: {},
  // eslint-disable-line
  data: function data() {
    return {
      orderNo: this.$route.params.orderNo,
      fcCleanInfo: {},
      provinceOptions: [],
      cityOptions: [],
      submitInfo: {},
      countryOptions: [],
      // OrganizationInfo: {
      //   provinceId: '',
      //   cityId: '',
      //   districtId: ''
      // },
      rules: {
        provinceId: [{
          required: true,
          message: '省份必选',
          trigger: 'blur'
        }],
        cityId: [{
          required: true,
          message: '市必选',
          trigger: 'blur'
        }],
        districtId: [{
          required: true,
          message: '区/县必选',
          trigger: 'blur'
        }],
        sudAddress: [{
          required: true,
          message: '详细地址必填',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    if (this.orderNo) {
      this.getInfo();
    } // 加载省份


    this.getProvinceOptions();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        orderNo: this.orderNo
      };
      (0, _fcClean.queryDetail)(param).then(function (response) {
        if (response.code === 200) {
          _this.fcCleanInfo = response.data;
          _this.fcCleanInfo.sendAddress = _this.fcCleanInfo.sendProvince + _this.fcCleanInfo.sendCity + _this.fcCleanInfo.sendCountry + _this.fcCleanInfo.sendAddress;
        } else {
          _this.$message.error(response.msg);
        }
      });
    },
    submit: function submit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.$confirm('确定进行拆单吗？').then(function (_) {
            _this2.submitInfo.orderNo = _this2.orderNo;
            _this2.submitInfo.sendProvince = _this2.fcCleanInfo.provinceId;
            _this2.submitInfo.sendCity = _this2.fcCleanInfo.cityId;
            _this2.submitInfo.sendCountry = _this2.fcCleanInfo.districtId;
            _this2.submitInfo.sendAddress = _this2.fcCleanInfo.sudAddress;
            (0, _fcClean.updatePaymentSuc)(_this2.submitInfo).then(function (response) {
              if (response.code === 200) {
                _this2.$message.success(response.msg);

                _this2.returnBtn();
              } else {
                _this2.$message.error(response.msg);
              }
            });
          }).catch(function (_) {});
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/consult/fcSplitOrder'
      });
    },
    // 加载省份
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this3.provinceOptions = response.data;
      });
    },
    // 省份
    proviceChange: function proviceChange(province) {
      var _this4 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this4.cityOptions = response.data;
        });
      }

      this.fcCleanInfo.cityId = '';
      this.fcCleanInfo.districtId = '';
    },
    // 市
    cityChange: function cityChange(city) {
      var _this5 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this5.countryOptions = response.data;
        });
      }

      this.fcCleanInfo.districtId = '';
    }
  }
};
exports.default = _default;