"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

require("core-js/modules/web.dom.iterable");

var _list = require("@/api/basics/warehouse/list");

var _organization = require("@/api/organization");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'warehouse_detail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {},
  filters: {},
  data: function data() {
    return {
      isEnablesOptions: [{
        label: '启用',
        value: '1'
      }, {
        label: '禁用',
        value: '0'
      }],
      typesOptions: [{
        label: '良品库',
        value: '0'
      }, {
        label: '坏品库',
        value: '1'
      }, {
        label: '报废品库',
        value: '2'
      }],
      textMap: {
        update: '编辑',
        create: '新建'
      },
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      dialogStatus: this.$route.params.dialogStatus,
      loading: false,
      multipleSelection: [],
      list: [],
      total: 0,
      listLoading: true,
      orgListValidate: false,
      // >>> 添加编辑FORM项目 >>>
      warehouseInfo: {
        id: '',
        name: '',
        type: '',
        memo: '',
        orgList: []
      },
      rules: {
        name: [{
          required: true,
          message: '仓库名称是必填的',
          trigger: 'blur'
        }, {
          max: 50,
          message: '最大长度 50 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '仓库分类是必填的',
          trigger: 'blur'
        }],
        memo: [{
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }]
      },
      // >>> 弹出框的数据 >>>
      orgDialogData: {},
      addOrgdialogFormVisible: false,
      organizationList: [],
      listQuery: {
        page: 1,
        limit: 500,
        name: '',
        parentId: '',
        isEnabled: '1'
      },
      parentOrgList: [],
      orgDialogSelection: [] // >>> 弹出框的数据 >>>

    };
  },
  created: function created() {
    if (!this.isCreate) {
      this.getWarehouse();
    }

    this.getList(); // 弹出框 上级网点

    this.getParentOrgList();
  },
  methods: {
    // 添加页，拿取上级机构
    getParentOrgList: function getParentOrgList() {
      var _this = this;

      (0, _organization.getOrganizationList)(this.listQuery).then(function (response) {
        _this.parentOrgList = response.data;
      });
    },
    // 弹出框 查询
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.listQuery.limit = 500;
      this.getOrganizationList();
    },
    // 弹出框 重置查询条件
    resetBtn: function resetBtn() {
      this.listQuery = {
        page: 1,
        limit: 500,
        parentId: '',
        name: '',
        isEnabled: '1'
      };
    },
    // 弹出框，拿取机构
    getOrganizationList: function getOrganizationList() {
      var _this2 = this;

      (0, _organization.fetchList)(this.listQuery).then(function (response) {
        _this2.organizationList = response.data.records;
      });
    },
    // 初始查后台 拿取 仓库信息
    getWarehouse: function getWarehouse() {
      var _this3 = this;

      // console.log(this.id)
      (0, _list.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this3.warehouseInfo = response.data;
          _this3.list = response.data.orgList;

          _this3.$nextTick(function () {
            _this3.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 重置 详细页
    resetFrom: function resetFrom() {
      this.warehouseInfo = {
        id: null,
        code: null,
        name: null,
        isEnable: '1',
        memo: null,
        orgList: []
      };
    },
    // 【已关联机构列表】验证方法
    validateOrgList: function validateOrgList() {
      if (!this.list || this.list.length === 0) {
        this.orgListValidate = true;
        return false;
      }

      return true;
    },
    // 详细页 新增确认
    create: function create() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this4.validateOrgList()) {
          _this4.list.forEach(function (item, index) {
            _this4.warehouseInfo.orgList.push({
              orgId: item.id,
              orgPath: item.path,
              isEnable: item.isEnable
            });
          });

          (0, _list.createData)(_this4.warehouseInfo).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '保存成功！',
                type: 'success'
              });

              _this4.resetFrom();

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/basics/warehouse'
              });
            } else {
              _this4.$message({
                showClose: true,
                message: '保存失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 详细页 编辑确认
    update: function update() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this5.validateOrgList()) {
          var tempData = Object.assign({}, _this5.warehouseInfo);
          tempData.orgList = [];

          _this5.list.forEach(function (item, index) {
            tempData.orgList.push({
              orgId: item.id,
              orgPath: item.path,
              isEnable: item.isEnable
            });
          });

          (0, _list.updateData)(tempData).then(function (response) {
            if (response.data) {
              _this5.$message({
                showClose: true,
                message: '保存成功！',
                type: 'success'
              });

              _this5.resetFrom();

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/basics/warehouse'
              });
            } else {
              _this5.$message({
                showClose: true,
                message: '保存失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 关联机构数据初始化
    getList: function getList() {
      this.list = [];
      this.listLoading = false;
    },
    // 关联机构 列表 selectChange事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;

      if (this.multipleSelection.length >= 1) {
        console.log(this.multipleSelection);
      }
    },
    // 添加关联机构  弹出框
    addOrg2Warehouse: function addOrg2Warehouse() {
      this.addOrgdialogFormVisible = true;
    },
    // 弹出框保存
    addOrgClick: function addOrgClick() {
      var _this6 = this;

      this.orgDialogSelection.forEach(function (item, index) {
        item.isDelete = true;
        _this6.list = _this6.list.concat(item);
      });
      this.list = this.unique(this.list);
      console.log(this.list);
      this.addOrgdialogFormVisible = false;
    },
    // 弹出框勾选
    orgDialogSelectionChange: function orgDialogSelectionChange(val) {
      this.orgDialogSelection = val;

      if (this.orgDialogSelection.length >= 1) {
        console.log(this.orgDialogSelection);
      } else {
        console.log();
      }
    },
    // 机构添加 去重
    unique: function unique(arr) {
      // 根据唯一标识no来对数组进行过滤
      var res = new Map(); // 定义常量 res,值为一个Map对象实例
      // 返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1

      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    // 关联机构 禁用、启用、删除
    handleModifyStatus: function handleModifyStatus(row, status) {
      if (status === 'true') {
        row.isEnable = '1';
        row.status = '1';
      }

      if (status === 'false') {
        row.status = '1';
        row.isEnable = '0';
      }

      if (status === 'deleted') {
        var index = this.list.indexOf(row);
        this.list.splice(index, 1);
      }
    },
    // 【弹出框机构选择列表】 单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.orgmultipleTable.toggleRowSelection(row);
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/warehouse'
      });
    }
  }
};
exports.default = _default;