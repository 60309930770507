"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order/order");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      returnTypeOptions: _constant.default.returnTypeOptions,
      goodsList4show: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.$on('loadExpressGoods', function (id) {
      if (id) {
        _this.initShowgoodsList4show(id);
      }
    });
  },
  methods: {
    initShowgoodsList4show: function initShowgoodsList4show(id) {
      var _this2 = this;

      (0, _order.getExpressGoods)({
        orderId: id
      }).then(function (response) {
        if (response.data) {
          var goodsArray = response.data;
          var len = goodsArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this2.goodsList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = goodsArray.slice(i * n, i * n + n);

            _this2.goodsList4show.push(temp);
          }
        }
      });
    }
  }
};
exports.default = _default;