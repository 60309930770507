var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _vm.isAudit2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.btnLoading,
                              expression: "btnLoading",
                            },
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_QUOTA_SALE_APPROVE_UPDATE_TWO"],
                              expression:
                                "['ROLE_QUOTA_SALE_APPROVE_UPDATE_TWO']",
                            },
                          ],
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.recheckConfirm()
                            },
                          },
                        },
                        [_vm._v("确认")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading,
                          expression: "btnLoading",
                        },
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_QUOTA_APPROVE_LISTALL"],
                          expression: "['ROLE_QUOTA_APPROVE_LISTALL']",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.return2List()
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: { model: _vm.saleInfo, "label-width": "150px" },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("收件信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件人",
                                            prop: "takeUser",
                                            rules: {
                                              required: true,
                                              message: "请填写收件人",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件人",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takeUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeUser",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takeUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件手机号",
                                            prop: "takePhone",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请填写收件手机号",
                                                trigger: "blur",
                                              },
                                              {
                                                pattern: /^1[123456789]\d{9}$/,
                                                message: "请输入正确的手机号码",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件手机号",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takePhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takePhone",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takePhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "省",
                                          prop: "province",
                                          rules: {
                                            required: true,
                                            message: "请选择省",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            model: {
                                              value: _vm.saleInfo.province,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "province",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.province",
                                            },
                                          },
                                          _vm._l(
                                            _vm.provinceOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "市",
                                          prop: "city",
                                          rules: {
                                            required: true,
                                            message: "请选择市",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择市",
                                              filterable: "",
                                              clearable: "",
                                              disabled: _vm.isDis,
                                            },
                                            model: {
                                              value: _vm.saleInfo.city,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.city",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cityOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "区/县",
                                          prop: "country",
                                          rules: {
                                            required: true,
                                            message: "请选择区/县",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择区",
                                              filterable: "",
                                              disabled: _vm.isDis,
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.saleInfo.country,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.country",
                                            },
                                          },
                                          _vm._l(
                                            _vm.countryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件地址",
                                            prop: "takeAddress",
                                            rules: {
                                              required: true,
                                              message: "收件地址",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.isDis,
                                              placeholder: "收件地址",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takeAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.takeAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("销售产品")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              _vm._l(
                                _vm.saleInfo.items,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.payType === "06" ||
                                            _vm.quotaState === "1" ||
                                            _vm.quotaState === "2",
                                          expression:
                                            "item.payType === '06' || quotaState === '1' || quotaState === '2'",
                                        },
                                      ],
                                      key: index,
                                    },
                                    [
                                      _c(
                                        "el-container",
                                        [
                                          _c(
                                            "el-aside",
                                            { attrs: { width: "20%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "block" },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      "margin-top": "70px",
                                                    },
                                                    attrs: {
                                                      src: item.src,
                                                      "preview-src-list": [
                                                        item.src,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "category" + index,
                                                          attrs: {
                                                            label: "品类",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".categoryId",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择品类",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("tree-select", {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              options:
                                                                _vm.tableData1,
                                                              disabled:
                                                                _vm.isDis,
                                                              accordion:
                                                                _vm.accordion,
                                                              clearable:
                                                                _vm.cTree,
                                                              placeholder:
                                                                "请选择品类",
                                                              value:
                                                                item.categoryId,
                                                            },
                                                            on: {
                                                              getValue:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.queryTreeSelectNodeKey(
                                                                    $event,
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "model" + index,
                                                          attrs: {
                                                            label: "型号",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".modelId",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择型号",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择型号",
                                                                disabled:
                                                                  _vm.isDis,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.modelId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "modelId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.modelId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.modelSelect[
                                                                index
                                                              ],
                                                              function (items) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: items.id,
                                                                    attrs: {
                                                                      disabled:
                                                                        items.isEnable !==
                                                                        "1",
                                                                      label:
                                                                        items.name,
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "sku" + index,
                                                          attrs: {
                                                            label: "物料选择",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".skuId",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                filterable: "",
                                                                disabled:
                                                                  _vm.isDis,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.skuId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "skuId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.skuId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.skuList[
                                                                index
                                                              ],
                                                              function (items) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: items.id,
                                                                    attrs: {
                                                                      label:
                                                                        items.code +
                                                                        "  " +
                                                                        items.name,
                                                                      disabled:
                                                                        items.isEnable !==
                                                                        "1",
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            float:
                                                                              "left",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            items.code
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            float:
                                                                              "right",
                                                                            color:
                                                                              "#8492a6",
                                                                            "font-size":
                                                                              "13px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            items.name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "skuName" + index,
                                                          attrs: {
                                                            label: "物料名称",
                                                            prop:
                                                              "skuName" + index,
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.skuName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "skuName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.skuName",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "skuCode" + index,
                                                          attrs: {
                                                            label: "物料编码",
                                                            prop:
                                                              "skuCode" + index,
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.skuCode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "skuCode",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.skuCode",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "brandId" + index,
                                                          attrs: {
                                                            label: "品牌",
                                                            prop:
                                                              "brandId" + index,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "filter-item",
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled: true,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.brandId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "brandId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.brandId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.brandSelect,
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.name,
                                                                      value:
                                                                        items.id,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "num" + index,
                                                          attrs: {
                                                            label: "购买数量",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".num",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请填写数量",
                                                                trigger: "blur",
                                                              },
                                                              {
                                                                pattern:
                                                                  /^(([1-9]+\d*)|0)$/,
                                                                message:
                                                                  "请正确填写整数",
                                                              },
                                                              {
                                                                validator:
                                                                  function (
                                                                    rule,
                                                                    value,
                                                                    callback
                                                                  ) {
                                                                    if (
                                                                      value ===
                                                                      0
                                                                    ) {
                                                                      return callback(
                                                                        "不可等于0"
                                                                      )
                                                                    } else {
                                                                      if (
                                                                        value >
                                                                        10
                                                                      ) {
                                                                        return callback(
                                                                          "单商品最多购买10个"
                                                                        )
                                                                      }
                                                                      if (
                                                                        _vm
                                                                          .saleInfo
                                                                          .items[
                                                                          index
                                                                        ].stock
                                                                      ) {
                                                                        if (
                                                                          _vm
                                                                            .saleInfo
                                                                            .items[
                                                                            index
                                                                          ]
                                                                            .stock <
                                                                          value
                                                                        ) {
                                                                          return callback(
                                                                            "不可大于剩余库存"
                                                                          )
                                                                        }
                                                                      }
                                                                      callback()
                                                                    }
                                                                  },
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.isDis,
                                                            },
                                                            model: {
                                                              value: item.num,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "num",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.num",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "stock" + index,
                                                          attrs: {
                                                            label: "剩余库存",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".stock",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value: item.stock,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "stock",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.stock",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "payType" + index,
                                                          attrs: {
                                                            label: "收费类型",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".payType",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择收费类型",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDis,
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.payType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "payType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.payType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.payType,
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.label,
                                                                      value:
                                                                        items.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "priceAfterY" +
                                                            index,
                                                          attrs: {
                                                            label: "税前单价",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".priceAfterY",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.priceAfterY,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "priceAfterY",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.priceAfterY",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "describes" + index,
                                                          attrs: {
                                                            label: "订购原因",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".describes",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "请选择商品描述",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDis,
                                                                filterable: "",
                                                                clearable: "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.describes,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "describes",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.describes",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.describeList,
                                                              function (
                                                                items,
                                                                indexs
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: indexs,
                                                                    attrs: {
                                                                      label:
                                                                        items.label,
                                                                      value:
                                                                        items.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key:
                                                            "priceBeforeY" +
                                                            index,
                                                          attrs: {
                                                            label: "含税单价",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".priceBeforeY",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                item.priceBeforeY,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "priceBeforeY",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.priceBeforeY",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          key: "memo" + index,
                                                          attrs: {
                                                            label: "订单备注",
                                                            prop:
                                                              "items." +
                                                              index +
                                                              ".memo",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.isDis,
                                                            },
                                                            model: {
                                                              value: item.memo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "memo",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.memo",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 7 } },
                                                    [
                                                      _vm._v(
                                                        "　\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  (_vm.saleInfo.state ===
                                                    "S1" ||
                                                    _vm.saleInfo.state ===
                                                      "") &&
                                                  (_vm.saleInfo.stateChild ===
                                                    "1" ||
                                                    _vm.saleInfo.stateChild ===
                                                      "2") &&
                                                  index != 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.removeDomain(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("购物车")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "总商品数",
                                            prop: "amount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "总数量",
                                            },
                                            model: {
                                              value: _vm.amount,
                                              callback: function ($$v) {
                                                _vm.amount = $$v
                                              },
                                              expression: "amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "税前总价",
                                            prop: "priceAfter",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "税前总价",
                                            },
                                            model: {
                                              value: _vm.priceAfter,
                                              callback: function ($$v) {
                                                _vm.priceAfter = $$v
                                              },
                                              expression: "priceAfter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "含税总价",
                                            prop: "priceBefore",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "含税总价",
                                            },
                                            model: {
                                              value: _vm.priceBefore,
                                              callback: function ($$v) {
                                                _vm.priceBefore = $$v
                                              },
                                              expression: "priceBefore",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "poolForm",
                    attrs: {
                      model: _vm.quotaPool,
                      "label-width": "150px",
                      size: "50%",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("额度信息")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "月度总额度" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.quotaPool.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.quotaPool,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "quotaPool.amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "可用额度" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.quotaPool.remaining,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.quotaPool,
                                                    "remaining",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quotaPool.remaining",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "本次减免额度" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.quotaPrice,
                                                callback: function ($$v) {
                                                  _vm.quotaPrice = $$v
                                                },
                                                expression: "quotaPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "approveForm",
                    attrs: {
                      model: _vm.quotaApprove,
                      "label-width": "150px",
                      size: "50%",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("审批信息")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 22 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "审批结果",
                                              prop: "state",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled: _vm.isAudit,
                                                },
                                                model: {
                                                  value: _vm.quotaApprove.state,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quotaApprove,
                                                      "state",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quotaApprove.state",
                                                },
                                              },
                                              _vm._l(
                                                _vm.resultOptions,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.val,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 22 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "审批备注" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 2,
                                                disabled: _vm.isAudit,
                                              },
                                              model: {
                                                value: _vm.quotaApprove.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.quotaApprove,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quotaApprove.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }