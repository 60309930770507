"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _sale = require("@/api/sale/sale");

var _select = require("@/api/common/select");

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

var _FixTop = _interopRequireDefault(require("@/components/FixTop"));

var _constant = _interopRequireDefault(require("@/api/sale/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 下拉框 树形结构 可检索组件
var _default = {
  name: 'qiyuSaleDetail',
  // eslint-disable-line
  components: {
    treeSelect: _treeSelect.default,
    FixTop: _FixTop.default
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      flag: this.$route.query.dialogStatus,
      sessionId: this.$route.query.sessionId,
      phone: this.$route.query.phone,
      pageType: this.$route.query.pageType,
      isDis: false,
      dialogVisible: false,
      btnLoading: false,
      tempBtn: false,
      completeBtn: false,
      // <状态控制> 隐藏/显示 型号选择折叠面板
      dialogFormVisible: false,
      // <状态控制> 添加关联型号按钮
      addBtnDisabled: true,
      // <状态控制> 删除关联型号按钮
      deleteBtnDisabled: true,
      // <参数验证> 关联型号列表是否存在
      modelListValidate: false,
      // <参数验证> 查询型号列表条件
      modelSelConditionValidate: false,
      // <下拉数组> 维修分类
      repairTypeSelect: [],
      // <下拉数组> 品类下拉
      categorySelect: [],
      // <下拉数组> 品牌
      brandSelect: [],
      // <下拉数组> 型号
      modelSelect: [],
      // <多选列表选中数组> 型号选择列表
      multipleSelection4ModelSel: [],
      // <多选列表选中数组> 关联型号列表
      multipleSelection: [],
      loadingList4ModelSelect: false,
      loadingList4Model: false,
      payType: _constant.default.payType,
      describeList: [[{
        label: '购买配件（无原因）',
        value: '01'
      }, {
        label: '配件丢失',
        value: '02'
      }, {
        label: '人为损坏',
        value: '03'
      }, {
        label: '消耗品',
        value: '04'
      }, {
        label: '保内损坏（非人为）',
        value: '05'
      }, {
        label: '开箱破损',
        value: '06'
      }, {
        label: '开箱缺件',
        value: '07'
      }, {
        label: '保外/特例补偿',
        value: '08'
      }, {
        label: '活动赠品补发',
        value: '09'
      }, {
        label: '其他',
        value: '10'
      }]],
      // <列表数组> 型号选择
      list4ModelSelect: null,
      // <列表数组> 关联型号
      list4Model: null,
      validateForm: [],
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      // <列表数组> 型号选择条件
      listQuery: {
        categoryId: undefined,
        brandId: undefined,
        id: undefined
      },
      skuList: [],
      rules: {},
      tableData1: [],
      accordion: false,
      cTree: true,
      saleInfo: {
        amount: 0,
        priceAfter: '0.00',
        priceBefore: '0.00',
        urgent: '1',
        province: '',
        city: '',
        district: '',
        state: '',
        items: [{
          categoryId: '',
          modelId: '',
          skuCode: '',
          brandId: '',
          skuName: '',
          skuId: '',
          num: 0,
          payType: '',
          describes: '',
          memo: '',
          priceBeforeY: '',
          priceBeforeYY: '',
          priceAfterY: '',
          priceAfterYY: '',
          stock: '',
          src: 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
        }]
      }
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    }
  },
  created: function created() {
    this.getSelect();

    if (this.id) {
      this.getSaleInfo();
    }
  },
  methods: {
    getProvinceOptions: function getProvinceOptions() {
      var _this = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this2 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this2.cityOptions = response.data;
        });
      }

      this.saleInfo.cityId = '';
      this.saleInfo.districtId = '';
    },
    cityChange: function cityChange(city) {
      var _this3 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this3.countryOptions = response.data;
        });
      }

      this.saleInfo.districtId = '';
    },
    payTypeChange: function payTypeChange(index) {
      this.describeList[index] = [];
      this.saleInfo.items[index].describes = '';

      if (this.saleInfo.items[index].payType === '01') {
        this.describeList[index] = [{
          label: '购买配件（无原因）',
          value: '01'
        }, {
          label: '配件丢失',
          value: '02'
        }, {
          label: '人为损坏',
          value: '03'
        }, {
          label: '消耗品',
          value: '04'
        }, {
          label: '其他',
          value: '10'
        }];
        this.saleInfo.items[index].priceAfterY = this.saleInfo.items[index].priceAfterYY;
        this.saleInfo.items[index].priceBeforeY = this.saleInfo.items[index].priceBeforeYY;
      } else {
        this.saleInfo.items[index].priceAfterY = 0;
        this.saleInfo.items[index].priceBeforeY = 0;
        this.describeList[index] = [{
          label: '保内损坏（非人为）',
          value: '05'
        }, {
          label: '开箱破损',
          value: '06'
        }, {
          label: '开箱缺件',
          value: '07'
        }, {
          label: '保外/特例补偿',
          value: '08'
        }, {
          label: '活动赠品补发',
          value: '09'
        }, {
          label: '其他',
          value: '10'
        }];
      }

      this.numChange();
    },
    numChange: function numChange() {
      var a = 0;
      var b = 0;
      var c = 0;
      this.saleInfo.items.forEach(function (it) {
        if (it.num === '' || it.num === undefined || it.num === null) {
          a = parseInt(a) + 0;
        } else {
          a = parseInt(a) + parseInt(it.num);

          if (it.payType === '01') {
            b = Number(b) + Number(it.priceBeforeY) * 100 * parseInt(it.num);
            c = Number(c) + Number((Number(it.priceAfterY) * 100 * parseInt(it.num)).toFixed(0));
          }
        }
      });
      this.saleInfo.amount = a;
      this.saleInfo.priceAfter = c / 100;
      this.saleInfo.priceBefore = b / 100;
    },
    removeDomain: function removeDomain(item) {
      var _this4 = this;

      if (item.num !== '' && item.num !== null && item.num !== undefined) {
        this.saleInfo.amount = parseInt(this.saleInfo.amount) - parseInt(item.num);
      }

      var index = this.saleInfo.items.indexOf(item);

      if (index !== -1) {
        this.saleInfo.items.splice(index, 1);
        this.skuList.splice(index, 1);
        this.modelSelect.splice(index, 1);
      }

      this.saleInfo.items.forEach(function (it, index) {
        if (it.payType === '01') {
          _this4.describeList[index] = [{
            label: '购买配件（无原因）',
            value: '01'
          }, {
            label: '配件丢失',
            value: '02'
          }, {
            label: '人为损坏',
            value: '03'
          }, {
            label: '消耗品',
            value: '04'
          }, {
            label: '其他',
            value: '10'
          }];
        } else {
          _this4.describeList[index] = [{
            label: '保内损坏（非人为）',
            value: '05'
          }, {
            label: '开箱破损',
            value: '06'
          }, {
            label: '开箱缺件',
            value: '07'
          }, {
            label: '保外/特例补偿',
            value: '08'
          }, {
            label: '活动赠品补发',
            value: '09'
          }, {
            label: '其他',
            value: '10'
          }];
        }
      });
    },
    addDomain: function addDomain() {
      if (this.saleInfo.items !== null && this.saleInfo.items.length >= 10) {
        this.$message({
          showClose: true,
          message: '单笔订单最多购买10种商品',
          type: 'warning'
        });
        return;
      }

      this.saleInfo.items.push({
        categoryId: '',
        modelId: '',
        skuCode: '',
        brandId: '',
        skuName: '',
        skuId: '',
        num: '',
        payType: '',
        memo: '',
        describes: '',
        priceBeforeY: '',
        priceBeforeYY: '',
        priceAfterY: '',
        priceAfterYY: '',
        stock: '',
        src: 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
      });
    },
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value, index) {
      this.saleInfo.items[index].categoryId = value;
    },
    // 获取维修代码详细
    getSaleInfo: function getSaleInfo() {
      var _this5 = this;

      var param = {
        id: this.id
      };

      if (this.flag === 'create') {
        param = {
          consultId: this.id
        };
      }

      (0, _select.getCategorySelect)().then(function (res) {
        _this5.categorySelect = res.data.records;
        (0, _sale.queryQiyuSaleById)(param).then(function (response) {
          if (response.data) {
            _this5.saleInfo = response.data;

            if (_this5.saleInfo.items != null && _this5.saleInfo.items.length > 0) {
              _this5.saleInfo.items.forEach(function (it, index) {
                if (it.src === '' || it.src === null || it.src === undefined) {
                  it.src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
                }

                it.priceAfterYY = Number(it.priceAfterYY) / 100;
                it.priceBeforeYY = Number(it.priceBeforeYY) / 100;

                if (it.payType === '01') {
                  _this5.describeList[index] = [{
                    label: '购买配件（无原因）',
                    value: '01'
                  }, {
                    label: '配件丢失',
                    value: '02'
                  }, {
                    label: '人为损坏',
                    value: '03'
                  }, {
                    label: '消耗品',
                    value: '04'
                  }, {
                    label: '其他',
                    value: '10'
                  }];
                } else {
                  it.priceBeforeY = 0;
                  it.priceAfterY = 0;
                  _this5.describeList[index] = [{
                    label: '保内损坏（非人为）',
                    value: '05'
                  }, {
                    label: '开箱破损',
                    value: '06'
                  }, {
                    label: '开箱缺件',
                    value: '07'
                  }, {
                    label: '保外/特例补偿',
                    value: '08'
                  }, {
                    label: '活动赠品补发',
                    value: '09'
                  }, {
                    label: '其他',
                    value: '10'
                  }];
                }

                var p1 = {
                  categoryId: it.categoryId
                };
                (0, _select.getModelSelect)(p1).then(function (res) {
                  _this5.modelSelect[index] = res.data;
                });
                var p2 = {
                  modelId: it.modelId,
                  isRetail: '1'
                };
                (0, _select.getSkuSelect)(p2).then(function (res) {
                  _this5.skuList[index] = res.data;
                });

                if (it.skuId === null || it.skuId === '' || it.skuId === undefined) {
                  it.priceBeforeY = '';
                  it.priceAfterY = '';
                }
              });
            }

            _this5.saleInfo.priceAfter = _this5.saleInfo.priceAfterY;
            _this5.saleInfo.priceBefore = _this5.saleInfo.priceBeforeY;

            if ((_this5.saleInfo.state === 'S1' || _this5.saleInfo.state === '') && (_this5.saleInfo.stateChild === '1' || _this5.saleInfo.stateChild === '2')) {
              _this5.isDis = false;
            } else {
              _this5.isDis = true;
            }

            _this5.getProvinceOptions();

            if (_this5.saleInfo.province !== null && _this5.saleInfo.province !== '') {
              (0, _select.getAreasByParentId)(_this5.saleInfo.province).then(function (response) {
                _this5.cityOptions = response.data;
              });
            } // 区/县  回显


            if (_this5.saleInfo.city !== null && _this5.saleInfo.city !== '') {
              (0, _select.getAreasByParentId)(_this5.saleInfo.city).then(function (response) {
                _this5.countryOptions = response.data;
              });
            }
          }
        });
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          for (var i = 0; i < _this6.saleInfo.items.length; i++) {
            for (var j = i + 1; j < _this6.saleInfo.items.length; j++) {
              if (_this6.saleInfo.items[i].skuCode === _this6.saleInfo.items[j].skuCode) {
                _this6.$message({
                  showClose: true,
                  message: '部件:' + _this6.saleInfo.items[j].skuName + '已重复',
                  type: 'warning'
                });

                return;
              }
            }
          }

          _this6.saleInfo.items.forEach(function (it) {
            it.priceBefore = it.priceBeforeY;
            it.priceAfter = it.priceAfterY;
          });

          (0, _sale.saveQiyuSale)(_this6.saleInfo).then(function (response) {
            if (response.data) {
              _this6.$message({
                showClose: true,
                message: response.msg,
                type: 'success'
              });

              _this6.$store.dispatch('tagsView/delView', _this6.$route);

              if (_this6.pageType === '1') {
                _this6.$router.push({
                  name: 'qiyuDetail',
                  query: {
                    id: _this6.id,
                    sessionId: _this6.sessionId,
                    phone: _this6.phone
                  }
                });
              } else {
                _this6.$router.push({
                  name: 'qiyuList',
                  query: {
                    id: _this6.id,
                    sessionId: _this6.sessionId,
                    phone: _this6.phone
                  }
                });
              }
            } else {
              _this6.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          for (var i = 0; i < _this7.saleInfo.items.length; i++) {
            for (var j = i + 1; j < _this7.saleInfo.items.length; j++) {
              if (_this7.saleInfo.items[i].skuCode === _this7.saleInfo.items[j].skuCode) {
                _this7.$message({
                  showClose: true,
                  message: '部件:' + _this7.saleInfo.items[j].skuName + '已重复',
                  type: 'warning'
                });

                return;
              }
            }
          }

          _this7.saleInfo.items.forEach(function (it) {
            it.priceBefore = it.priceBeforeY;
            it.priceAfter = it.priceAfterY;
          });

          _this7.dialogVisible = true;
        }
      });
    },
    comfirmToOrder: function comfirmToOrder() {
      var _this8 = this;

      this.completeBtn = true;
      (0, _sale.qiyuComplete)(this.saleInfo).then(function (response) {
        if (response.data) {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'success'
          });

          _this8.$store.dispatch('tagsView/delView', _this8.$route);

          if (_this8.pageType === '1') {
            _this8.$router.push({
              name: 'qiyuDetail',
              query: {
                id: _this8.id,
                sessionId: _this8.sessionId,
                phone: _this8.phone
              }
            });
          } else {
            _this8.$router.push({
              name: 'qiyuList',
              query: {
                id: _this8.id,
                sessionId: _this8.sessionId,
                phone: _this8.phone
              }
            });
          }
        } else {
          _this8.completeBtn = false;

          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);

      if (this.pageType === '1') {
        this.$router.push({
          name: 'qiyuDetail',
          query: {
            id: this.id,
            sessionId: this.sessionId,
            phone: this.phone
          }
        });
      } else {
        this.$router.push({
          name: 'qiyuList',
          query: {
            id: this.id,
            sessionId: this.sessionId,
            phone: this.phone
          }
        });
      }
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this9 = this;

      var params = {}; // 品类下拉
      // getCategorySelect(params).then(res => {
      //   this.categorySelect = res.data.records
      // })
      // 品牌下拉

      (0, _select.getBrandSelect)(params).then(function (res) {
        _this9.brandSelect = res.data.records;
      }); // // sku下拉
      // getSkuSelect({ isRetail: '1' }).then(res => {
      //   this.skuList = res.data
      // })
      // // 型号下拉
      // getModelSelect(params).then(res => {
      //   this.modelSelect = res.data.records
      // })
    },
    categoryChange: function categoryChange(value, index) {
      var _this10 = this;

      this.saleInfo.items[index].skuId = '';
      this.saleInfo.items[index].skuCode = '';
      this.saleInfo.items[index].skuName = '';
      this.saleInfo.items[index].priceAfterY = '';
      this.saleInfo.items[index].priceBeforeY = '';
      this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
      this.saleInfo.items[index].brandId = '';
      this.saleInfo.items[index].stock = '';
      this.saleInfo.items[index].modelId = '';
      var params = {
        categoryId: this.saleInfo.items[index].categoryId
      };
      (0, _select.getModelSelect)(params).then(function (res) {
        _this10.modelSelect[index] = res.data;
      });
      this.numChange();
    },
    modelChange: function modelChange(index) {
      var _this11 = this;

      this.modelSelect[index].find(function (i) {
        if (i.id === _this11.saleInfo.items[index].modelId) {
          _this11.saleInfo.items[index].brandId = i.brandId;
        }
      });
      this.saleInfo.items[index].skuId = '';
      this.saleInfo.items[index].skuCode = '';
      this.saleInfo.items[index].skuName = '';
      this.saleInfo.items[index].priceAfterY = '';
      this.saleInfo.items[index].priceBeforeY = '';
      this.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
      this.saleInfo.items[index].stock = '';
      var params = {
        modelId: this.saleInfo.items[index].modelId,
        isRetail: '1'
      };
      (0, _select.getSkuSelect)(params).then(function (res) {
        _this11.skuList[index] = res.data;
      });
      this.numChange();
    },
    skuChange: function skuChange(index) {
      var _this12 = this;

      this.skuList[index].find(function (i) {
        if (i.id === _this12.saleInfo.items[index].skuId) {
          _this12.saleInfo.items[index].priceBeforeY = Number(i.priceIncludeTax) / 100;
          _this12.saleInfo.items[index].priceAfterY = Number((Number(i.priceIncludeTax) / 113).toFixed(2));
          _this12.saleInfo.items[index].priceBeforeYY = Number(i.priceIncludeTax) / 100;
          _this12.saleInfo.items[index].priceAfterYY = Number((Number(i.priceIncludeTax) / 113).toFixed(2));
          _this12.saleInfo.items[index].stock = i.ddirStock;
          _this12.saleInfo.items[index].skuName = i.name;
          _this12.saleInfo.items[index].skuCode = i.code;

          if (i.picturePath !== null && i.picturePath !== undefined && i.picturePath !== '') {
            _this12.saleInfo.items[index].src = i.picturePath;
          } else {
            _this12.saleInfo.items[index].src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
          }

          _this12.numChange();
        }
      });
    }
  }
};
exports.default = _default;