"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/** 推送状态[对象] */
var pushStateMap = {
  '01': '待推送',
  '02': '推送成功',
  '03': '推送失败'
};
/** 推送状态[数组] */

var pushStateOptions = [{
  val: '01',
  name: '待推送'
}, {
  val: '02',
  name: '推送成功'
}, {
  val: '03',
  name: '推送失败'
}];
/** 调用状态[对象] */

var callStateMap = {
  '01': '待调用',
  '02': '调用成功',
  '03': '调用失败'
};
/** 调用状态[数组] */

var callStateOptions = [{
  val: '01',
  name: '待调用'
}, {
  val: '02',
  name: '调用成功'
}, {
  val: '03',
  name: '调用失败'
}];
/** 是否会员[对象] */

var memberFlagMap = {
  '0': '否',
  '1': '是'
};
/** 是否会员[数组] */

var memberFlagOptions = [{
  val: '0',
  name: '否'
}, {
  val: '1',
  name: '是'
}];
/** 调用类型[对象] */

var callTypeMap = {
  '0': 'SN调用',
  '1': '订单号调用'
};
/** 调用类型[数组] */

var callTypeOptions = [{
  val: '0',
  name: 'SN调用'
}, {
  val: '1',
  name: '订单号调用'
}];
/** 注册来源[对象] */

var registerSourceMap = {
  '01': '微信小程序',
  '02': 'CRM',
  '03': 'CSRM',
  '04': 'OMS',
  '05': 'Retail',
  '06': 'Dysonlink',
  '07': 'Yellow QRcode',
  '08': 'Tmall',
  '09': 'M2',
  '10': 'Stylist',
  '11': 'JD POP',
  '12': 'source5',
  '13': 'source6',
  '14': 'source7',
  '15': 'source8',
  '16': 'source9',
  '17': 'source10'
};
/** 注册来源[数组] */

var registerSourceOptions = [{
  val: '01',
  name: '微信小程序'
}, {
  val: '02',
  name: 'CRM'
}, {
  val: '03',
  name: 'CSRM'
}, {
  val: '04',
  name: 'OMS'
}, {
  val: '05',
  name: 'Retail'
}, {
  val: '06',
  name: 'Dysonlink'
}, {
  val: '07',
  name: 'Yellow QRcode'
}, {
  val: '08',
  name: 'Tmall'
}, {
  val: '09',
  name: 'M2'
}, {
  val: '10',
  name: 'Stylist'
}, {
  val: '11',
  name: 'JD POP'
}, {
  val: '12',
  name: 'source5'
}, {
  val: '13',
  name: 'source6'
}, {
  val: '14',
  name: 'source7'
}, {
  val: '15',
  name: 'source8'
}, {
  val: '16',
  name: 'source9'
}, {
  val: '17',
  name: 'source10'
}];
/** 注册类型[对象] */

var registerTypeMap = {
  '1': '自动注册',
  '2': '手动注册'
};
/** 注册类型[数组] */

var registerTypeOptions = [{
  val: '1',
  name: '自动注册'
}, {
  val: '2',
  name: '手动注册'
}];
/** sn状态[对象] */

var isValidMap = {
  '0': '无效',
  '1': '有效'
};
/** sn状态[数组] */

var isValidOptions = [{
  val: '0',
  name: '无效'
}, {
  val: '1',
  name: '有效'
}];
/** 90天权益[对象] */

var rightsMap = {
  '0': '无',
  '1': '有'
};
/** 90天权益[数组] */

var rightsOptions = [{
  val: '0',
  name: '无'
}, {
  val: '1',
  name: '有'
}];
/** 产品渠道状态[对象] */

var isEnableMap = {
  '0': '不可用',
  '1': '可用'
};
/** 产品渠道状态[数组] */

var isEnableOptions = [{
  val: '0',
  name: '不可用'
}, {
  val: '1',
  name: '可用'
}];
/** 活跃状态[对象] */

var activeStatusMap = {
  '01': '已绑定',
  '02': '已注销'
};
/** 活跃状态[数组] */

var activeStatusOptions = [{
  val: '01',
  name: '已绑定'
}, {
  val: '02',
  name: '已注销'
}];
var assetTypeOptions = [{
  val: '01',
  name: 'CN Normal'
}, {
  val: '02',
  name: 'CN Refurb'
}, {
  val: '03',
  name: 'Proper Global'
}, {
  val: '04',
  name: 'Gray Global'
}, {
  val: '05',
  name: 'Anomalies'
}];
var assetStatusOptions = [{
  val: '01',
  name: '已出库'
}, {
  val: '02',
  name: '已激活'
}, {
  val: '03',
  name: '已退回'
}, {
  val: '04',
  name: '已销毁'
}, {
  val: '05',
  name: '已锁定'
}];
var busTypeOption = [{
  val: 'customer_query',
  name: '客户查询'
}, {
  val: 'customer_sign_up',
  name: '客户注册'
}, {
  val: 'product_register',
  name: '产品注册'
}, {
  val: 'product_register_query',
  name: '产品注册查询'
}, {
  val: 'crm_customer',
  name: 'CRM客户信息查询'
}, {
  val: 'oms_order',
  name: '根据OMS订单号查询SN'
}, {
  val: 'oms_product',
  name: '查询SN销售日期'
}, {
  val: 'crm_product_register',
  name: '产品注册同步CRM'
}, {
  val: 'lock_status',
  name: '产品锁定状态查询'
}, {
  val: 'product_return',
  name: '产品解绑'
}, {
  val: 'product_lock',
  name: '产品锁定解锁'
}, {
  val: 'member_register',
  name: '单独注册会员'
}, {
  val: 'query_benefit_sku',
  name: '90天福利列表查询'
}, {
  val: 'extend_warranty',
  name: '同步会员权益延保'
}, {
  val: 'extend_warranty_query',
  name: '会员权益延保信息查询'
}];
var typeOption = [{
  val: 'CRM',
  name: 'CRM'
}, {
  val: 'CSRM',
  name: 'CSRM'
}, {
  val: 'OMS',
  name: 'OMS'
}];
var _default = {
  pushStateMap: pushStateMap,
  pushStateOptions: pushStateOptions,
  memberFlagMap: memberFlagMap,
  memberFlagOptions: memberFlagOptions,
  registerSourceMap: registerSourceMap,
  registerSourceOptions: registerSourceOptions,
  registerTypeMap: registerTypeMap,
  registerTypeOptions: registerTypeOptions,
  isValidMap: isValidMap,
  isValidOptions: isValidOptions,
  rightsMap: rightsMap,
  rightsOptions: rightsOptions,
  isEnableMap: isEnableMap,
  isEnableOptions: isEnableOptions,
  assetTypeOptions: assetTypeOptions,
  assetStatusOptions: assetStatusOptions,
  activeStatusMap: activeStatusMap,
  activeStatusOptions: activeStatusOptions,
  busTypeOption: busTypeOption,
  typeOption: typeOption,
  callStateMap: callStateMap,
  callStateOptions: callStateOptions,
  callTypeMap: callTypeMap,
  callTypeOptions: callTypeOptions
};
exports.default = _default;