var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_GDPR_HANDLESTATE"],
                      expression: "['ROLE_CONSULT_GDPR_HANDLESTATE']",
                    },
                  ],
                  attrs: {
                    disabled:
                      _vm.gdprTaskInfo.state === "2" ||
                      _vm.gdprTaskInfo.state === "1",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleState("1")
                    },
                  },
                },
                [_vm._v("已下发")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_GDPR_HANDLESTATE"],
                      expression: "['ROLE_CONSULT_GDPR_HANDLESTATE']",
                    },
                  ],
                  attrs: {
                    disabled:
                      _vm.gdprTaskInfo.state === "2" ||
                      _vm.gdprTaskInfo.state === "0",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleState("2")
                    },
                  },
                },
                [_vm._v("已处理")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "dataForm", attrs: { "label-width": "150px", size: "50%" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              订单信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "订单号" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "num",
                                                  $$v
                                                )
                                              },
                                              expression: "gdprTaskInfo.num",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "创建时间" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.inDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "inDate",
                                                  $$v
                                                )
                                              },
                                              expression: "gdprTaskInfo.inDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "任务类型" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.gdprTaskInfo.taskType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gdprTaskInfo,
                                                    "taskType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gdprTaskInfo.taskType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.GDPRTypes,
                                              function (type, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: type.name,
                                                    value: type.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "产品SN" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.gdprTaskInfo.serialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "serialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gdprTaskInfo.serialNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "处理状态" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.gdprTaskInfo.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gdprTaskInfo,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gdprTaskInfo.state",
                                              },
                                            },
                                            _vm._l(
                                              _vm.GDPRStates,
                                              function (type, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: type.name,
                                                    value: type.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "描述" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "textarea",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.gdprTaskInfo.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gdprTaskInfo.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              用户信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "用户姓名" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "gdprTaskInfo.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "手机号" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression: "gdprTaskInfo.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "邮箱" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "gdprTaskInfo.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "地址" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gdprTaskInfo.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { label: "邮编" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.gdprTaskInfo.postCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gdprTaskInfo,
                                                  "postCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gdprTaskInfo.postCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              订单履历\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "0px",
                                  "margin-right": "0px",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 22 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "50px",
                                            "margin-right": "50px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.listLoading,
                                                  expression: "listLoading",
                                                },
                                              ],
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                border: "",
                                                stripe: "",
                                                "header-cell-style": {
                                                  background: "#eef1f6",
                                                  color: "#606266",
                                                  height: "50px",
                                                },
                                                data: _vm.historyList,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "reRmk",
                                                  label: "操作人",
                                                  align: "center",
                                                  resizable: "",
                                                  "show-overflow-tooltip": "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "reDate",
                                                  label: "操作时间",
                                                  align: "center",
                                                  resizable: "",
                                                  "show-overflow-tooltip": "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  property: "state",
                                                  formatter: _vm.stateFormat,
                                                  label: "操作状态",
                                                  align: "center",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("pagination", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.total > 0,
                                                expression: "total>0",
                                              },
                                            ],
                                            attrs: {
                                              total: _vm.total,
                                              page: _vm.listQuery.page,
                                              limit: _vm.listQuery.limit,
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                return _vm.$set(
                                                  _vm.listQuery,
                                                  "page",
                                                  $event
                                                )
                                              },
                                              "update:limit": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.listQuery,
                                                  "limit",
                                                  $event
                                                )
                                              },
                                              pagination: _vm.getHistoryList,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }