"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * vsc
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var vscRouter = {
  path: '/vsc/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/vsc_call_record/:num',
    name: 'VscCallRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vsc/vsc_call_record/index'));
      });
    },
    meta: {
      title: 'vsc通话详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/vsc/vsc_call_record',
      parent: {
        path: '/vsc',
        redirect: 'noRedirect',
        meta: {
          title: 'vsc服务',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = vscRouter;
exports.default = _default;