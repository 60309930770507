"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.createData = createData;
exports.deleteData = deleteData;
exports.deleteItems = deleteItems;
exports.fetchList = fetchList;
exports.getItemsList = getItemsList;
exports.queryById = queryById;
exports.submitSave = submitSave;
exports.tempSave = tempSave;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询入库单分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 新建保存采购单
 */


function createData() {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/create',
    method: 'post'
  });
}
/**
 * 删除采购单
 */


function deleteData(ids) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/delete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 暂存采购信息
 * @param data
 */


function tempSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/tempSave',
    method: 'post',
    data: data
  });
}

function submitSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/submitSave',
    method: 'post',
    data: data
  });
}
/**
 * 查询采购单数据
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 查询采购单明细
 * @param parentId
 */


function getItemsList(parentId) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/getItemsList',
    method: 'post',
    params: {
      parentId: parentId
    }
  });
}
/**
 * 添加采购明细
 * @param item
 */


function addItems(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/addItems',
    method: 'post',
    data: data
  });
}
/**
 * 删除采购明细
 * @param itemIds
 */


function deleteItems(id, itemIds) {
  return (0, _request.default)({
    url: '/scm_app/stock_in_plan/deleteItems',
    method: 'post',
    params: {
      id: id,
      itemIds: itemIds
    }
  });
}