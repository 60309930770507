var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v(_vm._s(_vm.quotaApprove.orderNo)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isAudit
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_QUOTA_APPROVE_UPDATE"],
                      expression: "['ROLE_QUOTA_APPROVE_UPDATE']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { disabled: _vm.btnDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.recheckConfirm()
                    },
                  },
                },
                [_vm._v("确认")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.return2List()
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderInfo,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "检测信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("检测信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现前",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemBefore,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemBefore",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemBefore",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "问题出现后",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemAfter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemAfter",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemAfter",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "resolve方案",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .problemResolve,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "problemResolve",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.problemResolve",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { label: "工单号" },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo.orderNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "orderNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.orderNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测服务类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测服务子类型",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkSubType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkSubType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkSubType",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.businessSubTypeOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测结果",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled: "",
                                                          filterable: "",
                                                          clearable: "",
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderInfo
                                                              .checkResult,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderInfo,
                                                              "checkResult",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderInfo.checkResult",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.checkResultOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "人工物流费",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        value: "310",
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple",
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "检测备注",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "filter-item",
                                                      attrs: {
                                                        disabled: "",
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 2,
                                                        },
                                                        maxlength: "1000",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.orderInfo
                                                            .checkRemark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderInfo,
                                                            "checkRemark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderInfo.checkRemark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24, pull: 1 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "160px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      border: "",
                                                      stripe: "",
                                                      "tooltip-effect": "dark",
                                                      "header-cell-style": {
                                                        background: "#eef1f6",
                                                        color: "#606266",
                                                        height: "40px",
                                                      },
                                                      data: _vm.schemeList,
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "indexNum",
                                                        label: "序号",
                                                        width: "55",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "faultId",
                                                        label: "问题代码",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "repairId",
                                                        label: "处理方式",
                                                        resizable: "",
                                                        align: "center",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { "label-width": "150px", size: "50%" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "50px",
                          "margin-right": "50px",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          [
                            _c("order-price", {
                              ref: "checkPrice",
                              attrs: {
                                "order-info": _vm.orderInfo,
                                "audit-flag": !_vm.isAudit,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderInfo.quota !== null && _vm.orderInfo.quota !== ""
            ? _c(
                "el-form",
                {
                  ref: "dataForms",
                  attrs: {
                    model: _vm.quotaApprove,
                    "label-width": "150px",
                    size: "50%",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "0px",
                        "margin-right": "0px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 22 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "50px",
                                  "margin-right": "50px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "审批信息" },
                                  [
                                    _c(
                                      "el-divider",
                                      {
                                        staticClass: "myStyle",
                                        attrs: { "content-position": "left" },
                                      },
                                      [_vm._v("审批信息")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: { "border-top": "none" },
                                        attrs: { shadow: "never" },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 22 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "审批结果",
                                                      prop: "state",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          disabled: _vm.isAudit,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.quotaApprove
                                                              .state,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.quotaApprove,
                                                              "state",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "quotaApprove.state",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.resultOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.val,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 22 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "审批备注",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "textarea",
                                                        rows: 2,
                                                        disabled: _vm.isAudit,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.quotaApprove
                                                            .remark,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.quotaApprove,
                                                            "remark",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "quotaApprove.remark",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.orderCall,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "沟通信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("沟通信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "沟通结果" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm.orderCall
                                                          .callResult,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderCall,
                                                          "callResult",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderCall.callResult",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.callResultOptions,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.val,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "沟通时间" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm.orderCall.overTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderCall,
                                                          "overTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderCall.overTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "区域一",
                                                        value: "shanghai",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "沟通人" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm.orderCall.overUser,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderCall,
                                                          "overUser",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderCall.overUser",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "区域一",
                                                        value: "shanghai",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 23 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "沟通备注" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "textarea",
                                                    rows: 2,
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderCall.callRemark,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderCall,
                                                        "callRemark",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderCall.callRemark",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.quotaPool,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "额度信息" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("额度信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "月度总额度" },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: _vm.quotaPool.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quotaPool,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quotaPool.amount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "可用额度" } },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.quotaPool.remaining,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quotaPool,
                                                        "remaining",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quotaPool.remaining",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "本次减免额度",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.quotaApprove
                                                        .quotaPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quotaApprove,
                                                        "quotaPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quotaApprove.quotaPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.quotaApprove,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "0px", "margin-right": "0px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "50px",
                              "margin-right": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "审批附件" },
                              [
                                _c(
                                  "el-divider",
                                  {
                                    staticClass: "myStyle",
                                    attrs: { "content-position": "left" },
                                  },
                                  [_vm._v("审批附件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "box-card",
                                    staticStyle: { "border-top": "none" },
                                    attrs: { shadow: "never" },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 10 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("upload-imgs", {
                                                      attrs: {
                                                        limit: 1,
                                                        disabled: _vm.isAudit,
                                                        attachment:
                                                          _vm.quotaApprove
                                                            .adjunctPicture,
                                                      },
                                                      on: {
                                                        setFilePaths:
                                                          _vm.setFilePaths,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 10 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("upload-file-msg", {
                                                      attrs: {
                                                        limit: 1,
                                                        disabled: _vm.isAudit,
                                                        "email-name":
                                                          _vm.quotaApprove
                                                            .adjunctEmailName,
                                                        attachment:
                                                          _vm.quotaApprove
                                                            .adjunctEmail,
                                                      },
                                                      on: {
                                                        setEmailName:
                                                          _vm.setEmailNames,
                                                        setFilePaths:
                                                          _vm.setFilePathto,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }