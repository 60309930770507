var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_REGISTER_FAIL_ALLOW_REGISTER"],
                      expression: "['ROLE_REGISTER_FAIL_ALLOW_REGISTER']",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.buttonDisabled },
                  on: { click: _vm.permitRegister },
                },
                [_vm._v("允许注册")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_REGISTER_FAIL_REFUSE_REGISTER"],
                      expression: "['ROLE_REGISTER_FAIL_REFUSE_REGISTER']",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.buttonDisabled },
                  on: { click: _vm.refuseRegister },
                },
                [_vm._v("拒绝注册")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.productRegisterFailInfo,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              基本信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "电话",
                                            prop: "mobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterFailInfo
                                                  .mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterFailInfo,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterFailInfo.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品SN",
                                            prop: "serialNumber",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterFailInfo
                                                  .serialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterFailInfo,
                                                  "serialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterFailInfo.serialNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "prefix是否有效",
                                            prop: "prefixState",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .prefixState,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "prefixState",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.prefixState",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "无效",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "有效",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "产品状态",
                                            prop: "snState",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .snState,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "snState",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.snState",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "无效",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "有效",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册时间",
                                            prop: "registerDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterFailInfo
                                                  .registerDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterFailInfo,
                                                  "registerDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterFailInfo.registerDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册来源",
                                            prop: "registerSource",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .registerSource,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "registerSource",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.registerSource",
                                              },
                                            },
                                            _vm._l(
                                              _vm.registerSourceOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "购买时间",
                                            prop: "purchaseDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                _vm.productRegisterFailInfo
                                                  .purchaseDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.productRegisterFailInfo,
                                                  "purchaseDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productRegisterFailInfo.purchaseDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "错误信息",
                                            prop: "errMsg",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .errMsg,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "errMsg",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.errMsg",
                                              },
                                            },
                                            _vm._l(
                                              _vm.msgOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.val,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "处理状态",
                                            prop: "state",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.state",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "未处理",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "已绑定",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "2",
                                                  label: "已拒绝",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "阅读状态",
                                            prop: "readState",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.productRegisterFailInfo
                                                    .readState,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.productRegisterFailInfo,
                                                    "readState",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "productRegisterFailInfo.readState",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: "0",
                                                  label: "未读",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: "1",
                                                  label: "已读",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "销售渠道编码",
                                            prop: "saleChannel",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { disabled: _vm.status },
                                              model: {
                                                value: _vm.channelCode,
                                                callback: function ($$v) {
                                                  _vm.channelCode = $$v
                                                },
                                                expression: "channelCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.options,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认允许注册?",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.registerDisabled },
                  on: { click: _vm.allowRegister },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }