"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _request = require("@/utils/request1");

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _recheck = _interopRequireDefault(require("@/components/order/recheck"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _dispatch = _interopRequireDefault(require("@/components/order/dispatch"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _index = _interopRequireDefault(require("@/components/order/checkPrice/index"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 复核信息
// 检测信息
// 分派信息
// 收货信息
// 客户信息
// 产品注册信息
// 外呼信息
// 费用明细
// 新建任务工单
var _default = {
  name: 'orderHandleDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    orderRecheck: _recheck.default,
    orderCheck: _check.default,
    orderDispatch: _dispatch.default,
    orderReceipt: _receipt.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCallout: _calloutList.default,
    orderPrice: _index.default,
    NewTask: _NewTask.default
  },
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter
  },
  data: function data() {
    // 自定义校验：不为空或空格
    var validateBlank = function validateBlank(rule, value, callback) {
      if (!value || value.trim() === '') {
        callback('请输入有效信息');
      } else {
        callback();
      }
    };

    return {
      btnLoading: false,
      id: this.$route.params.id,
      step: '4',
      consumerShow: '1',
      productShow: '1',
      calloutShow: '0',
      isReadOnly: false,
      // businessTypeOptions: constant.businessTypeOptions,
      businessTypeOptions: [{
        val: '01',
        name: '维修'
      }],
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      handleResultOptions: _constant.default.handleResultOptions,
      feeTypeOptions: _constant.default.feeTypeOptions,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      rules: {
        handleType: [{
          required: true,
          message: '最终服务类型是必填的',
          trigger: 'blur'
        }],
        handleSubType: [{
          required: true,
          message: '最终服务子类型是必填的',
          trigger: 'blur'
        }],
        handleResult: [{
          required: true,
          message: '处理结果是必填的',
          trigger: 'blur'
        }],
        handleShow: [{
          required: true,
          message: '前端展示是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }],
        handleRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }],
        serviceFeeId: [{
          required: true,
          message: '用户收费是必填的',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      schemeAdd: {
        problemType: undefined,
        faultId: undefined,
        repairIds: [],
        problem: undefined,
        resolution: undefined,
        step: '4'
      },
      addSchemeQ: false,
      addSchemeA: false,
      schemeList: [],
      schemeListLoading: false,
      // <下拉数组> 维修分类
      repairTypeSelect: [],
      faultSelect: [],
      repairSelect: [],
      schemeAddrules: {
        problemType: [{
          required: true,
          message: '问题分类是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }],
        problem: [{
          required: true,
          message: '其他问题代码是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }],
        resolution: [{
          required: true,
          message: '其他处理方式是必填的',
          trigger: 'blur'
        }, {
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }, {
          validator: validateBlank,
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        step: '4'
      },
      skuList: [],
      skuListLoading: false,
      skuSelect: [],
      // ----------------------------------------------------------------------------------------------------------------------------------
      checkItemList4show: [],
      refDateBefore: ''
    };
  },
  computed: {
    confirmBtnStatus: function confirmBtnStatus() {
      return ['S300', 'S312', 'S313'].includes(this.orderInfo.subState);
    },
    PricesuBStatus: function PricesuBStatus() {
      return ['0101', '0301', '0201', '0202', '0204'].includes(this.orderInfo.subType);
    },
    schemeAddBtnDisabled: function schemeAddBtnDisabled() {
      return !this.schemeAdd.faultId || this.schemeAdd.faultId === '' || this.schemeAdd.repairIds.length === 0;
    },
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      return !this.skuAdd.feeType || this.skuAdd.feeType === '' || !this.skuAdd.skuCode || this.skuAdd.skuCode === '';
    },
    handleType: function handleType() {
      return this.orderInfo.handleType;
    },
    faultId4schemeAdd: function faultId4schemeAdd() {
      return this.schemeAdd.faultId;
    },
    repairIds4schemeAdd2: function repairIds4schemeAdd2() {
      return this.schemeAdd.repairIds;
    }
  },
  watch: {
    handleType: function handleType(val, oldval) {
      this.handleSubTypeChange(val, oldval);
    },
    faultId4schemeAdd: function faultId4schemeAdd(val, oldval) {
      this.addSchemeQ = val === '其他';

      if (oldval && val !== oldval) {
        this.schemeAdd.problem = '';
        this.schemeAdd.repairIds = [];

        if (val === '') {
          this.schemeAdd.repairIds = [];
        }
      }
    },
    repairIds4schemeAdd2: function repairIds4schemeAdd2(val, oldval) {
      this.addSchemeA = val && val.includes('其他');

      if (oldval && val !== oldval) {
        this.schemeAdd.resolution = '';
      }
    }
  },
  created: function created() {
    var _this = this;

    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
      this.loadSchemeList();
      this.loadOrderSkuList();
      this.initShowcheckItemList4show();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);

        _this.$refs.checkPrice.$emit('loadCheckInfo', _this.id);
      });
    }
  },
  methods: {
    setUploadFileList: function setUploadFileList(data) {
      this.orderInfo.uploadFiles = data;
    },
    setBtnsState: function setBtnsState(btnState) {
      this.btnLoading = btnState;
    },
    handleSubTypeChange: function handleSubTypeChange(val, oldval) {
      if (oldval && val !== oldval) {
        this.orderInfo.handleSubType = '';
      }

      if (val === '01') {
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions2;
      } else if (val === '02') {
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions3;
      } else if (val === '03') {
        this.businessSubTypeOptions = _constant.default.businessSubTypeOptions4;
      } else {
        this.businessSubTypeOptions = [];
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    loadRepairTypeSelect: function loadRepairTypeSelect() {
      var _this2 = this;

      var params = {
        isEnable: '1',
        categoryId: this.orderInfo.category
      };
      (0, _select.getCodeTypeSelect)('fault', params).then(function (res) {
        _this2.repairTypeSelect = res.data.records;
      });
      this.schemeAdd.faultId = '';
      this.faultSelect = [];
    },
    loadFaultSelect: function loadFaultSelect() {
      var _this3 = this;

      var params = {
        typeId: this.schemeAdd.problemType,
        categoryId: this.orderInfo.category,
        isEnable: '1'
      };
      (0, _select.getFaultSelect)(params).then(function (res) {
        _this3.faultSelect = res.data;

        _this3.faultSelect.push({
          'id': '其他',
          'name': '其他'
        });
      });
    },
    loadRepairSelect: function loadRepairSelect() {
      var _this4 = this;

      if (this.schemeAdd.faultId && this.schemeAdd.faultId !== '') {
        var faultId = this.schemeAdd.faultId;

        if (this.schemeAdd.faultId === '其他') {
          faultId = undefined;
        }

        var params = {
          categoryId: this.orderInfo.category,
          faultId: faultId,
          isEnable: '1'
        };
        (0, _select.getRepairSelect)(params).then(function (res) {
          _this4.repairSelect = res.data;

          _this4.repairSelect.push({
            'id': '其他',
            'name': '其他'
          });
        });
      } else {
        this.repairSelect = [];
      }
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this5 = this;

      var params = {
        modelId: this.orderInfo.model,
        isEnable: '1',
        tag: '2'
      };
      (0, _select.getSkuSelectTrue)(params).then(function (res) {
        _this5.skuSelect = res.data;
      });
    },
    initSelect: function initSelect() {
      var _this6 = this;

      var params = {
        isEnable: '1'
      };
      (0, _select.getCodeTypeSelect)('fault', params).then(function (res) {
        _this6.repairTypeSelect = res.data.records;
      });
    },
    initShowcheckItemList4show: function initShowcheckItemList4show() {
      var _this7 = this;

      (0, _order.getCheckItems)({
        orderId: this.id
      }).then(function (response) {
        if (response.data) {
          var itemArray = response.data;
          var len = itemArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this7.checkItemList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = itemArray.slice(i * n, i * n + n);

            _this7.checkItemList4show.push(temp);
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this8 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this8.orderInfo = response.data;
          _this8.refDateBefore = _this8.orderInfo.refDate;
          _this8.orderInfo.refDateBefore = _this8.refDateBefore;

          _this8.loadOrderSkuList();

          _this8.$refs.orderProduct.$emit('loadFreeSn', _this8.orderInfo.sn);
        }
      });
    },
    loadSchemeList: function loadSchemeList() {
      var _this9 = this;

      this.schemeListLoading = true;
      var params = {
        orderId: this.id,
        step: '4'
      };
      (0, _order.getSchemeList)(params).then(function (response) {
        if (response.data) {
          _this9.schemeList = response.data;
        }
      });
      this.schemeListLoading = false;
    },
    loadOrderSkuList: function loadOrderSkuList() {
      var _this10 = this;

      this.skuListLoading = true;
      var params = {
        orderId: this.id,
        step: '4'
      };
      (0, _order.getOrderSkuList)(params).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this10.skuList = response.data.map(function (v) {
            _this10.$set(v, 'indexNum', ++indexNum); // 动态加字段


            _this10.$set(v, 'edit', false); // 动态加字段


            return v;
          });
        }
      });
      this.skuListLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 验证方法：[换货]必须添加一个“成品”物料
    validateList: function validateList() {
      if (this.schemeList.length === 0) {
        this.$message({
          showClose: true,
          message: '【问题代码 + 处理方式】列表至少添加一行',
          type: 'error'
        });
        return false;
      }

      return true;
    },
    // 【处理完成】
    handleConfirm: function handleConfirm() {
      var _this11 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this11.validateList()) {
          _this11.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this11.handleOrderSumbit();
          }).catch(function () {});
        } else {
          _this11.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    handleOrderSumbit: function handleOrderSumbit() {
      var _this12 = this;

      this.btnLoading = true;
      this.orderInfo.orderSkuList = this.skuList;
      this.orderInfo.checkItemList = this.checkItemList4show.flat();
      (0, _order.handleOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this12.$message({
            showClose: true,
            message: '处理完了',
            type: 'success'
          });

          _this12.$store.dispatch('tagsView/delView', _this12.$route);

          _this12.$router.push({
            path: '/order/handle/list'
          });
        } else {
          _this12.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【问题解决方案】
    addSchemeClick: function addSchemeClick() {
      var _this13 = this;

      this.$refs['schemeAddForm'].validate(function (valid) {
        if (valid) {
          _this13.btnLoading = true;
          _this13.schemeAdd.orderId = _this13.orderInfo.id;
          _this13.schemeAdd.orderNo = _this13.orderInfo.orderNo;
          (0, _order.addScheme)(_this13.schemeAdd).then(function (response) {
            if (response.data) {
              _this13.$message({
                showClose: true,
                message: '添加成功！',
                type: 'success'
              });

              _this13.loadSchemeList();
            } else {
              _this13.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
          _this13.btnLoading = false;
        } else {
          _this13.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【问题解决方案】
    removeSchemeClick: function removeSchemeClick(row) {
      var _this14 = this;

      this.btnLoading = true;
      var params = {
        step: '4',
        orderId: this.orderInfo.id,
        faultId: row.faultId
      };
      (0, _order.removeScheme)(params).then(function (response) {
        if (response.data) {
          _this14.$message({
            showClose: true,
            message: '删除成功！',
            type: 'success'
          });

          _this14.loadSchemeList();
        } else {
          _this14.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 添加【工单物料SKU】
    addSkuClick: function addSkuClick() {
      var _this15 = this;

      // 检查是否已经添加
      if (this.skuList.find(function (v) {
        return v.skuCode === _this15.skuAdd.skuCode;
      })) {
        return;
      }

      this.btnLoading = true;
      var newSkuItem = {};
      newSkuItem.orderId = this.orderInfo.id;
      newSkuItem.orderNo = this.orderInfo.orderNo;
      newSkuItem.feeType = this.skuAdd.feeType;
      newSkuItem.quantity = 1;
      newSkuItem.state = 1;
      var skuInfo = this.skuSelect.filter(function (ob) {
        return _this15.skuAdd.skuCode === ob.code;
      })[0];
      newSkuItem.skuId = skuInfo.id;
      newSkuItem.skuCode = skuInfo.code;
      newSkuItem.skuName = skuInfo.name;
      newSkuItem.skuType = skuInfo.type;
      newSkuItem.skuTag = skuInfo.skuTag;
      newSkuItem.priceExcludeTax = skuInfo.priceExcludeTax;
      newSkuItem.priceIncludeTax = skuInfo.priceIncludeTax;
      newSkuItem.stock = skuInfo.stock;
      newSkuItem.ddirStock = skuInfo.ddirStock;
      newSkuItem.brandId = this.orderInfo.brand;
      newSkuItem.categoryId = this.orderInfo.category;
      newSkuItem.categoryName = this.orderInfo.categoryName;
      newSkuItem.modelId = this.orderInfo.model;
      newSkuItem.modelName = this.orderInfo.modelName;
      this.skuList.push(newSkuItem);
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this15.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this15.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 删除【工单物料SKU】
    removeSkuClick: function removeSkuClick(row) {
      var _this16 = this;

      this.btnLoading = true;
      this.skuList.splice(this.skuList.findIndex(function (item) {
        return item.indexNum === row.indexNum;
      }), 1);
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this16.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this16.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 更新【工单物料SKU】
    updateOrderSkuClick: function updateOrderSkuClick(row) {
      var _this17 = this;

      this.btnLoading = true;
      var indexNum = 0;
      this.skuList.map(function (v) {
        _this17.$set(v, 'indexNum', ++indexNum); // 动态加字段


        _this17.$set(v, 'edit', false); // 动态加字段


        return v;
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 快捷TAG查询
    stepClick: function stepClick(tab, event) {
      this.step = tab.name;

      if (tab.name === '1') {
        this.$refs.receiptOrder.$emit('loadExpressGoods', this.id);
      } else if (tab.name === '3') {
        this.$refs.checkOrder.$emit('loadcheckInfo', {
          id: this.orderInfo.id
        });
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    printDown: function printDown(type) {
      (0, _request.downLoadFile)('/bus_app/order/print/check/download?type=' + type + '&id=' + this.id).then(function (res) {
        var fileName = res.headers['content-disposition'].split('=')[1];
        var _res = res.data;
        var blob = new Blob([_res]);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    dataChange: function dataChange(val) {
      this.orderInfo.refDate = (0, _utils.parseTime)(val.refDate, '{y}-{m}-{d}');
      this.orderInfo.refDateBefore = val.refDateBefore;
    },
    tableRowClassName: function tableRowClassName(row) {
      if (row.row.state === '0') {
        return 'rowStyle3';
      } else {
        return 'rowStyle2';
      }
    }
  }
};
exports.default = _default;