"use strict";

var _interopRequireWildcard = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/core-js/modules/es6.promise.js");

require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/core-js/modules/es6.object.assign.js");

require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

var filters = _interopRequireWildcard(require("./filters"));

var _directives = _interopRequireDefault(require("./utils/directives"));

var _index3 = _interopRequireDefault(require("@/directive/permission/index"));

// A modern alternative to CSS resets
// lang i18n
// global css
// icon
// permission control
// 全局过滤器
// set ElementUI lang to zh-CN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.use(_directives.default);

_vue.default.use(_index3.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});