"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 顺丰下单路由
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var expressRouter = {
  path: '/express/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/express_org/detail/:id',
    name: 'expressOrgDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/express/express_org/detail'));
      });
    },
    meta: {
      title: '通讯录机构详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/express/express_org',
      parent: {
        path: '/express',
        redirect: 'noRedirect',
        meta: {
          title: '通讯录机构',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/sf_express_order/detail/:id',
    name: 'sfExpressOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/express/sf_express_order/detail'));
      });
    },
    meta: {
      title: '顺丰取件单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/express/sf_express_order',
      parent: {
        path: '/express',
        redirect: 'noRedirect',
        meta: {
          title: '顺丰取件单',
          breadcrumb: true
        }
      }
    }
  }, {
    path: '/sf_express_order/modify/:id',
    name: 'sfExpressOrderModify',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/express/sf_express_order/modify'));
      });
    },
    meta: {
      title: '顺丰取件单详情',
      icon: 'table',
      hidden: true,
      activeMenu: '/express/sf_express_order',
      parent: {
        path: '/express',
        redirect: 'noRedirect',
        meta: {
          title: '顺丰取件单',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = expressRouter;
exports.default = _default;