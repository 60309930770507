"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeOrganizationById = changeOrganizationById;
exports.createOrganization = createOrganization;
exports.deleteOrganization = deleteOrganization;
exports.fetchList = fetchList;
exports.getAllOrganizationList = getAllOrganizationList;
exports.getOrgAList = getOrgAList;
exports.getOrgTreeById = getOrgTreeById;
exports.getOrganizationList = getOrganizationList;
exports.getParentOrgList = getParentOrgList;
exports.queryById = queryById;
exports.updateOrganization = updateOrganization;
exports.validateCode = validateCode;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/acc_app/organization/getPageList',
    method: 'get',
    params: query
  });
}
/**
 * 新建保存
 * @param data
 */


function createOrganization(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/acc_app/organization/save',
    method: 'post',
    data: data
  });
}
/**
 * 编辑
 * @param data
 */


function updateOrganization(data) {
  return (0, _request.default)({
    url: '/acc_app/organization/update',
    method: 'post',
    data: data
  });
}
/**
 * 禁用、启用、删除
 * @param data
 */


function changeOrganizationById(data) {
  return (0, _request.default)({
    url: '/acc_app/organization/updateEnableOrStatus',
    method: 'get',
    params: data
  });
}
/**
 * 查询机构列表
 * @param query
 */


function getOrganizationList(query) {
  return (0, _request.default)({
    url: '/acc_app/organization/getOrganizationList',
    method: 'get',
    params: query
  });
}
/**
 * 查询【自身机构+所有下级机构】集合
 */


function getOrgTreeById(params) {
  return (0, _request.default)({
    url: '/acc_app/organization/getOrgTreeById',
    method: 'get',
    params: params
  });
}
/**
 * 作为查询条件  查询当前系统所有可用机构
 * @param query
 */


function getAllOrganizationList(query) {
  return (0, _request.default)({
    url: '/acc_app/organization/getAllOrganizationList',
    method: 'get',
    params: query
  });
}
/**
 * 机构列表的上级机构内容
 * @param query
 */


function getParentOrgList(query) {
  return (0, _request.default)({
    url: '/acc_app/organization/getParentOrgList',
    method: 'get',
    params: query
  });
}
/**
 * 查询所有可用的一级网点
 */


function getOrgAList() {
  return (0, _request.default)({
    url: '/acc_app/organization/getOrgAList',
    method: 'post'
  });
}
/**
 * 批量删除（逻辑删除）
 * @param data
 */


function deleteOrganization(data) {
  console.log(data);
  return (0, _request.default)({
    url: '/acc_app/organization/delete',
    method: 'post',
    data: {
      dataList: data
    }
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/acc_app/organization/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 校验code唯一性
 * @param value
 */


function validateCode(value) {
  console.log(value);
  return (0, _request.default)({
    url: '/acc_app/organization/validateCode',
    method: 'post',
    params: {
      code: value
    }
  });
}