"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItemList = getItemList;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 额度变动流水查询 分页
 * @param query
 */
function getItemList(query) {
  return (0, _request.default)({
    url: '/scm_app/quota_history/getPageList',
    method: 'post',
    params: query
  });
}