"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _sale = require("@/api/sale/sale");

var _select = require("@/api/common/select");

var _quotaApprove = require("@/api/reduction/approve/quotaApprove");

var _utils = require("@/utils");

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 下拉框 树形结构 可检索组件
var _default = {
  name: 'approveSaleDetail',
  // eslint-disable-line
  components: {
    treeSelect: _treeSelect.default
  },
  data: function data() {
    return {
      orderNo: this.$route.params.orderNo,
      level: this.$route.params.level,
      back: this.$route.params.back,
      isDis: true,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible8: false,
      dialog8Msg: '',
      btnLoading: false,
      tempBtn: false,
      completeBtn: false,
      quotaApprove: [],
      quotaPool: [],
      isAudit: false,
      isAudit2: true,
      resultOptions: _constant.default.saleQuotaStates,
      amount: null,
      // 购物车数量
      priceBefore: null,
      // 税前总价
      priceAfter: null,
      // 含税总价
      quotaPrice: null,
      // 减免金额
      quotaState: null,
      // 审批结果
      // <下拉数组> 品类下拉
      categorySelect: [],
      // <下拉数组> 品牌
      brandSelect: [],
      // <下拉数组> 型号
      modelSelect: [],
      payType: _constant.default.payType,
      describeList: [{
        label: '购买配件（无原因）',
        value: '01'
      }, {
        label: '配件丢失',
        value: '02'
      }, {
        label: '人为损坏',
        value: '03'
      }, {
        label: '消耗品',
        value: '04'
      }, {
        label: '保内损坏（非人为）',
        value: '05'
      }, {
        label: '开箱破损',
        value: '06'
      }, {
        label: '开箱缺件',
        value: '07'
      }, {
        label: '保外/特例补偿',
        value: '08'
      }, {
        label: '活动赠品补发',
        value: '09'
      }, {
        label: '其他',
        value: '10'
      }],
      cancelReason: [{
        label: '取消服务',
        value: '10'
      }, {
        label: '客服下错单',
        value: '20'
      }, {
        label: '时间过长',
        value: '30'
      }, {
        label: '测试订单',
        value: '40'
      }, {
        label: '价格调整',
        value: '50'
      }],
      validateForm: [],
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      isCancel: false,
      saleInfo2: {
        cancelMemo: '',
        cancelReason: ''
      },
      // <列表数组> 型号选择条件
      listQuery: {
        categoryId: undefined,
        brandId: undefined,
        id: undefined
      },
      skuList: [],
      rules: {
        state: [{
          required: true,
          message: '审批结果是必选的',
          trigger: 'blur'
        }]
      },
      tableData1: [],
      accordion: false,
      cTree: true,
      saleInfo: {
        amount: 0,
        priceAfter: '0.00',
        priceBefore: '0.00',
        urgent: '1',
        province: '',
        city: '',
        district: '',
        state: '',
        items: [{
          categoryId: '',
          modelId: '',
          skuCode: '',
          brandId: '',
          skuName: '',
          skuId: '',
          num: 0,
          payType: '',
          describes: '',
          memo: '',
          priceBeforeY: '',
          priceBeforeYY: '',
          priceAfterY: '',
          priceAfterYY: '',
          stock: '',
          reducePriceBefore: '',
          reducePriceAfter: '',
          src: 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image'
        }]
      }
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    }
  },
  created: function created() {
    this.getSelect();
    this.getApproveByOrderNo();
    this.getPool();
  },
  methods: {
    getProvinceOptions: function getProvinceOptions() {
      var _this = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this.provinceOptions = response.data;
      });
    },
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value, index) {
      this.saleInfo.items[index].categoryId = value;
    },
    // 获取维修代码详细
    getSaleInfo: function getSaleInfo() {
      var _this2 = this;

      var param = {
        num: this.orderNo
      };
      (0, _select.getCategorySelect)().then(function (res) {
        _this2.categorySelect = res.data.records;
        (0, _sale.queryByNum)(param).then(function (response) {
          if (response.data) {
            _this2.saleInfo = response.data;

            if (_this2.saleInfo.items != null && _this2.saleInfo.items.length > 0) {
              var a = 0;
              var b = 0;
              var c = 0;

              _this2.saleInfo.items.forEach(function (it, index) {
                if (it.src === '' || it.src === null || it.src === undefined) {
                  it.src = 'https://www.placehold.it/600x330/EFEFEF/AAAAAA&text=no+image';
                }

                if (!_this2.quotaApprove.state && it.payType === '06') {
                  it.priceAfterY = Number(it.reducePriceAfter) / 100;
                  it.priceBeforeY = Number(it.reducePriceBefore) / 100;
                } else {
                  it.priceAfterY = Number(it.priceAfter) / 100;
                  it.priceBeforeY = Number(it.priceBefore) / 100;
                }

                var p1 = {
                  categoryId: it.categoryId
                };
                (0, _select.getModelSelect)(p1).then(function (res) {
                  _this2.modelSelect[index] = res.data;
                });
                var p2 = {
                  modelId: it.modelId,
                  isRetail: '1'
                };
                (0, _select.getSkuSelect)(p2).then(function (res) {
                  _this2.skuList[index] = res.data;
                });

                if (it.payType === '06') {
                  a = parseInt(a) + parseInt(it.num);
                  b = Number(b) + Number(it.reducePriceBefore) * parseInt(it.num);
                  c = Number(c) + Number((Number(it.reducePriceAfter) * parseInt(it.num)).toFixed(0));
                }
              });

              if (_this2.quotaApprove.state === '2' || _this2.quotaApprove.state === '1') {
                _this2.amount = _this2.saleInfo.amount;
                _this2.priceAfter = _this2.saleInfo.priceAfter / 100;
                _this2.priceBefore = _this2.saleInfo.priceBefore / 100;
                _this2.quotaPrice = _this2.quotaApprove.quotaPrice / 100;
              } else {
                _this2.amount = a;
                _this2.priceAfter = c / 100;
                _this2.priceBefore = b / 100;
                _this2.quotaPrice = _this2.priceBefore;
              }
            }

            if (_this2.saleInfo.province !== null && _this2.saleInfo.province !== '') {
              (0, _select.getAreasByParentId)(_this2.saleInfo.province).then(function (response) {
                _this2.cityOptions = response.data;
              });
            }

            _this2.getProvinceOptions(); // 区/县  回显


            if (_this2.saleInfo.city !== null && _this2.saleInfo.city !== '') {
              (0, _select.getAreasByParentId)(_this2.saleInfo.city).then(function (response) {
                _this2.countryOptions = response.data;
              });
            }
          }
        });
      });
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this3 = this;

      var params = {};
      (0, _select.getBrandSelect)(params).then(function (res) {
        _this3.brandSelect = res.data.records;
      });
    },
    getApproveByOrderNo: function getApproveByOrderNo() {
      var _this4 = this;

      (0, _quotaApprove.getApproveByOrderNo)(this.orderNo).then(function (response) {
        if (response.data) {
          _this4.quotaApprove = response.data;
          _this4.quotaState = _this4.quotaApprove.state;
          _this4.quotaApprove.remark = (0, _utils.HTMLDecode)(_this4.quotaApprove.remark);

          if (_this4.quotaApprove.state !== '0') {
            _this4.isAudit = true;
            _this4.isAudit2 = false;
          } else {
            _this4.quotaApprove.state = null;
          }

          _this4.getSaleInfo();
        }
      });
    },
    getPool: function getPool() {
      var _this5 = this;

      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      (0, _quotaApprove.getPool)(year, month).then(function (response) {
        if (response.data) {
          _this5.quotaPool = response.data;
        }
      });
    },
    // 返回跳转过来的审批页面
    return2List: function return2List() {
      if (this.back !== undefined && this.back !== null && this.back !== '') {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveRecord'
        });
      }

      if (this.level === 1) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approve'
        });
      } else if (this.level === 2) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveStair'
        });
      } else if (this.level === 3) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveTertiary'
        });
      }
    },
    // 提交审批结果，审批完成
    recheckConfirm: function recheckConfirm() {
      var _this6 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          _this6.$confirm('是否确认？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            if (_this6.quotaApprove.state === '2') {
              _this6.updateSaleOrder();
            } else {
              _this6.quotaApprove.quotaPrice = Number(_this6.priceBefore) * 100;

              _this6.recheckOrderSumbit();
            }
          }).catch(function () {});
        } else {
          _this6.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    // 审批拒绝 更改销售明细 销售单税前总价及税后总价
    updateSaleOrder: function updateSaleOrder() {
      var _this7 = this;

      this.saleInfo.priceAfter = Number(this.saleInfo.priceAfter) + Number(this.priceAfter) * 100;
      this.saleInfo.priceBefore = Number(this.saleInfo.priceBefore) + Number(this.priceBefore) * 100;
      (0, _quotaApprove.updateSaleOrderPrice)(this.saleInfo).then(function (response) {
        if (!response.data) {
          _this7.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        } else {
          _this7.recheckOrderSumbit();
        }
      });
    },
    // 审批完成方法，审批完成跳转回到原来的审批页面
    recheckOrderSumbit: function recheckOrderSumbit() {
      var _this8 = this;

      this.btnLoading = true;
      this.quotaApprove.remark = (0, _utils.HTMLEncode)(this.quotaApprove.remark);
      (0, _quotaApprove.updateSaleApprove)(this.quotaApprove).then(function (response) {
        if (response.data > 0) {
          _this8.$message({
            showClose: true,
            message: '审批完成',
            type: 'success'
          });

          if (_this8.level === 1) {
            _this8.$store.dispatch('tagsView/delView', _this8.$route);

            _this8.$router.push({
              path: '/reduction/approve'
            });
          } else if (_this8.level === 2) {
            _this8.$store.dispatch('tagsView/delView', _this8.$route);

            _this8.$router.push({
              path: '/reduction/approveStair'
            });
          } else if (_this8.level === 3) {
            _this8.$store.dispatch('tagsView/delView', _this8.$route);

            _this8.$router.push({
              path: '/reduction/approveTertiary'
            });
          }
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    }
  }
};
exports.default = _default;