"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRoUpdate = createRoUpdate;
exports.editNoticeById = editNoticeById;
exports.editNoticeByOrgId = editNoticeByOrgId;
exports.fetchList = fetchList;
exports.getNoticeByOrgId = getNoticeByOrgId;
exports.getNoticeOrgId = getNoticeOrgId;

var _request = _interopRequireDefault(require("@/utils/request"));

function fetchList(data) {
  return (0, _request.default)({
    url: '/acc_app/notice/list',
    method: 'post',
    // 此处简写意为data: data,参数名不为"data"时简写无效
    data: data
  });
}

function createRoUpdate(data) {
  return (0, _request.default)({
    url: '/acc_app/notice/createRoUpdate',
    method: 'post',
    data: data
  });
}
/**
 * 公告编辑
 *
 * @param id
 * @returns {AxiosPromise}
 */


function editNoticeById(id) {
  return (0, _request.default)({
    url: '/acc_app/notice/editNoticeById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 公告适用机构编辑
 *
 * @param id
 * @returns {AxiosPromise}
 */


function editNoticeByOrgId(id) {
  return (0, _request.default)({
    url: '/acc_app/notice/editNoticeByOrgId',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 公告适用机构编辑查询
 *
 * @param id
 * @returns {AxiosPromise}
 */


function getNoticeOrgId(id) {
  return (0, _request.default)({
    url: '/acc_app/notice/getNoticeOrgId',
    method: 'post',
    params: {
      parentId: id
    }
  });
}
/**
 * 根据机构id获取对应的公告
 *
 * @returns {AxiosPromise}
 */


function getNoticeByOrgId() {
  return (0, _request.default)({
    url: '/acc_app/notice/getNoticeByOrgId',
    method: 'get'
  });
}