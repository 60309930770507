"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _productEnrollment = require("@/api/register/productEnrollment");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ProductEnrollmentDetail',
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      enrollmentInfo: {},
      btnLoading: false,
      listLoading: false,
      enrollmentHistoryList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getDetail();
  },
  methods: {
    // 初始查询详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _productEnrollment.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.enrollmentInfo = response.data;

          if (_this.enrollmentInfo.id) {
            _this.listQuery.parentId = _this.enrollmentInfo.id;

            _this.getHistoryList();
          }
        }
      });
    },
    // 获取履历
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _productEnrollment.getHistoryList)(this.listQuery).then(function (response) {
        _this2.enrollmentHistoryList = response.data.records;

        _this2.enrollmentHistoryList.forEach(function (item) {
          item.warrantyNote = (0, _utils.HTMLDecode)(item.warrantyNote);
        });

        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/register/product_enrollment'
      });
    },
    statusFormate: function statusFormate(row) {
      return row.isEnable === '1' ? '已生效' : '已失效';
    }
  }
};
exports.default = _default;