"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _filterUtils = require("@/api/register/filterUtils");

var _list = require("@/api/vsc/callRecord/list");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _utils = require("@/utils");

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'VscCallRecord',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    dragdialog: _elDragDialog.default
  },
  filters: {
    isEnableFilter: _filterUtils.isEnableFilter
  },
  data: function data() {
    return {
      num: this.$route.params.num,
      detailShow: false,
      vseVideoCallInfo: {},
      vseVideoCallList: [],
      listData: null,
      // 列表数据
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 查询数据
    getList: function getList() {
      var _this = this;

      this.listQuery.num = this.num;
      (0, _list.getPageList)(this.listQuery).then(function (response) {
        _this.listData = response.data.records;
        _this.total = response.data.total;
      });
    },
    gotoDetail: function gotoDetail(row, event) {
      this.vseVideoCallInfo = row;
      this.detailShow = true;
    },
    dateTimeFormat: function dateTimeFormat(row, column) {
      var date = row[column.property];

      if (date === undefined || date === null || date === '') {
        return '';
      }

      return (0, _utils.parseTime)(new Date(date), '{y}-{m}-{d} {h}:{i}:{s}');
    }
  }
};
exports.default = _default;