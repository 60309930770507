"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _order = require("@/api/order/order");

var _filterUtils = require("@/api/order/filterUtils");

var _quotaServe = require("@/api/reduction/serve/quotaServe");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  filters: {
    feeTypeFilter: _filterUtils.feeTypeFilter,
    feeOptionFilter: _filterUtils.feeOptionFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter,
    quotaFilter: _filterUtils.quotaFilter,
    priceFilter: function priceFilter(row) {
      if (row.feeType === '01') {
        return Math.floor(row.priceIncludeTaxYuan * row.quantity * row.discount) / 100;
      } else {
        return '0';
      }
    },
    discountFilter: function discountFilter(row) {
      if (row.discount === '100') {
        return '';
      } else {
        return row.discount + '%';
      }
    },
    retailFilter: function retailFilter(row) {
      if (row.isRetail === '1') {
        return '可销售';
      } else if (row.isRetail === '0') {
        return '不可销售';
      } else {
        return '';
      }
    },
    stateFilter: function stateFilter(row) {
      if (row.state === '0') {
        return '删除';
      } else {
        return '可用';
      }
    }
  },
  props: {
    orderInfo: {
      type: Object,
      default: null
    },
    priceState: {
      type: Boolean,
      default: false
    },
    auditFlag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      skuListLoading: false,
      schemeListLoading: false,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      checkResultOptions: _constant.default.checkResultOptions4all,
      SkuStateOptions: _constant.default.SkuStateOptions,
      feeTypeOption2: _constant.default.feeTypeOption2,
      skuCheckList: [],
      orderPrice: {},
      hisShow: true,
      priceFlag: true,
      logisticsCost: 310,
      logistics: 260,
      cost: 50,
      isClick: false,
      orderId: '',
      quotaList: [],
      quotaList1: [],
      approval: _constant.default.quotaStates3,
      approval2: _constant.default.quotaStates2,
      amountPrice: 0,
      partBreaks: true,
      totalCredits: true
    };
  },
  computed: {
    confirmBtnStatus: function confirmBtnStatus() {
      return ['S203', 'S300', 'S301', 'S302'].includes(this.orderInfo.subState);
    },
    confirmSubType: function confirmSubType() {
      return ['0102'].includes(this.orderInfo.subType);
    },
    confirmSubTypes: function confirmSubTypes() {
      return ['0102', '0203'].includes(this.orderInfo.subType);
    }
  },
  created: function created() {
    this.initSelect();
  },
  mounted: function mounted() {
    var _this = this;

    this.$on('loadCheckInfo', function (orderId) {
      _this.getQuota(); // const param = { isCheckHis: true, id: this.orderInfo.id }


      if (orderId) {
        _this.orderId = orderId;
        setTimeout(function () {
          _this.loadOrderSkuCheckList(orderId);
        }, 1500);
      }
    });
  },
  methods: {
    initSelect: function initSelect() {},
    loadOrderSkuCheckList: function loadOrderSkuCheckList(orderId) {
      var _this2 = this;

      var params = {
        isCheckHis: false,
        orderId: orderId,
        step: '3'
      };
      (0, _order.getOrderPriceList)(params).then(function (response) {
        if (response.data) {
          _this2.skuCheckList = response.data;
          _this2.orderInfo.orderSkuList = _this2.skuCheckList;
          var indexNum = 0;
          _this2.skuCheckList = response.data.map(function (v) {
            _this2.$set(v, 'indexNum', ++indexNum); // 动态加字段


            return v;
          });

          for (var i = 0; i < _this2.skuCheckList.length; i++) {
            if (_this2.skuCheckList[i].finalFeeType === '01' && _this2.skuCheckList[i].serviceType === '部件' && _this2.skuCheckList[i].state === '1') {
              _this2.logisticsCost = 310;
              _this2.logistics = 260;
              _this2.cost = 50;
              break;
            } else {
              _this2.logisticsCost = 0;
              _this2.logistics = 0;
              _this2.cost = 0;
            }
          }

          if (_this2.orderInfo.cleanType === '0') {
            _this2.cost = 0;
          }
        }

        _this2.getQuotaAmount();

        _this2.getPartBreaksPrice();
      });
      (0, _order.getOrderPriceById)(params).then(function (response) {
        if (response.data) {
          console.log(JSON.stringify(response));
          _this2.orderPrice = response.data;
        }
      });
    },
    editSkuPrice: function editSkuPrice(priceFlag) {
      var _this3 = this;

      this.priceFlag = priceFlag;

      if (this.priceState && this.orderInfo.callSign === '2') {
        if (priceFlag) {
          if ((!this.totalCredits || !this.partBreaks) && this.orderInfo.calloutResult === '4' || this.orderInfo.calloutResult !== '4') {
            if (this.orderInfo.quota !== null && this.orderInfo.quota !== '' && this.orderInfo.calloutResult === '4') {
              this.orderInfo.reduceAmount = this.amountPrice * 100;
            } else {
              this.orderInfo.reduceAmount = 0;
            }

            if (this.orderInfo.calloutResult !== '4' && (!this.totalCredits || !this.partBreaks)) {
              this.orderInfo.quotaState = '';
              this.orderInfo.quota = null;

              for (var i = 0; i < this.skuCheckList.length; i++) {
                if (this.skuCheckList[i].applyFeeType === '06') {
                  this.skuCheckList[i].applyFeeType = '';
                }
              }
            }

            for (var _i = 0; _i < this.skuCheckList.length; _i++) {
              if (this.skuCheckList[_i].state === '0') {
                this.skuCheckList[_i].applyFeeType = '';
              }
            }

            this.orderInfo.orderSkuList = this.skuCheckList;
            (0, _order.savePrice)(this.orderInfo).then(function (response) {
              if (response.data) {
                _this3.loadOrderSkuCheckList(_this3.orderInfo.id);
              }
            });
          } else {
            this.priceFlag = false;
            this.$message({
              showClose: true,
              message: '申请减免必须选择减免',
              type: 'warning'
            });
          }
        } else {
          this.orderPrice = {};
          this.logistics = '';
          this.cost = '';
          this.isClick = true;
          setTimeout(function () {
            _this3.isClick = false;
            console.log(_this3.isClick);
          }, 1500);
        }
      }
    },
    tableRowClassName: function tableRowClassName(row) {
      if (row.row.state === '0') {
        return 'rowStyle';
      } else {
        return 'rowStyle2';
      }
    },
    // 获取全部减免信息
    getQuota: function getQuota() {
      var _this4 = this;

      (0, _quotaServe.listAll)().then(function (response) {
        _this4.quotaList = response.data;
        _this4.quotaList1 = [];

        for (var i = 0; i < _this4.quotaList.length; i++) {
          if (_this4.quotaList[i].isEnable === '1') {
            _this4.quotaList1.push(_this4.quotaList[i]);
          }
        }
      });
    },
    getQuotaAmount: function getQuotaAmount() {
      if (this.partBreaks) {
        if (this.orderInfo.quota !== '' && this.orderInfo.quota !== null) {
          this.totalCredits = false;

          if (this.orderInfo.quotaState !== '0' && this.orderInfo.quotaState !== '1' && this.orderInfo.quotaState !== '2' && this.orderInfo.quotaState !== '3') {
            this.orderInfo.quotaState = '待提交';
          }

          for (var i = 0; i < this.quotaList.length; i++) {
            this.amountPrice = 0;

            if (this.quotaList[i].id === this.orderInfo.quota) {
              this.amountPrice = this.quotaList[i].amount / 100;
              break;
            }
          }
        } else {
          this.amountPrice = '';
          this.orderInfo.quota = null;
          this.totalCredits = true;

          if (this.orderInfo.quotaState === '待提交') {
            this.orderInfo.quotaState = '';
          }
        }
      }
    },
    getPartBreaksPrice: function getPartBreaksPrice() {
      if (this.totalCredits) {
        for (var i = 0; i < this.skuCheckList.length; i++) {
          if (this.skuCheckList[i].applyFeeType === '06') {
            this.partBreaks = false;

            if (this.orderInfo.quotaState !== '0' && this.orderInfo.quotaState !== '1' && this.orderInfo.quotaState !== '2' && this.orderInfo.quotaState !== '3') {
              this.orderInfo.quotaState = '待提交';
            }

            break;
          } else {
            this.partBreaks = true;

            if (this.orderInfo.quotaState === '待提交') {
              this.orderInfo.quotaState = '';
            }
          }
        }
      }
    }
  }
};
exports.default = _default;