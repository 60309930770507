var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  !_vm.dis
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.updateBill },
                        },
                        [_vm._v("确认")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.billInvoiceInfo,
                      rules: _vm.rules,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("基本信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "padding-top": "20px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("div", {
                                staticClass: "grid-content bg-purple",
                                staticStyle: { float: "right" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "1px" } },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "30px" } },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "业务类型",
                                              prop: "busType",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "业务类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .busTypeName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "busTypeName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.busTypeName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "业务单号",
                                              prop: "orderNum",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "业务单号",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo.orderNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "orderNum",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.orderNum",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content bg-purple" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "开票状态",
                                              prop: "billingStatus",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "filter-item",
                                              attrs: {
                                                disabled: true,
                                                placeholder: "开票状态",
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .billingStatusName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "billingStatusName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.billingStatusName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("发票信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票类型",
                                            prop: "invoiceType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.dis },
                                              on: {
                                                change: _vm.invoiceTypeClick,
                                              },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo
                                                    .invoiceType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "invoiceType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.invoiceType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "10" } },
                                                [_vm._v("纸质发票")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "20" } },
                                                [_vm._v("电子发票")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开票对象类型",
                                            prop: "custType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.dis },
                                              on: { change: _vm.custTypeClick },
                                              model: {
                                                value:
                                                  _vm.billInvoiceInfo.custType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billInvoiceInfo,
                                                    "custType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "billInvoiceInfo.custType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    disabled: _vm.flag,
                                                    label: "10",
                                                  },
                                                },
                                                [_vm._v("个人")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "20" } },
                                                [_vm._v("公司")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发票抬头",
                                            prop: "custName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: _vm.dis,
                                              placeholder: "发票抬头",
                                            },
                                            model: {
                                              value:
                                                _vm.billInvoiceInfo.custName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.billInvoiceInfo,
                                                  "custName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "billInvoiceInfo.custName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { hidden: _vm.part1 } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "纳税人识别号",
                                                prop: "custTax",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "纳税人识别号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo.custTax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custTax",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custTax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册地址",
                                                prop: "custAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "公司注册地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "公司注册电话",
                                                prop: "custPhone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "公司注册电话",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "开户行信息",
                                                prop: "custBank",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "开户行信息",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custBank,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custBank",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custBank",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "银行账号",
                                                prop: "custBankAcc",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "银行账号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custBankAcc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custBankAcc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custBankAcc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票人",
                                                prop: "takeUser",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "收票人",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票手机号",
                                                prop: "takeMobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "收票手机号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "省",
                                              prop: "takeProvinceId",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择省",
                                                  filterable: "",
                                                  disabled: _vm.dis,
                                                  clearable: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.proviceChange(
                                                      _vm.billInvoiceInfo
                                                        .takeProvinceId
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeProvinceId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeProvinceId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeProvinceId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.provinceOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "市",
                                              prop: "takeCityId",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择市",
                                                  filterable: "",
                                                  clearable: "",
                                                  disabled: _vm.dis,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.cityChange(
                                                      _vm.billInvoiceInfo
                                                        .takeCityId
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeCityId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeCityId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeCityId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.cityOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "区/县",
                                              prop: "takeDistrictId",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择区",
                                                  filterable: "",
                                                  clearable: "",
                                                  disabled: _vm.dis,
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeDistrictId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeDistrictId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeDistrictId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.countryOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "收票地址",
                                                prop: "takeAddress",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "收票地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .takeAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "takeAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.takeAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: { label: "营业执照" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c("upload-imgs", {
                                                    staticClass: "filter-item",
                                                    attrs: {
                                                      disabled: _vm.isReadOnly,
                                                      limit: 1,
                                                      attachment:
                                                        _vm.billInvoiceInfo
                                                          .charterPicture,
                                                    },
                                                    on: {
                                                      setFilePaths:
                                                        _vm.setCharterPicturePath,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                label: "一般纳税人资格证明",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c("upload-imgs", {
                                                    staticClass: "filter-item",
                                                    attrs: {
                                                      disabled: _vm.isReadOnly,
                                                      limit: 1,
                                                      attachment:
                                                        _vm.billInvoiceInfo
                                                          .custQualificationPicture,
                                                    },
                                                    on: {
                                                      setFilePaths:
                                                        _vm.setCustQualificationPicturePath,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { hidden: _vm.part2 } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮箱地址",
                                                prop: "custEmail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "邮箱地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { hidden: _vm.part3 } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "纳税人识别号",
                                                prop: "custTax",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "纳税人识别号",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo.custTax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custTax",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custTax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "邮箱地址",
                                                prop: "custEmail",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  disabled: _vm.dis,
                                                  placeholder: "邮箱地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.billInvoiceInfo
                                                      .custEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.billInvoiceInfo,
                                                      "custEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "billInvoiceInfo.custEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.checkShow
                              ? _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "grid-content bg-purple",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c("el-checkbox", {
                                                attrs: { disabled: false },
                                                model: {
                                                  value: _vm.checkFlag,
                                                  callback: function ($$v) {
                                                    _vm.checkFlag = $$v
                                                  },
                                                  expression: "checkFlag",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    "text-decoration":
                                                      "underline",
                                                  },
                                                  on: {
                                                    click: _vm.showConfirmation,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "我已经阅读并同意《增值税专用发票抬头确认书》"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "是否保存",
                          visible: _vm.dialogVisible,
                          width: "25%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { color: "red" } },
                          [_c("center", [_vm._v("保存后，将覆盖原信息")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.completeBtn,
                                },
                                on: { click: _vm.comfirm },
                              },
                              [_vm._v("确 定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisible = false
                                  },
                                },
                              },
                              [_vm._v("取 消")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "增值税专用发票抬头确认书",
                          visible: _vm.dialogConfirmation,
                          width: "50%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogConfirmation = $event
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c("center", [
                              _vm._v(
                                "根据国家税法及发票管理相关规定，任何单位和个人不得有虚开增值税专用发票行为【包括并不限于(1) 在没有货物采购或者没有接受应税劳务的情况下要求他人为自己开具增值税专用发票;(2) 虽有货物采购或者接受应税劳务但要求他人为自己开具数量或金额与实际情况不符的增值税专用发票】， 否则属于“虚开增值税专用发票”。"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p"),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c("center", [
                              _vm._v(
                                "我已充分了解上述各项相关国家税法和发票管理规定，并确认仅就我司实际购买商品或服务索取发票。如我司未按国家相关规定申请开具或使用增值税专用发票，由我司自行承担相应法律后果。"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }