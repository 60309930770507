"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRiskById = getRiskById;
exports.getRiskByRiskAndType = getRiskByRiskAndType;
exports.getRiskHisList = getRiskHisList;
exports.getRiskList = getRiskList;
exports.updateRisk = updateRisk;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRiskList(data) {
  return (0, _request.default)({
    url: '/bus_app/baseRisk/getRiskList',
    method: 'post',
    data: data
  });
}

function getRiskById(id) {
  return (0, _request.default)({
    url: '/bus_app/baseRisk/getRiskById',
    method: 'post',
    params: {
      id: id
    }
  });
}

function updateRisk(data) {
  return (0, _request.default)({
    url: '/bus_app/baseRisk/updateRisk',
    method: 'post',
    data: data
  });
}

function getRiskHisList(data) {
  return (0, _request.default)({
    url: '/bus_app/baseRisk/getRiskHisList',
    method: 'post',
    data: data
  });
}

function getRiskByRiskAndType(params) {
  return (0, _request.default)({
    url: '/bus_app/baseRisk/getRiskByRiskAndType',
    method: 'post',
    params: params
  });
}