var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            disabled: _vm.imgDisabled,
            action: _vm.uploadAction,
            "show-file-list": true,
            "list-type": "text",
            accept: ".msg",
            limit: _vm.limit,
            "file-list": _vm.imgList,
            multiple: _vm.isMultiple,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleAvatarSuccess,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.beforeAvatarUpload,
            "on-error": _vm.imgUploadError,
          },
        },
        [
          !_vm.imgDisabled
            ? _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.imgDisabled
        ? _c("el-button", { on: { click: _vm.downloads } }, [
            _vm._v("点击下载"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("span", { domProps: { textContent: _vm._s(_vm.emailName) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }