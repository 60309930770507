"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDisableStocks = batchDisableStocks;
exports.getList = getList;
exports.getVSEStockNotice = getVSEStockNotice;
exports.submitStocks = submitStocks;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询当天配置
 * @param query
 */
function getList(query) {
  return (0, _request.default)({
    url: '/bus_app/vse_stock_config/list',
    method: 'get',
    params: query
  });
}
/**
 * 查询当天配置
 * @param query
 */


function update(data) {
  return (0, _request.default)({
    url: '/bus_app/vse_stock_config/update',
    method: 'post',
    data: data
  });
}
/**
 * 设置默认日期的库存
 * @param startDate，endDate，group，stocks
 */


function submitStocks(startDate, endDate, stocks, startTime, endTime, withoutSat, withoutSun) {
  return (0, _request.default)({
    url: '/bus_app/vse_stock_config/submitStocks',
    method: 'post',
    params: {
      startDate: startDate,
      endDate: endDate,
      stocks: stocks,
      startTime: startTime,
      endTime: endTime,
      withoutSat: withoutSat,
      withoutSun: withoutSun
    }
  });
}
/**
 * 批量禁用库存
 * ids
 */


function batchDisableStocks(ids) {
  return (0, _request.default)({
    url: '/bus_app/vse_stock_config/batchDisableStocks',
    method: 'post',
    params: {
      ids: ids
    }
  });
}

function getVSEStockNotice() {
  return (0, _request.default)({
    url: '/bus_app/vse_stock_config/getVSEStockNotice',
    method: 'get'
  });
}