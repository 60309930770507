"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _order = require("@/api/order/order");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadFile = _interopRequireDefault(require("@/components/uploadFile2"));

var _consumer = _interopRequireDefault(require("@/components/order/consumer"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _calloutList = _interopRequireDefault(require("@/components/order/calloutList"));

var _NewTask = _interopRequireDefault(require("@/components/OrderTask/NewTask"));

var _utils = require("@/utils");

var _product2 = require("@/api/register/product");

var _risk = require("@/api/basics/risk/risk");

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect2/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件上传
// 客户信息
// 产品注册信息
// 外呼信息
// 新建任务工单
var _default = {
  name: 'orderReceiptDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadFile: _uploadFile.default,
    orderConsumer: _consumer.default,
    orderProduct: _product.default,
    orderCallout: _calloutList.default,
    NewTask: _NewTask.default,
    treeSelect: _treeSelect.default
  },
  filters: {
    stateFilter: _filterUtils.stateFilter,
    subStateFilter: _filterUtils.subStateFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter,
    cleanTypeFilter: function cleanTypeFilter(cleanType) {
      if (cleanType === '1') {
        return '清洁服务';
      } else if (cleanType === '0') {
        return '清洁维修';
      } else {
        return '';
      }
    }
  },
  data: function data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      step: '1',
      consumerShow: '1',
      productShow: '1',
      calloutShow: '0',
      isClickQuery: '0',
      isReadOnly: false,
      productDisabled: true,
      cleanTypeShow: true,
      postTypeOptions: _constant.default.postTypeOptions,
      // 邮寄方式
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {
        picturePath: ''
      },
      rulesOfsnExist: {
        sn: [{
          required: true,
          message: '产品SN是必填的',
          trigger: 'blur'
        }, {
          pattern: /^[A-Za-z0-9-]{1,40}$/,
          message: '限制英文+数字40位以内'
        }, {
          max: 40,
          message: '最大长度 40 个字符',
          trigger: 'blur'
        }],
        model: [{
          required: true,
          message: '型号是必填的',
          trigger: 'blur'
        }],
        sku: [{
          required: true,
          message: '物料编码是必填的',
          trigger: 'blur'
        }],
        receiptLogisticsCompany: [{
          max: 50,
          message: '最大长度 50 个字符',
          trigger: 'blur'
        }],
        receiptLogisticsNumber: [{
          max: 20,
          message: '最大长度 20 个字符',
          trigger: 'blur'
        }],
        receiptRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      rulesOfsnIsNull: {
        model: [{
          required: true,
          message: '型号是必填的',
          trigger: 'blur'
        }],
        sku: [{
          required: true,
          message: '物料编码是必填的',
          trigger: 'blur'
        }],
        receiptLogisticsCompany: [{
          max: 50,
          message: '最大长度 50 个字符',
          trigger: 'blur'
        }],
        receiptLogisticsNumber: [{
          max: 20,
          message: '最大长度 20 个字符',
          trigger: 'blur'
        }],
        receiptRemark: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      modelSelect: [],
      skuSelect: [],
      // ----------------------------------------------------------------------------------------------------------------------------------
      goodsList4show: [],
      jdSalesOrderNo: '',
      jdSupplyInfo: '',
      refDateBefore: '',
      // 品类树状数据
      tableData1: [],
      accordion: true,
      categorySelect: []
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    },
    rules: function rules() {
      return this.orderInfo.isSnNull ? this.rulesOfsnIsNull : this.rulesOfsnExist;
    },
    isSnNull: function isSnNull() {
      return this.orderInfo.isSnNull;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    },
    isSnNull: function isSnNull(val, oldval) {
      if (val) {
        this.orderInfo.sn = '系统自定义SN';
      } else if (this.orderInfo.sn === '系统自定义SN') {
        this.orderInfo.sn = '';
      }
    }
  },
  created: function created() {
    var _this = this;

    if (this.id) {
      this.getOrderInfo();
      this.initShowgoodsList4show();
      this.$nextTick(function () {
        _this.$refs.calloutOrder.$emit('loadcalloutList', _this.id);
      });
      this.loadCategorySelect();
    }
  },
  methods: {
    setUploadFileList: function setUploadFileList(data) {
      this.orderInfo.uploadFiles = data;
    },
    setBtnsState: function setBtnsState(btnState) {
      this.btnLoading = btnState;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    initSelect: function initSelect() {
      this.loadModelSelect();
      this.loadSkuSelect();
      this.loadCategorySelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this2 = this;

      var preSn = this.orderInfo.sn;
      var params = {
        categoryId: this.orderInfo.category
      };

      if (this.isClickQuery === '1') {
        if (preSn === '系统自定义SN') {
          preSn = '';
        }

        params = {
          categoryId: this.orderInfo.category,
          sn: preSn
        };
      }

      (0, _select.getModelSelect)(params).then(function (res) {
        _this2.modelSelect = res.data;
      });
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this3 = this;

      if (!this.orderInfo.sn) {
        return;
      }

      var preSn = this.orderInfo.sn;
      var params = {
        modelId: this.orderInfo.model,
        categoryId: this.orderInfo.category,
        tag: '1'
      };

      if (this.isClickQuery === '1') {
        if (preSn === '系统自定义SN') {
          preSn = '';
          params = {
            modelId: this.orderInfo.model,
            categoryId: this.orderInfo.category,
            sn: preSn,
            tag: '1'
          };
          (0, _select.getSkuSelect)(params).then(function (res) {
            _this3.skuSelect = res.data;
          });
        } else {
          this.skuSelect = [];
          (0, _product2.getSkuBySn)(this.orderInfo.sn, this.orderInfo.model, this.orderInfo.category).then(function (res) {
            _this3.skuSelect = res.data;
          });
        }
      }
    },
    loadCategorySelect: function loadCategorySelect() {
      var _this4 = this;

      // 品类I下拉
      (0, _select.getCategorySelect)().then(function (res) {
        _this4.categorySelect = res.data.records;
      });
    },
    modelSelectChange: function modelSelectChange() {
      this.orderInfo.sku = '';
      this.skuSelect = [];
    },
    skuSelectChange: function skuSelectChange() {
      this.orderInfo.model = '';
      this.modelSelect = [];
    },
    initShowgoodsList4show: function initShowgoodsList4show() {
      var _this5 = this;

      (0, _order.getExpressGoods)({
        orderId: this.id
      }).then(function (response) {
        if (response.data) {
          var goodsArray = response.data;
          var len = goodsArray.length;
          var n = 4; // 每行显示4个

          var lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
          _this5.goodsList4show = [];

          for (var i = 0; i < lineNum; i++) {
            var temp = goodsArray.slice(i * n, i * n + n);

            _this5.goodsList4show.push(temp);
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this6 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this6.orderInfo = response.data;
          _this6.refDateBefore = _this6.orderInfo.refDate;
          _this6.orderInfo.refDateBefore = _this6.refDateBefore;
          _this6.productDisabled = !!(_this6.orderInfo.sn && _this6.orderInfo.sn !== '');

          _this6.$refs.orderProduct.$emit('loadFreeSn', _this6.orderInfo.sn);

          if (!_this6.productDisabled) {
            _this6.initSelect();
          }

          if (_this6.orderInfo.cleanType === undefined || _this6.orderInfo.cleanType === null || _this6.orderInfo.cleanType === '') {
            _this6.cleanTypeShow = false;
          } // 查询京东补充信息


          if (_this6.orderInfo.jdOrderNo !== '' && _this6.orderInfo.jdOrderNo !== null) {
            (0, _order.getJdOrderSupplyInfo)(_this6.orderInfo.jdOrderNo).then(function (info) {
              if (info.data && info.code === 200) {
                _this6.jdSalesOrderNo = info.data.jdSalesOrderNo;
                _this6.jdSupplyInfo = info.data.supplyInfo;
              }
            });
          }
        }
      });
    },
    receiptConfirm: function receiptConfirm() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this7.$confirm('是否确认收货？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确认',
            type: 'warning',
            center: true
          }).then(function () {
            _this7.receiptOrderSumbit();
          }).catch(function () {});
        } else {
          _this7.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');

            if (isError && isError.length > 0) {
              isError[0].querySelector('input, textarea').focus();
            }
          });
        }
      });
    },
    receiptOrderSumbit: function receiptOrderSumbit() {
      var _this8 = this;

      this.btnLoading = true;
      this.orderInfo.goodsList = this.goodsList4show.flat();

      if (this.orderInfo.isSnNull) {
        this.orderInfo.sn = '';
      }

      (0, _order.receiptOrder)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this8.$message({
            showClose: true,
            message: '确认收货！',
            type: 'success'
          });

          _this8.$store.dispatch('tagsView/delView', _this8.$route);

          _this8.$router.push({
            path: '/order/receipt/list'
          });
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    errorConfirm: function errorConfirm() {
      var _this9 = this;

      this.$confirm('是否异常处理？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        type: 'warning',
        center: true
      }).then(function () {
        _this9.receiptErrorSumbit();
      }).catch(function () {});
    },
    receiptErrorSumbit: function receiptErrorSumbit() {
      var _this10 = this;

      this.btnLoading = true;
      this.orderInfo.goodsList = this.goodsList4show.flat();

      if (this.orderInfo.isSnNull) {
        this.orderInfo.sn = '';
      }

      (0, _order.receiptError)(this.orderInfo).then(function (response) {
        if (response.data) {
          _this10.$message({
            showClose: true,
            message: '处理完了！',
            type: 'success'
          });

          _this10.$store.dispatch('tagsView/delView', _this10.$route);

          _this10.$router.push({
            path: '/order/receipt/list'
          });
        } else {
          _this10.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    dataChange: function dataChange(val) {
      this.orderInfo.refDate = (0, _utils.parseTime)(val.refDate, '{y}-{m}-{d}');
      this.orderInfo.refDateBefore = val.refDateBefore;
    },
    getAdditionalDate: function getAdditionalDate() {
      var _this11 = this;

      if (this.orderInfo.sn) {
        // 获取生产日期\投保日期
        (0, _order.getAdditionalDate)({
          sn: this.orderInfo.sn
        }).then(function (response) {
          if (response.data) {
            _this11.$message({
              showClose: true,
              message: '获取生产日期\\投保日期成功！',
              type: 'success'
            });

            _this11.orderInfo.productDate = response.data.productDate;
            _this11.orderInfo.refDate = response.data.refDate;
            _this11.orderInfo.isExtendBenefit = response.data.isExtendBenefit;
            _this11.orderInfo.benefitEndDate = response.data.benefitEndDate;
            _this11.orderInfo.extendBenefitDate = response.data.extendBenefitDate;
            _this11.orderInfo.salesChannel = response.data.salesChannel;
            _this11.refDateBefore = _this11.orderInfo.refDate;
            _this11.orderInfo.refDateBefore = _this11.refDateBefore;
          } else {
            _this11.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
        (0, _risk.getRiskByRiskAndType)({
          type: '30',
          risk: this.orderInfo.sn
        }).then(function (response) {
          if (response.data) {
            console.log(response);
            _this11.orderInfo.snRisk = response.data.id;
            _this11.orderInfo.flagSnRisk = true;
          }
        });
        this.isClickQuery = '1';
        this.skuSelectChange();
        this.modelSelectChange();
        this.loadSkuSelect();
        this.loadModelSelect();
      } else {
        this.$message({
          showClose: true,
          message: '请输入sn',
          type: 'warning'
        });
      }
    },
    // 切换品类
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.orderInfo.category = value;
    }
  }
};
exports.default = _default;