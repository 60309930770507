"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _sale = require("@/api/sale/sale");

var _quotaApprove = require("@/api/reduction/approve/quotaApprove");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      flag: this.$route.params.dialogStatus,
      orderNo: this.$route.params.num,
      completeBtn: false,
      rules: {},
      rules1: {
        customeResult: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      rules2: {
        transactionId: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }]
      },
      customeResults: [{
        val: '1',
        name: '同意支付'
      }, {
        val: '0',
        name: '取消'
      }],
      resultOptions: _constant.default.saleQuotaStates,
      part1: true,
      part2: true,
      part3: true,
      quotaApprove: [],
      loadingList4Model: false,
      saleInfo: {
        amount: 0,
        priceAfter: '0.00',
        priceBefore: '0.00',
        urgent: '1',
        province: '',
        city: '',
        district: '',
        state: '',
        items: [{
          categoryId: '',
          modelId: '',
          skuCode: '',
          brandId: '',
          skuName: '',
          skuId: '',
          num: 0,
          payType: '',
          describes: '',
          memo: '',
          priceBeforeY: '',
          priceBeforeYY: '',
          priceAfterY: '',
          priceAfterYY: '',
          stock: ''
        }]
      }
    };
  },
  created: function created() {
    if (this.id) {
      this.getSaleInfo();
      this.getApproveByOrderNo();
    }
  },
  methods: {
    // 获取维修代码详细
    getSaleInfo: function getSaleInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _sale.queryById)(param).then(function (response) {
        if (response.data) {
          _this.saleInfo = response.data;
          _this.saleInfo.priceAfter = _this.saleInfo.priceAfterY;
          _this.saleInfo.priceBefore = _this.saleInfo.priceBeforeY;

          if (_this.saleInfo.isOnline === '20') {
            _this.saleInfo.callType = '支付流水确认';
            _this.part1 = true;
            _this.part2 = false;
            _this.part3 = true;
            _this.rules = _this.rules2;
          } else {
            if (_this.saleInfo.stateChild === '11') {
              _this.saleInfo.callType = '销售减免结果';
              _this.part1 = false;
              _this.part2 = true;
              _this.part3 = false;
              _this.rules = _this.rules1;
            } else {
              _this.saleInfo.callType = '销售超时';
              _this.part1 = false;
              _this.part2 = true;
              _this.part3 = true;
              _this.rules = _this.rules1;
            }
          }

          _this.$nextTick(function () {
            _this.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _sale.callout)(_this2.saleInfo).then(function (response) {
            if (response.data) {
              _this2.$message({
                showClose: true,
                message: response.msg,
                type: 'success'
              });

              _this2.$store.dispatch('tagsView/delView', _this2.$route);

              _this2.$router.push({
                path: '/callout/callSale'
              });
            } else {
              _this2.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    getApproveByOrderNo: function getApproveByOrderNo() {
      var _this3 = this;

      (0, _quotaApprove.getApproveByOrderNo)(this.orderNo).then(function (response) {
        if (response.data) {
          _this3.quotaApprove = response.data;
          _this3.quotaApprove.remark = (0, _utils.HTMLDecode)(_this3.quotaApprove.remark);
        } else {
          _this3.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/callout/callSale'
      });
    }
  }
};
exports.default = _default;