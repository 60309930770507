"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkPwd = checkPwd;
exports.createData = createData;
exports.findPageList = findPageList;
exports.findRoles = findRoles;
exports.queryById = queryById;
exports.restPwd = restPwd;
exports.rowStatusEdit = rowStatusEdit;
exports.unlockUser = unlockUser;
exports.updateData = updateData;
exports.updatePwd = updatePwd;
exports.userNameCheck = userNameCheck;

var _request = _interopRequireDefault(require("@/utils/request"));

var _auth = require("@/utils/auth");

function findPageList(query) {
  return (0, _request.default)({
    url: '/acc_app/user/getUserPageList',
    method: 'post',
    params: query
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/acc_app/user/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}

function createData(data) {
  data.password = (0, _auth.Encrypt)(data.password);
  return (0, _request.default)({
    url: '/acc_app/user/createUser',
    method: 'post',
    data: data
  });
}

function updateData(data) {
  return (0, _request.default)({
    url: '/acc_app/user/updateUser',
    method: 'post',
    data: data
  });
}

function findRoles(data) {
  return (0, _request.default)({
    url: '/acc_app/accRoles/findRoles',
    method: 'post'
  });
}

function rowStatusEdit(data) {
  return (0, _request.default)({
    url: '/acc_app/user/rowStatusEdit',
    method: 'post',
    data: data
  });
}

function userNameCheck(username) {
  return (0, _request.default)({
    url: '/acc_app/user/userNameCheck',
    method: 'post',
    async: false,
    params: {
      username: username
    }
  });
}

function restPwd(id) {
  return (0, _request.default)({
    url: '/acc_app/user/restPwd',
    method: 'post',
    params: {
      id: id
    }
  });
}

function checkPwd(username, userPwd) {
  var pwd = (0, _auth.Encrypt)(userPwd);
  return (0, _request.default)({
    url: '/acc_app/user/pwdCheck',
    method: 'post',
    async: false,
    params: {
      username: username,
      pwd: pwd
    }
  });
}

function updatePwd(username, userPwd, htPwd) {
  var pwd = (0, _auth.Encrypt)(userPwd);
  var oldPwd = (0, _auth.Encrypt)(htPwd);
  return (0, _request.default)({
    url: '/acc_app/user/updatePwd',
    method: 'post',
    async: false,
    params: {
      username: username,
      pwd: pwd,
      checkPwd: oldPwd
    }
  });
}

function unlockUser(data) {
  return (0, _request.default)({
    url: '/acc_app/user/editUnlockUser',
    method: 'post',
    data: data
  });
}