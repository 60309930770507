"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _layout = _interopRequireDefault(require("@/layout"));

var _router = require("@/router");

var state = {
  routes: [],
  // 全部路由
  addRoutes: [] // 动态路由

};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  /**
   * 根据用户拥有权限生成动态路由
   * @param {*} context
   * @param {*} accpers 用户拥有权限信息
   */
  genDynaRoutes: function genDynaRoutes(_ref, routeInfos) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = genRoutes4User(routeInfos);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
/**
 * 根据用户拥有权限生成动态路由
 * @param {*} routeInfos 用户拥有权限信息
 */

function genRoutes4User(routeInfos) {
  // 权限组
  var routesTemp = [];

  for (var i = 0; i < routeInfos.length; i++) {
    // 功能
    var route = {
      path: null,
      component: null,
      redirect: 'noRedirect',
      meta: {
        icon: null,
        title: null
      },
      children: [],
      alwaysShow: true
    }; // path 浏览器显示 必须

    route['path'] = '/' + routeInfos[i].url; // 布局 必须

    route['component'] = _layout.default; // name 定义可以 tab ，如果为空不能叠加tab

    route['name'] = routeInfos[i].url.replace(/\//g, '_'); // menu 名称

    route['meta']['title'] = routeInfos[i].authorityName; // menu icon

    route['meta']['icon'] = routeInfos[i].icons; // 子菜单

    var items = routeInfos[i].items;

    var _loop = function _loop(y) {
      var children = {
        path: null,
        name: null,
        component: null,
        meta: {
          title: null,
          icon: null
        }
      }; // 菜单 浏览器显示 必须

      var path = items[y].url; // console.log('path：' + path)

      children['path'] = path;
      children['name'] = path.replace(/\//g, '_'); // menu 名称

      children['meta']['title'] = items[y].authorityName; // menu icon
      // children['meta']['icon'] = items[i].icons
      // 根据菜单URL动态加载vue组件，这里要求vue组件须按照url路径存储
      // 如url="sys/user"，则组件路径应是"@/views/sys/user.vue",否则组件加载不到

      var url = routeInfos[i].url + '/' + items[y].url; // console.log('url：' + url)
      // const url = 'table/index'
      // children['component'] = resolve => require([`@/views/${url}`], resolve)
      // component: () => import('@/views/nested/menu2/index'),

      children['component'] = function (resolve) {
        return require(["@/views/".concat(url)], resolve);
      }; // 添加子菜单


      route['children'].push(children);
    };

    for (var y = 0; y < items.length; y++) {
      _loop(y);
    }

    routesTemp.push(route);
  } // 404 page must be placed at the end !!!


  return routesTemp.concat(_router.asyncRoutes);
}

var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;