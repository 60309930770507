"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItems = addItems;
exports.confirmSave = confirmSave;
exports.createData = createData;
exports.deleteData = deleteData;
exports.deleteItems = deleteItems;
exports.fetchList = fetchList;
exports.getHouseSelect = getHouseSelect;
exports.getItemsList = getItemsList;
exports.queryById = queryById;
exports.saveSn = saveSn;
exports.submitSave = submitSave;
exports.tempSave = tempSave;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询库存调整数据列表
 * @param query
 */
function fetchList(query) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/getPageList',
    method: 'post',
    params: query
  });
}
/**
 * 查询总部仓库
 */


function getHouseSelect() {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/getHouseSelect',
    method: 'post'
  });
}
/**
 * 新增库存调整信息
 */


function createData() {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/create',
    method: 'post'
  });
}
/**
 * 删除库存调整记录
 * @param ids
 */


function deleteData(ids) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/delete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
/**
 * 查询库存调整详情信息
 * @param id
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 查询库存调整明细信息
 * @param parentId
 */


function getItemsList(parentId) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/getItemsList',
    method: 'post',
    params: {
      parentId: parentId
    }
  });
}
/**
 * 库存调整 暂存操作
 * @param data
 */


function tempSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/tempSave',
    method: 'post',
    data: data
  });
}
/**
 * 添加库存盘点明细
 * @param data
 */


function addItems(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/addItems',
    method: 'post',
    data: data
  });
}
/**
 * 删除库存盘点明细
 * @param ids
 */


function deleteItems(parentId, ids) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/deleteItems',
    method: 'post',
    params: {
      parentId: parentId,
      itemIds: ids
    }
  });
}
/**
 * 保持序列号
 * @param data
 */


function saveSn(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/saveSn',
    method: 'post',
    data: data
  });
}
/**
 * 提交库存调整
 * @param data
 */


function submitSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/submitSave',
    method: 'post',
    data: data
  });
}
/**
 * 确认库存调整
 * @param data
 */


function confirmSave(data) {
  return (0, _request.default)({
    url: '/scm_app/stock_trim/confirmSave',
    method: 'post',
    data: data
  });
}