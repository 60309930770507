"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _order = require("@/api/order/order");

var _order_task = require("@/api/order_task");

var _select = require("@/api/common/select");

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

var _utils = require("@/utils");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _check = _interopRequireDefault(require("@/components/order/check"));

var _receipt = _interopRequireDefault(require("@/components/order/receipt"));

var _product = _interopRequireDefault(require("@/components/order/product"));

var _payment = _interopRequireDefault(require("@/components/order/payment"));

var _return = _interopRequireDefault(require("@/components/order/return"));

var _handle = _interopRequireDefault(require("@/components/order/handle"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _SFRouteState = _interopRequireDefault(require("@/components/order/SFRouteState"));

var _serviceConfig = require("@/api/basics/serviceConfig/serviceConfig");

var _quotaApprove = require("@/api/reduction/approve/quotaApprove");

var _approveAdjunct = _interopRequireDefault(require("@/components/reduction/approve/approveAdjunct"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 检测信息
// 收货信息
// 产品注册信息
// 支付信息
// 返机信息
// 处理信息
// 费用明细
// 工单物流信息
var _default = {
  // name: 'basicsSkuDetail', // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    orderCheck: _check.default,
    orderReceipt: _receipt.default,
    orderProduct: _product.default,
    orderPayment: _payment.default,
    orderReturn: _return.default,
    orderHandle: _handle.default,
    sfRouteState: _SFRouteState.default,
    orderPrice: _checkPrice.default,
    approveLists: _approveAdjunct.default
  },
  filters: {
    callSignFilter: _filterUtils.callSignFilter,
    skuTypeFilter: _filterUtils.skuTypeFilter,
    skuTagFilter: _filterUtils.skuTagFilter,
    showOverflowTooltipFilter: _filterUtils.showOverflowTooltipFilter
  },
  data: function data() {
    return {
      btnLoading: false,
      btnShow: false,
      btnDisabled: true,
      listLoading: false,
      listAll: null,
      id: this.$route.params.id,
      taskId: this.$route.params.taskId,
      productShow: '0',
      receiptShow: '0',
      checkShow: '0',
      paymentShow: '0',
      handleShow: '0',
      priceShow: '0',
      verifyShow: '0',
      returnShow: '0',
      sfInfoShow: '0',
      hisShow: '0',
      approvalShow: '0',
      callSignOptions: _constant.default.callSignOptions,
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      subStateOptions: _constant.default.subStateOptions,
      stateOptions: _constant.default.stateOptions,
      orderTypeState: _constant.default.orderTypeState,
      callResultOptions: [],
      callResultOptions2: [],
      cleanIsPay: false,
      label1: '沟通结果',
      hisList: [],
      taskInfo: {
        callResult: null
      },
      quotaApprove: {},
      approval: _constant.default.quotaStates,
      quotaList: [],
      amountPrice: 0,
      exchangeFlag: false,
      // ----------------------------------------------------------------------------------------------------------------------------------
      orderInfo: {},
      orderInfo2: {},
      rules: {
        callResult: [{
          required: false,
          message: '沟通结果是必填的',
          trigger: 'blur'
        }],
        callResult2: [{
          required: false,
          message: '沟通结果是必填的',
          trigger: 'blur'
        }],
        callRemark1: [{
          max: 1000,
          message: '最大长度 1000 个字符',
          trigger: 'blur'
        }]
      },
      // ----------------------------------------------------------------------------------------------------------------------------------
      userfeeSelect: [],
      categorySelectTree: [],
      categorySelect: [],
      modelSelect: [],
      skuAdd: {
        feeType: undefined,
        skuCode: undefined,
        category: undefined,
        model: undefined,
        step: '3'
      },
      skuSelect: [],
      skuListLoading: false,
      skuList: [],
      userId: this.$store.state.user.loginUser.memberId // 当前账号所属机构ID

    };
  },
  computed: {
    cleanStatus: function cleanStatus() {
      return ['0', '1'].includes(this.orderInfo.cleanType);
    },
    skuAddBtnDisabled: function skuAddBtnDisabled() {
      if (this.orderInfo.calloutResult === '5') {
        return this.skuList.length > 0 || this.skuList.length === 0 && (!this.skuAdd.skuCode || this.skuAdd.skuCode === '');
      } else {
        return true;
      }
    },
    // -----------------------------------------------------------------------------
    category: function category() {
      return this.skuAdd.category;
    },
    oTableData: function oTableData() {
      return this.list;
    },
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    } // -----------------------------------------------------------------------------

  },
  watch: {
    // -----------------------------------------------------------------------------
    category: function category(val, oldval) {
      this.categorySelectChange(val, oldval);
    },
    categorySelect: function categorySelect(val) {
      this.categorySelectTree = this.treeData;
    } // -----------------------------------------------------------------------------

  },
  created: function created() {
    this.initSelect();

    if (this.id) {
      this.getOrderInfo();
      this.getApprove();
    }

    if (this.taskId) {
      this.init();
    }
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _order_task.queryById)(this.taskId).then(function (res) {
        _this.taskInfo = res.data;

        _this.initCalloutResult(_this.taskInfo.taskType); // 如果未完结


        if (_this.taskInfo.type === '10' || _this.taskInfo.type === '20') {
          // 跟进人等于登录人
          _this.btnDisabled = false;

          if (_this.taskInfo.taskRmk === _this.userId || _this.taskInfo.taskTempRmk === _this.userId) {
            _this.btnShow = true;
          }
        }
      });
      (0, _order_task.getHistoryStateList)(this.taskId).then(function (response) {
        console.log(response);

        if (response.data) {
          _this.hisList = response.data;
          var indexNum = _this.hisList.length;
          _this.hisList = response.data.map(function (v) {
            _this.$set(v, 'indexNum', indexNum--); // 动态加字段


            return v;
          });
        }
      });
    },
    getTreeSelectNodeKey: function getTreeSelectNodeKey(value) {
      this.skuAdd.category = value;
    },
    initCalloutResult: function initCalloutResult(sign) {
      if (sign === '1') {
        this.callResultOptions = _constant.default.callResultOptions10;
      } else if (sign === '2') {
        this.callResultOptions = _constant.default.callResultOptions20;
      } else if (sign === '3') {
        this.callResultOptions = _constant.default.callResultOptions30;
      } else if (sign === '4') {
        this.callResultOptions = _constant.default.callResultOptions40;
      } else if (sign === '7') {
        this.callResultOptions = _constant.default.callResultOptions50;
      } else if (sign === '8') {
        this.callResultOptions = _constant.default.callResultOptions80;
        this.callResultOptions2 = _constant.default.callResultOptions20;
      } else {
        this.callResultOptions = _constant.default.callResultOptions00;
      }
    },
    initSelect: function initSelect() {
      var _this2 = this;

      (0, _select.getSkuFeeSelect)({
        isEnable: '1'
      }).then(function (res) {
        _this2.userfeeSelect = res.data;
      });
      (0, _select.getCategorySelect)({
        isEnable: '1'
      }).then(function (res) {
        _this2.categorySelect = res.data.records;
      });
      this.loadModelSelect();
      this.loadSkuSelect();
      this.listAllService();
    },
    // 查询所有的客服任务
    listAllService: function listAllService() {
      var _this3 = this;

      (0, _serviceConfig.listAll)().then(function (response) {
        console.log(response.data);
        _this3.listAll = response.data;
      });
    },
    loadModelSelect: function loadModelSelect() {
      var _this4 = this;

      var params = {
        categoryId: this.skuAdd.category,
        isEnable: '1'
      };
      (0, _select.getModelSelect)(params).then(function (res) {
        _this4.modelSelect = res.data;
      });
    },
    loadSkuSelect: function loadSkuSelect() {
      var _this5 = this;

      var params = {
        modelId: undefined,
        categoryId: undefined,
        isEnable: '1'
      };

      if (this.orderInfo.calloutResult === '5') {
        params.categoryId = this.skuAdd.category;
        params.modelId = this.skuAdd.model;
        params.tag = '1';
      }

      (0, _select.getSkuSelect)(params).then(function (res) {
        _this5.skuSelect = res.data;
      });
    },
    categorySelectChange: function categorySelectChange(val, oldval) {
      if (oldval !== undefined && (!val || val !== oldval)) {
        this.skuAdd.model = '';
        this.modelSelect = [];
        this.modelSelectChange();
      }
    },
    modelSelectChange: function modelSelectChange() {
      this.skuAdd.skuCode = '';
      this.skuSelect = [];
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    getOrderInfo: function getOrderInfo() {
      var _this6 = this;

      (0, _order.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this6.orderInfo = response.data;

          if (_this6.orderInfo.subType === '0203' && _this6.orderInfo.orderSkuList !== null && _this6.orderInfo.orderSkuList[0].exchange !== null && _this6.orderInfo.orderSkuList[0].exchange !== '0' && _this6.orderInfo.firstClassCategory !== null && _this6.orderInfo.firstClassCategory.isEnable === '0') {
            _this6.exchangeFlag = true;
          }

          if (_this6.orderInfo.cleanType === '1') {
            _this6.label1 = '清洁服务沟通结果';
          }

          _this6.$refs.orderProduct.$emit('loadFreeSn', _this6.orderInfo.sn);

          _this6.$refs.checkPrice.$emit('loadCheckInfo', _this6.orderInfo.id);

          _this6.$refs.approveOrder.$emit('loadCheckInfo', _this6.orderInfo.orderNo);

          _this6.$refs.receiptOrder.$emit('loadExpressGoods', _this6.orderInfo.id);

          _this6.$refs.checkOrder.$emit('loadcheckInfo', {
            id: _this6.orderInfo.id
          });

          _this6.$refs.handleOrder.$emit('loadhandleInfo', _this6.orderInfo.id);

          _this6.$refs.returnOrder.$emit('loadExpressGoods', _this6.orderInfo.id);

          if (_this6.$refs.sfInfoForm) {
            _this6.$refs.sfInfoForm.$emit('loadSFInfo', _this6.orderInfo.orderNo);
          }

          if (_this6.$refs.sfInfoTo) {
            _this6.$refs.sfInfoTo.$emit('loadSFInfo', _this6.orderInfo.orderNoc);
          } // 跟进人等于登录人


          if ((_this6.orderInfo.taskRmk === _this6.userId || _this6.taskInfo.taskTempRmk === _this6.userId) && _this6.orderInfo.callSign) {
            _this6.btnShow = true;
          } // 是清洁服务单，且产生了清洁维修单。查询清洁维修单详情


          if (_this6.orderInfo.cleanType === '1' && _this6.orderInfo.cleanOrderNo) {
            (0, _order.queryById)(_this6.orderInfo.cleanOrderNo).then(function (response) {
              if (response.data) {
                _this6.orderInfo2 = response.data;
                _this6.orderInfo2.callSign = '2'; // 清洁维修单检测子类型为保外

                if (_this6.orderInfo2.checkSubType === '0102' || _this6.orderInfo2.checkSubType === '0103' || _this6.orderInfo2.checkSubType === '0203') {
                  _this6.$refs.checkPrice.$emit('loadCheckInfo', _this6.orderInfo2.id);

                  _this6.$refs.approveOrder.$emit('loadCheckInfo', _this6.orderInfo2.orderNo);

                  _this6.cleanIsPay = true;
                }
              }
            });
          }
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------
    // 【外呼确认】
    callConfirm: function callConfirm() {
      var _this7 = this;

      if (this.verifyReduction()) {
        this.$refs['dataForm'].validate(function (valid) {
          if (valid) {
            _this7.$confirm('是否确认？', '提示', {
              cancelButtonText: '取消',
              confirmButtonText: '确认',
              type: 'warning',
              center: true
            }).then(function () {
              _this7.callConfirmSumbit();
            }).catch(function () {});
          } else {
            _this7.$nextTick(function () {
              var isError = document.getElementsByClassName('is-error');

              if (isError && isError.length > 0) {
                isError[0].querySelector('input, textarea').focus();
              }
            });
          }
        });
      }
    },
    verifyReduction: function verifyReduction() {
      if (!this.cleanIsPay) {
        if (this.taskInfo.callResult === '4' && (this.orderInfo.quotaState === '' || this.orderInfo.quotaState === null)) {
          this.$message({
            showClose: true,
            message: '申请减免必须选择减免',
            type: 'error'
          });
          return false;
        } else if (!this.$refs.checkPrice.priceFlag) {
          this.$message({
            showClose: true,
            message: '确认外呼必须保存费用明细',
            type: 'warning'
          });
          return false;
        }
      } else if (this.cleanIsPay) {
        if (this.cleanIsPay && this.taskInfo.callResult2 === '4' && (this.orderInfo2.quotaState === '' || this.orderInfo2.quotaState === null)) {
          this.$message({
            showClose: true,
            message: '申请减免必须选择减免',
            type: 'error'
          });
          return false;
        } else if (!this.$refs.checkPrice.priceFlag) {
          this.$message({
            showClose: true,
            message: '确认外呼必须保存费用明细',
            type: 'warning'
          });
          return false;
        }
      }

      return true;
    },
    callConfirmSumbit: function callConfirmSumbit() {
      var _this8 = this;

      this.taskInfo.planStarDate = (0, _utils.parseTime)(this.taskInfo.planStarDate, '{y}-{m}-{d} {h}:{i}:{s}');
      this.btnLoading = true; // this.orderInfo.orderSkuList = this.skuList

      this.taskInfo.quota = this.orderInfo.quota;
      (0, _order.updateOrderTask)(this.taskInfo).then(function (response) {
        if (response.data) {
          _this8.$message({
            showClose: true,
            message: '确认完了',
            type: 'success'
          });

          _this8.$store.dispatch('tagsView/delView', _this8.$route);

          _this8.$router.push({
            name: 'task'
          });
        } else {
          _this8.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    getApprove: function getApprove() {
      var _this9 = this;

      (0, _quotaApprove.getApproveByOrderNo)(this.id).then(function (response) {
        if (response.data) {
          _this9.quotaApprove = response.data;
        }
      });
    },
    // 存储外呼结果到orderInfo
    getQuotaAmount: function getQuotaAmount() {
      this.orderInfo.calloutResult = this.taskInfo.callResult;
    },
    // 存储外呼结果到orderInfo2
    getQuotaAmount2: function getQuotaAmount2() {
      this.orderInfo2.calloutResult = this.taskInfo.callResult2;
    },
    // 工单详情页面
    gotoEditHtml: function gotoEditHtml(orderNo) {
      if (orderNo) {
        if (orderNo.indexOf('W') === 0 || orderNo.indexOf('E') === 0 || orderNo.indexOf('C') === 0) {
          this.$router.push({
            name: 'queryOrderDetail',
            params: {
              id: orderNo
            }
          });
        }
      }
    }
  }
};
exports.default = _default;