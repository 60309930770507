var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "div2", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                { staticClass: "block", staticStyle: { height: "380px" } },
                [
                  _c(
                    "el-divider",
                    {
                      staticClass: "myStyle",
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("任务描述")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "border-top": "none" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "block",
                                  staticStyle: {
                                    height: "300px",
                                    overflow: "auto",
                                  },
                                },
                                [
                                  _c("pre", { staticClass: "content" }, [
                                    _vm._v(_vm._s(_vm.OrderTasks.describe1)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.OrderTasks.state === "10"
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["ROLE_WORK_TASK_UPDATE_20"],
                                          expression:
                                            "['ROLE_WORK_TASK_UPDATE_20']",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-left": "45%",
                                        "margin-top": "10px",
                                      },
                                      attrs: { type: "primary" },
                                      on: { click: _vm.getTask },
                                    },
                                    [_vm._v("领取\n                ")]
                                  )
                                : _c("el-button", {
                                    staticStyle: {
                                      "margin-left": "45%",
                                      "margin-top": "24px",
                                      border: "none",
                                    },
                                    attrs: { plain: "" },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "el-divider",
                  {
                    staticClass: "myStyle",
                    attrs: { "content-position": "left" },
                  },
                  [_vm._v("任务信息")]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "border-top": "none" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c("div", { staticClass: "createPost-container" }, [
                      _c(
                        "div",
                        {
                          staticClass: "block",
                          staticStyle: { height: "320px" },
                        },
                        [
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "创建人：" + _vm._s(_vm.OrderTasks.taskInRmkName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "div1" },
                            [
                              _vm._v("业务单号：\n                "),
                              _c(
                                "span",
                                {
                                  staticClass: "link-type",
                                  attrs: { id: "orderNo" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoEditHtml(
                                        _vm.OrderTasks.workNo
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.OrderTasks.workNo) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  icon: "el-icon-copy-document",
                                  circle: "",
                                },
                                on: { click: _vm.copyNo },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              staticClass: "div1",
                            },
                            [_vm._v(_vm._s(_vm.OrderTasks.workId))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "任务单号：" + _vm._s(_vm.OrderTasks.taskNo)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "任务类型：" + _vm._s(_vm.OrderTasks.taskTypeName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "期望完成时间：" +
                                _vm._s(_vm.OrderTasks.completeDate)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "当前受理组：" +
                                _vm._s(_vm.OrderTasks.taskHandleGroupName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "当前指派人：" +
                                _vm._s(_vm.OrderTasks.taskHandleRmkName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div1" }, [
                            _vm._v(
                              "状态：" +
                                _vm._s(
                                  _vm._f("TaskStateFilter")(
                                    _vm.OrderTasks.state
                                  )
                                )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }