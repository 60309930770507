"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeById = changeById;
exports.findPageList = findPageList;
exports.getCateListByParentId = getCateListByParentId;
exports.getConsultTypeByParentId = getConsultTypeByParentId;
exports.queryById = queryById;
exports.saveInfo = saveInfo;
exports.validateCode = validateCode;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询
 * @param params
 */
function findPageList(params) {
  return (0, _request.default)({
    url: '/bus_app/consult_type/list',
    method: 'get',
    params: params
  });
}
/**
 * 保存
 * @param params
 */


function saveInfo(params) {
  console.log(params);
  return (0, _request.default)({
    url: '/bus_app/consult_type/saveInfo',
    method: 'post',
    params: params
  });
}
/**
 * 状态修改
 * @param params
 */


function changeById(params) {
  console.log(params);
  return (0, _request.default)({
    url: '/bus_app/consult_type/changeById',
    method: 'post',
    params: params
  });
}
/**
 * 代码唯一性校验
 * @param params
 */


function validateCode(params) {
  return (0, _request.default)({
    url: '/bus_app/consult_type/ckeckCode',
    method: 'post',
    params: {
      code: params
    }
  });
}
/**
 * getCateListByParentId
 * @param params
 */


function getCateListByParentId(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_type/getCateListByParentId',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * queryById
 * @param params
 */


function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_type/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
 * 已知上级地分类ID获取直属下级分类
 * @param parentId {0:获取咨询工单分类，上级分类ID} 必须
 */


function getConsultTypeByParentId(parentId) {
  return (0, _request.default)({
    url: '/bus_app/consult_type/getConsultTypeByParentId',
    method: 'post',
    params: {
      parentId: parentId
    }
  });
}