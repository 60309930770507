"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _fault = require("@/api/basics/fault/fault");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _treeSelect = _interopRequireDefault(require("@/components/treeSelect/treeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// 下拉框 树形结构 可检索组件
var _default = {
  name: 'basicsFaultDetail',
  //eslint-disable-line
  directives: {
    waves: _waves.default
  },
  components: {
    treeSelect: _treeSelect.default,
    Sticky: _Sticky.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'YES',
        0: 'NO'
      };
      return statusMap[status];
    },
    statusNameFilter: function statusNameFilter(status) {
      var statusMap = {
        1: '启用',
        0: '禁用'
      };
      return statusMap[status];
    },
    tagStatusFilter0: function tagStatusFilter0(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    },
    tagStatusFilter: function tagStatusFilter(status) {
      var statusMap = {
        true: 'success',
        false: 'danger'
      };
      return statusMap[status];
    },
    isEnableFilter: function isEnableFilter(status) {
      var statusMap = {
        true: '启用',
        false: '禁用'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    var _this = this;

    var validate = function validate(rule, value, callback) {
      // 后台方法
      if (_this.isCreate) {
        (0, _fault.validateCode)(value).then(function (res) {
          if (res && res.data === true) {
            callback();
          } else if (res && res.data === false) {
            callback('编码已存在');
          }
        });
      } else {
        callback();
      }
    };

    return {
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      btnLoading: false,
      // 故障原因
      repairTypeSelect: [],
      // 删除按钮状态
      removeBtnDisabled: true,
      // 品类下拉
      categorySelect: [],
      categorySelect2: [],
      // 品牌下拉
      brandSelect: [],
      // 维修代码下拉
      modelSelect: [],
      // 一览页面信息
      modelSelConditionValidate: false,
      modelListValidate: false,
      //
      loading: false,
      multipleSelection: [],
      listQuery: {
        page: 1,
        limit: 1000000
      },
      list: [],
      total: 0,
      listLoading: true,
      // >>> 添加编辑FORM项目 >>>
      validateForm: {
        id: null,
        code: '',
        name: '',
        type: '',
        isUsed: '1',
        isEnable: '1',
        memo: '',
        ids: '',
        categoryId: ''
      },
      rules: {
        code: [{
          required: true,
          message: '故障原因编码是必填的',
          trigger: 'blur'
        }, {
          max: 64,
          message: '最大长度 64 个字符',
          trigger: 'blur'
        }, {
          validator: validate,
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '故障原因名称是必填的',
          trigger: 'blur'
        }, {
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '故障分类是必填的',
          trigger: 'change'
        }],
        memo: [{
          max: 255,
          message: '最大长度 255 个字符',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '品类是必选的',
          trigger: 'change'
        }]
      },
      // <<< 添加编辑FORM项目 <<<
      // ------- 弹出框
      // 弹出框列表
      dialogList: null,
      // 弹出框选择
      dialogSelection: [],
      textMap: {
        update: '编辑',
        create: '新建'
      },
      dialogFormVisible: false,
      // 弹出框内容
      modelInfo: {
        categoryId: '',
        isEnable: '1',
        page: 1,
        limit: 1000000,
        code: '',
        name: ''
      },
      setBtnState_disabled: true,
      // 删除按钮：初始化状态
      tableData1: [],
      accordion: false,
      cTree: true,
      pdText: '请选择品类',
      tableData12: [],
      accordion2: false,
      cTree2: true,
      pdText2: '请选择品类',
      isList: false
    };
  },
  computed: {
    treeData: function treeData() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      });
      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });
      return clData;
    },
    treeData2: function treeData2() {
      var cloneData = JSON.parse(JSON.stringify(this.categorySelect2)); // 对源数据深度克隆

      cloneData.filter(function (father) {
        var branchArr = cloneData.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组

        branchArr.length > 0 ? father.children = branchArr : ''; // 如果存在子级，则给父级添加一个children属性，并赋值

        return father.parentId === '0'; // 返回第一层
      }); // console.log(JSON.stringify(cloneData))

      var clData = [];
      cloneData.forEach(function (it) {
        if (it.parentId === '0') {
          clData.push(it);
        }
      });

      if (clData.length === 0) {
        cloneData.forEach(function (it) {
          if (it.level === 2) {
            clData.push(it);
          }
        });
      }

      if (clData.length === 0) {
        cloneData.forEach(function (it) {
          if (it.level === 3) {
            clData.push(it);
          }
        });
      }

      if (clData.length === 0) {
        cloneData.forEach(function (it) {
          if (it.level === 4) {
            clData.push(it);
          }
        });
      }

      return clData;
    }
  },
  watch: {
    categorySelect: function categorySelect(val) {
      this.tableData1 = this.treeData;
    },
    categorySelect2: function categorySelect2(val) {
      this.tableData12 = this.treeData2;
    }
  },
  created: function created() {
    var _this2 = this;

    this.getSelect();

    if (!this.isCreate) {
      this.getFaultInfo();
    } else {
      (0, _select.getCategorySelect)().then(function (res) {
        _this2.categorySelect = res.data.records;
      });
    }
  },
  methods: {
    queryTreeSelectNodeKey: function queryTreeSelectNodeKey(value) {
      this.validateForm.categoryId = value;
    },
    queryTreeSelectNodeKey2: function queryTreeSelectNodeKey2(value) {
      this.modelInfo.categoryId = value;
    },
    // 获取维修代码详细
    getFaultInfo: function getFaultInfo() {
      var _this3 = this;

      (0, _select.getCategorySelect)().then(function (res) {
        _this3.categorySelect = res.data.records;
        (0, _fault.queryById)(_this3.id).then(function (response) {
          if (response.data) {
            _this3.validateForm = response.data;
            _this3.list = response.data.modelList;

            if (!_this3.list || _this3.list.length === 0) {
              _this3.isList = false;
            } else {
              _this3.isList = true;
            }
          }
        });
      });
    },
    // 创建 页面数据初始化
    resetFrom: function resetFrom() {
      this.validateForm = {
        id: null,
        code: null,
        name: null,
        isEnable: '1',
        memo: null
      };
    },
    // 【已关联维修代码列表】验证方法
    validateModelList: function validateModelList() {
      if (!this.list || this.list.length === 0) {
        this.modelListValidate = true;
        return false;
      }

      return true;
    },
    // 新增确认
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this4.validateModelList()) {
          _this4.validateForm.ids = '';
          var ids = [];

          _this4.list.forEach(function (item, index) {
            ids.push(item.id);
          });

          _this4.validateForm.ids = ids.join(',');
          (0, _fault.createData)(_this4.validateForm).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '新增成功！',
                type: 'success'
              });

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/basics/fault/list'
              });
            } else {
              _this4.$message({
                showClose: true,
                message: '新增失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 编辑确认
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid && _this5.validateModelList()) {
          _this5.validateForm.modelList = undefined;
          _this5.validateForm.id = _this5.id;
          _this5.validateForm.ids = '';
          var ids = [];

          _this5.list.forEach(function (item, index) {
            ids.push(item.id);
          });

          _this5.validateForm.ids = ids.join(',');
          (0, _fault.updateData)(_this5.validateForm).then(function (response) {
            if (response.data) {
              _this5.$message({
                showClose: true,
                message: '修改成功！',
                type: 'success'
              });

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/basics/fault/list'
              });
            } else {
              _this5.$message({
                showClose: true,
                message: '修改失败！',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    categorySelectChange: function categorySelectChange() {
      this.modelSelect = [];
    },
    brandSelectChange: function brandSelectChange() {
      this.modelSelect = [];
    },
    // 下拉数据初始化
    getSelect: function getSelect() {
      var _this6 = this;

      // 维修分类下拉
      var params = {};

      if (!this.id) {
        params.isEnable = '1';
      }

      (0, _select.getCodeTypeSelect)('fault', params).then(function (res) {
        _this6.repairTypeSelect = res.data.records;
      }); // 品类下拉
      // getCategorySelect(params).then(res => {
      //   this.categorySelect = res.data.records
      // })
      // 品牌下拉

      (0, _select.getBrandSelect)(params).then(function (res) {
        _this6.brandSelect = res.data.records;
      }); // 维修代码下拉

      this.loadModelSelect();
    },
    loadModelSelect: function loadModelSelect() {
      var _this7 = this;

      var params = {
        categoryId: this.listQuery.categoryId,
        brandId: this.listQuery.brandId
      };

      if (!this.id) {
        params.isEnable = '1';
      }

      (0, _select.getModelSelect)(params).then(function (res) {
        _this7.modelSelect = res.data;
      });
    },
    cleanBtnClickDialog: function cleanBtnClickDialog() {
      this.modelInfo = {
        page: 1,
        limit: 1000000,
        isEnable: '1',
        categoryId: '',
        code: '',
        name: ''
      };
    },
    // 查询按钮事件
    searchBtnClickDialog: function searchBtnClickDialog() {
      var _this8 = this;

      // 验证【维修代码选择列表】查询条件
      this.modelSelConditionValidate = false;
      this.modelInfo.page = 1;
      var a = this.validateForm.categoryId;

      if (this.modelInfo.categoryId) {
        a = this.modelInfo.categoryId;
      }

      (0, _select.getRepairSelectByCategory)({
        isEnable: '1',
        categoryId: a,
        code: this.modelInfo.code,
        name: this.modelInfo.name
      }).then(function (response) {
        _this8.dialogList = response.data.records;
      });
    },
    // 单击一行，则切换该行的checkBox选中状态
    rowClickSelection: function rowClickSelection(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 弹出框 单击一行，则切换该行的checkBox选中状态
    dialogRowClickSelection: function dialogRowClickSelection(row, column, event) {
      this.$refs.dialogTable.toggleRowSelection(row);
    },
    // listTable中checkbox选中影响【删除】按钮状态
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;

      if (this.multipleSelection.length >= 1) {
        this.removeBtnDisabled = false;
      } else {
        this.removeBtnDisabled = true;
      }
    },
    dialogSelectionChange: function dialogSelectionChange(val) {
      this.dialoSelection = val;
    },
    // 添加关联维修代码
    addModel: function addModel() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this9.dialogFormVisible = true;
          _this9.modelInfo = {
            page: 1,
            limit: 1000000,
            isEnable: '1'
          };
          _this9.dialogList = [];
          var params = {
            id: _this9.validateForm.categoryId
          };
          (0, _select.getChildsById)(params).then(function (res) {
            _this9.categorySelect2 = res.data;
          });
        }
      });
    },
    saveModelInfo: function saveModelInfo() {
      var _this10 = this;

      this.dataModel = [];

      if (this.dialoSelection && this.dialoSelection.length > 0) {
        this.dialoSelection.forEach(function (item, index) {
          _this10.dataModel = _this10.dataModel.concat(item);
        });

        if (this.list != null && this.list.length > 0) {
          this.list.forEach(function (item, index) {
            var oldId = item.id;

            _this10.dataModel.some(function (item2, i) {
              if (item2.id === oldId) {
                _this10.dataModel.splice(i, 1);
              }
            });
          });
        }

        this.dataModel.forEach(function (item, index) {
          item.repairName = item.name;
          item.repairCode = item.code;

          if (_this10.list === null) {
            _this10.list = [];
          }

          _this10.list = _this10.list.concat(item);
        });
      }

      this.dialogFormVisible = false;

      if (!this.list || this.list.length === 0) {
        this.isList = false;
      } else {
        this.isList = true;
      }
    },
    deleteModel: function deleteModel() {
      var _this11 = this;

      this.dataModel = [];
      this.multipleSelection.forEach(function (item, index) {
        _this11.dataModel = _this11.dataModel.concat(item);
      });

      if (this.dataModel.length > 0) {
        this.dataModel.forEach(function (item, index) {
          var oldId = item.id;

          _this11.list.some(function (item2, i) {
            if (item2.id === oldId) {
              _this11.list.splice(i, 1);
            }
          });
        });
      }

      if (!this.list || this.list.length === 0) {
        this.isList = false;
      } else {
        this.isList = true;
      }
    }
  }
};
exports.default = _default;