"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPageList = findPageList;
exports.queryById = queryById;
exports.saveData = saveData;
exports.updateStatus = updateStatus;

var _request = _interopRequireDefault(require("@/utils/request"));

function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/overseasSku/getPageList',
    method: 'post',
    params: query
  });
}

function saveData(data) {
  return (0, _request.default)({
    url: '/bus_app/overseasSku/save',
    method: 'post',
    data: data
  });
}

function updateStatus(params) {
  return (0, _request.default)({
    url: '/bus_app/overseasSku/updateStatus',
    method: 'post',
    params: params
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/overseasSku/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}