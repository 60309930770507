var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "费用明细" }, [
    _c(
      "div",
      [
        _c(
          "el-divider",
          { staticClass: "myStyle", attrs: { "content-position": "left" } },
          [_vm._v("费用明细")]
        ),
        _vm._v(" "),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "border-top": "none" },
            attrs: { shadow: "never" },
          },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24, pull: 1 } }, [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "160px" } },
                    [
                      _vm.priceState &&
                      _vm.orderInfo.callSign === "2" &&
                      _vm.priceFlag
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: [
                                    "ROLE_ORDER_TASK_TASK_COMMIT",
                                    "ROLE_CALLOUT_SERVICE_COMMIT",
                                  ],
                                  expression:
                                    "['ROLE_ORDER_TASK_TASK_COMMIT','ROLE_CALLOUT_SERVICE_COMMIT']",
                                },
                              ],
                              staticStyle: {
                                float: "right",
                                "margin-bottom": "10px",
                              },
                              attrs: { type: "primary", loading: _vm.isClick },
                              on: {
                                click: function ($event) {
                                  return _vm.editSkuPrice(false)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.priceState &&
                      _vm.orderInfo.callSign === "2" &&
                      !_vm.priceFlag
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: [
                                    "ROLE_ORDER_TASK_TASK_COMMIT",
                                    "ROLE_CALLOUT_SERVICE_COMMIT",
                                  ],
                                  expression:
                                    "['ROLE_ORDER_TASK_TASK_COMMIT','ROLE_CALLOUT_SERVICE_COMMIT']",
                                },
                              ],
                              staticStyle: {
                                float: "right",
                                "margin-bottom": "10px",
                              },
                              attrs: { type: "primary", loading: _vm.isClick },
                              on: {
                                click: function ($event) {
                                  return _vm.editSkuPrice(true)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.skuListLoading,
                              expression: "skuListLoading",
                            },
                          ],
                          attrs: {
                            size: "mini",
                            border: "",
                            stripe: "",
                            "tooltip-effect": "dark",
                            "header-cell-style": {
                              background: "#eef1f6",
                              color: "#606266",
                              height: "40px",
                            },
                            data: _vm.skuCheckList,
                            "row-class-name": _vm.tableRowClassName,
                          },
                        },
                        [
                          _c("el-table-column", {
                            key: "indexNum",
                            attrs: {
                              prop: "indexNum",
                              label: "序号",
                              resizable: "",
                              align: "center",
                              "min-width": "60",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "serviceType",
                            attrs: {
                              prop: "serviceType",
                              label: "类型",
                              resizable: "",
                              align: "center",
                              "min-width": "60",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "skuCode",
                            attrs: {
                              prop: "skuCode",
                              label: "SKU编码",
                              resizable: "",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "skuName",
                            attrs: {
                              prop: "skuName",
                              label: "物料名称",
                              resizable: "",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "feeType",
                            attrs: {
                              prop: "feeType",
                              label: "收费类型",
                              align: "center",
                              "min-width": "70",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("feeTypeFilter")(row.feeType)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "isRetail",
                            attrs: {
                              prop: "isRetail",
                              label: "是否可销售",
                              resizable: "",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(_vm._f("retailFilter")(row))),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _vm.confirmSubType
                            ? _c("el-table-column", {
                                key: "feeOption",
                                attrs: {
                                  prop: "feeOption",
                                  label: "服务选项",
                                  width: "80",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("feeOptionFilter")(
                                                  row.feeOption
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1600567037
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "priceIncludeTaxYuan",
                            attrs: {
                              prop: "priceIncludeTaxYuan",
                              label: "单价",
                              resizable: "",
                              align: "center",
                              "min-width": "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "quantity",
                            attrs: {
                              prop: "quantity",
                              label: "数量",
                              resizable: "",
                              align: "center",
                              "min-width": "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.quantity))]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "isLack",
                            attrs: {
                              prop: "isLack",
                              label: "是否缺料",
                              resizable: "",
                              align: "center",
                              "min-width": "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.serviceType === "部件"
                                      ? [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                row.isLack === "1" ? "是" : "否"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "discount",
                            attrs: {
                              prop: "discount",
                              label: "折扣",
                              resizable: "",
                              align: "center",
                              "min-width": "60",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      _vm._s(_vm._f("discountFilter")(row))
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: "final",
                            attrs: {
                              prop: "priceIncludeTaxYuan",
                              label: "小计",
                              resizable: "",
                              align: "center",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(_vm._f("priceFilter")(row))),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          !_vm.confirmBtnStatus
                            ? _c("el-table-column", {
                                key: "state",
                                attrs: {
                                  prop: "state",
                                  label: "状态",
                                  resizable: "",
                                  align: "center",
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          !_vm.priceFlag &&
                                          row.isCleanConsult !== "1"
                                            ? [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "请选择",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: row.state,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "state",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.state",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.SkuStateOptions,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.val,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("stateFilter")(row)
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2082765910
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.confirmSubType &&
                          _vm.totalCredits &&
                          !_vm.confirmBtnStatus &&
                          (!_vm.partBreaks ||
                            (!_vm.priceFlag &&
                              _vm.orderInfo.calloutResult === "4"))
                            ? [
                                _c("el-table-column", {
                                  key: "applyFeeType",
                                  attrs: {
                                    prop: "applyFeeType",
                                    label: "申请收费类型",
                                    align: "center",
                                    "min-width": "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return row.state === "1" &&
                                            row.feeType === "01"
                                            ? [
                                                !_vm.priceFlag &&
                                                _vm.orderInfo.calloutResult ===
                                                  "4" &&
                                                row.isCleanConsult !== "1"
                                                  ? [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            filterable: "",
                                                            clearable: "",
                                                            placeholder:
                                                              "请选择",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.getPartBreaksPrice,
                                                          },
                                                          model: {
                                                            value:
                                                              row.applyFeeType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "applyFeeType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.applyFeeType",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.feeTypeOption2,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.name,
                                                                  value:
                                                                    item.val,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "feeTypeFilter"
                                                          )(row.applyFeeType)
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _vm.orderInfo.quota === "" ||
                                _vm.orderInfo.quota === null
                                  ? _c("el-table-column", {
                                      key: "approvalStatus",
                                      attrs: {
                                        prop: "approvalStatus",
                                        label: "审批状态",
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                row.applyFeeType === "06" &&
                                                _vm.orderInfo.quotaState ===
                                                  "0" &&
                                                _vm.auditFlag
                                                  ? [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            filterable: "",
                                                            placeholder:
                                                              "未审批",
                                                            size: "small",
                                                          },
                                                          model: {
                                                            value:
                                                              row.approvalStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "approvalStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.approvalStatus",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.approval2,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.name,
                                                                  value:
                                                                    item.val,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("quotaFilter")(
                                                            row.approvalStatus
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4254901461
                                      ),
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  key: "finalFeeType",
                                  attrs: {
                                    prop: "finalFeeType",
                                    label: "最终收费类型",
                                    align: "center",
                                    "min-width": "70",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm.orderInfo.quotaState !== 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("feeTypeFilter")(
                                                        row.finalFeeType
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    305973432
                                  ),
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { span: 5, pull: 1 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "人工费" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.logistics,
                                callback: function ($$v) {
                                  _vm.logistics = $$v
                                },
                                expression: "logistics",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { span: 3, pull: 1 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "-60px" },
                            attrs: { label: "物流费" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.cost,
                                callback: function ($$v) {
                                  _vm.cost = $$v
                                },
                                expression: "cost",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.orderPrice.totalPriceYuan !== "" &&
                _vm.orderPrice.totalPriceYuan !== "0" &&
                _vm.orderPrice.totalPriceYuan !== "0.00"
                  ? _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 4, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "-50px" },
                                attrs: { label: "实修原价" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.orderPrice.totalPriceYuan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderPrice,
                                        "totalPriceYuan",
                                        $$v
                                      )
                                    },
                                    expression: "orderPrice.totalPriceYuan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderPrice.discountPriceYuan !== "" &&
                _vm.orderPrice.discountPriceYuan !== "0" &&
                _vm.orderPrice.discountPriceYuan !== "0.00"
                  ? _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 4, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "-50px" },
                                attrs: { label: "折扣" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.orderPrice.discountPriceYuan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderPrice,
                                        "discountPriceYuan",
                                        $$v
                                      )
                                    },
                                    expression: "orderPrice.discountPriceYuan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderPrice.cappingReductionPriceYuan !== "" &&
                _vm.orderPrice.cappingReductionPriceYuan !== "0" &&
                _vm.orderPrice.cappingReductionPriceYuan !== "0.00"
                  ? _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 4, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "-50px" },
                                attrs: { label: "优惠" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value:
                                      _vm.orderPrice.cappingReductionPriceYuan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderPrice,
                                        "cappingReductionPriceYuan",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "orderPrice.cappingReductionPriceYuan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderPrice.reductionPriceYuan !== undefined &&
                _vm.orderPrice.reductionPriceYuan !== "" &&
                _vm.orderPrice.reductionPriceYuan !== "0" &&
                _vm.orderPrice.reductionPriceYuan !== "0.00"
                  ? _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 4, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "-50px" },
                                attrs: { label: "减免" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.orderPrice.reductionPriceYuan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderPrice,
                                        "reductionPriceYuan",
                                        $$v
                                      )
                                    },
                                    expression: "orderPrice.reductionPriceYuan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { span: 4, pull: 1 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "-50px" },
                            attrs: { label: "优惠券减免" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.orderInfo.couponAmountYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.orderInfo,
                                    "couponAmountYuan",
                                    $$v
                                  )
                                },
                                expression: "orderInfo.couponAmountYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { span: 4, pull: 1 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "-50px" },
                            attrs: { label: "合计" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.orderPrice.finalPriceYuan,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.orderPrice,
                                    "finalPriceYuan",
                                    $$v
                                  )
                                },
                                expression: "orderPrice.finalPriceYuan",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { span: 6, pull: 1 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.orderPrice.voidPrice,
                                expression: "orderPrice.voidPrice",
                              },
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: { label: "作废价格" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "filter-item",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.orderPrice.voidPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orderPrice, "voidPrice", $$v)
                                },
                                expression: "orderPrice.voidPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { label: "部件折后价" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.orderPrice.skuDiscountPriceYuan,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.orderPrice,
                                  "skuDiscountPriceYuan",
                                  $$v
                                )
                              },
                              expression: "orderPrice.skuDiscountPriceYuan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { label: "换货价格" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.orderPrice.exchangePriceYuan,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.orderPrice,
                                  "exchangePriceYuan",
                                  $$v
                                )
                              },
                              expression: "orderPrice.exchangePriceYuan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.orderInfo.cleanTypeOptions !== "1"
              ? _c(
                  "el-row",
                  [
                    _vm.confirmSubTypes &&
                    _vm.partBreaks &&
                    _vm.logisticsCost !== 0 &&
                    _vm.logisticsCost !== "" &&
                    (!_vm.totalCredits ||
                      (!_vm.priceFlag &&
                        _vm.orderInfo.calloutResult === "4")) &&
                    !_vm.confirmBtnStatus
                      ? [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { span: 8, pull: 1 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        label: "减免名称",
                                        prop: "quota",
                                      },
                                    },
                                    [
                                      !_vm.priceFlag
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: _vm.getQuotaAmount,
                                                focus: _vm.getQuota,
                                              },
                                              model: {
                                                value: _vm.orderInfo.quota,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderInfo,
                                                    "quota",
                                                    $$v
                                                  )
                                                },
                                                expression: "orderInfo.quota",
                                              },
                                            },
                                            _vm._l(
                                              _vm.quotaList1,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.quotaName,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: "",
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: _vm.getQuotaAmount,
                                              },
                                              model: {
                                                value: _vm.orderInfo.quota,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderInfo,
                                                    "quota",
                                                    $$v
                                                  )
                                                },
                                                expression: "orderInfo.quota",
                                              },
                                            },
                                            _vm._l(
                                              _vm.quotaList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.quotaName,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { span: 8, pull: 1 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        label: "金额",
                                        prop: "approvalNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.amountPrice,
                                          callback: function ($$v) {
                                            _vm.amountPrice = $$v
                                          },
                                          expression: "amountPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            (!_vm.priceFlag || !_vm.totalCredits || !_vm.partBreaks) &&
            !_vm.confirmBtnStatus &&
            _vm.orderInfo.cleanTypeOptions !== "1"
              ? _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 8, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "申请减免" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.orderPrice.reduceAmountYuan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.orderPrice,
                                        "reduceAmountYuan",
                                        $$v
                                      )
                                    },
                                    expression: "orderPrice.reduceAmountYuan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { span: 8, pull: 1 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: "状态" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.orderInfo.quotaState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderInfo,
                                          "quotaState",
                                          $$v
                                        )
                                      },
                                      expression: "orderInfo.quotaState",
                                    },
                                  },
                                  _vm._l(_vm.approval, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.val,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.orderInfo.quotaState !== "1" &&
                    _vm.orderInfo.quotaState !== "2" &&
                    _vm.orderInfo.quotaState !== "3"
                      ? _c(
                          "el-col",
                          {
                            staticStyle: { "padding-top": "20px" },
                            attrs: { span: 8, pull: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { label: "预计减免后" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.orderPrice.predictDerateYuan,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.orderPrice,
                                            "predictDerateYuan",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "orderPrice.predictDerateYuan",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }