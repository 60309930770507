"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _constant = _interopRequireDefault(require("@/api/register/constant"));

var _productRegister = require("@/api/register/productRegister");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _filterUtils = require("@/api/register/filterUtils");

var _filterUtils2 = require("@/api/order/filterUtils");

var _constant2 = _interopRequireDefault(require("@/api/order/constant"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SnProductRegisterDetail',
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  filters: {
    activeStatusFilter: _filterUtils.activeStatusFilter,
    rightsFilter: _filterUtils.rightsFilter,
    countryFilter: _filterUtils2.countryFilter
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      productRegisterInfo: {},
      btnLoading: false,
      listLoading: false,
      prodcutHistoryList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      countryOptions: _constant2.default.countryOptions,
      registerSourceOptions: _constant.default.registerSourceOptions
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getDetail();
  },
  methods: {
    // 初始查询详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _productRegister.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.productRegisterInfo = response.data;

          if (_this.productRegisterInfo.id) {
            _this.listQuery.parentId = _this.productRegisterInfo.id;

            _this.getHistoryList();
          }
        }
      });
    },
    // 获取检测单明细
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _productRegister.getHistoryList)(this.listQuery).then(function (response) {
        _this2.prodcutHistoryList = response.data.records;

        _this2.prodcutHistoryList.forEach(function (item) {
          item.warrantyNote = (0, _utils.HTMLDecode)(item.warrantyNote);
        });

        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    handleModifyStatus: function handleModifyStatus() {
      var _this3 = this;

      (0, _productRegister.unBundling)(this.id).then(function (response) {
        if (response.data) {
          _this3.getDetail();

          _this3.$message({
            message: '操作成功',
            type: 'success'
          });
        } else {
          _this3.$message({
            message: '操作失败' + response.msg,
            type: 'warning'
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/register/product_register'
      });
    }
  }
};
exports.default = _default;