"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _expressOrg = require("@/api/express/expressOrg");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _select = require("@/api/common/select");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'expressOrgDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default
  },
  directives: {},
  data: function data() {
    return {
      id: this.$route.params.id,
      expressOrg: {},
      orgRules: {
        orgName: [{
          required: true,
          message: '单位名称是必填的',
          trigger: 'blur'
        }],
        receiver: [{
          required: true,
          message: '联系人名称是必填的',
          trigger: 'blur'
        }],
        receiveMobile: [{
          required: true,
          message: '联系人手机是必填的',
          trigger: 'blur'
        }],
        provinceCode: [{
          required: true,
          message: '省份是必填的',
          trigger: 'blur'
        }],
        cityCode: [{
          required: true,
          message: '城市是必填的',
          trigger: 'blur'
        }],
        districtCode: [{
          required: true,
          message: '区域是必填的',
          trigger: 'blur'
        }],
        detailAddress: [{
          required: true,
          message: '详细地址是必填的',
          trigger: 'blur'
        }]
      },
      provinceSelect: [],
      // 省下拉
      citySelect: [],
      regionSelect: [] // 区下拉

    };
  },
  created: function created() {
    this.getProvinceOptions();

    if (this.$route.params.id) {
      this.getDetail();
    }
  },
  activated: function activated() {
    this.getProvinceOptions();

    if (this.$route.params.id) {
      this.getDetail();
    }
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _expressOrg.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.expressOrg = response.data;

          _this.getCityOption();
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.expressOrg = {}; // this.$router.go(-1) // 返回上一层

      this.$router.push({
        path: '/express/express_org'
      });
    },
    submitCreate: function submitCreate() {
      var _this2 = this;

      this.$refs['Form'].validate(function (valid) {
        if (valid) {
          _this2.expressOrg.province = _this2.getNameByCode(_this2.provinceSelect, _this2.expressOrg.provinceCode);
          _this2.expressOrg.city = _this2.getNameByCode(_this2.citySelect, _this2.expressOrg.cityCode);
          _this2.expressOrg.district = _this2.getNameByCode(_this2.regionSelect, _this2.expressOrg.districtCode);
          (0, _expressOrg.createExpressOrg)(_this2.expressOrg).then(function (res) {
            if (res.data && res.code === 200) {
              _this2.$message.success('操作成功');
            } else {
              _this2.$message.error(res.msg || '操作失败');
            }

            _this2.expressOrg = {};

            _this2.$router.push({
              path: '/express/express_org'
            });
          });
        }
      });
    },
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this3.provinceSelect = res.data;
      });
    },
    getNameByCode: function getNameByCode(select, code) {
      var name = '';

      if (select.length > 0) {
        select.forEach(function (item) {
          if (item.id === code) {
            name = item.label;
          }
        });
      }

      return name;
    },
    getCityOption: function getCityOption() {
      var _this4 = this;

      if (this.expressOrg.provinceCode) {
        (0, _select.getAreasByParentId)(this.expressOrg.provinceCode).then(function (response) {
          _this4.citySelect = response.data;
        });
      }

      if (this.expressOrg.cityCode) {
        (0, _select.getAreasByParentId)(this.expressOrg.cityCode).then(function (response) {
          _this4.regionSelect = response.data;
        });
      }
    },
    // 寄件人 城市
    proviceChange: function proviceChange(province) {
      var _this5 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this5.citySelect = response.data;
        });
      }

      this.$set(this.expressOrg, 'cityCode', '');
      this.$set(this.expressOrg, 'districtCode', ''); // this.expressOrg.cityCode = ''
      // this.expressOrg.districtCode = ''
    },
    // 寄件人区域
    cityChange: function cityChange(city) {
      var _this6 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this6.regionSelect = response.data;
        });
      } // this.expressOrg.districtCode = ''


      this.$set(this.expressOrg, 'districtCode', '');
    }
  }
};
exports.default = _default;