"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _invoice = require("@/api/bill/invoice");

var _select = require("@/api/common/select");

var _uploadImgs = _interopRequireDefault(require("@/components/uploadImgs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 附件图片上传
var _default = {
  name: 'invoiceDetail',
  // eslint-disable-line
  components: {
    uploadImgs: _uploadImgs.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      completeBtn: false,
      id: this.$route.params.id,
      orderNum: this.$route.params.orderNum,
      billStatus: this.$route.params.billStatus,
      isReadOnly: false,
      billInvoiceInfo: {
        custName: '',
        custTax: '',
        custAddress: '',
        custPhone: '',
        custBank: '',
        custBankAcc: '',
        takeUser: '',
        takeMobile: '',
        takeProvinceId: '',
        takeCityId: '',
        takeDistrictId: '',
        takeAddress: '',
        custEmail: '',
        charterPicture: '',
        custQualificationPicture: ''
      },
      loadingList4Model: false,
      flag: false,
      part1: false,
      part2: false,
      part3: false,
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      dis: false,
      rules: {},
      rules1: {
        custName: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z\u4e00-\u9fa5（）()]+$/,
          message: '只能使用汉字、英文字母和括号'
        }],
        custTax: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{17}|[a-zA-Z0-9]{18}|[a-zA-Z0-9]{20})$/,
          message: '只能填写15、17、18、20位的字母与数字不能含特殊字符及空格、全角'
        }],
        custAddress: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9\u4e00-\u9fa5（）\x00-\xff]+$/,
          message: '不能含有特殊字符及空格和除括号外的全角符号'
        }],
        custPhone: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^([0-9]{11,12}|[0-9]{4}[-]{1}[0-9]{7,8}|[0-9]{3}[-]{1}[0-9]{8,9})$/,
          message: '电话格式错误'
        }],
        custBank: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        custBankAcc: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9\u4e00-\u9fa5（）\x00-\xff]+$/,
          message: '不能含有特殊字符及空格和除括号外的全角符号'
        }],
        takeUser: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        takeMobile: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[0-9]{1,11}$/,
          message: '手机号码格式不正确'
        }],
        takeProvinceId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        takeCityId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        takeDistrictId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        takeAddress: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        charterPicture: [{
          required: true,
          message: '请添加',
          trigger: 'blur'
        }],
        custQualificationPicture: [{
          required: true,
          message: '请添加',
          trigger: 'blur'
        }]
      },
      rules3: {
        custName: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z\u4e00-\u9fa5（）()]+$/,
          message: '只能使用汉字、英文字母和括号'
        }],
        custTax: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{17}|[a-zA-Z0-9]{18}|[a-zA-Z0-9]{20})$/,
          message: '只能填写15、17、18、20位的字母与数字不能含特殊字符及空格、全角'
        }],
        custEmail: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^([a-z0-9_-]+[.]?[a-z0-9_-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]{2,})+$/i,
          message: '邮箱格式不正确'
        }]
      },
      rules2: {
        custName: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z\u4e00-\u9fa5（）()]+$/,
          message: '只能使用汉字、英文字母和括号'
        }],
        custEmail: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }, {
          pattern: /^([a-z0-9_-]+[.]?[a-z0-9_-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]{2,})+$/i,
          message: '邮箱格式不正确'
        }]
      },
      // 弹出层
      dialogConfirmation: false,
      checkFlag: false,
      checkShow: false
    };
  },
  created: function created() {
    this.getProvinceOptions();

    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryById)(param).then(function (response) {
        if (response.data) {
          _this.billInvoiceInfo = response.data;

          if (_this.billInvoiceInfo.billingStatus !== '10' & _this.billInvoiceInfo.billingStatus !== '70') {
            _this.dis = true;
          }

          if (_this.billInvoiceInfo.invoiceType === '10') {
            _this.flag = true;
            _this.part1 = false;
            _this.part2 = true;
            _this.part3 = true;
            _this.rules = _this.rules1;
          } else {
            _this.flag = false;

            if (_this.billInvoiceInfo.custType === '10') {
              _this.part1 = true;
              _this.part2 = false;
              _this.part3 = true;
              _this.rules = _this.rules2;
            } else {
              _this.part1 = true;
              _this.part2 = true;
              _this.part3 = false;
              _this.rules = _this.rules3;
            }
          }

          if (_this.billInvoiceInfo.takeProvinceId !== null && _this.billInvoiceInfo.takeProvinceId !== '') {
            (0, _select.getAreasByParentId)(_this.billInvoiceInfo.takeProvinceId).then(function (response) {
              _this.cityOptions = response.data;
            });
          } // 区/县  回显


          if (_this.billInvoiceInfo.takeCityId !== null && _this.billInvoiceInfo.takeCityId !== '') {
            (0, _select.getAreasByParentId)(_this.billInvoiceInfo.takeCityId).then(function (response) {
              _this.countryOptions = response.data;
            });
          } //


          _this.billInvoiceInfo.custName = _this.billInvoiceInfo.custNamePage;
          _this.billInvoiceInfo.custTax = _this.billInvoiceInfo.custTaxPage;
          _this.billInvoiceInfo.custAddress = _this.billInvoiceInfo.custAddressPage;
          _this.billInvoiceInfo.custPhone = _this.billInvoiceInfo.custPhonePage;
          _this.billInvoiceInfo.custBank = _this.billInvoiceInfo.custBankPage;
          _this.billInvoiceInfo.custBankAcc = _this.billInvoiceInfo.custBankAccPage;
          _this.billInvoiceInfo.takeUser = _this.billInvoiceInfo.takeUserPage;
          _this.billInvoiceInfo.takeMobile = _this.billInvoiceInfo.takeMobilePage;
          _this.billInvoiceInfo.takeAddress = _this.billInvoiceInfo.takeAddressPage;
          _this.billInvoiceInfo.custEmail = _this.billInvoiceInfo.custEmailPage;

          if (_this.billInvoiceInfo.charterPicture !== null && _this.billInvoiceInfo.charterPicture !== '') {
            _this.billInvoiceInfo.charterPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.billInvoiceInfo.charterPicture;
          }

          if (_this.billInvoiceInfo.custQualificationPicture !== null && _this.billInvoiceInfo.custQualificationPicture !== '') {
            _this.billInvoiceInfo.custQualificationPicture = process.env.VUE_APP_S3_STORAGE_URL + _this.billInvoiceInfo.custQualificationPicture;
          } // 使用个人支付，开具专票时


          if (_this.billInvoiceInfo.businessPay === '0' && _this.billInvoiceInfo.invoiceType === '10') {
            _this.checkShow = true;
          }
        }
      }); // 只有待处理的发票可以修改，除此之外状态不用提示

      if (this.billStatus === '10') {
        var orderNum = {
          orderNum: this.orderNum
        };
        (0, _invoice.queryInvoiceByNo)(orderNum).then(function (response) {
          if (response.code !== 200) {
            _this.$confirm('当前场景无法提交换票信息，原因如下：\r\n' + response.msg + '\r\n是否继续修改？', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning',
              center: true
            }).then(function () {}).catch(function () {});
          }
        });
      }
    },
    getProvinceOptions: function getProvinceOptions() {
      var _this2 = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this2.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this3 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this3.cityOptions = response.data;
        });
      }

      this.billInvoiceInfo.takeCityId = '';
      this.billInvoiceInfo.takeDistrictId = '';
    },
    cityChange: function cityChange(city) {
      var _this4 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this4.countryOptions = response.data;
        });
      }

      this.billInvoiceInfo.takeDistrictId = '';
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/callout/callBill'
      });
    },
    invoiceTypeClick: function invoiceTypeClick() {
      if (this.billInvoiceInfo.invoiceType === '10') {
        this.billInvoiceInfo.custType = '20';
        this.flag = true;
        this.part1 = false;
        this.part2 = true;
        this.part3 = true;
        this.rules = this.rules1;

        if (this.billInvoiceInfo.businessPay === '0') {
          this.checkShow = true;
        }
      } else {
        this.flag = false;

        if (this.billInvoiceInfo.custType === '10') {
          this.part1 = true;
          this.part2 = false;
          this.part3 = true;
          this.rules = this.rules2;
        } else {
          this.part1 = true;
          this.part2 = true;
          this.part3 = false;
          this.rules = this.rules3;
        }

        this.checkShow = false;
      }
    },
    comfirm: function comfirm() {
      var _this5 = this;

      // 将原来的直接修改改为和H5换票一致纸质发票
      if (this.billInvoiceInfo.charterPicture !== null && this.billInvoiceInfo.charterPicture.indexOf('=') !== -1) {
        this.billInvoiceInfo.charterPicture = this.billInvoiceInfo.charterPicture.split('=')[1];
      }

      if (this.billInvoiceInfo.custQualificationPicture !== null && this.billInvoiceInfo.custQualificationPicture.indexOf('=') !== -1) {
        this.billInvoiceInfo.custQualificationPicture = this.billInvoiceInfo.custQualificationPicture.split('=')[1];
      }

      (0, _invoice.createTicketOnPc)(this.billInvoiceInfo).then(function (response) {
        if (response.data) {
          _this5.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          });

          _this5.$store.dispatch('tagsView/delView', _this5.$route);

          _this5.$router.push({
            path: '/callout/callBill'
          });
        } else {
          _this5.$message({
            showClose: true,
            message: '当前场景无法提交换票信息，原因如下：\r\n' + response.msg,
            type: 'warning'
          });

          _this5.dialogVisible = false;
        }
      });
    },
    updateBill: function updateBill() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this6.checkShow === true && _this6.checkFlag === false) {
            _this6.$message({
              showClose: true,
              message: '请先勾选《增值税专用发票抬头确认书》',
              type: 'warning'
            });

            return;
          }

          _this6.dialogVisible = true;
        }
      });
    },
    custTypeClick: function custTypeClick() {
      if (this.billInvoiceInfo.invoiceType === '10') {
        this.part1 = false;
        this.part2 = true;
        this.part3 = true;
        this.rules = this.rules1;
      } else {
        if (this.billInvoiceInfo.custType === '10') {
          this.part1 = true;
          this.part2 = false;
          this.part3 = true;
          this.rules = this.rules2;
        } else {
          this.part1 = true;
          this.part2 = true;
          this.part3 = false;
          this.rules = this.rules3;
        }
      }
    },
    setCharterPicturePath: function setCharterPicturePath(data) {
      this.billInvoiceInfo.charterPicture = data;
    },
    setCustQualificationPicturePath: function setCustQualificationPicturePath(data) {
      this.billInvoiceInfo.custQualificationPicture = data;
    },
    setCheckFlag: function setCheckFlag() {
      this.checkFlag = true;
    },
    showConfirmation: function showConfirmation() {
      this.dialogConfirmation = true;
    }
  }
};
exports.default = _default;