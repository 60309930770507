var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "顺丰路由履历信息" },
    [
      _c(
        "el-table",
        {
          staticClass: "mySFtable",
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            border: "",
            stripe: "",
            "tooltip-effect": "dark",
            "header-cell-style": {
              background: "#eef1f6",
              color: "#606266",
              height: "40px",
            },
            data: _vm.routeList,
          },
        },
        [
          _c(
            "el-table-column",
            { attrs: { label: "物流路由信息" } },
            [
              _c("el-table-column", { attrs: { prop: "opeTitle" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opeRemark", "min-width": "280PX;" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "opeTime",
                  "min-width": "120PX;",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }