"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 公告块
 *
 * @type {{path: string, component, hidden: boolean, children: {path: string, component: (function(): (Promise<*>|*)), meta: {hidden: boolean, activeMenu: string, icon: string, title: string}, name: string}[]}}
 */
var noticeRouter = {
  path: '/notice/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'noticeDetail/:id',
    name: 'noticeDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/notice/list/edit'));
      });
    },
    meta: {
      title: '公告管理详细',
      icon: 'table',
      hidden: true,
      activeMenu: '/notice/list'
    }
  }]
};
var _default = noticeRouter;
exports.default = _default;