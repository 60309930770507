var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple",
                  staticStyle: { float: "left", "padding-left": "1.3rem" },
                },
                [
                  _vm.consultOrderInfo.receiveUser
                    ? _c(
                        "el-tag",
                        { attrs: { effect: "plain", type: "info" } },
                        [
                          _vm._v(
                            "领用人：" +
                              _vm._s(_vm.consultOrderInfo.receiveUser) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showGDPR,
                      expression: "showGDPR",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_SENDGDPRSMS"],
                      expression: "['ROLE_CONSULT_ORDER_SENDGDPRSMS']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.sendGdprSms },
                },
                [_vm._v("GDPR短信\n      ")]
              ),
              _vm._v(" "),
              _c("new-task", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["ROLE_WORK_TASK_MY_LIST"],
                    expression: "['ROLE_WORK_TASK_MY_LIST']",
                  },
                ],
                attrs: {
                  "work-id": _vm.consultOrderInfo.id,
                  "work-no": _vm.consultOrderInfo.num,
                  "work-type": "1",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isupload,
                      expression: "isupload",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_UPLOAD"],
                      expression: "['ROLE_CONSULT_ORDER_UPLOAD']",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUpload },
                },
                [_vm._v("待客户上传\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showChangeOrderBtn,
                      expression: "showChangeOrderBtn",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_TO_ORDER"],
                      expression: "['ROLE_CONSULT_ORDER_TO_ORDER']",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.changeOrder },
                },
                [_vm._v("转工单\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showBtn,
                      expression: "showBtn",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteConsultOrder },
                },
                [_vm._v("作废\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEdit,
                      expression: "isEdit",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_FINISH"],
                      expression: "['ROLE_CONSULT_ORDER_FINISH']",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.finishOrder },
                },
                [_vm._v("完结咨询单\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEdit,
                      expression: "isEdit",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_VSE"],
                      expression: "['ROLE_CONSULT_ORDER_VSE']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", disabled: _vm.disableVSE },
                  on: {
                    click: function ($event) {
                      _vm.vseVisiable = true
                    },
                  },
                },
                [_vm._v("转VSE\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEdit,
                      expression: "isEdit",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_SENDSMS"],
                      expression: "['ROLE_CONSULT_ORDER_SENDSMS']",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", disabled: _vm.disableVSE },
                  on: { click: _vm.sendSms },
                },
                [_vm._v("发送短信\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEdit,
                      expression: "isEdit",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.tempSave },
                },
                [_vm._v("暂存\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isQuery === "0",
                      expression: "isQuery==='0'",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "warning" },
                  on: { click: _vm.returnBtn },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.consultOrderInfo,
            rules: _vm.rules,
            "label-width": "150px",
          },
        },
        [
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-12" },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("咨询内容")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "会话ID", prop: "sessionId" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: !_vm.isEdit },
                                  model: {
                                    value: _vm.consultOrderInfo.sessionId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "sessionId",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.sessionId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "咨询单号", prop: "num" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.consultOrderInfo.num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.consultOrderInfo, "num", $$v)
                                    },
                                    expression: "consultOrderInfo.num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建人", prop: "inRmkName" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.consultOrderInfo.inRmkName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "inRmkName",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.inRmkName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "创建时间", prop: "inDateFM" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.consultOrderInfo.inDateFM,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "inDateFM",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.inDateFM",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isEdit,
                                    expression: "!isEdit",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "主状态", prop: "state" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          disabled: "",
                                          placeholder: "请选择状态",
                                        },
                                        model: {
                                          value: _vm.consultOrderInfo.state,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "state",
                                              $$v
                                            )
                                          },
                                          expression: "consultOrderInfo.state",
                                        },
                                      },
                                      _vm._l(
                                        _vm.stateOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.val,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "子状态",
                                      prop: "subState",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          disabled: "",
                                          clearable: "",
                                          placeholder: "请选择子状态",
                                        },
                                        model: {
                                          value: _vm.consultOrderInfo.subState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "subState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.subState",
                                        },
                                      },
                                      _vm._l(
                                        _vm.subStateOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.val,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "报修人unionid",
                                  prop: "applicantUnionid",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value:
                                      _vm.consultOrderInfo.applicantUnionid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "applicantUnionid",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "consultOrderInfo.applicantUnionid",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "报修人姓名",
                                  prop: "applicantMan",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: !_vm.isEdit },
                                  model: {
                                    value: _vm.consultOrderInfo.applicantMan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "applicantMan",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.applicantMan",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _vm.riskId
                                  ? _c("i", {
                                      staticClass: "el-icon-warning",
                                      staticStyle: {
                                        position: "relative",
                                        left: "10px",
                                        top: "28px",
                                        color: "red",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toRiskDetail(_vm.riskId)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "报修人电话",
                                      prop: "applicantTel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: !_vm.isEdit },
                                      model: {
                                        value:
                                          _vm.consultOrderInfo.applicantTel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "applicantTel",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.applicantTel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.consultOrderInfo.jdSalesOrderNo
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "JD单号",
                                      prop: "jdOrderNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.consultOrderInfo.jdSalesOrderNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "jdSalesOrderNo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.jdSalesOrderNo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.consultOrderInfo.jdOrderNo
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "JD虚拟电话" } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.consultOrderInfo
                                              .phoneBindNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "phoneBindNumber",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.phoneBindNumber",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: [
                                                  "ROLE_CONSULT_ORDER_REFRESHPHONE",
                                                ],
                                                expression:
                                                  "['ROLE_CONSULT_ORDER_REFRESHPHONE']",
                                              },
                                            ],
                                            attrs: {
                                              slot: "append",
                                              size: "small",
                                              type: "primary",
                                              disabled: !_vm.isEdit,
                                            },
                                            on: { click: _vm.refreshJDphone },
                                            slot: "append",
                                          },
                                          [
                                            _vm._v(
                                              "刷新电话\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.consultOrderInfo.jdOrderNo
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "电话过期时间" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.consultOrderInfo.phoneBindTimeout,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "phoneBindTimeout",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.phoneBindTimeout",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.consultOrderInfo.jdOrderNo
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "JD补充信息" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "500",
                                        autosize: { minRows: 5 },
                                        type: "textarea",
                                        "show-word-limit": "",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.jdSupplyInfo,
                                        callback: function ($$v) {
                                          _vm.jdSupplyInfo = $$v
                                        },
                                        expression: "jdSupplyInfo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.consultOrderInfo.jdOrderNo
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "寄件方式" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择寄件方式",
                                        },
                                        model: {
                                          value:
                                            _vm.consultOrderInfo.sendRepairType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "sendRepairType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.sendRepairType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: "10",
                                            label: "上门取件",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            value: "20",
                                            label: "用户自寄",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "业务类型", prop: "type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.isEdit,
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择业务类型",
                                    },
                                    on: { change: _vm.changeChannel },
                                    model: {
                                      value: _vm.consultOrderInfo.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.typeOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "接入渠道",
                                  prop: "accessChannel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.isEdit,
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择接入渠道",
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.accessChannel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "accessChannel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.accessChannel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.accessChannelOptions,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.val,
                                          disabled: item.val === "8",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "是否触发短信", prop: "isSms" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { fill: "#000" },
                                    model: {
                                      value: _vm.consultOrderInfo.isSms,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "isSms",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.isSms",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "1" } },
                                      [_vm._v("是")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "0" } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "咨询单一级归类",
                                  prop: "oneLevel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.isEdit,
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.oneLevelChange(
                                          _vm.consultOrderInfo.oneLevel
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.oneLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "oneLevel",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.oneLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.oneLevelSelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: item.isEnable !== "1",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "咨询单二级归类",
                                  prop: "twoLevel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.isEdit,
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "咨询单二级归类",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.twoLevelChange(
                                          _vm.consultOrderInfo.twoLevel
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.twoLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "twoLevel",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.twoLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.twoLevelSelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: item.isEnable !== "1",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "咨询单三级归类",
                                  prop: "threeLevel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.isEdit,
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "咨询单三级归类",
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.threeLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "threeLevel",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.threeLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.threeLevelSelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: item.isEnable !== "1",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.consultOrderInfo.customChannel === "FB49" ||
                            (_vm.consultOrderInfo.tradeInNo !== "" &&
                              _vm.consultOrderInfo.tradeInNo !== null &&
                              _vm.consultOrderInfo.tradeInNo !== undefined)
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "升级优惠服务单号",
                                      prop: "tradeInNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 2,
                                        disabled: !_vm.isEdit,
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.tradeInNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "tradeInNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.tradeInNo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.disable708
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "批次号",
                                      prop: "batchCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { rows: 2, disabled: !_vm.isEdit },
                                      model: {
                                        value: _vm.consultOrderInfo.batchCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "batchCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.batchCode",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.disable708
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "708SKU" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择sku",
                                        },
                                        model: {
                                          value:
                                            _vm.consultOrderInfo.skuCode708,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "skuCode708",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.skuCode708",
                                        },
                                      },
                                      _vm._l(
                                        _vm.select708,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.consultOrderInfo.customChannel === "FB49" ||
                            (_vm.consultOrderInfo.tradeInNo !== "" &&
                              _vm.consultOrderInfo.tradeInNo !== null &&
                              _vm.consultOrderInfo.tradeInNo !== undefined)
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "升级优惠服务单号",
                                      prop: "tradeInNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 2,
                                        disabled: !_vm.isEdit,
                                      },
                                      model: {
                                        value: _vm.consultOrderInfo.tradeInNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "tradeInNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.tradeInNo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "用户描述",
                                  prop: "userDescribe",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.userDescribe,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "userDescribe",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.userDescribe",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "自助服务描述",
                                  prop: "beforeMemo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.beforeMemo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "beforeMemo",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.beforeMemo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "问题出现前/后",
                                  prop: "afterMemo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.afterMemo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "afterMemo",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.afterMemo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Resolve方案",
                                  prop: "resolveMemo",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { disabled: !_vm.isEdit },
                                    model: {
                                      value: _vm.consultOrderInfo.resolveMemo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "resolveMemo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "consultOrderInfo.resolveMemo",
                                    },
                                  },
                                  [
                                    _vm.isEdit
                                      ? _c("el-button", {
                                          attrs: {
                                            slot: "append",
                                            icon: "el-icon-search",
                                          },
                                          on: { click: _vm.resolveClick },
                                          slot: "append",
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "解决方案",
                                  prop: "solutionMemo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.solutionMemo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "solutionMemo",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.solutionMemo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "memo" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.memo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "memo",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.memo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "关联服务单",
                                  prop: "sourceNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: !_vm.isEdit },
                                  model: {
                                    value: _vm.consultOrderInfo.sourceNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "sourceNum",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.sourceNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "vse接单时间",
                                  prop: "vseStartTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择日期时间",
                                  },
                                  model: {
                                    value: _vm.consultOrderInfo.vseStartTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.consultOrderInfo,
                                        "vseStartTime",
                                        $$v
                                      )
                                    },
                                    expression: "consultOrderInfo.vseStartTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.disable571
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "country",
                                      label: "销售国家",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "销售国家",
                                        },
                                        model: {
                                          value: _vm.consultOrderInfo.country,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "country",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.country",
                                        },
                                      },
                                      _vm._l(
                                        _vm.countryOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.val,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.disable571
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "globalWarranty",
                                      label: "是否全球联保",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "是否全球联保",
                                        },
                                        model: {
                                          value:
                                            _vm.consultOrderInfo.globalWarranty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "globalWarranty",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "consultOrderInfo.globalWarranty",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: "1", label: "是" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: "0", label: "否" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("el-tag", [_c("span", [_vm._v("上传附件")])]),
                        _vm._v(" 　　"),
                        _c("el-button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEdit,
                              expression: "isEdit",
                            },
                          ],
                          attrs: {
                            type: "info",
                            icon: "el-icon-plus",
                            size: "mini",
                            circle: "",
                          },
                          on: { click: _vm.addDomain },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.uploadList, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "el-container",
                                  [
                                    _c(
                                      "el-aside",
                                      {
                                        staticStyle: { "margin-left": "120px" },
                                        attrs: { width: "20%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "img_size" },
                                          [
                                            item.type.indexOf("image") !== -1
                                              ? _c("el-image", {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                  attrs: {
                                                    src: item.fileId,
                                                    "preview-src-list": [
                                                      item.fileId,
                                                    ],
                                                  },
                                                })
                                              : _c("video", {
                                                  staticClass: "avatar",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                  attrs: {
                                                    src: item.fileId,
                                                    controls: "controls",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-main",
                                      [
                                        _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "padding-bottom": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "上传人：" +
                                                        _vm._s(
                                                          item.uploadUserName
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "上传时间：" +
                                                        _vm._s(
                                                          item.uploadDateFM
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "描述：" +
                                                        _vm._s(item.content)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isEdit,
                                            expression: "isEdit",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeDomain(item)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("转单信息")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.changeActiveNames,
                              callback: function ($$v) {
                                _vm.changeActiveNames = $$v
                              },
                              expression: "changeActiveNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "转单信息", name: "1" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "工单类型",
                                      prop: "applicantMan",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.consultOrderInfo.type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.consultOrderInfo,
                                              "type",
                                              $$v
                                            )
                                          },
                                          expression: "consultOrderInfo.type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.typeOptions,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.val,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "工单号",
                                      prop: "applicantTel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.consultOrderInfo.bizNum,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "bizNum",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.bizNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否上门取件",
                                      prop: "applicantMan",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.consultOrderInfo.postType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "postType",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.postType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "预约快递公司",
                                      prop: "applicantTel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.consultOrderInfo.express,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "express",
                                            $$v
                                          )
                                        },
                                        expression: "consultOrderInfo.express",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "快递单号",
                                      prop: "applicantMan",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.consultOrderInfo.expressNum,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.consultOrderInfo,
                                            "expressNum",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "consultOrderInfo.expressNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("运单记录")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.expressActiveNames,
                              callback: function ($$v) {
                                _vm.expressActiveNames = $$v
                              },
                              expression: "expressActiveNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "运单状态记录", name: "1" } },
                              _vm._l(
                                _vm.expressStateInfoList,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "border-bottom": "1px solid #e6ebf5",
                                        padding: "5px",
                                      },
                                    },
                                    [
                                      _c("el-col", { attrs: { span: 3 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.orderStateCode))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 13 } }, [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(item.empCode) +
                                                  "】  " +
                                                  _vm._s(item.empPhone) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(_vm._s(item.orderStateDesc)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.inDateFM))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-collapse-item",
                              { attrs: { title: "运单路由", name: "2" } },
                              _vm._l(
                                _vm.expressRouteInfoList,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "border-bottom": "1px solid #e6ebf5",
                                        padding: "5px",
                                      },
                                    },
                                    [
                                      _c("el-col", { attrs: { span: 3 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatExpState")(
                                                  item.expState
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 13 } }, [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(item.opeTitle) +
                                                  "】  " +
                                                  _vm._s(item.acceptAddress)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(_vm._s(item.opeRemark)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.opeTime))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "el-col el-col-12" },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("el-tag", [_vm._v("CRM查询条件")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      type: "primary",
                                      placeholder: "手机号",
                                      disabled: _vm.isSearch === false,
                                    },
                                    model: {
                                      value: _vm.consultOrderInfo.crmPhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.consultOrderInfo,
                                          "crmPhone",
                                          $$v
                                        )
                                      },
                                      expression: "consultOrderInfo.crmPhone",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isEdit && _vm.isSearch,
                                            expression: "isEdit && isSearch",
                                          },
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.btnLoading,
                                            expression: "btnLoading",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          type: "primary",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.queryCusAndProd },
                                        slot: "append",
                                      },
                                      [_vm._v("查询\n                  ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isEdit &&
                                              _vm.isSearch === false,
                                            expression:
                                              "isEdit && (isSearch===false)",
                                          },
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.btnLoading,
                                            expression: "btnLoading",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          type: "primary",
                                          icon: "el-icon-delete",
                                        },
                                        on: { click: _vm.clearSearch },
                                        slot: "append",
                                      },
                                      [_vm._v("清空\n                  ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "unionId" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      type: "primary",
                                      placeholder: "unionId",
                                      disabled: _vm.isSearch2 === false,
                                    },
                                    model: {
                                      value: _vm.applicantUnionid,
                                      callback: function ($$v) {
                                        _vm.applicantUnionid = $$v
                                      },
                                      expression: "applicantUnionid",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isEdit && _vm.isSearch2,
                                            expression: "isEdit && isSearch2",
                                          },
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.btnLoading,
                                            expression: "btnLoading",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          size: "small",
                                          type: "primary",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: _vm.queryCusAndProdByUnionId,
                                        },
                                        slot: "append",
                                      },
                                      [_vm._v("查询\n                  ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isEdit &&
                                              _vm.isSearch2 === false &&
                                              _vm.consultOrderInfo.state ===
                                                "00",
                                            expression:
                                              "isEdit && (isSearch2===false && consultOrderInfo.state==='00')",
                                          },
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.btnLoading,
                                            expression: "btnLoading",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          size: "small",
                                          type: "primary",
                                          icon: "el-icon-delete",
                                        },
                                        on: { click: _vm.clearSearch2 },
                                        slot: "append",
                                      },
                                      [_vm._v("清空\n                  ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("el-tag", [_vm._v("机主信息")]),
                        _vm._v(" "),
                        _vm.isEdit
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: [
                                      "ROLE_CONSULT_ORDER_CSRM_CUSTOMER_MODIFY",
                                    ],
                                    expression:
                                      "['ROLE_CONSULT_ORDER_CSRM_CUSTOMER_MODIFY']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCusDrawer()
                                  },
                                },
                                slot: "append",
                              },
                              [_vm._v("编辑\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isEdit,
                                expression: "isEdit",
                              },
                            ],
                            attrs: {
                              slot: "append",
                              type: "primary",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showLabelDialog(1)
                              },
                            },
                            slot: "append",
                          },
                          [_vm._v("打标签\n            ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "customerForm",
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          rules: _vm.cusRules,
                          model: _vm.consultOrderCustomerInfo,
                          "label-position": "right",
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: { label: "姓名", prop: "name" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderCustomerInfo.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderCustomerInfo,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderCustomerInfo.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "手机号",
                                          prop: "mobile",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderCustomerInfo
                                                .mobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderCustomerInfo,
                                                "mobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderCustomerInfo.mobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "性别",
                                          prop: "gender",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderCustomerInfo
                                                .gender,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderCustomerInfo,
                                                "gender",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderCustomerInfo.gender",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "地区",
                                          prop: "districtDetail",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderCustomerInfo
                                                .districtDetail,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderCustomerInfo,
                                                "districtDetail",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderCustomerInfo.districtDetail",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "详细地址",
                                          prop: "detailAddress",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.consultOrderCustomerInfo
                                                .detailAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.consultOrderCustomerInfo,
                                                "detailAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "consultOrderCustomerInfo.detailAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.userLabelList.length > 0
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户标签" } },
                                          _vm._l(
                                            _vm.userLabelList,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag.labelName,
                                                  attrs: {
                                                    closable: "",
                                                    type: tag.labelLevelName,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.removeTag(
                                                        tag,
                                                        _vm.userLabelList,
                                                        "user"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(tag.labelName) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.mobileLabelList.length > 0
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "手机号标签" } },
                                          _vm._l(
                                            _vm.mobileLabelList,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag.labelName,
                                                  attrs: {
                                                    closable: "",
                                                    type: tag.labelLevelName,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.removeTag(
                                                        tag,
                                                        _vm.mobileLabelList,
                                                        "mobile"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(tag.labelName) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.addressLabelList.length > 0
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "详细地址标签" } },
                                          _vm._l(
                                            _vm.addressLabelList,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag.labelName,
                                                  attrs: {
                                                    closable: "",
                                                    type: tag.labelLevelName,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.removeTag(
                                                        tag,
                                                        _vm.mobileLabelList,
                                                        "add"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(tag.labelName) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommand } },
                          [
                            _vm.isEdit
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: [
                                          "ROLE_CONSULT_ORDER_CSRM_PRODUCT_MODIFY",
                                        ],
                                        expression:
                                          "['ROLE_CONSULT_ORDER_CSRM_PRODUCT_MODIFY']",
                                      },
                                    ],
                                    attrs: { type: "primary", size: "small" },
                                  },
                                  [
                                    _vm._v("\n                新建"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "1" } },
                                  [_vm._v("注册产品")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "2" } },
                                  [_vm._v("仅登记")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isEdit
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: [
                                      "ROLE_CONSULT_ORDER_CSRM_PRODUCT_MODIFY",
                                    ],
                                    expression:
                                      "['ROLE_CONSULT_ORDER_CSRM_PRODUCT_MODIFY']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showDrawer(
                                      2,
                                      _vm.consultOrderInfo.choosedProductType
                                    )
                                  },
                                },
                                slot: "append",
                              },
                              [_vm._v("编辑\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isEdit,
                                expression: "isEdit",
                              },
                            ],
                            attrs: {
                              slot: "append",
                              type: "primary",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showLabelDialog(2)
                              },
                            },
                            slot: "append",
                          },
                          [_vm._v("打标签\n            ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.switchProdcut },
                            model: {
                              value: _vm.consultOrderInfo.choosedProductType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.consultOrderInfo,
                                  "choosedProductType",
                                  $$v
                                )
                              },
                              expression: "consultOrderInfo.choosedProductType",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label:
                                    "注册产品信息（" +
                                    _vm.CustomerProductList.length +
                                    "）个",
                                  name: "1",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.isEdit &&
                                          _vm.consultOrderInfo.state === "00",
                                        expression:
                                          "isEdit && consultOrderInfo.state==='00'",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "primary",
                                              placeholder: "产品型号名称/SN",
                                            },
                                            model: {
                                              value: _vm.productSearch,
                                              callback: function ($$v) {
                                                _vm.productSearch = $$v
                                              },
                                              expression: "productSearch",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  slot: "append",
                                                  type: "primary",
                                                  icon: "el-icon-search",
                                                },
                                                on: {
                                                  click:
                                                    _vm.queryCustomerProduct,
                                                },
                                                slot: "append",
                                              },
                                              [
                                                _vm._v(
                                                  "查询\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse",
                                  {
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.activeNames,
                                      callback: function ($$v) {
                                        _vm.activeNames = $$v
                                      },
                                      expression: "activeNames",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CustomerProductList,
                                    function (item, index) {
                                      return _c(
                                        "el-collapse-item",
                                        {
                                          key: index,
                                          attrs: {
                                            title: item.prdName,
                                            name: index,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { name: "" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.choosedProduct(
                                                    item,
                                                    item.choose
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.choose,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "choose", $$v)
                                                },
                                                expression: "item.choose",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "是否选择此产品\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品名称",
                                                        prop: "productName",
                                                        "show-overflow-tooltip":
                                                          "",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.productName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "productName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.productName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品型号",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.modelName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "modelName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.modelName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品SKU",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.sku,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "sku",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.sku",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品SN",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.serialNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "serialNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.serialNumber",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "购买日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.purchaseDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "purchaseDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.purchaseDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "购买渠道",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.salesChannel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "salesChannel",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.salesChannel",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "注册时间",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.registerDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "registerDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.registerDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品90天权益",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.rights,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "rights",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.rights",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "是否是翻新机",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.refurbName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "refurbName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.refurbName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "订单日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.orderDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "orderDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.orderDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "入保日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.warrantyDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "warrantyDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.warrantyDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "保修结束日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.benefitEndDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "benefitEndDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.benefitEndDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: {
                                                        label: "是否延保",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          attrs: {
                                                            disabled: "",
                                                            size: "medium",
                                                          },
                                                          model: {
                                                            value:
                                                              item.isExtendBenefit,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "isExtendBenefit",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.isExtendBenefit",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              attrs: {
                                                                label: "1",
                                                              },
                                                            },
                                                            [_vm._v("是")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-radio-button",
                                                            {
                                                              attrs: {
                                                                label: "0",
                                                              },
                                                            },
                                                            [_vm._v("否")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "延保总时长",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.extendBenefitDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "extendBenefitDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.extendBenefitDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "销售国家",
                                                        prop: "country",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value: item.country,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "country",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.country",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.countryOptions,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.name,
                                                                  value:
                                                                    item.val,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        label: "是否全球联保",
                                                        prop: "globalWarranty",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              item.globalWarranty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "globalWarranty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.globalWarranty",
                                                          },
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: true,
                                                              label: "是",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: false,
                                                              label: "否",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "全球SKU",
                                                        prop: "overSeasSku",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.overSeasSku,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "overSeasSku",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.overSeasSku",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          item.choose
                                            ? _c(
                                                "el-row",
                                                [
                                                  _vm.snLabelList.length > 0
                                                    ? _c(
                                                        "el-col",
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "产品标签",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.snLabelList,
                                                              function (tag) {
                                                                return _c(
                                                                  "el-tag",
                                                                  {
                                                                    key: tag.labelName,
                                                                    attrs: {
                                                                      closable:
                                                                        "",
                                                                      type: tag.labelLevelName,
                                                                    },
                                                                    on: {
                                                                      close:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeTag(
                                                                            tag,
                                                                            _vm.snLabelList,
                                                                            "sn"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          tag.labelName
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label:
                                    "仅登记产品信息（" +
                                    _vm.enrollmentProductList.length +
                                    "）个",
                                  name: "2",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.isEdit &&
                                          _vm.consultOrderInfo.state === "00",
                                        expression:
                                          "isEdit && consultOrderInfo.state==='00'",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "primary",
                                              placeholder: "产品型号名称/SN",
                                            },
                                            model: {
                                              value: _vm.enrollSnSearch,
                                              callback: function ($$v) {
                                                _vm.enrollSnSearch = $$v
                                              },
                                              expression: "enrollSnSearch",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  slot: "append",
                                                  type: "primary",
                                                  icon: "el-icon-search",
                                                },
                                                on: {
                                                  click: _vm.filterProduct,
                                                },
                                                slot: "append",
                                              },
                                              [
                                                _vm._v(
                                                  "查询\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-collapse",
                                  {
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.activeNames2,
                                      callback: function ($$v) {
                                        _vm.activeNames2 = $$v
                                      },
                                      expression: "activeNames2",
                                    },
                                  },
                                  _vm._l(
                                    _vm.enrollmentProductList,
                                    function (item, index) {
                                      return _c(
                                        "el-collapse-item",
                                        {
                                          key: index,
                                          attrs: {
                                            title: item.prdName,
                                            name: index,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { name: "" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.choosedEnrollProduct(
                                                    item,
                                                    item.choose
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.choose,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "choose", $$v)
                                                },
                                                expression: "item.choose",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      是否选择此产品\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品名称",
                                                        prop: "skuName",
                                                        "show-overflow-tooltip":
                                                          "",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.skuName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "skuName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.skuName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品型号",
                                                        prop: "modelName",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.modelName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "modelName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.modelName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品SKU",
                                                        prop: "skuCode",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value: item.skuCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "skuCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.skuCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "产品SN",
                                                        prop: "serialNumber",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.serialNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "serialNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.serialNumber",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "购买日期",
                                                        prop: "purchaseDate",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.purchaseDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "purchaseDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.purchaseDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "登记日期",
                                                        prop: "enrollDate",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.enrollDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "enrollDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.enrollDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "入保日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.warrantyDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "warrantyDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.warrantyDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "保修结束日期",
                                                        prop: "bookRemark",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            item.benefitEndDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "benefitEndDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.benefitEndDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          item.choose
                                            ? _c(
                                                "el-row",
                                                [
                                                  _vm.snLabelList.length > 0
                                                    ? _c(
                                                        "el-col",
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "产品标签",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.snLabelList,
                                                              function (tag) {
                                                                return _c(
                                                                  "el-tag",
                                                                  {
                                                                    key: tag.labelName,
                                                                    attrs: {
                                                                      closable:
                                                                        "",
                                                                      type: tag.labelLevelName,
                                                                    },
                                                                    on: {
                                                                      close:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeTag(
                                                                            tag,
                                                                            _vm.snLabelList,
                                                                            "sn"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          tag.labelName
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              disabled: "",
                            },
                          },
                          [_vm._v("优惠券")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_CONSULT_QUERYCOUPON"],
                                expression: "['ROLE_CONSULT_QUERYCOUPON']",
                              },
                            ],
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.queryCustomerCoupon },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      [
                        _c(
                          "el-collapse",
                          {
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.couponActiveNames,
                              callback: function ($$v) {
                                _vm.couponActiveNames = $$v
                              },
                              expression: "couponActiveNames",
                            },
                          },
                          _vm._l(_vm.couponList, function (item, index) {
                            return _c(
                              "el-collapse-item",
                              {
                                key: index,
                                staticClass: "boltFont",
                                staticStyle: { "margin-left": "25px" },
                                attrs: {
                                  title: item.couponTemplateName,
                                  name: index,
                                },
                              },
                              [
                                _c("el-checkbox", {
                                  staticStyle: { "margin-right": "20px" },
                                  attrs: {
                                    disabled: _vm.disabledCouponCheck(item),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.choosedCoupon(
                                        item,
                                        item.choosed
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.choosed,
                                    callback: function ($$v) {
                                      _vm.$set(item, "choosed", $$v)
                                    },
                                    expression: "item.choosed",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.effectiveStartDate +
                                        "~" +
                                        item.effectiveEndDate
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("couponNameFilter")(
                                            item.status
                                          )
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-divider"),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-left": "25px",
                                      opacity: "0.5",
                                    },
                                  },
                                  [
                                    _vm._v("\n                  仅限SN:"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          "margin-right": "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.sn))]
                                    ),
                                    _vm._v("可用\n                "),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { opacity: "0.5" } },
                                      [_vm._v("适用清洁服务：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择服务SKU",
                                        },
                                        model: {
                                          value: item.serviceFeeId,
                                          callback: function ($$v) {
                                            _vm.$set(item, "serviceFeeId", $$v)
                                          },
                                          expression: "item.serviceFeeId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.userfeeSelect,
                                        function (fee, ind) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: ind,
                                              attrs: {
                                                label:
                                                  fee.name +
                                                  "（¥" +
                                                  fee.priceIncludeTaxYuan +
                                                  "）",
                                                value: fee.id,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v(_vm._s(fee.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "¥" +
                                                      _vm._s(
                                                        fee.priceIncludeTaxYuan
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { shadow: "hover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("el-tag", [_c("span", [_vm._v("历史工单记录")])]),
                        _vm._v(" 　　"),
                        _vm.isEdit
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "info",
                                  icon: "el-icon-search",
                                  size: "mini",
                                },
                                on: { click: _vm.queryHistory },
                              },
                              [_vm._v("查询\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.sendExchange },
                          },
                          [_vm._v("发送换票链接\n            ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "listTableDiv" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            key: "tableKey",
                            ref: "orderHistoryListTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              stripe: "",
                              "tooltip-effect": "dark",
                              data: _vm.list,
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "选择", width: "50px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: scope.row.bizNum },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.isCheck(scope.row)
                                            },
                                          },
                                          model: {
                                            value: _vm.exchangeId,
                                            callback: function ($$v) {
                                              _vm.exchangeId = $$v
                                            },
                                            expression: "exchangeId",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                     \n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "num", label: "咨询单号" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "type",
                                label: "业务类型",
                                formatter: _vm.typeFormat,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "bizNum",
                                label: "转单单号",
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "link-type",
                                          on: {
                                            click: function ($event) {
                                              return _vm.gotoQueryHtml(row)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(row.bizNum))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "state",
                                label: "主状态",
                                formatter: _vm.stateFormat,
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "subState",
                                label: "子状态",
                                formatter: _vm.subStateFormat,
                                align: "center",
                                resizable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total>0",
                            },
                          ],
                          attrs: {
                            "current-page": _vm.listQuery.page,
                            "page-sizes": [10, 20, 30, 50],
                            "page-size": _vm.listQuery.limit,
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialogMsg))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.comfirmToOrder(_vm.dialogType)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible1,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c("span", [_vm._v("请确认是否已解决客户问题")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.comfirmAgain("1")
                    },
                  },
                },
                [_vm._v("是")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.comfirmAgain("0")
                    },
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.uploadDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "uploadForm",
                  model: {
                    value: _vm.uploadInfo,
                    callback: function ($$v) {
                      _vm.uploadInfo = $$v
                    },
                    expression: "uploadInfo",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传附件" } },
                    [
                      _c("upload-file", {
                        ref: "upload1",
                        attrs: { limit: 1 },
                        on: {
                          setFilePaths: _vm.setFilePaths,
                          setFilePath: _vm.setFilePath,
                          setFile: _vm.setFile,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("el-input", {
                        ref: "content",
                        staticClass: "filter-item",
                        model: {
                          value: _vm.uploadInfo.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadInfo, "content", $$v)
                          },
                          expression: "uploadInfo.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.submitUpload },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  on: { click: _vm.resetUpload },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawer,
            direction: _vm.direction,
            "before-close": _vm.closeDrawer,
            size: "45%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 10, offset: 10 } }, [
                _c("span", [_vm._v(_vm._s(_vm.registerTitle))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ownCustomerProductForm",
              attrs: {
                rules: _vm.ownProductRule,
                model: _vm.ownCustomerProduct,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "padding-top": "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _vm.snRiskId && _vm.snRiskLevel === "30"
                            ? _c("i", {
                                staticClass: "el-icon-warning",
                                staticStyle: {
                                  position: "relative",
                                  right: "20px",
                                  top: "28px",
                                  color: "red",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toRiskDetail(_vm.snRiskId)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品SN", prop: "serialNumber" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    type: "primary",
                                    placeholder: "产品SN",
                                    disabled: _vm.disableSn,
                                  },
                                  on: { input: _vm.checkSnIn },
                                  model: {
                                    value: _vm.ownCustomerProduct.serialNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ownCustomerProduct,
                                        "serialNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ownCustomerProduct.serialNumber",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.btnLoading,
                                          expression: "btnLoading",
                                        },
                                      ],
                                      attrs: {
                                        slot: "append",
                                        size: "small",
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.getOverseasInfo },
                                      slot: "append",
                                    },
                                    [_vm._v("解析\n                ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "购买日期",
                                prop: "purchaseDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "dataStyle",
                                attrs: {
                                  align: "right",
                                  type: "datetime",
                                  placeholder: "请选择日期",
                                  "picker-options": _vm.pickerOptions,
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.ownCustomerProduct.purchaseDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ownCustomerProduct,
                                      "purchaseDate",
                                      $$v
                                    )
                                  },
                                  expression: "ownCustomerProduct.purchaseDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.isOverseas
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { span: 8, offset: 1 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "country", label: "销售国家" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.ownCustomerProduct.country,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ownCustomerProduct,
                                            "country",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ownCustomerProduct.country",
                                      },
                                    },
                                    _vm._l(
                                      _vm.countryOptions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.val,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOverseas
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { span: 8, offset: 1 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否全球联保",
                                    prop: "globalWarranty",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { fill: "#000", disabled: "" },
                                      model: {
                                        value:
                                          _vm.ownCustomerProduct.globalWarranty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ownCustomerProduct,
                                            "globalWarranty",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ownCustomerProduct.globalWarranty",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "true" } },
                                        [_vm._v("是")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "false" } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.isOverseas
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { span: 8, offset: 1 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生产日期",
                                    prop: "productDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "dataStyle",
                                    attrs: {
                                      align: "right",
                                      type: "datetime",
                                      placeholder: "请选择日期",
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "yyyy-MM-dd",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.ownCustomerProduct.productDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ownCustomerProduct,
                                          "productDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ownCustomerProduct.productDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "入保日期",
                                prop: "warrantyDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "dataStyle",
                                attrs: {
                                  align: "right",
                                  type: "datetime",
                                  placeholder: "请选择日期",
                                  "picker-options": _vm.pickerOptions,
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.ownCustomerProduct.warrantyDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ownCustomerProduct,
                                      "warrantyDate",
                                      $$v
                                    )
                                  },
                                  expression: "ownCustomerProduct.warrantyDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "入保日期备注",
                                prop: "warrantyNote",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ownCustomerProduct.warrantyNote,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ownCustomerProduct,
                                      "warrantyNote",
                                      $$v
                                    )
                                  },
                                  expression: "ownCustomerProduct.warrantyNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "0 auto",
                          "text-align": "center",
                        },
                      },
                      [
                        _c("input", {
                          staticStyle: { display: "none" },
                          attrs: { type: "reset" },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                            ],
                            attrs: {
                              slot: "append",
                              type: "primary",
                              size: "small",
                              disabled: _vm.btnLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitOwnProductInfo()
                              },
                            },
                            slot: "append",
                          },
                          [_vm._v("确认\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              size: "small",
                            },
                            on: { click: _vm.cancelProductClick },
                            slot: "append",
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.cusDrawer,
            direction: "ltr",
            "before-close": _vm.closeDrawer,
            size: "45%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.cusDrawer = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 10, offset: 10 } }, [
                _c("span", [_vm._v("编辑客户信息")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ownCustomerForm",
              attrs: {
                rules: _vm.ownCustomerRule,
                model: _vm.ownCustomer,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "padding-top": "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", prop: "name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ownCustomer.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ownCustomer, "name", $$v)
                                  },
                                  expression: "ownCustomer.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号", prop: "mobile" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.ownCustomer.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ownCustomer, "mobile", $$v)
                                    },
                                    expression: "ownCustomer.mobile",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "ROLE_CONSULT_ORDER_GETSENDCODE",
                                          ],
                                          expression:
                                            "['ROLE_CONSULT_ORDER_GETSENDCODE']",
                                        },
                                      ],
                                      attrs: {
                                        slot: "append",
                                        size: "small",
                                        type: "primary",
                                        disabled: _vm.isSend,
                                      },
                                      on: { click: _vm.sendMsg },
                                      slot: "append",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.sendMessage) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ownCustomer.gender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ownCustomer, "gender", $$v)
                                    },
                                    expression: "ownCustomer.gender",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: "保密", label: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { value: "先生", label: "先生" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { value: "女士", label: "女士" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "验证码", prop: "sendCode" } },
                            [
                              _c("el-input", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.sendCode,
                                  callback: function ($$v) {
                                    _vm.sendCode = $$v
                                  },
                                  expression: "sendCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "详细地址",
                                prop: "detailAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ownCustomer.detailAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ownCustomer,
                                      "detailAddress",
                                      $$v
                                    )
                                  },
                                  expression: "ownCustomer.detailAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { span: 8, offset: 1 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "地区", prop: "districtDetail" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ownCustomer.districtDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ownCustomer,
                                      "districtDetail",
                                      $$v
                                    )
                                  },
                                  expression: "ownCustomer.districtDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "0 auto",
                          "text-align": "center",
                        },
                      },
                      [
                        _c("input", {
                          staticStyle: { display: "none" },
                          attrs: { type: "reset" },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.btnLoading,
                                expression: "btnLoading",
                              },
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "ROLE_CONSULT_ORDER_CSRM_CUSTOMER_MODIFY",
                                ],
                                expression:
                                  "['ROLE_CONSULT_ORDER_CSRM_CUSTOMER_MODIFY']",
                              },
                            ],
                            attrs: {
                              slot: "append",
                              type: "primary",
                              disabled: _vm.btnLoading,
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitOwnCustomerInfo()
                              },
                            },
                            slot: "append",
                          },
                          [_vm._v("确认\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              size: "small",
                            },
                            on: { click: _vm.cancelClick },
                            slot: "append",
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": _vm.promotionClose,
            "close-on-click-modal": _vm.promotionClose,
            "close-on-press-escape": _vm.promotionClose,
            title: "PH02活动提醒",
            visible: _vm.showPromoation,
            "custom-class": "customerWidth",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPromoation = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", size: "small" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { push: 2 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购买/签收日期", prop: "signDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              align: "right",
                              placeholder: "请选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: { change: _vm.showPromoationMessage },
                            model: {
                              value: _vm.signDate,
                              callback: function ($$v) {
                                _vm.signDate = $$v
                              },
                              expression: "signDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { pull: 2 } },
                    [
                      _c("el-form-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.promotionMessage))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.promotionConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "556pro保修期提醒",
            visible: _vm.showPromoation2,
            "custom-class": "customerWidth",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPromoation2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", size: "small" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { push: 2 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购买/签收日期", prop: "signDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              align: "right",
                              placeholder: "请选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: { change: _vm.showPromoationMessage2 },
                            model: {
                              value: _vm.signDate2,
                              callback: function ($$v) {
                                _vm.signDate2 = $$v
                              },
                              expression: "signDate2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { pull: 2 } },
                    [
                      _c("el-form-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.promotionMessage2))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.WarrantyConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "571退货信息",
            visible: _vm.showOverseas,
            "custom-class": "customerWidth",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOverseas = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "overseasForm",
              attrs: {
                model: _vm.overseasSaleReturn,
                rules: _vm.overseasRules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品SN", prop: "serialNumber" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            on: { input: _vm.checkSnIn },
                            model: {
                              value: _vm.overseasSaleReturn.serialNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.overseasSaleReturn,
                                  "serialNumber",
                                  $$v
                                )
                              },
                              expression: "overseasSaleReturn.serialNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "国行SKU", prop: "skuCode" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.overseasSaleReturn.skuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSaleReturn, "skuCode", $$v)
                              },
                              expression: "overseasSaleReturn.skuCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "海外SKU", prop: "overseasSku" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.overseasSaleReturn.overseasSku,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.overseasSaleReturn,
                                  "overseasSku",
                                  $$v
                                )
                              },
                              expression: "overseasSaleReturn.overseasSku",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "销售国家", prop: "country" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.overseasSaleReturn.country,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.overseasSaleReturn,
                                    "country",
                                    $$v
                                  )
                                },
                                expression: "overseasSaleReturn.country",
                              },
                            },
                            _vm._l(_vm.countryOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "是否海外机", prop: "overseas" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.overseasSaleReturn.overseas,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.overseasSaleReturn,
                                    "overseas",
                                    $$v
                                  )
                                },
                                expression: "overseasSaleReturn.overseas",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: true },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "否", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "是否全球联保",
                            prop: "globalWarranty",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.overseasSaleReturn.globalWarranty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.overseasSaleReturn,
                                    "globalWarranty",
                                    $$v
                                  )
                                },
                                expression: "overseasSaleReturn.globalWarranty",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: true },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "否", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品购买日期", prop: "buyDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "购买日期",
                            },
                            model: {
                              value: _vm.overseasSaleReturn.buyDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSaleReturn, "buyDate", $$v)
                              },
                              expression: "overseasSaleReturn.buyDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "产品退货原因",
                            prop: "returnReason",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "medium" },
                              model: {
                                value: _vm.overseasSaleReturn.returnReason,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.overseasSaleReturn,
                                    "returnReason",
                                    $$v
                                  )
                                },
                                expression: "overseasSaleReturn.returnReason",
                              },
                            },
                            _vm._l(
                              _vm.returnReasonOptions,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { value: item.val, label: item.name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退款金额", prop: "amount" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.overseasSaleReturn.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.overseasSaleReturn, "amount", $$v)
                              },
                              expression: "overseasSaleReturn.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.cancelBtn },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_OVERSEAS_RETURN_SAVE"],
                              expression: "['ROLE_OVERSEAS_RETURN_SAVE']",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.btnLoading,
                              expression: "btnLoading",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.btnLoading,
                          },
                          on: { click: _vm.saveOverseasSaleReturn },
                        },
                        [_vm._v("确 定\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择VSE接入方式",
            visible: _vm.vseVisiable,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.vseVisiable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "vseForm", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block" },
                        attrs: {
                          "active-color": "#000000",
                          "inactive-color": "#000000",
                          "active-text": "立即转VSE",
                          "active-value": false,
                          "inactive-text": "预订VSE",
                          "inactive-value": true,
                        },
                        on: { change: _vm.showHint },
                        model: {
                          value: _vm.chooseValue,
                          callback: function ($$v) {
                            _vm.chooseValue = $$v
                          },
                          expression: "chooseValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.chooseValue
                ? _c(
                    "el-form-item",
                    { attrs: { label: "请选择日期" } },
                    [
                      _c("el-row", [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                align: "right",
                                type: "date",
                                size: "mini",
                                "value-format": "yyyy-MM-dd",
                                placeholder: "预约日期",
                                "picker-options": _vm.vsePickerOptions,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.initStocks(_vm.bookDate)
                                },
                              },
                              model: {
                                value: _vm.bookDate,
                                callback: function ($$v) {
                                  _vm.bookDate = $$v
                                },
                                expression: "bookDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.chooseValue
                ? _c(
                    "el-form-item",
                    { attrs: { label: "请选择时间段" } },
                    [
                      _c("el-row", [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "预约时间段",
                                },
                                model: {
                                  value: _vm.bookTime,
                                  callback: function ($$v) {
                                    _vm.bookTime = $$v
                                  },
                                  expression: "bookTime",
                                },
                              },
                              _vm._l(_vm.timePerList, function (item, index) {
                                return _c(
                                  "el-option",
                                  {
                                    key: index,
                                    attrs: {
                                      label: item.timeSlot,
                                      value: item.timeSlot,
                                      disabled: item.enableStock === 0,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "  " + _vm._s(item.timeSlot) + " "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [_vm._v("  " + _vm._s(item.enableStock))]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.btnLoading,
                    disabled: _vm.btnLoading,
                  },
                  on: { click: _vm.bookVSE },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.vseVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请填写不转VSE的原因",
            visible: _vm.showVSEDialog,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVSEDialog = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-col",
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.refuseNote,
                      callback: function ($$v) {
                        _vm.refuseNote = $$v
                      },
                      expression: "refuseNote",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_SAVEVSEINFO"],
                      expression: "['ROLE_CONSULT_ORDER_SAVEVSEINFO']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.submitVseRefuseNote },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "标签",
            visible: _vm.showLabel,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLabel = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", margin: "10px" } },
            [
              _c(
                "el-col",
                [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v("标签类型"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.queryLabel },
                      model: {
                        value: _vm.labelType,
                        callback: function ($$v) {
                          _vm.labelType = $$v
                        },
                        expression: "labelType",
                      },
                    },
                    _vm._l(_vm.labelTypeOptions, function (option) {
                      return _c("el-option", {
                        key: option.val,
                        attrs: { label: option.name, value: option.val },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", margin: "10px" } },
            [
              _c(
                "el-col",
                [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v("风险等级"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.queryLabel },
                      model: {
                        value: _vm.labelLevel,
                        callback: function ($$v) {
                          _vm.labelLevel = $$v
                        },
                        expression: "labelLevel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "30", label: "高风险" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "20", label: "中风险" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "10", label: "低风险" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", margin: "10px" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.labelList,
                        callback: function ($$v) {
                          _vm.labelList = $$v
                        },
                        expression: "labelList",
                      },
                    },
                    _vm._l(_vm.labels, function (label) {
                      return _c(
                        "el-checkbox",
                        {
                          key: label.id,
                          attrs: { value: label.id, label: label.id },
                        },
                        [_vm._v(_vm._s(label.name) + "\n          ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_SAVELABELS"],
                      expression: "['ROLE_CONSULT_ORDER_SAVELABELS']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveLabels },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showLabel = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "删除标签",
            visible: _vm.isDeleteLabel,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isDeleteLabel = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", margin: "10px" } },
            [_c("div", [_vm._v("是否确认删除标签？")])]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_CONSULT_ORDER_SAVELABELS"],
                      expression: "['ROLE_CONSULT_ORDER_SAVELABELS']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.confirmDeleteLabel },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isDeleteLabel = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }