var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _vm.transactionInfo.receivedFundState === "10"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_BILL_TRANSACTION_TEMP"],
                              expression: "['ROLE_BILL_TRANSACTION_TEMP']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.saveSumbit },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transactionInfo.receivedFundState === "10"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["ROLE_BILL_TRANSACTION_COMPLETE"],
                              expression: "['ROLE_BILL_TRANSACTION_COMPLETE']",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.completeSubmit },
                        },
                        [_vm._v("到账确认")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.transactionInfo,
                      rules: _vm.rules,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("业务信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "业务类别",
                                            prop: "busType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "业务类别",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.busTypeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "busTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.busTypeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "业务单号",
                                            prop: "invoiceNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "业务单号",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.orderNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "orderNum",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.orderNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "客户姓名",
                                            prop: "takeUser",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "客户姓名",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.takeUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "takeUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.takeUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "客户手机号",
                                            prop: "takeMobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "客户手机号",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.takeMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "takeMobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.takeMobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("到账信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "支付发起时间",
                                            prop: "notifyResponseDate",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "支付发起时间",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo
                                                  .notifyResponseDateExp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "notifyResponseDateExp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.notifyResponseDateExp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "支付金额",
                                            prop: "amount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "支付金额",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.amountExp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "amountExp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.amountExp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "付款人",
                                            prop: "payName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled:
                                                _vm.transactionInfo
                                                  .receivedFundState === "20",
                                              placeholder: "付款人",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo.payName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "payName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.payName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "银行流水号",
                                            prop: "sqbNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled:
                                                _vm.transactionInfo
                                                  .receivedFundState === "20",
                                              placeholder: "银行流水号",
                                            },
                                            model: {
                                              value: _vm.transactionInfo.sqbNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "sqbNum",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.sqbNum",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "到账日期",
                                            prop: "receivedFundDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled:
                                                _vm.transactionInfo
                                                  .receivedFundState === "20",
                                              type: "date",
                                              "picker-options":
                                                _vm.pickerOptions,
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "到账日期",
                                            },
                                            model: {
                                              value:
                                                _vm.transactionInfo
                                                  .receivedFundDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transactionInfo,
                                                  "receivedFundDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "transactionInfo.receivedFundDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }