"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDelete = batchDelete;
exports.createDispatch = createDispatch;
exports.deleteById = deleteById;
exports.deleteCategory = deleteCategory;
exports.getCateListByOrgId = getCateListByOrgId;
exports.getPageList = getPageList;
exports.saveData = saveData;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/baseDispatch/getPageList',
    method: 'post',
    params: query
  });
}

function saveData(data) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/save',
    method: 'post',
    data: data
  });
}

function deleteCategory(id) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/deleteCategory',
    method: 'post',
    params: {
      categoryId: id
    }
  });
}

function getCateListByOrgId(param) {
  return (0, _request.default)({
    url: '/bus_app/dispatch/getCateListByOrgId',
    method: 'post',
    params: {
      orgId: param.id,
      type: param.type
    }
  });
}

function createDispatch(orgId, categoryId, cityIds, isNationWide) {
  return (0, _request.default)({
    url: '/bus_app/baseDispatch/createDispatch',
    method: 'post',
    params: {
      orgId: orgId,
      categoryId: categoryId,
      cityIds: cityIds,
      isNationWide: isNationWide
    }
  });
}

function batchDelete(ids) {
  return (0, _request.default)({
    url: '/bus_app/baseDispatch/batchDelete',
    method: 'post',
    params: {
      ids: ids
    }
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/bus_app/baseDispatch/deleteById',
    method: 'post',
    params: {
      id: id
    }
  });
}