"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 机构块
 *
 * @type {{path: string, component, hidden: boolean, children: {path: string, component: (function(): (Promise<*>|*)), meta: {hidden: boolean, activeMenu: string, icon: string, title: string}, name: string}[]}}
 */
var organizationRouter = {
  path: '/sys/menu',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'org/:id',
    name: 'orgCreate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/organization/orgDetail'));
      });
    },
    meta: {
      title: '机构创建',
      hidden: true,
      activeMenu: '/sys/organization',
      parent: {
        path: '/sys',
        redirect: 'noRedirect',
        meta: {
          title: '账户机构',
          breadcrumb: true
        }
      }
    }
  }, {
    path: 'org/detail/:id',
    name: 'orgDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/organization/orgDetail'));
      });
    },
    meta: {
      title: '机构详细',
      hidden: true,
      activeMenu: '/sys/organization',
      parent: {
        path: '/sys',
        redirect: 'noRedirect',
        meta: {
          title: '账户机构',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = organizationRouter;
exports.default = _default;