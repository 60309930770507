"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalConfirm = approvalConfirm;
exports.getApproveByOrderNo = getApproveByOrderNo;
exports.getDuctionNameList = getDuctionNameList;
exports.getOrderCallByOrderNo = getOrderCallByOrderNo;
exports.getPool = getPool;
exports.getPoolRepair = getPoolRepair;
exports.getReductionList = getReductionList;
exports.listAll = listAll;
exports.listByNo = listByNo;
exports.listByOrderNo = listByOrderNo;
exports.updateSaleApprove = updateSaleApprove;
exports.updateSaleOrderPrice = updateSaleOrderPrice;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 查询分页
 * @param query
 */
function listAll(query) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/listAll',
    method: 'get',
    params: query
  });
}

function listByNo(orderNo) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/listByNo',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  });
}

function listByOrderNo(orderNo) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/listByOrderNo',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  });
}

function getOrderCallByOrderNo(orderNo) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/getOrderCallByOrderNo',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  });
}

function getApproveByOrderNo(orderNo) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/getApproveByOrderNo',
    method: 'post',
    params: {
      orderNo: orderNo
    }
  });
}

function getPool(year, month) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/getPool',
    method: 'post',
    params: {
      year: year,
      month: month
    }
  });
}

function approvalConfirm(data) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/updateApprove',
    method: 'post',
    data: data
  });
}

function updateSaleApprove(data) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/updateSaleApprove',
    method: 'post',
    data: data
  });
}

function updateSaleOrderPrice(data) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/updateSaleOrderPrice',
    method: 'post',
    data: data
  });
}

function getDuctionNameList(data) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/getDuctionNameList',
    method: 'post',
    data: data
  });
}

function getReductionList(query) {
  return (0, _request.default)({
    url: '/bus_app/quotaApprove/getReductionList',
    method: 'get',
    params: query
  });
}

function getPoolRepair(year, month) {
  return (0, _request.default)({
    url: '/bus_app/quota/getPoolRepair',
    method: 'post',
    params: {
      year: year,
      month: month
    }
  });
}