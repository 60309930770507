"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _uploadImgs = _interopRequireDefault(require("@/components/uploadImgs"));

var _uploadFileMsg = _interopRequireDefault(require("@/components/uploadFileMsg"));

var _checkPrice = _interopRequireDefault(require("@/components/order/checkPrice"));

var _quotaApprove = require("@/api/reduction/approve/quotaApprove");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { parseTime } from '@/utils'
// 附件图片上传
// 附件文件上传
// 费用明细
var _default = {
  name: 'approveDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    uploadImgs: _uploadImgs.default,
    uploadFileMsg: _uploadFileMsg.default,
    orderPrice: _checkPrice.default
  },
  filters: {},
  data: function data() {
    return {
      level: this.$route.params.level,
      back: this.$route.params.back,
      isAudit: false,
      resultOptions: _constant.default.quotaStates2,
      btnLoading: false,
      btnDisabled: false,
      orderNo: this.$route.params.id,
      orderInfo: {
        problemBefore: null
      },
      schemeList: [],
      orderCall: {},
      quotaApprove: {
        orderNo: this.$route.params.id
      },
      quotaPool: {
        amount: null
      },
      businessTypeOptions: _constant.default.businessTypeOptions,
      businessSubTypeOptions: _constant.default.businessSubTypeOptions,
      checkResultOptions: _constant.default.checkResultOptions,
      callResultOptions: _constant.default.callResultOptions,
      rules: {
        state: [{
          required: true,
          message: '审批结果是必选的',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    if (this.orderNo) {
      this.listByNo(this.orderNo);
      this.listByOrderNo(this.orderNo);
      this.getOrderCallByOrderNo(this.orderNo);
      this.getApproveByOrderNo(this.orderNo);
    }
  },
  methods: {
    // 设置图片上传
    setFilePaths: function setFilePaths(data) {
      this.quotaApprove.adjunctPicture = data;
    },
    // 设置文件上传
    setFilePathto: function setFilePathto(data) {
      this.quotaApprove.adjunctEmail = data;
    },
    setEmailNames: function setEmailNames(data) {
      this.quotaApprove.adjunctEmailName = data;
    },
    // 查询工单信息检测
    listByNo: function listByNo(orderNo) {
      var _this = this;

      (0, _quotaApprove.listByNo)(orderNo).then(function (response) {
        if (response.data) {
          _this.orderInfo = response.data;

          _this.getPool();

          _this.$refs.checkPrice.$emit('loadCheckInfo', _this.orderInfo.id);
        }
      });
    },
    // 查询问题信息列表
    listByOrderNo: function listByOrderNo(orderNo) {
      var _this2 = this;

      (0, _quotaApprove.listByOrderNo)(orderNo).then(function (response) {
        if (response.data) {
          var indexNum = 0;
          _this2.schemeList = response.data.map(function (v) {
            _this2.$set(v, 'indexNum', ++indexNum); // 动态加字段


            return v;
          });
        }
      });
    },
    // 查询沟通信息
    getOrderCallByOrderNo: function getOrderCallByOrderNo(orderNo) {
      var _this3 = this;

      (0, _quotaApprove.getOrderCallByOrderNo)(orderNo).then(function (response) {
        if (response.data) {
          _this3.orderCall = response.data;
        }
      });
    },
    // 查询审批详情
    getApproveByOrderNo: function getApproveByOrderNo(orderNo) {
      var _this4 = this;

      (0, _quotaApprove.getApproveByOrderNo)(orderNo).then(function (response) {
        if (response.data) {
          _this4.quotaApprove = response.data;
          _this4.quotaApprove.quotaPrice = _this4.quotaApprove.quotaPrice / 100;

          if (_this4.quotaApprove.state !== '0') {
            _this4.isAudit = true;
          } else {
            _this4.quotaApprove.state = null;
          }

          if (_this4.quotaApprove.reDate !== null) {
            var date = new Date(_this4.quotaApprove.reDate);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;

            _this4.getPool(year, month);
          }
        }
      });
    },
    // 查询当月额度信息
    getPool: function getPool(year, month) {
      var _this5 = this;

      if (year == null || month == null) {
        var date = new Date();
        year = date.getFullYear();
        month = date.getMonth() + 1;
      }

      if (this.orderInfo.checkType === '02') {
        (0, _quotaApprove.getPoolRepair)(year, month).then(function (response) {
          if (response.data) {
            _this5.quotaPool = response.data;
          }
        });
      } else {
        (0, _quotaApprove.getPool)(year, month).then(function (response) {
          if (response.data) {
            _this5.quotaPool = response.data;
          }
        });
      }
    },
    // 返回跳转过来的审批页面
    return2List: function return2List() {
      if (this.back !== undefined && this.back !== null && this.back !== '') {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveRecord'
        });
      }

      if (this.level === 1) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approve'
        });
      } else if (this.level === 2) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveStair'
        });
      } else if (this.level === 3) {
        this.$store.dispatch('tagsView/delView', this.$route);
        this.$router.push({
          path: '/reduction/approveTertiary'
        });
      }
    },
    // 提交审批结果，审批完成
    recheckConfirm: function recheckConfirm() {
      var _this6 = this;

      if (this.verifyApproval()) {
        this.$confirm('是否确认？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          type: 'warning',
          center: true
        }).then(function () {
          _this6.btnDisabled = true;

          _this6.recheckOrderSumbit();
        }).catch(function () {});
      } else {
        this.$message({
          showClose: true,
          message: '请选择审批结果',
          type: 'warning'
        });
      }
    },
    // 审批完成方法，审批完成跳转回到原来的审批页面
    recheckOrderSumbit: function recheckOrderSumbit() {
      var _this7 = this;

      this.btnLoading = true;
      this.quotaApprove.orderInfo = this.orderInfo;
      this.quotaApprove.quotaPrice = this.quotaApprove.quotaPrice * 100;
      (0, _quotaApprove.approvalConfirm)(this.quotaApprove).then(function (response) {
        if (response.data > 0) {
          _this7.$message({
            showClose: true,
            message: '审批完成',
            type: 'success'
          });

          _this7.return2List();
        } else {
          _this7.btnDisabled = false;

          _this7.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
      this.btnLoading = false;
    },
    verifyApproval: function verifyApproval() {
      for (var i = 0; i < this.orderInfo.orderSkuList.length; i++) {
        if (this.orderInfo.orderSkuList[i].applyFeeType === '06' && this.orderInfo.orderSkuList[i].approvalStatus !== '1' && this.orderInfo.orderSkuList[i].approvalStatus !== '2') {
          return false;
        }
      }

      if (this.orderInfo.quota !== null && this.orderInfo.quota !== '' && this.quotaApprove.state !== '1' && this.quotaApprove.state !== '2') {
        this.$refs['dataForms'].validate();
        return false;
      }

      return true;
    }
  }
};
exports.default = _default;