"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _return = require("@/api/sale/return");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

var _constant = _interopRequireDefault(require("@/api/sale/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
// 可拖拽弹出框
var _default = {
  name: 'orderSaleReturnCreate2',
  // eslint-disable-line
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default,
    permission: _index.default
  },
  data: function data() {
    return {
      isSf: false,
      id: this.$route.params.id,
      flag: this.$route.params.dialogStatus,
      isSearch: true,
      tempBtn: false,
      completeBtn: false,
      // 页面disabled 控制
      isDis: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible8: false,
      dialog8Msg: '',
      btnLoading: false,
      provinceOptions: [],
      cityOptions: [],
      countryOptions: [],
      payType: _constant.default.payType,
      loadingList4Model: false,
      // 退货商品 是否展示
      isList: true,
      rules: {},
      invoiceType: [{
        name: '纸质发票',
        val: '10'
      }, {
        name: '电子发票',
        val: '20'
      }],
      bookTimePerList: [{
        'val': '08:00~10:00',
        'name': '08:00~10:00'
      }, {
        'val': '10:00~12:00',
        'name': '10:00~12:00'
      }, {
        'val': '12:00~14:00',
        'name': '12:00~14:00'
      }, {
        'val': '14:00~16:00',
        'name': '14:00~16:00'
      }, {
        'val': '16:00~18:00',
        'name': '16:00~18:00'
      }, {
        'val': '18:00~20:00',
        'name': '18:00~20:00'
      }, {
        'val': '18:00~19:59',
        'name': '18:00~19:59'
      }],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      saleReturnInfo: {
        bookDate: '',
        bookClock: '',
        sendPhone: '',
        salePhone: '',
        sendUser: '',
        sendAddress: '',
        province: '',
        city: '',
        country: '',
        amount: 0,
        priceBefore: '0.00',
        urgent: '1',
        sendType: '1',
        expressMemo: '',
        items: [],
        items2: []
      },
      isCancel: false,
      saleInfo2: {
        cancelReason: '',
        cancelMemo: ''
      },
      cancelReason: [{
        label: '取消服务',
        value: '10'
      }, {
        label: '客服下错单',
        value: '20'
      }, {
        label: '时间过长',
        value: '30'
      }, {
        label: '测试订单',
        value: '40'
      }, {
        label: '价格调整',
        value: '50'
      }],
      rules2: {
        cancelReason: [{
          required: true,
          message: '请选择取消原因',
          trigger: 'change'
        }]
      },
      multipleSelection: [],
      reasonList: [{
        label: '七天无理由',
        value: '01'
      }, {
        label: '发货慢',
        value: '02'
      }, {
        label: '拍错/拍多',
        value: '03'
      }, {
        label: '配件不适用',
        value: '04'
      }, {
        label: '无库存',
        value: '05'
      }, {
        label: '质量问题',
        value: '06'
      }, {
        label: '外观/包装',
        value: '07'
      }, {
        label: '其他',
        value: '08'
      }]
    };
  },
  created: function created() {
    this.getProvinceOptions();

    if (this.id) {
      this.getSaleReturnInfo();
    }
  },
  methods: {
    checkboxInit: function checkboxInit(row) {
      if (this.isDis === false) {
        if (row.isOver === '1') {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    },
    handleSelectionChange: function handleSelectionChange(val, rows) {
      var _this = this;

      var selected = val.length && val.indexOf(rows) !== -1;
      this.$refs['dataForm'].clearValidate();

      if (selected) {
        this.multipleSelection = [];

        if (this.saleReturnInfo.items !== null && this.saleReturnInfo.items.length > 0) {
          this.saleReturnInfo.items.forEach(function (item, index) {
            if (item.saleNo === val[0].saleNo) {
              item.isDisabled = false;
              item.reduceNum = '0';

              _this.$refs.multipleTable.toggleRowSelection(_this.saleReturnInfo.items[index], true);

              _this.multipleSelection.push(item);
            } else {
              item.isDisabled = true;
              item.reason = '';
              item.memo = '';
              item.reduceNum = '';

              _this.$refs.multipleTable.toggleRowSelection(_this.saleReturnInfo.items[index], false);
            }
          });
        }
      } else {
        this.saleReturnInfo.items.forEach(function (item, index) {
          if (item.saleNo === rows.saleNo && item.skuCode === rows.skuCode) {
            item.isDisabled = true;
            item.reason = '';
            item.memo = '';
            item.reduceNum = ''; // this.$refs.multipleTable.toggleRowSelection(item, false)

            _this.multipleSelection.some(function (item2, i) {
              if (item2.saleNo === rows.saleNo && item2.skuCode === rows.skuCode) {
                _this.multipleSelection.splice(i, 1);
              }
            });
          }
        });
      }
    },
    // 查询条件清空
    clearSearch: function clearSearch() {
      this.isSearch = false;
      this.saleReturnInfo.salePhone = '';
      this.saleReturnInfo.items = [];
      this.$refs['dataForm'].clearValidate(['salePhone']);
      this.multipleSelection = [];
    },
    phoneSearch: function phoneSearch() {
      var _this2 = this;

      (0, _return.phoneQuery)({
        phone: this.saleReturnInfo.salePhone,
        id: this.id,
        state: 'S2'
      }).then(function (response) {
        if (response.data) {
          _this2.saleReturnInfo.items = response.data;

          if (!_this2.saleReturnInfo.items || _this2.saleReturnInfo.items.length === 0) {
            _this2.isList = true;
          } else {
            _this2.isList = false;
          }

          if (_this2.saleReturnInfo.items !== null && _this2.saleReturnInfo.items.length > 0) {
            _this2.saleReturnInfo.items.forEach(function (item, index) {
              if (item.saleNo === _this2.saleReturnInfo.saleNo && item.reason !== null && item.reason !== undefined && item.reason !== '') {
                item.isDisabled = false;

                if (_this2.saleReturnInfo.stateChild === '1' || _this2.saleReturnInfo.stateChild === '2') {
                  _this2.$nextTick(function () {
                    this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], true);
                  });
                }

                _this2.multipleSelection.push(item);
              } else {
                item.isDisabled = true;

                _this2.$refs.multipleTable.toggleRowSelection(_this2.saleReturnInfo.items[index], false);
              }
            });

            if (!(_this2.saleReturnInfo.stateChild === '1' || _this2.saleReturnInfo.stateChild === '2')) {
              _this2.saleReturnInfo.items = _this2.multipleSelection;
            }
          }
        }
      });
      this.isSearch = true;
    },
    getProvinceOptions: function getProvinceOptions() {
      var _this3 = this;

      (0, _select.getAreasByParentId)('0').then(function (response) {
        _this3.provinceOptions = response.data;
      });
    },
    proviceChange: function proviceChange(province) {
      var _this4 = this;

      if (province) {
        (0, _select.getAreasByParentId)(province).then(function (response) {
          _this4.cityOptions = response.data;
        });
      }

      this.saleReturnInfo.cityId = '';
      this.saleReturnInfo.districtId = '';
    },
    cityChange: function cityChange(city) {
      var _this5 = this;

      if (city) {
        (0, _select.getAreasByParentId)(city).then(function (response) {
          _this5.countryOptions = response.data;
        });
        (0, _return.getCityLastTime)(city).then(function (response) {
          if (response !== null && response.code === 200) {
            _this5.bookTimePerList = [];
            _this5.saleReturnInfo.bookClock = '';
            _this5.bookTimePerList = response.data;
          }
        });
      }

      this.saleReturnInfo.districtId = '';
    },
    sendTypeClick: function sendTypeClick() {
      var _this6 = this;

      if (this.saleReturnInfo.sendType === '1') {
        this.isSf = false;
      } else {
        this.isSf = true;
        this.saleReturnInfo.bookDate = '';
        this.saleReturnInfo.bookClock = '';
        this.saleReturnInfo.expressMemo = '';
        this.$nextTick(function () {
          _this6.$refs['dataForm'].clearValidate();
        });
      }
    },
    numChange: function numChange() {
      var a = 0;
      var b = 0;
      this.saleReturnInfo.items.forEach(function (it) {
        if (it.reduceNum === '' || it.reduceNum === undefined || it.reduceNum === null) {
          a = parseInt(a) + 0;
          b = Number(b) + 0;
        } else {
          a = parseInt(a) + parseInt(it.reduceNum);
          b = Number(b) + Number(it.priceBeforeY) * parseInt(it.reduceNum);
        }
      });
      this.saleReturnInfo.amount = a;
      this.saleReturnInfo.priceBefore = b;
    },
    // 获取详细
    getSaleReturnInfo: function getSaleReturnInfo() {
      var _this7 = this;

      var param = {
        id: this.id
      };

      if (this.flag === 'create' || this.flag === 'query' || this.flag === 'cancel') {
        param = {
          consultId: this.id
        };
      }

      (0, _return.queryById)(param).then(function (response) {
        if (response.data) {
          _this7.saleReturnInfo = response.data;
          _this7.saleReturnInfo.priceBefore = _this7.saleReturnInfo.priceBeforeY;
          _this7.isList = true;

          if (_this7.saleReturnInfo.stateChild === '1' || _this7.saleReturnInfo.stateChild === '2') {
            _this7.isSearch = true;
            _this7.isDis = false;
          } else {
            _this7.isSearch = false;
            _this7.isDis = true;
          }

          _this7.saleReturnInfo.items = response.data.items;

          if (_this7.flag === 'update' || _this7.flag === 'query') {
            if (!_this7.saleReturnInfo.items || _this7.saleReturnInfo.items.length === 0) {
              _this7.isList = true;
            } else {
              _this7.isList = false;
            }

            _this7.isSearch = true;
          } else {
            _this7.isSearch = false;
          }

          if (_this7.saleReturnInfo.sendType === '1') {
            _this7.isSf = false;
          } else {
            _this7.isSf = true;
          }

          if (_this7.flag === 'cancel' && (_this7.saleReturnInfo.state === 'S1' || _this7.saleReturnInfo.state === '') && (_this7.saleReturnInfo.stateChild === '1' || _this7.saleReturnInfo.stateChild === '2' || _this7.saleReturnInfo.stateChild === '4' || _this7.saleReturnInfo.stateChild === '41' || _this7.saleReturnInfo.stateChild === '43' || _this7.saleReturnInfo.stateChild === '44')) {
            _this7.isCancel = true;
            _this7.isDis = true;
            _this7.isSearch = true;

            if (!_this7.saleReturnInfo.items || _this7.saleReturnInfo.items.length === 0) {
              _this7.isList = true;
            } else {
              _this7.isList = false;
            }
          }

          if (_this7.saleReturnInfo.items !== null && _this7.saleReturnInfo.items.length > 0) {
            _this7.saleReturnInfo.items.forEach(function (item, index) {
              if (item.saleNo === _this7.saleReturnInfo.saleNo && item.reason !== null && item.reason !== undefined && item.reason !== '') {
                item.isDisabled = false;

                if (_this7.saleReturnInfo.stateChild === '1' || _this7.saleReturnInfo.stateChild === '2') {
                  _this7.$nextTick(function () {
                    this.$refs.multipleTable.toggleRowSelection(this.saleReturnInfo.items[index], true);
                  });
                }

                _this7.multipleSelection.push(item);
              } else {
                item.isDisabled = true;

                _this7.$refs.multipleTable.toggleRowSelection(_this7.saleReturnInfo.items[index], false);
              }
            });

            if (!(_this7.saleReturnInfo.stateChild === '1' || _this7.saleReturnInfo.stateChild === '2')) {
              _this7.saleReturnInfo.items = _this7.multipleSelection;
            }
          }

          if (_this7.saleReturnInfo.province != null && _this7.saleReturnInfo.province !== '') {
            (0, _select.getAreasByParentId)(_this7.saleReturnInfo.province).then(function (response) {
              _this7.cityOptions = response.data;
            });
          } // 区/县  回显


          if (_this7.saleReturnInfo.city != null && _this7.saleReturnInfo.city !== '') {
            (0, _select.getAreasByParentId)(_this7.saleReturnInfo.city).then(function (response) {
              _this7.countryOptions = response.data;
            });
          }
        }
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this8 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this8.saleReturnInfo.items === null || _this8.saleReturnInfo.items.length < 1) {
            _this8.$message({
              showClose: true,
              message: '请查询手机号后 添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this8.multipleSelection === null || _this8.multipleSelection.length < 1) {
            _this8.$message({
              showClose: true,
              message: '请添加退货商品！',
              type: 'warning'
            });

            return;
          }

          _this8.saleReturnInfo.items2 = _this8.multipleSelection;
          (0, _return.saveSaleReturn)(_this8.saleReturnInfo).then(function (response) {
            if (response.data) {
              _this8.$message({
                showClose: true,
                message: response.msg,
                type: 'success'
              });

              _this8.$store.dispatch('tagsView/delView', _this8.$route);

              _this8.$router.push({
                path: '/sale/return'
              });
            } else {
              _this8.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    completeSubmit: function completeSubmit() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this9.saleReturnInfo.items === null || _this9.saleReturnInfo.items.length < 1) {
            _this9.$message({
              showClose: true,
              message: '请查询手机号后 添加退货商品！',
              type: 'warning'
            });

            return;
          }

          if (_this9.multipleSelection === null || _this9.multipleSelection.length < 1) {
            _this9.$message({
              showClose: true,
              message: '请添加退货商品！',
              type: 'warning'
            });

            return;
          }

          _this9.dialogVisible = true;
        }
      });
    },
    comfirmToOrder: function comfirmToOrder() {
      var _this10 = this;

      this.completeBtn = true;
      this.saleReturnInfo.items2 = this.multipleSelection;
      (0, _return.complete2)(this.saleReturnInfo).then(function (response) {
        if (response.data) {
          _this10.$message({
            showClose: true,
            message: response.msg,
            type: 'success'
          });

          _this10.$store.dispatch('tagsView/delView', _this10.$route);

          _this10.$router.push({
            path: '/sale/return'
          });
        } else {
          _this10.completeBtn = false;

          _this10.$message({
            showClose: true,
            message: response.msg,
            type: 'warning'
          });
        }
      });
    },
    updateCancel: function updateCancel() {
      this.dialogVisible2 = true;
    },
    comfirmB: function comfirmB() {
      var _this11 = this;

      this.$refs['dataForm2'].validate(function (valid) {
        _this11.saleReturnInfo.cancelReason = _this11.saleInfo2.cancelReason;
        _this11.saleReturnInfo.cancelMemo = _this11.saleInfo2.cancelMemo;
        (0, _return.updateSaleReturnCancel)(_this11.saleReturnInfo).then(function (response) {
          if (response.data) {
            _this11.dialogVisible8 = true;
            _this11.dialog8Msg = response.msg;
            _this11.dialogVisible2 = false; // this.$message({ showClose: true, message: response.msg, type: 'success' })
            // this.$store.dispatch('tagsView/delView', this.$route)
            // this.$router.push({ name: 'consultOrderDetail', params: { id: this.$route.params.callId, isQuery: '0' }})
          } else {
            _this11.$message({
              showClose: true,
              message: response.msg,
              type: 'warning'
            });
          }
        });
      });
    },
    comfirmTo: function comfirmTo() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'consultOrderDetail',
        params: {
          id: this.$route.params.callId,
          isQuery: '0'
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);

      if (this.flag === 'cancel') {
        this.$router.push({
          name: 'consultOrderDetail',
          params: {
            id: this.$route.params.callId,
            isQuery: '0'
          }
        });
      } else {
        this.$router.push({
          path: '/sale/return'
        });
      }
    },
    remainderNumFormat: function remainderNumFormat(row) {
      if (row.isOver === '1') {
        return '0';
      } else {
        return row.remainderNum;
      }
    },
    isOverFormat: function isOverFormat(row) {
      if (row.isOver !== null && row.isOver !== '' && row.isOver !== undefined) {
        if (row.isOver === '1') {
          return '是';
        } else {
          return '否';
        }
      } else {
        return '否';
      }
    },
    payTypeFormat: function payTypeFormat(row) {
      return this.payType.map(function (type) {
        if (row.payType.trim() === type.value) {
          return type.label;
        }

        return '';
      });
    },
    billTypeFormat: function billTypeFormat(row) {
      if (row.billType === '' || row.billType === null || row.billType === undefined) {
        return '纸质发票';
      }

      return this.invoiceType.map(function (type) {
        if (row.billType.trim() === type.val) {
          return type.name;
        }
      });
    },
    stateFormat: function stateFormat(row) {
      if (row.reason === null || row.reason === undefined) {
        return '';
      }

      return this.reasonList.map(function (type) {
        if (row.reason.trim() === type.value) {
          return type.label;
        }

        return '';
      });
    }
  }
};
exports.default = _default;