"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ElTreeSelect',
  props: {
    // 配置项
    props: {
      type: Object,
      default: function _default() {
        return {
          key: undefined,
          // ID字段名
          value: 'id',
          // 显示名称
          label: 'name',
          // 子级字段名
          children: 'children',
          // 是否可选
          disabled: 'disabled'
        };
      }
    },
    // 选项列表数据(树形结构的对象数组)
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 初始值
    value: {
      type: String,
      default: function _default() {
        return null;
      }
    },
    // 可清空选项
    clearable: {
      type: Boolean,
      default: true
    },
    // 自动收起
    accordion: {
      type: Boolean,
      default: false
    },
    // 启用状态
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function _default() {
        return '请选择';
      }
    },
    filterTips: {
      type: String,
      default: function _default() {
        return '检索关键字';
      }
    }
  },
  data: function data() {
    return {
      filterText: '',
      valueId: this.value,
      valueTitle: '',
      defaultExpandedKey: []
    };
  },
  watch: {
    value: function value() {
      this.valueId = this.value;
      this.initHandle();
    },
    filterText: function filterText(val) {
      this.$refs.selectTree.filter(val);
    }
  },
  mounted: function mounted() {
    // 初始值
    this.valueId = this.value;
    this.initHandle();
  },
  methods: {
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 初始化值
    initHandle: function initHandle() {
      if (this.valueId) {
        // 初始化显示
        this.valueTitle = this.$refs.selectTree.getNode(this.valueId).data[this.props.label]; // 设置默认选中

        this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认展开

        this.defaultExpandedKey = [this.valueId];
      } else {
        this.valueTitle = '';
        this.valueId = null;
        this.defaultExpandedKey = [];
        this.clearSelected();
        this.$emit('getValue', null);
        this.$refs.selectTree.setCheckedKeys([]);
      }

      this.initScroll();
    },
    // 初始化滚动条
    initScroll: function initScroll() {
      this.$nextTick(function () {
        var scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0];
        var scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar');
        scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;';
        scrollBar.forEach(function (ele) {
          ele.style.width = 0;
        });
      });
    },
    // 切换选项
    // handleNodeClick(node) {
    //   const ds = node[this.props.disabled]
    //   if (!ds) {
    //     this.valueTitle = node[this.props.label]
    //     this.valueId = node[this.props.value]
    //     this.$emit('getValue', this.valueId)
    //     this.$emit('getNode', node)
    //     this.defaultExpandedKey = []
    //   }
    // },
    checkFn: function checkFn(node) {
      // console.log( node, data)
      // const checkedKeys = data.checkedKeys
      // console.log(node)
      var currKey = node.id;
      this.$refs.selectTree.setCheckedKeys([currKey]);
      this.valueTitle = node[this.props.label];
      this.valueId = node[this.props.value];
      this.$emit('getValue', this.valueId);
      this.$emit('getNode', node);
      this.$emit('checkChange', node);
      this.defaultExpandedKey = [];
    },
    // 清除选中
    clearHandle: function clearHandle() {
      this.valueTitle = '';
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit('getValue', null);
    },
    // 清空选中样式
    clearSelected: function clearSelected() {
      var allNode = document.querySelectorAll('#tree-option .el-tree-node');
      allNode.forEach(function (element) {
        return element.classList.remove('is-current');
      });
    }
  }
};
exports.default = _default2;