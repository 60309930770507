"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _invoice = require("@/api/bill/invoice");

var _fail = require("@/api/bill/fail");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'failDetail',
  // eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      billInvoiceInfo: {},
      loadingList4Model: false,
      isShow: false,
      invoiceType: [{
        name: '纸质发票',
        val: '10'
      }, {
        name: '电子发票',
        val: '20'
      }],
      custType: [{
        name: '个人',
        val: '10'
      }, {
        name: '公司',
        val: '20'
      }]
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryById)(param).then(function (response) {
        if (response.data) {
          _this.billInvoiceInfo = response.data;
          _this.billInvoiceInfo.custName = _this.billInvoiceInfo.custNamePage;
          _this.billInvoiceInfo.custTax = _this.billInvoiceInfo.custTaxPage;
          _this.billInvoiceInfo.custAddress = _this.billInvoiceInfo.custAddressPage;
          _this.billInvoiceInfo.custPhone = _this.billInvoiceInfo.custPhonePage;
          _this.billInvoiceInfo.custBank = _this.billInvoiceInfo.custBankPage;
          _this.billInvoiceInfo.custBankAcc = _this.billInvoiceInfo.custBankAccPage;
          _this.billInvoiceInfo.takeUser = _this.billInvoiceInfo.takeUserPage;
          _this.billInvoiceInfo.takeMobile = _this.billInvoiceInfo.takeMobilePage;
          _this.billInvoiceInfo.takeAddress = _this.billInvoiceInfo.takeAddressPage;
          _this.billInvoiceInfo.custEmail = _this.billInvoiceInfo.custEmailPage;

          if (_this.billInvoiceInfo.invoiceType === '10') {
            _this.isShow = true;
          }
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/fail'
      });
    },
    submitBtn: function submitBtn() {
      var _this2 = this;

      var pattern = /^([a-z0-9_-]+[.]?[a-z0-9_-]+)+@[a-z0-9_-]+(\.[a-z0-9_-]{2,})+$/i; // let pattern = /^([a-z0-9]+[.]?[a-z0-9]+)*@([a-z0-9]+\.)+([a-z0-9]+[.]?[a-z0-9]+){1,14}$/i

      var valid = pattern.test(this.billInvoiceInfo.custEmail);

      if (!valid) {
        this.$message.warning('邮箱格式有误！');
        return;
      }

      (0, _fail.update)(this.billInvoiceInfo).then(function (response) {
        if (response.data) {
          _this2.$message({
            message: '操作成功',
            type: 'success'
          });

          _this2.$store.dispatch('tagsView/delView', _this2.$route);

          _this2.$router.push({
            path: '/bill/fail'
          });
        } else {
          _this2.$message({
            showClose: true,
            message: response.msg || '操作失败',
            type: 'warning'
          });
        }
      });
    }
  }
};
exports.default = _default;