"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _product = require("@/api/register/product");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _constant = _interopRequireDefault(require("@/api/register/constant"));

var _fcFree = require("@/api/register/fcFree");

var _constant2 = _interopRequireDefault(require("@/api/order/constant"));

var _filterUtils = require("@/api/order/filterUtils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ProductDetail',
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  filters: {
    countryFilter: _filterUtils.countryFilter
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      productInfo: {},
      btnLoading: false,
      listLoading: false,
      prodcutHistoryList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      assetTypeOptions: _constant.default.assetTypeOptions,
      assetStatusOptions: _constant.default.assetStatusOptions,
      rightList: [],
      typeOptions: [{
        'name': '免费清洁服务',
        'val': '1'
      }],
      countryOptions: _constant2.default.countryOptions
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _product.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.productInfo = response.data;

          if (_this.productInfo.id) {
            _this.listQuery.parentId = _this.productInfo.id;

            _this.getHistoryList();

            _this.getRightList(_this.productInfo.serialNumber);
          }
        }
      });
    },
    // 获取明细
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _product.getHistoryList)(this.listQuery).then(function (response) {
        _this2.prodcutHistoryList = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    getRightList: function getRightList(sn) {
      var _this3 = this;

      this.listLoading = true;
      (0, _fcFree.getRightList)(sn).then(function (response) {
        _this3.rightList = response.data;
        _this3.listLoading = false;
      });
    },
    unlock: function unlock() {
      var _this4 = this;

      (0, _product.unlock)(this.productInfo.serialNumber).then(function (response) {
        if (response.data) {
          _this4.getDetail();

          _this4.$message({
            message: '操作成功',
            type: 'success'
          });
        } else {
          _this4.$message({
            message: '操作失败',
            type: 'warning'
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/register/product'
      });
    },
    gotoEditHtml: function gotoEditHtml(row) {
      this.$router.push({
        name: 'queryOrderDetail',
        params: {
          id: row.orderNum
        }
      });
    },
    // 业务类型格式化
    typeFormat: function typeFormat(row) {
      return this.typeOptions.map(function (type) {
        if (row.rightType === type.val) {
          return type.name;
        }

        return '';
      });
    },
    isEnjoyFormat: function isEnjoyFormat(row) {
      return row.isEnjoy === '1' ? '是' : '否';
    },
    isUsedFormat: function isUsedFormat(row) {
      return row.isUsed === '1' ? '是' : '否';
    }
  }
};
exports.default = _default;