"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/directive/permission/index"));

var _list = require("@/api/basics/cityTime/list");

var _select = require("@/api/common/select");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 按钮权限控制
// waves directive
// 可拖拽弹出框
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  components: {},
  directives: {
    waves: _waves.default,
    elDragDialog: _elDragDialog.default,
    permission: _index.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'YES',
        0: 'NO'
      };
      return statusMap[status];
    },
    statusNameFilter: function statusNameFilter(status) {
      var statusMap = {
        1: '启用',
        0: '禁用'
      };
      return statusMap[status];
    },
    tagStatusFilter: function tagStatusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      // this.$route.params.id,
      id: this.$route.params.id,
      isCreate: this.$route.params.id === 'create',
      // 入力项目 + 验证规则
      validateForm: {
        id: '',
        provinceId: '',
        cityId: '',
        time: ''
      },
      provinceSelect: [],
      // 省下拉
      citySelect: [],
      // 市下拉
      rules: {
        provinceId: [{
          required: true,
          message: '省份必须填',
          trigger: 'blur'
        }],
        cityId: [{
          required: true,
          message: '城市必填',
          trigger: 'blur'
        }],
        time: [{
          required: true,
          message: '最晚取件时间必须填',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  activated: function activated() {
    this.getProvinceOptions();

    if (!this.isCreate) {
      this.queryById();
    } // console.log('activated')

  },
  deactivated: function deactivated() {// console.log('deactivated')
  },
  created: function created() {
    this.getProvinceOptions();

    if (!this.isCreate) {
      this.queryById();
    }
  },
  methods: {
    // 初始化省份
    getProvinceOptions: function getProvinceOptions() {
      var _this = this;

      (0, _select.getAreasByParentId)('0').then(function (res) {
        _this.provinceSelect = res.data;
      });
    },
    // 寄件人 城市
    proviceChange: function proviceChange() {
      var _this2 = this;

      (0, _select.getAreasByParentId)(this.validateForm.provinceId).then(function (response) {
        _this2.validateForm.cityId = '';
        _this2.citySelect = response.data;
      });
    },
    // 初始查后台 拿取 仓库信息
    queryById: function queryById() {
      var _this3 = this;

      // console.log(this.id)
      (0, _list.queryById)(this.id).then(function (response) {
        console.log(response);

        if (response.data) {
          // console.log(response.data)
          _this3.validateForm = response.data;
          (0, _select.getAreasByParentId)(_this3.validateForm.provinceId).then(function (response) {
            _this3.citySelect = response.data;
          });

          _this3.$nextTick(function () {
            _this3.$refs['dataForm'].clearValidate();
          });
        }
      });
    },
    // 保存确认
    saveSumbit: function saveSumbit() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // console.log(this.validateForm)
          (0, _list.saveData)(_this4.validateForm).then(function (response) {
            if (response.data) {
              _this4.$message({
                showClose: true,
                message: '新增成功！',
                type: 'success'
              });

              _this4.$store.dispatch('tagsView/delView', _this4.$route);

              _this4.$router.push({
                path: '/basics/cityTime'
              });
            } else {
              _this4.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 编辑确认
    updateSubmit: function updateSubmit() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _list.saveData)(_this5.validateForm).then(function (response) {
            if (response.data) {
              _this5.$message({
                showClose: true,
                message: ' 修改成功！',
                type: 'success'
              });

              _this5.$store.dispatch('tagsView/delView', _this5.$route);

              _this5.$router.push({
                path: '/basics/cityTime'
              });
            } else {
              _this5.$message({
                showClose: true,
                message: response.msg,
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/basics/cityTime'
      });
    }
  }
};
exports.default = _default;