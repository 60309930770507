"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.complete = complete;
exports.complete2 = complete2;
exports.fetchList = fetchList;
exports.getCityLastTime = getCityLastTime;
exports.phoneQuery = phoneQuery;
exports.qiyuComplete = qiyuComplete;
exports.qiyuPhoneQuery = qiyuPhoneQuery;
exports.queryById = queryById;
exports.queryByNum = queryByNum;
exports.queryQiyuReturnById = queryQiyuReturnById;
exports.saveQiyuSaleReturn = saveQiyuSaleReturn;
exports.saveSaleReturn = saveSaleReturn;
exports.updateSaleReturnCancel = updateSaleReturnCancel;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Author: Lee
 * @Date: 2020-12-18 18:14:26
 * @LastEditTime: 2020-12-21 15:19:50
 * @Description:
 */

/** 保存工单*/
function saveSaleReturn(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/saveSaleReturn',
    method: 'post',
    data: data
  });
}

function fetchList(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/list',
    method: 'post',
    params: query
  });
}

function queryById(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/queryById',
    method: 'post',
    params: query
  });
}

function complete(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/complete',
    method: 'post',
    data: data
  });
}

function complete2(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/complete2',
    method: 'post',
    data: data
  });
}

function phoneQuery(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/phoneQuery',
    method: 'post',
    params: query
  });
}

function getCityLastTime(id) {
  return (0, _request.default)({
    url: '/bus_app/consult_order/getCityLastTime',
    method: 'post',
    params: {
      cityId: id
    }
  });
}

function updateSaleReturnCancel(data) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/updateSaleReturnCancel',
    method: 'post',
    data: data
  });
}

function queryByNum(query) {
  return (0, _request.default)({
    url: '/bus_app/sale/return/queryByNum',
    method: 'post',
    params: query
  });
}
/** 保存工单*/


function saveQiyuSaleReturn(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale_return/saveSaleReturn',
    method: 'post',
    data: data
  });
}

function queryQiyuReturnById(query) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale_return/queryById',
    method: 'post',
    params: query
  });
}

function qiyuPhoneQuery(query) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale_return/phoneQuery',
    method: 'post',
    params: query
  });
}

function qiyuComplete(data) {
  return (0, _request.default)({
    url: '/bus_app/qiyu_sale_return/complete',
    method: 'post',
    data: data
  });
}