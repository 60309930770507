"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _list = require("@/api/consult/gdpr/list");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'gdprTaskDetail',
  // eslint-disable-line
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  directives: {},
  data: function data() {
    return {
      id: this.$route.params.id,
      gdprTaskInfo: {},
      listLoading: false,
      historyList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      GDPRStates: [{
        'name': '待处理',
        'val': '0'
      }, {
        'name': '已下发',
        'val': '1'
      }, {
        'name': '已处理',
        'val': '2'
      }],
      GDPRTypes: [{
        'name': '下载部分数据',
        'val': '1'
      }, {
        'name': '下载全部数据',
        'val': '2'
      }, {
        'name': '取消部分订阅',
        'val': '3'
      }, {
        'name': '取消全部订阅',
        'val': '4'
      }, {
        'name': '纠正机主信息',
        'val': '5'
      }, {
        'name': '删除个人信息【手动删除】',
        'val': '6'
      }]
    };
  },
  created: function created() {
    this.getDetail();
  },
  activated: function activated() {
    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _list.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.gdprTaskInfo = response.data;

          if (_this.gdprTaskInfo.id) {
            _this.listQuery.parentId = _this.gdprTaskInfo.id;

            _this.getHistoryList();
          }
        }
      });
    },
    // 获取明细
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _list.getHistoryList)(this.listQuery).then(function (response) {
        _this2.historyList = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/consult/gdpr_task'
      });
    },
    // 更变状态
    handleState: function handleState(state) {
      var _this3 = this;

      (0, _list.handleState)(state, this.gdprTaskInfo.id).then(function (res) {
        if (res && res.code === 200) {
          _this3.$message.success('操作成功');

          _this3.getDetail();
        } else {
          _this3.$message.error(res.msg || '操作失败');
        }
      });
    },
    // 状态
    stateFormat: function stateFormat(row) {
      return this.GDPRStates.map(function (type) {
        if (row.state === type.val) {
          return type.name;
        }

        return '';
      });
    }
  }
};
exports.default = _default;