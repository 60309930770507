"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/order/constant"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    orderInfo: {
      type: Object,
      default: null
    },
    enableModify: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      linkTypeOptions: _constant.default.linkTypeOptions // 邮寄方式

    };
  },
  created: function created() {},
  methods: {
    // 点击详情
    toRiskDetail: function toRiskDetail(id) {
      this.$router.push({
        name: 'createRisk',
        params: {
          id: id
        }
      });
    }
  }
};
exports.default = _default;