var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-row",
      staticStyle: {
        "margin-left": "20px",
        "margin-right": "20px",
        "margin-top": "5px",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { border: "none" },
          attrs: { shadow: "never", "body-style": { padding: "0px" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              staticStyle: { "margin-left": "-20px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("　")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "-12px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_CALLOUT_SALE_COMMIT"],
                          expression: "['ROLE_CALLOUT_SALE_COMMIT']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.completeSubmit },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.returnBtn } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              { staticClass: "el-col el-col-24" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.saleInfo,
                      rules: _vm.rules,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("基本信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "销售单号",
                                            prop: "num",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "销售单号",
                                            },
                                            model: {
                                              value: _vm.saleInfo.num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "num",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.num",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "外呼类型",
                                            prop: "callType",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "外呼类型",
                                            },
                                            model: {
                                              value: _vm.saleInfo.callType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "callType",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.callType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "创建时间",
                                            prop: "inDateFM",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "创建时间",
                                            },
                                            model: {
                                              value: _vm.saleInfo.inDateFM,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "inDateFM",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.inDateFM",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("客户信息")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件人",
                                            prop: "takeUser",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "收件人",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takeUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeUser",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takeUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "收件人电话",
                                            prop: "takePhone",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "收件手机号",
                                            },
                                            model: {
                                              value: _vm.saleInfo.takePhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takePhone",
                                                  $$v
                                                )
                                              },
                                              expression: "saleInfo.takePhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "详细地址",
                                            prop: "takeAddressDetail",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              disabled: true,
                                              placeholder: "详细地址",
                                            },
                                            model: {
                                              value:
                                                _vm.saleInfo.takeAddressDetail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "takeAddressDetail",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.takeAddressDetail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("销售商品")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 10 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loadingList4Model,
                                            expression: "loadingList4Model",
                                          },
                                        ],
                                        ref: "multipleTable",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          border: "",
                                          fit: "",
                                          stripe: "",
                                          "header-cell-style": {
                                            background: "#eef1f6",
                                            color: "#606266",
                                          },
                                          "tooltip-effect": "dark",
                                          data: _vm.saleInfo.items,
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "skuCode",
                                            "min-width": "120px",
                                            label: "物料编码",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "skuName",
                                            "min-width": "180px",
                                            label: "物料名称",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "categoryName",
                                            "min-width": "180px",
                                            label: "品类",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "modelName",
                                            "min-width": "180px",
                                            label: "型号",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "num",
                                            "min-width": "180px",
                                            label: "数量",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            prop: "priceBeforeY",
                                            label: "含税单价",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 11 } }, [
                                  _vm._v(" \n                "),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-form-item", {
                                      attrs: { label: "本单货品总数:" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    _c("el-tag", [
                                      _vm._v(
                                        _vm._s(_vm.saleInfo.amount) + "个"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-form-item", {
                                      attrs: { label: "含税总价:" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c("el-tag", [
                                      _vm._v(
                                        _vm._s(_vm.saleInfo.priceBefore) + " 元"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover", hidden: _vm.part3 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("审批信息")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "padding-top": "20px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 22 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "审批结果",
                                              prop: "audit",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: _vm.quotaApprove.state,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quotaApprove,
                                                      "state",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quotaApprove.state",
                                                },
                                              },
                                              _vm._l(
                                                _vm.resultOptions,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.val,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 22 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "审批备注" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 2,
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.quotaApprove.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.quotaApprove,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "quotaApprove.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card",
                        staticStyle: { "margin-bottom": "80px" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("外呼信息")]
                            ),
                            _vm._v(" 　　\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { "padding-top": "20px" },
                            attrs: { hidden: _vm.part1 },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "外呼结果",
                                            prop: "customeResult",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择外呼结果",
                                              },
                                              model: {
                                                value:
                                                  _vm.saleInfo.customeResult,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saleInfo,
                                                    "customeResult",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saleInfo.customeResult",
                                              },
                                            },
                                            _vm._l(
                                              _vm.customeResults,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.val,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "外呼备注",
                                            prop: "customeMemo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                            },
                                            model: {
                                              value: _vm.saleInfo.customerMemo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "customerMemo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.customerMemo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { "padding-top": "20px" },
                            attrs: { hidden: _vm.part2 },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "支付流水号",
                                            prop: "transactionId",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            model: {
                                              value: _vm.saleInfo.transactionId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.saleInfo,
                                                  "transactionId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "saleInfo.transactionId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }