"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createData = createData;
exports.findPageList = findPageList;
exports.queryById = queryById;
exports.updateData = updateData;
exports.updateStatus = updateStatus;
exports.validateCode = validateCode;

var _request = _interopRequireDefault(require("@/utils/request"));

function findPageList(query) {
  return (0, _request.default)({
    url: '/bus_app/fault/getPage',
    method: 'post',
    params: query
  });
}

function createData(data) {
  return (0, _request.default)({
    url: '/bus_app/fault/create',
    method: 'post',
    params: data
  });
}

function updateData(data) {
  return (0, _request.default)({
    url: '/bus_app/fault/update',
    method: 'post',
    params: data
  });
}

function updateStatus(params) {
  return (0, _request.default)({
    url: '/bus_app/fault/updateStatus',
    method: 'post',
    params: params
  });
}

function queryById(id) {
  return (0, _request.default)({
    url: '/bus_app/fault/queryById',
    method: 'post',
    params: {
      id: id
    }
  });
}
/**
* 代码唯一性校验
* @param params
*/


function validateCode(params) {
  return (0, _request.default)({
    url: '/bus_app/fault/ckeckCode',
    method: 'post',
    params: {
      code: params
    }
  });
}