var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _vm.stockIn.state === "10"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_IN_SAVE"],
                      expression: "['ROLE_STOCK_STOCK_IN_SAVE']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isTemp,
                    size: "small",
                    type: "primary",
                    icon: "el-icon-folder",
                  },
                  on: { click: _vm.tempSave },
                },
                [_vm._v("暂存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stockIn.state === "10"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_IN_SUBMIT"],
                      expression: "['ROLE_STOCK_STOCK_IN_SUBMIT']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isSub,
                    size: "small",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submitSave },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stockIn.state === "10" || _vm.stockIn.state === "20"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_STOCK_STOCK_IN_CONFIRM"],
                      expression: "['ROLE_STOCK_STOCK_IN_CONFIRM']",
                    },
                  ],
                  attrs: {
                    disabled: _vm.isCon,
                    size: "small",
                    type: "primary",
                    icon: "el-icon-tickets",
                  },
                  on: { click: _vm.confirmSave },
                },
                [_vm._v("确认入库")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "default", icon: "el-icon-back" },
              on: { click: _vm.returnBtn },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.stockIn,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 60px 0 40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "入库单号", prop: "num" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "自动生成",
                                    },
                                    model: {
                                      value: _vm.stockIn.num,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockIn, "num", $$v)
                                      },
                                      expression: "stockIn.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "提交人/时间", prop: "num" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "提交人",
                                    },
                                    model: {
                                      value: _vm.submitAll,
                                      callback: function ($$v) {
                                        _vm.submitAll = $$v
                                      },
                                      expression: "submitAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [_c("el-form-item", { attrs: { label: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "入库仓库",
                                    prop: "warehouseCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        disabled: _vm.stockIn.state !== "10",
                                      },
                                      model: {
                                        value: _vm.stockIn.warehouseCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stockIn,
                                            "warehouseCode",
                                            $$v
                                          )
                                        },
                                        expression: "stockIn.warehouseCode",
                                      },
                                    },
                                    _vm._l(_vm.warehouseList, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "入库人/时间", prop: "num" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "入库人",
                                    },
                                    model: {
                                      value: _vm.confirmAll,
                                      callback: function ($$v) {
                                        _vm.confirmAll = $$v
                                      },
                                      expression: "confirmAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "入库备注", prop: "memo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.stockIn.state !== "10",
                                      placeholder: "200字以内",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.stockIn.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.stockIn, "memo", $$v)
                                      },
                                      expression: "stockIn.memo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("物料明细"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.skuListValidate,
                                expression: "skuListValidate",
                              },
                            ],
                            staticClass: "modelList__error",
                          },
                          [_vm._v(" 请添加物料 ！")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: {
                            float: "right",
                            "padding-right": "60px",
                          },
                        },
                        [
                          _vm.stockIn.state === "10" &&
                          _vm.stockIn.type !== "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.addSkuClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _vm._v("添加\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.stockIn.state === "10" &&
                          _vm.stockIn.type !== "10"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.deleteBtnDisabled,
                                    size: "small",
                                    type: "primary",
                                  },
                                  on: { click: _vm.deleteModelClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              staticStyle: {
                                display: "inline-block",
                                margin: "10px",
                              },
                              attrs: {
                                "show-file-list": false,
                                "before-upload": _vm.handleBeforeUpload,
                                "on-success": _vm.handleSuccess,
                                "on-error": _vm.handleError,
                                action: _vm.uploadAction,
                              },
                            },
                            [
                              _vm.stockIn.state === "10" &&
                              _vm.stockIn.type !== "10"
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "ROLE_STOCK_STOCK_IN_ITEM_UPLOAD",
                                          ],
                                          expression:
                                            "['ROLE_STOCK_STOCK_IN_ITEM_UPLOAD']",
                                        },
                                      ],
                                      attrs: { type: "primary", size: "small" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document-add",
                                      }),
                                      _vm._v("导入"),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.stockIn.state === "10" &&
                          _vm.stockIn.type !== "10"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: [
                                        "ROLE_STOCK_STOCK_IN_ITEM_UPLOAD",
                                      ],
                                      expression:
                                        "['ROLE_STOCK_STOCK_IN_ITEM_UPLOAD']",
                                    },
                                  ],
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.handelTempExport },
                                },
                                [_vm._v("导入模板下载")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "1px 60px 0 60px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingList4SkuSelect,
                                      expression: "loadingList4SkuSelect",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "header-cell-style": {
                                      background: "#eef1f6",
                                      color: "#606266",
                                    },
                                    size: "small",
                                    border: "",
                                    stripe: "",
                                    "tooltip-effect": "dark",
                                    data: _vm.list4Sku,
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "row-click": _vm.rowClickSelection,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      selectable: _vm.checkboxT,
                                      width: "50",
                                      align: "center",
                                      prop: "modelId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuCode",
                                      label: "物料编码",
                                      "min-width": "100px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "skuName",
                                      label: "物料名称",
                                      "min-width": "200px",
                                      resizable: "",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantity",
                                      label: "数量",
                                      width: "100px",
                                      align: "center",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "20px" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "总数量: " } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      staticStyle: {
                                        "min-width": "100px",
                                        "text-align": "right",
                                      },
                                      attrs: { type: "danger" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.stockIn.quantity || "0") +
                                          " 个"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物料添加",
            visible: _vm.addSkudialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSkudialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "searchInfoDiv" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryModelForm",
                      attrs: { model: _vm.listQuery, "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "型号选择", prop: "modelId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择...",
                                      },
                                      model: {
                                        value: _vm.listQuery.model,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "model", $$v)
                                        },
                                        expression: "listQuery.model",
                                      },
                                    },
                                    _vm._l(_vm.modelList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: item.isEnable !== "1",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "物料", prop: "sku" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { placeholder: "物料" },
                                    model: {
                                      value: _vm.listQuery.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "name", $$v)
                                      },
                                      expression: "listQuery.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content bg-purple",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "default" },
                            on: { click: _vm.resetBtn },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "listTableDiv" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: "tableKey",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": {
                      background: "#eef1f6",
                      color: "#606266",
                    },
                    size: "mini",
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark",
                    data: _vm.skuItemsList,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "code",
                      label: "物料编码",
                      "min-width": "80px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "物料名称",
                      "min-width": "100px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "modelNames",
                      label: "物料适用型号",
                      "min-width": "100px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数量", width: "100px", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              staticClass: "edit-input",
                              staticStyle: { width: "100%" },
                              attrs: { size: "mini" },
                              model: {
                                value: row.quantity,
                                callback: function ($$v) {
                                  _vm.$set(row, "quantity", $$v)
                                },
                                expression: "row.quantity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.btnLoading,
                                  size: "mini",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleModifyStatus(row)
                                  },
                                },
                              },
                              [_vm._v("\n              添加\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleFilter,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addSkudialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }