"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validate = require("@/utils/validate");

var _member = require("@/api/member");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var _this = this;

    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('请输入正确的账号'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      title: process.env.VUE_APP_INDEX_TITLE,
      dialogFormVisible: false,
      isPwd: this.$route.query.isPwd,
      username: this.$route.query.memberName,
      user: {
        // this.$store.loginUser.memberName
        // username: this.$store.state.user.loginUser.memberName,
        memberId: '',
        currentPassword: '',
        password: '',
        rstPassword: ''
      },
      pwdRules: {
        currentPassword: [{
          required: true,
          message: '请输入当前密码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error('请输入当前密码'));
            } else {
              (0, _member.checkPwd)(_this.$route.query.memberName, value).then(function (response) {
                var ret = response.data;

                if (ret) {
                  // console.log(ret)
                  callback();
                } else {
                  // console.log(response)
                  callback(new Error('当前密码错误'));
                }
              });
            }
          },
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 8,
          message: '密码长度8~20之间',
          trigger: 'blur'
        }, {
          max: 20,
          message: '密码长度8~20之间',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            var ret = false;
            var num = 0;
            var rule1 = /\d+/;
            var rule2 = /[a-z]+/;
            var rule3 = /[A-Z]+/;
            var rule4 = /[!@#\$]/;
            var rule5 = /[%^&*\{\};,.\?\/'`~"_-]/;
            var flag1 = rule1.test(value);
            var flag2 = rule2.test(value);
            var flag3 = rule3.test(value);
            var flag4 = rule4.test(value);
            var flag5 = rule5.test(value);

            if (flag1) {
              num = num + 1;
            }

            if (flag2) {
              num = num + 1;
            }

            if (flag3) {
              num = num + 1;
            }

            if (flag4) {
              num = num + 1;
            }

            if (flag5) {
              num = num - 1;
            }

            if (num > 2) {
              ret = true;
            }

            if (ret) {
              if (value === _this.user.currentPassword) {
                callback(new Error('新密码与上次密码不能一致'));
              } else {
                callback();
              }
            } else {
              callback(new Error('密码是数字,大小写字母,特殊符三种及以上,特殊字符只支持 ! @ # $'));
            }
          },
          trigger: 'blur'
        }],
        rstPassword: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error('请再次输入密码'));
            } else if (value !== _this.user.password) {
              callback(new Error('两次输入密码不一致'));
            } else if (value === _this.user.currentPassword) {
              callback(new Error('新密码与上次密码不能一致'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      },
      idaasSpUrl: process.env.VUE_APP_IDAAS_SP_URL,
      loginTitle: 'IDaaS'
    };
  },
  computed: {
    showIDaas: function showIDaas() {
      return this.loginTitle === 'IDaaS';
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        if (route.query.isPwd) {
          this.loginMessage();
          this.dialogFormVisible = true;
        }

        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.$nextTick(function () {
      _this2.$refs.username.focus();
    });
  },
  destroyed: function destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack: function goBack() {
      history.pushState(null, null, document.URL);
    },
    loginMessage: function loginMessage() {
      var _this3 = this;

      setTimeout(function () {
        _this3.$message.error('您的密码已经超过有效期，请修改密码后登录');
      }, 500);
    },
    showPwd: function showPwd() {
      var _this4 = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this4.$refs.password.focus();
      });
    },
    handleNextFocus: function handleNextFocus() {
      var _this5 = this;

      this.$nextTick(function () {
        _this5.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this6.loading = true;

          _this6.$store.dispatch('user/login', _this6.loginForm).then(function () {
            // this.$router.push({ path: this.redirect || '/' })
            // 不管this.redirect是何地址，直接跳转到首页
            _this6.$router.push({
              path: '/'
            }); // 清除tags缓存


            _this6.$store.dispatch('tagsView/delAllViews').then(function () {});

            _this6.$message({
              message: '欢迎回来',
              type: 'success'
            });

            _this6.loading = false;
          }).catch(function () {
            _this6.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updatePwdSubmit: function updatePwdSubmit() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _member.updatePwd)(_this7.$route.query.memberName, _this7.user.password, _this7.user.currentPassword).then(function (response) {
            // console.log(response.data)
            if (response.data) {
              var ret = response.data;

              if (ret) {
                _this7.$message({
                  title: '成功',
                  message: '修改密码成功!',
                  type: 'success'
                });

                _this7.dialogFormVisible = false;

                _store.default.dispatch('user/logout').then(function () {
                  location.reload();
                });

                _this7.$router.push('/login');
              } else {
                _this7.$message({
                  title: '失败',
                  message: response.msg || '修改失败,请重试!',
                  type: 'warning'
                });
              }
            } else {
              _this7.$message({
                title: '失败',
                message: response.msg || '修改密码异常!',
                type: 'warning'
              });
            }
          });
        }
      });
    },
    handleIdaasLogin: function handleIdaasLogin() {
      window.location.href = this.idaasSpUrl;
    },
    // 改变登陆类型
    changeLoginType: function changeLoginType(title) {
      if (title === 'CSRM') {
        this.loginTitle = 'IDaaS';
      } else {
        this.loginTitle = 'CSRM';
      }
    }
  }
};
exports.default = _default;