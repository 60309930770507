"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _index = _interopRequireDefault(require("@/layout/index"));

/**
 * 信息修改
 *
 * @type {{path: string, component, hidden: boolean, children: *[]}}
 */
var modifyRouter = {
  path: '/modify/detail',
  component: _index.default,
  hidden: true,
  children: [{
    path: 'modify/order_modify/:id',
    name: 'modifyDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/modify/order_modify/modify'));
      });
    },
    meta: {
      title: '修改服务单',
      icon: 'table',
      hidden: true,
      activeMenu: '/modify/order_modify/list',
      parent: {
        path: '/modify',
        redirect: 'noRedirect',
        meta: {
          title: '服务单修改',
          breadcrumb: true
        }
      }
    }
  }]
};
var _default = modifyRouter;
exports.default = _default;