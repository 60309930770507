var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _c(
            "div",
            {
              staticClass: "grid-content bg-purple",
              staticStyle: { float: "left", "padding-left": "1.3rem" },
            },
            [
              _c("el-tag", { attrs: { effect: "plain", type: "info" } }, [
                _vm._v("物料编码" + _vm._s(!_vm.isCreate ? "详细" : "创建")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_BASE_TASK_TYPE_ADD"],
                  expression: "['ROLE_BASE_TASK_TYPE_ADD']",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSumbit()
                },
              },
            },
            [_vm._v("保存\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              on: { click: _vm.returnList },
            },
            [_vm._v("返回\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: {
            "margin-top": "20px",
            "margin-left": "20px",
            "margin-right": "20px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.taskType,
                rules: _vm.rules,
                "label-width": "150px",
                size: "50%",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务类型名称", prop: "name" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.taskType.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskType, "name", $$v)
                          },
                          expression: "taskType.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择机构", prop: "name" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            placeholder: "机构",
                            clearable: "",
                          },
                          model: {
                            value: _vm.taskType.orgIdsList,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskType, "orgIdsList", $$v)
                            },
                            expression: "taskType.orgIdsList",
                          },
                        },
                        _vm._l(_vm.organizationList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              disabled: item.isEnable !== "1",
                              label: item.name,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "任务类型分类", prop: "name" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "任务类型分类", clearable: "" },
                          model: {
                            value: _vm.taskType.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskType, "type", $$v)
                            },
                            expression: "taskType.type",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }