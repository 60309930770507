"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downLoadFile = downLoadFile;
exports.downLoadFile1 = downLoadFile1;
exports.uploadFile = uploadFile;

var _axios = _interopRequireDefault(require("axios"));

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var api = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 // request timeout

}); // request interceptor


api.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers.common['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
});
/**
 * 上传文件
 * @param {请求目录文件} url
 * @param {文件对象} file
 * @param {成功回调函数} _cb
 * @param {失败回调函数} _catchcb
 */

function uploadFile(url, file, _cb, _catchcb) {
  var fd = new FormData();
  fd.append('file', file);
  return api.post(url, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    }
  }).then(_cb).catch(_catchcb);
}
/**
 * 下载文件
 * @param {请求url} url
 * @param {成功回调函数} _cb
 * @param {失败回调函数} _catchcb
 */


function downLoadFile(url, _cb, _catchcb) {
  return api.get(url, {
    responseType: 'blob'
  }, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(_cb).catch(_catchcb);
}

function downLoadFile1(url, data, _cb, _catchcb) {
  return api.post(url, data, {
    responseType: 'blob'
  }, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(_cb).catch(_catchcb);
}