var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-buttons-group" },
        [
          _c(
            "sticky",
            { attrs: { "z-index": 10, "class-name": "sub-navbar default" } },
            [
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.returnBtn } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.customerRegisterDetail,
            rules: _vm.formRules,
            "label-width": "150px",
            size: "50%",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "50px",
                      "margin-right": "50px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "myStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              客户详情\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "姓名",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isConfirm },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "手机号",
                                            prop: "mobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "性别",
                                            prop: "gender",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .gender,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "gender",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.gender",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "电子邮箱",
                                            prop: "email",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isConfirm },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "是否会员",
                                            prop: "memberFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: "",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.customerRegisterDetail
                                                    .memberFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customerRegisterDetail,
                                                    "memberFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customerRegisterDetail.memberFlag",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "是",
                                                  value: "1",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "否",
                                                  value: "0",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "生日",
                                            prop: "birthday",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .birthday,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "birthday",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.birthday",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "省市区",
                                            prop: "address",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { disabled: _vm.isConfirm },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "详细地址",
                                            prop: "detailAddress",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .detailAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "detailAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.detailAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "邮政编码",
                                            prop: "zipCode",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .zipCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "zipCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.zipCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册来源",
                                            prop: "registerSource",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: "",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.customerRegisterDetail
                                                    .registerSource,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customerRegisterDetail,
                                                    "registerSource",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customerRegisterDetail.registerSource",
                                              },
                                            },
                                            _vm._l(
                                              _vm.registerSourceOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.val,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "注册类型",
                                            prop: "registerSource",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: "",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.customerRegisterDetail
                                                    .registerType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customerRegisterDetail,
                                                    "registerType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customerRegisterDetail.registerType",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "自动注册",
                                                  value: "1",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "手动注册",
                                                  value: "2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "nmyStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              其他信息\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "CRM ID",
                                            prop: "crmId",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .crmId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "crmId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.crmId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8, pull: 1 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            label: "Union ID",
                                            prop: "unionid",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isConfirm,
                                            },
                                            model: {
                                              value:
                                                _vm.customerRegisterDetail
                                                  .unionid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customerRegisterDetail,
                                                  "unionid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customerRegisterDetail.unionid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, pull: 1 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { label: "创建时间" },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.isConfirm,
                                          },
                                          model: {
                                            value:
                                              _vm.customerRegisterDetail.inDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customerRegisterDetail,
                                                "inDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customerRegisterDetail.inDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "0px",
                          "margin-right": "0px",
                          "margin-top": "10px",
                        },
                      },
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "nmyStyle",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("\n              客户履历\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: { "border-top": "none" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 22 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "50px",
                                        "margin-right": "50px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            border: "",
                                            stripe: "",
                                            "header-cell-style": {
                                              background: "#eef1f6",
                                              color: "#606266",
                                              height: "50px",
                                            },
                                            data: _vm.customerHistoryList,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateTime",
                                              label: "操作时间",
                                              align: "center",
                                              width: "180px",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "updateUser",
                                              label: "操作人",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "crmId",
                                              label: "crm Id",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "name",
                                              label: "客户姓名",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "mobile",
                                              label: "手机号",
                                              align: "center",
                                              resizable: "",
                                              width: "180px",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "email",
                                              label: "邮箱",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "unionid",
                                              label: "unionid",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "memberFlag",
                                              label: "是否会员",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "birthday",
                                              label: "生日",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "gender",
                                              label: "性别",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "province",
                                              label: "省",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "city",
                                              label: "市",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "district",
                                              label: "区",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "detailAddress",
                                              label: "详细地址",
                                              align: "center",
                                              resizable: "",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("pagination", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.total > 0,
                                            expression: "total>0",
                                          },
                                        ],
                                        attrs: {
                                          total: _vm.total,
                                          page: _vm.listQuery.page,
                                          limit: _vm.listQuery.limit,
                                        },
                                        on: {
                                          "update:page": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "page",
                                              $event
                                            )
                                          },
                                          "update:limit": function ($event) {
                                            return _vm.$set(
                                              _vm.listQuery,
                                              "limit",
                                              $event
                                            )
                                          },
                                          pagination: _vm.getHistoryList,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }