var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "listTableDiv" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                size: "small",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                  height: "50px",
                },
                border: "",
                stripe: "",
                "tooltip-effect": "dark",
                data: _vm.listData,
                "highlight-current-row": "",
              },
              on: { "cell-dblclick": _vm.gotoDetail },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "cusName", label: "用户名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "inDate", formatter: "", label: "创建日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "cusPhone", label: "手机号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "通话开始时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "通话结束时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "callDuration", label: "通话时长" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dragdialog", rawName: "v-dragdialog" }],
          attrs: { visible: _vm.detailShow, "custom-class": "demo-drawer" },
          on: {
            "update:visible": function ($event) {
              _vm.detailShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.vseVideoCallInfo, "label-width": "180px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "预约ID", prop: "channelCode" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.num,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "num", $$v)
                          },
                          expression: "vseVideoCallInfo.num",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "cusName" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.cusName,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "cusName", $$v)
                          },
                          expression: "vseVideoCallInfo.cusName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "客户手机号", prop: "cusPhone" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.cusPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "cusPhone", $$v)
                          },
                          expression: "vseVideoCallInfo.cusPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "通话开始时间", prop: "startTime" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "startTime", $$v)
                          },
                          expression: "vseVideoCallInfo.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "通话结束时间", prop: "endTime" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "endTime", $$v)
                          },
                          expression: "vseVideoCallInfo.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "通话时长", prop: "callDuration" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.callDuration,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "callDuration", $$v)
                          },
                          expression: "vseVideoCallInfo.callDuration",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "视频地址", prop: "vseLink" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.vseVideoCallInfo.vseLink,
                          callback: function ($$v) {
                            _vm.$set(_vm.vseVideoCallInfo, "vseLink", $$v)
                          },
                          expression: "vseVideoCallInfo.vseLink",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }