"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/Hn-MYANe/0/dyson-china/csrm/csrmui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = _interopRequireDefault(require("@/api/register/constant"));

var _customer = require("@/api/register/customer");

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CustomerRegisterDetail',
  components: {
    Sticky: _Sticky.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      customerRegisterDetail: {},
      formRules: {},
      id: this.$route.params.id,
      isConfirm: true,
      customerHistoryList: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      listLoading: false,
      registerSourceOptions: _constant.default.registerSourceOptions
    };
  },
  computed: {},
  created: function created() {
    this.getDetail();
  },
  methods: {
    // 初始查询线下退换记录详情信息
    getDetail: function getDetail() {
      var _this = this;

      (0, _customer.queryById)(this.id).then(function (response) {
        if (response.data) {
          _this.customerRegisterDetail = response.data;

          if (_this.customerRegisterDetail.id) {
            _this.listQuery.parentId = _this.customerRegisterDetail.id;

            _this.getHistoryList();
          }
        }
      });
    },
    // 返回
    returnBtn: function returnBtn() {
      // this.$router.go(-1) // 返回上一层
      this.$router.push({
        path: '/register/customer_register'
      });
    },
    // 获取检测单明细
    getHistoryList: function getHistoryList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _customer.getHistoryList)(this.listQuery).then(function (response) {
        _this2.customerHistoryList = response.data.records;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;