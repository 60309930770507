var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "sticky",
        { attrs: { "class-name": "sub-navbar default" } },
        [
          _vm.extendInfo.orderStatus === "01"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BENEFIT_BATCH_APPROVE_TEMP"],
                      expression: "['ROLE_BENEFIT_BATCH_APPROVE_TEMP']",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.tempSave },
                },
                [_vm._v("暂存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.extendInfo.orderStatus === "01"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BENEFIT_BATCH_APPROVE_COMMIT"],
                      expression: "['ROLE_BENEFIT_BATCH_APPROVE_COMMIT']",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitSave },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.extendInfo.orderStatus !== "03"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BENEFIT_BATCH_APPROVE_PASS"],
                      expression: "['ROLE_BENEFIT_BATCH_APPROVE_PASS']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.extendInfo.orderStatus === "01",
                  },
                  on: { click: _vm.passApprove },
                },
                [_vm._v("审批通过")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.extendInfo.orderStatus !== "03"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_BENEFIT_BATCH_APPROVE_REFUSE"],
                      expression: "['ROLE_BENEFIT_BATCH_APPROVE_REFUSE']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.extendInfo.orderStatus === "01",
                  },
                  on: { click: _vm.refuseApprove },
                },
                [_vm._v("审批拒绝")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "default", icon: "el-icon-back" },
              on: { click: _vm.returnBtn },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.extendInfo,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("基本信息"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 60px 0 40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "延保单号" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "延保单号",
                                    },
                                    model: {
                                      value: _vm.extendInfo.num,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.extendInfo, "num", $$v)
                                      },
                                      expression: "extendInfo.num",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "提交人" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "提交人",
                                    },
                                    model: {
                                      value: _vm.extendInfo.commitUser,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extendInfo,
                                          "commitUser",
                                          $$v
                                        )
                                      },
                                      expression: "extendInfo.commitUser",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "提交时间" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "提交时间",
                                    },
                                    model: {
                                      value: _vm.extendInfo.commitTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extendInfo,
                                          "commitTime",
                                          $$v
                                        )
                                      },
                                      expression: "extendInfo.commitTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批人" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "审批人",
                                    },
                                    model: {
                                      value: _vm.extendInfo.approveUser,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extendInfo,
                                          "approveUser",
                                          $$v
                                        )
                                      },
                                      expression: "extendInfo.approveUser",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批时间" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "审批时间",
                                    },
                                    model: {
                                      value: _vm.extendInfo.approveTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extendInfo,
                                          "approveTime",
                                          $$v
                                        )
                                      },
                                      expression: "extendInfo.approveTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批结果" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: {
                                      disabled: true,
                                      placeholder: "审批结果",
                                    },
                                    model: {
                                      value: _vm.extendInfo.auditResult,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.extendInfo,
                                          "auditResult",
                                          $$v
                                        )
                                      },
                                      expression: "extendInfo.auditResult",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { shadow: "hover" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("el-tag", { attrs: { effect: "plain" } }, [
                        _vm._v("产品信息"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-content bg-purple",
                          staticStyle: {
                            float: "right",
                            "padding-right": "60px",
                          },
                        },
                        [
                          _vm.extendInfo.orderStatus === "01"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: [
                                        "ROLE_BENEFIT_BATCH_APPROVE_DELETE",
                                      ],
                                      expression:
                                        "['ROLE_BENEFIT_BATCH_APPROVE_DELETE']",
                                    },
                                  ],
                                  attrs: {
                                    disabled: _vm.deleteBtnDisabled,
                                    size: "small",
                                    type: "primary",
                                  },
                                  on: { click: _vm.deleteModelClick },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("删除\n            "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              staticStyle: {
                                display: "inline-block",
                                margin: "10px",
                              },
                              attrs: {
                                "show-file-list": false,
                                "before-upload": _vm.handleBeforeUpload,
                                "on-success": _vm.handleSuccess,
                                "on-error": _vm.handleError,
                                action: _vm.uploadAction,
                              },
                            },
                            [
                              _vm.extendInfo.orderStatus === "01"
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "ROLE_BENEFIT_BATCH_APPROVE_IMPORT",
                                          ],
                                          expression:
                                            "['ROLE_BENEFIT_BATCH_APPROVE_IMPORT']",
                                        },
                                      ],
                                      attrs: { type: "primary", size: "small" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document-add",
                                      }),
                                      _vm._v("导入"),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.extendInfo.orderStatus === "01"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: [
                                        "ROLE_BENEFIT_BATCH_APPROVE_IMPORT",
                                      ],
                                      expression:
                                        "['ROLE_BENEFIT_BATCH_APPROVE_IMPORT']",
                                    },
                                  ],
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.handelTempExport },
                                },
                                [_vm._v("导入模板下载")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "1px 60px 0 60px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingSnList,
                                      expression: "loadingSnList",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "header-cell-style": {
                                      background: "#eef1f6",
                                      color: "#606266",
                                      height: "50px",
                                    },
                                    size: "small",
                                    border: "",
                                    stripe: "",
                                    "tooltip-effect": "dark",
                                    data: _vm.snList,
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "row-click": _vm.rowClickSelection,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "50",
                                      align: "center",
                                      prop: "modelId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "serialNumber",
                                      label: "延保SN",
                                      "min-width": "100px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "warrantyDate",
                                      label: "入保日期",
                                      "min-width": "100px",
                                      formatter: _vm.dateTimeFormat,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "benefitEndDate",
                                      label: "保修结束日期",
                                      "min-width": "100px",
                                      formatter: _vm.dateTimeFormat,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "extendBenefitDate",
                                      label: "延保时长",
                                      "min-width": "100px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "extendBenefitNote",
                                      label: "延保原因",
                                      "min-width": "100px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "extendBenefitSource",
                                      label: "延保渠道",
                                      "min-width": "100px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("pagination", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.total > 0,
                                    expression: "total>0",
                                  },
                                ],
                                attrs: {
                                  total: _vm.total,
                                  page: _vm.listQuery.page,
                                  limit: _vm.listQuery.limit,
                                },
                                on: {
                                  "update:page": function ($event) {
                                    return _vm.$set(
                                      _vm.listQuery,
                                      "page",
                                      $event
                                    )
                                  },
                                  "update:limit": function ($event) {
                                    return _vm.$set(
                                      _vm.listQuery,
                                      "limit",
                                      $event
                                    )
                                  },
                                  pagination: _vm.getExendItemInfo,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }