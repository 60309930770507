"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _invoice = require("@/api/bill/invoice");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // name: 'basicsRepairDetail', eslint-disable-line
  data: function data() {
    return {
      id: this.$route.params.id,
      billInvoiceInfo: {},
      loadingList4Model: false,
      isShow: false
    };
  },
  created: function created() {
    if (this.id) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      var param = {
        id: this.id
      };
      (0, _invoice.queryById)(param).then(function (response) {
        if (response.data) {
          _this.billInvoiceInfo = response.data;
          _this.billInvoiceInfo.custName = _this.billInvoiceInfo.custNamePage;
          _this.billInvoiceInfo.custTax = _this.billInvoiceInfo.custTaxPage;
          _this.billInvoiceInfo.custAddress = _this.billInvoiceInfo.custAddressPage;
          _this.billInvoiceInfo.custPhone = _this.billInvoiceInfo.custPhonePage;
          _this.billInvoiceInfo.custBank = _this.billInvoiceInfo.custBankPage;
          _this.billInvoiceInfo.custBankAcc = _this.billInvoiceInfo.custBankAccPage;
          _this.billInvoiceInfo.takeUser = _this.billInvoiceInfo.takeUserPage;
          _this.billInvoiceInfo.takeMobile = _this.billInvoiceInfo.takeMobilePage;
          _this.billInvoiceInfo.takeAddress = _this.billInvoiceInfo.takeAddressPage;
          _this.billInvoiceInfo.custEmail = _this.billInvoiceInfo.custEmailPage;

          if (_this.billInvoiceInfo.invoiceType === '10') {
            _this.isShow = true;
          }
        }
      });
    },
    returnBtn: function returnBtn() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/bill/refund'
      });
    }
  }
};
exports.default = _default;